/* eslint-disable import/no-cycle */
import useTheme from 'hooks/useTheme'
import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  const { theme } = useTheme()
  return theme.isDark ? <Dark {...props} /> : <Light {...props} />
}

const Dark: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35Z"
        fill="#15152E"
        fillOpacity="0.6"
      />
      <path
        d="M16.5576 23.8404C16.8133 24.0962 17.1499 24.2308 17.5133 24.2308C17.8768 24.2308 18.1999 24.0962 18.4557 23.827C18.7114 23.5577 18.846 23.2347 18.846 22.8712C18.846 22.5077 18.7114 22.1847 18.4691 21.9289C18.1999 21.6731 17.8903 21.5385 17.5133 21.5385H17.4864C16.746 21.552 16.1537 22.1443 16.1672 22.8847C16.1672 23.2616 16.2883 23.5712 16.5576 23.8404Z"
        fill="#BDC2FF"
      />
      <path
        d="M16.7191 17.7424C16.4633 18.1731 16.3287 18.6981 16.3287 19.3039H16.3153V20.1924H18.8057V19.627C18.8057 19.1962 18.8864 18.8193 19.048 18.5366C19.2095 18.2539 19.4922 17.9039 19.923 17.5001C20.4749 16.9885 20.8383 16.5039 21.121 16.0327C21.4037 15.5616 21.5383 15.0097 21.5383 14.3501C21.5383 13.6904 21.3768 13.0847 21.0268 12.5327C20.6768 11.9808 20.2326 11.5501 19.6133 11.2404C18.9941 10.9308 18.2941 10.7693 17.4999 10.7693C16.4903 10.7693 15.521 11.1462 14.7672 11.8327C14.0807 12.4789 13.4614 13.4481 13.4614 14.8077H15.7364C15.7364 14.0943 15.9922 13.7577 16.2749 13.4885C16.571 13.2193 17.0691 13.0847 17.5672 13.0847C18.0383 13.0847 18.4287 13.2193 18.7114 13.4885C18.9941 13.7712 19.1422 14.0943 19.1422 14.4847C19.1422 14.8077 19.0614 15.0904 18.8864 15.3462C18.7114 15.602 18.4422 15.8981 18.0653 16.2347C17.4326 16.8001 16.9749 17.2981 16.7191 17.7424Z"
        fill="#BDC2FF"
      />
    </Svg>
  )
}

const Light: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="35" height="35" viewBox="0 0 35 35" fill="none" {...props}>
      <path
        d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35Z"
        fill="#E0E0E0"
      />
      <path
        d="M16.5576 23.8402C16.8133 24.096 17.1499 24.2306 17.5133 24.2306C17.8768 24.2306 18.1999 24.096 18.4557 23.8267C18.7114 23.5575 18.846 23.2344 18.846 22.871C18.846 22.5075 18.7114 22.1844 18.4691 21.9287C18.1999 21.6729 17.8903 21.5383 17.5133 21.5383H17.4864C16.746 21.5517 16.1537 22.144 16.1672 22.8844C16.1672 23.2614 16.2883 23.571 16.5576 23.8402Z"
        fill="#5A5A5A"
        fillOpacity="0.7"
      />
      <path
        d="M16.7191 17.7421C16.4633 18.1729 16.3287 18.6979 16.3287 19.3037H16.3153V20.1921H18.8057V19.6267C18.8057 19.196 18.8864 18.819 19.048 18.5364C19.2095 18.2537 19.4922 17.9037 19.923 17.4998C20.4749 16.9883 20.8383 16.5037 21.121 16.0325C21.4037 15.5614 21.5383 15.0094 21.5383 14.3498C21.5383 13.6902 21.3768 13.0844 21.0268 12.5325C20.6768 11.9806 20.2326 11.5498 19.6133 11.2402C18.9941 10.9306 18.2941 10.769 17.4999 10.769C16.4903 10.769 15.521 11.146 14.7672 11.8325C14.0807 12.4787 13.4614 13.4479 13.4614 14.8075H15.7364C15.7364 14.094 15.9922 13.7575 16.2749 13.4883C16.571 13.219 17.0691 13.0844 17.5672 13.0844C18.0383 13.0844 18.4287 13.219 18.7114 13.4883C18.9941 13.771 19.1422 14.094 19.1422 14.4844C19.1422 14.8075 19.0614 15.0902 18.8864 15.346C18.7114 15.6017 18.4422 15.8979 18.0653 16.2344C17.4326 16.7998 16.9749 17.2979 16.7191 17.7421Z"
        fill="#5A5A5A"
        fillOpacity="0.7"
      />
    </Svg>
  )
}

export default Icon

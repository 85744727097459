import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 25 24" fill="none" {...props}>
      <path
        d="M20.225 11.1489L20.2242 11.148L15.3256 6.273C14.9586 5.90779 14.365 5.90915 13.9997 6.27618C13.6345 6.64317 13.6359 7.23675 14.0028 7.602L17.2918 10.875H5.4375C4.91972 10.875 4.5 11.2947 4.5 11.8125C4.5 12.3303 4.91972 12.75 5.4375 12.75H17.2917L14.0029 16.023C13.6359 16.3882 13.6345 16.9818 13.9998 17.3488C14.365 17.7159 14.9587 17.7172 15.3256 17.352L20.2242 12.477L20.2251 12.4761C20.5923 12.1096 20.5911 11.5141 20.225 11.1489Z"
        fill="#ADADAD"
      />
    </Svg>
  )
}

export default Icon

import {
  beamColorsV2,
  darkColorsV2,
  lightColorsV2,
  aethirColorsV2,
  blueColorsV2,
  carvColorsV2,
} from 'uikit/theme/colorsV2'

export type CommonTheme = {
  connectButtonBackground: string
  connectedButtonBackground: string
  connectButtonInnerBackground: string
  activeMenuTextColor: string
  profileAction: string
  profileForeground: string
  closeButtonBackground: string
  modalBackground: string

  tableDropdownBtnBackground: string
  tableDropdownBtnColor: string
  tableDropdownBtnBorder: string
  tableDropdownArrowColor: string
  selectorBackground: string

  boxTableBackground: string
  customButtonBackground: string

  upcomingProjectIconColor: string
  projectCardShadow: string

  subscriptionNotStart: string
  subscriptionStartNotEnd: string
  insctructionText: string
  pipeFooter: string
  kycStatusDescBg: string
  saleEnds: string
  textDropDownMenu: string
  stepsTimeLineActive: string
  textBottomInfoCard: string
  liveProjectsQuestionIcon: string
  stakeActionButton: string
  actionButtonBorderNotStarted: string
  saleInfoBorder: string
  borderCompletedProjects: string
  borderLeftAccordion: string
  accSidebarBorder: string
  allNetwroksText: string
  projectTagText: string
  boxTableRequirements: string
  dropdownMenu: string
  notFoundPageBg: string
  tagBgLiveProject: string
  totalStakePoolTextTimeLine: string
  kycStatusLink: string
  kycGetTokensBtn: string
  completedProjectsBgHover: string
  reminderBtnTimeline: string
  liveProjectText: string
  investIcon: string
  boxTableBanner: string
  modalFilterText: string
  borderLineCancel: string
  promoCodeText: string
  totalPriceText: string
  bgInputAmountSaleCard: string
  styleCardBorder: string
  bgRemindTimeline: string
  headerContainerBgSaleCard: string
}

export const light: CommonTheme = {
  connectButtonBackground: lightColorsV2.gradients.green2lightblue,
  connectedButtonBackground: lightColorsV2.background,
  connectButtonInnerBackground: undefined,
  activeMenuTextColor: lightColorsV2.gradients.green2lightblue,
  profileAction: undefined,
  profileForeground: undefined,
  closeButtonBackground: undefined,
  modalBackground: undefined,

  tableDropdownBtnBackground: lightColorsV2.text,
  tableDropdownBtnColor: lightColorsV2.main,
  tableDropdownBtnBorder: lightColorsV2.light,
  tableDropdownArrowColor: lightColorsV2.text,
  selectorBackground: lightColorsV2.main,

  boxTableBackground: lightColorsV2.main,
  customButtonBackground: lightColorsV2.textThirdly,

  upcomingProjectIconColor: lightColorsV2.textDisabled,
  projectCardShadow: 'drop-shadow(0px 0px 14px rgba(0, 36, 164, 0.24));',

  subscriptionNotStart: lightColorsV2.textDisabled,
  subscriptionStartNotEnd: lightColorsV2.textThirdly,
  insctructionText: lightColorsV2.text,
  pipeFooter: lightColorsV2.light,
  kycStatusDescBg: lightColorsV2.dark2,
  saleEnds: lightColorsV2.textDisabled,
  textDropDownMenu: lightColorsV2.textSecondary,
  stepsTimeLineActive: lightColorsV2.textInfo,
  textBottomInfoCard: lightColorsV2.textSecondary,
  liveProjectText: lightColorsV2.main5,
  liveProjectsQuestionIcon: lightColorsV2.textSecondary,
  stakeActionButton: lightColorsV2.textThirdly,
  actionButtonBorderNotStarted: lightColorsV2.light,
  saleInfoBorder: lightColorsV2.light,
  borderCompletedProjects: undefined,
  borderLeftAccordion: lightColorsV2.light,
  accSidebarBorder: lightColorsV2.light,
  allNetwroksText: lightColorsV2.text,
  projectTagText: lightColorsV2.tagText,
  boxTableRequirements: lightColorsV2.main,
  dropdownMenu: lightColorsV2.main,
  notFoundPageBg: lightColorsV2.main,
  tagBgLiveProject: lightColorsV2.tagBg,
  totalStakePoolTextTimeLine: lightColorsV2.text,
  kycGetTokensBtn: lightColorsV2.textInfo,
  kycStatusLink: lightColorsV2.textLink,
  completedProjectsBgHover: lightColorsV2.dark2,
  reminderBtnTimeline: lightColorsV2.textInfo,
  investIcon: '#A6A8AA',
  boxTableBanner: lightColorsV2.light,
  modalFilterText: undefined,
  borderLineCancel: undefined,
  promoCodeText: '#fafafab3',
  totalPriceText: undefined,
  bgInputAmountSaleCard: lightColorsV2.main,
  styleCardBorder: undefined,
  bgRemindTimeline: darkColorsV2.feedbacks.infoBg,
  headerContainerBgSaleCard: `${darkColorsV2.dark2}80`,
}

export const aethir: CommonTheme = {
  connectButtonBackground: aethirColorsV2.main4,
  connectedButtonBackground: aethirColorsV2.background,
  connectButtonInnerBackground: undefined,
  activeMenuTextColor: aethirColorsV2.gradients.green2lightblue,

  profileAction: undefined,
  profileForeground: undefined,
  closeButtonBackground: undefined,
  modalBackground: undefined,

  tableDropdownBtnBackground: aethirColorsV2.text,
  tableDropdownBtnColor: aethirColorsV2.main,
  tableDropdownBtnBorder: aethirColorsV2.light,
  tableDropdownArrowColor: aethirColorsV2.text,
  selectorBackground: aethirColorsV2.main,

  boxTableBackground: lightColorsV2.main,
  customButtonBackground: lightColorsV2.textThirdly,

  upcomingProjectIconColor: lightColorsV2.textDisabled,
  projectCardShadow: 'drop-shadow(0px 0px 14px rgba(0, 36, 164, 0.24));',

  subscriptionNotStart: aethirColorsV2.textDisabled,
  subscriptionStartNotEnd: aethirColorsV2.main5,
  insctructionText: aethirColorsV2.main5,
  pipeFooter: aethirColorsV2.main5,
  kycStatusDescBg: aethirColorsV2.main5,
  saleEnds: aethirColorsV2.textDisabled,
  textDropDownMenu: aethirColorsV2.textSecondary,
  stepsTimeLineActive: aethirColorsV2.main5,
  textBottomInfoCard: aethirColorsV2.text,
  liveProjectsQuestionIcon: aethirColorsV2.main5,
  stakeActionButton: aethirColorsV2.textThirdly,
  actionButtonBorderNotStarted: aethirColorsV2.light,
  saleInfoBorder: aethirColorsV2.light,
  borderCompletedProjects: undefined,
  borderLeftAccordion: aethirColorsV2.light,
  accSidebarBorder: '#555a9bde',
  allNetwroksText: aethirColorsV2.main5,
  projectTagText: aethirColorsV2.textSecondary,
  boxTableRequirements: aethirColorsV2.background,
  dropdownMenu: aethirColorsV2.dark,
  notFoundPageBg: aethirColorsV2.dark2,
  tagBgLiveProject: aethirColorsV2.main2,
  totalStakePoolTextTimeLine: aethirColorsV2.textDisabled,
  kycGetTokensBtn: aethirColorsV2.main4,
  kycStatusLink: aethirColorsV2.main4,
  completedProjectsBgHover: aethirColorsV2.light,
  reminderBtnTimeline: aethirColorsV2.background,
  liveProjectText: aethirColorsV2.main5,
  investIcon: '#A6A8AA',
  boxTableBanner: aethirColorsV2.background,
  modalFilterText: aethirColorsV2.textThirdly,
  borderLineCancel: undefined,
  promoCodeText: aethirColorsV2.main,
  totalPriceText: '#925EFF',
  bgInputAmountSaleCard: '#f2f2f280',
  styleCardBorder: undefined,
  bgRemindTimeline: aethirColorsV2.main4,
  headerContainerBgSaleCard: `${darkColorsV2.dark}1F`,
}

export const dark: CommonTheme = {
  connectButtonBackground: darkColorsV2.light,
  connectedButtonBackground: darkColorsV2.light,
  connectButtonInnerBackground: darkColorsV2.main3,
  activeMenuTextColor: darkColorsV2.gradients.green2lightblue,

  profileAction: darkColorsV2.light,
  profileForeground: darkColorsV2.main,
  closeButtonBackground: darkColorsV2.dark2,
  modalBackground: darkColorsV2.main,

  tableDropdownBtnBackground: darkColorsV2.dark,
  tableDropdownBtnColor: darkColorsV2.textThirdly,
  tableDropdownBtnBorder: darkColorsV2.light,
  tableDropdownArrowColor: darkColorsV2.textThirdly,
  selectorBackground: darkColorsV2.light,

  boxTableBackground: darkColorsV2.dark2,
  customButtonBackground: darkColorsV2.light,

  upcomingProjectIconColor: lightColorsV2.light,
  projectCardShadow: 'drop-shadow(0px 0px 14px rgba(0, 36, 164, 0.24));',

  subscriptionNotStart: darkColorsV2.textDisabled,
  subscriptionStartNotEnd: darkColorsV2.main5,
  insctructionText: darkColorsV2.exploreIndicatorActive,
  pipeFooter: darkColorsV2.main5,
  kycStatusDescBg: 'rgba(189, 194, 255, 0.05)',
  saleEnds: darkColorsV2.light,
  textDropDownMenu: darkColorsV2.textSecondary,
  stepsTimeLineActive: darkColorsV2.textThirdly,
  textBottomInfoCard: darkColorsV2.textSecondary,
  liveProjectText: darkColorsV2.main5,
  liveProjectsQuestionIcon: darkColorsV2.textThirdly,
  stakeActionButton: darkColorsV2.textThirdly,
  actionButtonBorderNotStarted: darkColorsV2.light,
  saleInfoBorder: darkColorsV2.light,
  borderCompletedProjects: undefined,
  borderLeftAccordion: darkColorsV2.light,
  accSidebarBorder: '#555a9bde',
  allNetwroksText: darkColorsV2.textThirdly,
  projectTagText: darkColorsV2.textSecondary,
  boxTableRequirements: darkColorsV2.dark2,
  dropdownMenu: darkColorsV2.dark,
  notFoundPageBg: darkColorsV2.dark2,
  tagBgLiveProject: darkColorsV2.tagBg,
  totalStakePoolTextTimeLine: darkColorsV2.light,
  kycGetTokensBtn: darkColorsV2.textInfo,
  kycStatusLink: darkColorsV2.textLink,
  completedProjectsBgHover: darkColorsV2.light,
  reminderBtnTimeline: darkColorsV2.textSecondary,
  investIcon: '#A6A8AA',
  boxTableBanner: darkColorsV2.light,
  modalFilterText: undefined,
  borderLineCancel: undefined,
  promoCodeText: '#fafafab3',
  totalPriceText: undefined,
  bgInputAmountSaleCard: darkColorsV2.dark,
  styleCardBorder: undefined,
  bgRemindTimeline: darkColorsV2.feedbacks.infoBg,
  headerContainerBgSaleCard: `${darkColorsV2.dark}1F`,
}

export const beam: CommonTheme = {
  connectButtonBackground: beamColorsV2.button.primaryBg,
  connectedButtonBackground: beamColorsV2.button.primaryBg,

  connectButtonInnerBackground: beamColorsV2.main3,
  activeMenuTextColor: beamColorsV2.textLink,

  profileAction: beamColorsV2.dark2,
  profileForeground: 'rgba(32, 34, 42, 1)',
  closeButtonBackground: darkColorsV2.dark2,
  modalBackground: 'rgba(32, 34, 42, 1)',

  tableDropdownBtnBackground: beamColorsV2.main,
  tableDropdownBtnColor: beamColorsV2.textSecondary,
  tableDropdownBtnBorder: beamColorsV2.light,
  tableDropdownArrowColor: beamColorsV2.textSecondary,
  selectorBackground: beamColorsV2.main,
  boxTableBackground: beamColorsV2.main,
  customButtonBackground: beamColorsV2.textThirdly,

  upcomingProjectIconColor: beamColorsV2.textDisabled,
  projectCardShadow: '',

  subscriptionNotStart: beamColorsV2.textLink,
  subscriptionStartNotEnd: beamColorsV2.textLink,
  insctructionText: beamColorsV2.main5,
  pipeFooter: beamColorsV2.light,
  kycStatusDescBg: beamColorsV2.main3,
  saleEnds: beamColorsV2.main4,
  textDropDownMenu: beamColorsV2.main5,
  stepsTimeLineActive: beamColorsV2.textThirdly,
  textBottomInfoCard: beamColorsV2.text,
  liveProjectText: beamColorsV2.main5,
  liveProjectsQuestionIcon: beamColorsV2.textSecondary,
  stakeActionButton: beamColorsV2.textLink,
  actionButtonBorderNotStarted: beamColorsV2.textDisabled,
  saleInfoBorder: beamColorsV2.dark2,
  borderCompletedProjects: beamColorsV2.dark2,
  borderLeftAccordion: beamColorsV2.dark2,
  accSidebarBorder: beamColorsV2.dark2,
  allNetwroksText: beamColorsV2.textSecondary,
  projectTagText: beamColorsV2.text,
  boxTableRequirements: beamColorsV2.main,
  dropdownMenu: beamColorsV2.main,
  notFoundPageBg: beamColorsV2.main2,
  tagBgLiveProject: beamColorsV2.tagBg,
  totalStakePoolTextTimeLine: beamColorsV2.text,
  kycGetTokensBtn: beamColorsV2.textLink,
  kycStatusLink: beamColorsV2.textLink,
  completedProjectsBgHover: beamColorsV2.bannerBg,
  reminderBtnTimeline: beamColorsV2.textLink,
  investIcon: beamColorsV2.feedbacks.disableText,
  boxTableBanner: beamColorsV2.dark2,
  modalFilterText: undefined,
  borderLineCancel: undefined,
  promoCodeText: '#fafafab3',
  totalPriceText: undefined,
  bgInputAmountSaleCard: beamColorsV2.dark,
  styleCardBorder: beamColorsV2.dark2,
  bgRemindTimeline: beamColorsV2.feedbacks.infoBg,
  headerContainerBgSaleCard: `${beamColorsV2.dark}1F`,
}

export const blue: CommonTheme = {
  connectButtonBackground: blueColorsV2.gradients.green2lightblue,
  connectedButtonBackground: blueColorsV2.background,
  connectButtonInnerBackground: undefined,
  activeMenuTextColor: blueColorsV2.gradients.green2lightblue,
  profileAction: undefined,
  profileForeground: undefined,
  closeButtonBackground: undefined,
  modalBackground: undefined,

  tableDropdownBtnBackground: blueColorsV2.text,
  tableDropdownBtnColor: blueColorsV2.main,
  tableDropdownBtnBorder: blueColorsV2.light,
  tableDropdownArrowColor: blueColorsV2.text,
  selectorBackground: blueColorsV2.main,
  boxTableBackground: blueColorsV2.main,
  customButtonBackground: blueColorsV2.gradients.green2lightblue,

  upcomingProjectIconColor: lightColorsV2.textDisabled,
  projectCardShadow: 'drop-shadow(0px 0px 14px rgba(0, 36, 164, 0.24));',

  subscriptionNotStart: blueColorsV2.textDisabled,
  subscriptionStartNotEnd: blueColorsV2.main5,
  insctructionText: blueColorsV2.main5,
  pipeFooter: blueColorsV2.borderLine,
  kycStatusDescBg: lightColorsV2.dark2,
  saleEnds: blueColorsV2.main5,
  textDropDownMenu: blueColorsV2.textSecondary,
  stepsTimeLineActive: blueColorsV2.textThirdly,
  textBottomInfoCard: blueColorsV2.textSecondary,
  liveProjectText: blueColorsV2.text,
  liveProjectsQuestionIcon: blueColorsV2.textSecondary,
  stakeActionButton: blueColorsV2.textThirdly,
  actionButtonBorderNotStarted: blueColorsV2.light,
  saleInfoBorder: blueColorsV2.light,
  borderCompletedProjects: blueColorsV2.light,
  borderLeftAccordion: blueColorsV2.light,
  accSidebarBorder: blueColorsV2.dark2,
  allNetwroksText: blueColorsV2.textSecondary,
  projectTagText: blueColorsV2.main,
  boxTableRequirements: blueColorsV2.bannerBg,
  dropdownMenu: blueColorsV2.dark,
  notFoundPageBg: blueColorsV2.dark2,
  tagBgLiveProject: blueColorsV2.tagBg,
  totalStakePoolTextTimeLine: blueColorsV2.textDisabled,
  kycGetTokensBtn: blueColorsV2.textThirdly,
  kycStatusLink: blueColorsV2.main4,
  completedProjectsBgHover: blueColorsV2.tagText,
  reminderBtnTimeline: blueColorsV2.textSecondary,
  investIcon: '#A6A8AA',
  boxTableBanner: blueColorsV2.light,
  modalFilterText: undefined,
  borderLineCancel: undefined,
  promoCodeText: blueColorsV2.textThirdly,
  totalPriceText: undefined,
  bgInputAmountSaleCard: blueColorsV2.main,
  styleCardBorder: undefined,
  bgRemindTimeline: blueColorsV2.feedbacks.infoBg,
  headerContainerBgSaleCard: `${blueColorsV2.dark2}80`,
}

export const carv: CommonTheme = {
  connectButtonBackground: carvColorsV2.gradients.green2lightblue,
  connectedButtonBackground: carvColorsV2.background,
  connectButtonInnerBackground: undefined,
  activeMenuTextColor: carvColorsV2.gradients.green2lightblue,
  profileAction: undefined,
  profileForeground: undefined,
  closeButtonBackground: undefined,
  modalBackground: undefined,

  tableDropdownBtnBackground: carvColorsV2.text,
  tableDropdownBtnColor: carvColorsV2.main,
  tableDropdownBtnBorder: carvColorsV2.light,
  tableDropdownArrowColor: carvColorsV2.text,
  selectorBackground: carvColorsV2.main,
  boxTableBackground: carvColorsV2.main,
  customButtonBackground: carvColorsV2.gradients.green2lightblue,

  upcomingProjectIconColor: carvColorsV2.textDisabled,
  projectCardShadow: 'drop-shadow(0px 0px 14px rgba(0, 36, 164, 0.24));',

  subscriptionNotStart: carvColorsV2.textDisabled,
  subscriptionStartNotEnd: carvColorsV2.main5,
  insctructionText: carvColorsV2.main5,
  pipeFooter: carvColorsV2.light,
  kycStatusDescBg: carvColorsV2.dark2,
  saleEnds: carvColorsV2.main5,
  textDropDownMenu: carvColorsV2.textSecondary,
  stepsTimeLineActive: carvColorsV2.textThirdly,
  textBottomInfoCard: carvColorsV2.textSecondary,
  liveProjectText: carvColorsV2.text,
  liveProjectsQuestionIcon: carvColorsV2.textSecondary,
  stakeActionButton: carvColorsV2.textThirdly,
  actionButtonBorderNotStarted: carvColorsV2.light,
  saleInfoBorder: carvColorsV2.light,
  borderCompletedProjects: carvColorsV2.light,
  borderLeftAccordion: carvColorsV2.light,
  accSidebarBorder: carvColorsV2.dark2,
  allNetwroksText: carvColorsV2.textSecondary,
  projectTagText: carvColorsV2.main,
  boxTableRequirements: carvColorsV2.bannerBg,
  dropdownMenu: carvColorsV2.dark,
  notFoundPageBg: carvColorsV2.dark2,
  tagBgLiveProject: '#8A5AFF80',
  totalStakePoolTextTimeLine: carvColorsV2.text,
  kycGetTokensBtn: carvColorsV2.textThirdly,
  kycStatusLink: carvColorsV2.main4,
  completedProjectsBgHover: carvColorsV2.tagText,
  reminderBtnTimeline: carvColorsV2.secondary,
  investIcon: '#A6A8AA',
  boxTableBanner: carvColorsV2.light,
  modalFilterText: carvColorsV2.text,
  borderLineCancel: carvColorsV2.textThirdly,
  promoCodeText: carvColorsV2.secondary,
  totalPriceText: carvColorsV2.textInfo,
  bgInputAmountSaleCard: `#2A2732`,
  styleCardBorder: '#8a5aff80',
  bgRemindTimeline: carvColorsV2.main5,
  headerContainerBgSaleCard: carvColorsV2.dark2,
}

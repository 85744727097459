import { useState, useEffect, useContext } from 'react'
import { useAccount, useNetwork } from 'wagmi'
import MultiCallAbi from 'config/abi/Multicall.json'
import { getMulticallAddress } from 'utils/addressHelpers'
import BigNumber from 'bignumber.js'
import multicall from 'utils/multicall'
import { CompanyContext } from 'contexts/CompanyContext'

const useBNBBalance = () => {
  const [balance, setBalance] = useState(new BigNumber(0))
  const { address: account } = useAccount()
  const { isJustSale } = useContext(CompanyContext)
  const { chain } = useNetwork()
  const chainId = chain?.id

  useEffect(() => {
    const fetchAllBalances = async () => {
      const calls = [
        {
          address: getMulticallAddress(chainId),
          name: 'getEthBalance',
          params: [account],
        },
      ]

      const res = await multicall(MultiCallAbi, calls, chainId)
      setBalance(res)
    }

    if (account && !isJustSale) {
      fetchAllBalances()
    }
  }, [account, chainId])

  return balance
}

export default useBNBBalance

import { FarmConfig } from 'state/v2_types'
import { getCMSV1ApiUrl } from 'utils/getCMSApiUrl'
import { getWithExpiry, setWithExpiry } from 'utils/localStorageHelper'

export const FARM_STORAGE_KEY = 'FARMS_DATA'
const CACHE_VERSION = '1'

export const fetchFarmsConfig = async (): Promise<FarmConfig[]> => {
  let projects: FarmConfig[] = []

  // check local storage
  const cachedData = getWithExpiry(FARM_STORAGE_KEY, CACHE_VERSION)
  if (cachedData) {
    return cachedData
  }

  // fetch data from server
  try {
    const res = await fetch(`${getCMSV1ApiUrl()}/api/farms`)
    projects = await res.json()
    // save in local storage
    setWithExpiry(FARM_STORAGE_KEY, projects, 15 * 60 * 1000, CACHE_VERSION)
  } catch (e) {
    console.error('failed to fetch Farms data')
  }

  return projects
}

export default null

import React, { Fragment, useContext } from 'react'
import styled from 'styled-components'
import useTheme from 'hooks/useTheme'
import { useTranslation } from 'contexts/Localization'
import { Button, Flex, Toggle } from 'uikit'
import { RU, EN, FR } from 'config/localization/languages'
import useStagingIDO from 'hooks/useStagingIDO'
import { ThreeDotsIcon } from '../icons'
import ThemeSwitcher from './ThemeSwitcher'
import { DesktopLangSelector } from './LangSelector'
import { Brands, CompanyContext } from 'contexts/CompanyContext'

import { aethirLinks, links, roninLinks, beamLinks } from '../config'

const SettingsButton = styled(Button)`
  width: 34px;
  height: 34px;
  padding: 0;
  margin-left: 14px;
  border-radius: 5px;
  background: transparent;
  &:hover {
    background: ${({ theme }) => theme.colorsV2?.dark2};
  }
`

const SettingsDropdownContainer = styled.div`
  position: absolute;
  left: 68px;
  top: 34px;
  display: none;
  transform: translate(-100%, 0);
`

const SettingsDropdownContent = styled.div`
  margin-top: 12px;
  width: 276px;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => (theme.isColorOverride ? theme.colorsV2.dark : theme.common.dropdownMenu)};
  box-shadow: ${({ theme }) => theme.nav.dropdownShadow};
  border: ${({ theme }) => `1px solid ${theme.nav.dropdownBorder}`};
  backdrop-filter: blur(40px);
  padding: 20px 0;
  max-height: 600px;
  overflow-y: auto;
  z-index: ${({ theme }) => theme.zIndices?.dropdown};
  border-radius: 10px;

  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: normal;
  color: ${({ theme }) => theme.colorsV2?.text};
`

const Container = styled.div`
  position: relative;
  &:focus-within ${SettingsDropdownContainer} {
    display: block;
  }
`

const TextPrimary = styled.p`
  font-size: 14px;
  line-height: 28px;
`
const TextTitle = styled.p`
  font-size: 10px;
  margin-bottom: 5px;
  font-family: 'Acre';
  color: ${({ theme }) => theme.common.textDropDownMenu};
  padding: 0 20px;
`

const ExternalLinkWrap = styled.a`
  font-size: 14px;
  line-height: 30px;
  text-decoration: none;
`

const BreakWrapper = styled.div`
  padding: 0 20px;
`

const Break = styled.div`
  height: 0.5px;
  background-color: ${({ theme }) => theme.nav.dropdownBorder};
  margin: 20px 0;
`

const LinkItem = styled(Flex)`
  &:hover {
    background: ${({ theme }) => (theme.isDark ? theme.colorsV2.main2 : theme.colorsV2?.dark2)};
  }
  padding: 0 20px;
`

const languages = [EN, RU, FR]

const Settings: React.FC = () => {
  const { isDark, toggleTheme } = useTheme()
  const { currentLanguage, setLanguage } = useTranslation()
  const { isStagingIDO, toggleStagingIDO } = useStagingIDO()
  const { brand, moreLinks } = useContext(CompanyContext)
  const isRonin = brand === Brands.RONIN
  const isBeam = brand === Brands.BEAM
  const menuLinks = moreLinks

  return (
    <Container tabIndex={0}>
      <SettingsButton>
        <ThreeDotsIcon fillColor={isDark ? '#BDC2FF' : '#5A5A5AB2'} />
      </SettingsButton>

      <SettingsDropdownContainer>
        <SettingsDropdownContent>
          {!isRonin && brand !== Brands.BEAM && (
            <>
              <TextTitle style={{ marginBottom: 10 }}>Settings</TextTitle>
              {process.env.REACT_APP_ENABLE_STAGING_TOGGLE && (
                <Flex justifyContent="space-between" alignItems="center" marginBottom="10px" paddingX="20px">
                  <TextPrimary>Use staging IDO</TextPrimary>
                  <Toggle checked={isStagingIDO} scale="md" onChange={toggleStagingIDO} />
                </Flex>
              )}
              {process.env.REACT_APP_ENABLE_LIGHT_DARK && (
                <Flex justifyContent="space-between" alignItems="center" marginBottom="10px" paddingX="20px">
                  <TextPrimary>{isDark ? 'Dark' : 'Light'} Mode</TextPrimary>
                  <ThemeSwitcher isDark={isDark} toggleTheme={toggleTheme} />
                </Flex>
              )}
              {process.env.REACT_APP_ENABLE_LANGUAGE && (
                <Flex justifyContent="space-between" alignItems="center" paddingX="20px">
                  <TextPrimary>Language</TextPrimary>
                  <DesktopLangSelector setLang={setLanguage} currentLang={currentLanguage} langs={languages} />
                </Flex>
              )}
            </>
          )}

          {menuLinks
            .filter((item) => item?.label?.length > 0)
            .map(({ label, items }, index) => {
              return (
                <Fragment key={label}>
                  {!(isRonin || (isBeam && index === 0)) && (
                    <BreakWrapper>
                      <Break />
                    </BreakWrapper>
                  )}

                  <TextTitle>{label}</TextTitle>
                  {items.map((item) => {
                    return (
                      <ExternalLinkWrap href={item.href} target="_blank" key={item.href}>
                        <LinkItem key={item.href} justifyContent="space-between" alignItems="center">
                          {item.label}
                        </LinkItem>
                      </ExternalLinkWrap>
                    )
                  })}
                </Fragment>
              )
            })}
        </SettingsDropdownContent>
      </SettingsDropdownContainer>
    </Container>
  )
}

export default React.memo(Settings, () => true)

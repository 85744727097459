import React, { cloneElement, ElementType, isValidElement } from 'react'
import styled from 'styled-components'
import getExternalLinkProps from '../../util/getExternalLinkProps'
import { Flex } from '../Box'
import StyledButton from './StyledButton'
import { ButtonProps, scales, variants } from './types'

const StyledWrapper = styled(Flex)<{ hasLeftIcon: boolean }>`
  font-weight: inherit;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  flex-direction: row;
  align-items: center;
  ${({ theme }) => theme.mediaQueries?.sm} {
    ${({ hasLeftIcon }) => hasLeftIcon && 'margin-left: 0.75rem;'}
  }
`

const Button = <E extends ElementType = 'button'>(props: ButtonProps<E>): JSX.Element => {
  const { startIcon, endIcon, external, className, isLoading, disabled, children, ...rest } = props
  const internalProps = external ? getExternalLinkProps() : {}
  const isDisabled = isLoading || disabled
  const classNames = className ? [className] : []

  if (isLoading) {
    classNames.push('pancake-button--loading')
  }

  if (isDisabled && !isLoading) {
    classNames.push('pancake-button--disabled')
  }

  return (
    <StyledButton
      isLoading={isLoading}
      className={classNames.join(' ')}
      disabled={isDisabled}
      {...internalProps}
      {...rest}
    >
      <>
        <Flex position="absolute" left="10px">
          {isValidElement(startIcon) &&
            cloneElement(startIcon, {
              // @ts-ignore
              mr: '0.5rem',
            })}
        </Flex>
        {isValidElement(startIcon) ? (
          <StyledWrapper hasLeftIcon={isValidElement(startIcon)}>{children}</StyledWrapper>
        ) : (
          children
        )}

        {isValidElement(endIcon) &&
          cloneElement(endIcon, {
            // @ts-ignore
            ml: '0.5rem',
          })}
      </>
    </StyledButton>
  )
}

Button.defaultProps = {
  isLoading: false,
  external: false,
  variant: variants.PRIMARY,
  scale: scales.MD,
  disabled: false,
}

export default Button

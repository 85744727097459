import React, { useCallback } from 'react'
import { InjectedModalProps, RectangleWarning } from 'uikit'
import { useExpertModeManager } from 'state/user/hooks'
import { useTranslation } from 'contexts/Localization'
import ModalV2 from 'uikit/widgets/Modal/ModalV2'

interface ExpertModalProps extends InjectedModalProps {
  setShowConfirmExpertModal: (boolean) => void
}

const ExpertModal: React.FC<ExpertModalProps> = ({ setShowConfirmExpertModal }) => {
  const [, toggleExpertMode] = useExpertModeManager()
  const onDismiss = useCallback(() => {
    setShowConfirmExpertModal(false)
  }, [setShowConfirmExpertModal])

  const onConfirm = useCallback(() => {
    toggleExpertMode()
    onDismiss()
  }, [toggleExpertMode, onDismiss])

  const { t } = useTranslation()
  return (
    <ModalV2
      bodyTitle={t('Enabling Pro Mode')}
      bodySubtitle={t(
        'Enabling Pro Mode will turn off the “Confirmation Transaction” prompt, and could allow high slippage trades that might result in poor rates or lost funds.',
      )}
      bodyImage={<RectangleWarning width="72px" />}
      bodyImageMargin="0 0 41px 0"
      showCancel
      showConfirm
      onConfirm={onConfirm}
      onDismiss={onDismiss}
    />
  )
}

export default ExpertModal

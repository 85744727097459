import { Currency } from 'swap-sdk/entities/currency'
import { TokenClass } from 'swap-sdk/entities/token'

export function currencyId(currency: Currency): string {
  if (currency.isNative) return currency.symbol
  if (currency instanceof TokenClass) return currency.address
  throw new Error('invalid currency')
}

export default currencyId

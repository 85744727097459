import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = ({ fill = '#777777' }) => {
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.75533 0.537007C8.35662 0.0775086 7.64343 0.0775081 7.24472 0.537006L6.42464 1.48213C6.18188 1.76191 5.80435 1.88457 5.4435 1.80092L4.22451 1.51833C3.63187 1.38094 3.05488 1.80015 3.00241 2.40624L2.89447 3.6529C2.86252 4.02193 2.6292 4.34308 2.2881 4.4875L1.13581 4.97539C0.575599 5.21259 0.35521 5.89087 0.669012 6.41206L1.31446 7.48406C1.50552 7.8014 1.50552 8.19835 1.31446 8.51569L0.669012 9.5877C0.355211 10.1089 0.575598 10.7872 1.13581 11.0244L2.2881 11.5123C2.6292 11.6567 2.86252 11.9778 2.89447 12.3469L3.00241 13.5935C3.05488 14.1996 3.63186 14.6188 4.22451 14.4814L5.4435 14.1988C5.80435 14.1152 6.18188 14.2378 6.42464 14.5176L7.24472 15.4627C7.64343 15.9222 8.35662 15.9222 8.75533 15.4627L9.57541 14.5176C9.81817 14.2378 10.1957 14.1152 10.5565 14.1988L11.7755 14.4814C12.3682 14.6188 12.9452 14.1996 12.9976 13.5935L13.1056 12.3469C13.1375 11.9778 13.3708 11.6567 13.7119 11.5123L14.8642 11.0244C15.4245 10.7872 15.6448 10.1089 15.331 9.5877L14.6856 8.51569C14.4945 8.19835 14.4945 7.8014 14.6856 7.48406L15.331 6.41206C15.6448 5.89087 15.4245 5.21259 14.8642 4.97539L13.7119 4.4875C13.3708 4.34308 13.1375 4.02193 13.1056 3.6529L12.9976 2.40624C12.9452 1.80015 12.3682 1.38094 11.7755 1.51833L10.5565 1.80092C10.1957 1.88457 9.81817 1.76191 9.57541 1.48213L8.75533 0.537007ZM11.9266 6.11931C12.2467 5.78695 12.2467 5.24809 11.9266 4.91572C11.6065 4.58336 11.0875 4.58336 10.7674 4.91572L6.6007 9.24203L5.26567 7.6826C4.96492 7.33129 4.44682 7.29965 4.10848 7.61192C3.77013 7.92419 3.73966 8.46213 4.04041 8.81343L5.95297 11.0475C6.10295 11.2227 6.31588 11.3259 6.54153 11.3328C6.76718 11.3396 6.98557 11.2496 7.1452 11.0838L11.9266 6.11931Z"
        fill={fill}
      />
    </Svg>
  )
}

export default Icon

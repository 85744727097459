import networkModalOptions from 'config/constants/networkModalOptions'

export interface NetworkSelectorOption {
  iconSrc?: string
  name: string
  fullName?: string
  id: string
}

export const defaultNetworkSelectorOptions: NetworkSelectorOption[] = networkModalOptions.map((network) => ({
  ...network,
  id: network.chainID.toString(),
}))

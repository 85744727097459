import { useContext, useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { useAccount, useNetwork } from 'wagmi'
import { getBep20Contract } from 'utils/contractHelpers'
import { Address } from 'config/constants/types'
import { getAddress } from 'utils/addressHelpers'

import ERC20_ABI from 'config/abi/erc20.json'
import { multicallv2 } from 'utils/multicall'
import useWeb3 from './useWeb3'
import useRefresh from './useRefresh'

type Options = {
  resetWhenError?: boolean
  isPurchaseCard?: true
}

const useTokenBalance = (address: Address, options?: Options, overrideChainId?: number) => {
  const [balance, setBalance] = useState(new BigNumber(0))
  const { address: account } = useAccount()
  const { chain } = useNetwork()
  const chainId = chain?.id
  const targetChainId = overrideChainId || chainId
  const web3 = useWeb3()
  const { mediumRefresh } = useRefresh()

  useEffect(() => {
    const fetchBalance = async () => {
      if (!getAddress(address, targetChainId)) {
        console.error(`Fetching an undefined address on chain ${targetChainId}`)
        if (options?.resetWhenError) {
          setBalance(new BigNumber(0))
        }
        return
      }

      const contract = getBep20Contract(getAddress(address, targetChainId), web3)
      const res = await contract.methods.balanceOf(account).call()
      setBalance(new BigNumber(res))
    }

    if (web3 && account) {
      fetchBalance()
    }
  }, [account, address, web3, mediumRefresh, targetChainId])

  return balance
}

export const useTokenBalanceMultipleChain = (address: Address, chains: number[]) => {
  const [balance, setBalance] = useState(new BigNumber(0))
  const { address: account } = useAccount()
  const { mediumRefresh } = useRefresh()

  useEffect(() => {
    const fetchBalance = async () => {
      const balances = await Promise.allSettled(
        chains.map(async (chainId) => {
          if (!getAddress(address, chainId)) {
            return new BigNumber(0)
          }
          const tokenAddress = getAddress(address, chainId)
          const calls = [
            {
              address: tokenAddress,
              name: 'balanceOf',
              params: [account],
            },
          ]

          const [tokenBalance] = await multicallv2(ERC20_ABI, calls, chainId)
          return tokenBalance
        }),
      )

      const totalBalance = balances.reduce((total, result) => {
        if (result.status !== 'fulfilled') return total
        total = total.plus(result.value)
        return total
      }, new BigNumber(0))

      setBalance(totalBalance)
    }

    if (account) {
      fetchBalance()
    }
    // Need this because we need to pass chains as string to avoid infinite call
  }, [account, address, JSON.stringify(chains), mediumRefresh])

  return balance
}

export const useBurnedBalance = (tokenAddress: string) => {
  const [balance, setBalance] = useState(new BigNumber(0))
  const { slowRefresh } = useRefresh()
  const web3 = useWeb3()

  useEffect(() => {
    const fetchBalance = async () => {
      const contract = getBep20Contract(tokenAddress, web3)
      const res = await contract.methods.balanceOf('0x000000000000000000000000000000000000dEaD').call()
      setBalance(new BigNumber(res))
    }

    fetchBalance()
  }, [web3, tokenAddress, slowRefresh])

  return balance
}

export default useTokenBalance

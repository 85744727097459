import BigNumber from 'bignumber.js'
import erc20 from 'config/abi/erc20.json'
import masterchefABI from 'config/abi/masterchef.json'
import multicall from 'utils/multicall'
import { FarmConfig } from 'state/v2_types'
import IImpossiblePairABI from 'config/abi/IImpossiblePair.json'
import { convertFromWei } from 'utils/formatBalance'

const fetchFarms = async (farms: FarmConfig[]) => {
  // Only support BSC Mainnet for now
  const data = await Promise.allSettled(
    farms.map(async (farmConfig) => {
      const { masterChefContract, chainId, stakingToken } = farmConfig
      const stakingTokenAddress = stakingToken.address[chainId]
      const lpInformationCalls = [
        { address: stakingTokenAddress, name: 'token0' },
        { address: stakingTokenAddress, name: 'token1' },
      ]

      const [token0, token1] = await multicall(IImpossiblePairABI.abi, lpInformationCalls, chainId)
      const token0Address = token0[0]
      const token1Address = token1[0]
      const calls = [
        // Balance of token in the LP contract
        {
          address: token0Address,
          name: 'balanceOf',
          params: [stakingTokenAddress],
        },
        // Balance of quote token on LP contract
        {
          address: token1Address,
          name: 'balanceOf',
          params: [stakingTokenAddress],
        },
        // Balance of LP tokens in the master chef contract
        {
          address: stakingTokenAddress,
          name: 'balanceOf',
          params: [masterChefContract],
        },
        // Total supply of LP tokens
        {
          address: stakingTokenAddress,
          name: 'totalSupply',
        },
        // Token symbol
        {
          address: token0Address,
          name: 'symbol',
        },
        // Quote token symbol
        {
          address: token1Address,
          name: 'symbol',
        },
        // Token decimals
        {
          address: token0Address,
          name: 'decimals',
        },
        // Quote token decimals
        {
          address: token1Address,
          name: 'decimals',
        },
      ]

      const [
        token0BalanceLP,
        token1BalanceLP,
        lpTokenBalanceMC,
        lpTotalSupply,
        token0Symbol,
        token1Symbol,
        token0Decimals,
        token1Decimals,
      ] = await multicall(erc20, calls, chainId)
      // Ratio in % a LP tokens that are in staking, vs the total number in circulation
      const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(new BigNumber(lpTotalSupply))
      // Amount of token in the LP that are considered staking (i.e amount of token * lp ratio)
      const token0Amount = new BigNumber(token0BalanceLP).div(new BigNumber(10).pow(token0Decimals)).times(lpTokenRatio)
      const token1Amount = new BigNumber(token1BalanceLP).div(new BigNumber(10).pow(token1Decimals)).times(lpTokenRatio)

      const [rewardPerSecond, startTime, bonusEndTime] = await multicall(
        masterchefABI,
        [
          {
            address: masterChefContract,
            name: 'rewardPerSecond',
          },
          {
            address: masterChefContract,
            name: 'startTime',
          },
          {
            address: masterChefContract,
            name: 'bonusEndTime',
          },
        ],
        chainId,
      )

      const startTimeInMillis = parseInt(startTime, 10) * 1000
      const endTimeInMillis = parseInt(bonusEndTime, 10) * 1000
      return {
        ...farmConfig,
        rewardPerSecond: convertFromWei(new BigNumber(rewardPerSecond)).toJSON(),
        token0: { address: { [chainId]: token0Address }, decimals: token0Decimals, symbol: String(token0Symbol) },
        token1: { address: { [chainId]: token1Address }, decimals: token1Decimals, symbol: String(token1Symbol) },
        token0Amount: token0Amount.toJSON(),
        token1Amount: token1Amount.toJSON(),
        startTime: startTimeInMillis,
        endTime: endTimeInMillis,
        lpTotalSupply: convertFromWei(new BigNumber(lpTotalSupply)).toJSON(),
        lpTokenBalanceMC: convertFromWei(new BigNumber(lpTokenBalanceMC)).toJSON(),
      }
    }),
  )
  return data.filter((v) => v.status === 'fulfilled').map((v) => (v as PromiseFulfilledResult<any>).value)
}

export default fetchFarms

import { getCMSApiUrl, getCMSV1ApiUrl } from 'utils/getCMSApiUrl'

export const fetchDefaultWhitelistedList = async (): Promise<string[]> => {
  try {
    const whitelistedList = await (
      await fetch(`/json/kycedAddresses.json`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Cache-Control': 'no-cache',
        },
      })
    ).json()

    return whitelistedList
  } catch (e) {
    return []
  }
}

export const fetchMerkleProof = async (account: string, whitelistedUrl: string): Promise<string[]> => {
  try {
    const merkleProof = await (
      await fetch(`${getCMSV1ApiUrl()}/api/merkleProof?account=${account}&whitelistedUrl=${whitelistedUrl}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Cache-Control': 'no-cache',
        },
      })
    ).json()
    return merkleProof
  } catch (e) {
    throw Error('Oops! We are unable to get the merkle proof, please try again!')
  }
}

export const fetchMerkleProofV2 = async (account: string, saleId: string): Promise<string[]> => {
  try {
    const merkleProof = await (
      await fetch(`${getCMSApiUrl()}/api/backend-service/merkle/proof?address=${account}&saleId=${saleId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Cache-Control': 'no-cache',
        },
      })
    ).json()
    return merkleProof.data
  } catch (e) {
    throw Error('Oops! We are unable to get the merkle proof, please try again!')
  }
}

export default null

import React from 'react'
import styled from 'styled-components'
import useTheme from 'hooks/useTheme'
import { useMatchBreakpoints } from 'uikit'
import { SvgProps } from '../../../components/Svg'
import Flex from '../../../components/Box/Flex'
import Button from '../../../components/Button/Button'
import * as IconModule from '../icons'

const Icons = IconModule as unknown as { [key: string]: React.FC<SvgProps> }
const { MoonIcon, SunIcon } = Icons

interface Props {
  isDark: boolean
  toggleTheme: (isDark: boolean) => void
}

const ToggleButton = styled(Button)<{ isMobile: boolean }>`
  width: 58px;
  height: 32px;
  background: ${({ theme, isMobile }) =>
    theme.isDark ? (isMobile ? theme.colorsV2?.dark2 : theme.colorsV2?.main) : theme.colorsV2?.dark2};
  border: ${({ theme }) => `1px solid ${theme.isDark ? 'transparent' : theme.colorsV2?.light}`};
  box-sizing: border-box;
  border-radius: 20px;
`

const Circle = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  padding: 2.5px;
  background-color: ${({ theme }) => theme.colorsV2?.light};
`

const ThemeSwitcher: React.FC<Props> = ({ isDark, toggleTheme }) => {
  const { theme } = useTheme()
  const { isXl } = useMatchBreakpoints()
  const isMobile = isXl === false
  return (
    <ToggleButton variant="text" onClick={() => toggleTheme(!isDark)} isMobile={isMobile}>
      {/* alignItems center is a Safari fix */}
      <Flex alignItems="center">
        <Circle style={{ background: isDark ? '#2063E2' : 'transparent' }}>
          <MoonIcon fillColor={isDark ? 'white' : theme.colorsV2?.textSecondary} />
        </Circle>
        <Circle style={{ background: isDark ? 'transparent' : '#F5B452' }}>
          <SunIcon fillColor={isDark ? theme.colorsV2?.light : '#fff'} />
        </Circle>
      </Flex>
    </ToggleButton>
  )
}

export default React.memo(ThemeSwitcher, (prev, next) => prev.isDark === next.isDark)

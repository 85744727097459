import { useTranslation } from 'contexts/Localization'
import React, { useMemo } from 'react'
import WarningSquareIcon from 'componentsV2/Icons/WarningSquare'
import styled from 'styled-components'
import StyledCard from './StyledCard'

const Body = styled.div`
  background: ${({ theme }) => (theme.isDark ? `${theme.colorsV2?.textThirdly}0D` : theme.colorsV2?.dark2)};
  border-radius: 5px;
  padding: 10px 17.5px;
  margin-top: 20px;
  font-size: 11px;
  text-align: center;
  color: ${({ theme }) => (theme.isDark ? theme.colorsV2?.textThirdly : theme.colorsV2?.text)};
`

const RestrictedCountriesCard: React.FC = () => {
  const { t } = useTranslation()

  const title = t('Regions ineligible to participate this project')
  const imageInfo = useMemo(() => {
    return {
      image: <WarningSquareIcon />,
    }
  }, [])

  const subtitleInfo = useMemo(() => {
    return {
      subtitle: t(
        'Citizens and residents of jurisdictions, countries, and regions, including the US, Mainland China, Hong Kong, Macau, Taiwan, and UN-sanctioned jurisdictions are excluded from participation.',
      ),
    }
  }, [t])
  return (
    <StyledCard title={title} imageInfo={imageInfo} subtitleInfo={subtitleInfo} hidePostSaleInfo>
      <Body>{t('We apologize for any inconvenience and disappointment.')}</Body>
    </StyledCard>
  )
}

export default RestrictedCountriesCard

import BigNumber from 'bignumber.js'
import { useTranslation } from 'contexts/Localization'
import React, { useCallback, useState } from 'react'
import { useUnstake } from 'hooks/useIFAMaster'
import { Button, useMatchBreakpoints } from 'uikit'
import styled from 'styled-components'
import IFTypography from 'componentsV2/IFTypography/IFTypography'
import WhiteCircleLoader from 'componentsV2/Loader/WhiteCircleLoader'
import { getBalanceInWei } from 'utils/formatBalance'
import { setupNetwork } from 'utils/wallet'
import { Box } from '@mui/material'
import { useNetwork } from 'wagmi'
import ImageWithNetwork from 'componentsV2/ImageWithNetwork'

import { IdleSale } from '../utils'

interface UnstakeIdleModalProps {
  sale: IdleSale
}
const UnstakeRowBox = styled(Box)`
  background-color: ${({ theme }) => (theme.isDark ? theme.colorsV2?.light : theme.colorsV2.dark)};
  border-radius: 20px;
  padding: 15px 20px;
  margin-top: 10px;
  :hover {
    background-color: ${({ theme }) => theme.colorsV2?.dark2};
  }
`

const ProjectTitle = styled(Box)`
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
`

const UnstakeRow: React.FC<UnstakeIdleModalProps> = ({ sale }) => {
  const { t } = useTranslation()
  const { masterAddress, trackID, projectImage, chainId: saleChainId, projectSubtitle, projectTitle, staked } = sale
  const [buttonSuccess, setButtonSuccess] = useState(false)
  const { chain } = useNetwork()
  const chainId = chain?.id
  const { onUnstake, isLoading } = useUnstake(masterAddress, parseInt(trackID, 10))
  const { isXl } = useMatchBreakpoints()
  const isMobile = !isXl

  const onUnstakeSuccess = useCallback(() => {
    setButtonSuccess(true)
  }, [])

  const renderButton = useCallback(() => {
    if (chainId !== saleChainId) {
      return (
        <Button scale="xs" variant="main2" onClick={() => setupNetwork(saleChainId)}>
          {t('Switch Network')}
        </Button>
      )
    }
    if (isLoading) {
      return (
        <Button scale="xs" variant="main2">
          <WhiteCircleLoader />
        </Button>
      )
    }
    if (buttonSuccess) {
      return (
        <Button scale="xs" variant="success">
          {t('Success')}
        </Button>
      )
    }
    return (
      <Button
        scale="sm"
        onClick={() => onUnstake(getBalanceInWei(new BigNumber(staked)), onUnstakeSuccess)}
        variant="main2"
      >
        {t('Unstake')}
      </Button>
    )
  }, [chainId, isLoading, buttonSuccess, onUnstake, onUnstakeSuccess, saleChainId, staked, t])

  return (
    <UnstakeRowBox>
      <Box display="flex" justifyContent={isMobile ? 'left' : 'space-between'} alignItems="center">
        <Box>
          <ImageWithNetwork
            alt="icon"
            src={projectImage}
            chainId={saleChainId}
            width="26px"
            height="26px"
            chainWidth="16px"
            showBorder={false}
            chainMarginLeft={-3}
          />
        </Box>
        <Box flex="0 0 128px" maxWidth="128px" style={{ overflowWrap: 'break-word' }} marginLeft={4}>
          <ProjectTitle>
            <IFTypography variant="body2" ifcolor="text" fontWeight="bold">
              {projectTitle}
            </IFTypography>
          </ProjectTitle>
          <Box>
            <IFTypography variant="body2" ifcolor="textSecondary">
              {projectSubtitle}
            </IFTypography>
          </Box>
        </Box>
        <Box marginLeft={isMobile ? 'auto' : 2}>
          <IFTypography variant="body1" fontWeight="bold" ifcolor="text">
            {staked.toFixed(2)}
          </IFTypography>
        </Box>
        {!isMobile && (
          <Box marginLeft="auto" textAlign="right">
            {renderButton()}
          </Box>
        )}
      </Box>
      {isMobile && <Box textAlign="right">{renderButton()}</Box>}
    </UnstakeRowBox>
  )
}

export default UnstakeRow

import { useEffect } from 'react'
import { connectorLocalStorageKey, ConnectorNames } from 'uikit'
import useAuth from 'hooks/useAuth'
import { useAppStateUnavailableNetwork } from 'state/hooks'

const useEagerConnect = () => {
  const { login } = useAuth()
  const isUnavailable = useAppStateUnavailableNetwork()

  useEffect(() => {
    const connectorId = window.localStorage.getItem(connectorLocalStorageKey) as ConnectorNames
    if (connectorId && !isUnavailable) {
      login(connectorId)
    }
  }, [login, isUnavailable])
}

export default useEagerConnect

import React from 'react'
import styled from 'styled-components'
import { RectangleWarning } from 'uikit'
import { setupNetwork } from 'utils/wallet'

import { useTranslation } from 'contexts/Localization'
import Text from '../../components/Text/Text'
import Flex from '../../components/Box/Flex'
import ModalV2 from '../Modal/ModalV2'
import { Network } from './types'

interface Props {
  onDismiss?: () => void
  networks?: Network[]
}

const NetworkBox = styled(Flex)`
  width: 100%;
  height: 59px;
  background: ${({ theme }) => (theme.isDark ? theme.colorsV2?.light : theme.colorsV2.dark)};
  color: ${({ theme }) => theme.colorsV2?.textSecondary};
  border-radius: 20px;
  padding: 8px 24px;
  :hover {
    background: ${({ theme }) => (theme.isDark ? theme.colorsV2?.dark2 : theme.colorsV2?.light)};
  }
`

const UnsupportedModal: React.FC<Props> = ({ onDismiss = () => null, networks = [] }) => {
  const { t } = useTranslation()

  return (
    <ModalV2
      onDismiss={onDismiss}
      bodyPadding="0px 24px 24px 24px"
      bodyTitle={t('Network Unavailable')}
      bodyImage={<RectangleWarning width="90px" />}
      bodyDescription={t('Please connect to one of our supported networks.')}
      bodyImageMargin="0 0 23px 0"
    >
      {networks.length > 0 && (
        <Flex mt="42px" flexDirection="column">
          {networks.map((network, index) => {
            return (
              <NetworkBox
                style={{ cursor: 'pointer' }}
                alignItems="center"
                marginTop={index > 0 ? '12px' : '0px'}
                onClick={() => {
                  setupNetwork(network?.chainID)
                  onDismiss()
                }}
              >
                <img width="32px" alt={network.chainID.toString()} src={network.iconSrc} />
                <Text fontSize="16px" marginLeft="10px" fontWeight={700} color="textSecondary">
                  {network.fullName || network.name}
                </Text>
              </NetworkBox>
            )
          })}
        </Flex>
      )}
    </ModalV2>
  )
}

export default UnsupportedModal

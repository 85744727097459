import { useEffect, useMemo } from 'react'
import { kebabCase } from 'lodash'
import { Toast, toastTypes } from 'uikit'
import { useSelector, useDispatch } from 'react-redux'
import useRefresh from 'hooks/useRefresh'
import { ToastAction } from 'uikit/widgets/Toast/types'
import {
  fetchFarmsPublicDataAsync,
  fetchProjectsDataAsync,
  push as pushToast,
  remove as removeToast,
  clear as clearToast,
} from './actions'
import { State } from './types'
import { useBlockInitialised } from './block/hooks'
import { useIdosInitiated, useProjectsInitiated } from './idos/hooks'

export const useFetchPublicData = () => {
  const dispatch = useDispatch()
  const { slowRefresh } = useRefresh()
  const dataInitialised = useIdosInitiated()
  const blockInitialised = useBlockInitialised()
  const projectsInitialized = useProjectsInitiated()

  // useEffect(() => {
  //   dispatch(fetchFarmsPublicDataAsync())
  // }, [dispatch, slowRefresh])

  useEffect(() => {
    if (projectsInitialized) {
      dispatch(fetchProjectsDataAsync())
    }
  }, [dispatch, dataInitialised, blockInitialised, projectsInitialized, slowRefresh])
}

// Toasts
export const useToast = () => {
  const dispatch = useDispatch()
  const helpers = useMemo(() => {
    const push = (toast: Toast) => dispatch(pushToast(toast))

    return {
      toastError: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.DANGER, title, description })
      },
      toastInfo: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.INFO, title, description })
      },
      toastSuccess: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.SUCCESS, title, description })
      },
      toastErrorV2: (title: string, description?: string) => {
        // TODO: Create a better helper function for this
        let descriptionOverride = description
        let titleOverride = title
        if (title?.indexOf('underpriced') > -1 || description?.indexOf('underpriced') > -1) {
          titleOverride = 'Transaction Failed: Insufficient Gas Price'
          descriptionOverride =
            'The transaction was not successful due to an insufficient gas price. To complete the transaction, you can try increasing the gas price in your wallet settings.'
        }
        if (title?.indexOf('overpriced') > -1 || description?.indexOf('overpriced') > -1) {
          titleOverride = 'Transaction Failed: Gas Overpriced'
          descriptionOverride =
            'The transaction was not successful due to an overpriced gas fee. To complete the transaction, you can try adjusting the gas price in your wallet settings.'
        }
        return push({
          id: kebabCase(title),
          type: toastTypes.DANGER_V2,
          title: titleOverride,
          description: descriptionOverride,
        })
      },
      toastSuccessV2: (title: string, description?: string, action?: ToastAction) => {
        return push({
          id: kebabCase(title),
          type: toastTypes.SUCCESS_V2,
          title,
          description,
          action,
        })
      },
      toastInfoV2: (title: string, description?: string, toastIcon?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.INFO_V2, title, description, toastIcon })
      },
      toastCustom: (
        title: string,
        action: any,
        description?: string,
        toastBackground?: string,
        toastIcon?: string,
        toastBorder?: string,
      ) => {
        return push({
          id: kebabCase(title),
          type: toastTypes.CUSTOM,
          title,
          description,
          action,
          alwaysShow: true,
          toastBackground,
          toastIcon,
          toastBorder,
        })
      },
      toastAnnouncement: (title: string, action: any, description?: string, toastBackground?: string) => {
        return push({
          id: kebabCase(title),
          type: toastTypes.ANNOUNCEMENT,
          title,
          description,
          action,
          alwaysShow: true,
          toastBackground,
        })
      },
      toastWarning: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.WARNING, title, description })
      },
      push,
      remove: (id: string) => dispatch(removeToast(id)),
      clear: () => dispatch(clearToast()),
    }
  }, [dispatch])

  return helpers
}

// App
export const useAppStateUnavailableNetwork = (): boolean => {
  const isUnavailable = useSelector((state: State) => state.app.isNetworkUnavailable)
  return isUnavailable
}

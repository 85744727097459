import React from 'react'
import { Box } from '@mui/material'
import styled from 'styled-components'
import { useMatchBreakpoints } from 'uikit'
import { SouthArrow } from '../Icons'
import IFTypography from '../IFTypography/IFTypography'

type CurrencyConfirmationProps = {
  firstImageSrc: string
  firstTokenName: string
  firstValue: string
  secondImageSrc: string
  secondTokenName: string
  secondValue: string
}

const ConfirmBox = styled(Box)`
  background: ${({ theme }) => (theme.isDark ? theme.colorsV2?.light : theme.colorsV2?.dark2)};#f2f2f2;
  border-radius: 5px;
  padding: 10px;
`

const CurrencyConfirmation: React.FC<CurrencyConfirmationProps> = ({
  firstImageSrc,
  firstTokenName,
  firstValue,
  secondImageSrc,
  secondTokenName,
  secondValue,
}) => {
  const { isXl } = useMatchBreakpoints()
  const isMobile = !isXl

  return (
    <Box>
      <ConfirmBox display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center">
          <img width={isMobile ? '24px' : '32px'} src={firstImageSrc} alt={firstTokenName} />
          <Box marginLeft="10px">
            <IFTypography variant="h3" fontWeight="regular">
              {firstTokenName}
            </IFTypography>
          </Box>
        </Box>
        <IFTypography variant="h3" fontWeight="regular">
          {firstValue}
        </IFTypography>
      </ConfirmBox>
      <Box marginY="8px" textAlign="center">
        <SouthArrow />
      </Box>
      <ConfirmBox display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center">
          <img width={isMobile ? '24px' : '32px'} src={secondImageSrc} alt={secondTokenName} />
          <Box marginLeft="10px">
            <IFTypography variant="h3" fontWeight="regular">
              {secondTokenName}
            </IFTypography>
          </Box>
        </Box>
        <IFTypography variant="h3" fontWeight="regular">
          {secondValue}{' '}
        </IFTypography>
      </ConfirmBox>
    </Box>
  )
}

export default CurrencyConfirmation

import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M10 5.13397C10.6667 5.51888 10.6667 6.48113 10 6.86603L1.75 11.6292C1.08333 12.0141 0.249999 11.5329 0.249999 10.7631L0.25 1.23686C0.25 0.467059 1.08333 -0.0140658 1.75 0.370834L10 5.13397Z" />
    </Svg>
  )
}

export default Icon

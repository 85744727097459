import React from 'react'
import styled from 'styled-components'

const AddTokenSVG = styled.svg`
  fill: ${({ theme }) => theme.colorsV2?.text};
`

const TextColorSVG = styled.svg`
  fill: ${({ theme }) => theme.colorsV2?.text};
`

const InvertedTextColorSVG = styled.svg`
  fill: ${({ theme }) => (theme.isDark ? theme.colorsV2?.text : theme.colorsV2.main)};
`

const ProjectLinkSVG = styled.svg`
  fill: ${({ theme }) => theme.colorsV2?.textSecondary};
  :hover {
    fill: ${({ theme }) => theme.colorsV2?.text};
  }
`
const OpenIconSVG = styled.svg`
  fill: #fafafa;
`

const BreadcrumbArrowSVG = styled.svg`
  fill: ${({ theme }) => (theme.isDark ? theme.colorsV2.button.disabledText : theme.colorsV2?.textDisabled)};
`

export const GasIcon = () => (
  <TextColorSVG width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.4219 5.10966L19.4143 3.13492C19.2035 2.72173 18.6976 2.55757 18.2843 2.76844C17.8711 2.97927 17.707 3.48522 17.9178 3.89845L18.6042 5.24376C17.5056 5.60852 16.7108 6.64511 16.7108 7.86457V9.9246C16.7108 10.2725 16.9928 10.5546 17.3408 10.5546H18.8336V12.6651C18.8336 13.6019 18.0715 14.3641 17.1347 14.3641H16.112V3.5479C16.112 2.6944 15.4177 2 14.5642 2H7.13549C6.06006 2 5.18512 2.8749 5.18512 3.95033V18.1235C4.50609 18.2872 4 18.8995 4 19.6281V20.564C4 20.912 4.28206 21.194 4.62999 21.194H16.6672C17.0151 21.194 17.2972 20.912 17.2972 20.564V19.6281C17.2972 18.8995 16.791 18.2872 16.112 18.1235V16.044H17.1347C18.9978 16.044 20.5136 14.5282 20.5136 12.665V5.49141C20.5136 5.35866 20.4822 5.22783 20.4219 5.10966ZM13.4484 8.27218H7.84875C7.55877 8.27218 7.32372 8.03713 7.32372 7.74716V4.68646C7.32372 4.39648 7.55877 4.16143 7.84875 4.16143H13.4484C13.7384 4.16143 13.9735 4.39648 13.9735 4.68646V7.7472C13.9735 8.03713 13.7384 8.27218 13.4484 8.27218Z" />
  </TextColorSVG>
)

export const OpenLinkIcon = () => (
  <InvertedTextColorSVG width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.375 1.10878C0.375 0.743332 0.671257 0.447075 1.03671 0.447075H4.25787C4.62332 0.447075 4.91958 0.743332 4.91958 1.10878C4.91958 1.47423 4.62332 1.77049 4.25787 1.77049H1.69842V9.73018H9.52302V7.26626C9.52302 6.90081 9.81927 6.60455 10.1847 6.60455C10.5502 6.60455 10.8464 6.90081 10.8464 7.26626V10.3919C10.8464 10.7573 10.5502 11.0536 10.1847 11.0536H1.03671C0.671257 11.0536 0.375 10.7573 0.375 10.3919V1.10878Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9319 5.13243C10.5665 5.12963 10.2725 4.83111 10.2753 4.46567L10.2893 2.63666L9.34561 1.7087L7.54907 1.71794C7.18362 1.71982 6.88585 1.42509 6.88397 1.05964C6.88209 0.694195 7.17682 0.396418 7.54226 0.394539L10.9599 0.376962C11.1369 0.376052 11.3068 0.446063 11.4318 0.571355C11.5568 0.696646 11.6263 0.866776 11.625 1.04374L11.5987 4.47582C11.5959 4.84126 11.2973 5.13523 10.9319 5.13243Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2893 2.63666L6.96665 5.93674C6.70736 6.19427 6.28839 6.19284 6.03086 5.93355C5.77333 5.67426 5.77476 5.25529 6.03405 4.99776L9.34561 1.7087L10.2893 2.63666Z"
    />
  </InvertedTextColorSVG>
)

export const AddIcon = () => (
  <InvertedTextColorSVG width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.25 6C11.25 6.41421 10.9142 6.75 10.5 6.75H6.75V10.5C6.75 10.9142 6.41421 11.25 6 11.25C5.58579 11.25 5.25 10.9142 5.25 10.5V6.75H1.5C1.08579 6.75 0.75 6.41421 0.75 6C0.75 5.58579 1.08579 5.25 1.5 5.25H5.25V1.5C5.25 1.08579 5.58579 0.75 6 0.75C6.41421 0.75 6.75 1.08579 6.75 1.5V5.25H10.5C10.9142 5.25 11.25 5.58579 11.25 6Z" />
  </InvertedTextColorSVG>
)

export const DollarIcon = () => (
  <AddTokenSVG width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM11.0718 16.7534V15.6278C14.7498 15.6503 15.5576 9.90657 11.8057 9.17608L8.54772 8.60542C7.38423 8.36905 7.86098 6.55126 8.92913 6.62442H11.359C11.7404 6.62442 12.0961 6.83941 12.2878 7.18721C12.5825 7.72522 13.2371 7.90982 13.7513 7.59804C14.2624 7.28626 14.4381 6.59854 14.1413 6.06052C13.5681 5.01937 12.501 4.37217 11.3579 4.37217H11.0708V3.2466C11.0654 1.77549 8.93449 1.77437 8.92806 3.2466V4.37217C5.25008 4.34966 4.44227 10.0945 8.19418 10.825L11.4522 11.3957C12.6167 11.6321 12.1389 13.4498 11.0708 13.3767H8.64093C8.25953 13.3767 7.90384 13.1617 7.71206 12.8128C7.41744 12.2759 6.76177 12.089 6.24859 12.4031C5.73755 12.7137 5.56184 13.4014 5.85861 13.9406C6.43179 14.9817 7.49886 15.6278 8.642 15.6278H8.92913V16.7534C8.93449 18.2245 11.0665 18.2256 11.0718 16.7534Z"
    />
  </AddTokenSVG>
)

export const OmniIcon = () => (
  <TextColorSVG width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1445 22.2891C17.7472 22.2891 22.2891 17.7472 22.2891 12.1446C22.2891 6.54176 17.7472 2 12.1445 2C6.54192 2 2 6.54176 2 12.1446C2 13.7436 2.36989 15.2559 3.02888 16.6013L5.57637 14.0536C5.40067 13.4477 5.30632 12.8072 5.30632 12.1446C5.30632 8.36798 8.36794 5.30636 12.1445 5.30636C15.9211 5.30636 18.9828 8.36798 18.9828 12.1446C18.9828 15.9212 15.9211 18.9826 12.1445 18.9826C11.4914 18.9826 10.8598 18.8911 10.2617 18.7203L7.71057 21.2714C9.05006 21.9233 10.5547 22.2891 12.1445 22.2891Z"
    />
    <path d="M7.69734 19.1741C6.98418 19.8873 5.82811 19.8873 5.11495 19.1741C4.40179 18.461 4.40179 17.3049 5.11495 16.5918C5.82811 15.8786 6.98418 15.8786 7.69734 16.5918C8.4105 17.3049 8.4105 18.461 7.69734 19.1741Z" />
    <path d="M14.783 12.1447C14.783 13.6012 13.6021 14.7822 12.1454 14.7822C10.6888 14.7822 9.50781 13.6012 9.50781 12.1447C9.50781 10.6879 10.6888 9.50708 12.1454 9.50708C13.6021 9.50708 14.783 10.6879 14.783 12.1447Z" />
  </TextColorSVG>
)

export const ArbIcon = () => (
  <TextColorSVG width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 1622">
      <path
        id="Vector"
        d="M19.6574 15.2345L15.1421 8.2937L13.4336 11.0243L17.8116 18.2397L19.7337 17.3244L19.6574 15.2345Z"
      />
      <path
        id="Vector_2"
        d="M17.4617 19.0331L12.9311 12.0923L10.9785 15.189L14.6243 21.0009L16.943 20.0704L17.4617 19.0331Z"
      />
      <path
        id="Vector_3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.49602 7.21077L10.9623 2.34458L19.4286 7.21077V16.9584L10.9623 21.8399L4.75369 18.2551L3.92995 19.6127L10.7335 23.5331C10.8708 23.6094 11.0538 23.6094 11.1911 23.5331L20.771 18.011C20.9082 17.9347 20.9998 17.7822 20.9998 17.6144V6.58534C20.9998 6.41754 20.9082 6.26499 20.771 6.18872L11.1911 0.666579C11.0538 0.590307 10.8708 0.590307 10.7335 0.666579L1.15362 6.18872C1.01633 6.26499 0.924805 6.41754 0.924805 6.58534V17.6144C0.924805 17.7822 1.01633 17.9347 1.15362 18.011L3.07569 19.1093L3.92995 17.7669L2.51128 16.9584V7.21077H2.49602Z"
      />
      <path id="Vector_4" d="M14.639 7.31738L11.3745 7.36315L3.91504 19.6125L6.55407 21.138L14.639 7.31738Z" />
      <path
        id="Vector_5"
        d="M6.52382 8.43136C7.2713 7.211 9.40693 7.211 10.3832 7.3788L3.06104 19.1095C2.81697 18.9722 2.19154 18.6061 1.71865 18.3315C1.09321 17.9654 0.986429 17.9654 0.910156 17.6298V16.9739C2.46612 14.64 5.77635 9.65172 6.52382 8.43136Z"
      />
    </g>
  </TextColorSVG>
)

export const LineaIcon = () => (
  <TextColorSVG width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Clip path group">
      <mask id="mask0_190_3781" maskUnits="userSpaceOnUse" x="0" y="0" width="19" height="20">
        <g id="clip0_2303_633">
          <path id="Vector" d="M18.9998 0.144531H0.129883V19.8555H18.9998V0.144531Z" />
        </g>
      </mask>
      <g mask="url(#mask0_190_3781)">
        <g id="Group">
          <path id="Vector_2" d="M15.8014 19.8557H0.129883V3.34326H3.71554V16.6555H15.8014V19.854V19.8557Z" />
          <path
            id="Vector_3"
            d="M15.8 6.5414C17.5664 6.5414 18.9984 5.10943 18.9984 3.34298C18.9984 1.57652 17.5664 0.144531 15.8 0.144531C14.0334 0.144531 12.6016 1.57652 12.6016 3.34298C12.6016 5.10943 14.0334 6.5414 15.8 6.5414Z"
          />
        </g>
      </g>
    </g>
  </TextColorSVG>
)

export const AddTokenIcon = () => (
  <AddTokenSVG width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.4993 4.375C12.1542 4.375 11.8743 4.65482 11.8743 5C11.8743 5.34518 12.1542 5.625 12.4993 5.625H16.2494C16.3644 5.625 16.4577 5.71827 16.4577 5.83333V7.70833H17.4994C17.5713 7.70833 17.6411 7.71745 17.7077 7.73459V5.83333C17.7077 5.02792 17.0548 4.375 16.2494 4.375H12.4993Z"
      fillOpacity="0.7"
    />
    <path
      d="M17.7077 12.6821C17.6411 12.6992 17.5713 12.7083 17.4994 12.7083H16.4577V14.5833C16.4577 14.6984 16.3644 14.7917 16.2494 14.7917H4.99935C4.88429 14.7917 4.79102 14.6984 4.79102 14.5833V13.75C4.79102 13.4048 4.51119 13.125 4.16602 13.125C3.82084 13.125 3.54102 13.4048 3.54102 13.75V14.5833C3.54102 15.3887 4.19393 16.0417 4.99935 16.0417H16.2494C17.0548 16.0417 17.7077 15.3887 17.7077 14.5833V12.6821Z"
      fillOpacity="0.7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0827 8.95833H14.166C13.4757 8.95833 12.916 9.51798 12.916 10.2083C12.916 10.8987 13.4757 11.4583 14.166 11.4583H17.0827V8.95833ZM14.166 7.70833C12.7853 7.70833 11.666 8.82762 11.666 10.2083C11.666 11.589 12.7853 12.7083 14.166 12.7083H17.4994C17.9596 12.7083 18.3327 12.3352 18.3327 11.875V8.54167C18.3327 8.08143 17.9596 7.70833 17.4994 7.70833H14.166Z"
      fillOpacity="0.7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.41732 9.99967C7.25827 9.99967 8.75065 8.50729 8.75065 6.66634C8.75065 4.82539 7.25827 3.33301 5.41732 3.33301C3.57637 3.33301 2.08398 4.82539 2.08398 6.66634C2.08398 8.50729 3.57637 9.99967 5.41732 9.99967ZM5.41732 11.2497C7.94862 11.2497 10.0007 9.19765 10.0007 6.66634C10.0007 4.13504 7.94862 2.08301 5.41732 2.08301C2.88601 2.08301 0.833984 4.13504 0.833984 6.66634C0.833984 9.19765 2.88601 11.2497 5.41732 11.2497Z"
      fillOpacity="0.7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.33398 6.66634C3.33398 6.32116 3.61381 6.04134 3.95898 6.04134H6.87565C7.22083 6.04134 7.50065 6.32116 7.50065 6.66634C7.50065 7.01152 7.22083 7.29134 6.87565 7.29134H3.95898C3.61381 7.29134 3.33398 7.01152 3.33398 6.66634Z"
      fillOpacity="0.7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.41732 4.58301C5.7625 4.58301 6.04232 4.86283 6.04232 5.20801V8.12467C6.04232 8.46985 5.7625 8.74967 5.41732 8.74967C5.07214 8.74967 4.79232 8.46985 4.79232 8.12467V5.20801C4.79232 4.86283 5.07214 4.58301 5.41732 4.58301Z"
      fillOpacity="0.7"
    />
  </AddTokenSVG>
)
export const SiteLinkIcon = () => (
  <ProjectLinkSVG width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.3753 8.125C16.5181 4.14375 14.69 1.625 12.9992 1.625C11.3084 1.625 9.48023 4.14375 8.62305 8.125H17.3753Z" />
    <path d="M8.125 13C8.1248 14.087 8.19727 15.1727 8.34194 16.25H17.6581C17.8027 15.1727 17.8752 14.087 17.875 13C17.8752 11.913 17.8027 10.8273 17.6581 9.75H8.34194C8.19727 10.8273 8.1248 11.913 8.125 13V13Z" />
    <path d="M8.62305 17.875C9.48023 21.8563 11.3084 24.375 12.9992 24.375C14.69 24.375 16.5181 21.8563 17.3753 17.875H8.62305Z" />
    <path d="M19.041 8.12484H24.1597C23.3906 6.37452 22.222 4.82881 20.7477 3.61167C19.2733 2.39454 17.5342 1.53987 15.6699 1.11621C17.2112 2.47227 18.4202 4.97965 19.041 8.12484V8.12484Z" />
    <path d="M24.7434 9.75H19.2996C19.4337 10.8281 19.5007 11.9136 19.5003 13C19.5004 14.0865 19.4331 15.1719 19.2988 16.25H24.7426C25.3351 14.1238 25.3351 11.8762 24.7426 9.75H24.7434Z" />
    <path d="M15.6699 24.8836C17.5345 24.4602 19.2739 23.6056 20.7486 22.3885C22.2232 21.1713 23.3921 19.6255 24.1614 17.875H19.0426C18.4202 21.0202 17.2112 23.5276 15.6699 24.8836V24.8836Z" />
    <path d="M6.96055 17.875H1.8418C2.61107 19.6255 3.77992 21.1713 5.25457 22.3885C6.72923 23.6056 8.46863 24.4602 10.3332 24.8836C8.7903 23.5276 7.5813 21.0202 6.96055 17.875V17.875Z" />
    <path d="M10.3313 1.11621C8.46668 1.53964 6.72727 2.39421 5.25262 3.61135C3.77796 4.8285 2.60911 6.37434 1.83984 8.12484H6.95859C7.58097 4.97965 8.78997 2.47227 10.3313 1.11621V1.11621Z" />
    <path d="M6.49915 13C6.49904 11.9135 6.56634 10.8281 6.70065 9.75H1.2569C0.664367 11.8762 0.664367 14.1238 1.2569 16.25H6.70065C6.56634 15.1719 6.49904 14.0865 6.49915 13V13Z" />
  </ProjectLinkSVG>
)

export const MediumIcon = () => (
  <ProjectLinkSVG xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM8.11055 17.0145C10.88 17.0145 13.1251 14.7695 13.1251 12C13.1251 9.23056 10.88 6.98547 8.11055 6.98547C5.34111 6.98547 3.09602 9.23056 3.09602 12C3.09602 14.7695 5.34111 17.0145 8.11055 17.0145ZM16.1439 16.7722C17.5244 16.7722 18.6436 14.6356 18.6436 12C18.6436 9.3644 17.5244 7.22781 16.1439 7.22781C14.7633 7.22781 13.6441 9.3644 13.6441 12C13.6441 14.6356 14.7633 16.7722 16.1439 16.7722ZM20.9037 11.9803C20.9037 14.334 20.4968 16.2421 19.9948 16.2421C19.4927 16.2421 19.0858 14.334 19.0858 11.9803C19.0858 9.62649 19.4927 7.71838 19.9948 7.71838C20.4968 7.71838 20.9037 9.62649 20.9037 11.9803Z"
    />
  </ProjectLinkSVG>
)
export const WhitePaperIcon = () => (
  <ProjectLinkSVG width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26 13C26 20.1797 20.1797 26 13 26C5.8203 26 0 20.1797 0 13C0 5.8203 5.8203 0 13 0C20.1797 0 26 5.8203 26 13ZM8.57639 5.41667H17.7847C18.6311 5.41667 19.3194 6.10499 19.3194 6.95139V19.2292C19.3194 20.0756 18.6311 20.7639 17.7847 20.7639H8.57639C7.72999 20.7639 7.04167 20.0756 7.04167 19.2292V6.95139C7.04167 6.10499 7.72999 5.41667 8.57639 5.41667ZM14.2639 7.94444H9.79514C9.37079 7.94444 9.02778 8.28746 9.02778 8.71181C9.02778 9.13616 9.37079 9.47917 9.79514 9.47917H14.2639C14.6882 9.47917 15.0312 9.13616 15.0312 8.71181C15.0312 8.28746 14.6882 7.94444 14.2639 7.94444ZM9.79514 18.2361H16.566C16.9903 18.2361 17.3333 17.8931 17.3333 17.4688C17.3333 17.0444 16.9903 16.7014 16.566 16.7014H9.79514C9.37079 16.7014 9.02778 17.0444 9.02778 17.4688C9.02778 17.8931 9.37079 18.2361 9.79514 18.2361ZM9.79514 15.3924H16.566C16.9903 15.3924 17.3333 15.0494 17.3333 14.625C17.3333 14.2007 16.9903 13.8576 16.566 13.8576H9.79514C9.37079 13.8576 9.02778 14.2007 9.02778 14.625C9.02778 15.0494 9.37079 15.3924 9.79514 15.3924ZM9.79514 12.5486H16.566C16.9903 12.5486 17.3333 12.2056 17.3333 11.7813C17.3333 11.3569 16.9903 11.0139 16.566 11.0139H9.79514C9.37079 11.0139 9.02778 11.3569 9.02778 11.7813C9.02778 12.2056 9.37079 12.5486 9.79514 12.5486Z"
    />
  </ProjectLinkSVG>
)
export const GithubIcon = () => (
  <ProjectLinkSVG width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.0001 0C5.82129 0 0 5.96751 0 13.3291C0 19.2183 3.72493 24.2146 8.89027 25.9771C9.53997 26.1005 9.7785 25.6879 9.7785 25.3359C9.7785 25.018 9.76644 23.9681 9.76085 22.8543C6.1442 23.6606 5.38105 21.2816 5.38105 21.2816C4.78968 19.741 3.93762 19.3313 3.93762 19.3313C2.75812 18.504 4.02653 18.521 4.02653 18.521C5.33196 18.615 6.01934 19.8946 6.01934 19.8946C7.17882 21.9323 9.06055 21.3432 9.80239 21.0026C9.91907 20.1411 10.256 19.5531 10.6278 19.2203C7.74026 18.8832 4.70486 17.7403 4.70486 12.633C4.70486 11.1777 5.2127 9.98868 6.04431 9.05523C5.90934 8.71951 5.46436 7.36382 6.17025 5.52783C6.17025 5.52783 7.26192 5.16959 9.74621 6.89412C10.7832 6.59879 11.8953 6.45068 13.0001 6.4456C14.1049 6.45068 15.2179 6.59879 16.2568 6.89412C18.7381 5.16959 19.8282 5.52783 19.8282 5.52783C20.5359 7.36382 20.0907 8.71951 19.9557 9.05523C20.7892 9.98868 21.2936 11.1777 21.2936 12.633C21.2936 17.7524 18.2524 18.8797 15.3576 19.2097C15.8239 19.6233 16.2394 20.4345 16.2394 21.678C16.2394 23.4615 16.2243 24.8969 16.2243 25.3359C16.2243 25.6906 16.4583 26.1062 17.1173 25.9753C22.2798 24.2109 26 19.2163 26 13.3291C26 5.96751 20.1796 0 13.0001 0Z" />
  </ProjectLinkSVG>
)
export const DiscordIcon = () => (
  <ProjectLinkSVG width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26ZM15.6349 6.5C16.85 6.71048 18.0122 7.08012 19.0991 7.58875C20.9997 10.4273 21.944 13.6287 21.5953 17.3212C20.1412 18.4086 18.7307 19.0683 17.3439 19.5C17.0005 19.0292 16.6968 18.5273 16.4339 18.0012C16.9345 17.8109 17.4152 17.5762 17.8695 17.3023C17.7507 17.2133 17.6331 17.1202 17.5182 17.0244C14.7883 18.3168 11.7863 18.3168 9.02337 17.0244C8.9098 17.1202 8.79224 17.2133 8.67207 17.3023C9.12506 17.5748 9.60448 17.8096 10.105 17.9998C9.84221 18.5273 9.53713 19.0278 9.19506 19.4987C7.80963 19.067 6.40042 18.4072 4.94631 17.3212C4.64914 14.136 5.24347 10.9049 7.43586 7.59144C8.52282 7.08148 9.68638 6.71048 10.9028 6.5C11.0533 6.76983 11.2316 7.13275 11.3518 7.42145C12.6197 7.22718 13.8995 7.22718 15.1911 7.42145C15.3113 7.13275 15.4857 6.76983 15.6349 6.5ZM8.97185 13.6449C8.97185 14.5879 9.65203 15.3623 10.4814 15.3623C11.3254 15.3623 11.9923 14.5879 11.991 13.6449C12.0056 12.7005 11.3254 11.9261 10.4814 11.9261C9.63751 11.9261 8.97185 12.7019 8.97185 13.6449ZM14.5506 13.6449C14.5506 14.5879 15.2308 15.3623 16.0602 15.3623C16.9041 15.3623 17.5698 14.5879 17.5698 13.6449C17.5843 12.7005 16.9041 11.9261 16.0602 11.9261C15.2162 11.9261 14.5506 12.7019 14.5506 13.6449Z"
    />
  </ProjectLinkSVG>
)
export const TwitterIcon = () => (
  <ProjectLinkSVG width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26ZM18.3499 7.15L14.0879 12.1041H14.0877L18.723 18.85H15.314L12.1925 14.3073L8.28458 18.85H7.27458L11.7442 13.6548L7.27458 7.15H10.6836L13.6393 11.4516L17.3399 7.15H18.3499ZM12.2518 13.0644L12.7046 13.7121V13.7124L15.7891 18.1243H17.3404L13.5605 12.7175L13.1077 12.0698L10.1998 7.91031H8.6485L12.2518 13.0644Z"
    />
  </ProjectLinkSVG>
)
export const TelegramIcon = () => (
  <ProjectLinkSVG width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26 13C26 20.1797 20.1797 26 13 26C5.8203 26 0 20.1797 0 13C0 5.8203 5.8203 0 13 0C20.1797 0 26 5.8203 26 13ZM13.2961 9.32634C12.0316 9.85227 9.50451 10.9408 5.71475 12.5919C5.09935 12.8367 4.77698 13.0761 4.74763 13.3102C4.69803 13.7058 5.19347 13.8616 5.86813 14.0737C5.9599 14.1026 6.05499 14.1325 6.15247 14.1642C6.81622 14.3799 7.7091 14.6323 8.17326 14.6424C8.59431 14.6515 9.06424 14.4779 9.58306 14.1216C13.124 11.7314 14.9518 10.5233 15.0665 10.4973C15.1475 10.4789 15.2597 10.4558 15.3357 10.5233C15.4117 10.5909 15.4042 10.7188 15.3962 10.7532C15.3471 10.9624 13.4023 12.7704 12.3959 13.7061C12.0822 13.9978 11.8596 14.2047 11.8141 14.2519C11.7122 14.3578 11.6083 14.4579 11.5085 14.5542C10.8919 15.1486 10.4294 15.5944 11.5341 16.3224C12.065 16.6722 12.4898 16.9615 12.9136 17.2501C13.3764 17.5653 13.838 17.8796 14.4353 18.2711C14.5875 18.3709 14.7328 18.4745 14.8743 18.5754C15.4129 18.9594 15.8968 19.3044 16.4947 19.2494C16.842 19.2174 17.2009 18.8908 17.3831 17.9166C17.8138 15.6142 18.6603 10.6258 18.8559 8.57022C18.8731 8.39012 18.8515 8.15963 18.8342 8.05846C18.8169 7.95728 18.7807 7.81312 18.6492 7.7064C18.4934 7.58001 18.253 7.55336 18.1454 7.55526C17.6565 7.56387 16.9063 7.82472 13.2961 9.32634Z"
    />
  </ProjectLinkSVG>
)

export const GeckoTerminalIcon = () => (
  <ProjectLinkSVG width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7426_146987)">
      <g clipPath="url(#clip1_7426_146987)">
        <path d="M18.0699 7.04253C17.923 6.46036 17.4199 6.00818 16.7869 5.95731C16.7417 5.95166 16.6965 5.95166 16.6512 5.95166H16.6456L16.6512 5.95731C16.4873 5.95731 16.3347 5.98557 16.1878 6.04209C16.1821 6.04209 16.1821 6.04209 16.1765 6.04775C15.8543 6.16644 15.583 6.39818 15.4134 6.69775C13.5312 6.73166 11.5417 7.40427 10.6486 9.39949C10.5638 9.59731 10.4847 9.80079 10.4112 9.99862C10.4169 10.7899 10.3491 12.1747 9.87428 13.096C9.48428 13.8421 9.07732 14.3338 8.78906 14.6108L10.0891 15.9108L12.7286 18.5504C12.8134 18.6351 12.9434 18.6351 13.0282 18.5504L13.3221 18.2564L15.0573 16.5212L15.2608 16.3177L15.0573 16.5212C14.786 14.6277 15.176 13.1243 15.8486 12.1125C16.0747 11.7791 16.3347 11.4964 16.606 11.276C16.6117 11.2704 16.623 11.2647 16.6286 11.2534C16.866 11.0669 17.1204 10.9199 17.3747 10.8238C17.3747 10.8238 17.3747 10.8238 17.3804 10.8182C17.4199 10.8069 17.4482 10.7956 17.4821 10.7786C18.2508 10.5243 19.4717 10.0438 20.1782 9.46731L20.1838 9.47296C21.1108 8.72123 21.1447 7.80557 18.0699 7.04253ZM17.646 7.79992C17.4143 8.33688 16.7925 8.59122 16.2556 8.35949C15.7186 8.12775 15.4643 7.50601 15.696 6.96905C15.7243 6.90123 15.7638 6.8334 15.8091 6.77123C15.8769 6.89557 15.9843 6.99731 16.1199 7.05383C16.4082 7.17818 16.7417 7.06514 16.9056 6.79949C16.9225 6.77123 16.9395 6.74296 16.9508 6.7147C16.996 6.60166 17.0073 6.48296 16.9904 6.36992C17.0186 6.38122 17.0525 6.39253 17.0865 6.40949C17.0921 6.41514 17.0978 6.41514 17.1091 6.42079C17.3465 6.52818 17.533 6.72036 17.6347 6.94644C17.7534 7.20079 17.7647 7.51166 17.646 7.79992Z" />
        <path d="M10.3951 10.0327L7.45031 12.9775C7.36552 13.0623 7.36552 13.1923 7.45031 13.2771L8.349 14.1758C9.57552 13.3223 9.84683 12.9153 10.3951 10.0327Z" />
        <path d="M18.2969 12.9715L16.7877 11.4624C15.8664 12.5589 15.6008 13.5481 15.9569 15.0459C15.9908 15.2041 16.036 15.3568 16.0756 15.4981L18.3025 13.2711C18.3873 13.192 18.3873 13.062 18.2969 12.9715Z" />
        <path d="M13 0C5.82174 0 0 5.82174 0 13C0 20.1783 5.82174 26 13 26C20.1783 26 26 20.1783 26 13C26 5.82174 20.1783 0 13 0ZM21.207 15.7243L21.1278 15.8035L15.5491 21.3822L15.47 21.4613C14.0287 22.9026 11.6943 22.9026 10.2587 21.4613L10.1061 21.3087L5.18304 16.3857L4.52739 15.7357C3.08609 14.2943 3.08609 11.96 4.52739 10.5243L4.60652 10.4452L10.1796 4.87217L10.2587 4.79304C11.7 3.35174 14.0343 3.35174 15.47 4.79304L16.5891 5.91217C16.883 5.85 17.2561 5.80478 17.5557 5.90652C18.2 6.1213 18.4487 6.81087 18.4487 6.81087C18.4487 6.81087 20.28 7.20087 20.8848 8.05435C21.3483 8.71565 20.5287 9.30913 20.1952 9.51261L21.2013 10.5187C22.6483 11.9543 22.6483 14.2943 21.207 15.7243Z" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_7426_146987">
        <rect width="26" height="26" fill="white" />
      </clipPath>
      <clipPath id="clip1_7426_146987">
        <rect width="26" height="26" fill="white" />
      </clipPath>
    </defs>
  </ProjectLinkSVG>
)
export const OpenIcon = () => (
  <OpenIconSVG width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0.652469C0 0.327623 0.26334 0.0642834 0.588185 0.0642834H3.45144C3.77628 0.0642834 4.03962 0.327623 4.03962 0.652469C4.03962 0.977314 3.77628 1.24065 3.45144 1.24065H1.17637V8.31593H8.13157V6.12578C8.13157 5.80094 8.39491 5.5376 8.71976 5.5376C9.0446 5.5376 9.30794 5.80094 9.30794 6.12578V8.90412C9.30794 9.22896 9.0446 9.4923 8.71976 9.4923H0.588185C0.26334 9.4923 0 9.22896 0 8.90412V0.652469Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.3839 4.22904C9.05907 4.22655 8.79776 3.9612 8.80025 3.63636L8.81272 2.01058L7.97388 1.18573L6.37695 1.19394C6.05211 1.19561 5.78742 0.933629 5.78575 0.608787C5.78408 0.283946 6.04606 0.0192555 6.3709 0.017585L9.40879 0.00196092C9.56609 0.00115185 9.71716 0.0633842 9.82825 0.174754C9.93934 0.286124 10.0012 0.437352 9.99998 0.59465L9.97658 3.64539C9.97409 3.97022 9.70874 4.23153 9.3839 4.22904Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.81272 2.01058L5.85924 4.94399C5.62876 5.1729 5.25635 5.17163 5.02743 4.94115C4.79851 4.71067 4.79978 4.33825 5.03027 4.10934L7.97388 1.18573L8.81272 2.01058Z"
    />
  </OpenIconSVG>
)

export const BreadcrumbArrow = () => (
  <BreadcrumbArrowSVG width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.68023 0L0.740234 0.94L3.79357 4L0.740234 7.06L1.68023 8L5.68023 4L1.68023 0Z" />
  </BreadcrumbArrowSVG>
)

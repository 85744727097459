import { useMemo } from 'react'
import loZip from 'lodash/zip'
import IImpossiblePairABI from 'config/abi/IImpossiblePair.json'
import { Interface } from '@ethersproject/abi'
import { useNetwork } from 'wagmi'
import { Currency } from 'swap-sdk/entities/currency'
import { Pair } from 'swap-sdk/entities/pair'
import { TokenAmount } from 'swap-sdk/entities/fractions/tokenAmount'

import { useMultipleContractSingleData } from '../state/multicall/hooks'
import { wrappedCurrency } from '../utils/wrappedCurrency'

const PAIR_INTERFACE = new Interface(IImpossiblePairABI.abi)

export enum PairState {
  LOADING,
  NOT_EXISTS,
  EXISTS,
  INVALID,
}

export enum TradeState {
  SELL_ALL,
  SELL_TOKEN_0,
  SELL_TOKEN_1,
  SELL_NONE,
}

export function usePairs(currencies: [Currency | undefined, Currency | undefined][]): [PairState, Pair | null][] {
  const { chain } = useNetwork()
  const chainId = chain?.id

  const tokens = useMemo(
    () =>
      currencies.map(([currencyA, currencyB]) => [
        wrappedCurrency(currencyA, chainId),
        wrappedCurrency(currencyB, chainId),
      ]),
    [chainId, currencies],
  )

  const pairAddresses = useMemo(
    () =>
      tokens.map(([tokenA, tokenB]) => {
        return tokenA && tokenB && !tokenA.equals(tokenB) ? Pair.getAddress(tokenA, tokenB) : undefined
      }),
    [tokens],
  )

  const reserves = useMultipleContractSingleData(pairAddresses, chainId, PAIR_INTERFACE, 'getReserves')
  const pairSettings = useMultipleContractSingleData(pairAddresses, chainId, PAIR_INTERFACE, 'getPairSettings')
  const boosts = useMultipleContractSingleData(pairAddresses, chainId, PAIR_INTERFACE, 'calcBoost')

  return useMemo(() => {
    return loZip(reserves, pairSettings, boosts).map(([reserve, pairSetting, boost], i) => {
      const { result: reservesResult } = reserve
      const { result: pairSettingResults } = pairSetting
      const { result: boostResults } = boost

      const tokenA = tokens[i][0]
      const tokenB = tokens[i][1]

      if (reserve.loading || pairSetting.loading || boost.loading) return [PairState.LOADING, null]
      if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [PairState.INVALID, null]
      if (!reservesResult || !pairSettingResults || !boostResults) return [PairState.NOT_EXISTS, null]
      const [reserve0, reserve1] = reservesResult
      const [fee, tradeState, isXybk] = pairSettingResults
      const [boost0, boost1] = boostResults

      const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]
      return [
        PairState.EXISTS,
        new Pair(
          new TokenAmount(token0, reserve0.toString()),
          new TokenAmount(token1, reserve1.toString()),
          isXybk,
          fee,
          boost0,
          boost1,
          tradeState,
        ),
      ]
    })
  }, [reserves, pairSettings, boosts, tokens])
}

export function usePair(tokenA?: Currency, tokenB?: Currency): [PairState, Pair | null] {
  return usePairs([[tokenA, tokenB]])[0]
}

import React from 'react'
import { Box } from '@mui/material'
import { Currency } from 'swap-sdk/entities/currency'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import { useCurrencyBalance } from 'hooks/useWallet'
import { useAccount } from 'wagmi'
import { ChevronDownIcon, Flex, UndefinedTokenIcon, useMatchBreakpoints, useModal } from 'uikit'
import CurrencySearchModal from 'componentsV2/SearchModal/CurrencySearchModal'
import { CurrencyLogo } from 'componentsV2/Logo'
import WhiteCircleLoader from 'componentsV2/Loader/WhiteCircleLoader'
import IFTypography from '../IFTypography/IFTypography'

type CurrencyInputPanelProps = {
  value: string
  label?: string
  currency?: Currency
  enableCurrencySelect?: boolean
  hideMaxButton?: boolean
  hideBalance?: boolean
  otherCurrency?: Currency | null
  showCommonBases?: boolean
  onCurrencySelect?: (currency: Currency) => void
  onMaxClicked?: () => void
  onUserInput?: (value: string) => void
  isLP?: boolean
  LPcurrency0?: Currency
  LPcurrency1?: Currency
}

const StyledContainer = styled(Box)`
  border: 1px solid ${(props) => props.theme.colorsV2?.light};
  border-radius: 20px;
  overflow: hidden;
`

const StyledHeader = styled(Box)`
  background: ${({ theme }) => `${theme.colorsV2?.dark2}`};
`

const StyledBody = styled(Box)`
  background: ${({ theme }) => (theme.isDark ? theme.colorsV2.dark : theme.colorsV2?.main)};
  padding-left: 20px;
  padding-right: 20px;
`

const StyledCurrencyIconContainer = styled(Box)<{ $isLp: boolean }>`
  width: ${({ $isLp }) => ($isLp ? '48' : '35')}px;
  height: 35px;
  margin: 17px 13px 8px 20px;
`

const StyledButton = styled.button`
  color: ${(props) => props.theme.colorsV2?.textThirdly};
  border: solid 1px ${(props) => props.theme.colorsV2?.textThirdly};
  background: transparent;
  border-radius: 20px;
  font-size: 14px;
  padding: 3px 10px;
`

const StyledInput = styled.input`
  border: none;
  background: transparent;
  text-align: right;
  color: ${(props) => props.theme.colorsV2?.text};
  font-size: 24px;
  font-weight: 500;
  width: 100%;
  font-family: Acre;
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &:disabled {
    color: #A6A8AA;
  }
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button{
    -webkit-appearance: none; 
    margin: 0; 
  }
  ::placeholder {
    color: ${({ theme }) => (theme.isDark ? theme.colorsV2?.light : theme.colorsV2?.textDisabled)};
    font-size: 24px;
  }

  ${({ theme }) => theme.mediaQueries?.sm} {
    font-size: 32px;
    ::placeholder {
      font-size: 32px;
    }
  }
}    
`

const CurrencyInputPanel: React.FC<CurrencyInputPanelProps> = ({
  value,
  label,
  currency,
  enableCurrencySelect = false,
  hideMaxButton,
  hideBalance,
  onCurrencySelect,
  onMaxClicked,
  onUserInput,
  otherCurrency,
  showCommonBases,
  isLP,
  LPcurrency0,
  LPcurrency1,
}) => {
  const { t } = useTranslation()
  const { address: account } = useAccount()
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const onWheel = (e) => e.currentTarget.blur()
  const { isXl } = useMatchBreakpoints()
  const isMobile = !isXl

  const [onPresentCurrencyModal] = useModal(
    <CurrencySearchModal
      onCurrencySelect={onCurrencySelect}
      selectedCurrency={currency}
      otherSelectedCurrency={otherCurrency}
      showCommonBases={showCommonBases}
    />,
  )

  return (
    <StyledContainer display="flex" flexDirection="column">
      <StyledHeader display="flex" flexDirection="row" justifyContent="space-between">
        <Box
          display="flex"
          flexDirection="row"
          style={{ cursor: enableCurrencySelect ? 'pointer' : 'unset' }}
          onClick={() => {
            if (enableCurrencySelect) {
              onPresentCurrencyModal()
            }
          }}
        >
          <>
            <StyledCurrencyIconContainer $isLp={isLP}>
              {isLP && LPcurrency0 && LPcurrency1 ? (
                <Flex>
                  <CurrencyLogo currency={LPcurrency0} size="32px" />
                  <CurrencyLogo style={{ marginLeft: -11 }} currency={LPcurrency1} size="32px" />
                </Flex>
              ) : currency ? (
                <CurrencyLogo currency={currency} size="32px" />
              ) : (
                <UndefinedTokenIcon width="32px" />
              )}
            </StyledCurrencyIconContainer>
            <Box display="flex" flexDirection="column" justifyContent="center">
              {label && (
                <IFTypography variant="caption" ifcolor="textSecondary">
                  {label}
                </IFTypography>
              )}
              <Box display="flex" flexDirection="row" marginTop={label ? '2px' : '12px'}>
                <IFTypography fontWeight={isMobile ? 'regular' : 'bold'} variant={isMobile ? 'body1' : 'body2'}>
                  {isLP && LPcurrency0 && LPcurrency1
                    ? `${LPcurrency0.symbol} - ${LPcurrency1.symbol}`
                    : currency
                    ? currency.symbol
                    : t('Select a token')}
                </IFTypography>
                {enableCurrencySelect && <ChevronDownIcon />}
              </Box>
            </Box>
          </>
        </Box>
        <Box display="flex" marginTop="26px" marginRight="20px">
          {!hideBalance && (
            <Flex alignItems="center">
              <IFTypography
                variant="caption"
                ifcolor={account && currency && !selectedCurrencyBalance ? 'textDisabled' : 'text'}
              >
                {t('Balance')}: {account && currency ? selectedCurrencyBalance?.toSignificant(6) ?? '' : '-'}
              </IFTypography>
              {account && currency && !selectedCurrencyBalance && (
                <Flex ml="5px">
                  <WhiteCircleLoader variant="disabled" width="16px" />
                </Flex>
              )}
            </Flex>
          )}
        </Box>
      </StyledHeader>
      <StyledBody display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Box marginY={isMobile ? '20px' : '15px'}>
          {/* TODO: replace with IF Button Component */}
          {!hideMaxButton && account && currency && selectedCurrencyBalance && (
            <StyledButton onClick={onMaxClicked}>{t('Max')}</StyledButton>
          )}
        </Box>
        <Box marginY={isMobile ? '20px' : '15px'}>
          <StyledInput
            type="number"
            placeholder="0"
            pattern="^[0-9]*[.,]?[0-9]*$"
            inputMode="decimal"
            minLength={1}
            maxLength={79}
            value={value}
            onChange={(evt) => onUserInput(evt.target.value)}
            onWheel={onWheel}
          />
        </Box>
      </StyledBody>
    </StyledContainer>
  )
}

export default CurrencyInputPanel

import React from 'react'
import styled from 'styled-components'
import { Card } from 'uikit'

export const BodyWrapper = styled(Card)<{ maxWidth?: string }>`
  border-radius: 20px;
  max-width: ${({ maxWidth }) => maxWidth || '450px'} ;
  width: 100%;
  z-index: 1;
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;
  backdrop-filter: blur(10px);
  box-shadow: ${({ theme }) => theme.colorsV2?.shadows?.settings};
  background: ${({ theme }) => theme.colorsV2?.swap?.background};
  overflow: visible;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    border-radius: 20px;
    background: ${({ theme }) => theme.colorsV2?.gradients?.swapBorder}
    z-index: -2;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;

    border-radius: 20px;
    background: ${({ theme }) => theme.colorsV2?.swap?.background};
    z-index: -1;
  }

  ${({ theme }) => theme.mediaQueries?.sm} {
    margin-top: 64px;
  }
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ maxWidth, children }: { maxWidth?: string; children: React.ReactNode }) {
  return <BodyWrapper maxWidth={maxWidth}>{children}</BodyWrapper>
}

import React from 'react'
import Svg from '../../../components/Svg/Svg'

const WalletRonin: React.FC = ({ ...props }) => {
  return (
    <Svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 1651">
        <path
          id="Rectangle 617 (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.8333 2.36667H7.16663C4.33156 2.36667 2.03329 4.66494 2.03329 7.5V14.5C2.03329 17.3351 4.33156 19.6333 7.16663 19.6333H18.8333C21.6684 19.6333 23.9666 17.3351 23.9666 14.5V7.5C23.9666 4.66494 21.6684 2.36667 18.8333 2.36667ZM7.16663 0.5C3.30063 0.5 0.166626 3.63401 0.166626 7.5V14.5C0.166626 18.366 3.30063 21.5 7.16663 21.5H18.8333C22.6993 21.5 25.8333 18.366 25.8333 14.5V7.5C25.8333 3.63401 22.6993 0.5 18.8333 0.5H7.16663Z"
          fill="white"
        />
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.9526 9.3806H19.5645C18.5259 9.3806 17.6839 10.2226 17.6839 11.2612C17.6839 12.2998 18.5259 13.1418 19.5645 13.1418H23.9526V9.3806ZM19.5645 7.5C17.4873 7.5 15.8033 9.18394 15.8033 11.2612C15.8033 13.3384 17.4873 15.0224 19.5645 15.0224H24.5795C25.2719 15.0224 25.8332 14.4611 25.8332 13.7687V8.75373C25.8332 8.06131 25.2719 7.5 24.5795 7.5H19.5645Z"
          fill="white"
        />
      </g>
    </Svg>
  )
}

export default React.memo(WalletRonin)

import React, { useContext } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { CompanyContext } from 'contexts/CompanyContext'

import { LogoIconLight } from '../../../components/Svg'

interface Props {
  isDark: boolean
  isMobile: boolean
  href: string
}

const StyledLink = styled(Link)`
  padding-left: 20px;
  .mobile-icon {
    width: 32px;
  }
`

const Logo: React.FC<Props> = ({ href, isMobile, isDark }) => {
  const { logoDesktopDark, logoDesktopLight, logoMobileDark, logoMobileLight } = useContext(CompanyContext)

  const isAbsoluteUrl = href.startsWith('http')
  let innerLogo = <LogoIconLight />
  if (isDark && isMobile) {
    innerLogo = logoMobileDark
  } else if (!isDark && isMobile) {
    innerLogo = logoMobileLight
  } else if (isDark && !isMobile) {
    innerLogo = logoDesktopDark
  } else if (!isDark && !isMobile) {
    innerLogo = logoDesktopLight
  }

  return (
    <>
      {isAbsoluteUrl ? (
        <StyledLink as="a" href={href} aria-label="Impossible home page">
          {innerLogo}
        </StyledLink>
      ) : (
        <StyledLink to={href} aria-label="Impossible home page">
          {innerLogo}
        </StyledLink>
      )}
    </>
  )
}

export default React.memo(Logo, (prev, next) => prev.isMobile === next.isMobile && prev.isDark === next.isDark)

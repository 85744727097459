import React, { useContext } from 'react'
import { Menu as UikitMenu } from 'uikit'
import { useAccount, useNetwork } from 'wagmi'
import { allLanguages } from 'config/localisation/languageCodes'
import useTheme from 'hooks/useTheme'
import useAuth from 'hooks/useAuth'
import BigNumber from 'bignumber.js'
import useTokenBalance from 'hooks/useTokenBalance'
import useBNBBalance from 'hooks/useBNBBalance'
import { useGetBalance } from 'hooks/useGetBalance'
import { getAddress } from 'utils/addressHelpers'
import { useAppStateUnavailableNetwork } from 'state/hooks'

import { MINIMUM_IDIA, MINIMUM_IF } from 'config/constants/kyc'
import { getBalanceNumber, convertFromWei, getBalanceInWei } from 'utils/formatBalance'
import { useKycStatus } from 'hooks/useKycStatus'
import { useKycSession } from 'hooks/useKycSession'
import { useIsUserSignedIn } from 'state/user/hooks'
import useSignOAuth from 'hooks/useSignOAuth'
import networkModalOptions from 'config/constants/networkModalOptions'
import tokens from 'config/constants/tokens'
import gasTokens from 'config/constants/gasTokens'
import { CompanyContext } from 'contexts/CompanyContext'

const useIFBalanceCallback = () => getBalanceNumber(useTokenBalance(tokens.if.address)).toPrecision(6)
const useGasBalanceCallback = () => getBalanceNumber(useBNBBalance()).toPrecision(4)

const Menu = (props) => {
  const { address: account } = useAccount()
  const { chain } = useNetwork()
  const chainId = chain?.id
  const isUserSigned = useIsUserSignedIn()
  const { signOAuth } = useSignOAuth('if')
  const ifBalance = useGetBalance(getAddress(tokens.if.address, chainId), account)
  const idiaBalance = useGetBalance(getAddress(tokens.idia.address, chainId), account)
  const hasMinimumIdia = idiaBalance.isGreaterThanOrEqualTo(getBalanceInWei(new BigNumber(MINIMUM_IDIA)))
  const { menuLinks } = useContext(CompanyContext)

  const { login, logout } = useAuth()
  // TODO: replace with proper implementation
  // eslint-disable-next-line
  const setSelectedLanguage = () => {}
  const { toggleTheme } = useTheme()
  const gasToken = gasTokens[chainId] || 'BNB'
  const isUnavailable = useAppStateUnavailableNetwork()

  const balances = [
    {
      symbol: 'IDIA',
      balance: convertFromWei(useTokenBalance(tokens.idia.address)).decimalPlaces(3).toNumber().toLocaleString(),
      iconSrc: '/images/tokens/IDIA.png',
    },
    {
      symbol: 'IF',
      balance: convertFromWei(useTokenBalance(tokens.if.address)).decimalPlaces(3).toNumber().toLocaleString(),
      iconSrc: '/images/tokens/IF.png',
    },
    {
      symbol: 'BUSD',
      balance: convertFromWei(useTokenBalance(tokens.busd.address)).decimalPlaces(3).toNumber().toLocaleString(),
      iconSrc: '/images/tokens/busd.png',
    },
    {
      symbol: gasToken,
      balance: convertFromWei(useBNBBalance()).decimalPlaces(3).toNumber().toLocaleString(),
      iconSrc: `/images/tokens/${gasToken}.png`,
    },
  ]

  const { status } = useKycStatus()
  const { onSession, isLoading } = useKycSession()

  const kycInfo = {
    status: status || 'PENDING',
    verifyKycCallback: isUserSigned ? () => onSession : () => signOAuth(() => onSession()),
    isLoading,
    passMinRequirement: hasMinimumIdia,
    minRequirementText: `You need to hold at least ${MINIMUM_IDIA} IDIA to start KYC.`,
    verifiedSrc: '/images/verified.svg',
    getIFUrl: `${process.env.REACT_APP_SWAP_DOMAIN}/#/swap/${tokens.if.address[chainId]}`,
    getIDIAUrl: `${process.env.REACT_APP_SWAP_DOMAIN}/#/swap/${tokens.idia.address[chainId]}`,
  }

  const networks = networkModalOptions.map((val) => ({ ...val, isCurrent: chainId === val.chainID }))

  return (
    <UikitMenu
      account={account}
      login={login}
      logout={logout}
      isDark={false}
      toggleTheme={toggleTheme}
      currentLang="EN"
      langs={allLanguages}
      setLang={setSelectedLanguage}
      links={menuLinks}
      useIFBalance={useIFBalanceCallback}
      ifIcon="/images/tokens/IF.png"
      useGasBalance={useGasBalanceCallback}
      gasToken={gasToken}
      balances={balances}
      kycInfo={kycInfo}
      networks={networks}
      showNetworks
      isNetworkUnavailable={isUnavailable}
      {...props}
    />
  )
}

export default Menu

import React from 'react'
import { Box } from '@mui/material'
import styled from 'styled-components'
import HelpSharpIcon from '@mui/icons-material/HelpSharp'
import { useTranslation } from 'contexts/Localization'
import IFTooltip from 'componentsV2/IFTooltip'
import IFTypography from '../IFTypography/IFTypography'

const HelpIcon = styled(HelpSharpIcon)`
  width: 14px !important;
  height: 14px !important;
  color: ${({ theme }) => theme.colorsV2?.textSecondary};
  margin-left: 7px;
`

type CurrencyConfirmationProps = {
  amount: string
}

const RewardsBox = styled(Box)`
  background: ${({ theme }) => (theme.isDark ? theme.colorsV2.dark : theme.colorsV2?.dark2)};
  border-radius: 5px;
  padding: 16px;
  opacity: 0.5;
`

const CurrencyConfirmation: React.FC<CurrencyConfirmationProps> = ({ amount }) => {
  const { t } = useTranslation()

  return (
    <RewardsBox alignItems="center">
      <Box display="flex" alignItems="center" flexDirection="column">
        <Box display="flex" flexDirection="row" alignItems="center" width="100%" fontWeight={700}>
          <Box lineHeight="20px">
            <IFTypography variant="body2" fontWeight="bold">
              {t('Claimable rewards')}
            </IFTypography>
          </Box>
          <IFTooltip
            placement="top"
            title={t('IDIA that you can claim from users that have unvested vIDIA immediately')}
          >
            <HelpIcon />
          </IFTooltip>
          <Box flexGrow="1" textAlign="right">
            <IFTypography variant="body1" fontWeight="bold">
              {amount}
            </IFTypography>
          </Box>
          <Box marginLeft="5px">
            <IFTypography variant="body2" fontWeight="bold">
              IDIA
            </IFTypography>
          </Box>
        </Box>
      </Box>
      <Box marginTop="7px" display="flex" alignItems="center">
        <IFTypography variant="caption" ifcolor="textSecondary">
          {t('The rewards will be collected to your wallet')}
        </IFTypography>
      </Box>
    </RewardsBox>
  )
}

export default CurrencyConfirmation

import BigNumber from 'bignumber.js'
import { AverageBlockTime, Chain } from './constants/types'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

export const BASE_BSC_SCAN_URLS = {
  [Chain.BSC_MAINNET]: 'https://bscscan.com',
  [Chain.BSC_TESTNET]: 'https://testnet.bscscan.com',
  [Chain.POLYGON_MAINNET]: 'https://polygonscan.com',
  [Chain.MOONRIVER_MAINNET]: 'https://blockscout.moonriver.moonbeam.network',
  [Chain.AVAX_MAINNET]: 'https://snowtrace.io',
  [Chain.ETH_MAINNET]: 'https://etherscan.io',
  [Chain.MOONBEAM_MAINNET]: 'https://blockscout.moonbeam.network',
  [Chain.AURORA_MAINNET]: 'https://aurorascan.dev',
  [Chain.GOERLI]: 'https://goerli.etherscan.io',
  [Chain.ARBITRUM_MAINNET]: 'https://arbiscan.io',
  [Chain.OPTIMISM_MAINNET]: 'https://optimistic.etherscan.io',
  [Chain.NEON_DEVNET]: 'https://neonscan.org',
  [Chain.OKC_MAINNET]: 'https://www.oklink.com/okc',
  [Chain.ZKSYNC_ERA_MAINNET]: 'https://explorer.zksync.io',
  [Chain.ZKSYNC_SEPOLIA]: 'https://sepolia.explorer.zksync.io/',
  [Chain.MANTLE]: 'https://explorer.mantle.xyz/',
  [Chain.OMNI_TESTNET]: 'https://testnet.explorer.omni.network',
  [Chain.ARBITRUM_GOERLI]: 'https://goerli.arbiscan.io',
  [Chain.ARBITRUM_SEPOLIA]: 'https://sepolia-explorer.arbitrum.io',
  [Chain.LINEA_TESTNET]: 'https://explorer.goerli.linea.build',
  [Chain.HUMANODE_TESTNET]: 'https://beta.explorer.humanode.io',
  [Chain.HUMANODE_MAINNET]: 'https://humanode.subscan.io',
  [Chain.SAIGON]: 'https://saigon-app.roninchain.com/',
  [Chain.RONIN]: 'https://app.roninchain.com/',
  [Chain.MANTA_MAINNET]: 'https://pacific-explorer.manta.network/',
  [Chain.OKX_TESTNET]: 'https://www.okx.com/explorer/x1-test',
  [Chain.BASE_SEPOLIA]: 'https://sepolia.basescan.org',
  [Chain.BEAM]: 'https://subnets.avax.network/beam',
  [Chain.BEAM_TEST]: 'https://subnets-test.avax.network/beam',
  [Chain.IMMUTABLE]: 'https://explorer.immutable.com/',
  [Chain.IMMUTABLE_TEST]: 'https://explorer.testnet.immutable.com/',
  [Chain.POLYGON_AMOY]: ' https://amoy.polygonscan.com',
}

export const AVG_BLOCK_TIME: AverageBlockTime = {
  [Chain.BSC_MAINNET]: 3.0596,
  [Chain.BSC_TESTNET]: 3.0596,
  [Chain.POLYGON_MAINNET]: 2,
  [Chain.ETH_MAINNET]: 13.29,
  [Chain.AVAX_MAINNET]: 1.978,
  [Chain.MOONRIVER_MAINNET]: 13.56,
  [Chain.MOONBEAM_MAINNET]: 12.45,
  [Chain.AURORA_MAINNET]: 1.2,
  [Chain.GOERLI]: 23.8,
  [Chain.ARBITRUM_MAINNET]: 0.9,
  [Chain.ARBITRUM_TESTNET]: 1,
  [Chain.OPTIMISM_MAINNET]: 120,
  [Chain.OPTIMISM_TESTNET]: 120,
  [Chain.NEON_DEVNET]: 3,
  [Chain.OKC_MAINNET]: 3,
  [Chain.OMNI_TESTNET]: 3,
  [Chain.ZKSYNC_ERA_MAINNET]: 3,
  [Chain.ZKSYNC_SEPOLIA]: 3,
  [Chain.MANTLE]: 3,
  [Chain.LINEA_TESTNET]: 3,
  [Chain.ARBITRUM_GOERLI]: 3,
  [Chain.ARBITRUM_SEPOLIA]: 3,
  [Chain.SAIGON]: 3,
  [Chain.RONIN]: 3,
  [Chain.HUMANODE_TESTNET]: 3,
  [Chain.HUMANODE_MAINNET]: 3,
  [Chain.MANTA_MAINNET]: 3,
  [Chain.OKX_TESTNET]: 3,
  [Chain.BASE_SEPOLIA]: 3,
  [Chain.BEAM]: 3,
  [Chain.BEAM_TEST]: 3,
  [Chain.IMMUTABLE]: 3,
  [Chain.IMMUTABLE_TEST]: 3,
  [Chain.POLYGON_AMOY]: 3,
}

export const CAKE_PER_BLOCK = new BigNumber(0.014467)
export const BLOCKS_PER_YEAR = new BigNumber(10512000)
export const CAKE_POOL_PID = 0
export const BASE_EXCHANGE_URL = process.env.REACT_APP_SWAP_DOMAIN
export const BASE_ADD_LIQUIDITY_URL = `${BASE_EXCHANGE_URL}/#/add`
export const BASE_LIQUIDITY_POOL_URL = `${BASE_EXCHANGE_URL}/#/pool`
export const LOTTERY_MAX_NUMBER_OF_TICKETS = 50
export const LOTTERY_TICKET_PRICE = 1

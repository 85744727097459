import React from 'react'
import styled from 'styled-components'

import Button from '../../components/Button/Button'
import Text from '../../components/Text/Text'
import { connectorLocalStorageKey, walletLocalStorageKey } from './config'
import { Login, Config } from './types'

interface Props {
  walletConfig: Config
  login: Login
  onDismiss: () => void
  mb: string
}

const WalletCardButton = styled(Button)`
  width: 100%;
  min-width: 400px;
  height: 50px;
  background: ${({ theme }) => (theme.isDark ? theme.colorsV2?.light : theme.colorsV2.dark)};
  color: ${({ theme }) => theme.colorsV2?.textSecondary};
  margin-bottom: 10px;
  padding: 0 36px 0 20px;
  &:hover {
    background: ${({ theme }) => (theme.isDark ? theme.colorsV2?.dark2 : theme.colorsV2?.light)};
    color: ${({ theme }) => theme.colorsV2?.text};
    opacity: 1;
  }
  @media (max-width: 575px) {
    min-width: 100%;
  }

  > div {
    width: 100%;
    justify-content: space-between;
  }
`

const WalletCard: React.FC<Props> = ({ login, walletConfig, onDismiss, mb }) => {
  const { title, icon: Icon } = walletConfig

  const loginWallet = () => {
    login(walletConfig.connectorId)
    if (window.fathom) {
      window.fathom.trackGoal(walletConfig.fathomID, 0)
    }
    window.localStorage.setItem(connectorLocalStorageKey, walletConfig.connectorId)
    window.localStorage.setItem(walletLocalStorageKey, walletConfig.walletID || '')
    onDismiss()
  }

  return (
    <WalletCardButton
      width="100%"
      variant="tertiary"
      onClick={loginWallet}
      style={{ justifyContent: 'space-between' }}
      mb={mb}
      id={`wallet-connect-${title.toLocaleLowerCase()}`}
    >
      <Text bold color="primary" mr="16px" textAlign="left">
        {title}
      </Text>
      <Icon width="32px" />
    </WalletCardButton>
  )
}

export default WalletCard

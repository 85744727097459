import React, { CSSProperties, MutableRefObject, useCallback, useMemo } from 'react'
import { Text, useMatchBreakpoints } from 'uikit'
import styled from 'styled-components'
import { FixedSizeList } from 'react-window'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { useAccount, useNetwork } from 'wagmi'
import { useCurrencyBalance } from 'hooks/useWallet'
import { Currency } from 'swap-sdk/entities/currency'
import { currencyEquals, TokenClass } from 'swap-sdk/entities/token'
import { CurrencyAmount } from 'swap-sdk/entities/fractions/currencyAmount'
import Column from 'componentsV2/layout/Column'
import { RowFixed, RowBetween } from 'componentsV2/layout/Row'
import useTheme from 'hooks/useTheme'
import { Chain } from 'config/constants/types'
import { useCombinedActiveList } from '../../state/lists/hooks'
import { useIsUserAddedToken, useAllInactiveTokens } from '../../hooks/Tokens'
import { CurrencyLogo } from '../Logo'
import CircleLoader from '../Loader/CircleLoader'
import { isTokenOnList } from '../../utils'
import ImportRow from './ImportRow'

function currencyKey(currency: Currency): string {
  return currency instanceof TokenClass ? currency.address : currency.isNative ? currency.symbol : ''
}

const StyledBalanceText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  max-width: 5rem;
  text-overflow: ellipsis;
`

function Balance({ balance }: { balance: CurrencyAmount }) {
  return <StyledBalanceText title={balance.toExact()}>{balance.toSignificant(4)}</StyledBalanceText>
}

const MenuItem = styled(RowBetween)<{ disabled: boolean; selected: boolean }>`
  padding: 5px 15px;
  height: 56px;
  display: grid;
  grid-template-columns: auto minmax(auto, 1fr) minmax(0, 72px);
  grid-gap: 8px;
  border-radius: 10px;
  background: ${({ theme }) => (theme.isDark ? theme.colorsV2?.light : theme.colorsV2.dark)};
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  pointer-events: ${({ disabled }) => disabled && 'none'};
  :hover {
    background-color: ${({ theme, disabled }) =>
      !disabled && theme.isDark ? theme.colorsV2?.dark2 : theme.colorsV2?.light};
  }
  opacity: ${({ disabled, selected }) => (disabled || selected ? 0.2 : 1)};
`

function CurrencyRow({
  currency,
  onSelect,
  isSelected,
  otherSelected,
  style,
}: {
  currency: Currency
  onSelect: () => void
  isSelected: boolean
  otherSelected: boolean
  style: CSSProperties
}) {
  const { address: account } = useAccount()
  const key = currencyKey(currency)
  const selectedTokenList = useCombinedActiveList()
  const isOnSelectedList = isTokenOnList(selectedTokenList, currency)
  const customAdded = useIsUserAddedToken(currency)
  const balance = useCurrencyBalance(account ?? undefined, currency)
  const { theme } = useTheme()
  const { isXl } = useMatchBreakpoints()
  const isMobile = !isXl

  const styles = {
    ...style,
    height: Number(style.height) - 10,
    width: `calc(100% - ${isMobile ? '17px' : '26px'})`,
  }
  // only show add or remove buttons if not on selected list
  return (
    <MenuItem
      style={styles}
      className={`token-item-${key}`}
      onClick={() => (isSelected ? null : onSelect())}
      disabled={isSelected || otherSelected}
      selected={false}
    >
      <CurrencyLogo currency={currency} size="24px" />
      <Column>
        <Text color={theme.colorsV2?.text} bold>
          {currency.symbol}
        </Text>
        <Text color={theme.colorsV2?.textSecondary} small style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {!isOnSelectedList && customAdded && '(Added) •'} {currency.name}
        </Text>
      </Column>
      <RowFixed style={{ justifySelf: 'flex-end' }}>
        {balance ? <Balance balance={balance} /> : account ? <CircleLoader /> : null}
      </RowFixed>
    </MenuItem>
  )
}

export default function CurrencyList({
  height,
  currencies,
  selectedCurrency,
  onCurrencySelect,
  otherCurrency,
  fixedListRef,
  showETH,
  showImportView,
  setImportToken,
}: {
  height: number
  currencies: Currency[]
  selectedCurrency?: Currency | null
  onCurrencySelect: (currency: Currency) => void
  otherCurrency?: Currency | null
  fixedListRef?: MutableRefObject<FixedSizeList | undefined>
  showETH: boolean
  showImportView: () => void
  setImportToken: (token: TokenClass) => void
}) {
  const { chain } = useNetwork()
  const chainId = chain?.id || Chain.BSC_MAINNET

  const itemData: (Currency | undefined)[] = useMemo(() => {
    const formatted: (Currency | undefined)[] = showETH ? [Currency.ETHER[chainId], ...currencies] : currencies
    return formatted
  }, [currencies, showETH, chainId])

  const { isXl } = useMatchBreakpoints()
  const isMobile = !isXl

  const inactiveTokens: {
    [address: string]: TokenClass
  } = useAllInactiveTokens()

  const Row = useCallback(
    ({ data, index, style }) => {
      const currency: Currency = data[index]
      const isSelected = Boolean(selectedCurrency && currencyEquals(selectedCurrency, currency))
      const otherSelected = Boolean(otherCurrency && currencyEquals(otherCurrency, currency))
      const handleSelect = () => onCurrencySelect(currency)

      const token = wrappedCurrency(currency, chainId)

      const showImport =
        !currency.isNative && inactiveTokens && token && Object.keys(inactiveTokens).includes(token.address)

      if (showImport && token) {
        return <ImportRow style={style} token={token} showImportView={showImportView} setImportToken={setImportToken} />
      }
      return (
        <CurrencyRow
          style={style}
          currency={currency}
          isSelected={isSelected}
          onSelect={handleSelect}
          otherSelected={otherSelected}
        />
      )
    },
    [chainId, inactiveTokens, onCurrencySelect, otherCurrency, selectedCurrency, setImportToken, showImportView],
  )

  const itemKey = useCallback((index: number, data: any) => currencyKey(data[index]), [])

  return (
    <FixedSizeList
      height={height}
      ref={fixedListRef as any}
      width={`calc(100% + ${isMobile ? '18px' : '36px'})`}
      itemData={itemData}
      itemCount={itemData.length}
      itemSize={66}
      itemKey={itemKey}
    >
      {Row}
    </FixedSizeList>
  )
}

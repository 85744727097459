import { useCallback, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { Contract } from 'web3-eth-contract'
import { ethers } from 'ethers'
import { useDispatch } from 'react-redux'
import { fetchFarmUserDataAsync } from 'state/actions'
import { approve } from 'utils/callHelpers'
import { getAddress } from 'utils/addressHelpers'
import { getBep20Contract } from 'utils/contractHelpers'
import { fetchSalesUserDataAsync } from 'state/idos'
import { Address } from 'config/constants/types'
import { useToast } from 'state/hooks'

import { useAccount, useNetwork, useBalance } from 'wagmi'

import { useMasterchef, useIFAMasterContract, useSolvMarketContract } from './useContract'
import useWeb3 from './useWeb3'

// Approve a Farm
export const useApprove = (lpContract: Contract) => {
  const dispatch = useDispatch()
  const { account } = useWeb3React()
  const masterChefContract = useMasterchef()

  const handleApprove = useCallback(async () => {
    try {
      const tx = await approve(lpContract, masterChefContract, account)
      dispatch(fetchFarmUserDataAsync(account))
      return tx
    } catch (e) {
      return false
    }
  }, [account, dispatch, lpContract, masterChefContract])

  return { onApprove: handleApprove }
}

// Approve an IFO
export const useIfoApprove = (tokenContract: Contract, spenderAddress: string) => {
  const { account } = useWeb3React()
  const onApprove = useCallback(async () => {
    const tx = await tokenContract.methods.approve(spenderAddress, ethers.constants.MaxUint256).send({ from: account })
    return tx
  }, [account, spenderAddress, tokenContract])

  return onApprove
}

// Approve a Farm
export const useIdoApprove = (masterAddress: string, address: Address) => {
  const web3 = useWeb3()
  const dispatch = useDispatch()
  const { toastErrorV2 } = useToast()

  const { address: account } = useAccount()
  const { chain } = useNetwork()
  const tokenAdress = getAddress(address, chain.id)
  const tokenContract = getBep20Contract(tokenAdress, web3)
  const IFAMasterContract = useIFAMasterContract(masterAddress)
  const [isLoading, setIsLoading] = useState(false)
  const balance = useBalance({
    address: account,
  })

  const handleApprove = useCallback(
    async (amount) => {
      try {
        setIsLoading(true)
        if (balance?.data?.value <= 0) {
          throw new Error('No gas fees')
        }

        const gasPrice = await web3.eth.getGasPrice()
        const gasAmount = await tokenContract.methods
          .approve(IFAMasterContract.options.address, amount ?? ethers.constants.MaxUint256)
          .estimateGas({ from: account })

        const tx = await approve(tokenContract, IFAMasterContract, account, amount, gasPrice, gasAmount)
        setIsLoading(false)
        dispatch(fetchSalesUserDataAsync(account))
        return tx
      } catch (e: any) {
        setIsLoading(false)
        toastErrorV2('Error', e.message)
        return false
      }
    },
    [account, tokenContract, IFAMasterContract],
  )

  return { onApprove: handleApprove, isLoading }
}

export const useSolvSaleApprove = (address: Address, marketAddress: string) => {
  const web3 = useWeb3()
  const { account, chainId } = useWeb3React()
  const solvMarketContract = useSolvMarketContract(marketAddress)
  const tokenAdress = getAddress(address, chainId)
  const tokenContract = getBep20Contract(tokenAdress, web3)
  const [isLoading, setIsLoading] = useState(false)

  const handleApprove = useCallback(async () => {
    try {
      setIsLoading(true)
      const tx = await approve(tokenContract, solvMarketContract, account)
      setIsLoading(false)
      return tx
    } catch (e) {
      setIsLoading(false)
      return false
    }
  }, [account, tokenContract, solvMarketContract, setIsLoading])

  return { onApprove: handleApprove, isLoading }
}

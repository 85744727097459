import { Box } from '@mui/material'
import React, { cloneElement, Children, ReactElement } from 'react'
import styled from 'styled-components'
import { Text } from '../Text'
import { NotificationDotProps } from './types'

const NotificationDotRoot = styled.span`
  display: inline-flex;
  position: relative;
`

const Dot = styled(Box)<{ show: boolean }>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  position: absolute;
  top: -3px;
  right: -5px;
  width: 28px;
  height: 18px;
  pointer-events: none;
  border-radius: 9px;
  background-color: ${({ theme }) => theme.colorsV2?.feedbacks?.dangerText};
  align-items: center;
  justify-content: center;
  border: solid 2px ${({ theme }) => theme.colorsV2?.swap?.background};
`

const NotificationDot: React.FC<NotificationDotProps> = ({ show = false, children, ...props }) => {
  return (
    <NotificationDotRoot>
      {Children.map(children, (child: ReactElement) => cloneElement(child, props))}
      <Dot show={show}>
        <Text fontSize="10px" bold color="white">
          Pro
        </Text>
      </Dot>
    </NotificationDotRoot>
  )
}

export default NotificationDot

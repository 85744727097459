import { beamColorsV2, darkColorsV2, lightColorsV2, carvColorsV2, particleColorsV2 } from 'uikit/theme/colorsV2'
import { TableTheme } from './IFSortableTable'

export const light: TableTheme = {
  thColor: lightColorsV2.textSecondary,
}
export const dark: TableTheme = {
  thColor: darkColorsV2.textThirdly,
}
export const blue: TableTheme = {
  thColor: lightColorsV2.textSecondary,
}
export const beam: TableTheme = {
  thColor: beamColorsV2.textSecondary,
}
export const carv: TableTheme = {
  thColor: carvColorsV2.textSecondary,
}

export const particle: TableTheme = {
  thColor: particleColorsV2.textSecondary,
}

import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = ({ fillColor }) => {
  return (
    <Svg width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.25 3C12.0889 3 12.1822 4.10351 11.5792 4.68675C10.4513 5.77764 9.75 7.30693 9.75 9C9.75 10.6931 10.4513 12.2224 11.5792 13.3132C12.1822 13.8965 12.0889 15 11.25 15C7.93629 15 5.25 12.3137 5.25 9C5.25 5.68629 7.93629 3 11.25 3Z"
        fill={fillColor}
        fillOpacity="0.7"
      />
    </Svg>
  )
}

export default Icon

import React from 'react'
import styled from 'styled-components'

interface Props {
  size?: number
}

const SVGContainer = styled.svg`
  path:nth-child(2) {
    fill: ${({ theme }) => (theme.isDark ? '#2E3466' : '#FFFFFF')};
  }
`

const Revoked: React.FC<Props> = ({ size = 86 }) => {
  return (
    <SVGContainer width={size} height={size} viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M49.5711 7.07107L78.9289 36.4289C82.5324 40.0324 82.5324 45.9676 78.9289 49.5711L49.5711 78.9289C45.9676 82.5324 40.0324 82.5324 36.4289 78.9289L7.07108 49.5711C3.46759 45.9676 3.46758 40.0324 7.07108 36.4289L36.4289 7.07107C40.1384 3.36159 45.9676 3.46758 49.5711 7.07107Z"
        fill="url(#paint0_linear_30947_26213)"
      />
      <path d="M38.9819 45.8473C38.9819 48.0507 40.7681 49.8369 42.9715 49.8369C45.1749 49.8369 46.9611 48.0507 46.9611 45.8473V28.3471C46.9611 26.1436 45.1749 24.3574 42.9715 24.3574C40.7681 24.3574 38.9819 26.1436 38.9819 28.3471V45.8473ZM42.9715 62.3574C46.1632 62.3574 48.5 60.36 48.5 57.6805C48.5 55.2446 46.2202 52.9549 42.9715 52.9549C39.8368 52.9549 37.5 55.1959 37.5 57.6805C37.5 60.36 39.9508 62.3574 42.9715 62.3574Z" />
      <defs>
        <linearGradient
          id="paint0_linear_30947_26213"
          x1="43"
          y1="81.1633"
          x2="43"
          y2="-12.9439"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ED696C" stopOpacity="0.53" />
          <stop offset="1" stopColor="#ED696C" />
        </linearGradient>
      </defs>
    </SVGContainer>
  )
}

export default Revoked

import { Box } from '@mui/material'
import DefaultIFTooltip from 'componentsV2/DefaultIFTooltip'
import IFTypography from 'componentsV2/IFTypography/IFTypography'
import { useTranslation } from 'contexts/Localization'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useProjectFromId, useSaleUserData } from 'state/idos/hooks'
import { isSubscribeableIDO, isClaimableIDO, isDroppedIDO, isPurchaseableIDO } from 'state/idos/saleUtil'
import { ClaimableIDO, DroppedIDO, PurchasableIDO, SubscribeableIDO } from 'state/v2_types'
import styled, { useTheme } from 'styled-components'
import { Button, Flex } from 'uikit'

interface PostSaleInfoProps {
  hide?: boolean
}

const Container = styled.div`
  padding: 30px 19px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background: ${({ theme }) => (theme.isDark ? theme.colorsV2.dark : theme.colorsV2?.dark2)};
  display: flex;
  flex-direction: column;
  align-items: center;
`

const LatestUpdatesBtn = styled(Button)<{ maxWidth: string }>`
  background: transparent;
  border: 1px solid ${({ theme }) => theme.colorsV2?.textThirdly};
  flex-grow: 1;
  max-width: ${({ maxWidth }) => maxWidth};
`

const LaunchAppBtn = styled(Button)<{ maxWidth: string }>`
  span {
    color: #fff !important;
  }
  flex-grow: 1;
  max-width: ${({ maxWidth }) => maxWidth};
`

const PostSaleInfo: React.FC<PostSaleInfoProps> = ({ hide = false }) => {
  const { t } = useTranslation()
  const { projectID, saleID } = useParams<{ projectID: string; saleID?: string }>()
  const project = useProjectFromId(projectID)
  const userData = useSaleUserData(saleID)
  const { isDark } = useTheme()
  if (hide) {
    return null
  }
  if (project == null || project.sales == null) return null
  const sale = saleID == null ? project.sales[0] : project.sales.find((s) => s.id === saleID)
  if (sale == null) return null
  const { appLink, latestUpdatesLink, postSaleText } = project.postSaleInfo ?? {}
  if (!appLink && !latestUpdatesLink) return null

  // Only display for PaidStakeDrop and PaidStakeClaim
  if (
    !(
      isSubscribeableIDO(sale as SubscribeableIDO) &&
      isPurchaseableIDO(sale as PurchasableIDO) &&
      (isClaimableIDO(sale as ClaimableIDO) || isDroppedIDO(sale as DroppedIDO))
    )
  ) {
    return null
  }
  const {
    purchasePeriod: { endTime },
  } = sale as PurchasableIDO

  const hasWithdrawn = userData?.hasWithdrawn ?? false
  const purchasePeriodPassed = new Date() > new Date(endTime)
  if (!hasWithdrawn && !purchasePeriodPassed) {
    return null
  }
  const hasTwoLinks = latestUpdatesLink && appLink
  const btnMaxWidth = hasTwoLinks ? '156px' : '200px'
  const latestUpdatesBtnHandler = () => {
    if (window.fathom) {
      window.fathom.trackGoal('L2MYDJX9', 0)
    }
    window.open(latestUpdatesLink, '_blank')
  }
  const launchAppBtnHandler = () => {
    if (window.fathom) {
      window.fathom.trackGoal('VKGXSNGQ', 0)
    }
    window.open(appLink, '_blank')
  }
  return (
    <Container>
      <Box
        marginBottom="15px"
        maxWidth="350px"
        textAlign="center"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <IFTypography lineHeight="20px" variant="body2" ifcolor={isDark ? 'textSecondary' : 'text'}>
          {postSaleText ?? t('Explore more with %projectName%!', { projectName: project.title })}
          <Box display="inline-flex" position="absolute">
            <DefaultIFTooltip
              placement="top"
              title={t('By clicking the link below, you will be redirected to an external site.')}
            />
          </Box>
          <Box display="inline-flex" width="24px" />
        </IFTypography>
      </Box>
      <Flex width="100%" justifyContent="center">
        {latestUpdatesLink && (
          <LatestUpdatesBtn maxWidth={btnMaxWidth} onClick={latestUpdatesBtnHandler}>
            <IFTypography fontWeight="bold" variant="button" ifcolor="textThirdly">
              {t('Latest Updates')}
            </IFTypography>
          </LatestUpdatesBtn>
        )}
        {hasTwoLinks && <Box width="15px" />}
        {appLink && (
          <LaunchAppBtn maxWidth={btnMaxWidth} onClick={launchAppBtnHandler}>
            <IFTypography fontWeight="bold" variant="button">
              {t('Launch App')}
            </IFTypography>
          </LaunchAppBtn>
        )}
      </Flex>
    </Container>
  )
}

export default PostSaleInfo

/* eslint-disable */
import React from 'react'
import { useTheme } from 'styled-components'
import Carousel from 'react-material-ui-carousel'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import './index.css'

const IFCarousel: React.FC<{
  height: number
  animation?: 'fade' | 'slide'
  autoPlay?: boolean
  duration?: number
  onChange?: (number) => void
  navButtonsAlwaysVisible?: boolean
  indicators?: boolean
  children: React.ReactNode
}> = ({ children, height, animation, autoPlay, duration, onChange, navButtonsAlwaysVisible, indicators }) => {
  const theme = useTheme()
  return (
    <Carousel
      className="carousel"
      animation={animation}
      duration={duration}
      onChange={onChange}
      height={height}
      autoPlay={autoPlay}
      indicators={indicators}
      navButtonsAlwaysVisible={navButtonsAlwaysVisible}
      navButtonsProps={{
        className: 'navButton',
        style: {
          backgroundColor: theme.isDark ? theme.colorsV2.main3 : theme.colorsV2?.dark2,
        },
      }}
      PrevIcon={
        <NavigateBeforeIcon
          style={{
            fill: theme.isDark ? theme.colorsV2?.text : '#5A5A5A',
          }}
        />
      }
      NextIcon={
        <NavigateNextIcon
          style={{
            fill: theme.isDark ? theme.colorsV2?.text : '#5A5A5A',
          }}
        />
      }
      indicatorContainerProps={{
        className: 'indicatorContainer',
        style: {
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          bottom: '5px',
          zIndex: 10,
        },
      }}
      indicatorIconButtonProps={{
        style: {
          margin: '0 2.5px',
          backgroundColor: theme.colorsV2.exploreIndicator,
          opacity: theme.isDark ? 1 : 0.3,
          color: theme.colorsV2.exploreIndicator,
          borderRadius: 10,
          height: 6,
          width: 6,
        },
      }}
      activeIndicatorIconButtonProps={{
        style: {
          backgroundColor: theme.colorsV2.exploreIndicatorActive,
          color: theme.colorsV2.exploreIndicatorActive,
          width: 30,
          opacity: 1,
          height: 6,
          borderRadius: 10,
        },
      }}
    >
      {children}
    </Carousel>
  )
}

export default IFCarousel

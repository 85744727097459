/* eslint-disable import/no-cycle */

import invariant from 'tiny-invariant'
import { BigintIsh } from 'swap-sdk/constants'
import JSBI from 'jsbi'
import { TokenClass } from '../token'
import { CurrencyAmount } from './currencyAmount'

export class TokenAmount extends CurrencyAmount {
  public readonly token: TokenClass

  // amount _must_ be raw, i.e. in the native representation
  public constructor(token: TokenClass, amount: BigintIsh) {
    super(token, amount)
    this.token = token
  }

  public add(other: TokenAmount): TokenAmount {
    invariant(this.token.equals(other.token), 'TOKEN')
    return new TokenAmount(this.token, JSBI.add(this.raw, other.raw))
  }

  public subtract(other: TokenAmount): TokenAmount {
    invariant(this.token.equals(other.token), 'TOKEN')
    return new TokenAmount(this.token, JSBI.subtract(this.raw, other.raw))
  }
}

export default null

import React from 'react'
import styled, { DefaultTheme, useTheme } from 'styled-components'
import CheckmarkCircleIcon from '../Svg/Icons/CheckmarkCircle'
import ErrorIcon from '../Svg/Icons/Error'
import BlockIcon from '../Svg/Icons/Block'
import InfoIcon from '../Svg/Icons/Info'
import CheckmarkCircleV2Icon from '../Svg/Icons/CheckmarkCircleV2'
import ErrorV2Icon from '../Svg/Icons/ErrorV2'
import IFIcon from '../Svg/Icons/IF'
import CloseIcon from '../Svg/Icons/Close'
import { Text } from '../Text'
import { IconButton } from '../Button'
import Flex from '../Box/Flex'
import { AlertProps, variants } from './types'

interface ThemedIconLabel {
  variant: AlertProps['variant']
  theme: DefaultTheme
  hasDescription?: boolean
}

const getThemeColor = ({ theme, variant = variants.INFO }: ThemedIconLabel) => {
  switch (variant) {
    case variants.ANNOUNCEMENT:
      return 'transparent'
    case variants.DANGER:
      return theme.colors?.failure
    case variants.WARNING:
      return theme.colors?.warning
    case variants.SUCCESS:
      return theme.colors?.success
    case variants.SUCCESS_V2:
      return 'transparent'
    case variants.DANGER_V2:
      return 'transparent'
    case variants.INFO:
    default:
      return theme.colors?.secondary
  }
}

const getIcon = (variant: AlertProps['variant'] = variants.INFO) => {
  switch (variant) {
    case variants.DANGER:
      return BlockIcon
    case variants.DANGER_V2:
      return ErrorV2Icon
    case variants.WARNING:
      return ErrorIcon
    case variants.SUCCESS:
      return CheckmarkCircleIcon
    case variants.SUCCESS_V2:
      return CheckmarkCircleV2Icon
    case variants.ANNOUNCEMENT:
      return IFIcon
    case variants.INFO:
    default:
      return InfoIcon
  }
}

const getAlertBackground = ({ theme, variant = variants.INFO }: ThemedIconLabel) => {
  switch (variant) {
    case variants.SUCCESS_V2:
      return theme.colorsV2?.feedbacks?.successBg
    case variants.DANGER_V2:
      return theme.colorsV2?.feedbacks?.dangerBg
    case variants.INFO_V2:
      return theme.colorsV2?.feedbacks?.infoBg
    default:
      return theme.alert?.background
  }
}

const getAlertBorder = ({ theme, variant = variants.INFO }: ThemedIconLabel) => {
  switch (variant) {
    case variants.SUCCESS_V2:
      return theme.colorsV2?.feedbacks?.successText
    case variants.DANGER_V2:
      return theme.colorsV2?.feedbacks?.dangerText
    case variants.INFO_V2:
      return theme.colorsV2?.feedbacks?.infoText
    default:
      return 'none'
  }
}

const getBackdropFilter = ({ variant = variants.INFO }: ThemedIconLabel) => {
  switch (variant) {
    case variants.SUCCESS_V2:
    case variants.DANGER_V2:
    case variants.INFO_V2:
      return 'blur(40px)'
    default:
      return 'blur(40px)'
  }
}

const getTextColor = ({ theme, variant = variants.INFO }: ThemedIconLabel) => {
  switch (variant) {
    case variants.SUCCESS_V2:
      return theme.colorsV2?.feedbacks?.successText
    case variants.DANGER_V2:
      return theme.colorsV2?.feedbacks?.dangerText
    case variants.INFO_V2:
      return theme.colorsV2?.text
    default:
      return ''
  }
}

const IconLabel = styled.div<ThemedIconLabel>`
  background-color: ${getThemeColor};
  border-radius: 16px 0 0 16px;
  color: ${({ theme }) => theme.alert?.background};
  padding: 24px 11px 0 19px;
`

const withHandlerSpacing = 32 + 12 + 8 // button size + inner spacing + handler position
const Details = styled.div<{ hasHandler: boolean; variant?: string }>`
  flex: 1;
  padding-bottom: 12px;
  padding-left: ${({ variant }) => (variant === variants.ANNOUNCEMENT ? `0` : '15px')};
  padding-right: ${({ hasHandler, variant }) =>
    hasHandler ? `${variant === variants.ANNOUNCEMENT ? withHandlerSpacing - 12 : withHandlerSpacing}px` : '12px'};
  padding-top: 20px;
`

const CloseHandler = styled.div`
  border-radius: 0 16px 16px 0;
  right: 11px;
  position: absolute;
  top: 22px;
`

const StyledAlert = styled(Flex)<{ toastBackground?: string; toastBorder?: string; variant?: AlertProps['variant'] }>`
  position: relative;
  background-color: ${({ theme, variant }) => getAlertBackground({ theme, variant })};
  background: ${({ toastBackground }) => toastBackground};
  backdrop-filter: ${({ theme, variant }) => getBackdropFilter({ theme, variant })};
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  border: 1px solid
    ${({ theme, toastBorder, variant }) =>
      toastBorder && toastBorder.length > 0 ? toastBorder : getAlertBorder({ theme, variant })};
  box-shadow: 0px 20px 36px -8px rgba(14, 14, 44, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.05);
`

const Alert: React.FC<AlertProps> = ({
  title,
  children,
  variant,
  onClick,
  toastBackground,
  toastIcon,
  toastBorder,
}) => {
  const Icon = getIcon(variant)
  const theme = useTheme()

  return (
    <StyledAlert toastBackground={toastBackground} toastBorder={toastBorder} variant={variant}>
      {toastIcon ? (
        <Flex justifyContent="center" paddingLeft="15px" paddingY="20px">
          <img src={toastIcon} alt="toastIcon" width="100%" style={{ height: 36 }} />
        </Flex>
      ) : (
        <IconLabel variant={variant} hasDescription={!!children}>
          <Icon color={getTextColor({ theme, variant })} width="24px" />
        </IconLabel>
      )}

      <Details hasHandler={!!onClick} variant={variant}>
        {typeof title === 'string' ? (
          <Text bold color={getTextColor({ theme, variant })}>
            {title}
          </Text>
        ) : (
          title
        )}
        {typeof children === 'string' ? <Text as="p">{children}</Text> : children}
      </Details>
      {onClick && (
        <CloseHandler>
          <IconButton scale="sm" variant="text" onClick={onClick}>
            <CloseIcon width="14px" />
          </IconButton>
        </CloseHandler>
      )}
    </StyledAlert>
  )
}

export default Alert

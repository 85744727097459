import React, { CSSProperties } from 'react'
import { Button, Text, CheckmarkCircleIcon, useMatchBreakpoints } from 'uikit'
import { RowFixed } from 'componentsV2/layout/Row'
import Column from 'componentsV2/layout/Column'
import CurrencyLogo from 'componentsV2/Logo/CurrencyLogo'
import styled from 'styled-components'
import { useIsUserAddedToken, useIsTokenActive } from 'hooks/Tokens'
import { useTranslation } from 'contexts/Localization'
import { TokenClass } from 'swap-sdk/entities/token'
import useTheme from 'hooks/useTheme'

const TokenSection = styled.div<{ dim?: boolean }>`
  padding: 5px 15px;
  height: 56px;
  display: grid;
  grid-template-columns: auto minmax(auto, 1fr) auto;
  grid-gap: 8px;
  align-items: center;
  border-radius: 10px;
  background: ${({ theme }) => (theme.isDark ? theme.colorsV2?.light : theme.colorsV2.dark)};

  opacity: ${({ dim }) => (dim ? '0.4' : '1')};
`

const CheckIcon = styled(CheckmarkCircleIcon)`
  height: 16px;
  width: 16px;
  margin-right: 6px;
  stroke: ${({ theme }) => theme.colors?.success};
`

const NameOverflow = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 140px;
  font-size: 12px;
`

export default function ImportRow({
  token,
  style,
  dim,
  showImportView,
  setImportToken,
}: {
  token: TokenClass
  style?: CSSProperties
  dim?: boolean
  showImportView: () => void
  setImportToken: (token: TokenClass) => void
}) {
  // globals

  const { t } = useTranslation()
  const { theme } = useTheme()

  // check if already active on list or local storage tokens
  const isAdded = useIsUserAddedToken(token)
  const isActive = useIsTokenActive(token)
  const { isXl } = useMatchBreakpoints()
  const isMobile = !isXl

  const styles = style && {
    ...style,
    height: Number(style.height) - 10,
    width: `calc(100% - ${isMobile ? '17px' : '26px'})`,
  }
  return (
    <TokenSection style={styles}>
      <CurrencyLogo currency={token} size="24px" style={{ opacity: dim ? '0.6' : '1' }} />
      <Column style={{ opacity: dim ? '0.6' : '1' }}>
        <Text color={theme.colorsV2?.text} bold>
          {token.symbol}
        </Text>
        <Text color={theme.colorsV2?.textSecondary} small style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <NameOverflow title={token.name}>{token.name}</NameOverflow>
        </Text>
      </Column>
      {!isActive && !isAdded ? (
        <Button
          scale="sm"
          variant="quaternary"
          width="fit-content"
          onClick={() => {
            if (setImportToken) {
              setImportToken(token)
            }
            showImportView()
          }}
        >
          {t('Import')}
        </Button>
      ) : (
        <RowFixed style={{ minWidth: 'fit-content' }}>
          <CheckIcon />
          <Text color="success">Active</Text>
        </RowFixed>
      )}
    </TokenSection>
  )
}

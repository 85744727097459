import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 12 9" {...props}>
      <path
        d="M4.55316 8.14654C4.3579 8.3418 4.04132 8.3418 3.84606 8.14654L0.953162 5.25365C0.7579 5.05839 0.757901 4.7418 0.953163 4.54654L1.51574 3.98397C1.71073 3.78897 2.02679 3.78866 2.22216 3.98328L3.84606 5.60091C4.04143 5.79553 4.35748 5.79522 4.55248 5.60022L9.7758 0.3769C9.97155 0.18115 10.2891 0.181711 10.4842 0.378151L11.0485 0.946546C11.2426 1.14201 11.2421 1.45763 11.0473 1.6524L4.55316 8.14654Z"
        fillOpacity="0.3"
      />
    </Svg>
  )
}

export default Icon

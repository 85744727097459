import { DefaultTheme } from 'styled-components'
import { light as lightAlert } from '../components/Alert/theme'
import { light as lightCard } from '../components/Card/theme'
import { light as lightRadio } from '../components/Radio/theme'
import { light as lightToggle } from '../components/Toggle/theme'
import { light as lightFooter } from '../widgets/Footer/theme'
import { blue as lightNav } from '../widgets/Menu/theme'
import { light as lightTable } from 'componentsV2/IFSortableTable/theme'
import { light as lightAccordion } from 'componentsV2/IFAccordion/theme'
import { light as lightModal } from '../widgets/Modal/theme'
import { aethir as lightSale } from 'views/Sale/theme'
import { aethir as lightCommon } from './common'

import base from './base'
import { lightColors } from './colors'
import { aethirColorsV2 } from './colorsV2'

const lightTheme: DefaultTheme = {
  ...base,
  isDark: false,
  useColorsOnly: false,
  alert: lightAlert,
  colors: lightColors,
  card: lightCard,
  toggle: lightToggle,
  table: lightTable,
  sale: lightSale,
  common: lightCommon,
  accordion: lightAccordion,
  footer: lightFooter,
  nav: lightNav,
  colorsV2: aethirColorsV2,
  modal: lightModal,
  radio: lightRadio,
}

export default lightTheme

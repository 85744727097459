import React, { useMemo } from 'react'
import styled from 'styled-components'

import { Box, Flex } from 'uikit'
import useTheme from 'hooks/useTheme'
import { useTranslation } from 'contexts/Localization'
import { StartLight, StartDark } from 'componentsV2/Icons/Purchase/Start'
import IFTypography from 'componentsV2/IFTypography/IFTypography'
import { SubscribeableIDO } from 'state/v2_types'

import { StepRequirementEnum } from 'state/types'
import SaleDetailCard from '../SaleDetailCard'

const AllocationAmount = styled.div`
  max-width: 300px;
  width: 100%;
  padding: 20px 50px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colorsV2?.light};
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 3px 0 10px 0;
  width: 100%;
`

const Instruction = styled.div`
  max-width: 300px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 3px 0 10px 0;
  width: 100%;
`

const Logo = styled.img`
  width: 36px;
  height: 36px;
  margin-right: 8px;
`

type StakingEndCardProps = {
  sale: SubscribeableIDO
  estimatedAllocation: any
}

const StakingEndCard: React.FC<StakingEndCardProps> = ({ sale, estimatedAllocation }) => {
  const { t } = useTranslation()
  const { theme } = useTheme()
  const { token, chainId, stepRequirement } = sale
  const { image } = token

  return (
    <SaleDetailCard
      requireKYC={!!stepRequirement?.includes(StepRequirementEnum.KYC)}
      targetNetworkID={chainId}
      countdownInfo={undefined}
      title={t('Thank you for your participation.')}
      imageInfo={{
        image: theme.isDark ? <StartDark /> : <StartLight />,
      }}
      linkBtnInfo={undefined}
    >
      <>
        {parseFloat(estimatedAllocation) > 1 && (
          <Box marginBottom={2}>
            <IFTypography variant="body2" ifcolor="textSecondary" fontWeight="regular">
              {t(`You're on the list`)}
            </IFTypography>
          </Box>
        )}
        <AllocationAmount>
          <IFTypography variant="body2" ifcolor="textSecondary" fontWeight="regular">
            {t('Your Allocation')}
          </IFTypography>

          <Flex justifyContent="center" alignItems="center">
            <Logo src={image} alt="logo" />
            <IFTypography variant="h2" fontWeight="regular">
              {estimatedAllocation.decimalPlaces(2).toNumber().toLocaleString()}
            </IFTypography>
          </Flex>
        </AllocationAmount>
      </>
    </SaleDetailCard>
  )
}

export default StakingEndCard

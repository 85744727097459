import { Box } from '@mui/material'
import BigNumber from 'bignumber.js'
import Logo from 'componentsV2/Logo/Logo'
import IFTypography from 'componentsV2/IFTypography/IFTypography'
import { useTranslation } from 'contexts/Localization'
import React, { useContext, useMemo } from 'react'
import { isSubscribeableIDO, isDroppedIDO, isPurchaseableIDO, isFixedAllocationIDO } from 'state/idos/saleUtil'
import { SubscribeableIDO, IDO, DroppedIDO, PurchasableIDO, FixedAllocationIDO } from 'state/v2_types'
import styled, { useTheme } from 'styled-components'
import { getAllocatedAmount } from './helper'
import { Brands, CompanyContext } from 'contexts/CompanyContext'
import { convertFromWei } from 'utils/formatBalance'
import { useGetAllocation, useGetMaxPaymentInWeiV2 } from 'hooks/useIFASale'
import { useSaleUserData } from 'state/idos/hooks'

interface Props {
  sale: IDO
}

interface BottomInfoProps {
  sale: IDO
  isMobile?: boolean
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  & > div {
    display: flex;
    justify-content: space-between;
    &:not(:first-child):not(:last-child) {
      margin-top: 10px;
    }
  }
`

const PercentagebarContainer = styled.div`
  background: ${({ theme }) => (theme.isDark ? theme.colorsV2?.dark2 : theme.colorsV2?.light)};
  border-radius: 41px;
  height: 8px;
  display: flex;

  & > div {
    background: linear-gradient(249.75deg, #ff8686 0.91%, #ff0e73 91.5%);
    border-radius: 41px;
    height: 8px;
  }
`

const RemainingTokensSection = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  padding: 15px 0px;
  flex: 2;
`

const Progessbar = styled.div<{ $canPurchase: boolean }>`
  border-radius: 5px;
  display: flex;
  background: ${({ theme }) => (theme.isDark ? theme.colorsV2?.dark2 : theme.colorsV2?.light)};
  border: ${({ theme }) => (theme.isDark ? theme.colorsV2?.light : theme.colorsV2?.textDisabled)};
  height: 7px;
  & > div {
    border-radius: 20px;
    background: ${({ theme, $canPurchase }) =>
      $canPurchase
        ? theme.colorsV2?.textThirdly
        : theme.isDark
        ? theme.colorsV2.textDisabled
        : theme.colorsV2.feedbacks.disableBg};
    height: 7px;
  }
`

const Percentagebar: React.FC<Props> = ({ sale }) => {
  const { t } = useTranslation()
  const { symbol: saleTokenSymbol } = sale.token
  const { saleAmount } = sale
  const saleTokenTotalSupply = new BigNumber(saleAmount)
  const allocated = getAllocatedAmount(sale)
  const isAirdrop = isDroppedIDO(sale as DroppedIDO)
  const isPurchaseable = isPurchaseableIDO(sale as PurchasableIDO)

  if (allocated == null || allocated.isNaN() || !isPurchaseable) {
    return null
  }
  const percentage = allocated.div(saleTokenTotalSupply).multipliedBy(100).decimalPlaces(2).toString()

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <PercentagebarContainer>
        <Box width={`${percentage}%`} />
      </PercentagebarContainer>
      <Box display="flex" justifyContent="space-between" marginTop="5px">
        <IFTypography variant="caption" commonColor="textBottomInfoCard">
          {allocated.toNumber().toLocaleString()}/{saleTokenTotalSupply.toNumber().toLocaleString()} {saleTokenSymbol}{' '}
          {t(isAirdrop && !isPurchaseable ? 'Allocated' : 'Sold')}
        </IFTypography>
        <IFTypography variant="caption" ifcolor="text">
          {percentage}%
        </IFTypography>
      </Box>
    </Box>
  )
}

const BottomInfo: React.FC<BottomInfoProps> = ({ sale, isMobile }) => {
  const { t } = useTranslation()
  const isPurchaseable = isPurchaseableIDO(sale as PurchasableIDO)
  const isSubscribeable = isSubscribeableIDO(sale as SubscribeableIDO)
  const isAirdropped = isDroppedIDO(sale as DroppedIDO)
  const isFixedAllocation = isFixedAllocationIDO(sale as FixedAllocationIDO)
  const { isJustSale } = useContext(CompanyContext)
  const { allo: userAllocationInWEI } = useGetAllocation(sale.id, sale.isPrivate)
  const pricePerNode = !isPurchaseable ? 0 : (sale as PurchasableIDO).purchasePeriod.salePrice
  const paymentToken = !isPurchaseable ? null : (sale as PurchasableIDO).paymentToken

  // const {
  //   purchasePeriod,
  //   token: saleToken,
  //   chainId,
  //   saleChainId = chainId,
  //   saleAddress,
  //   saleTokenDefaultName,
  // } = sale as PurchasableIDO
  // const { publicAllocation, totalPaymentReceived, salePrice, maxTotalPurchasable } = purchasePeriod
  // const userAllocationInWei = new BigNumber(publicAllocation).isGreaterThan(0)
  //   ? publicAllocation.toString()
  //   : userAllocationInWEI
  // const userAllocation = userAllocationInWei && convertFromWei(new BigNumber(userAllocationInWei), saleToken.decimals)
  // const userData = useSaleUserData(sale.id)
  // const maxPaymentAllocationInWei = useGetMaxPaymentInWeiV2(saleAddress, userAllocationInWei)
  // const paymentDecimals = paymentToken?.decimals || 0
  // const paymentTokenNeeded = convertFromWei(maxPaymentAllocationInWei, paymentDecimals)
  // const totalPurchasedAllocation = new BigNumber(totalPaymentReceived).dividedBy(salePrice)
  // const canPurchase = paymentDecimals > 0 && paymentTokenNeeded.isGreaterThan(0.001)

  // const totalPercentageInt = totalPurchasedAllocation
  //   .div(new BigNumber(maxTotalPurchasable))
  //   .multipliedBy(100)
  //   .isGreaterThan(100)
  //   ? new BigNumber(100)
  // : totalPurchasedAllocation.div(new BigNumber(maxTotalPurchasable)).multipliedBy(100)

  // const totalAllocation = userAllocation || userData?.userTokenAllocation || new BigNumber(0)

  // const totalPercentage = `${totalAllocation.isEqualTo(0) ? '0' : totalPercentageInt.decimalPlaces(2).toString()}%`

  const infoItems = useMemo(() => {
    const ret = []

    const stakingToken = !isSubscribeable ? null : (sale as SubscribeableIDO).stakingToken
    const idoToken = sale.token

    // Aethir

    if (stakingToken?.symbol) {
      ret.push(
        <div key="stakingRequest">
          <IFTypography variant="caption" commonColor="textBottomInfoCard">
            {t('Staking Request')}
          </IFTypography>
          <Box display="flex" alignItems="center">
            <Logo srcs={[`/images/tokens/${stakingToken?.symbol}.png`]} width={18} height={18} alt="token" />
            <Box marginLeft="5px" display="inline-block">
              <IFTypography variant="caption" ifcolor="text">
                {stakingToken?.symbol ?? '-'}
              </IFTypography>
            </Box>
          </Box>
        </div>,
      )
    }

    if (isFixedAllocation && isPurchaseable) {
      const saleTokenAllocation = (sale as FixedAllocationIDO).saleTokenAllocationOverride
      const purchaseCap = new BigNumber(saleTokenAllocation).toNumber().toLocaleString()
      ret.push(
        <div>
          <IFTypography variant="caption" commonColor="textBottomInfoCard">
            {t('Purchase Cap')}
          </IFTypography>
          <IFTypography variant="caption" ifcolor="text">
            {saleTokenAllocation != null ? `${purchaseCap} ${idoToken.symbol}` : ''}
          </IFTypography>
        </div>,
      )
    } else if ((sale as SubscribeableIDO).subscribePeriod) {
      const {
        subscribePeriod: { isLimited, maxTotalStake },
      } = sale as SubscribeableIDO
      const stakingCap =
        isLimited && maxTotalStake
          ? `${new BigNumber(maxTotalStake).decimalPlaces(2).toNumber().toLocaleString()} ${stakingToken.symbol}`
          : '-'

      {
        !isJustSale &&
          ret.push(
            <div key="stakingCap">
              <IFTypography variant="caption" commonColor="textBottomInfoCard">
                {t('Staking Cap')}
              </IFTypography>
              <IFTypography variant="caption" ifcolor="text">
                {stakingCap}
              </IFTypography>
            </div>,
          )
      }
    }

    {
      isJustSale &&
        !ret.some((elem) => elem.key === 'tokenForStakeOne') &&
        ret.push(
          <div key="tokenForStakeOne">
            <IFTypography variant="caption" commonColor="textBottomInfoCard">
              Price per Node
              {}
            </IFTypography>
            <IFTypography variant="caption" commonColor="textBottomInfoCard">
              {new BigNumber(pricePerNode).decimalPlaces(4).toString()} {paymentToken?.symbol}
            </IFTypography>
          </div>,
        )
    }
    {
      !isMobile &&
        ret.push(
          <div key="tokenForStake">
            <IFTypography variant="caption" commonColor="textBottomInfoCard">
              {isJustSale ? `Node for Sale` : t(`Token For ${isAirdropped ? 'Drop' : 'Sale'}`)}
              {}
            </IFTypography>
            <IFTypography variant="caption" commonColor="textBottomInfoCard">
              {new BigNumber(sale.saleAmount).decimalPlaces(2).toNumber().toLocaleString()} {idoToken.symbol}
            </IFTypography>
          </div>,
        )
    }

    if (isPurchaseable && !isMobile) {
      ret.push(
        <div key="payIn">
          <IFTypography variant="caption" commonColor="textBottomInfoCard">
            {t('Pay in')}
          </IFTypography>
          <Box display="flex" alignItems="center">
            <Logo srcs={[`/images/tokens/${paymentToken?.symbol}.png`]} width={18} height={18} alt="token" />
            <Box marginLeft="5px" display="inline-block">
              <IFTypography variant="caption" commonColor="textBottomInfoCard">
                {paymentToken?.symbol}
              </IFTypography>
            </Box>
          </Box>
        </div>,
      )
    }
    return ret
  }, [sale, t, isPurchaseable, isSubscribeable, isAirdropped, isFixedAllocation])

  return (
    <Container>
      {infoItems.map((e) => e)}
      <Box flexGrow={1} />
      {/* {sale?.saleAttributes?.isNFTSale && (
        <RemainingTokensSection>
          <Progessbar $canPurchase={canPurchase}>
            <Box width={totalPercentage} />
          </Progessbar>
          <Box display="flex" justifyContent="space-between">
            <Box textAlign="left" marginTop="4px">
              <IFTypography
                variant="overline"
                fontFamily="Roboto"
                ifcolor={canPurchase ? 'textSecondary' : 'textDisabled'}
                lineHeight="18px"
              >
                {}
                {`${totalPurchasedAllocation.decimalPlaces(2).toNumber().toLocaleString()}/${maxTotalPurchasable} ${
                  saleToken.symbol || saleTokenDefaultName
                } ${t('sold')}`}
              </IFTypography>
            </Box>
            <Box textAlign="right" marginTop="4px">
              <IFTypography
                variant="overline"
                fontFamily="Roboto"
                ifcolor={canPurchase ? 'textSecondary' : 'textDisabled'}
                lineHeight="18px"
              >
                {`${totalPurchasedAllocation
                  .dividedBy(maxTotalPurchasable)
                  .multipliedBy(100)
                  .decimalPlaces(2)
                  .toNumber()
                  .toLocaleString()}%`}
              </IFTypography>
            </Box>
          </Box>
        </RemainingTokensSection>
      )} */}
    </Container>
  )
}

export default BottomInfo

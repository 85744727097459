import { Box } from '@mui/material'
import BigNumber from 'bignumber.js'
import { ClosedDark, ClosedLight } from 'componentsV2/Icons/Sale/Closed'
import IFTypography from 'componentsV2/IFTypography/IFTypography'
import { Chain } from 'config/constants/types'
import { useTranslation } from 'contexts/Localization'
import React, { useCallback, useContext, useMemo } from 'react'
import { StepRequirementEnum } from 'state/types'
import BuybackSection from 'componentsV2/BuybackSection/BuybackSection'
import { BuybackInfo } from 'state/v2_types'
import styled, { useTheme } from 'styled-components'
import { WalletWhiteIcon } from 'uikit/widgets/Menu/icons'
import { registerToken } from 'utils/wallet'
import SaleDetailCard from '../SaleDetailCard'
import { CompanyContext } from 'contexts/CompanyContext'

const SVGWrapper = styled.div`
  path {
    fill: ${({ theme }) => theme.colorsV2?.textLink};
  }
`

const PurchasedAmountWrapper = styled.div`
  margin: 20px 30px 0;
  padding: 20px;
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.colorsV2?.light}`};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 300px;
`

interface ClaimStartCardPops {
  claimDate: string | Date
  tokenSymbol: string
  tokenIcon: string
  tokenAddress: string
  tokenDecimals?: number
  purchasedAmount: number | BigNumber
  totalPurchased?: number | BigNumber
  chainId?: Chain
  isPurchased?: boolean
  stepRequirement?: StepRequirementEnum[]
  buybacks?: BuybackInfo[]
  saleAddress?: string
}

const ClaimStartCard: React.FC<ClaimStartCardPops> = ({
  claimDate,
  tokenIcon,
  tokenSymbol,
  tokenAddress,
  tokenDecimals = 18,
  purchasedAmount,
  chainId,
  isPurchased,
  stepRequirement,
  totalPurchased,
  buybacks,
  saleAddress,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { showAddToWallet } = useContext(CompanyContext)

  // OMNI OVERDRIVE
  const isAllChains = chainId === 165 || chainId === 59140 || chainId === 421613
  const countdownInfo = useMemo(() => {
    const claimTimestamp = new Date(claimDate).getTime()
    const currentTimestamp = new Date().getTime()
    const seconds = Math.trunc((claimTimestamp - currentTimestamp) / 1000)
    return {
      seconds,
      description: t('Claims in'),
    }
  }, [t, claimDate])

  const imageInfo = useMemo(() => {
    return {
      image: theme.isDark ? <ClosedDark /> : <ClosedLight />,
    }
  }, [theme])

  const title = t('Thank you for your participation.')

  const subtitleInfo = useMemo(() => {
    return {
      subtitle: t('You can claim your tokens after the specified distribution time.'),
    }
  }, [t])

  const addToWalletHandler = useCallback(() => {
    registerToken(tokenAddress, tokenSymbol, tokenDecimals)
  }, [tokenAddress, tokenSymbol, tokenDecimals])

  const child = useMemo(() => {
    const amount = new BigNumber(purchasedAmount).decimalPlaces(2).toNumber().toLocaleString()
    return (
      <PurchasedAmountWrapper>
        <IFTypography ifcolor="textSecondary" variant="button" fontWeight="regular">
          {isPurchased ? t('Your purchased tokens') : t('Your Total Share')} {isAllChains && '(all chains included)'}
        </IFTypography>
        <Box display="flex" alignContent="center">
          <Box marginRight="10px">
            <img src={tokenIcon} alt="token_icon" width="36px" />
          </Box>
          <IFTypography ifcolor="text" variant="h2" fontWeight="regular">
            {amount}
          </IFTypography>
        </Box>
      </PurchasedAmountWrapper>
    )
  }, [tokenIcon, purchasedAmount, t, isPurchased])

  const footer = useMemo(() => {
    return <BuybackSection buybacks={buybacks} saleAddress={saleAddress} totalPurchased={totalPurchased} />
  }, [buybacks])

  const linkBtnInfo = useMemo(() => {
    if (!showAddToWallet) {
      return null
    }
    return {
      text: t('Add %token% to your wallet', { token: tokenSymbol || '' }),
      icon: (
        <SVGWrapper>
          <WalletWhiteIcon />
        </SVGWrapper>
      ),
      handler: addToWalletHandler,
    }
  }, [tokenSymbol, t, addToWalletHandler])

  return (
    <SaleDetailCard
      requireKYC={!!stepRequirement?.includes(StepRequirementEnum.KYC)}
      targetNetworkID={chainId}
      countdownInfo={countdownInfo}
      imageInfo={imageInfo}
      title={title}
      subtitleInfo={subtitleInfo}
      linkBtnInfo={linkBtnInfo}
      footer={buybacks?.length > 0 && footer}
    >
      {child}
    </SaleDetailCard>
  )
}

export default ClaimStartCard

import React, { useCallback, useContext, useMemo } from 'react'
import styled from 'styled-components'
import { Button } from 'uikit'
import { defaultNetworkSelectorOptions, NetworkSelectorOption } from './type'
import { Brands, CompanyContext } from 'contexts/CompanyContext'
export interface DesktopNetworkSelectorStyles {
  containerWidth?: string
}

const DesktopSelectorContainer = styled.ul<{ width?: string }>`
  overflow: hidden;
  max-height: 50vh;
  background: ${({ theme }) => theme.common.selectorBackground};
  border: 0.5px solid ${({ theme }) => theme.common.tableDropdownBtnBorder};
  backdrop-filter: blur(20px);
  box-shadow: ${({ theme }) => (theme.isDark ? 'none' : '0px 0px 15px rgba(212, 203, 220, 0.65);')};
  border-radius: 20px;
  top: 5px;
  position: absolute;
  padding: 12px 0;
  width: ${({ width }) => width ?? '100%'};
  list-style-type: none;
  z-index: 10;
  font-family: Roboto;
`
const DesktopSelectorInnerContent = styled.div`
  overflow-y: auto;
  max-height: calc(50vh - 24px);
`

const DesktopSelectorOptionWrapper = styled(Button)<{ active: boolean; isBeam: boolean }>`
  padding: 8px 15px;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 0;
  width: 100%;
  height: 42px;
  font-family: Roboto;
  justify-content: flex-start;
  font-weight: ${({ active }) => (active ? 700 : 400)};
  color: ${({ active, theme, isBeam }) => (active ? theme.colorsV2?.text : !isBeam && theme.colorsV2?.textSecondary)};
  background: ${({ theme }) => theme.common.selectorBackground};
  :hover {
    opacity: 1 !important;
    background: ${({ theme }) => (theme.isDark ? theme.colorsV2.main3 : theme.colorsV2?.light)};
  }
  ${({ active, theme }) =>
    active && `background: ${theme.isDark ? theme.colorsV2.main2 : theme.colorsV2?.dark2} !important`};

  img {
    width: 26px;
    height: 26px;
    margin-right: 10px;
  }
`

interface DesktopNetworkSelectorProps {
  selectionHandler: (network: NetworkSelectorOption) => void
  currentNetwork: NetworkSelectorOption
  options?: NetworkSelectorOption[]
  className?: string
  style?: DesktopNetworkSelectorStyles
}

interface DesktopSelectorOptionProps {
  clickHandler: (option: NetworkSelectorOption) => void
  option: NetworkSelectorOption
  activeID: string
}

const DesktopSelectorOption: React.FC<DesktopSelectorOptionProps> = ({ activeID, option, clickHandler }) => {
  const { brand } = useContext(CompanyContext)
  const active = useMemo(() => {
    return option.id === activeID
  }, [activeID, option])
  const handler = useCallback(() => {
    clickHandler(option)
  }, [clickHandler, option])

  return (
    <li>
      <DesktopSelectorOptionWrapper active={active} onClick={handler} key={option.id} isBeam={brand === Brands.BEAM}>
        {option.iconSrc && <img src={option.iconSrc} alt={option.name} />}
        {option.fullName ?? option.name}
      </DesktopSelectorOptionWrapper>
    </li>
  )
}

const DesktopNetworkSelector = ({
  currentNetwork,
  selectionHandler,
  className,
  options = defaultNetworkSelectorOptions,
  style = {},
}: DesktopNetworkSelectorProps) => {
  const clickHandler = useCallback(
    (network: NetworkSelectorOption) => {
      selectionHandler(network)
    },
    [selectionHandler],
  )
  return (
    <DesktopSelectorContainer className={className} width={style?.containerWidth}>
      <DesktopSelectorInnerContent>
        {options.map((option) => (
          <DesktopSelectorOption
            activeID={currentNetwork.id}
            clickHandler={clickHandler}
            option={option}
            key={option.id}
          />
        ))}
      </DesktopSelectorInnerContent>
    </DesktopSelectorContainer>
  )
}

export default DesktopNetworkSelector

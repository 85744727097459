import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="21" height="20" viewBox="0 0 21 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.76659 7.41666C7.05355 7.25094 7.42052 7.34922 7.58623 7.63618C8.93211 9.96668 11.5982 10.2743 12.7773 10.1199C13.1059 10.0768 13.4071 10.3083 13.4501 10.6369C13.4932 10.9655 13.2617 11.2667 12.9331 11.3097C11.5191 11.4949 8.2393 11.1665 6.54707 8.2363C6.38135 7.94934 6.47964 7.58237 6.76659 7.41666Z"
        fill="#00FFB9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.64615 2.99986C5.57415 2.61169 2.76913 4.78737 2.38096 7.85937C2.09783 10.1001 3.17858 12.2 4.97896 13.3228C5.16897 13.4413 5.36694 13.5489 5.57212 13.6445C5.87245 13.7846 6.0024 14.1415 5.86237 14.4419C5.72235 14.7422 5.36537 14.8722 5.06503 14.7321C4.81553 14.6158 4.57486 14.485 4.34395 14.341C2.16101 12.9796 0.846443 10.4312 1.19043 7.70894C1.66168 3.97942 5.06707 1.33808 8.79659 1.80933C8.99828 1.83482 9.19694 1.86892 9.39221 1.91125C9.76436 1.99191 10.124 2.10239 10.4688 2.23986C10.7766 2.36259 10.9266 2.71161 10.8039 3.01942C10.6812 3.32722 10.3321 3.47726 10.0243 3.35453C9.74082 3.24148 9.4448 3.15051 9.13801 3.08401C8.97701 3.04911 8.81297 3.02094 8.64615 2.99986Z"
        fill="#00FFB9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5258 5.22144C12.2452 5.22144 11.0665 5.65002 10.1228 6.37187C9.8596 6.5732 9.48303 6.52305 9.2817 6.25985C9.08037 5.99665 9.13052 5.62008 9.39372 5.41875C10.5394 4.54238 11.9726 4.02144 13.5258 4.02144C17.2849 4.02144 20.3323 7.06885 20.3323 10.828C20.3323 14.5872 17.2849 17.6346 13.5258 17.6346C10.5828 17.6346 8.07756 15.7672 7.12736 13.1546C7.0141 12.8432 7.17473 12.499 7.48615 12.3857C7.79756 12.2724 8.14183 12.4331 8.25509 12.7445C9.03835 14.898 11.1034 16.4346 13.5258 16.4346C16.6222 16.4346 19.1323 13.9244 19.1323 10.828C19.1323 7.73159 16.6222 5.22144 13.5258 5.22144Z"
        fill="#00FFB9"
      />
      <path
        d="M12.2267 10.0625L12.2959 11.611L12.3224 12.203C12.3438 12.68 12.8866 12.9422 13.2735 12.6624L15.291 11.2031C15.6407 10.9502 15.617 10.422 15.2462 10.2013L13.1064 8.92804C12.6961 8.68389 12.1789 8.99351 12.2002 9.47047L12.2267 10.0625Z"
        fill="#00FFB9"
      />
    </Svg>
  )
}

export default Icon

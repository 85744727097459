import React, { useState, useEffect } from 'react'

const THREE_SECONDS_INTERVAL = 3000
const FAST_INTERVAL = 10000
const MEDIUM_INTERVAL = 30000
const SLOW_INTERVAL = 60000
const FIVE_MINUTE_INTERVAL = 5 * 60000

const RefreshContext = React.createContext({ threeSeconds: 0, slow: 0, medium: 0, fast: 0, fiveMinutes: 0 })

// This context maintain 2 counters that can be used as a dependencies on other hooks to force a periodic refresh
const RefreshContextProvider = ({ children }) => {
  const [slow, setSlow] = useState(0)
  const [fast, setFast] = useState(0)
  const [medium, setMedium] = useState(0)
  const [threeSeconds, setThreeSeconds] = useState(0)
  const [fiveMinutes, setFiveMinutes] = useState(0)

  useEffect(() => {
    const interval = setInterval(async () => {
      setThreeSeconds((prev) => prev + 1)
    }, THREE_SECONDS_INTERVAL)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    const interval = setInterval(async () => {
      setFast((prev) => prev + 1)
    }, FAST_INTERVAL)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    const interval = setInterval(async () => {
      setMedium((prev) => prev + 1)
    }, MEDIUM_INTERVAL)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    const interval = setInterval(async () => {
      setSlow((prev) => prev + 1)
    }, SLOW_INTERVAL)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    const interval = setInterval(async () => {
      setFiveMinutes((prev) => prev + 1)
    }, FIVE_MINUTE_INTERVAL)
    return () => clearInterval(interval)
  }, [])

  return (
    <RefreshContext.Provider value={{ threeSeconds, slow, fast, fiveMinutes, medium }}>
      {children}
    </RefreshContext.Provider>
  )
}

export { RefreshContext, RefreshContextProvider }

import React, { useContext } from 'react'

import { Flex, useMatchBreakpoints } from 'uikit'

import { ConnectButton } from '@rainbow-me/rainbowkit'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import styled, { useTheme } from 'styled-components'
import { Box } from '@mui/material'
import { WalletWhiteIcon } from 'uikit/widgets/Menu/icons'
import { Brands, CompanyContext } from 'contexts/CompanyContext'
import { getBalanceNumber } from 'utils/formatBalance'
import useBNBBalance from 'hooks/useBNBBalance'

const StyledMobileConnectButton = styled.button`
  background: ${({ theme }) => (theme.isColorOverride ? theme.colorsV2.main5 : theme.common.connectButtonBackground)};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 30px;
  font-weight: 700;
  color: ${({ theme }) => (theme.isColorOverride ? theme.colorsV2.dark : 'rgb(255, 255, 255)')};
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-right: 10px;
  border: none;
  white-space: nowrap;
  height: 38px;
  padding: 0 20px;
  font-size: 14px;
`

const StyledMobileWrongButton = styled.button`
  background: ${({ theme }) => (theme.isDark ? 'rgba(88, 11, 11, 0.3)' : 'rgba(255, 178, 192, 0.3)')};
  color: ${({ theme }) => (theme.isDark ? '#FF8799' : '#ED756E')};

  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  font-family: Acre;
  border-radius: 30px;
  font-weight: 700;
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-right: 10px;
  border: none;
  white-space: nowrap;
  height: 38px;
  padding: 0 20px;
  font-size: 14px;
`

const StyledMobileAccountButton = styled.button<{ isRonin?: boolean }>`
  background: ${({ theme }) =>
    theme.isColorOverride ? theme.colorsV2.difficultyPill.text.beginner : theme.common.connectedButtonBackground};
  height: ${({ isRonin }) => isRonin && '42px'};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  font-family: SFRounded, ui-rounded, 'SF Pro Rounded', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  border-radius: 12px;
  font-weight: 700;
  color: ${({ theme }) =>
    theme.isColorOverride ? theme.colorsV2.roiCalculator.background : theme.isDark ? 'rgb(255, 255, 255)' : '#232323'};
  padding: 4px 8px;
  border: none;
  gap: 8px;
  white-space: nowrap;
  display: flex;
  align-items: center;
`

const ConnectWalletButton = (props) => {
  const { isXl } = useMatchBreakpoints()
  const isMobile = isXl === false
  const { brand } = useContext(CompanyContext)
  const isRonin = brand === Brands.RONIN
  const useGasBalanceCallback = () => getBalanceNumber(useBNBBalance()).toPrecision(2)
  const gasBalance = useGasBalanceCallback && useGasBalanceCallback()
  const theme = useTheme()
  return (
    <Box style={{ ...props.style }} display="flex">
      <ConnectButton.Custom>
        {({ account, chain, openAccountModal, openChainModal, openConnectModal, authenticationStatus, mounted }) => {
          // Note: If your app doesn't use authentication, you
          // can remove all 'authenticationStatus' checks
          const ready = mounted && authenticationStatus !== 'loading'
          const connected =
            ready && account && chain && (!authenticationStatus || authenticationStatus === 'authenticated')

          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                ...props.style,
              }}
              {...(!ready && {
                'aria-hidden': true,
                style: {
                  opacity: 0,
                  pointerEvents: 'none',
                  userSelect: 'none',
                },
              })}
            >
              {(() => {
                if (!connected) {
                  return (
                    <StyledMobileConnectButton style={{ ...props.sx }} onClick={openConnectModal} type="button">
                      Connect Wallet{' '}
                      {props.showWalletIcon && (
                        <WalletWhiteIcon color={theme.isColorOverride ? theme.colorsV2.dark : '#FAFAFA'} />
                      )}
                    </StyledMobileConnectButton>
                  )
                }

                if (chain.unsupported) {
                  return (
                    <StyledMobileWrongButton style={{ ...props.sx }} onClick={openChainModal} type="button">
                      Wrong network
                    </StyledMobileWrongButton>
                  )
                }

                return (
                  <div style={{ display: 'flex', gap: 8, height: '100%' }}>
                    <StyledMobileAccountButton
                      onClick={openChainModal}
                      style={{ display: 'flex', alignItems: 'center', height: isRonin && '42px' }}
                      type="button"
                    >
                      {chain.hasIcon && (
                        <div
                          style={{
                            background: chain.iconBackground,
                            width: 24,
                            height: 24,
                            borderRadius: 999,
                            overflow: 'hidden',
                          }}
                        >
                          {chain.iconUrl && (
                            <img
                              alt={chain.name ?? 'Chain icon'}
                              src={chain.iconUrl}
                              style={{ width: 24, height: 24 }}
                            />
                          )}
                        </div>
                      )}
                      {!isMobile && <Box marginLeft="8px">{chain.name}</Box>}
                      <KeyboardArrowDownIcon />
                    </StyledMobileAccountButton>

                    <StyledMobileAccountButton isRonin={isRonin} onClick={openAccountModal} type="button">
                      {!isMobile && isRonin && gasBalance} {isRonin && !isMobile && 'RON'}
                      {isRonin && !isMobile ? (
                        <Flex
                          style={{
                            background: '#F2F2F2',
                            borderRadius: '12px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '5px 7px 5px 8px ',
                          }}
                        >
                          <img
                            src="/images/icons/EllipseRonin.png"
                            alt="roninAddresRocket"
                            style={{ marginRight: '4px' }}
                          />
                          <Box alignSelf="center">{account.displayName}</Box>
                          <KeyboardArrowDownIcon />
                        </Flex>
                      ) : (
                        <>
                          {account.displayName} <KeyboardArrowDownIcon />
                        </>
                      )}
                    </StyledMobileAccountButton>
                  </div>
                )
              })()}
            </div>
          )
        }}
      </ConnectButton.Custom>
    </Box>
  )
}

export default ConnectWalletButton

import { useCallback } from 'react'
import { SendReferral } from 'state/v2_types'
import { useAccount } from 'wagmi'

const useSendReferral = () => {
  const { address: account } = useAccount()

  const URL = process.env.REACT_APP_BACKEND_URL
  const sendReferral = useCallback(
    (body: SendReferral) => {
      fetch(`${URL}/api/backend-service/user/send`, {
        method: 'POST',
        body: JSON.stringify(body),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status_code !== 200) {
            throw res.error
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    [account],
  )

  return { sendReferral }
}

export default useSendReferral

import React from 'react'
import styled from 'styled-components'

const WarningSVG = styled.svg`
  path:nth-child(2) {
    fill: ${({ theme }) => theme.colorsV2?.main};
  }
`

interface WarningSquareIconProps {
  width?: number
  height?: number
}

const WarningSquareIcon: React.FC<WarningSquareIconProps> = ({ width = 86, height = 85 }) => {
  return (
    <WarningSVG width={width} height={height} viewBox="0 0 86 85" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M49.5597 6.70689L78.8667 36.0139C82.464 39.6111 82.464 45.536 78.8667 49.1333L49.5597 78.4403C45.9625 82.0375 40.0375 82.0375 36.4403 78.4403L7.1333 49.1333C3.53605 45.536 3.53605 39.6111 7.1333 36.0139L36.4403 6.70689C40.1433 3.00384 45.9625 3.10964 49.5597 6.70689Z"
        fill="url(#paint0_linear_7344_143185)"
      />
      <path d="M39.2044 45.3016C39.2044 47.3828 40.8916 49.07 42.9728 49.07C45.0541 49.07 46.7413 47.3828 46.7413 45.3016V27.2938C46.7413 25.2126 45.0541 23.5254 42.9728 23.5254C40.8916 23.5254 39.2044 25.2126 39.2044 27.2938V45.3016ZM42.9728 61.6226C45.9876 61.6226 48.1948 59.62 48.1948 56.9337C48.1948 54.4915 46.0414 52.196 42.9728 52.196C40.0119 52.196 37.8047 54.4427 37.8047 56.9337C37.8047 59.62 40.1196 61.6226 42.9728 61.6226Z" />
      <defs>
        <linearGradient
          id="paint0_linear_7344_143185"
          x1="43"
          y1="80.6708"
          x2="43"
          y2="-13.2734"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ED696C" stopOpacity="0.53" />
          <stop offset="1" stopColor="#ED696C" />
        </linearGradient>
      </defs>
    </WarningSVG>
  )
}

export default WarningSquareIcon

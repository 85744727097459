/* eslint-disable dot-notation */
import BigNumber from 'bignumber.js'
import { createSlice } from '@reduxjs/toolkit'
import { FarmConfig, FarmsState } from 'state/v2_types'
import { calculateLPPrice } from 'utils/prices'
import { calculateAPR } from 'utils/calculateAPR'
import {
  fetchFarmUserEarnings,
  fetchFarmUserTokenBalances,
  fetchFarmUserStakedBalances,
  fetchFarmEnabled,
} from './fetchFarmUser'
import { fetchFarmsConfig } from './fetchConfigs'
import fetchFarms from './fetchFarms'
import fetchPriceCoins from '../../views/Earn/api/PriceFarmsLP'

const initialState: FarmsState = {
  data: [],
  userData: {},
  isFetched: false,
  isUserDataFetched: false,
  isUserDataLoading: false,
  isPriceDataLoading: false,
  isPriceDataFetched: false,
}

export const farmsSlice = createSlice({
  name: 'Farms',
  initialState,
  reducers: {
    setFarmsPublicData: (state, action) => {
      const liveFarmsData: FarmConfig[] = action.payload
      state.data = liveFarmsData
      state.isFetched = true
    },

    setFarmsPublicDataWithPrice: (state, action) => {
      const farmsData: FarmConfig[] = action.payload
      state.data = farmsData
      state.isPriceDataFetched = true
    },

    setFarmUserData: (state, action) => {
      state.userData = action.payload
      state.isUserDataFetched = true
      state.isUserDataLoading = false
    },

    setIsFarmsUserDataLoading: (state, action) => {
      state.isUserDataLoading = action.payload
    },

    setIsPriceDataLoading: (state, action) => {
      state.isPriceDataLoading = action.payload
    },
  },
})

// Actions
export const {
  setFarmsPublicData,
  setFarmsPublicDataWithPrice,
  setFarmUserData,
  setIsFarmsUserDataLoading,
  setIsPriceDataLoading,
} = farmsSlice.actions

// Thunks
export const fetchFarmsPublicDataAsync = () => async (dispatch) => {
  const farmsConfig = await fetchFarmsConfig()
  const farms = await fetchFarms(farmsConfig)
  dispatch(setFarmsPublicData(farms))
  dispatch(fetchFarmsPrices(farms))
}

export const fetchFarmsPrices = (farms: any[]) => async (dispatch) => {
  dispatch(setIsPriceDataLoading(true))
  const symbols = farms.flatMap((farm) => [farm.token0.symbol, farm.token1.symbol, farm.rewardToken.symbol])
  const mapSymbolPrice: Record<string, number> = await fetchPriceCoins(symbols)
  const symbolPriceUpper: Record<string, number> = Object.fromEntries(
    Object.entries(mapSymbolPrice).map(([k, v]) => [k.toUpperCase(), v]), // uppercase converting
  )

  const farmsWithPrice = farms.map((farm) => {
    const stakingTokenPrice = calculateLPPrice(
      new BigNumber(symbolPriceUpper[farm.token0.symbol.toUpperCase()]),
      farm.token0Amount,
      new BigNumber(symbolPriceUpper[farm.token1.symbol.toUpperCase()]),
      farm.token1Amount,
      farm.lpTotalSupply,
    )

    const liquidityValue = farm.lpTokenBalanceMC
      ? new BigNumber(farm.lpTokenBalanceMC).multipliedBy(stakingTokenPrice).toNumber()
      : 0

    const apr = calculateAPR(
      symbolPriceUpper[farm.rewardToken.symbol],
      liquidityValue,
      new BigNumber(farm.rewardPerSecond).toNumber(),
    )
    return {
      ...farm,
      stakingToken: {
        ...farm.stakingToken,
        busdPrice: stakingTokenPrice.toString(),
      },
      rewardToken: {
        ...farm.rewardToken,
        busdPrice: symbolPriceUpper[farm.rewardToken.symbol],
      },
      liquidityValue,
      apr,
    }
  })

  dispatch(setIsPriceDataLoading(false))
  dispatch(setFarmsPublicDataWithPrice(farmsWithPrice))
}

export const fetchFarmUserDataAsync = (account) => async (dispatch, getState) => {
  dispatch(setIsFarmsUserDataLoading(true))
  const {
    farms: { data: farmsList },
  } = getState()

  const functions = [
    { key: 'userStakingTokenBalance', fn: fetchFarmUserTokenBalances(farmsList, account) },
    { key: 'userStakedTokenBalance', fn: fetchFarmUserStakedBalances(farmsList, account) },
    { key: 'userEarnings', fn: fetchFarmUserEarnings(farmsList, account) },
    { key: 'userFarmEnabled', fn: fetchFarmEnabled(farmsList, account) },
  ]
  const results = await Promise.allSettled(functions.map((val) => val.fn))

  const keyedResult = results.reduce((acc, result, idx) => {
    if (result.status !== 'fulfilled') {
      acc[functions[idx].key] = {}
    }

    acc[functions[idx].key] = (result as any).value
    return acc
  }, {})

  const farmUserDataMap = farmsList.reduce((acc, farm) => {
    acc[farm.id] = {
      stakingTokenBalance: keyedResult['userStakingTokenBalance'][farm.id],
      stakedTokenBalance: keyedResult['userStakedTokenBalance'][farm.id],
      earnings: keyedResult['userEarnings'][farm.id],
      isEnabled: keyedResult['userFarmEnabled'][farm.id],
    }
    return acc
  }, {})

  dispatch(setFarmUserData(farmUserDataMap))
}

export default farmsSlice.reducer

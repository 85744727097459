import { useIFTieredSaleContract } from 'hooks/useContract'

export const useFetchPromoCode = (contractAddress: string) => {
  const IFTieredSaleContract = useIFTieredSaleContract(contractAddress)

  const fetchPromo = async (code: string) => {
    let res = await IFTieredSaleContract.methods.promoCodes(code.toLowerCase())
    res = await res.call()

    if (res.promoCodeOwnerAddress === '0x0000000000000000000000000000000000000000') {
      return false
    }

    return res
  }

  return { fetchPromo }
}

import React, { createContext, useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Box, Drawer, Theme } from '@mui/material'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { useMatchBreakpoints } from 'uikit/hooks'
import { Overlay } from 'uikit/components/Overlay'
import { useModalV2 } from 'uikit'

import { useAccountSidebar } from '../AccountSidebar/AccountSidebarContext'

const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: DRAWER_WIDTH,
      height: '100%',
      borderRadius: '0',
      maxHeight: '100vh',
    },
    overflowX: 'hidden',
    backgroundColor: 'inherit',
    boxShadow: 'inherit',
  },
}))

interface DrawerContext {
  isOpen: boolean
  nodeId: string
  drawerNode: React.ReactNode
  setDrawerNode: React.Dispatch<React.SetStateAction<React.ReactNode>>
  onPresent: (node: React.ReactNode, newNodeId: string, drawerProps: any, drawerType: string) => void
  onDismiss: any
  drawerProps: any
  drawerType?: string
}

export const DRAWER_WIDTH = 320

const MainContainer = styled(Box)<{ $drawerOpen: boolean }>`
  display: block;
  ${({ theme }) => theme.mediaQueries?.md} {
    display: flex;
  }
`

const ManageApp = styled(Box)<{ $drawerOpen: boolean; $isMobile: boolean }>`
  margin-right: 0;
  width: 100%;
  ${({ $drawerOpen, $isMobile }) =>
    $drawerOpen &&
    !$isMobile &&
    `
    margin-right: ${DRAWER_WIDTH}px;
    width: calc(100% - ${DRAWER_WIDTH})
  `}
`

const DrawerContainer = styled(Drawer)<{ $drawerOpen: boolean; $modalOpen: boolean; $isMobile: boolean }>`
  border-radius: 20px 20px 0px 0px;
  flex-shrink: 0;

  .MuiDrawer-paper {
    background-color: ${({ theme }) => theme.colorsV2?.main};
    border-radius: ${({ $isMobile }) => $isMobile && '20px 20px 0px 0px'};
    max-height: ${({ $isMobile }) => ($isMobile ? '80vh' : '100vh')};
    z-index: ${({ $drawerOpen, $modalOpen }) => ($drawerOpen ? ($modalOpen ? 98 : 100) : 0)};
    border-left: ${({ $isMobile, $drawerOpen, theme }) =>
      $isMobile && $drawerOpen && `1px solid ${theme.colorsV2?.light}`};
    width: ${({ $drawerOpen, $isMobile }) => (!$drawerOpen && '0px') || ($isMobile && '100%') || `${DRAWER_WIDTH}px`};
  }
`

export const Context = createContext<DrawerContext>({
  isOpen: false,
  nodeId: '',
  drawerNode: null,
  setDrawerNode: () => null,
  onPresent: () => null,
  onDismiss: () => null,
  drawerProps: null,
  drawerType: '',
})

interface Props {
  children: React.ReactNode
}

const DrawerProvider: React.FC<Props> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [drawerNode, setDrawerNode] = useState<React.ReactNode>()
  const [nodeId, setNodeId] = useState('')
  const [drawerType, setDrawerType] = useState('')
  const [drawerProps, setDrawerProps] = useState(null)

  const classes = useStyles()

  const { pathname } = useLocation()
  const { isXl } = useMatchBreakpoints()
  const [, , isModalOpen] = useModalV2({ modal: undefined })

  // TODO: Convert account sidebar to use Drawer
  const { closeAccountSidebar: onCloseAccountSidebar } = useAccountSidebar()
  const isMobile = !isXl

  const handleDismiss = useCallback(() => {
    setDrawerNode(undefined)
    setIsOpen(false)
    setNodeId('')
    setDrawerType('')
    if (isMobile) {
      const body = document.getElementsByTagName('body')[0]
      body.style.removeProperty('overflow')
    }
  }, [isMobile])

  useEffect(() => {
    handleDismiss()
  }, [pathname, handleDismiss])

  const handleOpen: DrawerContext['onPresent'] = (node, newNodeId, props, type) => {
    setDrawerNode(node)
    setIsOpen(true)
    setNodeId(newNodeId)
    setDrawerProps(props)
    setDrawerType(type)
    onCloseAccountSidebar()
    if (isMobile) {
      const body = document.getElementsByTagName('body')[0]
      body.style.overflow = 'hidden'
    }
  }

  const newProps = {
    onDismiss: handleDismiss,
    drawerProps,
  }

  return (
    <Context.Provider
      value={{
        isOpen,
        nodeId,
        drawerNode,
        drawerProps,
        setDrawerNode,
        onPresent: handleOpen,
        onDismiss: handleDismiss,
        drawerType,
      }}
    >
      <MainContainer $drawerOpen={isOpen}>
        <ManageApp $drawerOpen={isOpen} $isMobile={isMobile}>
          {children}
        </ManageApp>
        {isOpen && isMobile && <Overlay zIndex={20} show />}
        <DrawerContainer
          $drawerOpen={isOpen}
          variant="persistent"
          anchor={isMobile ? 'bottom' : 'right'}
          open={isOpen}
          classes={{ paper: classes.drawerPaper }}
          $modalOpen={isModalOpen && !isMobile}
          $isMobile={isMobile}
          hideBackdrop
        >
          {React.isValidElement(drawerNode) && React.cloneElement(drawerNode, newProps)}
        </DrawerContainer>
      </MainContainer>
    </Context.Provider>
  )
}

export default DrawerProvider

import useTheme from 'hooks/useTheme'
import React from 'react'
import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
`

const StyledImage = styled.img<{ size: string; stroke?: string }>`
  animation: 2s ${rotate} linear infinite;
  height: ${({ size }) => size};
  width: ${({ size }) => size};
`

/**
 * Takes in custom size and stroke for circle color, default to primary color as fill,
 * need ...rest for layered styles on top
 */
export default function WhiteCircleLoader({
  size = '16px',
  stroke,
  variant = 'white',
  ...rest
}: {
  size?: string
  stroke?: string
  variant?: 'white' | 'disabled'
  [k: string]: any
}) {
  const { isDark } = useTheme()
  const image = {
    white: ['WhiteLoading', 'WhiteLoading'],
    disabled: ['DisabledLightLoading', 'DisabledDarkLoading'],
  }
  return (
    <StyledImage src={`/images/icons/${image[variant][isDark ? 1 : 0]}.png`} size={size} stroke={stroke} {...rest} />
  )
}

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Flex, useModalV2 } from 'uikit'

import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import { Language } from 'state/types'
import ModalV2 from 'uikit/widgets/Modal/ModalV2'
import IFTypography from 'componentsV2/IFTypography/IFTypography'
import Button from '../../../components/Button/Button'
import Dropdown from '../../../components/Dropdown/Dropdown'

interface Props {
  currentLang: Language
  langs: Language[]
  setLang: (lang: Language) => void
}

interface MobileLangSelectorModalProps extends Props {
  onDismiss?: VoidFunction
}

const ButtonStyled = styled(Button)<{ isMobile: boolean }>`
  height: 36px;
  padding: 5px 5px 5px 15px;
  font-size: 12px;
  font-weight: 500;
  background: ${({ theme, isMobile }) =>
    theme.isDark ? (isMobile ? theme.colorsV2?.dark2 : theme.colorsV2?.main) : theme.colorsV2?.dark2};
  color: ${({ theme }) => theme.colorsV2?.text};
  border-radius: 20px;
  &:hover {
    opacity: 1 !important;
    > svg {
      transform: rotate(180deg);
    }
  }
  > svg {
    width: 0.8em;
  }
`

const LanguageButton = styled(Flex)`
  color: ${({ theme }) => theme.colorsV2?.textSecondary};
  padding: 0 8px;
  text-decoration: none;
  text-align: left;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  font-size: 14px;
  font: Roboto;
  &:hover {
    background: ${({ theme }) => (theme.isDark ? theme.colorsV2?.dark2 : theme.colorsV2?.light)};
  }
  cursor: pointer;
`

const DesktopDropdown = styled(Dropdown)`
  & > div {
    transform: unset;
    right: 0;
    left: unset;
  }
`

const MobileLanguageButton = styled(LanguageButton)<{ isActive?: boolean }>`
  padding: 10px 20px;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  background: ${({ isActive = false, theme }) => (isActive ? theme.colorsV2?.dark2 : 'transparent')};
  border-radius: 10px;
`

const DesktopSelector: React.FC<Props> = ({ currentLang, langs, setLang }) => {
  const { theme } = useTheme()

  return (
    <DesktopDropdown
      width="191px"
      position="bottom-left"
      target={
        <ButtonStyled endIcon={<ArrowDropDownIcon width="5px" style={{ color: theme.colorsV2?.text }} />}>
          {currentLang?.language}
        </ButtonStyled>
      }
    >
      {langs.map((lang) => (
        <LanguageButton
          key={lang.code}
          onClick={() => setLang(lang)}
          // Safari fix
          style={{ minHeight: '32px', height: 'auto' }}
        >
          {lang.language}
        </LanguageButton>
      ))}
    </DesktopDropdown>
  )
}

const MobileSelector: React.FC<Props> = ({ currentLang, langs, setLang }) => {
  const { theme } = useTheme()
  const [onPresent] = useModalV2({
    modal: <MobileLangSelectorModal currentLang={currentLang} langs={langs} setLang={setLang} />,
  })
  return (
    <ButtonStyled
      onClick={onPresent}
      isMobile
      endIcon={<ArrowDropDownIcon width="5px" style={{ color: theme.colorsV2?.text }} />}
    >
      {currentLang?.language}
    </ButtonStyled>
  )
}

const MobileLangSelectorModal: React.FC<MobileLangSelectorModalProps> = ({
  onDismiss,
  langs,
  setLang,
  currentLang,
}) => {
  const { t } = useTranslation()
  const clickHandler = useCallback(
    (lang: Language) => {
      setLang(lang)
      onDismiss()
    },
    [setLang, onDismiss],
  )
  return (
    <ModalV2 title={t('Language')} onDismiss={onDismiss} bodyPadding="15px 10px 0">
      {langs.map((lang) => {
        const isActive = lang === currentLang
        return (
          <MobileLanguageButton
            key={lang.code}
            onClick={() => clickHandler(lang)}
            // Safari fix
            style={{ minHeight: '32px', height: 'auto' }}
            isActive={isActive}
          >
            <IFTypography variant="body1" ifcolor={isActive ? 'text' : 'textSecondary'}>
              {lang.language}
            </IFTypography>
          </MobileLanguageButton>
        )
      })}
    </ModalV2>
  )
}

export const DesktopLangSelector = React.memo(DesktopSelector, (prev, next) => prev.currentLang === next.currentLang)

export const MobileLangSelector = React.memo(MobileSelector, (prev, next) => prev.currentLang === next.currentLang)

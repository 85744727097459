import styled from 'styled-components'
import { Box } from 'uikit'

interface CardProps {
  width?: string
  padding?: string
  border?: string
  borderRadius?: string
}

const Card = styled(Box)<CardProps>`
  width: ${({ width }) => width ?? '100%'};
  border-radius: 16px;
  padding: 1.25rem;
  padding: ${({ padding }) => padding};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};
  background-color: ${({ theme }) => theme.colors?.background};
`
export default Card

import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const SwapLight: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="18" height="17" viewBox="0 0 18 17" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1628 8.5022C10.9201 8.5022 9.91278 9.50956 9.91278 10.7522C9.91278 11.9949 10.9201 13.0022 12.1628 13.0022C13.4054 13.0022 14.4128 11.9949 14.4128 10.7522C14.4128 9.50956 13.4054 8.5022 12.1628 8.5022ZM6.91278 10.7522C6.91278 7.85271 9.26328 5.5022 12.1628 5.5022C15.0623 5.5022 17.4128 7.85271 17.4128 10.7522C17.4128 13.6517 15.0623 16.0022 12.1628 16.0022C9.26328 16.0022 6.91278 13.6517 6.91278 10.7522Z"
        fill="#5A5A5A"
        fillOpacity="0.7"
      />
      <path
        d="M6.0039 10.8407C6.00137 10.7691 6.00009 10.6971 6.00009 10.6249C6.00009 7.57762 8.27187 5.06094 11.2142 4.67611C10.7815 2.21268 8.63117 0.34082 6.04341 0.34082C3.144 0.34082 0.793579 2.69124 0.793579 5.59065C0.793579 8.47722 3.12268 10.8194 6.0039 10.8407Z"
        fill="#5A5A5A"
        fillOpacity="0.7"
      />
    </Svg>
  )
}

export default SwapLight

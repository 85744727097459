import React from 'react'

interface Props {
  size?: number
}

export const StartLight: React.FC<Props> = ({ size = 100 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.5675 85.5646C62.0476 86.1186 62.4872 86.7288 62.8789 87.3979C63.7396 83.6045 66.9857 80.7271 70.9196 80.4908C73.2133 75.6744 78.5721 72.6648 84.1193 73.4602C90.0248 74.3063 95.1085 80.1871 94.9983 86.0452C94.8708 92.7062 89.0754 98.333 82.3425 98.333H57.9093H42.0893H17.6561C10.5483 98.333 5 92.3022 5 85.8325C5 80.0726 10.0839 74.2913 15.8795 73.4602C21.424 72.6646 26.7846 75.6728 29.079 80.4908C33.0129 80.7273 36.259 83.6045 37.1197 87.3979C37.3697 86.9329 37.6765 86.4856 38.0332 86.0588C41.7557 79.1816 42.4994 71 42.4994 71H57.0828C57.0828 71 57.0828 78.7571 61.5675 85.5646Z"
        fill="url(#paint0_linear_11494_82842)"
      />
      <path
        d="M57.9857 69.9439C59.5923 73.212 59.4246 77.6117 56.2974 81.6458C55.9609 82.0798 55.2822 81.8839 55.1708 81.3461C54.8332 79.717 54.1912 77.3731 53.5288 78.9811C52.8544 80.6184 51.2363 84.4884 50.3227 86.6714C50.0911 87.2247 49.2996 87.202 49.0983 86.637C48.3022 84.4021 46.8751 80.448 46.1933 78.8798C45.5373 77.3709 44.8397 79.547 44.4536 81.1313C44.3208 81.6764 43.6153 81.8419 43.3071 81.3731C40.5392 77.1635 40.6606 72.5553 42.0894 69.9439L57.9857 69.9439Z"
        fill="url(#paint1_linear_11494_82842)"
      />
      <path
        d="M68.4319 53.6655C62.1161 50.891 60.5371 44.7325 60.5371 42V68.7991H75.9978C76.1075 64.9106 74.7478 56.44 68.4319 53.6655Z"
        fill="url(#paint2_linear_11494_82842)"
      />
      <path
        d="M68.4319 53.6655C62.1161 50.891 60.5371 44.7325 60.5371 42V68.7991H75.9978C76.1075 64.9106 74.7478 56.44 68.4319 53.6655Z"
        fill="#C7D9F3"
        fillOpacity="0.3"
      />
      <path
        d="M31.5661 53.6655C37.882 50.891 39.4609 44.7325 39.4609 42V68.7991H24.0002C23.8906 64.9106 25.2503 56.44 31.5661 53.6655Z"
        fill="url(#paint3_linear_11494_82842)"
      />
      <path
        d="M31.5661 53.6655C37.882 50.891 39.4609 44.7325 39.4609 42V68.7991H24.0002C23.8906 64.9106 25.2503 56.44 31.5661 53.6655Z"
        fill="#C7D9F3"
        fillOpacity="0.3"
      />
      <path
        d="M63.498 38.0759L62.0521 69.3268C62.0028 70.3943 61.1229 71.2344 60.0543 71.2344H39.9418C38.8732 71.2344 37.9933 70.3943 37.944 69.3268L36.498 38.0759C36.498 32.925 37.6786 27.9764 39.8121 23.6666V23.6648C42.0603 19.1193 45.3701 15.2808 49.4708 12.6578C49.7987 12.4481 50.1957 12.4471 50.5237 12.6567C54.4336 15.1561 57.6219 18.76 59.8598 23.0278C59.9703 23.2377 60.0778 23.4512 60.1838 23.6646V23.6664C62.3174 27.9764 63.498 32.9248 63.498 38.0759Z"
        fill="url(#paint4_linear_11494_82842)"
      />
      <path
        d="M60.1822 23.6825V23.6843H39.8105V23.6825C42.0586 19.1299 45.3682 15.2853 49.4686 12.6582C49.7968 12.448 50.1946 12.4469 50.5227 12.6571C54.4323 15.1606 57.6204 18.7701 59.8582 23.0447C59.9687 23.2549 60.0762 23.4688 60.1822 23.6825V23.6825Z"
        fill="url(#paint5_linear_11494_82842)"
      />
      <path
        d="M60.1822 23.6825V23.6843H39.8105V23.6825C42.0586 19.1299 45.3682 15.2853 49.4686 12.6582C49.7968 12.448 50.1946 12.4469 50.5227 12.6571C54.4323 15.1606 57.6204 18.7701 59.8582 23.0447C59.9687 23.2549 60.0762 23.4688 60.1822 23.6825V23.6825Z"
        fill="#C7D9F3"
        fillOpacity="0.3"
      />
      <circle cx="49.998" cy="35.5" r="6.5" fill="url(#paint6_linear_11494_82842)" />
      <defs>
        <linearGradient
          id="paint0_linear_11494_82842"
          x1="50"
          y1="52.3953"
          x2="50"
          y2="155"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C2D2F1" stopOpacity="0.12" />
          <stop offset="1" stopColor="#8F9EBB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_11494_82842"
          x1="50"
          y1="66.001"
          x2="50"
          y2="99.001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCE300" />
          <stop offset="0.208333" stopColor="#FCC400" />
          <stop offset="0.828125" stopColor="#FC0000" stopOpacity="0.53" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_11494_82842"
          x1="63.7112"
          y1="44.3815"
          x2="75.373"
          y2="47.3221"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00153D" />
          <stop offset="1" stopColor="#0057FF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_11494_82842"
          x1="36.2868"
          y1="44.3815"
          x2="24.6251"
          y2="47.3221"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00153D" />
          <stop offset="1" stopColor="#0057FF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_11494_82842"
          x1="49.998"
          y1="12.5"
          x2="49.998"
          y2="71.2344"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#72E2FF" />
          <stop offset="1" stopColor="#00C2F5" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_11494_82842"
          x1="49.9964"
          y1="12.5"
          x2="49.9964"
          y2="23.6843"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0057FF" />
          <stop offset="1" stopColor="#003191" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_11494_82842"
          x1="49.998"
          y1="29"
          x2="49.998"
          y2="42"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.45" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export const StartDark: React.FC<Props> = ({ size = 100 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.5675 85.5646C62.0477 86.1186 62.4872 86.7288 62.8789 87.3979C63.7396 83.6045 66.9857 80.7271 70.9196 80.4908C73.2133 75.6744 78.5721 72.6648 84.1193 73.4602C90.0248 74.3063 95.1085 80.1871 94.9983 86.0452C94.8708 92.7062 89.0754 98.333 82.3425 98.333H57.9093H42.0893H17.6561C10.5483 98.333 5 92.3022 5 85.8325C5 80.0726 10.0839 74.2913 15.8795 73.4602C21.424 72.6646 26.7846 75.6728 29.079 80.4908C33.0129 80.7273 36.259 83.6045 37.1197 87.3979C37.3697 86.9329 37.6765 86.4856 38.0332 86.0588C41.7557 79.1816 42.4994 71 42.4994 71H57.0828C57.0828 71 57.0828 78.7571 61.5675 85.5646Z"
        fill="url(#paint0_linear_11519_15701)"
      />
      <path
        d="M57.9857 69.943C59.5923 73.211 59.4246 77.6107 56.2974 81.6448C55.9609 82.0789 55.2822 81.8829 55.1708 81.3451C54.8332 79.716 54.1912 77.3721 53.5288 78.9801C52.8544 80.6175 51.2363 84.4875 50.3227 86.6704C50.0911 87.2237 49.2996 87.201 49.0983 86.636C48.3022 84.4012 46.8751 80.447 46.1933 78.8788C45.5373 77.3699 44.8397 79.546 44.4536 81.1303C44.3208 81.6754 43.6153 81.8409 43.3071 81.3721C40.5392 77.1625 40.6606 72.5543 42.0894 69.943L57.9857 69.943Z"
        fill="url(#paint1_linear_11519_15701)"
      />
      <path
        d="M68.4319 53.6655C62.1161 50.891 60.5371 44.7325 60.5371 42V68.7991H75.9978C76.1075 64.9106 74.7478 56.44 68.4319 53.6655Z"
        fill="url(#paint2_linear_11519_15701)"
      />
      <path
        d="M68.4319 53.6655C62.1161 50.891 60.5371 44.7325 60.5371 42V68.7991H75.9978C76.1075 64.9106 74.7478 56.44 68.4319 53.6655Z"
        fill="url(#paint3_linear_11519_15701)"
      />
      <path
        d="M31.5661 53.6655C37.882 50.891 39.4609 44.7325 39.4609 42V68.7991H24.0002C23.8906 64.9106 25.2503 56.44 31.5661 53.6655Z"
        fill="url(#paint4_linear_11519_15701)"
      />
      <path
        d="M31.5661 53.6655C37.882 50.891 39.4609 44.7325 39.4609 42V68.7991H24.0002C23.8906 64.9106 25.2503 56.44 31.5661 53.6655Z"
        fill="url(#paint5_linear_11519_15701)"
      />
      <path
        d="M63.498 38.0759L62.0521 69.3268C62.0028 70.3943 61.1229 71.2344 60.0543 71.2344H39.9418C38.8732 71.2344 37.9933 70.3943 37.944 69.3268L36.498 38.0759C36.498 32.925 37.6786 27.9764 39.8121 23.6666V23.6648C42.0603 19.1193 45.3701 15.2808 49.4708 12.6578C49.7987 12.4481 50.1957 12.4471 50.5237 12.6567C54.4336 15.1561 57.6219 18.76 59.8598 23.0278C59.9703 23.2377 60.0778 23.4512 60.1838 23.6646V23.6664C62.3174 27.9764 63.498 32.9248 63.498 38.0759Z"
        fill="url(#paint6_linear_11519_15701)"
      />
      <circle cx="49.998" cy="35.5" r="6.5" fill="url(#paint7_linear_11519_15701)" />
      <path
        d="M60.1822 23.6825V23.6843H39.8105V23.6825C42.0586 19.1299 45.3682 15.2853 49.4686 12.6582C49.7968 12.448 50.1946 12.4469 50.5227 12.6571C54.4323 15.1606 57.6204 18.7701 59.8582 23.0447C59.9687 23.2549 60.0762 23.4688 60.1822 23.6825V23.6825Z"
        fill="url(#paint8_linear_11519_15701)"
      />
      <path
        d="M60.1822 23.6825V23.6843H39.8105V23.6825C42.0586 19.1299 45.3682 15.2853 49.4686 12.6582C49.7968 12.448 50.1946 12.4469 50.5227 12.6571C54.4323 15.1606 57.6204 18.7701 59.8582 23.0447C59.9687 23.2549 60.0762 23.4688 60.1822 23.6825V23.6825Z"
        fill="#6361C9"
        fillOpacity="0.8"
      />
      <defs>
        <linearGradient id="paint0_linear_11519_15701" x1="50" y1="65" x2="50" y2="131" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B6DCFF" stopOpacity="0.12" />
          <stop offset="1" stopColor="#66B6FF" />
        </linearGradient>
        <linearGradient id="paint1_linear_11519_15701" x1="50" y1="66" x2="50" y2="99" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FCE300" />
          <stop offset="0.208333" stopColor="#FCC400" />
          <stop offset="0.828125" stopColor="#FC0000" stopOpacity="0.53" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_11519_15701"
          x1="63.7112"
          y1="44.3815"
          x2="75.373"
          y2="47.3221"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00153D" />
          <stop offset="1" stopColor="#0057FF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_11519_15701"
          x1="68.2704"
          y1="42"
          x2="68.2704"
          y2="68.7991"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#817FC8" />
          <stop offset="1" stopColor="#6361C9" stopOpacity="0.48" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_11519_15701"
          x1="36.2868"
          y1="44.3815"
          x2="24.6251"
          y2="47.3221"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00153D" />
          <stop offset="1" stopColor="#0057FF" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_11519_15701"
          x1="31.7276"
          y1="42"
          x2="31.7276"
          y2="68.7991"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#817FC8" />
          <stop offset="1" stopColor="#6361C9" stopOpacity="0.48" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_11519_15701"
          x1="49.998"
          y1="12.5"
          x2="49.998"
          y2="71.2344"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#72E2FF" />
          <stop offset="1" stopColor="#00C2F5" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_11519_15701"
          x1="49.998"
          y1="29"
          x2="49.998"
          y2="42"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2E3466" />
          <stop offset="1" stopColor="#2E3466" stopOpacity="0.42" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_11519_15701"
          x1="49.9964"
          y1="12.5"
          x2="49.9964"
          y2="23.6843"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0057FF" />
          <stop offset="1" stopColor="#003191" />
        </linearGradient>
      </defs>
    </svg>
  )
}

import Web3 from 'web3'
import { AbiItem } from 'web3-utils'
import web3NoAccounts from 'utils/web3'
import { poolsConfig } from 'config/constants'
import { PoolCategory, Chain } from 'config/constants/types'

// Addresses
import { getAddress, getMasterChefAddress } from 'utils/addressHelpers'

// ABI
import bep20Abi from 'config/abi/erc20.json'
import masterChef from 'config/abi/masterchef.json'
import sousChef from 'config/abi/sousChef.json'
import sousChefBnb from 'config/abi/sousChefBnb.json'
import IFASaleAbi from 'config/abi/IFAllocationSale.json'
import IFFixedSaleAbi from 'config/abi/IFFixedSale.json'
import IFTieredSaleAbi from 'config/abi/IFTieredSale.json'
import IFAMasterAbi from 'config/abi/IFAllocationMaster.json'
import solvICMarketV2 from 'config/abi/SolvICMarketV2.2.json'
import solvVoucher from 'config/abi/SolvICVoucher.json'
import vidiaAbi from 'config/abi/vIDIA.json'
import lpAbi from 'config/abi/uni_v2_lp.json'
import questRewardAbi from 'config/abi/questReward.json'

export const getContract = (abi: any, address: string, web3?: Web3) => {
  const _web3 = web3 ?? web3NoAccounts[Chain.BSC_MAINNET]
  return new _web3.eth.Contract(abi as unknown as AbiItem, address)
}

export const getBep20Contract = (address: string, web3?: Web3) => {
  return getContract(bep20Abi, address, web3)
}
export const getSouschefContract = (id: number, web3?: Web3) => {
  const config = poolsConfig.find((pool) => pool.sousId === id)
  const abi = config.poolCategory === PoolCategory.BINANCE ? sousChefBnb : sousChef
  return getContract(abi, getAddress(config.contractAddress, Chain.BSC_MAINNET), web3)
}
export const getMasterchefContract = (web3?: Web3) => {
  // Only support BSC Mainnet for now
  return getContract(masterChef, getMasterChefAddress(Chain.BSC_MAINNET), web3)
}

export const getMasterchefContractWithAddress = (address: string, web3?: Web3) => {
  return getContract(masterChef, address, web3)
}

export const getLPContract = (address: string, web3?: Web3) => {
  return getContract(lpAbi, address, web3)
}

export const getIFASaleContract = (address: string, web3?: Web3) => {
  return getContract(IFASaleAbi, address, web3)
}
export const getIFFixedSaleContract = (address: string, web3?: Web3) => {
  return getContract(IFFixedSaleAbi, address, web3)
}

export const getIFTieredSaleContract = (address: string, web3?: Web3) => {
  return getContract(IFTieredSaleAbi, address, web3)
}

export const getIFAMasterContract = (address: string, web3?: Web3) => {
  return getContract(IFAMasterAbi.abi, address, web3)
}

export const getQuestRewardContract = (address: string, web3?: Web3) => {
  return getContract(questRewardAbi, address, web3)
}

export const getSolvMarketContract = (address: string, web3?: Web3) => {
  return getContract(solvICMarketV2.abi, address, web3)
}

export const getSolvVoucherContract = (address: string, web3?: Web3) => {
  return getContract(solvVoucher.abi, address, web3)
}

export const getVIDIAContract = (address: string, web3?: Web3) => {
  return getContract(vidiaAbi.abi, address, web3)
}

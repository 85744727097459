import { useTranslation } from 'contexts/Localization'
import React, { useMemo } from 'react'
import styled, { useTheme } from 'styled-components'
import StyledCard from './StyledCard'
import { TempSuspendedSale } from 'componentsV2/Icons/Staking/TempSuspendedSale'
import { LinkExternal } from 'uikit'
import IFTypography from 'componentsV2/IFTypography'
import { Box } from '@mui/material'
import BigNumber from 'bignumber.js'

const Body = styled.div`
  border-radius: 5px;
  padding: 10px 17.5px;
  margin-top: 20px;
  font-size: 11px;
  text-align: center;
  color: ${({ theme }) => theme.colorsV2?.main4};
`

const PurchasedAmountWrapper = styled.div`
  margin: 20px 30px 0;
  padding: 20px;
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.colorsV2?.light}`};
  display: flex;
  flex-direction: column;
  align-items: center;
`

const TemporarilySuspended: React.FC<{ purchasedAllocation: BigNumber }> = ({ purchasedAllocation }) => {
  const { t } = useTranslation()
  const title = t('Sale has been paused')
  const imageInfo = useMemo(() => {
    return {
      image: <TempSuspendedSale />,
    }
  }, [])

  const subtitleInfo = useMemo(() => {
    return {
      subtitle: t('The purchase period is paused. Stay tuned for updates!'),
    }
  }, [t])
  return (
    <StyledCard title={title} imageInfo={imageInfo} subtitleInfo={subtitleInfo} hidePostSaleInfo>
      <Body>
        {purchasedAllocation.isGreaterThan(0) && (
          <PurchasedAmountWrapper>
            <IFTypography ifcolor="textSecondary" variant="button" fontWeight="regular">
              {t('Your purchased allocations')}
            </IFTypography>
            <Box display="flex" alignContent="center">
              <IFTypography ifcolor="text" variant="h2" fontWeight="regular">
                {purchasedAllocation.decimalPlaces(2).toNumber().toLocaleString()}
              </IFTypography>
            </Box>
          </PurchasedAmountWrapper>
        )}
      </Body>
    </StyledCard>
  )
}

export default TemporarilySuspended

import { useAccount, useNetwork } from 'wagmi'
import { Chain } from 'config/constants/types'
import { useCoinGeckoPrice } from 'hooks/useCoinGeckoPrice'
import { useKycStatus } from 'hooks/useKycStatus'
import erc20 from 'config/abi/erc20.json'
import { useEffect, useMemo, useState } from 'react'
import { useAppStateUnavailableNetwork } from 'state/hooks'
import { KYCStatus, StepRequirementEnum } from 'state/types'
import { IDO, SubscribeableIDO } from 'state/v2_types'
import IImpossiblePairABI from 'config/abi/IImpossiblePair.json'
import multicall from 'utils/multicall'
import BigNumber from 'bignumber.js'
import useRefresh from 'hooks/useRefresh'

const useSaleParticipable = (sale?: IDO): boolean => {
  const { address: account } = useAccount()
  const { chain } = useNetwork()
  const chainId = chain?.id
  const isNetworUnavailable = useAppStateUnavailableNetwork()
  const { status: kycStatus, complianceCountry: country } = useKycStatus()
  if (!sale) return false
  const targetNetworkID = sale.chainId

  if (!account) return false
  if (isNetworUnavailable || (targetNetworkID != null && chainId != null && targetNetworkID !== chainId)) return false
  if (sale.stepRequirement?.includes(StepRequirementEnum.KYC) && kycStatus !== KYCStatus.VERIFIED) {
    return false
  }
  if (sale.restrictedCountries?.includes(country)) {
    return false
  }
  return true
}

const fetchPoolInfo = async (masterAddress: string, stakingTokenAddress: string, chainId: Chain) => {
  try {
    const lpInformationCalls = [
      { address: stakingTokenAddress, name: 'token0' },
      { address: stakingTokenAddress, name: 'token1' },
    ]

    const [token0, token1] = await multicall(IImpossiblePairABI.abi, lpInformationCalls, chainId)
    const token0Address = token0[0]
    const token1Address = token1[0]

    const calls = [
      // Balance of token in the LP contract
      {
        address: token0Address,
        name: 'balanceOf',
        params: [stakingTokenAddress],
      },
      // Balance of quote token on LP contract
      {
        address: token1Address,
        name: 'balanceOf',
        params: [stakingTokenAddress],
      },
      // token0 decimals
      {
        address: token0Address,
        name: 'decimals',
      },
      // token1 decimals
      {
        address: token1Address,
        name: 'decimals',
      },
      // Balance of LP tokens in the master chef contract
      {
        address: stakingTokenAddress,
        name: 'balanceOf',
        params: [masterAddress],
      },
      // Total supply of LP tokens
      {
        address: stakingTokenAddress,
        name: 'totalSupply',
      },
    ]

    const [token0BalanceLP, token1BalanceLP, token0Decimals, token1Decimals, lpTokenBalanceMC, lpTotalSupply] =
      await multicall(erc20, calls, chainId)

    // Ratio in % a LP tokens that are in staking, vs the total number in circulation
    const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(new BigNumber(lpTotalSupply))
    // Amount of token in the LP that are considered staking (i.e amount of token * lp ratio)
    const token0Amount = new BigNumber(token0BalanceLP).div(new BigNumber(10).pow(token0Decimals)).times(lpTokenRatio)
    const token1Amount = new BigNumber(token1BalanceLP).div(new BigNumber(10).pow(token1Decimals)).times(lpTokenRatio)

    return { token0Amount, token1Amount }
  } catch (e) {
    return null
  }
}

const usePoolAmountInfo = (masterAddress: string, stakingTokenAddress: string, chainId: Chain) => {
  const [poolInfo, setPoolInfo] = useState(undefined)
  const { slowRefresh } = useRefresh()

  useEffect(() => {
    const fetchPool = async () => {
      const result = await fetchPoolInfo(masterAddress, stakingTokenAddress, chainId)
      setPoolInfo(result)
    }

    fetchPool()
  }, [masterAddress, stakingTokenAddress, chainId, slowRefresh])

  return poolInfo
}

export const useApr = (sale: SubscribeableIDO): number | null => {
  const { token, chainId, saleAmount, stakingToken, masterAddress } = sale
  const stakingTokenAddress = stakingToken?.address && stakingToken?.address[chainId]
  const symbols = useMemo(() => [token.coinGeckoId], [token])
  const prices = useCoinGeckoPrice(symbols)
  const poolInfo = usePoolAmountInfo(masterAddress, stakingTokenAddress, chainId)
  const saleTokenPrice = prices && prices[token.coinGeckoId?.toLocaleLowerCase()]?.price
  const yearlyRewards = saleAmount * 12 * saleTokenPrice
  const tvl = new BigNumber(poolInfo?.token0Amount || 0)
    .times(saleTokenPrice)
    .plus(poolInfo?.token1Amount || 0)
    .toNumber()
  return (yearlyRewards * 100) / tvl
}

export { useSaleParticipable }
export default useSaleParticipable

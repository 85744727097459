import React from 'react'

interface StandardIconProps {
  size?: number
}

const StandardIcon: React.FC<StandardIconProps> = ({ size = 60 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.8548 21.0664H44.6076C44.9354 21.0672 45.2344 21.2447 45.3788 21.5244C45.5233 21.804 45.4881 22.1374 45.2881 22.3842L40.7484 27.9338C40.5072 28.2272 40.5072 28.639 40.7484 28.9324L45.2881 34.4819C45.4881 34.7288 45.5233 35.0621 45.3788 35.3418C45.2344 35.6215 44.9354 35.799 44.6076 35.7997H26.5178C25.0906 35.7997 23.9336 34.7003 23.9336 33.3442V30.8886L36.8548 21.0664Z"
        fill="#BA3324"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1328 26.7334H20.5328V44.3001C20.5328 45.2389 19.7717 46.0001 18.8328 46.0001C17.8939 46.0001 17.1328 45.2389 17.1328 44.3001V26.7334Z"
        fill="#E64C3C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.872 12C19.662 12 20.3025 12.6405 20.3025 13.4306V17.0069H17.4414V13.4306C17.4414 12.6405 18.0819 12 18.872 12Z"
        fill="#E64C3C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.7222 18.2959V28.3699C37.7222 29.7609 36.5552 30.8885 35.1156 30.8885H17.7378C16.7792 30.8857 16.0029 30.1356 16 29.2094V17.4564C16.0029 16.5302 16.7792 15.7801 17.7378 15.7773H35.1156C36.5552 15.7773 37.7222 16.9049 37.7222 18.2959Z"
        fill="#FF454B"
      />
    </svg>
  )
}

export default StandardIcon

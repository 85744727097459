import { Box } from '@mui/material'
import { useNetwork } from 'wagmi'
import BigNumber from 'bignumber.js'
import ActionBtnWithApproveFlow from 'componentsV2/ActionBtnWithApproveFlow/ActionBtnWithApproveFlow'
import IFTypography from 'componentsV2/IFTypography/IFTypography'
import InputArea from 'componentsV2/InputArea/InputArea'
import { useTranslation } from 'contexts/Localization'
import { ApprovalState } from 'hooks/useApproveCallback'
import { useStake } from 'hooks/useIFAMaster'
import useTokenBalance from 'hooks/useTokenBalance'
import React, { useCallback, useMemo, useState } from 'react'
import { useToast } from 'state/hooks'
import { useSaleUserData } from 'state/idos/hooks'
import { StepRequirementEnum } from 'state/types'
import { SubscribeableIDO } from 'state/v2_types'
import styled from 'styled-components'
import { useIdoApprove } from 'hooks/useApprove'
import { OpenNewIcon, useIsDrawerOpen, useModalV2 } from 'uikit'
import ModalV2 from 'uikit/widgets/Modal/ModalV2'
import { getBscScanLink } from 'utils'
import { convertFromWei, getBalanceInWei } from 'utils/formatBalance'
import getMoreTokenAddress from 'utils/getMoreTokenAddress'
import { QUICK_ACTION_DRAWER_TYPE } from 'views/Manage/Manage'
import SaleDetailCard from '../SaleDetailCard'

const InputBodyContainer = styled.div`
  margin: 20px auto 0px;
  width: 100%;
  max-width: 390px;
  span {
    text-transform: unset;
  }
`

const EstimatedBox = styled(Box)`
  background: ${({ theme }) => (theme.isDark ? `${theme.colorsV2.dark}4D` : `${theme.colorsV2?.dark2}80`)};
  padding: 15px 20px;
  border-radius: 20px;
`

interface StakingCardProps {
  sale: SubscribeableIDO
}

const StakingCard: React.FC<StakingCardProps> = ({ sale }) => {
  const { t } = useTranslation()
  const { chain } = useNetwork()
  const chainId = chain?.id
  const { subscribePeriod, stakingToken } = sale
  const countdownInfo = useMemo(() => {
    const endTimestamp = new Date(subscribePeriod.endTime).getTime()
    const currentTimestamp = new Date().getTime()
    const seconds = Math.trunc((endTimestamp - currentTimestamp) / 1000)
    return {
      seconds,
      description: t('Staking ends in'),
    }
  }, [subscribePeriod, t])

  const children = useMemo(() => {
    return <InputBody sale={sale} />
  }, [sale])

  const linkBtnInfo = useMemo(() => {
    return {
      text: t('Get more %token%', { token: stakingToken.symbol }),
      icon: <OpenNewIcon />,
      href: getMoreTokenAddress(stakingToken, { chainId }),
    }
  }, [t, stakingToken, chainId])
  const [isOpen, drawerType] = useIsDrawerOpen()

  return (
    <SaleDetailCard
      requireKYC={!!sale.stepRequirement?.includes(StepRequirementEnum.KYC)}
      targetNetworkID={sale.chainId}
      restrictedCountries={sale.restrictedCountries}
      countdownInfo={countdownInfo}
      linkBtnInfo={isOpen && drawerType === QUICK_ACTION_DRAWER_TYPE ? undefined : linkBtnInfo}
    >
      {children}
    </SaleDetailCard>
  )
}

const InputBody: React.FC<{ sale: SubscribeableIDO }> = ({ sale }) => {
  const { t } = useTranslation()
  const userData = useSaleUserData(sale.id)
  const [inputVal, setInputVal] = useState('')
  const { subscribePeriod, stakingToken, trackId, masterAddress, chainId } = sale
  const { maxTotalStake: intMaxTotalStake } = subscribePeriod
  const maxTotalStake = new BigNumber(intMaxTotalStake)
  const intTrackId = parseInt(trackId)
  const balance = convertFromWei(useTokenBalance(stakingToken.address), stakingToken.decimals)
  const { onStake, isLoading: staking } = useStake(masterAddress, intTrackId)

  const stakingLimit = subscribePeriod.isLimited ? new BigNumber(subscribePeriod.maxTotalStake) : null
  const stakedAmount = new BigNumber(userData?.userStakeAmount ?? 0)
  const maxAmountToStake = stakingLimit ? BigNumber.min(stakingLimit.minus(stakedAmount), balance) : balance
  const tokenAllowance = userData?.stakingTokenAllowanceInWei

  const approval =
    tokenAllowance === '0' ||
    !tokenAllowance ||
    new BigNumber(convertFromWei(tokenAllowance, stakingToken.decimals)).isLessThan(new BigNumber(inputVal))
      ? ApprovalState.NOT_APPROVED
      : ApprovalState.APPROVED
  const { onApprove, isLoading } = useIdoApprove(masterAddress, stakingToken.address)

  const [onPresent, onDismiss] = useModalV2({
    modal: (
      <ModalV2
        bodyTitle={t('Confirm Staking')}
        bodySubtitle={t('Staking %amount% %token%.', {
          amount: new BigNumber(inputVal).decimalPlaces(2).toNumber().toLocaleString(),
          token: stakingToken.symbol,
        })}
        bodyDescription={t('Confirm this transaction in your wallet')}
        showLoader
        bodyImageWidth={36}
        bodyImageMargin="33px 0 66px 0"
      />
    ),
    options: {
      backdropBlurVal: 'blur(20px)',
      closeOnOverlayClick: false,
    },
  })

  const { toastSuccessV2 } = useToast()

  const onStakeSuccess = useCallback(
    (txHash) => {
      toastSuccessV2(`Success`, `You've staked ${inputVal} ${stakingToken.symbol}`, {
        text: 'View on explorer',
        url: getBscScanLink(txHash, 'transaction', chainId),
      })
      setInputVal('')
      onDismiss()
    },
    [inputVal, stakingToken, toastSuccessV2, onDismiss, chainId],
  )

  const stakeHandler = useCallback(async () => {
    onPresent()
    await onStake(getBalanceInWei(new BigNumber(inputVal), stakingToken.decimals), onStakeSuccess)
    onDismiss()
  }, [onStake, onStakeSuccess, stakingToken, inputVal, onPresent, onDismiss])

  let invalidButtonText = t('Enter an amount')
  let isButtonValid = true
  const inputValBigNumber = new BigNumber(inputVal)

  if (balance.isLessThan(inputValBigNumber)) {
    isButtonValid = false
    invalidButtonText = t('Insufficient %token% Balance', { token: stakingToken.symbol })
  } else if (maxTotalStake && new BigNumber(inputVal).isGreaterThan(maxTotalStake.minus(stakedAmount))) {
    isButtonValid = false
    invalidButtonText = t(`Exceeded Limit`)
  }

  const [isOpen, drawerType] = useIsDrawerOpen()
  const isSidebar = isOpen && drawerType === QUICK_ACTION_DRAWER_TYPE

  const estimatedAllocation = userData?.userTokenAllocation || new BigNumber(0)
  const estimatedAllocationStr = estimatedAllocation.decimalPlaces(2).toNumber().toLocaleString()

  return (
    <InputBodyContainer>
      {isSidebar && (
        <EstimatedBox marginBottom={5}>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <IFTypography variant="caption" ifcolor="textSecondary">
                {t('EST. Allocation')}
              </IFTypography>
            </Box>
            <Box>
              <IFTypography variant="caption" fontWeight="bold" ifcolor="text">
                {estimatedAllocationStr}
              </IFTypography>
            </Box>
          </Box>
          <Box marginTop={2} display="flex" justifyContent="space-between">
            <Box>
              <IFTypography variant="caption" ifcolor="textSecondary">
                {t('Staked %token%', { token: stakingToken.symbol })}
              </IFTypography>
            </Box>
            <Box>
              <IFTypography variant="caption" fontWeight="bold" ifcolor="text">
                {stakedAmount.decimalPlaces(2).toNumber().toLocaleString()}
              </IFTypography>
            </Box>
          </Box>
        </EstimatedBox>
      )}

      <InputArea
        balance={balance}
        inputVal={inputVal}
        iconSrc={`/images/tokens/${stakingToken.symbol}.png`}
        symbol={stakingToken.symbol}
        onInputChange={setInputVal}
        maxAmountOnSelect={maxAmountToStake}
        isLP={stakingToken.isLP}
        icon1Src={`/images/tokens/${stakingToken.symbol.split('-')[0]}.png`}
        icon2Src={
          stakingToken.symbol.split('-').length > 1 ? `/images/tokens/${stakingToken.symbol.split('-')[1]}.png` : ''
        }
      />
      {stakingLimit && (
        <Box marginTop="5px" textAlign="center">
          {maxTotalStake.minus(stakedAmount).isGreaterThan(0) ? (
            <>
              <IFTypography fontFamily="Roboto" variant="overline" lineHeight="18px">
                Your can stake{' '}
              </IFTypography>
              <IFTypography fontFamily="Roboto" variant="button" lineHeight="20px" fontWeight="bold">
                {` ${stakingLimit.minus(stakedAmount).decimalPlaces(2).toNumber().toLocaleString()} `}
              </IFTypography>
              <IFTypography fontFamily="Roboto" variant="overline" lineHeight="18px">
                more{' '}
              </IFTypography>
              <IFTypography fontFamily="Roboto" variant="overline" lineHeight="18px">
                {stakingToken.symbol}
              </IFTypography>
            </>
          ) : (
            <>
              <IFTypography fontFamily="Roboto" variant="overline" lineHeight="18px">
                You have reached <b>maximum</b> staking cap
              </IFTypography>
            </>
          )}
        </Box>
      )}
      <Box textAlign="center" marginTop="40px">
        <ActionBtnWithApproveFlow
          approvalState={approval}
          approveHandler={onApprove}
          balance={balance}
          userInput={inputVal}
          decimals={stakingToken.decimals}
          actionHandler={stakeHandler}
          inProgress={isLoading || staking}
          textWithoutApproval={stakedAmount.isGreaterThan(0) ? t('Stake More') : t('Stake')}
          textInvalid={invalidButtonText}
          textWithApproval={stakedAmount.isGreaterThan(0) ? t('Stake More') : t('Stake')}
          isValid={isButtonValid}
          isMobileOverride={isSidebar}
        />
      </Box>
    </InputBodyContainer>
  )
}

export default StakingCard

import { useAccount, useSignMessage } from 'wagmi'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useToast } from 'state/hooks'
import { setUserSignedState } from 'state/user/actions'

export type TOAuth = 'if' | 'frill'
const useSignOAuth = (type: TOAuth, redirect?: string) => {
  const { signMessageAsync } = useSignMessage()

  const { address: account } = useAccount()

  const { toastErrorV2 } = useToast()
  const dispatch = useDispatch()

  const URL = process.env.REACT_APP_BACKEND_URL
  const NONCE_ENDPOINT = `${URL}/api/backend-service/oauth/nonce`
  const AUTH_ENDPOINT = `${URL}/api/backend-service/oauth/auth/${type}`

  const handleRedirect = (res) => {
    if (!redirect) {
      return
    }
    if (res.data.access_token) {
      window.location.href = `${redirect}?ssoToken=${res.data.access_token}`
    } else {
      throw new Error('No token.')
    }
  }

  const handleAuthenticate = ({ publicAddress: address, signature }: { publicAddress: string; signature: string }) =>
    fetch(`${AUTH_ENDPOINT}`, {
      body: JSON.stringify({ address, signature }),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }).then((response) => response.json())

  const handleSignMessage = async ({ publicAddress, nonce }: { publicAddress: string; nonce: string }) => {
    try {
      const signature = await signMessageAsync({ message: `SSO login with wallet, code: ${nonce}` })
      return { publicAddress, signature: signature }
    } catch (err) {
      console.error(err)
      throw new Error('You need to sign the message to be able to log in.')
    }
  }

  const handleStorePersistent = () => {
    if (type === 'if') {
      // set the expiry to 6.5 days ahead
      dispatch(setUserSignedState({ isSigned: true, expiry: new Date().getTime() + 6.5 * 24 * 3600 * 1000, account }))
    }
  }

  const signOAuth = useCallback(
    (onSucceed?, onError?) => {
      // Fetch nonce
      const publicAddress = account.toLowerCase()
      fetch(`${NONCE_ENDPOINT}?address=${publicAddress}`)
        .then((res) => res.json())
        .then((res) => res.data?.nonce)
        // Popup MetaMask confirmation modal to sign message
        .then((nonce) => {
          if (!nonce) {
            throw new Error('No nonce found.')
          }
          return handleSignMessage({ publicAddress, nonce })
        })
        // Send signature to backend on the /auth route
        .then(handleAuthenticate)
        .then(handleRedirect)
        .then(handleStorePersistent)
        .then(() => {
          if (onSucceed) onSucceed()
        })
        .catch((error) => {
          console.error(`OAuth(${type}) Login failed:`, error)

          if (error.toString().indexOf('sign the message') > -1) {
            toastErrorV2('Error', `User rejected the signature request.`)
          } else {
            toastErrorV2('Error', `Sign failed`)
          }

          onError?.()
        })
    },
    [account],
  )

  return { signOAuth }
}

export default useSignOAuth

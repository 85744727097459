import { Box } from '@mui/material'
import React from 'react'
import styled, { keyframes } from 'styled-components'

const loadingAnimation = keyframes`
  to {
    transform: translateX(100%);
  }
`

const LoadingContainer = styled.div<{ height: string; padding: string; margin: string }>`
  height: ${({ height }) => height};
  border-radius: 20px;
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  background: ${({ theme }) => (theme.isDark ? theme.colorsV2.main2 : theme.colorsV2.dark)};
  display: flex;
`

const Skeleton = styled(Box)`
  background: ${({ theme }) => theme.colorsV2.loadingFirst};
  overflow: hidden;
  position: relative;
  z-index: 1;
  ${({ theme }) => theme.isDark && 'box-shadow: inset 0px 0px 1px #041A00'};
  &::after {
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background-image: ${({ theme }) => {
      const colorDark = theme.colorsV2.loadingSecond
      const colorLight = theme.colorsV2.loadingFirst
      return `linear-gradient(90deg, ${colorLight} 0, ${colorDark}  50%, ${colorLight} 100%)`
    }};
    transform: translateX(-100%);
    background-repeat: no-repeat;
    animation: ${loadingAnimation} 1.5s ease-in-out infinite;
  }
`

const Loading = ({ height = '135px', padding = '0px', margin = '0', firstWidth = '100%', secondWidth = '60%' }) => (
  <LoadingContainer height={height} padding={padding} margin={margin}>
    <Skeleton height="44px" width="44px" flexShrink="0" borderRadius="50%" />
    <Box marginLeft="20px" display="flex" flexDirection="column" width="100%" height="fit-content">
      <Skeleton height="20px" borderRadius="2px" width={firstWidth} />
      <Skeleton marginTop="10px" height="14px" width={secondWidth} borderRadius="2px" />
    </Box>
  </LoadingContainer>
)

export const MobileLoading = ({
  height = '135px',
  padding = '0',
  margin = '0',
  firstWidth = '60%',
  secondWidth = '100%',
}) => (
  <LoadingContainer height={height} padding={padding} margin={margin}>
    <Skeleton height="44px" width="44px" flexShrink="0" borderRadius="50%" />
    <Box marginLeft="20px" display="flex" flexDirection="column" width="100%" height="fit-content">
      <Skeleton height="20px" borderRadius="2px" width={firstWidth} />
      <Skeleton marginTop="10px" height="14px" width={secondWidth} borderRadius="2px" />
    </Box>
  </LoadingContainer>
)

export default Loading

import React, { useState } from 'react'
import { Button, Text, Flex, Message, Checkbox, Link } from 'uikit'
import { AutoColumn } from 'componentsV2/layout/Column'
import { useAddUserToken } from 'state/user/hooks'
import { getBscScanLink, getScanText } from 'utils'
import truncateHash from 'utils/truncateHash'
import { useTranslation } from 'contexts/Localization'
import { useNetwork } from 'wagmi'
import { TokenClass } from 'swap-sdk/entities/token'
import { Currency } from 'swap-sdk/entities/currency'
import styled from 'styled-components'
import { CurrencyLogo } from 'componentsV2/Logo'

interface ImportProps {
  tokens: TokenClass[]
  handleCurrencySelect?: (currency: Currency) => void
}

const StyledLink = styled(Link)`
  color: ${({ theme }) => (theme.isDark ? theme.colorsV2?.textLink : theme.colorsV2?.textThirdly)};
  font-size: 12px;
  font-weight: 700;
`

function ImportToken({ tokens, handleCurrencySelect }: ImportProps) {
  const { chain } = useNetwork()
  const chainId = chain?.id

  const { t } = useTranslation()

  const [confirmed, setConfirmed] = useState(false)

  const addToken = useAddUserToken()

  // use for showing import source on inactive tokens

  return (
    <AutoColumn gap="30px">
      <Message variant="warning">
        <Flex flexDirection="column">
          <Text lineHeight="20px" fontSize="14px">
            {t(
              'Anyone can create a BEP20 token on Blockchain with any name, including creating fake versions of existing tokens and tokens that claim to represent projects that do not have a token.',
            )}
            <br />
            <br />
            {t('If you purchase an arbitrary token, you may be unable to sell it back.')}
          </Text>
          <Flex mt="10px" alignItems="center" onClick={() => setConfirmed(!confirmed)}>
            <Checkbox
              scale="sm"
              name="confirmed"
              type="checkbox"
              checked={confirmed}
              onChange={() => setConfirmed(!confirmed)}
            />
            <Text fontSize="14px" bold ml="8px" style={{ userSelect: 'none' }}>
              {t('I understand')}
            </Text>
          </Flex>
        </Flex>
      </Message>

      {tokens.map((token) => {
        const address = token.address ? `${truncateHash(token.address)}` : null
        return (
          <Flex flexDirection="row" key={token.address}>
            <CurrencyLogo currency={token} />
            <Flex ml="15px" flexDirection="column" width="100%">
              <Flex alignItems="center">
                <Text bold mr="8px" fontSize="16px">
                  {token.symbol}
                </Text>
                <Text fontSize="12px">{token.name}</Text>
              </Flex>
              {chainId && (
                <Flex mt="6px" justifyContent="space-between" width="100%">
                  <Text fontSize="12px" bold mr="4px">
                    {address}
                  </Text>
                  <StyledLink href={getBscScanLink(token.address, 'address', chainId)} external>
                    ({t('View on %scanner%', { scanner: getScanText(chainId) })})
                  </StyledLink>
                </Flex>
              )}
            </Flex>
          </Flex>
        )
      })}

      <Flex justifyContent="space-between" alignItems="center">
        <Button
          fullWidth
          variant="primary"
          scale="lg"
          disabled={!confirmed}
          onClick={() => {
            tokens.map((token) => addToken(token))
            if (handleCurrencySelect) {
              handleCurrencySelect(tokens[0])
            }
          }}
        >
          {t('Import')}
        </Button>
      </Flex>
    </AutoColumn>
  )
}

export default ImportToken

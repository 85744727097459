import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import masterchefABI from 'config/abi/masterchef.json'
import { FarmConfig } from 'state/v2_types'
import { multicallPerChainId, reduceByChainId } from 'utils/chainIdReducer'

export const fetchFarmUserTokenBalances = async (farms: FarmConfig[], account: string) => {
  const callsPerChainId = reduceByChainId(farms, (farm) => {
    const { stakingToken, chainId } = farm
    const stakingTokenAddress = stakingToken.address[chainId]

    return { address: stakingTokenAddress, name: 'balanceOf', params: [account] }
  })

  const rawTokenBalances = await multicallPerChainId(erc20ABI, callsPerChainId)

  return Object.keys(rawTokenBalances).reduce((acc, key) => {
    acc[key] = new BigNumber(rawTokenBalances[key]).toJSON()
    return acc
  }, {})
}

export const fetchFarmUserStakedBalances = async (farms: FarmConfig[], account: string) => {
  const callsPerChainId = reduceByChainId(farms, (farm) => {
    const { masterChefContract } = farm
    return { address: masterChefContract, name: 'userInfo', params: [account] }
  })

  const rawStakedBalances = await multicallPerChainId(masterchefABI, callsPerChainId)

  return Object.keys(rawStakedBalances).reduce((acc, key) => {
    acc[key] = new BigNumber(rawStakedBalances[key][0]._hex).toJSON()
    return acc
  }, {})
}

export const fetchFarmUserEarnings = async (farms: FarmConfig[], account: string) => {
  const callsPerChainId = reduceByChainId(farms, (farm) => {
    const { masterChefContract } = farm
    return { address: masterChefContract, name: 'pendingReward', params: [account] }
  })

  const rawEarnings = await multicallPerChainId(masterchefABI, callsPerChainId)

  return Object.keys(rawEarnings).reduce((acc, key) => {
    acc[key] = new BigNumber(rawEarnings[key]).toJSON()
    return acc
  }, {})
}

export const fetchFarmEnabled = async (farms: FarmConfig[], account: string) => {
  const callsPerChainId = reduceByChainId(farms, (farm) => {
    const { masterChefContract, stakingToken, chainId } = farm
    const stakingTokenAddress = stakingToken.address[chainId]
    return { address: stakingTokenAddress, name: 'allowance', params: [account, masterChefContract] }
  })
  const rawAllowances = await multicallPerChainId(erc20ABI, callsPerChainId)

  return Object.keys(rawAllowances).reduce((acc, key) => {
    acc[key] = new BigNumber(rawAllowances[key]).gt(0)
    return acc
  }, {})
}

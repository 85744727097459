import {
  PurchasableIDO,
  SubscribeableIDO,
  ClaimableIDO,
  DroppedIDO,
  FixedAllocationIDO,
  CliffVestableIDO,
  LinearVestableIDO,
} from 'state/v2_types'

export const isSubscribeableIDO = (sale: SubscribeableIDO): boolean => {
  return !!('subscribePeriod' in sale && sale.subscribePeriod?.startTime && sale.subscribePeriod?.endTime)
}

export const isPurchaseableIDO = (sale: PurchasableIDO): boolean => {
  return !!(
    'purchasePeriod' in sale &&
    sale.purchasePeriod?.startTime &&
    sale.purchasePeriod?.endTime &&
    sale.purchasePeriod?.salePrice &&
    sale.purchasePeriod?.salePrice > 0
  )
}

export const isFixedAllocationIDO = (sale: FixedAllocationIDO): boolean => {
  return !!('saleTokenAllocationOverride' in sale) && sale.saleTokenAllocationOverride > 0
}

export const isClaimableIDO = (sale: ClaimableIDO): boolean => {
  return !!('claimPeriod' in sale && sale.claimPeriod?.startTime)
}

export const isDroppedIDO = (sale: DroppedIDO): boolean => {
  return !!('airdropInfo' in sale && (sale.airdropInfo?.initialAirdrop || sale.airdropInfo?.isInitialTBD))
}

export const isCliffVestableIDO = (sale: CliffVestableIDO): boolean => {
  return !!('cliffVestInfo' in sale && sale.cliffVestInfo?.vestList?.length > 0)
}

export const isLinearVestableIDO = (sale: LinearVestableIDO): boolean => {
  return !!('linearVestInfo' in sale && sale.linearVestInfo?.startTime && sale.linearVestInfo?.endTime)
}

export default isSubscribeableIDO

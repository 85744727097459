import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'contexts/Localization'
import { useBlock } from 'state/block/hooks'
import { useToast } from 'state/hooks'
import { useNetwork } from 'wagmi'
import { createPublicClient, custom } from 'viem'

import { getBscScanLink, getScanText } from 'utils'
import { AppDispatch, AppState } from '../index'
import { checkedTransaction, finalizeTransaction } from './actions'
import useWeb3 from 'hooks/useWeb3'

export function shouldCheck(
  currentBlock: number,
  tx: { addedTime: number; receipt?: any; lastCheckedBlockNumber?: number },
): boolean {
  if (tx.receipt) return false
  if (!tx.lastCheckedBlockNumber) return true
  const blocksSinceCheck = Number(currentBlock) - tx.lastCheckedBlockNumber
  if (blocksSinceCheck < 1) return false
  const minutesPending = (new Date().getTime() - tx.addedTime) / 1000 / 60
  if (minutesPending > 60) {
    // every 10 blocks if pending for longer than an hour
    return blocksSinceCheck > 9
  }
  if (minutesPending > 5) {
    // every 3 blocks if pending more than 5 minutes
    return blocksSinceCheck > 2
  }
  // otherwise every block
  return true
}

export default function Updater(): null {
  const { chain } = useNetwork()
  const chainId = chain?.id || 0
  const { t } = useTranslation()

  const { currentBlock } = useBlock(chainId)

  const dispatch = useDispatch<AppDispatch>()
  const state = useSelector<AppState, AppState['transactions']>((s) => s.transactions)

  const transactions = useMemo(() => (chainId ? state[chainId] ?? {} : {}), [chainId, state])

  const { toastError, toastSuccessV2 } = useToast()

  useEffect(() => {
    if (!chainId || !currentBlock || !window.ethereum) return

    const provider = createPublicClient({
      chain,
      transport: custom(window.ethereum),
    })

    Object.keys(transactions)
      .filter((hash) => shouldCheck(currentBlock, transactions[hash]))
      .forEach((hash) => {
        provider
          .getTransactionReceipt({ hash: hash as any })
          .then((receipt) => {
            if (receipt) {
              const anyReceipt = receipt as any
              dispatch(
                finalizeTransaction({
                  chainId,
                  hash,
                  receipt: {
                    blockHash: anyReceipt.blockHash,
                    blockNumber: Number(anyReceipt.blockNumber),
                    contractAddress: anyReceipt.contractAddress,
                    from: anyReceipt.from,
                    status: anyReceipt.status,
                    to: anyReceipt.to,
                    transactionHash: anyReceipt.transactionHash,
                    transactionIndex: anyReceipt.transactionIndex,
                  },
                }),
              )

              if (anyReceipt.status === 1 || anyReceipt.status === 'success') {
                toastSuccessV2(
                  t('Success'),
                  transactions[hash]?.summary ?? `Hash: ${hash.slice(0, 8)}...${hash.slice(58, 65)}`,
                  chainId
                    ? {
                        text: t('View on %scanner%', { scanner: getScanText(chainId) }),
                        url: getBscScanLink(hash, 'transaction', chainId),
                      }
                    : {
                        text: '',
                        url: '',
                      },
                )
              } else {
                toastError(
                  t('Transaction receipt'),
                  // TODO: Update to reactNode
                  // <Flex flexDirection="column">
                  //   <Text>{transactions[hash]?.summary ?? `Hash: ${hash.slice(0, 8)}...${hash.slice(58, 65)}`}</Text>
                  //   {chainId && (
                  //     <Link external href={getBscScanLink(hash, 'transaction', chainId)}>
                  //       {t('View on BscScan')}
                  //     </Link>
                  //   )}
                  // </Flex>,
                  transactions[hash]?.summary ?? `Hash: ${hash.slice(0, 8)}...${hash.slice(58, 65)}`,
                )
              }
            } else {
              dispatch(checkedTransaction({ chainId, hash, blockNumber: currentBlock }))
            }
          })
          .catch((error) => {
            console.error(`failed to check transaction hash: ${hash}`, error)
          })
      })
  }, [chainId, transactions, currentBlock, dispatch, toastSuccessV2, toastError, t])

  return null
}

import { Box } from '@mui/material'
import IFTypography from 'componentsV2/IFTypography/IFTypography'
import React from 'react'
import { useMatchBreakpoints } from 'uikit'
import { IDO } from 'state/v2_types'
import styled from 'styled-components'
import { getSaleIcon } from './helper'

interface TopHeaderProps {
  sale: IDO
}

const IconWrapper = styled.div<{ isMobile: boolean }>`
  flex-shrink: 0;
  border-radius: ${({ isMobile }) => (isMobile ? '10px' : '20px')};
  background: ${({ theme }) => (theme.isDark ? `${theme.colorsV2?.light}80` : '#FAFAFA80')};
  box-shadow: ${({ theme }) => (theme.isDark ? `0px 0px 20px rgba(0, 0, 0, 0.1)` : `0px 0px 14px rgb(0 0 0 / 16%)`)};
  width: ${({ isMobile }) => (isMobile ? '44px' : '60px')};
  height: ${({ isMobile }) => (isMobile ? '44px' : '60px')};

  display: flex;
  justify-content: center;
  align-items: center;
`

const TitleWrapper = styled(Box)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  flex-grow: 1;
`

const TopTitle: React.FC<TopHeaderProps> = ({ sale }) => {
  const SaleIcon = getSaleIcon(sale, 44)
  const { isXl } = useMatchBreakpoints()
  const isMobile = !isXl
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <IconWrapper isMobile={isMobile}>{SaleIcon}</IconWrapper>
      <TitleWrapper marginX="10px" title={sale.title}>
        <IFTypography variant="h6" fontWeight="bold" textAlign="left">
          {sale.title}
        </IFTypography>
      </TitleWrapper>
      <Box flexShrink="0">
        <img width="26px" src={`/images/icons/networks/${sale.chainId}.svg`} alt={`network_${sale.chainId}`} />
      </Box>
    </Box>
  )
}

export default TopTitle

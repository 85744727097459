import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const InvestDark: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M10.3514 5.83722C9.76632 5.83722 9.29034 6.31323 9.29034 6.89824C9.34368 8.30392 11.3593 8.30353 11.4124 6.89824C11.4124 6.31323 10.9364 5.83722 10.3514 5.83722Z"
        fill="#BDC2FF"
      />
      <path
        d="M12.4236 0.618936L12.5304 1.22934C12.8262 3.02091 14.2287 4.42341 16.0202 4.71919L16.6335 4.81631C17.4035 2.27254 17.2231 0.413062 17.2481 0.0014917C16.8304 0.0268501 14.9661 -0.152498 12.4236 0.618936Z"
        fill="#BDC2FF"
      />
      <path
        d="M11.484 1.40475L11.4076 0.967712C7.3292 2.53389 3.72883 5.98798 2.63853 10.207C2.63853 10.207 2.24989 11.9994 3.75008 13.4996C5.25027 14.9998 7.04272 14.6112 7.04272 14.6112C11.2074 13.535 14.6947 9.98142 16.2846 5.83533L15.8509 5.76665C13.608 5.39635 11.8548 3.64382 11.484 1.40475ZM10.3515 9.02024C9.18139 9.02024 8.22944 8.06832 8.22944 6.89819C8.33615 4.08687 12.3673 4.08765 12.4735 6.89823C12.4735 8.06832 11.5216 9.02024 10.3515 9.02024Z"
        fill="#BDC2FF"
      />
      <path
        d="M7.69879 15.5302V17.5083H8.2293C11.5681 17.5083 14.0641 14.6568 14.0649 11.417C12.3185 13.3365 10.1192 14.8163 7.69879 15.5302Z"
        fill="#BDC2FF"
      />
      <path
        d="M5.83258 3.18457C2.58592 3.18542 -0.258789 5.68727 -0.258789 9.02015V9.55067H1.71938C2.43472 7.12552 3.91701 4.92733 5.83258 3.18457Z"
        fill="#BDC2FF"
      />
      <path
        d="M1.85231 11.9998C0.468335 13.308 0.204803 15.4469 -6.10352e-05 17.2498C2.02464 17.0443 3.77308 16.8211 5.24994 15.4253C5.24994 15.4253 4.01328 15.2083 3.08682 14.3327C2.16036 13.4571 1.85231 11.9998 1.85231 11.9998Z"
        fill="#BDC2FF"
      />
    </Svg>
  )
}

export default InvestDark

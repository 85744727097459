import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const ParticipatedRoninLight: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M7.35737 5.2863C5.10349 6.27238 0.680245 9.59685 1.01833 15.0062C1.01832 15.9922 1.44092 18.2179 3.13133 19.2322C4.82174 20.2464 8.34343 20.5 9.89297 20.5C10.1284 20.5 10.3156 20.3062 10.3156 20.0707V18.387C10.3156 18.1536 10.1264 17.9644 9.89297 17.9644C9.65957 17.9644 9.47037 17.7752 9.47037 17.5418V14.161C9.47037 13.9276 9.65957 13.7384 9.89297 13.7384C10.1264 13.7384 10.3156 13.5492 10.3156 13.3158V11.3575C10.3156 10.8053 10.7633 10.3575 11.3156 10.3575H15.2669C16.0055 10.3575 16.4803 9.58607 16.0605 8.9784C15.1065 7.59745 13.6851 6.25633 11.5834 5.2863C10.7382 5.42717 8.70971 5.62439 7.35737 5.2863Z"
        fill="#8A8A8A"
      />
      <path
        d="M12.006 12.2027C12.006 11.6505 12.4537 11.2027 13.006 11.2027H18.6128C19.1651 11.2027 19.6128 11.6505 19.6128 12.2027V12.7384C19.6128 13.2906 19.1651 13.7384 18.6128 13.7384H13.006C12.4537 13.7384 12.006 13.2906 12.006 12.7384V12.2027Z"
        fill="#8A8A8A"
      />
      <path
        d="M11.1608 15.5836C11.1608 15.0313 11.6085 14.5836 12.1608 14.5836H17.7676C18.3199 14.5836 18.7676 15.0313 18.7676 15.5836V16.1192C18.7676 16.6715 18.3199 17.1192 17.7676 17.1192H12.1608C11.6085 17.1192 11.1608 16.6715 11.1608 16.1192V15.5836Z"
        fill="#8A8A8A"
      />
      <path
        d="M12.006 18.9644C12.006 18.4121 12.4537 17.9644 13.006 17.9644H18.6128C19.1651 17.9644 19.6128 18.4121 19.6128 18.9644V19.5C19.6128 20.0523 19.1651 20.5 18.6128 20.5H13.006C12.4537 20.5 12.006 20.0523 12.006 19.5V18.9644Z"
        fill="#8A8A8A"
      />
      <path
        d="M6.08956 1.48268C6.08956 2.15884 6.93476 3.17309 7.35737 3.59569C9.04778 4.27189 10.8791 3.87741 11.5834 3.59565C12.2877 2.75044 13.2738 1.90524 12.8512 1.06003C12.5131 0.383867 11.1608 0.778273 10.7382 1.06C9.72394 0.0457504 8.62517 0.637447 8.20257 1.06008C6.51216 0.0458299 6.08956 0.919209 6.08956 1.48268Z"
        fill="#8A8A8A"
      />
    </Svg>
  )
}

export default ParticipatedRoninLight

import React, { useCallback, useContext, useEffect } from 'react'
import get from 'lodash/get'
import { Context } from './DrawerContext'

interface UseDrawerV2Options {
  closeOnOverlayClick?: boolean
  updateOnPropsChange?: boolean
  drawerId?: string
  drawerType?: string
}

interface UserDrawerV2Param {
  drawer?: React.ReactNode
  options?: UseDrawerV2Options
}

const defaultUseDrawerV2Options: UseDrawerV2Options = {
  closeOnOverlayClick: true,
  updateOnPropsChange: false,
  drawerId: 'defaultNodeId',
  drawerType: '',
}

const useDrawer = ({
  drawer,
  options = defaultUseDrawerV2Options,
}: UserDrawerV2Param): [(drawerProps: any) => void, () => void, boolean, string] => {
  const { isOpen, nodeId, drawerNode, setDrawerNode, onPresent, onDismiss } = useContext(Context)
  const finalOptions = { ...defaultUseDrawerV2Options, ...options }
  const { drawerId, updateOnPropsChange, drawerType } = finalOptions
  const onPresentCallback = useCallback(
    (drawerProps) => {
      onPresent(drawer, drawerId, drawerProps, drawerType)
    },
    [drawer, drawerId, onPresent, drawerType],
  )

  // Note: This logic follows useModal
  useEffect(() => {
    // NodeId is needed in case there are 2 useDrawer hooks on the same page and one has updateOnPropsChange
    if (updateOnPropsChange && isOpen && nodeId === drawerId) {
      const drawerProps = get(drawer, 'props')
      const oldDrawerProps = get(drawerNode, 'props')
      if (drawerProps && oldDrawerProps && JSON.stringify(drawerProps) !== JSON.stringify(oldDrawerProps)) {
        setDrawerNode(drawer)
      }
    }
  }, [updateOnPropsChange, nodeId, drawerId, isOpen, drawer, drawerNode, setDrawerNode])

  return [onPresentCallback, onDismiss, isOpen, drawerType]
}

export const useIsDrawerOpen = (): [boolean, string] => {
  const { isOpen, drawerType } = useContext(Context)
  return [isOpen, drawerType]
}

export default useDrawer

import React from 'react'
import { Flex, IconButton, useModal, SettingsIcon, Link } from 'uikit'
import SettingsModal from './SettingsModal'
import { useNetwork } from 'wagmi'
import { GeckoTerminalIcon } from 'views/Project/components/Icons'

const GlobalSettings = () => {
  const [onPresentSettingsModal] = useModal(<SettingsModal />)
  const { chain } = useNetwork()
  const isHumaNodeChainId = chain?.id === 5234
  return (
    <Flex style={{ gap: '5px' }}>
      {isHumaNodeChainId && (
        <Link
          external
          href="https://www.geckoterminal.com/humanode/impossible-finance-humanode/pools"
          noUnderline
          target="_blank"
          rel="noopener noreferrer"
          title="geckoterminal pools"
          aria-label="geckoterminal stats"
          style={{ display: 'inline-block', fontSize: '12px' }}
        >
          <GeckoTerminalIcon />
        </Link>
      )}
      <IconButton
        onClick={onPresentSettingsModal}
        variant="text"
        scale="sm"
        mr="8px"
        id="open-settings-dialog-button"
        title="settings"
      >
        <SettingsIcon height={24} width={24} color="textSubtle" />
      </IconButton>
    </Flex>
  )
}

export default GlobalSettings

import { createSlice } from '@reduxjs/toolkit'
import { BannerState } from 'state/types'
import { getBanners } from 'utils/idosHelpers'

const initialState: BannerState = {
  banners: [],
}

export const bannersSlice = createSlice({
  name: 'banners',
  initialState,
  reducers: {
    initBanners: (state: BannerState, action) => {
      state.banners = action.payload
    },
  },
})

export const { initBanners } = bannersSlice.actions

export const fetchBanners = () => async (dispatch) => {
  const data = await getBanners()
  dispatch(initBanners(data.sort((a, b) => new Date(b.startTime).valueOf() - new Date(a.startTime).valueOf())))
}

export default bannersSlice.reducer

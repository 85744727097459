import React, { useContext, useMemo } from 'react'
import { StartLight, StartDark } from 'componentsV2/Icons/Staking/Start'
import useTheme from 'hooks/useTheme'
import { useTranslation } from 'contexts/Localization'
import { OpenNewIcon } from 'uikit'
import { SubscribeableIDO } from 'state/v2_types'
import { StepRequirementEnum } from 'state/types'

import SaleDetailCard from '../SaleDetailCard'
import { CompanyContext } from 'contexts/CompanyContext'

interface Props {
  sale: SubscribeableIDO
}

const StakingStartCard: React.FC<Props> = ({ sale }) => {
  const { t } = useTranslation()
  const { theme } = useTheme()
  const { faqLink } = useContext(CompanyContext)

  const stakingSale = sale

  const { subscribePeriod, stepRequirement, isGiftBox } = stakingSale

  const countdownInfo = useMemo(() => {
    const claimTimestamp = new Date(subscribePeriod.startTime).getTime()
    const currentTimestamp = new Date().getTime()
    const seconds = Math.trunc((claimTimestamp - currentTimestamp) / 1000)
    return {
      seconds,
      description: t('Staking starts in'),
    }
  }, [t, subscribePeriod.startTime])

  const icon = <OpenNewIcon />
  const { stakingToken } = sale
  const { isLP, symbol } = stakingToken
  return (
    <SaleDetailCard
      requireKYC={!!stepRequirement?.includes(StepRequirementEnum.KYC)}
      targetNetworkID={sale.chainId}
      countdownInfo={countdownInfo}
      imageInfo={{
        image: theme.isDark ? <StartDark /> : <StartLight />,
      }}
      subtitleInfo={{
        subtitle: isGiftBox
          ? t('Stake %tokenName% to potentially receive purchase allocation.', {
              tokenName: isLP ? 'LP tokens' : symbol || '',
            })
          : t(
              "Stake %tokenName% to receive token rewards. Rewards will be distributed based on your stakeweight (amount and time staked) relative to all participants' stakeweight.",
              { tokenName: isLP ? 'LP tokens' : symbol || '' },
            ),
      }}
      linkBtnInfo={{
        text: t('How to participate?'),
        icon,
        href: faqLink,
      }}
    />
  )
}

export default StakingStartCard

import { useAccount } from 'wagmi'
import useRefresh from 'hooks/useRefresh'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchFarmUserDataAsync } from 'state/farms'
import { useFarmDataFetched } from 'state/farms/hooks'
import { State } from 'state/types'
import { Project } from 'state/v2_types'

import { fetchIdoUserDataAsync, fetchInitProjects, fetchInitProjectsWithAllocations } from '.'
import filterLive, { filterParticipatedOrWhitelistedProjects } from './utils'

export const useIdosInitiated = (): boolean => {
  return useSelector((state: State) => state.idos.initialised)
}

export const useProjectsInitiated = (): boolean => {
  return useSelector((state: State) => state.idos.projectsInitialized)
}

export const useProjectsSyncedOnchain = (): boolean => {
  return useSelector((state: State) => state.idos.projectsSynced)
}

export const useIdosSyncedOnchain = (): boolean => {
  return useSelector((state: State) => state.idos.syncedOnchain)
}

export const useIsSaleUserDataLoading = (): boolean => {
  return useSelector((state: State) => state.idos.isSaleUserDataLoading)
}

export const useIsSaleUserDataFetched = (): boolean => {
  return useSelector((state: State) => state.idos.isSaleUserDataFetched)
}

export const useSaleUserDataMap = () => {
  return useSelector((state: State) => state.idos.saleUserDataMap)
}

export const useSaleUserData = (saleID: string) => {
  const saleUserDataMap = useSaleUserDataMap()
  return saleUserDataMap[saleID]
}

export const useFetchInitIdosPublicData = () => {
  const dispatch = useDispatch()
  const { address: account } = useAccount()

  useEffect(() => {
    dispatch(fetchInitProjects(account))
  }, [dispatch, account])
}

export const useFetchInitIdosPublicWithAllocationsData = () => {
  const dispatch = useDispatch()
  const { address: account } = useAccount()

  useEffect(() => {
    dispatch(fetchInitProjectsWithAllocations(account))
  }, [dispatch, account])
}

export const useFetchIdoUserData = () => {
  const dispatch = useDispatch()
  const { slowRefresh } = useRefresh()
  const { address: account } = useAccount()
  const projectsInitialized = useProjectsInitiated()

  useEffect(() => {
    if (account && projectsInitialized) {
      dispatch(fetchIdoUserDataAsync(account))
    }
  }, [account, dispatch, projectsInitialized, slowRefresh])
}

export const useFetchFarmuserData = () => {
  const dispatch = useDispatch()
  const { slowRefresh } = useRefresh()
  const { address: account } = useAccount()
  const farmDataFetched = useFarmDataFetched()

  useEffect(() => {
    if (account && farmDataFetched) {
      dispatch(fetchFarmUserDataAsync(account))
    }
  }, [account, dispatch, farmDataFetched, slowRefresh])
}

export const useAllProjects = (): Project[] => {
  return useSelector((state: State) => state.idos.projects)
}

export const useAllProjectsWithAllocation = (): Project[] => {
  return useSelector((state: State) => state.idos.projectsWithAllocations)
}

// Live Projects
export const useLiveProjects = (): Project[] => {
  const projects = useSelector((state: State) => state.idos.projects)
  const liveProjects = projects.filter((p) => p.isLive && !p.isPrivate)
  return liveProjects
}

// Completed Projects
export const useCompletedProjects = (): Project[] => {
  const projects = useSelector((state: State) => state.idos.projects)
  const completedProjects = projects.filter((p) => !p.isLive && p.sales.length > 0 && !p.isPrivate)

  return completedProjects
}

export const useProjectFromId = (id): Project => {
  const project = useSelector((state: State) => {
    return state.idos.projects.find((f) => {
      return `${f.id}` === id || f.oldId?.trim().toLowerCase() === decodeURI(id)?.trim().toLowerCase()
    })
  })
  return project
}

export const useParticipatedOrWhitelistedProjects = (onlyLiveProjects = true): Project[] => {
  let projects = useSelector((state: State) => state.idos.projects)
  if (onlyLiveProjects) {
    projects = filterLive(projects)
  }
  const saleUserDataMap = useSelector((state: State) => state.idos.saleUserDataMap)
  const participatedProjects = filterParticipatedOrWhitelistedProjects(projects, saleUserDataMap)
  return participatedProjects
}

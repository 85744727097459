import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import Clipboard from 'react-clipboard.js'
import styled from 'styled-components'

const Clip = styled(Clipboard)<{ children: React.ReactNode }>`
  margin: 0;
  padding: 0;
  border: 0;
  width: 25px;
  height: 20px;
  background: transparent;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`
const WalletCopy = ({ walletAddress }) => {
  const [open, setOpen] = React.useState(false)
  const clickIcon = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Clip data-clipboard-text={walletAddress} onClick={clickIcon}>
        <WalletIcon />
      </Clip>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={handleClose}
        open={open}
        autoHideDuration={3000}
        message="Copied"
      />
    </>
  )
}

const WalletIconSVG = styled.svg`
  fill: ${({ theme }) => theme.colorsV2?.text};
`
const WalletIcon = () => (
  <WalletIconSVG width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.58398 4.63965V13.473H15.4173V4.63965H6.58398ZM6.16732 3.38965C5.70708 3.38965 5.33398 3.76274 5.33398 4.22298V13.8896C5.33398 14.3499 5.70708 14.723 6.16732 14.723H15.834C16.2942 14.723 16.6673 14.3499 16.6673 13.8896V4.22298C16.6673 3.76274 16.2942 3.38965 15.834 3.38965H6.16732Z"
      fillOpacity="0.7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.33398 5.59668C3.67916 5.59668 3.95898 5.8765 3.95898 6.22168V15.9856H13.7229C14.0681 15.9856 14.3479 16.2654 14.3479 16.6106C14.3479 16.9557 14.0681 17.2356 13.7229 17.2356H3.33398C2.98881 17.2356 2.70898 16.9557 2.70898 16.6106V6.22168C2.70898 5.8765 2.98881 5.59668 3.33398 5.59668Z"
      fillOpacity="0.7"
    />
  </WalletIconSVG>
)

export default WalletCopy

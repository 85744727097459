import styled from 'styled-components'
import { OverlayProps } from './types'

const isBeam = process.env.REACT_APP_WHITELABEL_BRAND === 'beam'

const Overlay = styled.div.attrs({ role: 'presentation', isBeam })<OverlayProps>`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: ${({ theme, transparentBackground }) =>
    transparentBackground
      ? 'transparent'
      : isBeam
      ? '#00000073'
      : theme.brand !== 'if'
      ? '#242f3f73'
      : theme.isDark
      ? '#15152E33'
      : '#6D8DAA4D'};
  transition: opacity 0.4s;
  z-index: ${({ zIndex }) => zIndex};
  pointer-events: ${({ show }) => (show ? 'initial' : 'none')};
  backdrop-filter: ${({ transparentBackground, theme }) =>
    transparentBackground ? 'blur(0px)' : theme.brand !== 'if' ? 'blur(5px)' : 'blur(10px)'};
  display: ${({ show }) => (show ? 'block' : 'none')};
`

Overlay.defaultProps = {
  show: false,
  transparentBackground: false,
  zIndex: 10,
}

export default Overlay

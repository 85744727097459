export const STAGING_IDO_STORAGE_KEY = 'IS_STAGING_IDO'
const APIS_V1 = ['https://config-cms.impossible.finance', 'https://stg-config-cms.impossible.finance']
const APIS_V2 = ['https://backend.impossible.finance', 'https://backend-staging.impossible.finance']

export const getCMSApiUrl = () => {
  const cachedIsStagingIDO = localStorage.getItem(STAGING_IDO_STORAGE_KEY)
  const cachedResult = Number(cachedIsStagingIDO ? JSON.parse(cachedIsStagingIDO) : false)
  return APIS_V2[cachedResult]
}

export const getCMSV1ApiUrl = () => {
  const cachedIsStagingIDO = localStorage.getItem(STAGING_IDO_STORAGE_KEY)
  const cachedResult = Number(cachedIsStagingIDO ? JSON.parse(cachedIsStagingIDO) : false)
  return APIS_V1[cachedResult]
}

export default getCMSApiUrl

import React, { useState } from 'react'
import { Toggle, Flex, InjectedModalProps, Text, Box, useMatchBreakpoints } from 'uikit'
import { useExpertModeManager, useUserExpertModeAcknowledgementShow, useUserSingleHopOnly } from 'state/user/hooks'
import { useTranslation } from 'contexts/Localization'
import { useSwapActionHandlers } from 'state/swap/hooks'
import ModalV2 from 'uikit/widgets/Modal/ModalV2'
import DefaultIFTooltip from 'componentsV2/DefaultIFTooltip/DefaultIFTooltip'
import styled from 'styled-components'
import TransactionSettings from './TransactionSettings'
import ExpertModal from './ExpertModal'

const StyledLabel = styled(Text)`
  color: ${({ theme }) => (theme.isDark ? theme.colorsV2?.textSecondary : theme.colorsV2?.text)};
`

const SettingsModal: React.FC<InjectedModalProps> = ({ onDismiss }) => {
  const [showConfirmExpertModal, setShowConfirmExpertModal] = useState(false)
  const [showExpertModeAcknowledgement] = useUserExpertModeAcknowledgementShow()
  const [expertMode, toggleExpertMode] = useExpertModeManager()
  const [singleHopOnly, setSingleHopOnly] = useUserSingleHopOnly()
  const { onChangeRecipient } = useSwapActionHandlers()
  const { isXl } = useMatchBreakpoints()
  const isMobile = !isXl

  const { t } = useTranslation()

  if (showConfirmExpertModal) {
    return <ExpertModal setShowConfirmExpertModal={setShowConfirmExpertModal} onDismiss={onDismiss} />
  }

  const handleExpertModeToggle = () => {
    if (expertMode) {
      onChangeRecipient(null)
      toggleExpertMode()
    } else if (!showExpertModeAcknowledgement) {
      onChangeRecipient(null)
      toggleExpertMode()
    } else {
      setShowConfirmExpertModal(true)
    }
  }

  return (
    <ModalV2 bodyPadding="0px" title={t('Transaction Settings')} onDismiss={onDismiss}>
      <Box px={!isMobile && '15px'}>
        <Flex pt="15px" flexDirection="column">
          <TransactionSettings />
        </Flex>
        <Flex pt="19px" pb="19px" flexDirection="column">
          <Text fontWeight="bold">{t('Advanced Options')}</Text>
        </Flex>
        <Flex justifyContent="space-between" alignItems="center" mb="9px">
          <Flex alignItems="center">
            <StyledLabel>{t('Pro Mode')}</StyledLabel>
            <DefaultIFTooltip
              placement="top"
              title={t('Allow high price impact trades and skip the confirmation modals. Use at your own risk.')}
            />
          </Flex>
          <Toggle id="toggle-expert-mode-button" scale="md" checked={expertMode} onChange={handleExpertModeToggle} />
        </Flex>
        <Flex justifyContent="space-between" alignItems="center" mb="9px">
          <Flex alignItems="center">
            <StyledLabel>{t('Disable Multihops')}</StyledLabel>
            <DefaultIFTooltip placement="top" title={t('Restricts swaps to direct pairs only.')} />
          </Flex>
          <Toggle
            id="toggle-disable-multihop-button"
            checked={singleHopOnly}
            scale="md"
            onChange={() => {
              setSingleHopOnly(!singleHopOnly)
            }}
          />
        </Flex>
      </Box>
    </ModalV2>
  )
}

export default SettingsModal

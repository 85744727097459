import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const ParticipatedRoninLightActive: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
      <path
        d="M10.1074 6.7863C7.85349 7.77238 3.43025 11.0969 3.76833 16.5062C3.76832 17.4922 4.19092 19.7179 5.88133 20.7322C7.57174 21.7464 11.0934 22 12.643 22C12.8784 22 13.0656 21.8062 13.0656 21.5707V19.887C13.0656 19.6536 12.8764 19.4644 12.643 19.4644C12.4096 19.4644 12.2204 19.2752 12.2204 19.0418V15.661C12.2204 15.4276 12.4096 15.2384 12.643 15.2384C12.8764 15.2384 13.0656 15.0492 13.0656 14.8158V12.8575C13.0656 12.3053 13.5133 11.8575 14.0656 11.8575H18.0169C18.7555 11.8575 19.2303 11.0861 18.8105 10.4784C17.8565 9.09745 16.4351 7.75633 14.3334 6.7863C13.4882 6.92717 11.4597 7.12439 10.1074 6.7863Z"
        fill="#8692FF"
      />
      <path
        d="M14.756 13.7027C14.756 13.1505 15.2037 12.7027 15.756 12.7027H21.3628C21.9151 12.7027 22.3628 13.1505 22.3628 13.7027V14.2384C22.3628 14.7906 21.9151 15.2384 21.3628 15.2384H15.756C15.2037 15.2384 14.756 14.7906 14.756 14.2384V13.7027Z"
        fill="#8692FF"
      />
      <path
        d="M13.9108 17.0836C13.9108 16.5313 14.3585 16.0836 14.9108 16.0836H20.5176C21.0699 16.0836 21.5176 16.5313 21.5176 17.0836V17.6192C21.5176 18.1715 21.0699 18.6192 20.5176 18.6192H14.9108C14.3585 18.6192 13.9108 18.1715 13.9108 17.6192V17.0836Z"
        fill="#8692FF"
      />
      <path
        d="M14.756 20.4644C14.756 19.9121 15.2037 19.4644 15.756 19.4644H21.3628C21.9151 19.4644 22.3628 19.9121 22.3628 20.4644V21C22.3628 21.5523 21.9151 22 21.3628 22H15.756C15.2037 22 14.756 21.5523 14.756 21V20.4644Z"
        fill="#8692FF"
      />
      <path
        d="M8.83956 2.98268C8.83956 3.65884 9.68476 4.67309 10.1074 5.09569C11.7978 5.77189 13.6291 5.37741 14.3334 5.09565C15.0377 4.25044 16.0238 3.40524 15.6012 2.56003C15.2631 1.88387 13.9108 2.27827 13.4882 2.56C12.4739 1.54575 11.3752 2.13745 10.9526 2.56008C9.26216 1.54583 8.83956 2.41921 8.83956 2.98268Z"
        fill="#8692FF"
      />
    </Svg>
  )
}

export default ParticipatedRoninLightActive

import React from 'react'
import { Flex, Text } from 'uikit'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import { Chain } from 'config/constants/types'
import { AutoColumn } from 'componentsV2/layout/Column'
import { AutoRow } from 'componentsV2/layout/Row'
import { Currency, ETHER } from 'swap-sdk/entities/currency'
import gasTokens from 'config/constants/gasTokens'
import { currencyEquals, TokenClass } from 'swap-sdk/entities/token'
import useTheme from 'hooks/useTheme'

import { SUGGESTED_BASES } from '../../config/constants'
import { CurrencyLogo } from '../Logo'

const BaseWrapper = styled.div<{ disable?: boolean }>`
  border-radius: 10px;
  display: flex;
  padding: 6px;
  margin-right: 30px;
  align-items: center;
  :hover {
    cursor: ${({ disable }) => !disable && 'pointer'};
    background-color: ${({ theme, disable }) => !disable && theme.colorsV2?.dark2};
  }

  background-color: ${({ disable }) => disable && 'transparent'};
  opacity: ${({ disable }) => disable && '0.4'};
`

export default function CommonBases({
  chainId,
  onSelect,
  selectedCurrency,
  otherCurrency,
}: {
  chainId?: Chain
  selectedCurrency?: Currency | null
  otherCurrency?: Currency | null
  onSelect: (currency: Currency) => void
}) {
  const { t } = useTranslation()
  const { theme } = useTheme()
  const NATIVE = ETHER[chainId]

  return (
    <AutoColumn gap="md">
      <AutoRow>
        <Flex ml="15px">
          <Text fontSize="12px" color={theme.isDark ? 'textThirdly' : 'text'}>
            {t('Common bases')}
          </Text>
        </Flex>
      </AutoRow>
      <AutoRow ml="9px">
        <BaseWrapper
          onClick={() => {
            if (
              (!selectedCurrency || !currencyEquals(selectedCurrency, NATIVE)) &&
              (!otherCurrency || !currencyEquals(otherCurrency, NATIVE))
            ) {
              onSelect(NATIVE)
            }
          }}
          disable={selectedCurrency?.isNative || otherCurrency?.isNative}
        >
          <CurrencyLogo size="18px" currency={NATIVE} style={{ marginRight: 8 }} />
          <Text fontSize="14px" fontWeight="700">
            {gasTokens[chainId]}
          </Text>
        </BaseWrapper>
        {(chainId ? SUGGESTED_BASES[chainId] : []).map((token: TokenClass) => {
          const selected = selectedCurrency instanceof TokenClass && selectedCurrency.address === token.address
          const otherSelected = otherCurrency instanceof TokenClass && otherCurrency.address === token.address
          return (
            <BaseWrapper
              onClick={() => !selected && onSelect(token)}
              disable={selected || otherSelected}
              key={token.address}
            >
              <CurrencyLogo size="18px" currency={token} style={{ marginRight: 8 }} />
              <Text fontSize="14px" fontWeight="700">
                {token.symbol}
              </Text>
            </BaseWrapper>
          )
        })}
      </AutoRow>
    </AutoColumn>
  )
}

import React from 'react'
import { Web3ReactProvider } from '@web3-react/core'
import { Provider } from 'react-redux'
import { getLibrary } from 'utils/web3React'
import { LanguageProvider } from 'contexts/Localization'
import { ThemeContextProvider } from 'contexts/ThemeContext'
import { CompanyContextProvider } from 'contexts/CompanyContext'
import { RefreshContextProvider } from 'contexts/RefreshContext'
import store from 'state'
import { AccountSidebarProvider } from 'uikit/widgets/AccountSidebar/AccountSidebarContext'
import { HistoryProvider } from 'contexts/SwapNotSupportedContext'

interface ProvidersProps {
  children: React.ReactNode
}

const Providers: React.FC<ProvidersProps> = ({ children }) => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Provider store={store}>
        <CompanyContextProvider>
          <ThemeContextProvider>
            <LanguageProvider>
              <RefreshContextProvider>
                <HistoryProvider>
                  <AccountSidebarProvider>{children}</AccountSidebarProvider>
                </HistoryProvider>
              </RefreshContextProvider>
            </LanguageProvider>
          </ThemeContextProvider>
        </CompanyContextProvider>
      </Provider>
    </Web3ReactProvider>
  )
}

export default Providers

import { Box, Typography } from '@mui/material'
import useTheme from 'hooks/useTheme'
import * as React from 'react'
import styled from 'styled-components'
import { Flex, WarningIcon } from 'uikit'
import { ColorsV2 } from 'uikit/theme/colorsV2'

type VariantType = 'info'
interface StyledContainerProps {
  variant?: VariantType
  padding?: string
  width?: string
}

const getBgColor = (variant: VariantType, colors: ColorsV2) => {
  switch (variant) {
    case 'info':
      return colors.infoCard.infoBg
    default:
      return colors.infoCard.infoBg
  }
}

const getTextColor = (variant: VariantType, colors: ColorsV2) => {
  switch (variant) {
    case 'info':
      return colors.infoCard.infoText
    default:
      return colors.infoCard.infoText
  }
}

const StyledContainer = styled(Box)<StyledContainerProps>`
  border-radius: 5px;
  background: ${({ variant, theme }) => getBgColor(variant, theme.colorsV2)};
`

const StyledText = styled(Typography)<{ $cardVariant: VariantType }>`
  color: ${({ $cardVariant, theme }) => getTextColor($cardVariant, theme.colorsV2)};
`

interface InfoCardProps extends StyledContainerProps {
  children: React.ReactNode
}

const InfoCard: React.FC<InfoCardProps> = ({ variant = 'info', children, padding = '10px', width = 'auto' }) => {
  const { theme } = useTheme()

  return (
    <StyledContainer variant={variant} padding={padding} width={width}>
      <Flex flexDirection="row">
        <Flex alignItems="flex-start">
          <WarningIcon color={getTextColor(variant, theme.colorsV2)} />
        </Flex>
        <Flex marginLeft="8px">
          <StyledText variant="caption" $cardVariant={variant}>
            {children}
          </StyledText>
        </Flex>
      </Flex>
    </StyledContainer>
  )
}

export default InfoCard

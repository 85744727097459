import React from 'react'

interface Props {
  size?: number
}

export const StartLight: React.FC<Props> = ({ size = 100 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <svg width="100" height="98" viewBox="0 0 100 98" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M67.5781 18.5078C67.5781 28.2159 59.7081 36.0859 50 36.0859C40.2919 36.0859 32.4219 28.2159 32.4219 18.5078C32.4219 8.79968 40.2919 0.929688 50 0.929688C59.7081 0.929688 67.5781 8.79968 67.5781 18.5078ZM51.3543 23.9973C51.9262 24.5695 52.676 24.8555 53.4258 24.8555C54.1754 24.8555 54.9254 24.5693 55.4973 23.9973C56.6414 22.8531 56.6414 20.9982 55.4973 19.8541L52.9375 17.2943V13.5273C52.9375 11.9094 51.626 10.5977 50.0078 10.5977C48.3898 10.5977 47.0781 11.9094 47.0781 13.5273V18.5078C47.0781 19.2848 47.3869 20.0299 47.9363 20.5793L51.3543 23.9973Z"
          fill="url(#paint0_linear_7153_131403)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M94.1406 95.0705C94.1406 96.6885 95.4523 98.0002 97.0703 98.0002C98.6883 98.0002 100 96.6885 100 95.0705V88.0391C100 83.1928 96.0572 79.25 91.2109 79.25H50H8.78906C3.94277 79.25 0 83.1928 0 88.0391V95.0705C0 96.6885 1.31172 98.0002 2.92969 98.0002C4.54766 98.0002 5.85938 96.6885 5.85938 95.0705V88.0391C5.85938 86.4236 7.17363 85.1094 8.78906 85.1094H50H91.2109C92.8264 85.1094 94.1406 86.4236 94.1406 88.0391V95.0705Z"
          fill="#C7D9F3"
        />
        <path
          d="M43.7494 83.1564H12.9062V56.9289C12.9062 51.8896 16.9914 47.8047 22.0305 47.8047H34.6252C39.6643 47.8047 43.7494 51.8896 43.7494 56.9289V83.1564Z"
          fill="url(#paint1_linear_7153_131403)"
        />
        <path
          d="M44.14 89.0156H12.5156C10.8977 89.0156 9.58594 87.7039 9.58594 86.0859V83.3516C9.58594 78.5053 13.5287 74.5625 18.375 74.5625H38.2807C43.1269 74.5625 47.0697 78.5053 47.0697 83.3516V86.0859C47.0697 87.7039 45.758 89.0156 44.14 89.0156Z"
          fill="url(#paint2_linear_7153_131403)"
        />
        <path
          d="M87.0932 83.1564H56.25V56.9289C56.25 51.8896 60.3352 47.8047 65.3742 47.8047H77.9689C83.008 47.8047 87.0932 51.8896 87.0932 56.9289V83.1564Z"
          fill="url(#paint3_linear_7153_131403)"
        />
        <path
          d="M87.4838 89.0156H55.8594C54.2414 89.0156 52.9297 87.7039 52.9297 86.0859V83.3516C52.9297 78.5053 56.8725 74.5625 61.7188 74.5625H81.6244C86.4707 74.5625 90.4135 78.5053 90.4135 83.3516V86.0859C90.4135 87.7039 89.1018 89.0156 87.4838 89.0156Z"
          fill="url(#paint4_linear_7153_131403)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_7153_131403"
            x1="50"
            y1="-23"
            x2="50"
            y2="52.5796"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#C2D2F1" stopOpacity="0.12" />
            <stop offset="1" stopColor="#6589CF" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_7153_131403"
            x1="28.3278"
            y1="47.8047"
            x2="28.3278"
            y2="83.1564"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5ED4F3" />
            <stop offset="1" stopColor="#18A0C4" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_7153_131403"
            x1="28.3278"
            y1="74.5625"
            x2="28.3278"
            y2="89.0156"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5ED4F3" />
            <stop offset="1" stopColor="#2AA5C6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_7153_131403"
            x1="71.6716"
            y1="47.8047"
            x2="71.6716"
            y2="83.1564"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5ED4F3" />
            <stop offset="1" stopColor="#18A0C4" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_7153_131403"
            x1="71.6716"
            y1="74.5625"
            x2="71.6716"
            y2="89.0156"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5ED4F3" />
            <stop offset="1" stopColor="#2AA5C6" />
          </linearGradient>
        </defs>
      </svg>
    </svg>
  )
}

export const StartDark: React.FC<Props> = ({ size = 100 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.5781 18.5078C67.5781 28.2159 59.7081 36.0859 50 36.0859C40.2919 36.0859 32.4219 28.2159 32.4219 18.5078C32.4219 8.79968 40.2919 0.929688 50 0.929688C59.7081 0.929688 67.5781 8.79968 67.5781 18.5078ZM51.3543 23.9973C51.9262 24.5695 52.676 24.8555 53.4258 24.8555C54.1754 24.8555 54.9254 24.5693 55.4973 23.9973C56.6414 22.8531 56.6414 20.9982 55.4973 19.8541L52.9375 17.2943V13.5273C52.9375 11.9094 51.626 10.5977 50.0078 10.5977C48.3898 10.5977 47.0781 11.9094 47.0781 13.5273V18.5078C47.0781 19.2848 47.3869 20.0299 47.9363 20.5793L51.3543 23.9973Z"
        fill="url(#paint0_linear_7153_131386)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M94.1406 95.0705C94.1406 96.6885 95.4523 98.0002 97.0703 98.0002C98.6883 98.0002 100 96.6885 100 95.0705V88.0391C100 83.1928 96.0572 79.25 91.2109 79.25H50H8.78906C3.94277 79.25 0 83.1928 0 88.0391V95.0705C0 96.6885 1.31172 98.0002 2.92969 98.0002C4.54766 98.0002 5.85938 96.6885 5.85938 95.0705V88.0391C5.85938 86.4236 7.17363 85.1094 8.78906 85.1094H50H91.2109C92.8264 85.1094 94.1406 86.4236 94.1406 88.0391V95.0705Z"
        fill="#7D7D7D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M94.1406 95.0705C94.1406 96.6885 95.4523 98.0002 97.0703 98.0002C98.6883 98.0002 100 96.6885 100 95.0705V88.0391C100 83.1928 96.0572 79.25 91.2109 79.25H50H8.78906C3.94277 79.25 0 83.1928 0 88.0391V95.0705C0 96.6885 1.31172 98.0002 2.92969 98.0002C4.54766 98.0002 5.85938 96.6885 5.85938 95.0705V88.0391C5.85938 86.4236 7.17363 85.1094 8.78906 85.1094H50H91.2109C92.8264 85.1094 94.1406 86.4236 94.1406 88.0391V95.0705Z"
        fill="#6361C9"
      />
      <path
        d="M43.7494 83.1564H12.9062V56.9289C12.9062 51.8896 16.9914 47.8047 22.0305 47.8047H34.6252C39.6643 47.8047 43.7494 51.8896 43.7494 56.9289V83.1564Z"
        fill="url(#paint1_linear_7153_131386)"
      />
      <path
        d="M44.14 89.0156H12.5156C10.8977 89.0156 9.58594 87.7039 9.58594 86.0859V83.3516C9.58594 78.5053 13.5287 74.5625 18.375 74.5625H38.2807C43.1269 74.5625 47.0697 78.5053 47.0697 83.3516V86.0859C47.0697 87.7039 45.758 89.0156 44.14 89.0156Z"
        fill="url(#paint2_linear_7153_131386)"
      />
      <path
        d="M87.0932 83.1564H56.25V56.9289C56.25 51.8896 60.3352 47.8047 65.3742 47.8047H77.9689C83.008 47.8047 87.0932 51.8896 87.0932 56.9289V83.1564Z"
        fill="url(#paint3_linear_7153_131386)"
      />
      <path
        d="M87.4838 89.0156H55.8594C54.2414 89.0156 52.9297 87.7039 52.9297 86.0859V83.3516C52.9297 78.5053 56.8725 74.5625 61.7188 74.5625H81.6244C86.4707 74.5625 90.4135 78.5053 90.4135 83.3516V86.0859C90.4135 87.7039 89.1018 89.0156 87.4838 89.0156Z"
        fill="url(#paint4_linear_7153_131386)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7153_131386"
          x1="50"
          y1="-19"
          x2="50"
          y2="52.5796"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B6DCFF" stopOpacity="0.12" />
          <stop offset="1" stopColor="#66B6FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_7153_131386"
          x1="28.3278"
          y1="47.8047"
          x2="28.3278"
          y2="83.1564"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5ED4F3" />
          <stop offset="1" stopColor="#18A0C4" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_7153_131386"
          x1="28.3278"
          y1="74.5625"
          x2="28.3278"
          y2="89.0156"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5ED4F3" />
          <stop offset="1" stopColor="#2AA5C6" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_7153_131386"
          x1="71.6716"
          y1="47.8047"
          x2="71.6716"
          y2="83.1564"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5ED4F3" />
          <stop offset="1" stopColor="#18A0C4" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_7153_131386"
          x1="71.6716"
          y1="74.5625"
          x2="71.6716"
          y2="89.0156"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5ED4F3" />
          <stop offset="1" stopColor="#2AA5C6" />
        </linearGradient>
      </defs>
    </svg>
  )
}

// Wallet name key based on
// https://github.com/rainbow-me/rainbowkit/blob/main/packages/rainbowkit/src/wallets/walletConnectors/metaMaskWallet/metaMaskWallet.ts#L96
export const RAINBOW_KIT_FATHOM_ID = {
  MetaMask: 'T26PNED8',
  BitKeep: 'LUPAQQNN',
  'Coin98 Wallet': '7ZC1U43J',
  'Coinbase Wallet': 'WBY8T3VI',
  WalletConnect: 'MYLTQBGK',
  Rainbow: 'ITPRWEKX',
  'Ledger Live': 'YINQ386M',
  'OKX Wallet': 'TBEZJXNU',
  'Trust Wallet': 'YO2WAVOQ',
  'Uniswap Wallet': 'OS7FC5IJ',
  TokenPocket: 'FXUOLHTV',
}

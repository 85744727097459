import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import { Box } from '@mui/material'
import { useMatchBreakpoints } from 'uikit/hooks'

import { Link } from '../../components/Link'
import { OpenNewIcon } from '../../components/Svg'
import ModalV2 from '../Modal/ModalV2'
import WalletCard from './WalletCard'
import config from './config'
import { Login } from './types'

interface Props {
  login: Login
  onDismiss?: () => void
}

const HelpLink = styled(Link)`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: ${({ theme }) => theme.colorsV2?.textLink};
  text-decoration-line: underline;
  display: flex;
  align-self: center;
  align-items: center;
  margin-top: 20px;
`

const OpenIcon = styled(OpenNewIcon)`
  width: 12px;
  fill: ${({ theme }) => theme.colorsV2?.textLink};
  margin-left: 5px;
`

const WalletBox = styled(Box)<{ isMobile }>`
  overflow-y: auto;
  max-height: calc(80vh - 187px);
  padding-right: ${({ isMobile }) => (isMobile ? '0' : '37px')};
`

const LinkBox = styled(Box)<{ isMobile }>`
  padding-right: ${({ isMobile }) => (isMobile ? '0' : '37px')};
`

const ConnectModal: React.FC<Props> = ({ login, onDismiss = () => null }) => {
  const { t } = useTranslation()
  const { isXl } = useMatchBreakpoints()
  const isMobile = !isXl

  return (
    <ModalV2
      title={t('Connect Wallet')}
      onDismiss={onDismiss}
      minWidth="300px"
      bodyPadding="0px"
      bodyMargin={isMobile ? null : '0 -37px 0 0'}
      disableScroll
    >
      <WalletBox isMobile={isMobile}>
        {config.map((entry, index) => (
          <WalletCard
            key={entry.title}
            login={login}
            walletConfig={entry}
            onDismiss={onDismiss}
            mb={index < config.length - 1 ? '8px' : '0'}
          />
        ))}
      </WalletBox>
      <LinkBox isMobile={isMobile} display="flex" justifyContent="center">
        <HelpLink href="https://impossible.freshdesk.com/en/support/solutions/folders/80000447667" external>
          {t('Learn how to connect')}
          <OpenIcon />
        </HelpLink>
      </LinkBox>
    </ModalV2>
  )
}

export default ConnectModal

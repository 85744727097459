import { Chain } from 'config/constants/types'
import { multicallv2 } from './multicall'

export const reduceBySaleChainId = (list: any[], fn: any): Map<Chain, any> => {
  return list.reduce((acc, data) => {
    const { chainId, saleChainId = chainId } = data
    if (!acc[saleChainId]) {
      acc[saleChainId] = []
    }
    acc[saleChainId].push({ id: data.id, calls: fn(data) })
    return acc
  }, {} as Map<Chain, any>)
}

export const reduceByChainId = (list: any[], fn: any): Map<Chain, any> => {
  return list.reduce((acc, data) => {
    if (!acc[data.chainId]) {
      acc[data.chainId] = []
    }
    acc[data.chainId].push({ id: data.id, calls: fn(data) })
    return acc
  }, {} as Map<Chain, any>)
}

export const multicallPerChainId = async (abi: any[], callsPerChain: Map<Chain, any>) => {
  const results = await Promise.allSettled(
    Object.keys(callsPerChain).map((chainId) =>
      multicallv2(
        abi,
        callsPerChain[chainId].map((val) => val.calls),
        chainId as unknown as Chain,
      ),
    ),
  )

  return results.reduce((acc, result, idx) => {
    if (result.status !== 'fulfilled') return acc
    const chainId = Object.keys(callsPerChain)[idx]
    const calls = callsPerChain[chainId]
    result.value.forEach((val, j) => {
      acc[calls[j].id] = val
    })
    return acc
  }, {})
}

export default null

import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
      <path d="M11.0001 21.6665L12.8801 19.7865L5.44008 12.3332L21.6667 12.3332L21.6667 9.6665L5.44008 9.6665L12.8801 2.21317L11.0001 0.333171L0.333415 10.9998L11.0001 21.6665Z" />
    </Svg>
  )
}

export default Icon

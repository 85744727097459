import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'
import styled, { useTheme } from 'styled-components'

//const theme = useTheme()
const InvestSVG = styled.svg`
  fill: ${({ theme }) => theme.common.investIcon};
`
const InvestLight: React.FC<SvgProps> = (props) => {
  return (
    <InvestSVG width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M10.3514 5.83722C9.76638 5.83722 9.29041 6.31323 9.29041 6.89824C9.34374 8.30392 11.3593 8.30353 11.4125 6.89824C11.4125 6.31323 10.9365 5.83722 10.3514 5.83722Z"
        fillOpacity="0.7"
      />
      <path
        d="M12.4237 0.618936L12.5305 1.22934C12.8263 3.02091 14.2288 4.42341 16.0203 4.71919L16.6336 4.81631C17.4035 2.27254 17.2232 0.413062 17.2481 0.0014917C16.8304 0.0268501 14.9662 -0.152498 12.4237 0.618936Z"
        fillOpacity="0.7"
      />
      <path
        d="M11.4841 1.40475L11.4076 0.967712C7.32926 2.53389 3.72889 5.98798 2.63859 10.207C2.63859 10.207 2.24995 11.9994 3.75014 13.4996C5.25033 14.9998 7.04279 14.6112 7.04279 14.6112C11.2074 13.535 14.6948 9.98142 16.2847 5.83533L15.8509 5.76665C13.6081 5.39635 11.8549 3.64382 11.4841 1.40475ZM10.3515 9.02024C9.18145 9.02024 8.2295 8.06832 8.2295 6.89819C8.33621 4.08687 12.3673 4.08765 12.4736 6.89823C12.4736 8.06832 11.5216 9.02024 10.3515 9.02024Z"
        fillOpacity="0.7"
      />
      <path
        d="M7.69885 15.5302V17.5083H8.22936C11.5682 17.5083 14.0641 14.6568 14.065 11.417C12.3186 13.3365 10.1193 14.8163 7.69885 15.5302Z"
        fillOpacity="0.7"
      />
      <path
        d="M5.83264 3.18457C2.58598 3.18542 -0.258728 5.68727 -0.258728 9.02015V9.55067H1.71944C2.43478 7.12552 3.91707 4.92733 5.83264 3.18457Z"
        fillOpacity="0.7"
      />
      <path
        d="M1.85237 11.9998C0.468396 13.308 0.204864 15.4469 0 17.2498C2.0247 17.0443 3.77315 16.8211 5.25 15.4253C5.25 15.4253 4.01334 15.2083 3.08688 14.3327C2.16043 13.4571 1.85237 11.9998 1.85237 11.9998Z"
        fillOpacity="0.7"
      />
    </InvestSVG>
  )
}

export default InvestLight

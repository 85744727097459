import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const SwapDark: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="18" height="17" viewBox="0 0 18 17" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1627 8.5022C10.92 8.5022 9.91266 9.50956 9.91266 10.7522C9.91266 11.9949 10.92 13.0022 12.1627 13.0022C13.4053 13.0022 14.4127 11.9949 14.4127 10.7522C14.4127 9.50956 13.4053 8.5022 12.1627 8.5022ZM6.91266 10.7522C6.91266 7.85271 9.26316 5.5022 12.1627 5.5022C15.0622 5.5022 17.4127 7.85271 17.4127 10.7522C17.4127 13.6517 15.0622 16.0022 12.1627 16.0022C9.26316 16.0022 6.91266 13.6517 6.91266 10.7522Z"
        fill="#BDC2FF"
      />
      <path
        d="M6.00378 10.8407C6.00125 10.7691 5.99997 10.6971 5.99997 10.6249C5.99997 7.57762 8.27175 5.06094 11.2141 4.67611C10.7813 2.21268 8.63105 0.34082 6.04328 0.34082C3.14388 0.34082 0.793457 2.69124 0.793457 5.59065C0.793457 8.47722 3.12256 10.8194 6.00378 10.8407Z"
        fill="#BDC2FF"
      />
    </Svg>
  )
}

export default SwapDark

import React, { useCallback } from 'react'
import { Box } from '@mui/material'
import { PaidStakeSaleConfig, PrivateIDOConfig, PurchasableIDO, SubscribeableIDO } from 'state/v2_types'
import { ClaimCard, ClaimEndCard, ClaimStartCard } from 'componentsV2/Card'
import { convertFromWei } from 'utils/formatBalance'
import BigNumber from 'bignumber.js'
import { useIsSaleUserDataFetched, useSaleUserData } from 'state/idos/hooks'
import { isPurchaseableIDO, isSubscribeableIDO } from 'state/idos/saleUtil'
import { useAccount } from 'wagmi'
import LoadingCard from 'componentsV2/Card/LoadingCard/LoadingCard'
import { useGetAllocation } from 'hooks/useIFASale'

const Claiming: React.FC<{ sale: PaidStakeSaleConfig | PrivateIDOConfig }> = ({ sale }) => {
  const userData = useSaleUserData(sale.id)
  const { address: account } = useAccount()
  const isSaleUserDataFetched = useIsSaleUserDataFetched()
  const { allo: userAllocationInWei, isLoading } = useGetAllocation(sale.id, sale.isPrivate)

  const getClaimingState = useCallback(() => {
    const now = new Date()
    const {
      claimPeriod,
      token,
      saleAddress,
      chainId,
      saleChainId = chainId,
      saleAmount,
      whitelistedUrl,
      stepRequirement,
      saleId,
      buybackInfo,
    } = sale

    let { purchaserCount = 0 } = claimPeriod
    let finalSalePrice = 0
    const { startTime } = claimPeriod
    let finalTotalPaymentReceived = saleAmount
    const { symbol, image, address, decimals } = token

    const paymentReceivedInWei = (userData && userData.paymentReceivedInWei) || new BigNumber(0)
    const hasWithdrawn = (userData && userData.hasWithdrawn) || false
    const userAllocation = userAllocationInWei && convertFromWei(new BigNumber(userAllocationInWei), decimals)
    let fullDisplayshares = userAllocation || userData?.userTokenAllocation || new BigNumber(0)

    if (isPurchaseableIDO(sale)) {
      const { paymentToken, purchasePeriod } = sale as PurchasableIDO
      const { salePrice, totalPaymentReceived } = purchasePeriod
      finalTotalPaymentReceived = totalPaymentReceived
      finalSalePrice = salePrice
      fullDisplayshares = convertFromWei(new BigNumber(paymentReceivedInWei), paymentToken.decimals).dividedBy(
        finalSalePrice,
      )
    }

    if (isSubscribeableIDO(sale as SubscribeableIDO)) {
      const { subscribePeriod } = sale as SubscribeableIDO
      const { numTrackStakers = 0 } = subscribePeriod
      purchaserCount = Math.max(purchaserCount, numTrackStakers)
    }

    if (fullDisplayshares.isGreaterThan(0)) {
      if (new Date(startTime) > now) {
        return (
          <ClaimStartCard
            stepRequirement={stepRequirement}
            claimDate={startTime}
            tokenIcon={image}
            tokenSymbol={symbol}
            tokenAddress={address}
            tokenDecimals={decimals}
            purchasedAmount={fullDisplayshares}
            chainId={saleChainId}
            isPurchased={isPurchaseableIDO(sale)}
            buybacks={buybackInfo}
            saleAddress={saleAddress}
          />
        )
      }
      if (!hasWithdrawn) {
        return (
          <ClaimCard
            stepRequirement={stepRequirement}
            tokenIcon={image}
            tokenSymbol={symbol}
            tokenAddress={address}
            tokenDecimals={decimals}
            purchasedAmount={fullDisplayshares}
            saleAddress={saleAddress}
            chainId={saleChainId}
            salePrice={finalSalePrice}
            isPurchased={isPurchaseableIDO(sale)}
            whitelistedUrl={whitelistedUrl}
            saleId={saleId}
            userAllocation={userAllocationInWei}
          />
        )
      }
    }

    return (
      <ClaimEndCard
        tokenIcon={image}
        claimedShare={fullDisplayshares}
        participants={purchaserCount}
        totalSales={finalTotalPaymentReceived}
        chainId={chainId}
        buybacks={buybackInfo}
        saleAddress={saleAddress}
      />
    )
  }, [userData, sale])

  if ((account && !isSaleUserDataFetched) || isLoading) {
    return <LoadingCard />
  }
  return (
    <>
      <Box>{getClaimingState()}</Box>
    </>
  )
}

export default Claiming

import React, { KeyboardEvent, RefObject, useCallback, useMemo, useRef, useState, useEffect } from 'react'
import { Text, Input, Box, useMatchBreakpoints } from 'uikit'
import { useTranslation } from 'contexts/Localization'
import { FixedSizeList } from 'react-window'
import useDebounce from 'hooks/useDebounce'
import { useNetwork } from 'wagmi'
import { Chain } from 'config/constants/types'
import Column, { AutoColumn } from 'componentsV2/layout/Column'
import Row from 'componentsV2/layout/Row'
import { Currency, ETHER } from 'swap-sdk/entities/currency'
import { TokenClass } from 'swap-sdk/entities/token'
import useTheme from 'hooks/useTheme'
import { useAllTokens, useToken, useIsUserAddedToken, useFoundOnInactiveList } from '../../hooks/Tokens'
import { isAddress } from '../../utils'
import CommonBases from './CommonBases'
import CurrencyList from './CurrencyList'
import { filterTokens, useSortedTokensByQuery } from './filtering'
import useTokenComparator from './sorting'
import ImportRow from './ImportRow'
import Search from './Icons'

interface CurrencySearchProps {
  selectedCurrency?: Currency | null
  onCurrencySelect: (currency: Currency) => void
  otherSelectedCurrency?: Currency | null
  showCommonBases?: boolean
  showImportView: () => void
  setImportToken: (token: TokenClass) => void
}

function CurrencySearch({
  selectedCurrency,
  onCurrencySelect,
  otherSelectedCurrency,
  showCommonBases,
  showImportView,
  setImportToken,
}: CurrencySearchProps) {
  const { t } = useTranslation()
  const { chain } = useNetwork()
  const chainId = chain?.id || Chain.BSC_MAINNET

  const { theme } = useTheme()

  // refs for fixed size lists
  const fixedList = useRef<FixedSizeList>()

  const [searchQuery, setSearchQuery] = useState<string>('')
  const debouncedQuery = useDebounce(searchQuery, 200)

  const [invertSearchOrder] = useState<boolean>(false)

  const allTokens = useAllTokens()

  // if they input an address, use it
  const searchToken = useToken(debouncedQuery)
  const searchTokenIsAdded = useIsUserAddedToken(searchToken)

  const showETH: boolean = useMemo(() => {
    const s = debouncedQuery.toLowerCase().trim()

    if (chainId === Chain.HUMANODE_TESTNET || chainId === Chain.HUMANODE_MAINNET) {
      return false
    }
    return s === '' || s === 'b' || s === 'bn' || s === 'bnb'
  }, [debouncedQuery])

  const tokenComparator = useTokenComparator(invertSearchOrder)

  const filteredTokens: TokenClass[] = useMemo(() => {
    return filterTokens(Object.values(allTokens), debouncedQuery)
  }, [allTokens, debouncedQuery])

  const sortedTokens: TokenClass[] = useMemo(() => {
    return filteredTokens.sort(tokenComparator)
  }, [filteredTokens, tokenComparator])

  const filteredSortedTokens = useSortedTokensByQuery(sortedTokens, debouncedQuery)

  const handleCurrencySelect = useCallback(
    (currency: Currency) => {
      onCurrencySelect(currency)
    },
    [onCurrencySelect],
  )

  // manage focus on modal show
  const inputRef = useRef<HTMLInputElement>()
  const { isXl } = useMatchBreakpoints()
  const isMobile = !isXl

  useEffect(() => {
    if (!isMobile) {
      inputRef.current.focus()
    }
  }, [isMobile])

  const handleInput = useCallback((event) => {
    const input = event.target.value
    const checksummedInput = isAddress(input)
    setSearchQuery(checksummedInput || input)
    fixedList.current?.scrollTo(0)
  }, [])

  const handleEnter = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        const s = debouncedQuery.toLowerCase().trim()
        if (s === ETHER[chainId].symbol?.toLowerCase()) {
          handleCurrencySelect(ETHER[chainId])
        } else if (filteredSortedTokens.length > 0) {
          if (
            filteredSortedTokens[0].symbol?.toLowerCase() === debouncedQuery.trim().toLowerCase() ||
            filteredSortedTokens.length === 1
          ) {
            handleCurrencySelect(filteredSortedTokens[0])
          }
        }
      }
    },
    [filteredSortedTokens, handleCurrencySelect, debouncedQuery, chainId],
  )

  // if no results on main list, show option to expand into inactive
  const inactiveTokens = useFoundOnInactiveList(debouncedQuery)
  const filteredInactiveTokens: TokenClass[] = useSortedTokensByQuery(inactiveTokens, debouncedQuery)
  const currencyListHeight = Math.min(
    390,
    window.innerHeight * 0.8 - (showCommonBases && searchQuery === '' ? 330 : 221),
  )
  return (
    <>
      <div>
        <AutoColumn gap="16px">
          <Row>
            <Box width="100%" position="relative">
              <Input
                id="token-search-input"
                width="100%"
                placeholder={t('Search name or paste address')}
                scale="lg"
                autoComplete="off"
                value={searchQuery}
                ref={inputRef as RefObject<HTMLInputElement>}
                onChange={handleInput}
                onKeyDown={handleEnter}
              />
              <Box position="absolute" top="12px" right="6px">
                <Text color="textDisabled">
                  <Search color="currentColor" />
                </Text>
              </Box>
            </Box>
          </Row>
          {showCommonBases && searchQuery === '' && (
            <CommonBases
              chainId={chainId}
              onSelect={handleCurrencySelect}
              selectedCurrency={selectedCurrency}
              otherCurrency={otherSelectedCurrency}
            />
          )}
        </AutoColumn>
        {searchToken && !searchTokenIsAdded ? (
          <Column style={{ padding: '20px 0', height: '100%' }}>
            <ImportRow token={searchToken} showImportView={showImportView} setImportToken={setImportToken} />
          </Column>
        ) : filteredSortedTokens?.length > 0 || filteredInactiveTokens?.length > 0 ? (
          <Box margin="24px 0">
            <Box ml="15px" mb="5px">
              <Text fontSize="12px" color={theme.isDark ? 'textThirdly' : 'text'}>
                {t('Token Name')}
              </Text>
            </Box>
            <CurrencyList
              height={currencyListHeight}
              showETH={showETH}
              currencies={
                filteredInactiveTokens ? filteredSortedTokens.concat(filteredInactiveTokens) : filteredSortedTokens
              }
              onCurrencySelect={handleCurrencySelect}
              otherCurrency={otherSelectedCurrency}
              selectedCurrency={selectedCurrency}
              fixedListRef={fixedList}
              showImportView={showImportView}
              setImportToken={setImportToken}
            />
          </Box>
        ) : (
          <Column style={{ padding: '20px', height: '100%' }}>
            <Text color="textDisabled" textAlign="center" mb="20px">
              {t('No results found.')}
            </Text>
          </Column>
        )}
      </div>
    </>
  )
}

export default CurrencySearch

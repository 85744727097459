import React from 'react'

interface Props {
  size?: number
}

export const TempSuspendedSale: React.FC<Props> = ({ size = 100 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="101" height="98" viewBox="0 0 101 98" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.0781 18.5078C68.0781 28.2159 60.2081 36.0859 50.5 36.0859C40.7919 36.0859 32.9219 28.2159 32.9219 18.5078C32.9219 8.79968 40.7919 0.929688 50.5 0.929688C60.2081 0.929688 68.0781 8.79968 68.0781 18.5078ZM51.8543 23.9973C52.4262 24.5695 53.176 24.8555 53.9258 24.8555C54.6754 24.8555 55.4254 24.5693 55.9973 23.9973C57.1414 22.8531 57.1414 20.9982 55.9973 19.8541L53.4375 17.2943V13.5273C53.4375 11.9094 52.126 10.5977 50.5078 10.5977C48.8898 10.5977 47.5781 11.9094 47.5781 13.5273V18.5078C47.5781 19.2848 47.8869 20.0299 48.4363 20.5793L51.8543 23.9973Z"
        fill="url(#paint0_linear_4127_10951)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M94.6406 95.0705C94.6406 96.6885 95.9523 98.0002 97.5703 98.0002C99.1883 98.0002 100.5 96.6885 100.5 95.0705V88.0391C100.5 83.1928 96.5572 79.25 91.7109 79.25H50.5H9.28906C4.44277 79.25 0.5 83.1928 0.5 88.0391V95.0705C0.5 96.6885 1.81172 98.0002 3.42969 98.0002C5.04766 98.0002 6.35938 96.6885 6.35938 95.0705V88.0391C6.35938 86.4236 7.67363 85.1094 9.28906 85.1094H50.5H91.7109C93.3264 85.1094 94.6406 86.4236 94.6406 88.0391V95.0705Z"
        fill="#C7D9F3"
      />
      <path
        d="M44.2504 83.1562H13.4072V56.9287C13.4072 51.8895 17.4924 47.8045 22.5314 47.8045H35.1262C40.1652 47.8045 44.2504 51.8895 44.2504 56.9287V83.1562Z"
        fill="url(#paint1_linear_4127_10951)"
      />
      <path
        d="M44.641 89.0156H13.0166C11.3986 89.0156 10.0869 87.7039 10.0869 86.0859V83.3516C10.0869 78.5053 14.0297 74.5625 18.876 74.5625H38.7816C43.6279 74.5625 47.5707 78.5053 47.5707 83.3516V86.0859C47.5707 87.7039 46.259 89.0156 44.641 89.0156Z"
        fill="url(#paint2_linear_4127_10951)"
      />
      <path
        d="M87.5932 83.1562H56.75V56.9287C56.75 51.8895 60.8352 47.8045 65.8742 47.8045H78.4689C83.508 47.8045 87.5932 51.8895 87.5932 56.9287V83.1562Z"
        fill="url(#paint3_linear_4127_10951)"
      />
      <path
        d="M87.9838 89.0156H56.3594C54.7414 89.0156 53.4297 87.7039 53.4297 86.0859V83.3516C53.4297 78.5053 57.3725 74.5625 62.2188 74.5625H82.1244C86.9707 74.5625 90.9135 78.5053 90.9135 83.3516V86.0859C90.9135 87.7039 89.6018 89.0156 87.9838 89.0156Z"
        fill="url(#paint4_linear_4127_10951)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4127_10951"
          x1="50.5"
          y1="0.929688"
          x2="50.5"
          y2="86"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3C7EFF" stopOpacity="0.12" />
          <stop offset="1" stopColor="#0074FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4127_10951"
          x1="28.8288"
          y1="47.8045"
          x2="28.8288"
          y2="83.1562"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5ED4F3" />
          <stop offset="1" stopColor="#18A0C4" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4127_10951"
          x1="28.8288"
          y1="74.5625"
          x2="28.8288"
          y2="89.0156"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5ED4F3" />
          <stop offset="1" stopColor="#2AA5C6" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_4127_10951"
          x1="72.1716"
          y1="47.8045"
          x2="72.1716"
          y2="83.1562"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5ED4F3" />
          <stop offset="1" stopColor="#18A0C4" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_4127_10951"
          x1="72.1716"
          y1="74.5625"
          x2="72.1716"
          y2="89.0156"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5ED4F3" />
          <stop offset="1" stopColor="#2AA5C6" />
        </linearGradient>
      </defs>
    </svg>
  )
}

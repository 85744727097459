import { Brands, CompanyContext } from 'contexts/CompanyContext'
import React, { useContext } from 'react'

interface Props {
  size?: number
}

export const ClosedLightRonin: React.FC<Props> = ({ size = 100 }) => {
  return (
    <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 93 67" fill="none">
      <path
        d="M44.3033 1.64754C44.3033 0.737629 45.041 0 45.9509 0C46.8608 0 47.5984 0.737629 47.5984 1.64754V27.8525C47.5984 28.7624 46.8608 29.5 45.9509 29.5C45.041 29.5 44.3033 28.7624 44.3033 27.8525V1.64754Z"
        fill="#616568"
      />
      <path
        d="M44.3033 0H67.4957C67.9024 0 68.1389 0.459685 67.9025 0.790619L63.1831 7.39791C63.0589 7.57175 63.0589 7.80529 63.1831 7.97914L67.9025 14.5864C68.1389 14.9174 67.9024 15.377 67.4957 15.377H44.3033V0Z"
        fill="url(#paint0_linear_205_21482)"
      />
      <path
        d="M9.1082 55.2271L3.10392 63.8583C2.18143 65.1843 3.13035 67.0004 4.74573 67.0004H88.683C90.208 67.0004 91.1719 65.3622 90.4313 64.0291L87.3718 58.522C86.9154 57.7005 85.9488 57.3076 85.0488 57.5776L82.6912 58.2849C81.768 58.5618 80.7787 58.141 80.3379 57.2839L72.0537 41.1758C71.5785 40.2517 70.4746 39.8455 69.5137 40.2412L63.5689 42.689C62.6112 43.0834 61.5108 42.6813 61.033 41.7624L55.0092 30.1781C54.832 29.8373 54.5604 29.5548 54.227 29.3643L47.9522 25.7786C47.3862 25.4552 46.6981 25.4279 46.1083 25.7055L28.9515 33.7793C28.5778 33.9552 28.2676 34.2425 28.0637 34.6017L17.4191 53.3565C17.0639 53.9825 16.3996 54.3693 15.6798 54.3693H10.75C10.0953 54.3693 9.48207 54.6897 9.1082 55.2271Z"
        fill="url(#paint1_linear_205_21482)"
      />
      <rect x="41.0083" y="25.2627" width="9.88525" height="4.39344" fill="#7D7D7D" />
      <defs>
        <linearGradient
          id="paint0_linear_205_21482"
          x1="56.3853"
          y1="0"
          x2="56.3853"
          y2="15.377"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#84BFF4" />
          <stop offset="1" stopColor="#4B7EFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_205_21482"
          x1="54.1886"
          y1="25.2627"
          x2="54.1886"
          y2="69.7463"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8AB56F" />
          <stop offset="1" stopColor="#5EA035" stopOpacity="0.27" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export const ClosedLight: React.FC<Props> = ({ size = 100 }) => {
  const { brand } = useContext(CompanyContext)
  if (brand === Brands.RONIN) return <ClosedLightRonin size={size} />
  return (
    <svg width={size} height={size} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M48 19.5C48 18.6716 48.6716 18 49.5 18C50.3284 18 51 18.6716 51 19.5V58.5C51 59.3284 50.3284 60 49.5 60C48.6716 60 48 59.3284 48 58.5V19.5Z"
        fill="#616568"
      />
      <path
        d="M48 20.6973H89.2862C89.6973 20.6973 89.9327 21.1658 89.6874 21.4957L80.8938 33.3181C80.7621 33.4953 80.7621 33.7378 80.8938 33.9149L89.6874 45.7374C89.9327 46.0673 89.6973 46.5358 89.2862 46.5358H48V20.6973Z"
        fill="url(#paint0_linear_7153_131790)"
      />
      <path
        d="M63.3866 30.6734C64.6982 30.6734 65.7615 29.6299 65.7615 28.3426C65.7615 27.0553 64.6982 26.0117 63.3866 26.0117C62.075 26.0117 61.0117 27.0553 61.0117 28.3426C61.0117 29.6299 62.075 30.6734 63.3866 30.6734Z"
        fill="#FAFAFA"
      />
      <path
        d="M74.2637 30.6734H70.9745C70.5708 30.6734 70.2383 30.3471 70.2383 29.9508V26.7226C70.2383 26.3263 70.5708 26 70.9745 26H74.2637C74.6674 26 74.9999 26.3263 74.9999 26.7226V29.9508C74.9999 30.3587 74.6674 30.6734 74.2637 30.6734Z"
        fill="#FAFAFA"
      />
      <path
        d="M65.7616 32.665H65.6191H62.0093C61.4512 32.665 61 33.1079 61 33.6557V37.3384V41.0095C61 41.5573 61.4512 42.0002 62.0093 42.0002H64.7523C65.3104 42.0002 65.7616 41.5573 65.7616 41.0095V38.329C65.7616 37.7813 66.2129 37.3384 66.771 37.3384H69.3715C69.9296 37.3384 70.3808 36.8956 70.3808 36.3478V33.6557C70.3808 33.1079 69.9296 32.665 69.3715 32.665H65.7616Z"
        fill="#FAFAFA"
      />
      <path
        d="M15.9032 82.3579L10.6858 89.8579C9.76334 91.1839 10.7123 93 12.3276 93H88.101C89.6259 93 90.5899 91.3618 89.8493 90.0287L87.2816 85.4068C86.8252 84.5854 85.8586 84.1924 84.9585 84.4624L83.0928 85.0222C82.1696 85.2991 81.1803 84.8783 80.7395 84.0212L73.3406 69.6345C72.8653 68.7103 71.7614 68.3041 70.8005 68.6998L65.6918 70.8034C64.7341 71.1977 63.6337 70.7956 63.1559 69.8767L57.7714 59.5219C57.5942 59.1812 57.3227 58.8987 56.9892 58.7081L51.4029 55.516C50.8369 55.1925 50.1489 55.1652 49.559 55.4428L34.0744 62.7297C33.7007 62.9056 33.3905 63.1929 33.1866 63.5521L23.5748 80.4872C23.2195 81.1132 22.5553 81.5 21.8355 81.5H17.545C16.8903 81.5 16.2771 81.8204 15.9032 82.3579Z"
        fill="url(#paint1_linear_7153_131790)"
      />
      <rect x="45" y="55" width="9" height="4" fill="#7D7D7D" />
      <defs>
        <linearGradient
          id="paint0_linear_7153_131790"
          x1="69.1406"
          y1="20.6973"
          x2="69.1406"
          y2="46.5358"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5ED4F3" />
          <stop offset="1" stopColor="#18A0C4" />
        </linearGradient>
        <linearGradient id="paint1_linear_7153_131790" x1="57" y1="55" x2="57" y2="95.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#8AB56F" />
          <stop offset="1" stopColor="#597845" stopOpacity="0.27" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export const ClosedDark: React.FC<Props> = ({ size = 100 }) => {
  const { brand } = useContext(CompanyContext)
  if (brand === Brands.RONIN) return <ClosedLightRonin size={size} />
  return (
    <svg width={size} height={size} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M48 19.5C48 18.6716 48.6716 18 49.5 18C50.3284 18 51 18.6716 51 19.5V58.5C51 59.3284 50.3284 60 49.5 60C48.6716 60 48 59.3284 48 58.5V19.5Z"
        fill="#616568"
      />
      <path
        d="M48 20.6973H89.2862C89.6973 20.6973 89.9327 21.1658 89.6874 21.4957L80.8938 33.3181C80.7621 33.4953 80.7621 33.7378 80.8938 33.9149L89.6874 45.7374C89.9327 46.0673 89.6973 46.5358 89.2862 46.5358H48V20.6973Z"
        fill="url(#paint0_linear_7153_130325)"
      />
      <path
        d="M63.3866 30.6734C64.6982 30.6734 65.7615 29.6299 65.7615 28.3426C65.7615 27.0553 64.6982 26.0117 63.3866 26.0117C62.075 26.0117 61.0117 27.0553 61.0117 28.3426C61.0117 29.6299 62.075 30.6734 63.3866 30.6734Z"
        fill="#2E3466"
      />
      <path
        d="M74.2637 30.6734H70.9745C70.5708 30.6734 70.2383 30.3471 70.2383 29.9508V26.7226C70.2383 26.3263 70.5708 26 70.9745 26H74.2637C74.6674 26 74.9999 26.3263 74.9999 26.7226V29.9508C74.9999 30.3587 74.6674 30.6734 74.2637 30.6734Z"
        fill="#2E3466"
      />
      <path
        d="M65.7616 32.665H65.6191H62.0093C61.4512 32.665 61 33.1079 61 33.6557V37.3384V41.0095C61 41.5573 61.4512 42.0002 62.0093 42.0002H64.7523C65.3104 42.0002 65.7616 41.5573 65.7616 41.0095V38.329C65.7616 37.7813 66.2129 37.3384 66.771 37.3384H69.3715C69.9296 37.3384 70.3808 36.8956 70.3808 36.3478V33.6557C70.3808 33.1079 69.9296 32.665 69.3715 32.665H65.7616Z"
        fill="#2E3466"
      />
      <path
        d="M15.9032 82.3579L10.6858 89.8579C9.76334 91.1839 10.7123 93 12.3276 93H88.101C89.6259 93 90.5899 91.3618 89.8493 90.0287L87.2816 85.4068C86.8252 84.5854 85.8586 84.1924 84.9585 84.4624L83.0928 85.0222C82.1696 85.2991 81.1803 84.8783 80.7395 84.0212L73.3406 69.6345C72.8653 68.7103 71.7614 68.3041 70.8005 68.6998L65.6918 70.8034C64.7341 71.1977 63.6337 70.7956 63.1559 69.8767L57.7714 59.5219C57.5942 59.1812 57.3227 58.8987 56.9892 58.7081L51.4029 55.516C50.8369 55.1925 50.1489 55.1652 49.559 55.4428L34.0744 62.7297C33.7007 62.9056 33.3905 63.1929 33.1866 63.5521L23.5748 80.4872C23.2195 81.1132 22.5553 81.5 21.8355 81.5H17.545C16.8903 81.5 16.2771 81.8204 15.9032 82.3579Z"
        fill="url(#paint1_linear_7153_130325)"
      />
      <rect x="45" y="55" width="9" height="4" fill="#7D7D7D" />
      <defs>
        <linearGradient
          id="paint0_linear_7153_130325"
          x1="69.1406"
          y1="20.6973"
          x2="69.1406"
          y2="46.5358"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5ED4F3" />
          <stop offset="1" stopColor="#18A0C4" />
        </linearGradient>
        <linearGradient id="paint1_linear_7153_130325" x1="50" y1="61" x2="50" y2="93" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00D279" />
          <stop offset="1" stopColor="#00D279" stopOpacity="0.19" />
        </linearGradient>
      </defs>
    </svg>
  )
}

import React from 'react'
import Box from '@mui/material/Box'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import { useLocation, Link } from 'react-router-dom'
import { useMatchBreakpoints } from 'uikit'

const TabsBox = styled(Box)`
  background: ${({ theme }) => (theme.isDark ? theme.colorsV2.main3 : theme.colorsV2.main2)};
  backdrop-filter: blur(40px);
  display: flex;
  padding-top: 84px;
  ${({ theme }) => theme.mediaQueries?.md} {
    padding-top: 90px;
  }
  position: fixed;
  top: 0;
  z-index: 11;
  width: 100%;
`

const TabsBoxContainer = styled(Box)`
  height: 116px;
  ${({ theme }) => theme.mediaQueries?.md} {
    padding-top: 132px;
  }
`
const TabBox = styled(Box)`
  min-width: 72px;
  text-align: center;
`

const TitleBox = styled(Box)<{
  $isActive?: boolean
}>`
  padding-bottom: 16px;
  background: ${({ $isActive }) =>
    $isActive ? 'linear-gradient(90deg, #00cb82 -2.21%, #00b4ed 100%) left bottom no-repeat' : 'transparent'};
  color: ${({ $isActive, theme }) => ($isActive ? theme.colorsV2.text : theme.colorsV2.textSecondary)};
  font-weight: 700;
  font-size: 14px;
  ${({ theme }) => theme.mediaQueries?.md} {
    font-size: 18px;
  }
  background-size: 100% 5px;
`

type TabMenu = {
  name: string
  title: string
  link: string
}

type MainTabProps = {
  menu: TabMenu[]
}

const MainTab: React.FC<MainTabProps> = ({ menu }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const { isXl } = useMatchBreakpoints()
  const isMobile = !isXl

  return (
    <TabsBoxContainer>
      <TabsBox alignItems="center" justifyContent={isMobile ? 'space-evenly' : 'center'}>
        {menu.map((tab) => {
          return (
            <Link to={tab.link} key={tab.name}>
              <TabBox paddingX={isMobile ? 4 : 8}>
                <TitleBox $isActive={location.pathname.includes(tab.name)}>{t(tab.title)}</TitleBox>
              </TabBox>
            </Link>
          )
        })}
      </TabsBox>
    </TabsBoxContainer>
  )
}

export default MainTab

import { useState, useCallback, useContext } from 'react'
import { useAccount, useBalance } from 'wagmi'
import { useToast } from 'state/hooks'
import { useDispatch } from 'react-redux'
import { fetchIdoUserDataAsync, fetchSalesUserDataAsync } from 'state/idos'
import { useIFAMasterContract } from './useContract'
import useWeb3 from 'hooks/useWeb3'
import { Brands, CompanyContext } from 'contexts/CompanyContext'

// stake
export const useStake = (masterAddress: string, trackId: number) => {
  const { address: account } = useAccount()
  const IFMasterContract = useIFAMasterContract(masterAddress)
  const web3 = useWeb3()
  const { brand } = useContext(CompanyContext)

  const [txHash, setTxHash] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const { toastErrorV2 } = useToast()
  const dispatch = useDispatch()
  const balance = useBalance({
    address: account,
  })

  const handleStake = useCallback(
    async (amount: string, onSuccess?: any) => {
      try {
        setIsLoading(true)
        if (balance?.data?.value <= 0) {
          throw new Error('No gas fees')
        }

        const gasPrice = await web3.eth.getGasPrice()
        const gasAmount = await IFMasterContract.methods.stake(trackId, amount).estimateGas({ from: account })

        const tx = await IFMasterContract.methods.stake(trackId, amount).send({
          from: account,
          gasPrice: gasPrice,
          gasLimit: brand === Brands.RONIN ? ((gasAmount * 120) / 100).toFixed(0) : gasAmount,
        })

        // const tx = await IFMasterContract.methods.stake(trackId, amount).send({ from: account, gas: 200000 })
        dispatch(fetchSalesUserDataAsync(account))
        setTxHash(tx.transactionHash)
        setIsLoading(false)
        if (onSuccess) {
          onSuccess(tx.transactionHash)
        }
      } catch (e: any) {
        console.error(e)
        setIsLoading(false)
        toastErrorV2('Error', e.message)
      }
    },
    [account, trackId, IFMasterContract, toastErrorV2, dispatch],
  )

  return { onStake: handleStake, isLoading, txHash }
}

// unstake
export const useUnstake = (masterAddress: string, trackId: number) => {
  const { address: account } = useAccount()
  const IFMasterContract = useIFAMasterContract(masterAddress)
  const [txHash, setTxHash] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const { toastErrorV2 } = useToast()
  const dispatch = useDispatch()
  const web3 = useWeb3()
  const { brand } = useContext(CompanyContext)

  const balance = useBalance({
    address: account,
  })

  const handleUnstake = useCallback(
    async (amount: string, onSuccess?: any) => {
      try {
        setIsLoading(true)
        if (balance?.data?.value <= 0) {
          throw new Error('No gas fees')
        }

        const gasPrice = await web3.eth.getGasPrice()
        const gasAmount = await IFMasterContract.methods.unstake(trackId, amount).estimateGas({ from: account })

        const tx = await IFMasterContract.methods.unstake(trackId, amount).send({
          from: account,
          gasPrice: gasPrice,
          gasLimit: brand === Brands.RONIN ? ((gasAmount * 120) / 100).toFixed(0) : gasAmount,
        })
        dispatch(fetchIdoUserDataAsync(account))
        setTxHash(tx.transactionHash)
        setIsLoading(false)
        onSuccess(tx.transactionHash)
      } catch (e: any) {
        console.error(e)
        setIsLoading(false)
        toastErrorV2('Error', e.message)
      }
    },
    [account, trackId, IFMasterContract, toastErrorV2, dispatch],
  )

  return { onUnstake: handleUnstake, isLoading, txHash }
}

import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Flex, IconButton, ArrowBackIcon, NotificationDot } from 'uikit'
import { Link } from 'react-router-dom'
import { useExpertModeManager } from 'state/user/hooks'
import GlobalSettings from 'componentsV2/Menu/GlobalSettings'
import IFTypography from 'componentsV2/IFTypography/IFTypography'
// import Transactions from './Transactions'
import DefaultIFTooltip from 'componentsV2/DefaultIFTooltip/DefaultIFTooltip'
import { Variant } from '@mui/material/styles/createTypography'

interface Props {
  title: string
  subtitle?: string | ReactNode
  helper?: string
  backTo?: string
  noConfig?: boolean
  titleVariant?: Variant
  subtitlePadding?: string
  subtitleGap?: string
}

const AppHeaderContainer = styled.div`
  padding: 30px;
  padding-bottom: 0;
  width: 100%;
  position: relative;
`

const AppHeader: React.FC<Props> = ({
  title,
  subtitle,
  helper,
  backTo,
  noConfig = false,
  subtitlePadding,
  titleVariant,
  subtitleGap,
}) => {
  const [expertMode] = useExpertModeManager()

  return (
    <AppHeaderContainer>
      <Flex justifyContent="center">
        <Flex flexDirection="column" alignItems="center">
          <IFTypography variant={titleVariant ?? 'h3'} fontWeight="bold">
            {title}
          </IFTypography>
          <Flex alignItems="center" marginTop={subtitleGap ?? '10px'} px={subtitlePadding ?? '38px'}>
            <IFTypography variant="body2" ifcolor="textSecondary" align="center">
              {subtitle}
            </IFTypography>
            {helper && <DefaultIFTooltip title={helper} placement="top" />}
          </Flex>
        </Flex>
      </Flex>
      <Flex
        position="absolute"
        zIndex={1}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        left={0}
        top={0}
        width="100%"
        padding="30px"
      >
        <Flex alignItems="flex-start">
          {backTo && (
            <IconButton as={Link} to={backTo}>
              <ArrowBackIcon width="16px" />
            </IconButton>
          )}
        </Flex>
        <Flex alignItems="flex-end">
          {!noConfig && (
            <Flex alignItems="center">
              <NotificationDot show={expertMode}>
                <GlobalSettings />
              </NotificationDot>
            </Flex>
          )}
        </Flex>
      </Flex>
    </AppHeaderContainer>
  )
}

export default AppHeader

import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const LiquidityDark: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
      <path
        d="M12.5 5C13.7426 5 14.75 3.99264 14.75 2.75C14.75 1.50736 13.7426 0.5 12.5 0.5C11.2574 0.5 10.25 1.50736 10.25 2.75C10.25 3.99264 11.2574 5 12.5 5Z"
        fill="#BDC2FF"
      />
      <path
        d="M3.5 3.5C4.32843 3.5 5 2.82843 5 2C5 1.17157 4.32843 0.5 3.5 0.5C2.67157 0.5 2 1.17157 2 2C2 2.82843 2.67157 3.5 3.5 3.5Z"
        fill="#BDC2FF"
      />
      <path
        d="M0.125 11.3148C0.125 10.1675 1.38226 9.4061 2.44833 9.82996C3.89981 10.4071 5.82828 11 7.625 11C9.42172 11 11.3502 10.4071 12.8017 9.82996C13.8677 9.4061 15.125 10.1675 15.125 11.3148V15.5C15.125 16.3284 14.4534 17 13.625 17H1.625C0.796573 17 0.125 16.3284 0.125 15.5V11.3148Z"
        fill="#BDC2FF"
      />
      <path
        d="M3.9768 9.24652C5.12682 9.60217 6.40623 9.875 7.625 9.875C8.84377 9.875 10.1232 9.60217 11.2732 9.24652C11.3398 8.96685 11.375 8.67504 11.375 8.375C11.375 6.30393 9.69607 4.625 7.625 4.625C5.55393 4.625 3.875 6.30393 3.875 8.375C3.875 8.67504 3.91024 8.96685 3.9768 9.24652Z"
        fill="#BDC2FF"
      />
    </Svg>
  )
}

export default LiquidityDark

import { Box } from '@mui/material'
import { Error } from '@mui/icons-material'
import { useTranslation } from 'contexts/Localization'
import React from 'react'
import styled from 'styled-components'
import { Button, Link, useMatchBreakpoints } from 'uikit'
import IFTypography from '../IFTypography/IFTypography'

const Container = styled.div`
  background: ${({ theme }) => theme.colorsV2?.swap.migrationBannerBackground};
  padding: 10px 15px;
  color: ${({ theme }) => theme.colorsV2?.textThirdly};
  position: relative;
  height: 102px;
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);
  margin-top: -8px;
  display: flex;
  align-items: flex-start;

  & > div {
    display: flex;
    flex-direction: column;
  }

  ${({ theme }) => theme.mediaQueries?.md} {
    padding: 16px 0;
    top: 0;

    & > div {
      flex-direction: row;
      align-items: center;
    }
  }

  ${({ theme }) => theme.mediaQueries?.sm} {
    align-items: center;
    justify-content: center;
    height: 52px;

    & > div {
      flex-direction: row;
      align-items: center;
    }
  }

  svg {
    fill: ${({ theme }) => theme.colorsV2?.textThirdly};
    margin-right: 10px;
  }

  button {
    background: ${({ theme }) => theme.colorsV2?.textThirdly};
    color: ${({ theme }) => theme.colorsV2?.swap.migrationBannerBtnColor};
    font-size: 14px;
    margin: 10px 0 0 0;
    border-radius: 21px;
    height: 32px;

    ${({ theme }) => theme.mediaQueries?.sm} {
      margin: 0 0 0 25px;
    }
  }

  a {
    text-decoration: unset;
  }
`

const SwapMigrationBanner = () => {
  const { t } = useTranslation()
  const { isXl } = useMatchBreakpoints()
  const isMobile = isXl === false
  return (
    <Container>
      <Error />
      <Box>
        <IFTypography
          variant="body2"
          fontWeight={isMobile ? 'regular' : 'bold'}
          ifcolor="textThirdly"
          lineHeight="20px"
        >
          {t('Remember to migrate earlier liquidity to V3 liquidity.')}
        </IFTypography>
        <Link external href={`${process.env.REACT_APP_MIGRATE_DOMAIN}/#/migrate`}>
          <Button>{t('Migrate Now')}</Button>
        </Link>
      </Box>
    </Container>
  )
}

export default SwapMigrationBanner

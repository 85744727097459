import { Box } from '@mui/material'
import BigNumber from 'bignumber.js'
import IFTypography from 'componentsV2/IFTypography/IFTypography'
import { useTranslation } from 'contexts/Localization'
import React, { useContext, useMemo } from 'react'
import { useSaleUserData } from 'state/idos/hooks'
import { PurchasableIDO, SubscribeableIDO } from 'state/v2_types'
import styled, { useTheme } from 'styled-components'
import { Button, useModalV2 } from 'uikit'
import DefaultIFTooltip from 'componentsV2/DefaultIFTooltip/DefaultIFTooltip'
import { isSaleParticipated } from 'state/idos/utils'
import { isPurchaseableIDO } from 'state/idos/saleUtil'
import { convertFromWei } from 'utils/formatBalance'

import UnstakeModal from './UnstakeModal'
import { CompanyContext, Brands } from 'contexts/CompanyContext'

const SaleToken = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 64px;
  box-shadow: inset 0px 0px 10px rgba(5, 10, 20, 0.12);
`

const EstimatedAllocationArea = styled.div`
  border-radius: 10px;
  background: ${({ theme }) => theme.sale.estimatedAllocationAreaBackground};
  padding: 10px;
  svg {
    width: 16px;
    height: 16px;
  }
`

const EstimatedAllocationAmount = styled.div<{ hasParticipated: boolean }>`
  margin: 8px 0;
  font-size: 32px;
  line-height: 37.5px;
  color: ${({ theme, hasParticipated }) =>
    !hasParticipated ? theme.sale.estimatedAllocationAmount : theme.sale.estimatedAllocationAmountParticipated};
`

const UnstakeArea = styled(Box)<{ isSubcriptionEnded: boolean }>`
  margin-top: ${({ isSubcriptionEnded }) => (isSubcriptionEnded ? '20px' : '0')};
  padding: 10px 16px 10px 56px;
  right: 56px;
  bottom: 0;
  width: calc(100% + 76px);
  position: relative;
  border-radius: 10px;
  background: ${({ theme, isSubcriptionEnded }) =>
    !isSubcriptionEnded ? 'transparent' : theme.sale.unstakeAreaBackground};
  display: flex;
  flex-direction: column;
`

const UnstakeButton = styled(Button)`
  background: ${({ theme }) => theme.sale.unstakeButtonBackground};
  margin-top: 5px;
  width: 85px;
  height: 26px;
  padding: 7px 10px;
  align-self: flex-end;
  span {
    color: #ffffff;
  }

  ${({ theme }) => theme.mediaQueries?.md} {
    margin-top: 10px;
  }
`

interface StakePeriodWidgetProps {
  sale: SubscribeableIDO
  saleTokenImage: string
}

const TimeLineStakeWidget: React.FC<StakePeriodWidgetProps> = ({ sale, saleTokenImage }) => {
  const { t } = useTranslation()
  const {
    subscribePeriod: { startTime, totalStaked, endTime },
    chainId,
    isGiftBox,
  } = sale
  const userData = useSaleUserData(sale.id)
  // OMNI OVERDRIVE
  const isAllChains = chainId === 165 || chainId === 59140 || chainId === 421613
  const userStakeAmout = new BigNumber(userData?.userStakeAmount ?? 0)
  const userStakedOnCurrentChain = userData?.userStakedOnCurrentChain ?? 0

  const stakingTokenOnChain = convertFromWei(userStakedOnCurrentChain.toLocaleString(), sale.stakingToken.decimals)
  const stakingTokenOnChainStr = stakingTokenOnChain.decimalPlaces(8).toNumber().toLocaleString()

  const userStakeAmountStr = userStakeAmout.decimalPlaces(8).toNumber().toLocaleString()
  const totalStakedStr = new BigNumber(totalStaked ?? 0).decimalPlaces(3).toNumber().toLocaleString()
  const hasParticipated = isSaleParticipated(sale, userData)

  const hasStaked =
    userData?.userStakedOnCurrentChain !== null && userData?.userStakedOnCurrentChain !== undefined
      ? stakingTokenOnChain.isGreaterThan(0)
      : userStakeAmout.isGreaterThan(0)
  const isSubcriptionStarted = new Date() >= new Date(startTime)
  const isSubcriptionEnded = new Date() > new Date(endTime)
  const { isDark, common } = useTheme()

  const [onPresentUnstakeModal] = useModalV2({
    modal: (
      <UnstakeModal
        userStakeAmount={
          userData?.userStakedOnCurrentChain !== null && userData?.userStakedOnCurrentChain !== undefined
            ? stakingTokenOnChain
            : userStakeAmout
        }
        stakingToken={sale.stakingToken}
        masterAddress={sale.masterAddress}
        chainId={chainId}
        trackID={parseInt(sale.trackId)}
        isDuringSubscriptionPeriod={isSubcriptionStarted && !isSubcriptionEnded}
      />
    ),
    options: {
      backdropBlurVal: 'blur(20px)',
      closeOnOverlayClick: false,
    },
  })

  const estimatedAllocation = userData?.userTokenAllocation || new BigNumber(0)
  const estimatedAllocationStr = estimatedAllocation.decimalPlaces(2).toNumber().toLocaleString()
  const isPurchaseable = isPurchaseableIDO(sale as unknown as PurchasableIDO)

  const estimationAllocationWidget = useMemo(() => {
    if (isSubcriptionEnded || isGiftBox) return null
    return (
      <EstimatedAllocationArea>
        <Box display="flex" alignItems="center">
          <IFTypography variant="body1" lineHeight="24px">
            {isPurchaseable ? t('Estimated Allocation') : t('Estimated Rewards')}{' '}
            {isAllChains && <span style={{ fontSize: '12px' }}>(all chains)</span>}
          </IFTypography>
          <DefaultIFTooltip
            title={t(
              'Your allocation can increase or decrease, relative to the amount and time that it remains staked by other users.',
            )}
            ml="5px"
            placement="top"
          />
        </Box>
        <EstimatedAllocationAmount hasParticipated={hasParticipated}>
          {hasParticipated ? (
            <Box display="flex" alignItems="center">
              <SaleToken src={saleTokenImage} alt="sale-token" />
              <Box marginLeft="20px">{estimatedAllocationStr}</Box>
            </Box>
          ) : (
            '-'
          )}
        </EstimatedAllocationAmount>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <IFTypography
            variant="overline"
            lineHeight="18px"
            fontFamily="Roboto"
            commonColor="totalStakePoolTextTimeLine"
          >
            {t('Total staked in this pool ')}
          </IFTypography>
          <IFTypography
            variant="overline"
            lineHeight="18px"
            fontFamily="Roboto"
            fontWeight="bold"
            commonColor="totalStakePoolTextTimeLine"
          >
            {totalStakedStr}
          </IFTypography>
        </Box>
      </EstimatedAllocationArea>
    )
  }, [
    t,
    hasParticipated,
    saleTokenImage,
    estimatedAllocationStr,
    totalStakedStr,
    isDark,
    isSubcriptionEnded,
    isPurchaseable,
  ])

  const { brand } = useContext(CompanyContext)
  const isRonin = brand === Brands.RONIN

  const unstakeWidget = useMemo(() => {
    if (!hasStaked) return null
    return (
      <UnstakeArea isSubcriptionEnded={isSubcriptionEnded}>
        <Box letterSpacing="0.5px" display="flex" justifyContent="space-between">
          <IFTypography variant="body1" lineHeight="20px" fontWeight="bold">
            {t('Staked %token%', { token: sale.stakingToken.symbol })}
          </IFTypography>
          <IFTypography variant="body1" lineHeight="20px" fontWeight="bold">
            {userData?.userStakedOnCurrentChain !== null && userData?.userStakedOnCurrentChain !== undefined
              ? stakingTokenOnChainStr
              : userStakeAmountStr}
          </IFTypography>
        </Box>
        <UnstakeButton isRonin={isRonin} onClick={onPresentUnstakeModal}>
          <IFTypography variant="button" lineHeight="14px" fontWeight="bold">
            {t('Unstake')}
          </IFTypography>
        </UnstakeButton>
      </UnstakeArea>
    )
  }, [t, hasStaked, sale, userStakeAmountStr, isSubcriptionEnded, onPresentUnstakeModal, isRonin, userData])

  if (!isSubcriptionStarted) return null
  if (estimationAllocationWidget == null && unstakeWidget == null) return null
  return (
    <Box marginTop="10px">
      {estimationAllocationWidget}
      {unstakeWidget}
    </Box>
  )
}

export default TimeLineStakeWidget

import { Box } from '@mui/material'
import BigNumber from 'bignumber.js'
import { ClosedDark, ClosedLight } from 'componentsV2/Icons/Sale/Closed'
import IFTypography from 'componentsV2/IFTypography/IFTypography'
import { Chain } from 'config/constants/types'
import { useTranslation } from 'contexts/Localization'
import React, { useMemo } from 'react'
import styled, { useTheme } from 'styled-components'
import { BuybackInfo } from 'state/v2_types'
import BuybackSection from 'componentsV2/BuybackSection'

import SaleDetailCard from '../SaleDetailCard'

const ChildWrapper = styled.div`
  margin: 20px 30px 0;
  width: calc(100% - 30px);
`
const ClaimedShareWrapper = styled.div`
  padding: 20px;
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.colorsV2?.light}`};
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  width: 100%;
`

interface ClaimCardProps {
  tokenIcon: string
  claimedShare: number | BigNumber
  totalSales: number | BigNumber
  participants: number
  chainId?: Chain
  buybacks?: BuybackInfo[]
  saleAddress?: string
  totalPurchased?: number | BigNumber
}

const ClaimEndCard: React.FC<ClaimCardProps> = ({
  tokenIcon,
  claimedShare,
  participants,
  totalSales,
  chainId,
  buybacks,
  saleAddress,
  totalPurchased,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  // OMNI OVERDRIVE
  const isAllChains = chainId === 165 || chainId === 59140 || chainId === 421613

  const imageInfo = useMemo(() => {
    return {
      image: theme.isDark ? <ClosedDark /> : <ClosedLight />,
    }
  }, [theme])

  const child = useMemo(() => {
    const amount = new BigNumber(claimedShare).decimalPlaces(2).toNumber().toLocaleString()
    const totalSalesAmount = new BigNumber(totalSales)
    const totalSalesAmountStr = totalSalesAmount.isFinite()
      ? new BigNumber(totalSales).decimalPlaces(2).toNumber().toLocaleString()
      : ''
    return (
      <ChildWrapper>
        {parseInt(amount) > 0 && (
          <Box display="flex" justifyContent="center">
            <ClaimedShareWrapper>
              <IFTypography ifcolor="textSecondary" variant="button" fontWeight="regular">
                {t('Claimed Share')} {isAllChains && '(all chains included)'}
              </IFTypography>
              <Box display="flex" alignContent="center">
                <Box marginRight="10px">
                  <img src={tokenIcon} alt="token_icon" width="36px" />
                </Box>
                <IFTypography ifcolor="text" variant="h2" fontWeight="regular">
                  {amount}
                </IFTypography>
              </Box>
            </ClaimedShareWrapper>
          </Box>
        )}
        <Box display="flex" justifyContent="center">
          <Box marginTop="20px" display="flex" flexDirection="column" width="calc(100% - 30px)" maxWidth="300px">
            <Box display="flex" justifyContent="space-between">
              <IFTypography ifcolor="text" variant="button" fontFamily="Roboto" fontWeight="regular">
                {t('Participants')}
              </IFTypography>
              <IFTypography ifcolor="text" variant="button" fontFamily="Roboto" fontWeight="bold">
                {participants?.toLocaleString()}
              </IFTypography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <IFTypography ifcolor="text" variant="button" fontFamily="Roboto" fontWeight="regular">
                {t('Total Sales')}
              </IFTypography>
              <IFTypography ifcolor="text" variant="button" fontFamily="Roboto" fontWeight="bold">
                {totalSalesAmountStr}
              </IFTypography>
            </Box>
          </Box>
        </Box>
      </ChildWrapper>
    )
  }, [tokenIcon, claimedShare, t, participants, totalSales])

  const footer = useMemo(() => {
    return <BuybackSection buybacks={buybacks} saleAddress={saleAddress} totalPurchased={totalPurchased} />
  }, [buybacks])

  return (
    <SaleDetailCard
      requireKYC={false}
      targetNetworkID={chainId}
      footer={footer}
      imageInfo={imageInfo}
      title={parseInt(claimedShare.toString()) > 0 ? t('Thank you for your participation.') : t('The sale has ended.')}
      checkWalletConnection={false}
    >
      {child}
    </SaleDetailCard>
  )
}

export default ClaimEndCard

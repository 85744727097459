export const LAUNCHPAD_TABS_MENU = [
  {
    name: 'explore',
    title: 'Explore',
    link: '/explore',
  },
  {
    name: 'manage',
    title: 'Manage',
    link: '/manage',
  },
  { name: 'vesting', title: 'Vesting', link: '/vesting' },
]

export const SWAP_TABS_MENU = [
  {
    name: 'swap',
    title: 'Swap',
    link: '/swap',
  },
  {
    name: 'liquidity',
    title: 'Liquidity',
    link: '/liquidity',
  },
  {
    name: 'rewards',
    title: 'Rewards',
    link: '/rewards',
  },
].filter((val) => val)

export default null

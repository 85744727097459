import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const IF: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 27 28" {...props} width="38px">
      <circle cx="13.4717" cy="13" r="13" fill="#050A5A" />
      <path
        d="M9.18792 9.86806C10.4058 9.86806 11.3932 8.88558 11.3932 7.67362C11.3932 6.46167 10.4058 5.47919 9.18792 5.47919C7.96999 5.47919 6.98267 6.46167 6.98267 7.67362C6.98267 8.88558 7.96999 9.86806 9.18792 9.86806Z"
        fill="white"
      />
      <path
        d="M19.2879 9.8681H16.2337C15.8588 9.8681 15.55 9.56088 15.55 9.18783V6.14854C15.55 5.77548 15.8588 5.46826 16.2337 5.46826H19.2879C19.6628 5.46826 19.9716 5.77548 19.9716 6.14854V9.18783C19.9716 9.57185 19.6628 9.8681 19.2879 9.8681Z"
        fill="#00FFB9"
      />
      <path
        d="M11.3932 11.743H11.2609H7.90891C7.39068 11.743 6.97168 12.16 6.97168 12.6757V16.1429V19.5991C6.97168 20.1148 7.39068 20.5318 7.90891 20.5318H10.456C10.9742 20.5318 11.3932 20.1148 11.3932 19.5991V17.0755C11.3932 16.5598 11.8122 16.1429 12.3304 16.1429H14.7452C15.2634 16.1429 15.6824 15.7259 15.6824 15.2103V12.6757C15.6824 12.16 15.2634 11.743 14.7452 11.743H11.3932Z"
        fill="#00DAFF"
      />
    </Svg>
  )
}

export default IF

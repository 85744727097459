import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="21" height="20" viewBox="0 0 21 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.58495 7.41666C6.87191 7.25094 7.23887 7.34922 7.40459 7.63618C8.75047 9.96668 11.4165 10.2743 12.5957 10.1199C12.9242 10.0768 13.2255 10.3083 13.2685 10.6369C13.3115 10.9655 13.0801 11.2667 12.7515 11.3097C11.3375 11.4949 8.05766 11.1665 6.36543 8.2363C6.19971 7.94934 6.298 7.58237 6.58495 7.41666Z"
        fill="#A6A8AA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.46451 2.99986C5.39251 2.61169 2.58749 4.78737 2.19932 7.85937C1.91619 10.1001 2.99694 12.2 4.79732 13.3228C4.98732 13.4413 5.1853 13.5489 5.39048 13.6445C5.69081 13.7846 5.82076 14.1415 5.68073 14.4419C5.54071 14.7422 5.18372 14.8722 4.88339 14.7321C4.63389 14.6158 4.39322 14.485 4.16231 14.341C1.97937 12.9796 0.664802 10.4312 1.00879 7.70894C1.48004 3.97942 4.88543 1.33808 8.61494 1.80933C8.81664 1.83482 9.0153 1.86892 9.21057 1.91125C9.58272 1.99191 9.94235 2.10239 10.2871 2.23986C10.5949 2.36259 10.745 2.71161 10.6222 3.01942C10.4995 3.32722 10.1505 3.47726 9.84269 3.35453C9.55918 3.24148 9.26316 3.15051 8.95637 3.08401C8.79537 3.04911 8.63133 3.02094 8.46451 2.99986Z"
        fill="#A6A8AA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3441 5.22144C12.0636 5.22144 10.8848 5.65002 9.94116 6.37187C9.67796 6.5732 9.30139 6.52305 9.10006 6.25985C8.89873 5.99665 8.94888 5.62008 9.21208 5.41875C10.3578 4.54238 11.791 4.02144 13.3441 4.02144C17.1033 4.02144 20.1507 7.06885 20.1507 10.828C20.1507 14.5872 17.1033 17.6346 13.3441 17.6346C10.4012 17.6346 7.89592 15.7672 6.94572 13.1546C6.83246 12.8432 6.99309 12.499 7.30451 12.3857C7.61592 12.2724 7.96019 12.4331 8.07345 12.7445C8.85671 14.898 10.9217 16.4346 13.3441 16.4346C16.4405 16.4346 18.9507 13.9244 18.9507 10.828C18.9507 7.73159 16.4405 5.22144 13.3441 5.22144Z"
        fill="#A6A8AA"
      />
      <path
        d="M12.045 10.0625L12.1143 11.611L12.1408 12.203C12.1621 12.68 12.705 12.9422 13.0918 12.6624L15.1094 11.2031C15.459 10.9502 15.4354 10.422 15.0646 10.2013L12.9248 8.92804C12.5145 8.68389 11.9972 8.99351 12.0186 9.47047L12.045 10.0625Z"
        fill="#A6A8AA"
      />
    </Svg>
  )
}

export default Icon

import { createPublicClient, http } from 'viem'
import * as chains from 'wagmi/chains'
import networkParams from 'config/constants/networkParams'

import { chainIds } from './web3React'

function getChain(id: number) {
  for (const chain of Object.values(chains)) {
    if ('id' in chain) {
      if (chain.id === id) {
        return chain
      }
    }
  }
}

export const simpleRpcProviders = chainIds.reduce((acc, val) => {
  let chain = getChain(val as number)
  if (!chain) {
    const chainItem = networkParams[val as number]
    chain = chainItem[0]
  }

  acc[val] = createPublicClient({
    chain,
    transport: http(chain?.rpcUrls?.default?.[0] || chain?.rpcUrls[0]),
  })
  return acc
}, {})

export default null

import React from 'react'
import { Percent } from 'swap-sdk/entities/fractions/percent'
import { Flex, WarningIcon } from 'uikit'
import { warningSeverity } from 'utils/prices'
import { ONE_BIPS } from '../../../config/constants'
import { ErrorText } from './styleds'

/**
 * Formatted version of price impact text with warning colors
 */
export default function FormattedPriceImpact({ priceImpact }: { priceImpact?: Percent }) {
  const severity = warningSeverity(priceImpact)
  return (
    <ErrorText fontSize="14px" severity={severity}>
      <Flex flexDirection="row">
        {severity > 2 && (
          <Flex mr="6px">
            <WarningIcon color="currentColor" width="20px" />
          </Flex>
        )}
        {priceImpact ? (priceImpact.lessThan(ONE_BIPS) ? '<0.01%' : `${priceImpact.toFixed(2)}%`) : '-'}
      </Flex>
    </ErrorText>
  )
}

import BigNumber from 'bignumber.js'
import { useSelector } from 'react-redux'
import { State } from 'state/types'
import { FarmConfig } from 'state/v2_types'
import { convertFromWei } from 'utils/formatBalance'

export const useFarmsData = (): FarmConfig[] => {
  const farmsList = useSelector((state: State) => state.farms.data)
  return farmsList.map((farm) => ({
    ...farm,
    startTime: new Date(farm.startTime),
    endTime: new Date(farm.endTime),
    lpTokenBalanceMC: new BigNumber(farm.lpTokenBalanceMC),
    rewardPerSecond: new BigNumber(farm.rewardPerSecond),
  }))
}

export const useFarmsDataById = (id: string): FarmConfig | null => {
  const farmsList = useSelector((state: State) => state.farms.data)
  const farm = farmsList.find((val) => val.id === id)
  if (farm) {
    return {
      ...farm,
      startTime: new Date(farm.startTime),
      endTime: new Date(farm.endTime),
      lpTokenBalanceMC: new BigNumber(farm.lpTokenBalanceMC),
      rewardPerSecond: new BigNumber(farm.rewardPerSecond),
    }
  }

  return null
}

export const useFarmDataFetched = (): boolean => {
  return useSelector((state: State) => state.farms.isFetched)
}

export const useFarmUserDataFetched = (): boolean => {
  return useSelector((state: State) => state.farms.isUserDataFetched)
}

export const useFarmUserById = (id) => {
  const farmsUserMap = useSelector((state: State) => state.farms.userData)
  const farmUser = farmsUserMap[id]

  return {
    tokenBalance: farmUser?.stakingTokenBalance
      ? convertFromWei(new BigNumber(farmUser.stakingTokenBalance))
      : new BigNumber(0),
    stakedBalance: farmUser?.stakedTokenBalance
      ? convertFromWei(new BigNumber(farmUser.stakedTokenBalance))
      : new BigNumber(0),
    earnings: farmUser?.earnings ? convertFromWei(new BigNumber(farmUser.earnings)) : new BigNumber(0),
    isEnabled: farmUser?.isEnabled ?? false,
  }
}

export const useFarmUserMap = () => {
  const farmsUserMap = useSelector((state: State) => state.farms.userData)

  return Object.keys(farmsUserMap).reduce((acc, key) => {
    const farmUser = farmsUserMap[key]
    acc[key] = {
      tokenBalance: farmUser.stakingTokenBalance
        ? convertFromWei(new BigNumber(farmUser.stakingTokenBalance))
        : new BigNumber(0),
      stakedBalance: farmUser.stakedTokenBalance
        ? convertFromWei(new BigNumber(farmUser.stakedTokenBalance))
        : new BigNumber(0),
      earnings: farmUser.earnings ? convertFromWei(new BigNumber(farmUser.earnings)) : new BigNumber(0),
      isEnabled: farmUser.isEnabled,
    }
    return acc
  }, {})
}

import { DefaultTheme } from 'styled-components'
import { dark as darkAlert } from '../components/Alert/theme'
import { dark as darkCard } from '../components/Card/theme'
import { dark as darkRadio } from '../components/Radio/theme'
import { dark as darkToggle } from '../components/Toggle/theme'
import { carv as darkNav } from '../widgets/Menu/theme'
import { carv as darkModal } from '../widgets/Modal/theme'
import { carv as darkFooter } from '../widgets/Footer/theme'
import { carv as darkTable } from 'componentsV2/IFSortableTable/theme'
import { carv as darkAccordion } from 'componentsV2/IFAccordion/theme'
import { carv as carvSale } from 'views/Sale/theme'
import { carv as carvCommon } from './common'
import base from './base'
import { darkColors } from './colors'
import { carvColorsV2 } from './colorsV2'

const darkTheme: DefaultTheme = {
  ...base,
  isDark: true,
  useColorsOnly: false,
  alert: darkAlert,
  colors: darkColors,
  footer: darkFooter,
  accordion: darkAccordion,
  card: darkCard,
  sale: carvSale,
  toggle: darkToggle,
  common: carvCommon,
  table: darkTable,
  nav: darkNav,
  colorsV2: carvColorsV2,
  modal: darkModal,
  radio: darkRadio,
}

export default darkTheme

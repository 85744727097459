import { useEffect, useState } from 'react'
import useRefresh from './useRefresh'

const API_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/api/backend-service/country/block`

export const useBlockedCountries = () => {
  const { fiveMinutesRefresh } = useRefresh()
  const [blockedCountries, setBlockedCountries] = useState([])

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const result = await (
          await fetch(`${API_ENDPOINT}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          })
        ).json()

        setBlockedCountries(result.data)
      } catch (e) {
        console.error(`Error fetching blocked`)
      }
    }
    fetchPrices()
  }, [fiveMinutesRefresh])

  return blockedCountries
}

export default useBlockedCountries

import { scales, variants } from './types'
import { PancakeTheme } from '../../theme'

export const scaleVariants = {
  [scales.LG]: {
    height: '44px',
    padding: '0 20px',
    borderRadius: '22px',
  },
  [scales.MD]: {
    height: '38px',
    padding: '0 20px',
    borderRadius: '19px',
  },
  [scales.SMMD]: {
    height: '34px',
    padding: '0 24px',
    borderRadius: '50px',
  },
  [scales.SM]: {
    height: '26px',
    padding: '0 15px',
    borderRadius: '13px',
    fontSize: '14px',
  },
  [scales.XS]: {
    height: '20px',
    fontSize: '12px',
    padding: '0 8px',
    borderRadius: '10px',
  },
}

export const styleVariants = (theme: PancakeTheme) => {
  return {
    [variants.PRIMARY]: {
      background: theme.colorsV2?.button.primaryBg,
      color: theme.colorsV2?.button.primaryText,
    },
    [variants.SECONDARY]: {
      background: theme.colorsV2?.button.secondaryBg,
      color: theme.colorsV2?.button.primaryText,
      ':disabled': {
        background: theme.colorsV2?.button.disabledBg,
        color: theme.colorsV2?.button.disabledText,
      },
    },
    [variants.THIRDLY]: {
      background: theme.isDark ? theme.colorsV2?.button.secondaryBg : theme.colorsV2?.textThirdly,
      color: theme.colorsV2?.button.primaryText,
    },
    [variants.MAIN2]: {
      background: theme.isDark ? theme.colorsV2.main2 : theme.colorsV2?.textThirdly,
      color: theme.colorsV2?.button.primaryText,
    },
    [variants.TERTIARY]: {
      background: theme.colorsV2?.button.tertiaryBg,
      color: theme.colorsV2?.button.primaryText,
    },
    [variants.DARK2]: {
      background: theme.isDark ? theme.colorsV2?.light : theme.colorsV2?.dark2,
      color: theme.isDark ? theme.colorsV2?.text : theme.colorsV2?.textSecondary,
    },
    [variants.QUATERNARY]: {
      background: theme.colorsV2?.button.quaternaryBg,
      color: theme.colorsV2?.button.quaternaryText,
    },
    [variants.QUINARY]: {
      border: `1px solid ${theme.colorsV2?.textThirdly}`,
      background: 'transparent',
      color: theme.colorsV2?.textThirdly,
      ':disabled': {
        border: `1px solid ${theme.isDark ? theme.colorsV2.textDisabled : theme.colorsV2?.textThirdly}`,
        opacity: '0.4',
        color: `${theme.isDark ? theme.colorsV2.textDisabled : theme.colorsV2?.textThirdly}`,
        background: 'transparent',
      },
    },
    [variants.SENARY]: {
      background: theme.isDark ? theme.colorsV2.textDisabled : theme.colorsV2?.textThirdly,
      color: '#fff',
    },
    [variants.SUBTLE]: {
      background: 'textSubtle',
      color: theme.colorsV2?.textSecondary,
    },
    [variants.DANGER]: {
      background: theme.colorsV2?.button.dangerBg,
      color: theme.colorsV2?.button.dangerText,
    },
    [variants.SUCCESS]: {
      background: theme.colorsV2?.success,
      color: theme.colorsV2?.button.primaryText,
    },
    [variants.TEXT]: {
      background: 'transparent',
      fontSize: '14px',
      fontWeight: 400,
      textDecoration: 'underline',
      color: theme.colorsV2?.button.linkText,
      ':disabled': {
        background: 'transparent',
        color: theme.colorsV2?.textDisabled,
      },
    },
    [variants.TOAST_ACTION]: {
      background: 'transparent',
      color: 'text',
      boxShadow: 'none',
      padding: 0,
    },
    [variants.RADIO_SELECTED]: {
      background: theme.colorsV2?.button.radioSelectedBg,
      color: theme.colorsV2?.button.radioSelectedText,
    },
    [variants.RADIO_OPTION]: {
      background: theme.colorsV2?.button.radioOptionBg,
      color: theme.colorsV2?.button.radioOptionText,
    },
  }
}

import React from 'react'
import styled from 'styled-components'
import { DropdownProps, PositionProps, Position } from './types'

const getLeft = ({ position }: PositionProps) => {
  if (position === 'bottom-left') {
    return '0%'
  }

  if (position === 'top-right') {
    return '100%'
  }
  return '50%'
}

const getBottom = ({ position }: PositionProps) => {
  if (position === 'top' || position === 'top-right') {
    return '100%'
  }
  return 'auto'
}

const DropdownContent = styled.div<{ position: Position; width: string }>`
  width: ${({ width }) => width ?? 'max-content'};
  display: none;
  flex-direction: column;
  position: absolute;
  transform: translate(-50%, 0);
  left: ${getLeft};
  bottom: ${getBottom};
  background-color: ${({ theme }) => (theme.isDark ? theme.colorsV2?.main : theme.colorsV2?.dark2)};
  box-shadow: ${({ theme }) => theme.shadows?.level10};
  padding: 8px 0;
  max-height: 500px;
  overflow-y: auto;
  z-index: ${({ theme }) => theme.zIndices?.dropdown};
  border-radius: 20px;
  min-width: 80px;
`

const Container = styled.div`
  position: relative;
  &:hover ${DropdownContent}, &:focus-within ${DropdownContent} {
    display: flex;
  }
`

const Dropdown: React.FC<DropdownProps> = ({ className, target, position = 'bottom', width, children }) => {
  return (
    <Container className={className}>
      {target}
      <DropdownContent position={position} width={width}>
        {children}
      </DropdownContent>
    </Container>
  )
}

export default Dropdown

import React from 'react'
import Svg from '../../../components/Svg/Svg'

const KycSuccess: React.FC = ({ ...props }) => {
  return (
    <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M9.2447 0.870464C9.6434 0.410966 10.3566 0.410966 10.7553 0.870464L11.9694 2.26966C12.2121 2.54943 12.5897 2.6721 12.9505 2.58845L14.7552 2.17009C15.3478 2.03271 15.9248 2.45191 15.9773 3.05801L16.137 4.90359C16.169 5.27263 16.4023 5.59377 16.7434 5.7382L18.4493 6.46048C19.0095 6.69768 19.2299 7.37597 18.9161 7.89715L17.9606 9.48419C17.7695 9.80152 17.7695 10.1985 17.9606 10.5158L18.9161 12.1028C19.2299 12.624 19.0095 13.3023 18.4493 13.5395L16.7434 14.2618C16.4023 14.4062 16.169 14.7274 16.137 15.0964L15.9773 16.942C15.9248 17.5481 15.3478 17.9673 14.7552 17.8299L12.9505 17.4116C12.5897 17.3279 12.2121 17.4506 11.9694 17.7303L10.7553 19.1295C10.3566 19.589 9.6434 19.589 9.2447 19.1295L8.03062 17.7303C7.78786 17.4506 7.41033 17.3279 7.04948 17.4116L5.24485 17.8299C4.6522 17.9673 4.07522 17.5481 4.02274 16.942L3.86295 15.0964C3.831 14.7274 3.59768 14.4062 3.25658 14.2618L1.5507 13.5395C0.990481 13.3023 0.770093 12.624 1.08389 12.1028L2.03943 10.5158C2.2305 10.1985 2.2305 9.80152 2.03943 9.48419L1.08389 7.89715C0.770093 7.37597 0.990481 6.69768 1.5507 6.46048L3.25658 5.7382C3.59768 5.59377 3.831 5.27263 3.86295 4.9036L4.02274 3.05801C4.07522 2.45191 4.6522 2.03271 5.24485 2.17009L7.04948 2.58845C7.41033 2.6721 7.78786 2.54943 8.03062 2.26966L9.2447 0.870464Z"
        fill="#D900FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7119 6.29913C15.096 6.69796 15.096 7.34459 14.7119 7.74343L8.97421 13.7009C8.78266 13.8998 8.52058 14.0078 8.2498 13.9996C7.97902 13.9913 7.72351 13.8674 7.54354 13.6572L5.24846 10.9764C4.88756 10.5548 4.92413 9.90929 5.33014 9.53456C5.73616 9.15984 6.35787 9.19781 6.71877 9.61937L8.32081 11.4907L13.3209 6.29912C13.705 5.90029 14.3278 5.90029 14.7119 6.29913Z"
        fill="#FFE000"
      />
    </Svg>
  )
}

export default React.memo(KycSuccess)

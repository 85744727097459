import { Chain } from 'config/constants/types'
import { Currency, ETHER } from 'swap-sdk/entities/currency'
import { CurrencyAmount } from 'swap-sdk/entities/fractions/currencyAmount'
import { TokenAmount } from 'swap-sdk/entities/fractions/tokenAmount'
import { TokenClass } from 'swap-sdk/entities/token'
import getGasToken from './getGasToken'

export function wrappedCurrency(currency: Currency | undefined, chainId: Chain | undefined): TokenClass | undefined {
  return chainId && currency?.isNative ? getGasToken(chainId) : currency instanceof TokenClass ? currency : undefined
}

export function wrappedCurrencyAmount(
  currencyAmount: CurrencyAmount | undefined,
  chainId: Chain | undefined,
): TokenAmount | undefined {
  const token = currencyAmount && chainId ? wrappedCurrency(currencyAmount.currency, chainId) : undefined
  return token && currencyAmount ? new TokenAmount(token, currencyAmount.raw) : undefined
}

export function unwrappedToken(token: TokenClass): Currency {
  if (token.name === 'WeHMND' || token.symbol === 'WeHMND') return token
  if (token.equals(getGasToken(token.chainId))) return ETHER[token.chainId]
  return token
}

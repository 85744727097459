import React, { useCallback } from 'react'
import { Box } from '@mui/material'
import { CliffVestableIDO, PurchasableIDO, SubscribeableIDO } from 'state/v2_types'
import { ClaimEndCard, ClaimStartCard } from 'componentsV2/Card'
import { convertFromWei } from 'utils/formatBalance'
import BigNumber from 'bignumber.js'
import { useIsSaleUserDataFetched, useSaleUserData } from 'state/idos/hooks'
import { isPurchaseableIDO, isSubscribeableIDO } from 'state/idos/saleUtil'
import { useAccount } from 'wagmi'
import LoadingCard from 'componentsV2/Card/LoadingCard/LoadingCard'
import CliffVestingClaimCard from 'componentsV2/Card/CliffVestingClaimCard'
import { useGetAllocation, useGetClaimableNumber, useGetHasOptedIn } from 'hooks/useIFASale'

const findNextCliffPeriod = (cliffPeriod) => {
  if (!cliffPeriod) return null
  const now = new Date()
  let i = 0
  while (i < cliffPeriod.length) {
    if (now.getTime() < new Date(cliffPeriod[i].claimTime).getTime()) {
      return i
    }
    i++
  }

  return null
}

const CliffVestClaiming: React.FC<{ sale: CliffVestableIDO }> = ({ sale }) => {
  const userData = useSaleUserData(sale.id)
  const { address: account } = useAccount()
  const isSaleUserDataFetched = useIsSaleUserDataFetched()
  const {
    cliffVestInfo,
    token,
    saleAddress,
    chainId,
    saleChainId = chainId,
    saleAmount,
    whitelistedUrl,
    stepRequirement,
    saleId,
    buybackInfo,
  } = sale

  const { allo: userAllocationInWei, isLoading } = useGetAllocation(sale.id, sale.isPrivate)

  const hasOptin = useGetHasOptedIn(saleAddress)
  const claimableNumber = useGetClaimableNumber(saleAddress)

  const getClaimingState = useCallback(() => {
    const now = new Date()

    const { purchasePeriod } = sale as any as PurchasableIDO

    let purchaserCount = purchasePeriod?.purchaserCount || 0
    let finalSalePrice = 0
    const nextCliffPeriodIndex = findNextCliffPeriod(cliffVestInfo?.cliffPeriod)
    const nextCliffPeriod = cliffVestInfo?.cliffPeriod?.[nextCliffPeriodIndex]

    const totalPctClaimed =
      cliffVestInfo?.cliffPeriod?.reduce((sum, item, index) => {
        if (index < parseInt(claimableNumber.toString())) {
          sum += item.pct
        }

        return sum
      }, 0) || 0

    const startTime = cliffVestInfo?.cliffPeriod?.[0]?.claimTime
    const endTime = cliffVestInfo?.cliffPeriod?.[cliffVestInfo?.cliffPeriod?.length - 1]?.claimTime

    let finalTotalPaymentReceived = saleAmount
    const { symbol, image, address, decimals } = token

    const paymentReceivedInWei = (userData && userData.paymentReceivedInWei) || new BigNumber(0)
    const claimableTokenInWei = userData?.currentClaimableTokenInWei
    const claimableToken = convertFromWei(claimableTokenInWei || 0)

    const cannotClaim = parseInt(claimableNumber.toString()) <= nextCliffPeriodIndex && hasOptin
    const cannotClaimNextCliff = parseInt(claimableNumber.toString()) >= nextCliffPeriodIndex && hasOptin

    const userAllocation = userAllocationInWei && convertFromWei(new BigNumber(userAllocationInWei), decimals)
    let fullDisplayshares = userAllocation || userData?.userTokenAllocation || new BigNumber(0)
    let totalPurchased = userAllocation || userData?.userTokenAllocation || new BigNumber(0)

    if (isPurchaseableIDO(sale as any as PurchasableIDO)) {
      const { paymentToken, purchasePeriod } = sale as any as PurchasableIDO
      const { salePrice, totalPaymentReceived } = purchasePeriod
      finalTotalPaymentReceived = totalPaymentReceived
      finalSalePrice = salePrice
      fullDisplayshares = convertFromWei(new BigNumber(paymentReceivedInWei), paymentToken.decimals).dividedBy(
        finalSalePrice,
      )
      totalPurchased = convertFromWei(new BigNumber(paymentReceivedInWei), paymentToken.decimals).dividedBy(
        finalSalePrice,
      )

      if (hasOptin) {
        fullDisplayshares = fullDisplayshares.multipliedBy(totalPctClaimed).dividedBy(100)
      }
    }

    if (isSubscribeableIDO(sale as any as SubscribeableIDO)) {
      const { subscribePeriod } = sale as any as SubscribeableIDO
      const { numTrackStakers = 0 } = subscribePeriod
      purchaserCount = Math.max(purchaserCount, numTrackStakers)
    }

    if (fullDisplayshares.isGreaterThan(0)) {
      if (startTime && new Date(startTime) > now) {
        return (
          <ClaimStartCard
            stepRequirement={stepRequirement}
            claimDate={startTime}
            tokenIcon={image}
            tokenSymbol={symbol}
            tokenAddress={address}
            tokenDecimals={decimals}
            purchasedAmount={fullDisplayshares}
            totalPurchased={totalPurchased}
            chainId={saleChainId}
            saleAddress={saleAddress}
            buybacks={buybackInfo}
          />
        )
      }
      // in between vesting period or still has claimable token
      if (
        (!cannotClaim && startTime && endTime && new Date(startTime) < now && new Date(endTime) > now) ||
        claimableToken.isGreaterThan(0)
      ) {
        return (
          <CliffVestingClaimCard
            stepRequirement={stepRequirement}
            tokenIcon={image}
            tokenSymbol={symbol}
            tokenAddress={address}
            tokenDecimals={decimals}
            purchasedAmount={fullDisplayshares}
            nextClaimableAmount={fullDisplayshares.times((nextCliffPeriod?.pct || 0) / 100)}
            saleAddress={saleAddress}
            chainId={saleChainId}
            salePrice={finalSalePrice}
            totalPurchased={totalPurchased}
            whitelistedUrl={whitelistedUrl}
            nextClaimTime={new Date(nextCliffPeriod?.claimTime)}
            claimableToken={claimableToken}
            saleId={saleId}
            userAllocation={userAllocationInWei}
            buybacks={buybackInfo}
            cannotClaimNext={cannotClaimNextCliff}
          />
        )
      }
    }

    return (
      <ClaimEndCard
        tokenIcon={image}
        claimedShare={fullDisplayshares}
        participants={purchaserCount}
        totalSales={finalTotalPaymentReceived}
        totalPurchased={totalPurchased}
        chainId={chainId}
        buybacks={buybackInfo}
        saleAddress={saleAddress}
      />
    )
  }, [userData, sale, userAllocationInWei])

  if ((account && !isSaleUserDataFetched) || isLoading) {
    return <LoadingCard />
  }
  return (
    <>
      <Box>{getClaimingState()}</Box>
    </>
  )
}

export default CliffVestClaiming

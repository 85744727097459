import { CircularProgress } from '@mui/material'
import React from 'react'
import useTheme from 'hooks/useTheme'
import IFTypography from 'componentsV2/IFTypography'
import { useTranslation } from 'contexts/Localization'
import { Flex, RectangleWarning, LinkExternal } from 'uikit'
import useFetchBabt from 'hooks/useFetchBabt'

const Bab: React.FC = () => {
  const { theme } = useTheme()
  const { t } = useTranslation()
  const babDetected = useFetchBabt()

  const Detected = (
    <Flex margin="0px auto 40px" flexDirection="column">
      <Flex justifyContent="center" alignItems="center" paddingBottom="50px">
        <img
          src={theme.isDark ? '/images/Icon-Checked-Dark.svg' : '/images/Icon-Checked-Light.svg'}
          alt="checked"
          width="85px"
          height="85px"
        />
      </Flex>
      <IFTypography fontSize="24px" lineHeight="30px" align="center" paddingBottom="30px" fontWeight="bold">
        {t('BAB Token Has Been Connected')}
      </IFTypography>
      <IFTypography fontSize="14px" lineHeight="20px" align="center" paddingBottom="10px" color="textDisabled">
        {t('Your BAB Token (Binance Account Bound Token) has connected to the current wallet address. ')}
      </IFTypography>
    </Flex>
  )

  const NotDetected = (
    <Flex margin="0px auto 40px" flexDirection="column">
      <Flex justifyContent="center" alignItems="center" paddingBottom="50px">
        <RectangleWarning height="85px" width="85px" />
      </Flex>
      <IFTypography fontSize="24px" lineHeight="30px" align="center" paddingBottom="30px" fontWeight="bold">
        {t('Unable to Detect the BAB Token in Your Wallet')}
      </IFTypography>
      <IFTypography fontSize="14px" lineHeight="20px" align="center" paddingBottom="20px" color="textDisabled">
        {t('Please make sure you have minted the BAB Token (Binance Account Bound Token) to your wallet. ')}
      </IFTypography>
      <Flex alignItems="center" alignSelf="center">
        <LinkExternal
          small
          href="https://impossible.gitbook.io/impossible-finance-faq/start-here/get-started/verify-kyc-your-wallet"
          margin="5px 0px 10px 0px"
        >
          {t('How to proceed with Synaps or BAB Token')}
        </LinkExternal>
      </Flex>
    </Flex>
  )

  if (babDetected === undefined) {
    return (
      <Flex height="295px" alignItems="center">
        {' '}
        <Flex margin="auto" marginTop="60px">
          <CircularProgress color="primary" size="62px" />
        </Flex>
      </Flex>
    )
  }

  return <>{babDetected ? Detected : NotDetected}</>
}

export default Bab

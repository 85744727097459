import * as React from 'react'
import { Box } from '@mui/material'
import styled, { useTheme } from 'styled-components'
import format from 'date-fns/format'
import { BuybackInfo, BuybackType } from 'state/v2_types'
import IFTypography from 'componentsV2/IFTypography/IFTypography'
import ModalV2 from 'uikit/widgets/Modal/ModalV2'
import { Button, LinkExternal, useModalV2 } from 'uikit'
import { useGetHasOptedIn, useOptin } from 'hooks/useIFASale'
import useTranslation from 'contexts/Localization/useTranslation'
import BigNumber from 'bignumber.js'

const BuyBackBox = styled(Box)`
  border-radius: 20px;
  background: ${({ theme }) =>
    theme.isDark
      ? 'linear-gradient(135deg, rgba(96, 133, 204, 0.15) 0%, rgba(109, 53, 181, 0.38) 100%);'
      : 'linear-gradient(135deg, rgba(239, 239, 255, 0.62) 0%, rgba(235, 237, 255, 0.77) 100%)'};
  padding: 16px;
  margin-top: 16px;
`

const BuyBackBadge = styled(Box)`
  border-radius: 20px;
  padding: 4px 10px;
  background: linear-gradient(
    91deg,
    rgba(13, 189, 181, 0.8) 0%,
    rgba(129, 154, 224, 0.8) 50.52%,
    rgba(130, 111, 228, 0.8) 100%
  );
  width: fit-content;
`

const WarningBox = styled(Box)`
  border-radius: 5px;
  background: ${({ theme }) => theme.colorsV2.dark2};
`

const CalculateBox = styled(Box)`
  border-radius: 5px;
  background: ${({ theme }) => theme.colorsV2.main};
`

interface BuybackSectionProps {
  buybacks: BuybackInfo[]
  saleAddress: string
  totalPurchased?: number | BigNumber
}

const BuybackSection: React.FC<BuybackSectionProps> = ({ buybacks = [], saleAddress, totalPurchased = 0 }) => {
  const { onOptIn, isLoading } = useOptin({
    saleAddress,
  })
  const { t } = useTranslation()
  const theme = useTheme()
  const [_, onDismiss] = useModalV2({
    modal: <Box />,
  })
  const [onPresentDisclaimerModal] = useModalV2({
    modal: (
      <ModalV2
        bodyTitle={t('Exercise Buyback #2')}
        bodyImage={
          <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
            <path
              d="M46.5597 3.70689L75.8667 33.0139C79.464 36.6111 79.464 42.536 75.8667 46.1333L46.5597 75.4403C42.9625 79.0375 37.0375 79.0375 33.4403 75.4403L4.1333 46.1333C0.536049 42.536 0.536048 36.6111 4.1333 33.0139L33.4403 3.70689C37.1433 0.00384036 42.9625 0.109643 46.5597 3.70689Z"
              fill="url(#paint0_linear_31578_117635)"
            />
            <path
              d="M36.2044 42.3011C36.2044 44.3823 37.8916 46.0695 39.9728 46.0695C42.0541 46.0695 43.7413 44.3824 43.7413 42.3011V24.2934C43.7413 22.2121 42.0541 20.5249 39.9728 20.5249C37.8916 20.5249 36.2044 22.2121 36.2044 24.2933V42.3011ZM39.9728 58.6221C42.9876 58.6221 45.1948 56.6195 45.1948 53.9332C45.1948 51.4911 43.0414 49.1955 39.9728 49.1955C37.0119 49.1955 34.8047 51.4422 34.8047 53.9332C34.8047 56.6195 37.1196 58.6221 39.9728 58.6221Z"
              fill="white"
            />
            <defs>
              <linearGradient
                id="paint0_linear_31578_117635"
                x1="40"
                y1="77.6708"
                x2="40"
                y2="-16.2734"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#ED696C" stopOpacity="0.53" />
                <stop offset="1" stopColor="#ED696C" />
              </linearGradient>
            </defs>
          </svg>
        }
        confirmStyle={{
          background: theme.colorsV2.button.secondaryBg,
        }}
        bodyImageWidth={36}
        bodyImageMargin="33px 0 33px 0"
        bodyPadding="0px 0 33px 0"
        onConfirm={() => {
          if (window.fathom) {
            window.fathom.trackEvent('Opt in exercise')
          }
          onOptIn()
          onDismiss()
          return
        }}
        onCancel={() => {
          if (window.fathom) {
            window.fathom.trackEvent('Opt in cancel')
          }
        }}
        showConfirm
        confirmText="Exercise Buyback"
        showCancel
      >
        <Box marginBottom={2}>
          <IFTypography fontSize="14px" ifcolor="text" textAlign="center">
            By exercising, you confirm to give up your locked tokens at the buyback price of{' '}
            <span style={{ fontWeight: 700 }}>0.075</span> USDT which may result in a loss depending on the market price
            (e.g. net loss at market price of <span style={{ fontWeight: 700 }}>0.086</span> USD on 31/10/2023)
            {/* You will be selling Bricken tokens at a price of <span style={{ fontWeight: 700 }}>0.075</span>, referred to
            as the &apos;buyback price point,&apos; which is lower than the current market price of{' '}
            <span style={{ fontWeight: 700 }}>0.085</span>. */}
          </IFTypography>
        </Box>
        <Box display="flex" justifyContent="center" marginY={3}>
          <IFTypography fontSize="14px" ifcolor="textSecondary" textAlign="center">
            OR
          </IFTypography>
        </Box>
        <Box marginBottom={2}>
          <IFTypography fontSize="14px" ifcolor="text" textAlign="center">
            <span style={{ fontWeight: 700 }}>HODL</span> and do not exercise the Buyback to get{' '}
            <span style={{ fontWeight: 700 }}>additional</span> BKN tokens at the end of the vesting period.
          </IFTypography>
        </Box>
        <WarningBox padding={4}>
          <CalculateBox padding={4} marginBottom={2}>
            <Box display="flex" justifyContent="space-between">
              <Box>
                <IFTypography fontSize="12px" ifcolor="textSecondary">
                  Your locked tokens
                </IFTypography>
              </Box>
              <Box>
                <IFTypography fontSize="12px" ifcolor="textSecondary">
                  {(parseFloat(totalPurchased.toString()) * 0.59).toFixed(2)} BKN
                </IFTypography>
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Box>
                <IFTypography fontSize="12px" ifcolor="textSecondary">
                  Bonus Top-Up x1.27 (airdrop)
                </IFTypography>
              </Box>
              <Box>
                <IFTypography fontSize="12px" ifcolor="textSecondary">
                  {(parseFloat(totalPurchased.toString()) * 0.59 * 1.27).toFixed(2)} BKN
                </IFTypography>
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" marginTop={2}>
              <Box>
                <IFTypography fontSize="12px" ifcolor="text" fontWeight={'bold'}>
                  Total BKN Tokens
                </IFTypography>
              </Box>
              <Box>
                <IFTypography fontSize="12px" ifcolor="text" fontWeight={'bold'}>
                  {(parseFloat(totalPurchased.toString()) * 0.59 * 2.27).toFixed(2)} BKN
                </IFTypography>
              </Box>
            </Box>
          </CalculateBox>
          <IFTypography fontSize="12px" ifcolor="text">
            If you exercise the Buyback, you will not be able to participate in the Bonus program.
          </IFTypography>
        </WarningBox>
        <Box display="flex" justifyContent="center" marginTop={3}>
          <IFTypography fontSize="12px" ifcolor="text">
            <LinkExternal href="https://blog.impossible.finance/updates-to-brickken-buy-back-protection-program/">
              BKN Bonus Top-Up & Buyback Program details
            </LinkExternal>
          </IFTypography>
        </Box>
      </ModalV2>
    ),
    options: {
      backdropBlurVal: 'blur(20px)',
      closeOnOverlayClick: false,
    },
  })

  const hasOptin = useGetHasOptedIn(saleAddress)
  const noOfBuybacks = buybacks?.length || 0

  if (noOfBuybacks === 0) {
    return null
  }

  return (
    <BuyBackBox>
      {buybacks.map((bb, index) => {
        const {
          type,
          buybackStartTime,
          buybackEndTime,
          buybackPrice,
          paymentToken,
          tradeAppName,
          buybackActive,
          buttonLink,
          buttonText,
          airdropDate,
        } = bb

        const isUnlocked = type === BuybackType.UNLOCKED

        const timeFormat = isUnlocked ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd HH:mm'

        const isBuybackActive =
          buybackActive &&
          parseFloat(totalPurchased.toString()) > 0 &&
          new Date().getTime() > new Date(buybackStartTime).getTime() &&
          new Date().getTime() < new Date(buybackEndTime).getTime()

        return (
          <Box marginBottom={index < noOfBuybacks - 1 && 4} key={type}>
            <BuyBackBadge display="flex" marginBottom="8px">
              <Box style={{ height: 12, width: 15 }} marginRight="5px">
                <img src="/images/buyback.svg" alt="bb" />
              </Box>
              <Box>
                <IFTypography fontSize="11px" color="#FFFFFF">
                  Buyback #{index + 1} ({type.charAt(0).toUpperCase() + type.slice(1)} tokens)
                </IFTypography>
              </Box>
            </BuyBackBadge>

            {hasOptin && !isUnlocked ? (
              <>
                <Box>
                  <IFTypography fontSize="12px" ifcolor="textSecondary">
                    You have opted in for this buyback
                  </IFTypography>
                </Box>
                {airdropDate?.length > 0 && (
                  <IFTypography fontSize="12px" ifcolor="textSecondary">
                    Airdrop Date: {format(new Date(airdropDate), 'yyyy-MM-dd HH:mm')}
                  </IFTypography>
                )}
                <IFTypography fontSize="12px" ifcolor="textSecondary">
                  Buyback price: {buybackPrice} {paymentToken}.
                </IFTypography>
              </>
            ) : (
              <>
                <Box>
                  <IFTypography fontSize="12px" ifcolor="textSecondary">
                    {isBuybackActive ? (
                      <>Buyback program is activated for the {type} tokens.</>
                    ) : (
                      <>
                        The buyback will activate for <span style={{ fontWeight: 700 }}>{type} tokens</span> when the
                        token’s market price falls below a specified buyback price.
                      </>
                    )}
                  </IFTypography>
                </Box>
                <ul
                  style={{
                    listStylePosition: 'outside',
                    paddingLeft: '12px',
                    marginTop: '4px',
                  }}
                >
                  <IFTypography fontSize="12px" ifcolor="textSecondary">
                    <li>
                      Buyback period: {format(new Date(buybackStartTime), timeFormat)} to{' '}
                      {format(new Date(buybackEndTime), timeFormat)}
                    </li>
                  </IFTypography>
                  <IFTypography fontSize="12px" ifcolor="textSecondary">
                    <li>
                      Buyback price: {buybackPrice} {paymentToken}.
                    </li>
                  </IFTypography>
                  <IFTypography fontSize="12px" ifcolor="textSecondary">
                    <li>
                      {isUnlocked ? (
                        `Trade on ${tradeAppName} `
                      ) : (
                        <>
                          The {paymentToken} will be{' '}
                          <span style={{ fontWeight: 700 }}>
                            airdropped within one week after the buy-back period ends.
                          </span>
                        </>
                      )}
                    </li>
                  </IFTypography>
                </ul>
              </>
            )}

            {isBuybackActive && (
              <Box display="flex" justifyContent="flex-end" marginTop={2}>
                {isUnlocked ? (
                  <Button scale="sm" onClick={() => window.open(buttonLink)}>
                    <IFTypography fontSize="14px" fontWeight="bold" style={{ color: '#FFFFFF' }}>
                      {buttonText}
                    </IFTypography>
                  </Button>
                ) : (
                  <Button
                    scale="sm"
                    onClick={() => {
                      if (window.fathom) {
                        window.fathom.trackEvent('Exercise buyback 2')
                      }
                      onPresentDisclaimerModal()
                    }}
                    disabled={isLoading || hasOptin}
                    style={{ opacity: isLoading || hasOptin ? 0.5 : 1 }}
                  >
                    <IFTypography
                      fontSize="14px"
                      fontWeight="bold"
                      style={{ color: !(isLoading || hasOptin) && '#FFFFFF' }}
                    >
                      {hasOptin ? 'Buyback Exercised' : `Exercise Buyback #${index + 1}`}
                    </IFTypography>
                  </Button>
                )}
              </Box>
            )}
          </Box>
        )
      })}
    </BuyBackBox>
  )
}

export default BuybackSection

import {
  darkColorsV2,
  beamColorsV2,
  lightColorsV2,
  carvColorsV2,
  particleColorsV2,
  campusColors,
  moonveilColors,
} from '../../theme/colorsV2'
import { ModalTheme } from './types'

const isIF = process.env.REACT_APP_WHITELABEL_BRAND === 'if'

export const light: ModalTheme = {
  background: lightColorsV2.main,
  boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.1);',
}

export const dark: ModalTheme = {
  background: !isIF ? beamColorsV2.main : darkColorsV2.main,
  boxShadow: '0px 4px 50px 0px rgba(0, 0, 0, 0.09);',
}

export const beam: ModalTheme = {
  background: beamColorsV2.main,
  boxShadow: '0px 4px 50px 0px rgba(0, 0, 0, 0.09);',
}

export const carv: ModalTheme = {
  background: carvColorsV2.main,
  boxShadow: '0px 4px 50px 0px rgba(0, 0, 0, 0.09);',
}

export const particle: ModalTheme = {
  background: particleColorsV2.main,
  delegationCardBg: particleColorsV2.main2,
  delegationDiscountBg: particleColorsV2.main3,
  boxShadow: '0px 4px 50px 0px rgba(0, 0, 0, 0.09);',
}

export const campus: ModalTheme = {
  background: campusColors.main,
  delegationCardBg: campusColors.main3,
  delegationDiscountBg: 'rgba(102, 102, 102, 0.45)',
  boxShadow: '0px 4px 50px 0px rgba(0, 0, 0, 0.09);',
}

export const moonveil: ModalTheme = {
  background: moonveilColors.main,
  delegationCardBg: moonveilColors.main3,
  delegationDiscountBg: 'rgba(102, 102, 102, 0.45)',
  boxShadow: '0px 4px 50px 0px rgba(0, 0, 0, 0.09);',
}

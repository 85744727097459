import { createSlice } from '@reduxjs/toolkit'
import { AppState } from '../types'

const initialState: AppState = {
  isNetworkUnavailable: false,
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setUnavailable: (state: AppState) => {
      state.isNetworkUnavailable = true
    },
    clearUnavailable: (state: AppState) => {
      state.isNetworkUnavailable = false
    },
  },
})

// Actions
export const { setUnavailable, clearUnavailable } = appSlice.actions

export default appSlice.reducer

import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const InvestDarkActive: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="18" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M11.9589 7.42103C11.3413 7.42103 10.8389 7.92349 10.8389 8.541C10.8952 10.0248 13.0228 10.0244 13.0789 8.541C13.0789 7.92349 12.5764 7.42103 11.9589 7.42103Z"
        fill="#8692FF"
      />
      <path
        d="M14.1463 1.91284L14.259 2.55716C14.5712 4.44827 16.0516 5.92868 17.9427 6.24089L18.5901 6.3434C19.4028 3.65831 19.2124 1.69553 19.2387 1.2611C18.7978 1.28786 16.83 1.09855 14.1463 1.91284Z"
        fill="#8692FF"
      />
      <path
        d="M13.1545 2.74231L13.0738 2.281C8.76882 3.93418 4.96843 7.58016 3.81755 12.0336C3.81755 12.0336 3.40732 13.9256 4.99086 15.5091C6.57439 17.0927 8.46643 16.6824 8.46643 16.6824C12.8625 15.5464 16.5436 11.7955 18.2218 7.41904L17.7639 7.34654C15.3965 6.95567 13.5459 5.10578 13.1545 2.74231ZM11.959 10.7809C10.7239 10.7809 9.71908 9.77609 9.71908 8.54095C9.83171 5.57344 14.0868 5.57426 14.1989 8.54098C14.1989 9.77609 13.1941 10.7809 11.959 10.7809Z"
        fill="#8692FF"
      />
      <path
        d="M9.15895 17.6525V19.7405H9.71893C13.2432 19.7405 15.8779 16.7306 15.8788 13.3108C14.0353 15.337 11.7139 16.8989 9.15895 17.6525Z"
        fill="#8692FF"
      />
      <path
        d="M7.18905 4.62101C3.76202 4.62191 0.759277 7.26275 0.759277 10.7808V11.3408H2.84735C3.60243 8.78091 5.16706 6.4606 7.18905 4.62101Z"
        fill="#8692FF"
      />
      <path
        d="M2.98766 13.926C1.5268 15.3069 1.24862 17.5646 1.03238 19.4677C3.16956 19.2507 5.01514 19.0151 6.57405 17.5418C6.57405 17.5418 5.26868 17.3127 4.29076 16.3885C3.31283 15.4643 2.98766 13.926 2.98766 13.926Z"
        fill="#8692FF"
      />
    </Svg>
  )
}

export default InvestDarkActive

import { Box, Container } from '@mui/material'
import { useAccount, useNetwork } from 'wagmi'
import ImageWithNetwork from 'componentsV2/ImageWithNetwork'
import Logo from 'componentsV2/Logo/Logo'
import Countdown from 'componentsV2/Countdown/Countdown'
import { IFSortableTable, MainTab } from 'componentsV2'
import { DefaultStyles } from 'componentsV2/IFSortableTable/IFSortableTable'
import IFTypography from 'componentsV2/IFTypography/IFTypography'
import { useTokenBalanceMultipleChain } from 'hooks/useTokenBalance'
import { convertFromWei } from 'utils/formatBalance'
import tokens from 'config/constants/tokens'
import { useTranslation } from 'contexts/Localization'
import React, { useCallback, useContext, useMemo } from 'react'
import { useHistory, Link } from 'react-router-dom'
import {
  useAllProjectsWithAllocation,
  useFetchInitIdosPublicWithAllocationsData,
  useIsSaleUserDataFetched,
  useSaleUserDataMap,
} from 'state/idos/hooks'
import styled from 'styled-components'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import DEFAULT_TOKEN_LIST from 'config/constants/swap/impossible-default.tokenlist.json'
import { Button, Flex, Link as UIKitLink, OpenNewIcon, useMatchBreakpoints, useModalV2 } from 'uikit'
import ConnectWalletButton from 'componentsV2/ConnectWalletButton/ConnectWalletButton'
import Loading from 'uikit/widgets/AccountSidebar/YourProjects/Loading'
import useTheme from 'hooks/useTheme'
import WhiteCircleLoader from 'componentsV2/Loader/WhiteCircleLoader'
import CircleLoader from 'componentsV2/Loader/CircleLoader'
import DefaultIFTooltip from 'componentsV2/DefaultIFTooltip/DefaultIFTooltip'
import { useDrawer } from 'uikit/widgets/Drawer'
import { Chain } from 'config/constants/types'
import ManageDrawer from 'uikit/widgets/Drawer/ManageDrawer'
import { LAUNCHPAD_TABS_MENU } from 'config/menus/tabs'

import BigNumber from 'bignumber.js'
import getMoreTokenAddress from 'utils/getMoreTokenAddress'
import { getCategorisedManageList, ManageRow, ManageState } from './utils'
import ManageMobileCard from './ManageMobileCard'
import UnstakeIdleModal from './UnstakeIdleModal'
import { Brands, CompanyContext } from 'contexts/CompanyContext'

const PriceText = styled(IFTypography)<{ $isUp: boolean }>`
  color: ${({ $isUp, theme }) => ($isUp ? theme.colorsV2.success : theme.colorsV2.danger)};
`

const ManageContainer = styled.div<{ $drawerOpen: boolean }>`
  margin: 40px 0 37px;
  ${({ theme }) => theme.mediaQueries.md} {
    margin: 60px ${({ $drawerOpen }) => ($drawerOpen ? 0 : 77)}px 0;
  }
  min-height: calc(100vh - 173px);
`

const ListText = styled(IFTypography)`
  li {
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
`

const InfoBox = styled(Box)`
  background-color: ${({ theme }) => (theme.isDark ? theme.colorsV2?.dark2 : '#FFFFFF80')};
  padding: 30px 40px 30px 40px;
  border-radius: 20px;
  width: 100%;
  margin-left: 16px;
  margin-right: 16px;
  ${({ theme }) => theme.mediaQueries?.md} {
    width: 480px;
    padding: 40px;
    background-color: ${({ theme }) => (theme.isDark ? theme.colorsV2?.dark2 : theme.colorsV2?.main)};
  }
`

const Card = styled(Box)<{ width?: string; padding?: string }>`
  background-color: ${({ theme }) => theme.colorsV2?.main};
  padding: ${({ padding }) => padding ?? '30px 40px 30px 40px'};
  border-radius: 20px;
  ${({ theme }) => theme.mediaQueries?.md} {
    width: ${({ width }) => width};
  }
`

const ManageDesc = styled.ul`
  list-style-position: outside;
  margin-left: 14px;
`

const UnstakeBox = styled(Box)`
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 20px;
  background: ${({ theme }) => theme.colorsV2?.feedbacks.infoBg};

  @media (max-width: 768px) {
    flex-direction: column;
  }
`
const UnstakeText = styled(Box)`
  flex: 1 1 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 15px;
`

const UnstakeButton = styled(Button)`
  flex: 1 1 10%;
  display: flex;
  margin: 4px;
  @media (max-width: 768px) {
    min-height:26px;
    pading: 10px;
    align-self: flex-end;



`

const style = styled(DefaultStyles)`
  margin-left: ${({ isMobile }) => (isMobile ? '84px' : '110px')};
  width: ${({ isMobile }) => `calc(100% - ${isMobile ? '84px' : '110px'})`};
  table {
    .tableHeader {
      display: flex;
    }
    td {
      :last-child {
        border-radius: 0px 20px 20px 0px;
      }
      :first-child {
        border-radius: 20px 0px 0px 20px;
      }
    }
  }
`

const CTATitle = {
  [ManageState.purchase]: 'Purchase',
  [ManageState.staking]: 'Stake',
  [ManageState.claim]: 'Claim',
}
const getTableColumn = (state: ManageState, t, theme) => {
  const allocationTitle = {
    [ManageState.purchase]: 'Allocation',
    [ManageState.staking]: 'EST. Allocation',
    [ManageState.claim]: 'Total Purchased',
    [ManageState.past]: 'Total Purchased',
  }

  return [
    {
      header: '',
      accessor: 'image',
      cell: (value) => {
        const { projectImage, chainId } = value
        return (
          <Flex mr="20px">
            <ImageWithNetwork
              alt="icon"
              src={projectImage}
              chainId={chainId}
              width="40px"
              height="40px"
              chainWidth="21px"
              chainHeight="21px"
            />
          </Flex>
        )
      },
    },
    {
      header: 'Project',
      headerJustify: 'left',
      accessor: 'projectTitle',
      isSortable: true,
      cell: (value) => {
        const { projectTitle, projectSubtitle } = value
        return (
          <Flex flexDirection="column" alignItems="flex-start">
            <IFTypography variant="body2" fontWeight="bold">
              {projectTitle}
            </IFTypography>
            <IFTypography variant="body2" ifcolor="textSecondary" fontSize="12px">
              {projectSubtitle}
            </IFTypography>
          </Flex>
        )
      },
    },
    {
      header: 'Staked',
      accessor: 'staked',
      headerJustify: 'left',
      isSortable: true,
      cell: (value) => {
        const { staked, stakingToken } = value
        return (
          stakingToken && (
            <Flex flexDirection="row" alignItems="center" justifyContent="left">
              <Flex mr="5px">
                <Logo srcs={[`/images/tokens/${stakingToken.symbol}.png`]} width="26px" height="26px" />
              </Flex>
              <Flex flexDirection="column" alignItems="flex-start">
                <IFTypography variant="body2" ifcolor="textSecondary" fontSize="12px">
                  $ {stakingToken.symbol}
                </IFTypography>
                <IFTypography variant="body2" fontWeight="bold">
                  {staked === 0 ? '-' : staked.toLocaleString()}
                </IFTypography>
              </Flex>
            </Flex>
          )
        )
      },
    },
    {
      header: allocationTitle[state],
      headerJustify: 'left',
      accessor: 'allocation',
      isSortable: true,
      cell: (value) => {
        const { allocation, token } = value
        return (
          <Flex justifyContent="left">
            <Flex flexDirection="column" alignItems="flex-start">
              <IFTypography variant="body2" ifcolor="textSecondary" fontSize="12px">
                $ {token.symbol}
              </IFTypography>
              <IFTypography variant="body2" fontWeight="bold">
                {state === ManageState.staking && 'EST. '}
                {allocation === 0 ? '-' : allocation.toLocaleString()}
              </IFTypography>
            </Flex>
          </Flex>
        )
      },
    },
    state !== ManageState.past && {
      header: 'Status',
      accessor: 'startTime',
      headerJustify: 'left',
      isSortable: true,
      cell: (value: ManageRow) => {
        const { startTime, endTime, airdropNumber, isAirdrop, isStartTimeTBD, isEndTimeTBD } = value
        let isTBD = false
        const currentDate = new Date()
        const titlePrefix =
          state === ManageState.claim
            ? isAirdrop
              ? `Airdrop ${airdropNumber ? `#${airdropNumber} ` : ``}`
              : `Claim `
            : `${state} `

        let title = titlePrefix + t('Starts In')

        let color: 'text' | 'danger' | 'success' = 'text'
        let countdown = Math.floor((new Date(startTime).getTime() - currentDate.getTime()) / 1000)
        if (isStartTimeTBD || isEndTimeTBD) {
          isTBD = true
        } else if (currentDate > new Date(startTime)) {
          title = titlePrefix + t('Ends In')
          countdown = Math.floor((new Date(endTime).getTime() - currentDate.getTime()) / 1000)
          color = countdown < 3600 ? 'danger' : 'text'

          if (state === ManageState.claim) {
            title = null
            color = 'success'
            countdown = null
          }
        }

        return (
          <Flex flexDirection="column" alignItems="flex-start" justifyContent="left">
            <IFTypography variant="body2" ifcolor="textSecondary">
              {title}
            </IFTypography>
            <IFTypography variant="body2" fontWeight="bold" ifcolor={color}>
              {!isTBD ? (
                countdown ? (
                  <Countdown style={{ fontSize: '16px', color: 'inherit' }} seconds={countdown} />
                ) : state === ManageState.claim ? (
                  t('Available Now')
                ) : (
                  ''
                )
              ) : (
                'TBD'
              )}
            </IFTypography>
          </Flex>
        )
      },
    },
    state === ManageState.past && {
      header: 'Sale Price',
      accessor: 'salePrice',
      headerJustify: 'left',
      isSortable: true,
      cell: (value) => {
        const { salePrice } = value
        return (
          <Flex flexDirection="column" alignItems="flex-start" justifyContent="left">
            <IFTypography variant="body2" ifcolor="textSecondary" fontWeight="bold">
              {salePrice && salePrice > 0 ? `$${salePrice}` : '-'}
            </IFTypography>
          </Flex>
        )
      },
    },
    state === ManageState.past &&
      process.env.REACT_APP_ENABLE_CURRENT_PRICE && {
        header: 'Current Price',
        accessor: 'currentPrice',
        headerJustify: 'center',
        isSortable: true,
        cell: ({ currentPrice, currentPriceState, coinGeckoId }) => {
          if (!coinGeckoId) {
            return <p>-</p>
          }
          if (!currentPrice) {
            return <CircleLoader />
          }
          return (
            <PriceText
              align="center"
              variant="body2"
              $isUp={currentPriceState === 'UP'}
            >{`$${currentPrice.toLocaleString()}`}</PriceText>
          )
        },
      },
    process.env.REACT_APP_ENABLE_QUICK_SIDEBAR &&
      state !== ManageState.past && {
        header: '',
        accessor: 'sidebarCta',
        cell: (value: ManageRow) => {
          return <ManageButton value={value} state={state} padding="0 30px 0 0" />
        },
      },
    (!process.env.REACT_APP_ENABLE_QUICK_SIDEBAR || state === ManageState.past) && {
      header: '',
      accessor: 'cta',
      cell: (value) => {
        if (state !== ManageState.past) {
          return (
            <Flex justifyContent="right" paddingRight="30px">
              <IFTypography
                fontFamily="Acre"
                variant="body2"
                ifcolor={theme.isDark ? 'textThirdly' : 'textSecondary'}
                style={{ textDecorationLine: 'underline' }}
              >
                {t('See detail')}
              </IFTypography>
              <IFTypography ifcolor={theme.isDark ? 'textThirdly' : 'textSecondary'}>
                <NavigateNextIcon fontSize="inherit" />
              </IFTypography>
            </Flex>
          )
        }

        const { tokens: swapTokens } = DEFAULT_TOKEN_LIST
        const hasTrade = swapTokens.some((token) => token.address === value.token.address)

        if (hasTrade) {
          return (
            <UIKitLink
              onClick={(e) => e.stopPropagation()}
              href={`/swap/${value.token.address}`}
              target="_blank"
              rel="noopener noreferrer"
              fontSize="14px"
            >
              <Flex justifyContent="right" paddingRight="30px">
                <IFTypography
                  fontFamily="Acre"
                  variant="body2"
                  ifcolor="textLink"
                  style={{ textDecorationLine: 'underline' }}
                >
                  {t('Trade')}
                </IFTypography>
                <IFTypography ifcolor="textLink">
                  <NavigateNextIcon fontSize="inherit" />
                </IFTypography>
              </Flex>
            </UIKitLink>
          )
        }
        return (
          <Flex justifyContent="right" paddingRight="30px">
            <IFTypography
              fontFamily="Acre"
              variant="body2"
              ifcolor={theme.isDark ? 'textThirdly' : 'textSecondary'}
              style={{
                textDecorationLine: 'underline',
                // To preserve the space in the column
                visibility: 'hidden',
              }}
            >
              {t('See detail')}
            </IFTypography>
          </Flex>
        )
      },
    },
  ]
}

export const QUICK_ACTION_DRAWER_TYPE = 'quickAction'

export const ManageButton: React.FC<{ value: ManageRow; state: ManageState; padding?: string }> = ({
  value,
  state,
  padding = '0',
}) => {
  const { saleId, startTime, isAirdrop } = value
  const [onOpen] = useDrawer({
    drawer: <ManageDrawer />,
    options: {
      drawerId: saleId,
      drawerType: QUICK_ACTION_DRAWER_TYPE,
    },
  })

  return (
    <Flex justifyContent="right" padding={padding}>
      {!(isAirdrop && state === ManageState.claim) && (
        <Button
          onClick={(e) => {
            e.stopPropagation()
            onOpen({ value })
          }}
          disabled={new Date() < new Date(startTime) && !value.hasClaimable}
          variant="primary"
        >
          {CTATitle[state]}
        </Button>
      )}
    </Flex>
  )
}

interface CardWidgetProps {
  isSaleUserDataFetched: boolean
  totalTokenOwn: BigNumber
  totalIdleStaked: BigNumber
  totalStaked: BigNumber
  unstakeHandler: VoidFunction
  tokenName: string
}

const CardWidget: React.FC<CardWidgetProps> = ({
  isSaleUserDataFetched,
  totalTokenOwn,
  totalIdleStaked,
  totalStaked,
  tokenName,
  unstakeHandler,
}) => {
  const { address: account } = useAccount()
  const { chain } = useNetwork()
  const chainId = chain?.id
  const { t } = useTranslation()
  const token = tokens[tokenName.toLowerCase()]
  return (
    <Flex flexDirection="column">
      <Flex flexDirection="row" justifyContent="space-between" mb="20px">
        <Flex flexDirection="row" alignItems="center">
          <Logo srcs={[`/images/tokens/${tokenName.toUpperCase()}.svg`]} width="42px" />
          <Flex ml="10px" />
          <IFTypography variant="h2" fontWeight="bold">
            {tokenName}
          </IFTypography>
        </Flex>
        <Flex>
          <UIKitLink
            href={
              process.env.REACT_APP_WHITELABEL_BRAND === 'if'
                ? getMoreTokenAddress(token, { chainId })
                : 'https://roninchain.com/blog/posts/how-to-buy-ron'
            }
            target="_blank"
            rel="noopener noreferrer"
            fontSize="14px"
          >
            {t('Get %token%', { token: tokenName })} <OpenNewIcon ml="10px" color="currentColor" width="15px" />
          </UIKitLink>
        </Flex>
      </Flex>
      <Flex flexDirection="row" justifyContent="space-between" mb="10px">
        <Box justifyContent="left" display="flex">
          <IFTypography variant="body2">{t('Total owned')}</IFTypography>
          <DefaultIFTooltip
            placement="top"
            title={t('Total %token% owned on all chains, including %token% staked in projects.', {
              token: tokenName,
            })}
          />
        </Box>
        {isSaleUserDataFetched ? (
          <IFTypography variant="body2">
            {account ? totalTokenOwn.decimalPlaces(2).toNumber().toLocaleString() : '-'}
          </IFTypography>
        ) : (
          <WhiteCircleLoader variant="disabled" width="16px" />
        )}
      </Flex>
      <Flex flexDirection="row" justifyContent="space-between" mb="10px">
        <IFTypography variant="body2">{t('Total amount staked')}</IFTypography>
        {isSaleUserDataFetched ? (
          <IFTypography variant="body2">
            {account ? totalStaked.decimalPlaces(2).toNumber().toLocaleString() : '-'}
          </IFTypography>
        ) : (
          <WhiteCircleLoader variant="disabled" width="16px" />
        )}
      </Flex>
      <Flex flexDirection="row" justifyContent="space-between" mb="10px">
        <Box justifyContent="left" display="flex">
          <IFTypography variant="body2">{t('Staked in inactive projects')}</IFTypography>
        </Box>
        {isSaleUserDataFetched ? (
          <IFTypography variant="body2">
            {account ? totalIdleStaked.decimalPlaces(2).toNumber().toLocaleString() : '-'}
          </IFTypography>
        ) : (
          <WhiteCircleLoader variant="disabled" width="16px" />
        )}
      </Flex>
      {process.env.REACT_APP_ENABLE_UNSTAKE_IDLE && (
        <Flex flexDirection="row" justifyContent="flex-end">
          <Button disabled={totalIdleStaked.isEqualTo(0)} onClick={unstakeHandler} maxWidth="98px">
            {t('Unstake')}
          </Button>
        </Flex>
      )}
    </Flex>
  )
}

const Manage: React.FC = () => {
  useFetchInitIdosPublicWithAllocationsData()

  const { address: account } = useAccount()
  const projects = useAllProjectsWithAllocation()
  const userDataMap = useSaleUserDataMap()
  const { t } = useTranslation()
  const { isXl, isMd, isLg } = useMatchBreakpoints()
  const isTabletSize = isMd && !isLg
  const { brand } = useContext(CompanyContext)
  const isSaleUserDataFetched = useIsSaleUserDataFetched()
  // disable price for now
  // const coinGeckoIds = useMemo(() => projects.map((project) => project.coinGeckoId).filter((val) => val), [projects])
  // const priceMap = useCoinGeckoPrice(coinGeckoIds)
  const priceMap = undefined

  const EMPTY_STATE = {
    [ManageState.claim]: t('Nothing to claim yet.'),
    [ManageState.past]: t('No completed projects.'),
    [ManageState.purchase]: t('Not participated in purchase.'),
    [ManageState.staking]: t('Not participated in staking.'),
  }

  const idiaOnWallet = convertFromWei(
    useTokenBalanceMultipleChain(tokens.idia.address, [
      Chain.BSC_MAINNET,
      Chain.ARBITRUM_MAINNET,
      Chain.MOONRIVER_MAINNET,
      Chain.MANTLE,
    ]),
  )

  const vidiaOnWallet = convertFromWei(useTokenBalanceMultipleChain(tokens.vidia.address, [Chain.BSC_MAINNET]))

  const {
    categorisedManageList,
    totalStaked: idiaTotalStaked,
    totalIdleStaked: idiaTotalIdleStaked,
    idleSales: idiaIdleSales,
  } = getCategorisedManageList(projects, userDataMap, priceMap)

  const {
    totalStaked: vidiaTotalStaked,
    totalIdleStaked: vidiaTotalIdleStaked,
    idleSales: vidiaIdleSales,
  } = useMemo(
    () => getCategorisedManageList(projects, userDataMap, priceMap, 'vIDIA'),
    [projects, userDataMap, priceMap],
  )

  const {
    totalStaked: ronStaked,
    totalIdleStaked: ronTotalIdleStaked,
    idleSales: ronIdleSales,
  } = useMemo(
    () => getCategorisedManageList(projects, userDataMap, priceMap, 'WETH'),
    [projects, userDataMap, priceMap],
  )

  const isEmptyAll =
    Object.keys(categorisedManageList).reduce((acc, key) => acc + categorisedManageList[key].length, 0) === 0

  const [onOpen, , isOpen] = useDrawer({
    drawer: <ManageDrawer />,
    options: {
      drawerType: QUICK_ACTION_DRAWER_TYPE,
    },
  })

  const history = useHistory()

  const onRowClicked = useCallback(
    (value: ManageRow) => {
      if (process.env.REACT_APP_ENABLE_QUICK_SIDEBAR) {
        if (window.fathom) {
          window.fathom.trackGoal('OFGXKJEX', 0)
        }
        return onOpen({ value })
      }
      if (value.isSingleSale) {
        return history.push(`/launchpad/project/${value.projectId}`)
      }
      return history.push(`/launchpad/project/${value.projectId}/sale/${value.saleId}`)
    },
    [onOpen, history],
  )

  const [onPresentIDIAUnstakeModal] = useModalV2({
    modal: <UnstakeIdleModal idleSales={idiaIdleSales} tokenName="IDIA" />,
    options: {
      backdropBlurVal: 'blur(20px)',
      closeOnOverlayClick: false,
      updateOnPropsChange: true,
      modalId: 'unstakeIDIAIdle',
    },
  })

  const [onPresentVIDIAUnstakeModal] = useModalV2({
    modal: <UnstakeIdleModal idleSales={vidiaIdleSales} tokenName="vIDIA" />,
    options: {
      backdropBlurVal: 'blur(20px)',
      closeOnOverlayClick: false,
      updateOnPropsChange: true,
      modalId: 'unstakeVIDIAIdle',
    },
  })

  const [onPresentRonUnstakeModal] = useModalV2({
    modal: <UnstakeIdleModal idleSales={ronIdleSales} tokenName="RON" />,
    options: {
      backdropBlurVal: 'blur(20px)',
      closeOnOverlayClick: false,
      updateOnPropsChange: true,
      modalId: 'unstakeRONIdle',
    },
  })

  const totalIdiaOwn = useMemo(
    () => idiaOnWallet.plus(idiaTotalIdleStaked).plus(idiaTotalStaked),
    [idiaOnWallet, idiaTotalIdleStaked, idiaTotalStaked],
  )

  const totalVidiaOwn = useMemo(
    () => vidiaOnWallet.plus(vidiaTotalIdleStaked).plus(vidiaTotalStaked),
    [vidiaOnWallet, vidiaTotalIdleStaked, vidiaTotalStaked],
  )

  const isMobile = isXl === false
  const theme = useTheme()

  return (
    <>
      {brand === Brands.IF ? <MainTab menu={LAUNCHPAD_TABS_MENU} /> : <Box marginTop={8} />}
      <ManageContainer $drawerOpen={isOpen}>
        <Container>
          {brand === Brands.IF ? (
            <>
              <Flex justifyContent="center">
                <IFTypography variant="h2" fontWeight="bold">
                  {t('Manage')}
                </IFTypography>
              </Flex>
              <Flex justifyContent="center" mt="20px" padding="24px">
                <Flex flexDirection="column" maxWidth="760px">
                  <IFTypography variant="body1" fontWeight="bold">
                    {t('Tips to maximize your assets: ')}
                  </IFTypography>
                  <Flex mt="10px" />
                  <ListText variant="body1">
                    <ManageDesc>
                      <li>
                        {t(
                          'Staked IDIA assets that are idle: unstake your assets after the staking period (when the final allocation is revealed) and stake on other live projects to get allocation in advance.',
                        )}
                      </li>
                      <li>{t('Change the network to manage other assets separately.')}</li>
                    </ManageDesc>
                  </ListText>
                </Flex>
              </Flex>
              <Flex justifyContent="center" flexWrap={isMobile ? 'wrap' : 'nowrap'}>
                <InfoBox mt={isMobile ? '35px' : '40px'} mb={isMobile ? '10px' : '90px'}>
                  <CardWidget
                    isSaleUserDataFetched={isSaleUserDataFetched}
                    tokenName="IDIA"
                    totalTokenOwn={totalIdiaOwn}
                    totalIdleStaked={idiaTotalIdleStaked}
                    totalStaked={idiaTotalStaked}
                    unstakeHandler={onPresentIDIAUnstakeModal}
                  />
                </InfoBox>
                <InfoBox mt={isMobile ? '10px' : '40px'} mb={isMobile ? '35px' : '90px'}>
                  <CardWidget
                    isSaleUserDataFetched={isSaleUserDataFetched}
                    tokenName="vIDIA"
                    totalTokenOwn={totalVidiaOwn}
                    totalIdleStaked={vidiaTotalIdleStaked}
                    totalStaked={vidiaTotalStaked}
                    unstakeHandler={onPresentVIDIAUnstakeModal}
                  />
                </InfoBox>
              </Flex>
            </>
          ) : (
            <>
              <UnstakeBox marginBottom={10} columnGap={8} alignItems="center">
                <UnstakeText>
                  <Box alignSelf="center">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="Tool Icon / warning">
                        <path
                          id="Exclude"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9.13457 3.16144L1.70235 15.9989C1.31639 16.6656 1.79745 17.4999 2.56778 17.4999H17.4322C18.2025 17.4999 18.6836 16.6656 18.2976 15.9989L10.8654 3.16144C10.4803 2.49615 9.51974 2.49615 9.13457 3.16144ZM10 7.49995C9.53976 7.49995 9.16667 7.87304 9.16667 8.33328V11.6666C9.16667 12.1268 9.53976 12.4999 10 12.4999C10.4602 12.4999 10.8333 12.1268 10.8333 11.6666V8.33328C10.8333 7.87304 10.4602 7.49995 10 7.49995ZM10 14.1666C9.53976 14.1666 9.16667 14.5397 9.16667 14.9999C9.16667 15.4602 9.53976 15.8333 10 15.8333C10.4602 15.8333 10.8333 15.4602 10.8333 14.9999C10.8333 14.5397 10.4602 14.1666 10 14.1666Z"
                          fill={theme.theme.colorsV2?.feedbacks.iconWarningUnstake}
                        />
                      </g>
                    </svg>
                  </Box>
                  <Box>
                    <IFTypography ifcolor="textInfo" fontSize="14px">
                      To fully optimize and utilize your staked assets: Reduce idling staked assets by unstaking in
                      projects that have passed the staking period (when final allocation is revealed) and stake on
                      other live projects.
                    </IFTypography>
                  </Box>
                </UnstakeText>

                <UnstakeButton disabled={ronTotalIdleStaked.isEqualTo(0)} scale="sm" onClick={onPresentRonUnstakeModal}>
                  Unstake
                </UnstakeButton>
              </UnstakeBox>
            </>
          )}

          {account ? (
            // Shown when after fetching all non was participated
            isSaleUserDataFetched && isEmptyAll ? (
              <Card mb="120px" padding="110px 0">
                <IFTypography variant="body2" align="center" ifcolor="textSecondary">
                  {t('You haven’t participated in any projects yet.')}
                </IFTypography>
                <Flex justifyContent="center">
                  <Link to="/explore">
                    <Box display="flex" alignItems="center" color={theme.theme.colorsV2?.textLink}>
                      <IFTypography variant="body2" ifcolor="textLink">
                        {t('See Projects')}
                      </IFTypography>
                      <OpenNewIcon ml="10px" color="currentColor" width="15px" />
                    </Box>
                  </Link>
                </Flex>
              </Card>
            ) : (
              [ManageState.purchase, ManageState.staking, ManageState.claim, ManageState.past].map((state) => {
                const defaultSortField = state === ManageState.claim ? 'startTime' : 'endTime'
                const projectList = categorisedManageList[state].sort(
                  (a, b) => new Date(a[defaultSortField]).valueOf() - new Date(b[defaultSortField]).valueOf(),
                )
                return (
                  <Box mb="50px" padding={3} key={state}>
                    <IFTypography variant="h3">{t(state)}</IFTypography>
                    <Box>
                      {isSaleUserDataFetched && projectList.length === 0 ? (
                        // Shown after fetched and empty list in the current type
                        <Card mt="20px">
                          <IFTypography variant="body2" align="center" ifcolor="textSecondary">
                            {EMPTY_STATE[state]}
                          </IFTypography>
                        </Card>
                      ) : isMobile && state !== ManageState.past ? (
                        // Show mobile card only after fetched and only in mobile and not past projects
                        !isSaleUserDataFetched ? (
                          // Shown if hasn't finished fetching
                          // We want a total padding to be 20px. The outer padding is 8px, so we set it as 12px here.
                          <Loading height={isMobile ? '249px' : '90px'} padding="12px 35px 212px" />
                        ) : (
                          <Box
                            display={isTabletSize ? 'grid' : 'block'}
                            columnGap="20px"
                            gridTemplateColumns={isTabletSize ? '1fr 1fr' : '1fr'}
                          >
                            {projectList.map((item) => {
                              return <ManageMobileCard item={item} state={state} onClick={() => onRowClicked(item)} />
                            })}
                          </Box>
                        )
                      ) : (
                        <IFSortableTable
                          data={projectList}
                          columns={getTableColumn(state, t, theme).filter((item) => !!item)}
                          config={{ onRowClicked }}
                          defaultSortField="endTime"
                          isLoading={!isSaleUserDataFetched}
                          CustomStyles={style}
                        />
                      )}
                    </Box>
                  </Box>
                )
              })
            )
          ) : (
            <Card mb="120px" padding="110px 0">
              <IFTypography variant="body2" align="center" ifcolor="textSecondary">
                {t('Connect wallet to view your participated projects.')}
              </IFTypography>
              <Flex justifyContent="center" marginTop={4}>
                <ConnectWalletButton width="290px" marginTop="10px" />
              </Flex>
            </Card>
          )}
        </Container>
      </ManageContainer>
    </>
  )
}

export default Manage

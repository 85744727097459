import { Chain } from 'config/constants/types'

const getTokenLogoURL = (address: string, chainId: Chain) => {
  switch (chainId) {
    case Chain.BSC_MAINNET:
      return `https://assets.trustwalletapp.com/blockchains/smartchain/assets/${address}/logo.png`
    case Chain.MOONBEAM_MAINNET:
      return `https://impossiblefinance.github.io/solarbeam-tokenlist/assets/moonbeam/${address}/logo.png`
    case Chain.MOONRIVER_MAINNET:
      return `https://impossiblefinance.github.io/solarbeam-tokenlist/assets/moonriver/${address}/logo.png`
    case Chain.AVAX_MAINNET:
      return `https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/${address}/logo.png`
    default:
      return `https://assets.trustwalletapp.com/blockchains/smartchain/assets/${address}/logo.png`
  }
}

export default getTokenLogoURL

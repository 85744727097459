import {
  coin98Wallet,
  injectedWallet,
  rainbowWallet,
  walletConnectWallet,
  metaMaskWallet,
  coinbaseWallet,
  braveWallet,
  bitKeepWallet,
  okxWallet,
  trustWallet,
  uniswapWallet,
  tokenPocketWallet,
} from '@rainbow-me/rainbowkit/wallets'
import { connectorsForWallets, type Chain, type Wallet } from '@rainbow-me/rainbowkit'

import { BASE_BSC_SCAN_URLS } from 'config'
import getRpcUrl from 'utils/getRpcUrl'
import { RoninConnector } from 'ronin-connector'

import {
  arbitrum,
  mainnet,
  polygon,
  bsc,
  bscTestnet,
  aurora,
  moonbeam,
  ronin,
  zkSync,
  goerli,
  saigon,
  arbitrumGoerli,
  lineaTestnet,
  mantle,
} from 'wagmi/chains'

export interface MyWalletOptions {
  projectId: string
  chains: Chain[]
}

const omniTestnet: Chain = {
  id: 165,
  name: 'Omni Testnet',
  network: 'Omni Testnet',
  iconUrl: '/images/icons/networks/165.svg',
  iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: 'OMNI',
    symbol: 'OMNI',
  },
  rpcUrls: {
    default: {
      http: [getRpcUrl(165)],
    },
    public: {
      http: [getRpcUrl(165)],
    },
  },
  blockExplorers: {
    default: {
      name: 'Explorer',
      url: BASE_BSC_SCAN_URLS[165],
    },
  },
  contracts: {
    multicall3: {
      address: '0xe7f1F4a6a6B8aC6ecc535Dc41c7E79df36296634',
    },
  },
}

const baseSepolia: Chain = {
  id: 84532,
  name: 'Base Sepolia',
  network: 'Base Sepolia',
  iconUrl: '/images/icons/networks/165.svg',
  iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: 'ETH',
    symbol: 'ETH',
  },
  rpcUrls: {
    default: {
      http: [getRpcUrl(84532)],
    },
    public: {
      http: [getRpcUrl(84532)],
    },
  },
  blockExplorers: {
    default: {
      name: 'Explorer',
      url: BASE_BSC_SCAN_URLS[84532],
    },
  },
  contracts: {
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
    },
  },
}

const arbitrumSepolia: Chain = {
  id: 421614,
  name: 'Arbitrum Sepolia',
  network: 'Arbitrum Sepolia',
  iconUrl: '/images/icons/networks/42161.svg',
  iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: 'ETH',
    symbol: 'ETH',
  },
  rpcUrls: {
    default: {
      http: [getRpcUrl(421614)],
    },
    public: {
      http: [getRpcUrl(421614)],
    },
  },
  blockExplorers: {
    default: {
      name: 'Explorer',
      url: BASE_BSC_SCAN_URLS[421614],
    },
  },
  contracts: {
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
    },
  },
}

const beam: Chain = {
  id: 4337,
  name: 'Beam',
  network: 'Beam',
  iconUrl: '/images/icons/networks/4337.png',
  iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: 'BEAM',
    symbol: 'BEAM',
  },
  rpcUrls: {
    default: {
      http: [getRpcUrl(4337)],
    },
    public: {
      http: [getRpcUrl(4337)],
    },
  },
  blockExplorers: {
    default: {
      name: 'Explorer',
      url: BASE_BSC_SCAN_URLS[4337],
    },
  },
  contracts: {
    multicall3: {
      address: '0x4956f15efdc3dc16645e90cc356eafa65ffc65ec',
    },
  },
}

const beamTestnet: Chain = {
  id: 13337,
  name: 'Beam Testnet',
  network: 'Beam Testnet',
  iconUrl: '/images/icons/networks/4337.png',
  iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: 'BEAM',
    symbol: 'BEAM',
  },
  rpcUrls: {
    default: {
      http: [getRpcUrl(13337)],
    },
    public: {
      http: [getRpcUrl(13337)],
    },
  },
  blockExplorers: {
    default: {
      name: 'Explorer',
      url: BASE_BSC_SCAN_URLS[13337],
    },
  },
  contracts: {
    multicall3: {
      address: '0x9BF49b704EE2A095b95c1f2D4EB9010510c41C9E',
    },
  },
}

const immutable: Chain = {
  id: 13371,
  name: 'Immutable',
  network: 'Immutable',
  iconUrl: '/images/icons/networks/13371.png',
  iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: 'IMX',
    symbol: 'IMX',
  },
  rpcUrls: {
    default: {
      http: [getRpcUrl(13371)],
    },
    public: {
      http: [getRpcUrl(13371)],
    },
  },
  blockExplorers: {
    default: {
      name: 'Explorer',
      url: BASE_BSC_SCAN_URLS[13371],
    },
  },
  contracts: {
    multicall3: {
      address: '0x55BeE1bD3Eb9986f6d2d963278de09eE92a3eF1D',
    },
  },
}

const immutableTestnet: Chain = {
  id: 13473,
  name: 'Immutable Testnet',
  network: 'Immutable Testnet',
  iconUrl: '/images/icons/networks/13371.png',
  iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: 'tIMX',
    symbol: 'tIMX',
  },
  rpcUrls: {
    default: {
      http: [getRpcUrl(13473)],
    },
    public: {
      http: [getRpcUrl(13473)],
    },
  },
  blockExplorers: {
    default: {
      name: 'Explorer',
      url: BASE_BSC_SCAN_URLS[13473],
    },
  },
  contracts: {
    multicall3: {
      address: '0x55BeE1bD3Eb9986f6d2d963278de09eE92a3eF1D',
    },
  },
}

const okxTestnet: Chain = {
  id: 195,
  name: 'OKX Testnet',
  network: 'OKX Testnet',
  iconUrl: '/images/tokens/OKB.png',
  iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: 'OKB',
    symbol: 'OKB',
  },
  rpcUrls: {
    default: {
      http: [getRpcUrl(195)],
    },
    public: {
      http: [getRpcUrl(195)],
    },
  },
  blockExplorers: {
    default: {
      name: 'Explorer',
      url: BASE_BSC_SCAN_URLS[195],
    },
  },
  contracts: {},
}

const mantaMainnet: Chain = {
  id: 169,
  name: 'Manta',
  network: 'Manta',
  iconUrl: '/images/icons/networks/169.svg',
  iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: 'ETH',
    symbol: 'ETH',
  },
  rpcUrls: {
    default: {
      http: [getRpcUrl(169)],
    },
    public: {
      http: [getRpcUrl(169)],
    },
  },
  blockExplorers: {
    default: {
      name: 'Explorer',
      url: BASE_BSC_SCAN_URLS[169],
    },
  },
  contracts: {
    multicall3: {
      address: '0x8AafBE321FDB1F5797d9A1Db9E3f53623659ba10',
    },
  },
}

const hmndTestnet: Chain = {
  id: 15110,
  name: 'Humanode Testnet',
  network: 'Humanode Testnet',
  iconUrl: '/images/hmnd.png',
  iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: 'eHMND',
    symbol: 'eHMND',
  },
  rpcUrls: {
    default: {
      http: [getRpcUrl(15110)],
    },
    public: {
      http: [getRpcUrl(15110)],
    },
  },
  blockExplorers: {
    default: {
      name: 'Explorer',
      url: BASE_BSC_SCAN_URLS[15110],
    },
  },
}

const zksyncTestnet: Chain = {
  id: 300,
  name: 'zksyncTestnet',
  network: 'zksyncTestnet',
  iconUrl: '/images/icons/networks/300.svg',
  iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: 'ETH',
    symbol: 'ETH',
  },
  rpcUrls: {
    default: {
      http: [getRpcUrl(300)],
    },
    public: {
      http: [getRpcUrl(300)],
    },
  },
  blockExplorers: {
    default: {
      name: 'Explorer',
      url: BASE_BSC_SCAN_URLS[300],
    },
  },
}

const polygonAmoy: Chain = {
  id: 80002,
  name: 'Polygon Amoy',
  network: 'polygonAmoy',
  iconUrl: '/images/icons/networks/137.svg',
  iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: 'ETH',
    symbol: 'ETH',
  },
  rpcUrls: {
    default: {
      http: [getRpcUrl(80002)],
    },
    public: {
      http: [getRpcUrl(80002)],
    },
  },
  blockExplorers: {
    default: {
      name: 'Explorer',
      url: BASE_BSC_SCAN_URLS[80002],
    },
  },
}

const hmndMainnet: Chain = {
  id: 5234,
  name: 'Humanode Mainnet',
  network: 'Humanode Mainnet',
  iconUrl: '/images/hmnd.png',
  iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: 'eHMND',
    symbol: 'eHMND',
  },
  rpcUrls: {
    default: {
      http: [getRpcUrl(5234)],
    },
    public: {
      http: [getRpcUrl(5234)],
    },
  },
  blockExplorers: {
    default: {
      name: 'Explorer',
      url: BASE_BSC_SCAN_URLS[5234],
    },
  },
}

export const roninWallet = ({ chains, projectId }: MyWalletOptions): Wallet => ({
  id: 'Impossible Finance',
  name: 'Ronin Wallet',
  iconUrl: '/images/roninwallet.png',
  iconBackground: '#FFF',
  downloadUrls: {
    android: 'https://play.google.com/store/apps/details?id=com.skymavis.genesis&hl=en&gl=US',
    ios: 'https://apps.apple.com/us/app/ronin-wallet/id1592675001',
    chrome: 'https://chrome.google.com/webstore/detail/ronin-wallet/fnjhmkhhmkbjkkabndcnnogagogbneec',
  },
  createConnector: () => {
    const connector = new RoninConnector({
      chains: [ronin, saigon, bsc],
      options: {
        projectId: projectId,
        metadata: {
          name: 'Impossible Finance',
          description: 'Impossible Finance Ronin Wallet',
          url: 'https://ronin.impossible.finance/explore',
          icons: ['/images/roninwallet.png'],
        },
      },
    })

    return {
      connector,
    }
  },
})

const getConnectors = () => {
  const projectId = 'c64c89769e7ce51031897547240dcdb8'
  let prdChains = [
    bsc,
    arbitrum,
    mantaMainnet,
    {
      ...mantle,
      iconUrl: '/images/mantle.jpeg',
    },
    okxTestnet,
    mainnet,
    polygon,
    zkSync,
    {
      ...aurora,
      iconUrl: '/images/Aurora.svg',
    },
    { ...moonbeam, iconUrl: '/images/Moonbeam.svg' },
    hmndMainnet,
    omniTestnet,
    arbitrumGoerli,
    {
      ...lineaTestnet,
      iconUrl: '/images/linea.jpg',
    },
  ]

  let stgChains = [
    arbitrum,
    mantaMainnet,
    {
      ...mantle,
      iconUrl: '/images/mantle.jpeg',
    },
    mainnet,
    okxTestnet,
    polygon,
    bsc,
    zksyncTestnet,
    polygonAmoy,
    {
      ...zkSync,
      iconUrl: '/images/icons/networks/300.svg',
    },
    {
      ...ronin,
      iconUrl: '/images/icons/networks/2021.svg',
    },
    {
      ...saigon,
      iconUrl: '/images/icons/networks/2021.svg',
    },
    goerli,
    {
      ...aurora,
      iconUrl: '/images/Aurora.svg',
    },
    { ...moonbeam, iconUrl: '/images/Moonbeam.svg' },
    {
      ...bscTestnet,
      name: 'BSC Testnet',
    },
    baseSepolia,
    omniTestnet,
    hmndTestnet,
    hmndMainnet,
    arbitrumSepolia,
    arbitrumGoerli,
    beam,
    beamTestnet,
    immutable,
    immutableTestnet,
    {
      ...lineaTestnet,
      iconUrl: '/images/linea.jpg',
    },
  ]

  let chains = process.env.REACT_APP_STAGING ? stgChains : prdChains

  let connectors = connectorsForWallets([
    {
      groupName: 'Recommended',
      wallets: [
        metaMaskWallet({ projectId, chains }),
        injectedWallet({ chains }),
        coin98Wallet({ projectId, chains }),
        walletConnectWallet({ projectId, chains }),
      ],
    },
    {
      groupName: 'Others',
      wallets: [
        bitKeepWallet({ chains, projectId }),
        coinbaseWallet({ chains, appName: 'Impossible Finance' }),
        rainbowWallet({ projectId, chains }),
        braveWallet({ chains }),
        okxWallet({ projectId, chains }),
        trustWallet({ projectId, chains }),
        uniswapWallet({ projectId, chains }),
        tokenPocketWallet({ projectId, chains }),
      ],
    },
  ])

  if (process.env.REACT_APP_WHITELABEL_BRAND === 'ronin') {
    prdChains = [
      {
        ...ronin,
        iconUrl: '/images/icons/networks/2021.svg',
      },
      bsc,
    ]

    stgChains = [
      {
        ...ronin,
        iconUrl: '/images/icons/networks/2021.svg',
      },
      {
        ...saigon,
        iconUrl: '/images/icons/networks/2021.svg',
      },
    ]

    chains = process.env.REACT_APP_STAGING ? stgChains : prdChains
    connectors = connectorsForWallets([
      {
        groupName: 'Recommended',
        wallets: [roninWallet({ projectId, chains })],
      },
    ])
  }

  if (process.env.REACT_APP_WHITELABEL_BRAND === 'aethir' || process.env.REACT_APP_WHITELABEL_BRAND === 'carv') {
    prdChains = [arbitrum]

    stgChains = [arbitrum, bscTestnet, arbitrumSepolia]

    chains = process.env.REACT_APP_STAGING ? stgChains : prdChains
  }

  if (process.env.REACT_APP_WHITELABEL_BRAND === 'sophon') {
    prdChains = [
      {
        ...zkSync,
        iconUrl: '/images/icons/networks/300.svg',
      },
    ]

    stgChains = [
      {
        ...zkSync,
        iconUrl: '/images/icons/networks/300.svg',
      },
      bscTestnet,
      zksyncTestnet,
    ]

    chains = process.env.REACT_APP_STAGING ? stgChains : prdChains
  }

  return {
    connectors,
    chains,
  }
}

export default getConnectors

import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const InvestLight: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.39126 4.24717C4.39126 5.12217 0.466263 8.07217 0.766263 12.8722C0.766259 13.7472 1.14125 15.7222 2.64125 16.6222C4.14125 17.5222 7.26625 17.7472 8.64125 17.7472C8.75434 17.7472 8.88012 17.7453 9.01625 17.7413V15.4972H8.26625V11.7472H9.01625V8.74717H14.8281C14.0398 7.12614 12.6266 5.39425 10.1413 4.24717C9.39126 4.37217 7.59127 4.54717 6.39126 4.24717Z"
        fill="url(#paint0_linear_20522_155743)"
      />
      <path d="M10.5163 9.49717H17.2663V11.7472H10.5163V9.49717Z" fill="url(#paint1_linear_20522_155743)" />
      <path d="M9.76625 12.4972H16.5163V14.7472H9.76625V12.4972Z" fill="url(#paint2_linear_20522_155743)" />
      <path d="M10.5163 15.4972H17.2663V17.7472H10.5163V15.4972Z" fill="url(#paint3_linear_20522_155743)" />
      <path
        d="M5.26626 0.871988C5.26626 1.47199 6.01626 2.37199 6.39126 2.74699C7.89126 3.34702 9.51626 2.99697 10.1413 2.74695C10.7663 1.99695 11.6413 1.24695 11.2663 0.496948C10.9663 -0.103052 9.76626 0.246928 9.39126 0.496918C8.49126 -0.403082 7.51626 0.121965 7.14126 0.496988C5.64126 -0.403012 5.26626 0.371988 5.26626 0.871988Z"
        fill="url(#paint4_linear_20522_155743)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_20522_155743"
          x1="0.385593"
          y1="5.74504"
          x2="17.2663"
          y2="5.74504"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#12ECB8" />
          <stop offset="0.921875" stopColor="#00B4ED" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_20522_155743"
          x1="0.385593"
          y1="5.74504"
          x2="17.2663"
          y2="5.74504"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#12ECB8" />
          <stop offset="0.921875" stopColor="#00B4ED" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_20522_155743"
          x1="0.385593"
          y1="5.74504"
          x2="17.2663"
          y2="5.74504"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#12ECB8" />
          <stop offset="0.921875" stopColor="#00B4ED" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_20522_155743"
          x1="0.385593"
          y1="5.74504"
          x2="17.2663"
          y2="5.74504"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#12ECB8" />
          <stop offset="0.921875" stopColor="#00B4ED" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_20522_155743"
          x1="0.385593"
          y1="5.74504"
          x2="17.2663"
          y2="5.74504"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#12ECB8" />
          <stop offset="0.921875" stopColor="#00B4ED" />
        </linearGradient>
      </defs>
    </Svg>
  )
}

export default InvestLight

import React, { memo } from 'react'
import { Text, Flex, ArrowForwardIcon } from 'uikit'
import { unwrappedToken } from 'utils/wrappedCurrency'
import { Trade } from 'swap-sdk/entities/trade'
import { CurrencyLogo } from 'componentsV2/Logo'
import styled from 'styled-components'

const StyledText = styled(Text)`
  color: ${({ theme }) => (theme.isDark ? theme.colorsV2?.textSecondary : theme.colorsV2?.text)};
`

const StyledArrow = styled.span`
  color: ${({ theme }) => (theme.isDark ? theme.colorsV2?.textSecondary : theme.colorsV2?.text)};
`

export default memo(function SwapRoute({ trade }: { trade: Trade }) {
  return (
    <Flex flexWrap="wrap" width="100%" justifyContent="flex-start" alignItems="center">
      {trade.route.path.map((token, i, path) => {
        const isLastItem: boolean = i === path.length - 1
        const currency = unwrappedToken(token)
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Flex mb="10px" key={i}>
            <Flex alignItems="center">
              <CurrencyLogo currency={currency} size="26px" />
              <Flex mr="5px" />

              <StyledText fontSize="14px" ml="0.125rem" mr="0.125rem">
                {currency.symbol}
              </StyledText>
            </Flex>
            {!isLastItem && (
              <Flex ml="8px" mr="13px" alignItems="center">
                <StyledArrow>
                  <ArrowForwardIcon color="currentColor" width="14px" />
                </StyledArrow>
              </Flex>
            )}
          </Flex>
        )
      })}
    </Flex>
  )
})

import { createSlice } from '@reduxjs/toolkit'
import tokens from 'config/constants/tokens'
import { Chain } from 'config/constants/types'
import { VIDIAState } from 'state/v2_types'
import { getAddress } from 'utils/addressHelpers'
import vidiaAbi from 'config/abi/vIDIA.json'
import { convertFromWei } from 'utils/formatBalance'
import BigNumber from 'bignumber.js'
import { multicallv2 } from 'utils/multicall'

const initialState: VIDIAState = {
  data: null,
}

export const vIDIASlice = createSlice({
  name: 'vIDIA',
  initialState,
  reducers: {
    setVIDIAData: (state, action) => {
      state.data = action.payload
    },
  },
})

export const { setVIDIAData } = vIDIASlice.actions

export const fetchVIDIAData = (account: string, chainId: Chain) => async (dispatch) => {
  const address = getAddress(tokens.vidia.address, chainId)

  const calls = [
    {
      address,
      name: 'userInfo',
      params: [account],
    },
    {
      address,
      name: 'calculateUserReward',
      params: [account],
    },
    {
      address,
      name: 'skipDelayFee',
    },
    {
      address,
      name: 'cancelUnstakeFee',
    },
    {
      address,
      name: 'unstakingDelay',
    },
  ]
  const [userInfo, userReward, skipDelayFee, cancelUnstakeFee, unstakingDelay] = await multicallv2(
    vidiaAbi.abi,
    calls,
    chainId,
  )

  dispatch(
    setVIDIAData({
      unstaking: convertFromWei(new BigNumber(userInfo.unstakingAmt._hex)).toJSON(),
      unstakeAt: parseInt(userInfo.unstakeAt),
      reward:
        new BigNumber(userReward).gte(0) &&
        convertFromWei(new BigNumber(userReward)).toNumber().toLocaleString(undefined, { maximumFractionDigits: 5 }),
      skipDelayFee: parseInt(skipDelayFee),
      cancelUnstakeFee: parseInt(cancelUnstakeFee),
      unstakingDelay: parseInt(unstakingDelay),
    }),
  )
}

export default vIDIASlice.reducer

import React from 'react'
import styled from 'styled-components'

interface ColorBorderBlankCardProps {
  showBorder?: boolean
  hideShadow?: boolean
  borderColor?: string
  className?: string
  containerStyle?: React.CSSProperties
  children?: React.ReactNode
}

const Container = styled.div<Pick<ColorBorderBlankCardProps, 'showBorder' | 'borderColor' | 'hideShadow'>>`
  border-radius: 20px;
  position: relative;

  ${({ theme, showBorder, borderColor, hideShadow }) => {
    const style = []
    if (theme.isDark) {
      style.push(`background: ${theme.colorsV2?.main};`)
    } else {
      style.push(`
		background: ${theme.colorsV2.main2};
	`)
      if (!hideShadow) {
        style.push(`
    box-shadow: ${theme.colorsV2.shadows.settings};
		backdrop-filter: blur(20px);
    `)
      }
    }
    if (showBorder) {
      const _borderColor = borderColor ?? theme.sale.saleBoxBorderColor
      style.push(`
		&:before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: -2;
			margin: -1px;
			border-radius: inherit;
			background: ${_borderColor};
		}

		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: -1;
			border-radius: inherit;
			background: ${theme.colorsV2?.main};
		}`)
    }
    return style.join('')
  }}
`

const ColorBorderBlankCard: React.FC<ColorBorderBlankCardProps> = ({
  showBorder = true,
  borderColor = null,
  containerStyle,
  className,
  children,
  hideShadow = false,
}) => {
  return (
    <Container
      showBorder={showBorder}
      borderColor={borderColor}
      hideShadow={hideShadow}
      className={className}
      style={containerStyle}
    >
      {children}
    </Container>
  )
}

export default ColorBorderBlankCard

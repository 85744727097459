import * as React from 'react'
import { Box } from '@mui/material'
import styled, { useTheme } from 'styled-components'
import BigNumber from 'bignumber.js'
import IFTypography from 'componentsV2/IFTypography/IFTypography'
import ModalV2 from 'uikit/widgets/Modal/ModalV2'
import { Button, LinkExternal, useModalV2 } from 'uikit'
import { useFetchJoin, useJoinBuyback } from 'hooks/useBuyback'
import useTranslation from 'contexts/Localization/useTranslation'
import { useIsUserSignedIn } from 'state/user/hooks'
import useSignOAuth from 'hooks/useSignOAuth'
import { useAccount } from 'wagmi'

const BuyBackBox = styled(Box)`
  border-radius: 20px;
  background: ${({ theme }) =>
    theme.isDark
      ? 'linear-gradient(135deg, rgba(96, 133, 204, 0.15) 0%, rgba(109, 53, 181, 0.38) 100%);'
      : 'linear-gradient(135deg, rgba(239, 239, 255, 0.62) 0%, rgba(235, 237, 255, 0.77) 100%)'};
  padding: 16px;
  margin-top: 16px;
`

const BuyBackBadge = styled(Box)`
  border-radius: 20px;
  padding: 4px 10px;
  background: linear-gradient(
    91deg,
    rgba(13, 189, 181, 0.8) 0%,
    rgba(129, 154, 224, 0.8) 50.52%,
    rgba(130, 111, 228, 0.8) 100%
  );
  width: fit-content;
`

const BuybackSection: React.FC<{ saleId: number; totalPurchased: BigNumber }> = ({ saleId, totalPurchased }) => {
  const isUserSigned = useIsUserSignedIn()
  const { address: account } = useAccount()

  const { signOAuth } = useSignOAuth('if')

  const [isJoinClick, setIsJoinClick] = React.useState(false)

  const { joinBuyback, isLoading } = useJoinBuyback()
  const buybacks = [
    {
      buybackStartTime: '2023-12-21T13:00:00Z',
      buybackEndTime: '2023-12-29T13:00:00Z',
      buybackActive: false,
    },
  ]
  const { t } = useTranslation()
  const [_, onDismiss] = useModalV2({
    modal: <Box />,
  })
  const [onPresentDisclaimerModal] = useModalV2({
    modal: (
      <ModalV2
        bodyTitle={t('Join Buyback Program')}
        bodyImage={
          <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
            <path
              d="M46.5597 3.70689L75.8667 33.0139C79.464 36.6111 79.464 42.536 75.8667 46.1333L46.5597 75.4403C42.9625 79.0375 37.0375 79.0375 33.4403 75.4403L4.1333 46.1333C0.536049 42.536 0.536048 36.6111 4.1333 33.0139L33.4403 3.70689C37.1433 0.00384036 42.9625 0.109643 46.5597 3.70689Z"
              fill="url(#paint0_linear_31578_117635)"
            />
            <path
              d="M36.2044 42.3011C36.2044 44.3823 37.8916 46.0695 39.9728 46.0695C42.0541 46.0695 43.7413 44.3824 43.7413 42.3011V24.2934C43.7413 22.2121 42.0541 20.5249 39.9728 20.5249C37.8916 20.5249 36.2044 22.2121 36.2044 24.2933V42.3011ZM39.9728 58.6221C42.9876 58.6221 45.1948 56.6195 45.1948 53.9332C45.1948 51.4911 43.0414 49.1955 39.9728 49.1955C37.0119 49.1955 34.8047 51.4422 34.8047 53.9332C34.8047 56.6195 37.1196 58.6221 39.9728 58.6221Z"
              fill="white"
            />
            <defs>
              <linearGradient
                id="paint0_linear_31578_117635"
                x1="40"
                y1="77.6708"
                x2="40"
                y2="-16.2734"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#ED696C" stopOpacity="0.53" />
                <stop offset="1" stopColor="#ED696C" />
              </linearGradient>
            </defs>
          </svg>
        }
        bodyImageWidth={36}
        bodyImageMargin="33px 0 33px 0"
        bodyPadding="0px 0 33px 0"
        onConfirm={() => {
          if (window.fathom) {
            window.fathom.trackEvent('Opt in exercise')
          }
          if (!isUserSigned) {
            signOAuth(() =>
              joinBuyback(
                {
                  saleId,
                },
                setIsJoinClick(true),
              ),
            )
            onDismiss()
            return
          }
          joinBuyback(
            {
              saleId,
            },
            setIsJoinClick(true),
          )
          onDismiss()
          return
        }}
        onCancel={() => {
          if (window.fathom) {
            window.fathom.trackEvent('Opt in cancel')
          }
        }}
        showConfirm
        confirmText="Buyback Opt-in"
        showCancel
      >
        <Box marginBottom={2}>
          <IFTypography fontSize="14px" ifcolor="text" textAlign="left">
            By opting in to the buy-back program, you confirm to trade your tokens at the buyback price of{' '}
            <span style={{ fontWeight: 700 }}>0.075 USDT.</span>
          </IFTypography>
        </Box>
        <Box marginBottom={2}>
          <IFTypography fontSize="14px" ifcolor="text" textAlign="left">
            - Refund of your unlocked tokens (20%) will be completed{' '}
            <span style={{ fontWeight: 700 }}>via the Openswap buyback process.</span>
          </IFTypography>
        </Box>
        <Box marginBottom={2}>
          <IFTypography fontSize="14px" ifcolor="text" textAlign="left">
            - Refund of your locked tokens (80%) will be <span style={{ fontWeight: 700 }}>airdropped</span>.
          </IFTypography>
        </Box>
        <Box marginBottom={2}>
          <IFTypography fontSize="14px" ifcolor="text" textAlign="left">
            The above applies to the amount of refund that you are eligible for and would exercise. Please see more
            details in the announcement.
          </IFTypography>
        </Box>

        <Box display="flex" justifyContent="center" marginTop={3}>
          <IFTypography fontSize="12px" ifcolor="text">
            <LinkExternal href="https://blog.impossible.finance/impossiblefinanceido21-eclipse-fi/">
              Sale Details
            </LinkExternal>
          </IFTypography>
        </Box>
      </ModalV2>
    ),
    options: {
      backdropBlurVal: 'blur(20px)',
      closeOnOverlayClick: false,
    },
  })

  const hasOptin = useFetchJoin(saleId, isJoinClick, account)
  const noOfBuybacks = buybacks?.length || 0

  return (
    <BuyBackBox>
      {buybacks.map((bb, index) => {
        const { buybackStartTime, buybackEndTime } = bb

        const isBuybackActive =
          account?.length > 0 &&
          parseFloat(totalPurchased.toString()) > 0 &&
          new Date().getTime() > new Date(buybackStartTime).getTime() &&
          new Date().getTime() < new Date(buybackEndTime).getTime()

        return (
          <Box marginBottom={index < noOfBuybacks - 1 && 4} key={'a'}>
            <BuyBackBadge display="flex" marginBottom="8px">
              <Box style={{ height: 12, width: 15 }} marginRight="5px">
                <img src="/images/buyback.svg" alt="bb" />
              </Box>
              <Box>
                <IFTypography fontSize="11px" color="#FFFFFF">
                  Buyback
                </IFTypography>
              </Box>
            </BuyBackBadge>
            <Box>
              <IFTypography fontSize="12px" ifcolor="text">
                If you would like to join Buyback, please click{' '}
                <span style={{ fontWeight: 700 }}>“Buyback Opt-in”</span> to reserve your requirement within the Opt-in
                window.
              </IFTypography>
            </Box>
            <Box>
              <IFTypography fontSize="12px" ifcolor="text">
                You will received 100% refund based on the amount of ECLIP in the purchase wallet:
              </IFTypography>
            </Box>
            <Box>
              <IFTypography fontSize="12px" ifcolor="text">
                <span style={{ fontWeight: 700 }}>Buy-back #1 - 20% of unlocked ECLIP tokens</span>: exercise the refund
                via the standard OpenSwap process
              </IFTypography>
            </Box>
            <Box>
              <IFTypography fontSize="12px" ifcolor="text">
                <span style={{ fontWeight: 700 }}>Buy-back #2 - Remaining 80% of locked ECLIP</span>: not need to take
                action, it will automatically be airdropped the USDT
              </IFTypography>
            </Box>
            <ul
              style={{
                listStylePosition: 'outside',
                paddingLeft: '12px',
                marginTop: '4px',
              }}
            >
              <IFTypography fontSize="12px" ifcolor="text">
                <li>Buy-back Opt-in Time Window: 22/12/2023 01:00 PM (UTC) - 29/12/2023 01:00 PM (UTC)</li>
              </IFTypography>
              <IFTypography fontSize="12px" ifcolor="text">
                <li>Buy-back Period (aka “Refund Window”): 05/01/2024 06:00 AM (UTC) - 12/01/2024 06:00 AM (UTC)</li>
              </IFTypography>
              <IFTypography fontSize="12px" ifcolor="text">
                <li>Buy-back price: 0.075 USDT.</li>
              </IFTypography>
            </ul>

            {isBuybackActive && (
              <Box display="flex" justifyContent="flex-end" marginTop={2}>
                <Button
                  scale="sm"
                  onClick={() => {
                    if (window.fathom) {
                      window.fathom.trackEvent('Exercise buyback Eclip')
                    }
                    onPresentDisclaimerModal()
                  }}
                  disabled={isLoading || hasOptin}
                  style={{ opacity: isLoading || hasOptin ? 0.5 : 1 }}
                >
                  <IFTypography
                    fontSize="14px"
                    fontWeight="bold"
                    style={{ color: !(isLoading || hasOptin) && '#FFFFFF' }}
                  >
                    {hasOptin ? 'Buyback Opted-in' : `Buyback Opt-in`}
                  </IFTypography>
                </Button>
              </Box>
            )}
          </Box>
        )
      })}
    </BuyBackBox>
  )
}

export default BuybackSection

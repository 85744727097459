import { useMemo } from 'react'
import Web3 from 'web3'
import { useNetwork, useWalletClient } from 'wagmi'
import { getWeb3NoAccount } from 'utils/web3'
import { type PublicClient, getPublicClient } from '@wagmi/core'
import { providers } from 'ethers'
import { type HttpTransport } from 'viem'

export function publicClientToProvider(publicClient: PublicClient) {
  const { chain, transport } = publicClient
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  }
  if (transport.type === 'fallback')
    return new providers.FallbackProvider(
      (transport.transports as ReturnType<HttpTransport>[]).map(
        ({ value }) => new providers.JsonRpcProvider(value?.url, network),
      ),
    )
  return new providers.JsonRpcProvider(transport.url, network)
}

/** Action to convert a viem Public Client to an ethers.js Provider. */
export function getEthersProvider({ chainId }: { chainId?: number } = {}) {
  const publicClient = getPublicClient({ chainId })
  return publicClientToProvider(publicClient)
}

export function walletClientToSigner(walletClient, chainId?) {
  const { account, chain, transport } = walletClient
  const network = {
    chainId: chain?.id || 0,
    name: chain?.name || '',
    ensAddress: chain?.contracts?.ensRegistry?.address,
  }
  const provider = new providers.Web3Provider(transport, network)
  const signer = provider.getSigner(account.address)
  // @ts-ignore
  return signer
}

/** Hook to convert a viem Wallet Client to an ethers.js Signer. */
export function useEthersSigner({ chainId = 0 } = {}) {
  const { data: walletClient } = useWalletClient({ chainId })
  return useMemo(() => (walletClient ? walletClientToSigner(walletClient) : undefined), [walletClient])
}

export function useWeb3() {
  const { chain } = useNetwork()
  const chainId = chain?.id || 0
  const { data: walletClient } = useWalletClient({ chainId })

  return useMemo(
    () =>
      walletClient
        ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          new Web3(walletClientToSigner(walletClient)?.provider?.provider)
        : getWeb3NoAccount(chainId),
    [walletClient],
  )
}

export default useWeb3

import { useTranslation } from 'contexts/Localization'
import React from 'react'
import styled from 'styled-components'

const SwitcherWrapper = styled.div<{ padding?: string; width?: string }>`
  background: ${({ theme }) => (theme.isDark ? theme.colorsV2?.dark2 : `${theme.colorsV2?.light}CC`)};
  border-radius: 25px;
  padding: ${({ padding }) => padding || '6px 6px'};
  display: flex;
  border: ${({ theme }) => `0.5px solid ${theme.colorsV2.light}`};
  ${({ width }) => width && `width: ${width};`}
`

const SwitcherItem = styled.div<{ active: boolean; fontSize?: number; padding?: string }>`
  color: ${({ theme, active }) =>
    active ? theme.colorsV2?.text : theme.isDark ? theme.colorsV2?.light : theme.colorsV2?.textSecondary};
  ${({ active, theme }) => active && `background: ${theme.isDark ? theme.colorsV2?.light : theme.colorsV2?.main};`}
  flex-grow: 1;
  border-radius: 25px;
  font-size: ${({ fontSize }) => `${fontSize}px` || '100%'};
  padding: ${({ padding }) => padding || '8px 0'};
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 90px;
  ${({ theme }) => theme.mediaQueries?.lg} {
    min-width: 126px;
  }
`

type SwitcherProps = {
  options: string[]
  value: number
  onChange: (number) => void
  size?: 'sm' | 'md' | 'lg'
  width?: string
}

const Switcher: React.FC<SwitcherProps> = ({ options, value, onChange, size = 'lg', width }) => {
  const { t } = useTranslation()
  const fontSize = {
    sm: 12,
    md: 16,
    lg: 20,
  }

  const containerPadding = {
    sm: '3px 3px',
    md: '4px 4px',
    lg: '6px 6px',
  }

  const contentPadding = {
    sm: '4px 11px',
    md: '8px 25px',
    lg: '8px 0',
  }

  return (
    <SwitcherWrapper width={width} padding={containerPadding[size]}>
      {options.map((option, idx) => (
        <SwitcherItem
          key={option}
          fontSize={fontSize[size]}
          padding={contentPadding[size]}
          active={value === idx}
          onClick={() => onChange(idx)}
        >
          {t(option)}
        </SwitcherItem>
      ))}
    </SwitcherWrapper>
  )
}

export default Switcher

import React from 'react'

type HistoryContextType = {
  historyStack: string[]
  setHistoryStack: React.Dispatch<React.SetStateAction<string[]>>
}

export const HistoryContext = React.createContext<HistoryContextType>({
  historyStack: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setHistoryStack: () => {},
})

export const HistoryProvider = ({ children }) => {
  const [historyStack, setHistoryStack] = React.useState([])

  return (
    <HistoryContext.Provider value={{ historyStack: historyStack, setHistoryStack: setHistoryStack }}>
      {children}
    </HistoryContext.Provider>
  )
}

import { darkColors, lightColors } from '../../theme/colors'
import { RadioTheme } from './types'

export const light: RadioTheme = {
  handleBackground: lightColors.card,
}

export const dark: RadioTheme = {
  handleBackground: darkColors.card,
}

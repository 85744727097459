import React, { useState } from 'react'
import { escapeRegExp } from 'utils'
import { Text, Button, Input, Flex, Box } from 'uikit'
import { useTranslation } from 'contexts/Localization'
import { useUserSlippageTolerance, useUserTransactionTTL } from 'state/user/hooks'
import DefaultIFTooltip from 'componentsV2/DefaultIFTooltip/DefaultIFTooltip'
import styled from 'styled-components'

enum SlippageError {
  InvalidInput = 'InvalidInput',
  RiskyLow = 'RiskyLow',
  RiskyHigh = 'RiskyHigh',
}

enum DeadlineError {
  InvalidInput = 'InvalidInput',
}

const StyledInputContainer = styled(Flex)`
  position: relative;
  height: 34px;
  width: 109px;
  &:after {
    content: '%';
    color: ${({ theme }) => (theme.isDark ? theme.colorsV2?.text : theme.colorsV2?.textSecondary)};
    position: absolute;
    right: 14px;
    top: 8px;
  }
`

const StyledLabel = styled(Text)`
  color: ${({ theme }) => (theme.isDark ? theme.colorsV2?.textSecondary : theme.colorsV2?.text)};
`
const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`) // match escaped "." characters via in a non-capturing group

const SlippageTabs = () => {
  const [userSlippageTolerance, setUserSlippageTolerance] = useUserSlippageTolerance()
  const [ttl, setTtl] = useUserTransactionTTL()
  const [slippageInput, setSlippageInput] = useState('')
  const [deadlineInput, setDeadlineInput] = useState('')

  const { t } = useTranslation()

  const slippageInputIsValid =
    slippageInput === '' || (userSlippageTolerance / 100).toFixed(2) === Number.parseFloat(slippageInput).toFixed(2)
  const deadlineInputIsValid = deadlineInput === '' || (ttl / 60).toString() === deadlineInput

  let slippageError: SlippageError | undefined
  if (slippageInput !== '' && !slippageInputIsValid) {
    slippageError = SlippageError.InvalidInput
  } else if (slippageInputIsValid && userSlippageTolerance < 50) {
    slippageError = SlippageError.RiskyLow
  } else if (slippageInputIsValid && userSlippageTolerance > 500) {
    slippageError = SlippageError.RiskyHigh
  } else {
    slippageError = undefined
  }

  let deadlineError: DeadlineError | undefined
  if (deadlineInput !== '' && !deadlineInputIsValid) {
    deadlineError = DeadlineError.InvalidInput
  } else {
    deadlineError = undefined
  }

  const parseCustomSlippage = (value: string) => {
    if (value === '' || inputRegex.test(escapeRegExp(value))) {
      setSlippageInput(value)

      try {
        const valueAsIntFromRoundedFloat = Number.parseInt((Number.parseFloat(value) * 100).toString())
        if (!Number.isNaN(valueAsIntFromRoundedFloat) && valueAsIntFromRoundedFloat < 5000) {
          setUserSlippageTolerance(valueAsIntFromRoundedFloat)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  const parseCustomDeadline = (value: string) => {
    setDeadlineInput(value)

    try {
      const valueAsInt: number = Number.parseInt(value) * 60
      if (!Number.isNaN(valueAsInt) && valueAsInt > 0) {
        setTtl(valueAsInt)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Flex flexDirection="column">
      <Flex flexDirection="column" mb="4px">
        <Flex mb="12px" alignItems="center">
          <StyledLabel>{t('Slippage Tolerance')}</StyledLabel>
          <DefaultIFTooltip
            placement="top"
            title={t(
              'Setting a high slippage tolerance increases the probability that the swap executes successfully, at the expense of the swap price. Use with caution.',
            )}
          />
        </Flex>
        <Flex flexWrap="wrap">
          <Button
            mt="4px"
            mr="15px"
            mb="15px"
            scale="smmd"
            onClick={() => {
              setSlippageInput('')
              setUserSlippageTolerance(10)
            }}
            variant={userSlippageTolerance === 10 ? 'radioSelected' : 'radioOption'}
          >
            0.1%
          </Button>
          <Button
            mt="4px"
            mr="15px"
            mb="15px"
            scale="smmd"
            onClick={() => {
              setSlippageInput('')
              setUserSlippageTolerance(50)
            }}
            variant={userSlippageTolerance === 50 ? 'radioSelected' : 'radioOption'}
          >
            0.5%
          </Button>
          <Button
            mr="15px"
            mt="4px"
            mb="15px"
            scale="smmd"
            onClick={() => {
              setSlippageInput('')
              setUserSlippageTolerance(100)
            }}
            variant={userSlippageTolerance === 100 ? 'radioSelected' : 'radioOption'}
          >
            1.0%
          </Button>
          <Flex alignItems="center">
            <Box height="34px" width="76px" mt="4px" mr="15px" mb="15px">
              <StyledInputContainer>
                <Input
                  style={{ borderRadius: '50px', height: '100%', paddingRight: '30px' }}
                  scale="sm"
                  inputMode="decimal"
                  pattern="^[0-9]*[.,]?[0-9]{0,2}$"
                  placeholder={(userSlippageTolerance / 100).toFixed(2)}
                  value={slippageInput}
                  onBlur={() => {
                    parseCustomSlippage((userSlippageTolerance / 100).toFixed(2))
                  }}
                  onChange={(event) => {
                    if (event.currentTarget.validity.valid) {
                      parseCustomSlippage(event.target.value.replace(/,/g, '.'))
                    }
                  }}
                  isWarning={!slippageInputIsValid}
                  isSuccess={![10, 50, 100].includes(userSlippageTolerance)}
                />
              </StyledInputContainer>
            </Box>
          </Flex>
        </Flex>
        {!!slippageError && (
          <Text fontSize="14px" color={slippageError === SlippageError.InvalidInput ? 'red' : '#F3841E'} mt="8px">
            {slippageError === SlippageError.InvalidInput
              ? t('Enter a valid slippage percentage')
              : slippageError === SlippageError.RiskyLow
              ? t('Your transaction may fail')
              : t('Your transaction may be frontrun')}
          </Text>
        )}
      </Flex>
      <Flex justifyContent="space-between" alignItems="flex-start" mb="12px" flexDirection="column">
        <Flex alignItems="center">
          <StyledLabel>{t('Transaction deadline')}</StyledLabel>
          <DefaultIFTooltip
            placement="top"
            title={t('Your transaction will revert if it is pending for more than this period of time.')}
          />
        </Flex>
        <Flex flexDirection="row" alignItems="center" mt="15px">
          <Box width="70px" mr="5px">
            <Input
              style={{ borderRadius: '50px' }}
              scale="sm"
              inputMode="numeric"
              pattern="^[0-9]+$"
              color={deadlineError ? 'red' : undefined}
              onBlur={() => {
                parseCustomDeadline((ttl / 60).toString())
              }}
              placeholder={(ttl / 60).toString()}
              value={deadlineInput}
              onChange={(event) => {
                if (event.currentTarget.validity.valid) {
                  parseCustomDeadline(event.target.value)
                }
              }}
            />
          </Box>
          <Text>{t('minutes')}</Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default SlippageTabs

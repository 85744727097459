import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0.652225C0 0.327379 0.26334 0.0640392 0.588185 0.0640392H3.45144C3.77628 0.0640392 4.03962 0.327379 4.03962 0.652225C4.03962 0.97707 3.77628 1.24041 3.45144 1.24041H1.17637V8.31569H8.13157V6.12554C8.13157 5.80069 8.39491 5.53735 8.71976 5.53735C9.0446 5.53735 9.30794 5.80069 9.30794 6.12554V8.90387C9.30794 9.22872 9.0446 9.49206 8.71976 9.49206H0.588185C0.26334 9.49206 0 9.22872 0 8.90387V0.652225Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.3839 4.2288C9.05907 4.22631 8.79776 3.96096 8.80025 3.63612L8.81272 2.01034L7.97388 1.18548L6.37695 1.1937C6.05211 1.19537 5.78742 0.933385 5.78575 0.608543C5.78408 0.283702 6.04606 0.0190114 6.3709 0.0173409L9.40879 0.00171678C9.56609 0.00090771 9.71716 0.0631401 9.82825 0.17451C9.93934 0.28588 10.0012 0.437107 9.99998 0.594405L9.97658 3.64514C9.97409 3.96998 9.70874 4.23129 9.3839 4.2288Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.81272 2.01034L5.85924 4.94374C5.62876 5.17266 5.25635 5.17139 5.02743 4.94091C4.79851 4.71042 4.79978 4.33801 5.03027 4.10909L7.97388 1.18548L8.81272 2.01034Z"
      />
    </Svg>
  )
}

export default Icon

import contracts from 'config/constants/contracts'
import { HistoryContext } from 'contexts/SwapNotSupportedContext'
import { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useNetwork } from 'wagmi'

const useSwapAvailabilityCheck = () => {
  const { historyStack, setHistoryStack } = useContext(HistoryContext)
  const { replace, location } = useHistory()
  const { chain } = useNetwork()
  const chainId = chain?.id

  useEffect(() => {
    if (chainId && !contracts.router[chainId] && location.pathname !== '/swapNotSupported') {
      setHistoryStack((prev) => [...prev, location.pathname])
      replace('swapNotSupported')
    }
    if (chainId && contracts.router[chainId] && location.pathname === '/swapNotSupported') {
      const lastUrl = historyStack.pop()
      setHistoryStack(historyStack)
      if (lastUrl) {
        replace(lastUrl)
      } else {
        replace('swap') // navigate to swap if there's no history
      }
    }
  }, [replace, chainId, setHistoryStack, historyStack, location])
}

export default useSwapAvailabilityCheck

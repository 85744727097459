import React from 'react'

export const LogoLightWithText: React.FC = ({ ...props }) => {
  return (
    <svg width="134" height="35" viewBox="0 0 134 35" fill="none" {...props}>
      <path
        d="M4.22672 12.3795C6.54921 12.3795 8.43196 10.4707 8.43196 8.11616C8.43196 5.76159 6.54921 3.85284 4.22672 3.85284C1.90423 3.85284 0.0214844 5.76159 0.0214844 8.11616C0.0214844 10.4707 1.90423 12.3795 4.22672 12.3795Z"
        fill="#050A5A"
      />
      <path
        d="M23.4863 12.3795H17.662C16.9471 12.3795 16.3584 11.7826 16.3584 11.0579V5.15316C16.3584 4.42839 16.9471 3.83152 17.662 3.83152H23.4863C24.2012 3.83152 24.7899 4.42839 24.7899 5.15316V11.0579C24.7899 11.8039 24.2012 12.3795 23.4863 12.3795Z"
        fill="#00FFB9"
      />
      <path
        d="M8.4315 16.4083H8.17919H1.78723C0.798995 16.4083 0 17.2183 0 18.2202V24.9563V31.671C0 32.6729 0.798995 33.4829 1.78723 33.4829H6.64428C7.63251 33.4829 8.4315 32.6729 8.4315 31.671V26.7682C8.4315 25.7663 9.2305 24.9563 10.2187 24.9563H14.8235C15.8117 24.9563 16.6107 24.1462 16.6107 23.1444V18.2202C16.6107 17.2183 15.8117 16.4083 14.8235 16.4083H8.4315Z"
        fill="#00DAFF"
      />
      <path d="M40.6858 0.975098H43.1669V15.8541H40.6858V0.975098Z" fill="#050A5A" />
      <path
        d="M45.8162 6.1124H48.2973V7.07165C48.865 6.34689 49.685 5.92056 50.6312 5.92056C51.7245 5.92056 52.7128 6.4961 53.3856 7.45535C54.0164 6.51742 55.0046 5.92056 56.2452 5.92056C58.2847 5.92056 59.7986 7.56193 59.7986 9.69359V15.8541H57.3175V9.88544C57.3175 8.92619 56.6447 8.1588 55.7195 8.1588C54.7103 8.1588 54.0584 8.99014 54.0584 9.99202V15.8541H51.5773V9.88544C51.5773 8.92619 50.9045 8.1588 49.9373 8.1588C48.9491 8.1588 48.3183 8.99014 48.3183 9.99202V15.8541H45.8162V6.1124Z"
        fill="#050A5A"
      />
      <path
        d="M62.0906 6.1124H64.5717V7.15692C65.3286 6.38952 66.3589 5.92056 67.6205 5.92056C70.2067 5.92056 72.3514 8.1588 72.3514 10.9939C72.3514 13.829 70.2067 16.0673 67.6205 16.0673C66.3589 16.0673 65.3286 15.5983 64.5717 14.8309V20.3306H62.0906V6.1124V6.1124ZM69.8913 10.9939C69.8913 9.43779 68.6718 8.20143 67.1579 8.20143C65.644 8.20143 64.4035 9.43779 64.4035 10.9939C64.4035 12.55 65.644 13.7864 67.1579 13.7864C68.6718 13.7651 69.8913 12.5287 69.8913 10.9939Z"
        fill="#050A5A"
      />
      <path
        d="M73.5918 10.9939C73.5918 8.18011 75.7785 5.92056 78.6801 5.92056C81.5397 5.92056 83.7475 8.1588 83.7475 10.9939C83.7475 13.829 81.5397 16.0673 78.6801 16.0673C75.7785 16.0459 73.5918 13.8077 73.5918 10.9939ZM81.3505 10.9939C81.3505 9.45911 80.152 8.26538 78.6801 8.26538C77.1452 8.26538 75.9467 9.45911 75.9467 10.9939C75.9467 12.5287 77.1452 13.7438 78.6801 13.7438C80.152 13.7224 81.3505 12.5074 81.3505 10.9939Z"
        fill="#050A5A"
      />
      <path
        d="M84.7568 14.5964L86.1446 12.8698C86.8174 13.5732 87.7005 13.9996 88.4574 13.9996C89.2144 13.9996 89.677 13.5945 89.677 13.0616C89.677 12.3795 88.899 12.209 87.7426 11.8039C86.2497 11.271 84.9881 10.5889 84.9881 8.88356C84.9881 6.90111 86.6702 5.89923 88.5205 5.89923C89.9083 5.89923 91.0857 6.45346 91.8216 7.28481L90.3708 8.77697C89.8242 8.22274 89.0882 7.83904 88.4364 7.83904C87.8267 7.83904 87.4272 8.15879 87.4272 8.67039C87.4272 9.33121 88.121 9.52305 89.2354 9.88544C90.6652 10.3757 92.116 11.0365 92.116 12.9763C92.116 14.9588 90.6021 16.0246 88.5205 16.0246C86.8805 16.0459 85.5348 15.3638 84.7568 14.5964Z"
        fill="#050A5A"
      />
      <path
        d="M92.978 14.5964L94.3658 12.8698C95.0386 13.5732 95.9217 13.9996 96.6786 13.9996C97.4356 13.9996 97.8982 13.5945 97.8982 13.0616C97.8982 12.3795 97.1202 12.209 95.9637 11.8039C94.4709 11.271 93.2093 10.5889 93.2093 8.88356C93.2093 6.90111 94.8914 5.89923 96.7417 5.89923C98.1295 5.89923 99.3069 6.45346 100.043 7.28481L98.592 8.77697C98.0453 8.22274 97.3094 7.83904 96.6576 7.83904C96.0479 7.83904 95.6484 8.15879 95.6484 8.67039C95.6484 9.33121 96.3422 9.52305 97.4566 9.88544C98.8864 10.3757 100.337 11.0365 100.337 12.9763C100.337 14.9588 98.8233 16.0246 96.7417 16.0246C95.1017 16.0459 93.756 15.3638 92.978 14.5964Z"
        fill="#050A5A"
      />
      <path
        d="M101.977 2.93624C101.977 2.12621 102.65 1.48671 103.428 1.48671C104.227 1.48671 104.879 2.12621 104.879 2.93624C104.879 3.74627 104.227 4.38576 103.428 4.38576C102.629 4.38576 101.977 3.74627 101.977 2.93624ZM102.208 6.11241H104.647V15.8541H102.208V6.11241Z"
        fill="#050A5A"
      />
      <path
        d="M109.61 14.5964L109.547 15.8541H107.234V0.975098H109.715V7.22086C110.451 6.32557 111.607 5.87792 112.785 5.87792C115.476 5.87792 117.6 8.13748 117.6 10.9726C117.6 13.8077 115.476 16.0673 112.785 16.0673C111.46 16.0459 110.325 15.513 109.61 14.5964ZM115.118 10.9726C115.118 9.37384 113.92 8.13748 112.364 8.13748C110.829 8.13748 109.61 9.37384 109.61 10.9726C109.61 12.5713 110.829 13.829 112.364 13.829C113.92 13.829 115.118 12.5713 115.118 10.9726Z"
        fill="#050A5A"
      />
      <path d="M119.534 0.975098H121.994V15.8541H119.534V0.975098Z" fill="#050A5A" />
      <path
        d="M133.979 11.4202H126.41C126.599 12.9977 127.713 14.0209 129.122 14.0209C130.131 14.0209 131.078 13.5093 131.582 12.614L133.559 13.4453C132.781 15.0441 131.162 16.0459 129.143 16.0459C126.094 16.0459 123.992 13.8077 123.992 10.9726C123.992 8.13747 126.136 5.89923 129.059 5.89923C131.834 5.89923 133.958 7.88167 134 10.866L133.979 11.4202ZM126.662 9.52305H131.372C130.972 8.54249 130.215 7.92431 129.08 7.92431C127.903 7.90299 127.083 8.52117 126.662 9.52305Z"
        fill="#050A5A"
      />
      <path
        d="M40.6858 19.8829H49.8322V22.3983H43.1669V26.0648H49.0752V28.5801H43.1669V34.7619H40.6858V19.8829Z"
        fill="#050A5A"
      />
      <path
        d="M51.4092 21.8441C51.4092 21.034 52.082 20.3945 52.86 20.3945C53.659 20.3945 54.3108 21.034 54.3108 21.8441C54.3108 22.6541 53.659 23.2936 52.86 23.2936C52.061 23.2936 51.4092 22.6541 51.4092 21.8441ZM51.6405 25.0202H54.0795V34.7619H51.6405V25.0202Z"
        fill="#050A5A"
      />
      <path
        d="M56.6658 25.0203H59.1259V25.9156C59.8197 25.2121 60.7239 24.8284 61.6911 24.8284C63.6255 24.8284 65.1394 26.4272 65.1394 28.4735V34.7832H62.6583V28.7933C62.6583 27.7701 61.9013 27.0453 60.9131 27.0453C59.8828 27.0453 59.1259 27.7914 59.1048 28.7933V34.7832H56.6448V25.0203H56.6658Z"
        fill="#050A5A"
      />
      <path
        d="M66.8635 29.8804C66.8635 27.0666 69.0292 24.7858 71.6155 24.7858C72.9822 24.7858 74.0335 25.2974 74.7484 26.1287V25.0202H77.2295V34.7619H74.9166L74.8535 33.5042C74.1386 34.4209 73.0452 34.9538 71.6155 34.9538C69.0082 34.9538 66.8635 32.7155 66.8635 29.8804ZM74.8114 29.8804C74.8114 28.3243 73.6129 27.0879 72.078 27.0879C70.5431 27.0879 69.3236 28.3243 69.3236 29.8804C69.3236 31.4365 70.5221 32.6942 72.078 32.6942C73.6129 32.6729 74.8114 31.4152 74.8114 29.8804Z"
        fill="#050A5A"
      />
      <path
        d="M79.7945 25.0203H82.2545V25.9156C82.9484 25.2121 83.8525 24.8284 84.8197 24.8284C86.7541 24.8284 88.268 26.4272 88.268 28.4735V34.7832H85.7869V28.7933C85.7869 27.7701 85.03 27.0453 84.0418 27.0453C83.0115 27.0453 82.2545 27.7914 82.2335 28.7933V34.7832H79.7734V25.0203H79.7945Z"
        fill="#050A5A"
      />
      <path
        d="M89.9922 29.9018C89.9922 27.088 92.1789 24.8284 95.0805 24.8284C95.7954 24.8284 96.5103 24.9776 97.12 25.2548V28.0472C96.6364 27.4717 95.9216 27.1093 95.0805 27.1093C93.5246 27.1093 92.3681 28.3457 92.3681 29.9018C92.3681 31.4579 93.5036 32.6729 95.0805 32.6729C95.9216 32.6729 96.6364 32.3105 97.12 31.735V34.5488C96.5103 34.8259 95.7954 34.9751 95.0805 34.9751C92.1789 34.9538 89.9922 32.7155 89.9922 29.9018Z"
        fill="#050A5A"
      />
      <path
        d="M108.558 30.3281H100.989C101.178 31.9055 102.293 32.9287 103.701 32.9287C104.711 32.9287 105.657 32.4171 106.161 31.5218L108.138 32.3532C107.36 33.9519 105.741 34.9538 103.722 34.9538C100.674 34.9538 98.571 32.7155 98.571 29.8804C98.571 27.0453 100.716 24.8071 103.638 24.8071C106.414 24.8071 108.537 26.7895 108.58 29.7738L108.558 30.3281ZM101.22 28.4309H105.93C105.531 27.4503 104.774 26.8322 103.638 26.8322C102.482 26.8108 101.641 27.429 101.22 28.4309Z"
        fill="#050A5A"
      />
    </svg>
  )
}

export const LogoDark: React.FC = (props) => {
  return (
    <svg width="22" height="26" viewBox="0 0 22 26" fill="none" {...props}>
      <path
        d="M3.75052 7.59436C5.81162 7.59436 7.48248 5.89856 7.48248 3.8067C7.48248 1.71483 5.81162 0.019043 3.75052 0.019043C1.68941 0.019043 0.0185547 1.71483 0.0185547 3.8067C0.0185547 5.89856 1.68941 7.59436 3.75052 7.59436Z"
        fill="white"
      />
      <path
        d="M20.843 7.59425H15.6742C15.0398 7.59425 14.5173 7.06398 14.5173 6.42007V1.17418C14.5173 0.530274 15.0398 0 15.6742 0H20.843C21.4774 0 21.9999 0.530274 21.9999 1.17418V6.42007C21.9999 7.08291 21.4774 7.59425 20.843 7.59425Z"
        fill="#00FFB9"
      />
      <path
        d="M7.48258 10.8306H7.25866H1.58608C0.709073 10.8306 0 11.5502 0 12.4403V18.4248V24.3904C0 25.2805 0.709073 26.0001 1.58608 26.0001H5.8965C6.77351 26.0001 7.48258 25.2805 7.48258 24.3904V20.0346C7.48258 19.1445 8.19165 18.4248 9.06866 18.4248H13.1552C14.0322 18.4248 14.7412 17.7052 14.7412 16.8151V12.4403C14.7412 11.5502 14.0322 10.8306 13.1552 10.8306H7.48258Z"
        fill="#00DAFF"
      />
    </svg>
  )
}

export const LogoLight: React.FC = (props) => {
  return (
    <svg width="22" height="26" viewBox="0 0 22 26" fill="none" {...props}>
      <path
        d="M3.74952 7.49713C5.81014 7.49713 7.48061 5.82299 7.48061 3.75784C7.48061 1.69269 5.81014 0.0185547 3.74952 0.0185547C1.6889 0.0185547 0.0184326 1.69269 0.0184326 3.75784C0.0184326 5.82299 1.6889 7.49713 3.74952 7.49713Z"
        fill="#050A5A"
      />
      <path
        d="M20.8434 7.49726H15.6758C15.0415 7.49726 14.5192 6.97376 14.5192 6.33808V1.15918C14.5192 0.523502 15.0415 0 15.6758 0H20.8434C21.4777 0 22 0.523502 22 1.15918V6.33808C22 6.99246 21.4777 7.49726 20.8434 7.49726Z"
        fill="#00FFB9"
      />
      <path
        d="M7.48083 11.0244H7.25697H1.58571C0.708907 11.0244 0 11.7349 0 12.6136V18.5217V24.411C0 25.2898 0.708907 26.0002 1.58571 26.0002H5.89512C6.77192 26.0002 7.48083 25.2898 7.48083 24.411V20.1109C7.48083 19.2321 8.18974 18.5217 9.06654 18.5217H13.1521C14.0289 18.5217 14.7378 17.8112 14.7378 16.9325V12.6136C14.7378 11.7349 14.0289 11.0244 13.1521 11.0244H7.48083Z"
        fill="#00DAFF"
      />
    </svg>
  )
}

export const LogoDarkWithText: React.FC = ({ ...props }) => {
  return (
    <svg viewBox="0 0 134 35" width="134" height="35" fill="none" {...props}>
      <path
        d="M4.10022 10.9133C6.35338 10.9133 8.17993 9.08679 8.17993 6.83362C8.17993 4.58046 6.35338 2.75391 4.10022 2.75391C1.84706 2.75391 0.0205078 4.58046 0.0205078 6.83362C0.0205078 9.08679 1.84706 10.9133 4.10022 10.9133Z"
        fill="white"
      />
      <path
        d="M22.7852 10.9132H17.1348C16.4413 10.9132 15.8701 10.3421 15.8701 9.64851V3.99811C15.8701 3.30456 16.4413 2.7334 17.1348 2.7334H22.7852C23.4788 2.7334 24.05 3.30456 24.05 3.99811V9.64851C24.05 10.3625 23.4788 10.9132 22.7852 10.9132Z"
        fill="#00FFB9"
      />
      <path
        d="M8.17982 14.7686H7.93503H1.73388C0.775145 14.7686 0 15.5437 0 16.5024V22.9484V29.3739C0 30.3326 0.775145 31.1078 1.73388 31.1078H6.44594C7.40467 31.1078 8.17982 30.3326 8.17982 29.3739V24.6823C8.17982 23.7235 8.95496 22.9484 9.91369 22.9484H14.381C15.3397 22.9484 16.1149 22.1732 16.1149 21.2145V16.5024C16.1149 15.5437 15.3397 14.7686 14.381 14.7686H8.17982Z"
        fill="#00DAFF"
      />
      <path d="M39.4712 0H41.8782V14.2382H39.4712V0Z" fill="white" />
      <path
        d="M44.4482 4.91601H46.8553V5.83394C47.406 5.14039 48.2016 4.73242 49.1195 4.73242C50.1802 4.73242 51.139 5.28318 51.7917 6.20111C52.4037 5.30358 53.3624 4.73242 54.5659 4.73242C56.5446 4.73242 58.0133 6.30311 58.0133 8.34296V14.2381H55.6063V8.52655C55.6063 7.60861 54.9535 6.87427 54.056 6.87427C53.0768 6.87427 52.4445 7.66981 52.4445 8.62854V14.2381H50.0374V8.52655C50.0374 7.60861 49.3847 6.87427 48.4464 6.87427C47.4876 6.87427 46.8757 7.66981 46.8757 8.62854V14.2381H44.4482V4.91601Z"
        fill="white"
      />
      <path
        d="M60.2368 4.91601H62.6439V5.91554C63.3782 5.18119 64.3777 4.73242 65.6017 4.73242C68.1107 4.73242 70.1913 6.87427 70.1913 9.58728C70.1913 12.3003 68.1107 14.4421 65.6017 14.4421C64.3777 14.4421 63.3782 13.9934 62.6439 13.259V18.5218H60.2368V4.91601V4.91601ZM67.8047 9.58728C67.8047 8.09818 66.6216 6.91506 65.1529 6.91506C63.6842 6.91506 62.4807 8.09818 62.4807 9.58728C62.4807 11.0764 63.6842 12.2595 65.1529 12.2595C66.6216 12.2391 67.8047 11.056 67.8047 9.58728Z"
        fill="white"
      />
      <path
        d="M71.395 9.58728C71.395 6.89467 73.5165 4.73242 76.3315 4.73242C79.1057 4.73242 81.2475 6.87427 81.2475 9.58728C81.2475 12.3003 79.1057 14.4421 76.3315 14.4421C73.5165 14.4217 71.395 12.2799 71.395 9.58728ZM78.9221 9.58728C78.9221 8.11858 77.7594 6.97626 76.3315 6.97626C74.8424 6.97626 73.6797 8.11858 73.6797 9.58728C73.6797 11.056 74.8424 12.2187 76.3315 12.2187C77.7594 12.1983 78.9221 11.0356 78.9221 9.58728Z"
        fill="white"
      />
      <path
        d="M82.2266 13.0345L83.5729 11.3822C84.2256 12.0554 85.0824 12.4634 85.8167 12.4634C86.5511 12.4634 86.9998 12.0758 86.9998 11.5658C86.9998 10.9131 86.2451 10.7499 85.1232 10.3623C83.6749 9.85235 82.4509 9.1996 82.4509 7.56772C82.4509 5.67065 84.0828 4.71191 85.8779 4.71191C87.2242 4.71191 88.3665 5.24228 89.0805 6.03782L87.673 7.46572C87.1426 6.93536 86.4287 6.56819 85.7963 6.56819C85.2048 6.56819 84.8172 6.87416 84.8172 7.36373C84.8172 7.99609 85.4903 8.17967 86.5715 8.52645C87.9586 8.99561 89.3661 9.62797 89.3661 11.4842C89.3661 13.3813 87.8974 14.4012 85.8779 14.4012C84.2868 14.4216 82.9813 13.7689 82.2266 13.0345Z"
        fill="white"
      />
      <path
        d="M90.2026 13.0345L91.5489 11.3822C92.2017 12.0554 93.0584 12.4634 93.7928 12.4634C94.5271 12.4634 94.9759 12.0758 94.9759 11.5658C94.9759 10.9131 94.2212 10.7499 93.0992 10.3623C91.6509 9.85235 90.427 9.1996 90.427 7.56772C90.427 5.67065 92.0589 4.71191 93.854 4.71191C95.2003 4.71191 96.3426 5.24228 97.0566 6.03782L95.649 7.46572C95.1187 6.93536 94.4047 6.56819 93.7724 6.56819C93.1808 6.56819 92.7933 6.87416 92.7933 7.36373C92.7933 7.99609 93.4664 8.17967 94.5475 8.52645C95.9346 8.99561 97.3421 9.62797 97.3421 11.4842C97.3421 13.3813 95.8734 14.4012 93.854 14.4012C92.2629 14.4216 90.9574 13.7689 90.2026 13.0345Z"
        fill="white"
      />
      <path
        d="M98.9331 1.87636C98.9331 1.10122 99.5859 0.489258 100.341 0.489258C101.116 0.489258 101.748 1.10122 101.748 1.87636C101.748 2.65151 101.116 3.26346 100.341 3.26346C99.5655 3.26346 98.9331 2.65151 98.9331 1.87636ZM99.1575 4.91574H101.524V14.2379H99.1575V4.91574Z"
        fill="white"
      />
      <path
        d="M106.338 13.0347L106.277 14.2382H104.033V0H106.44V5.97678C107.154 5.12004 108.276 4.69167 109.418 4.69167C112.029 4.69167 114.089 6.85392 114.089 9.56692C114.089 12.2799 112.029 14.4422 109.418 14.4422C108.133 14.4218 107.031 13.9118 106.338 13.0347ZM111.682 9.56692C111.682 8.03703 110.519 6.85392 109.01 6.85392C107.521 6.85392 106.338 8.03703 106.338 9.56692C106.338 11.0968 107.521 12.3003 109.01 12.3003C110.519 12.3003 111.682 11.0968 111.682 9.56692Z"
        fill="white"
      />
      <path d="M115.966 0H118.352V14.2382H115.966V0Z" fill="white" />
      <path
        d="M129.979 9.99514H122.636C122.819 11.5046 123.901 12.4838 125.267 12.4838C126.246 12.4838 127.164 11.9942 127.654 11.1375L129.571 11.933C128.817 13.4629 127.246 14.4216 125.288 14.4216C122.33 14.4216 120.29 12.2798 120.29 9.56677C120.29 6.85376 122.371 4.71191 125.206 4.71191C127.899 4.71191 129.959 6.60898 130 9.46478L129.979 9.99514ZM122.881 8.17967H127.45C127.062 7.24134 126.328 6.64978 125.226 6.64978C124.084 6.62938 123.289 7.22094 122.881 8.17967Z"
        fill="white"
      />
      <path
        d="M39.4712 18.0938H48.3446V20.5008H41.8782V24.0093H47.6102V26.4164H41.8782V32.3319H39.4712V18.0938Z"
        fill="white"
      />
      <path
        d="M49.8745 19.9701C49.8745 19.195 50.5273 18.583 51.282 18.583C52.0572 18.583 52.6895 19.195 52.6895 19.9701C52.6895 20.7453 52.0572 21.3572 51.282 21.3572C50.5069 21.3572 49.8745 20.7453 49.8745 19.9701ZM50.0989 23.0095H52.4651V32.3316H50.0989V23.0095Z"
        fill="white"
      />
      <path
        d="M54.974 23.0098H57.3606V23.8665C58.0338 23.1933 58.9109 22.8262 59.8493 22.8262C61.7259 22.8262 63.1946 24.3561 63.1946 26.3143V32.3523H60.7876V26.6203C60.7876 25.6412 60.0533 24.9476 59.0945 24.9476C58.095 24.9476 57.3606 25.6616 57.3402 26.6203V32.3523H54.9536V23.0098H54.974Z"
        fill="white"
      />
      <path
        d="M64.8677 27.6604C64.8677 24.9678 66.9687 22.7852 69.4778 22.7852C70.8037 22.7852 71.8236 23.2747 72.5171 24.0703V23.0095H74.9242V32.3317H72.6803L72.6191 31.1282C71.9256 32.0053 70.8649 32.5153 69.4778 32.5153C66.9483 32.5153 64.8677 30.3734 64.8677 27.6604ZM72.5783 27.6604C72.5783 26.1713 71.4156 24.9882 69.9265 24.9882C68.4374 24.9882 67.2543 26.1713 67.2543 27.6604C67.2543 29.1495 68.417 30.353 69.9265 30.353C71.4156 30.3326 72.5783 29.1291 72.5783 27.6604Z"
        fill="white"
      />
      <path
        d="M77.4125 23.0098H79.7991V23.8665C80.4723 23.1933 81.3494 22.8262 82.2877 22.8262C84.1644 22.8262 85.6331 24.3561 85.6331 26.3143V32.3523H83.2261V26.6203C83.2261 25.6412 82.4917 24.9476 81.533 24.9476C80.5335 24.9476 79.7991 25.6616 79.7787 26.6203V32.3523H77.3921V23.0098H77.4125Z"
        fill="white"
      />
      <path
        d="M87.3057 27.681C87.3057 24.9884 89.4271 22.8262 92.2421 22.8262C92.9356 22.8262 93.6292 22.969 94.2208 23.2341V25.9063C93.7516 25.3556 93.058 25.0088 92.2421 25.0088C90.7326 25.0088 89.6107 26.1919 89.6107 27.681C89.6107 29.1701 90.7122 30.3328 92.2421 30.3328C93.058 30.3328 93.7516 29.986 94.2208 29.4353V32.1279C93.6292 32.3931 92.9356 32.5359 92.2421 32.5359C89.4271 32.5155 87.3057 30.3736 87.3057 27.681Z"
        fill="white"
      />
      <path
        d="M105.318 28.0889H97.9743C98.1578 29.5984 99.239 30.5775 100.606 30.5775C101.585 30.5775 102.503 30.0879 102.992 29.2312L104.91 30.0267C104.155 31.5566 102.584 32.5154 100.626 32.5154C97.6683 32.5154 95.6284 30.3735 95.6284 27.6605C95.6284 24.9475 97.7091 22.8057 100.544 22.8057C103.237 22.8057 105.297 24.7027 105.338 27.5585L105.318 28.0889ZM98.1986 26.2734H102.768C102.38 25.3351 101.646 24.7435 100.544 24.7435C99.4225 24.7231 98.6066 25.3147 98.1986 26.2734Z"
        fill="white"
      />
    </svg>
  )
}

export default React.memo(LogoLightWithText)

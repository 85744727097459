import React, { useMemo, useState } from 'react'
import { SubmitKYCBody, SubmitKYCSourceBody } from 'state/v2_types'
import Bab from './Bab'
import { CompliancePage } from './ComplianceCard'
import ComplianceModal from './ComplianceModal'
import SuccessPage from './SuccessPage'
import KYCList from './KYCList'

interface Props {
  submitKYC: (body: SubmitKYCBody, onSucceed?: any) => void
  submitKYCSource: (body: SubmitKYCSourceBody) => void
  isLoading: boolean
  recompliance?: boolean
}

const MainPage: React.FC<Props> = ({ submitKYC, isLoading, recompliance, submitKYCSource }) => {
  const [page, setPage] = useState<CompliancePage>('compliance')

  const Page = useMemo(() => {
    switch (page) {
      case 'compliance':
        return (
          <ComplianceModal recompliance={recompliance} submitKYC={submitKYC} isLoading={isLoading} setPage={setPage} />
        )
      case 'kycList':
        return (
          <KYCList
            recompliance={recompliance}
            submitKYC={submitKYC}
            submitKYCSource={submitKYCSource}
            isLoading={isLoading}
            setPage={setPage}
          />
        )
      case 'bab':
        return <Bab />
      case 'success':
        return <SuccessPage />
      default:
        return (
          <ComplianceModal recompliance={recompliance} submitKYC={submitKYC} isLoading={isLoading} setPage={setPage} />
        )
    }
  }, [page])

  return Page
}

export default MainPage

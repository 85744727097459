import React, { useMemo } from 'react'
import { Text, ArrowDownIcon } from 'uikit'
import { Field } from 'state/swap/actions'
import { useTranslation } from 'contexts/Localization'
import { computeSlippageAdjustedAmounts, computeTradePriceBreakdown, warningSeverity } from 'utils/prices'
import { AutoColumn } from 'componentsV2/layout/Column'
import { CurrencyLogo } from 'componentsV2/Logo'
import styled from 'styled-components'
import { RowBetween, RowFixed } from 'componentsV2/layout/Row'
import truncateHash from 'utils/truncateHash'
import { TradeType } from 'swap-sdk/constants'
import { Trade } from 'swap-sdk/entities/trade'
import { TruncatedText } from './styleds'

const StyledRowBetween = styled(RowBetween)`
  background: ${({ theme }) => (theme.isDark ? theme.colorsV2?.light : theme.colorsV2?.dark2)};
  padding: 10px;
  border-radius: 5px;
`

export default function SwapModalHeader({
  trade,
  allowedSlippage,
  recipient,
}: {
  trade: Trade
  allowedSlippage: number
  recipient: string | null
}) {
  const { t } = useTranslation()
  const slippageAdjustedAmounts = useMemo(
    () => computeSlippageAdjustedAmounts(trade, allowedSlippage),
    [trade, allowedSlippage],
  )
  const { priceImpactWithoutFee } = useMemo(() => computeTradePriceBreakdown(trade), [trade])
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  const amount =
    trade.tradeType === TradeType.EXACT_INPUT
      ? slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(6)
      : slippageAdjustedAmounts[Field.INPUT]?.toSignificant(6)
  const symbol =
    trade.tradeType === TradeType.EXACT_INPUT ? trade.outputAmount.currency.symbol : trade.inputAmount.currency.symbol

  const tradeInfoText =
    trade.tradeType === TradeType.EXACT_INPUT
      ? t('Amount received is estimated. You will receive at least %amount% %symbol% or the transaction will revert.', {
          amount,
          symbol,
        })
      : t('Input is estimated. You will sell at most %amount% %symbol% or the transaction will revert.', {
          amount,
          symbol,
        })

  const [estimatedText, transactionRevertText] = tradeInfoText.split(`${amount} ${symbol}`)

  const truncatedRecipient = recipient ? truncateHash(recipient) : ''

  const recipientInfoText = t('Output will be sent to %recipient%', {
    recipient: truncatedRecipient,
  })

  const [recipientSentToText, postSentToText] = recipientInfoText.split(truncatedRecipient)

  return (
    <AutoColumn gap="md">
      <StyledRowBetween align="flex-end">
        <RowFixed gap="0px">
          <CurrencyLogo currency={trade.inputAmount.currency} size="32px" style={{ marginRight: '12px' }} />
          <TruncatedText fontSize="24px">{trade.inputAmount.currency.symbol}</TruncatedText>
        </RowFixed>
        <RowFixed gap="0px">
          <Text fontFamily="Acre" fontSize="24px" ml="10px" color="text">
            {trade.inputAmount.toSignificant(6)}
          </Text>
        </RowFixed>
      </StyledRowBetween>
      <AutoColumn justify="center">
        <ArrowDownIcon width="20px" />
      </AutoColumn>
      <StyledRowBetween align="flex-end">
        <RowFixed gap="0px">
          <CurrencyLogo currency={trade.outputAmount.currency} size="32px" style={{ marginRight: '12px' }} />
          <TruncatedText fontSize="24px">{trade.outputAmount.currency.symbol}</TruncatedText>
        </RowFixed>
        <RowFixed gap="0px">
          <Text fontFamily="Acre" fontSize="24px" ml="10px" color={priceImpactSeverity > 2 ? 'failure' : 'text'}>
            {trade.outputAmount.toSignificant(6)}
          </Text>
        </RowFixed>
      </StyledRowBetween>
      <AutoColumn justify="flex-start" gap="sm" style={{ padding: '8px 0 0 0px' }}>
        <Text small color="textSubtle" textAlign="left" style={{ width: '100%' }}>
          {estimatedText}
          <b>
            {amount} {symbol}
          </b>
          {transactionRevertText}
        </Text>
      </AutoColumn>
      {recipient !== null ? (
        <AutoColumn justify="flex-start" gap="sm" style={{ padding: '12px 0 0 0px' }}>
          <Text color="textSubtle">
            {recipientSentToText}
            <b title={recipient}>{truncatedRecipient}</b>
            {postSentToText}
          </Text>
        </AutoColumn>
      ) : null}
    </AutoColumn>
  )
}

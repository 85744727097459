import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = ({ fill = '#F2F7FA' }) => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7727 16H16.9091C15.1045 16 13.6364 14.35 13.6364 12.5455C13.6364 10.7409 15.1045 9 16.9091 9H19.7727C19.9988 9 20.1818 8.81702 20.1818 8.5909V7.63637C20.1818 6.77851 19.5164 6.08085 18.6757 6.01319L6.05091 6H4.63637C3.73391 6 3 6.73387 3 7.63637V17.4545C3 18.357 3.73387 19.0909 4.63637 19.0909H18.5454C19.4479 19.0909 20.1818 18.357 20.1818 17.4545V16.4091C20.1818 16.183 19.9988 16 19.7727 16ZM17.4545 10.0909H20.3182C20.9949 10.0909 21.5455 10.6414 21.5455 11.3182V13.7727C21.5455 14.4495 20.9949 15 20.3182 15H17.4545C16.101 15 15 13.8989 15 12.5454C15 11.1919 16.101 10.0909 17.4545 10.0909ZM16.6364 12.5454C16.6364 12.9964 17.0035 13.3636 17.4545 13.3636C17.9056 13.3636 18.2727 12.9964 18.2727 12.5454C18.2727 12.0944 17.9056 11.7273 17.4545 11.7273C17.0035 11.7273 16.6364 12.0944 16.6364 12.5454Z"
        fill={fill}
      />
    </Svg>
  )
}

export default Icon

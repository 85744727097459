import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = ({ fill = '#777777' }) => {
  return (
    <Svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7555 0.870014C10.3568 0.410516 9.64356 0.410516 9.24485 0.870014L8.03077 2.26921C7.78801 2.54898 7.41049 2.67165 7.04964 2.588L5.245 2.16964C4.65236 2.03226 4.07537 2.45146 4.0229 3.05756L3.86311 4.90315C3.83116 5.27218 3.59784 5.59332 3.25674 5.73775L1.55085 6.46003C0.990638 6.69723 0.77025 7.37552 1.08405 7.8967L2.03959 9.48374C2.23066 9.80107 2.23066 10.198 2.03959 10.5154L1.08405 12.1024C0.77025 12.6236 0.990638 13.3019 1.55085 13.5391L3.25674 14.2614C3.59784 14.4058 3.83116 14.7269 3.86311 15.096L4.0229 16.9415C4.07537 17.5476 4.65236 17.9668 5.245 17.8295L7.04964 17.4111C7.41049 17.3275 7.78801 17.4501 8.03077 17.7299L9.24485 19.1291C9.64356 19.5886 10.3568 19.5886 10.7555 19.1291L11.9695 17.7299C12.2123 17.4501 12.5898 17.3275 12.9507 17.4111L14.7553 17.8295C15.348 17.9668 15.9249 17.5476 15.9774 16.9415L16.1372 15.096C16.1692 14.7269 16.4025 14.4058 16.7436 14.2614L18.4495 13.5391C19.0097 13.3019 19.2301 12.6236 18.9163 12.1024L17.9607 10.5154C17.7697 10.198 17.7697 9.80107 17.9607 9.48374L18.9163 7.8967C19.2301 7.37552 19.0097 6.69723 18.4495 6.46003L16.7436 5.73775C16.4025 5.59332 16.1692 5.27218 16.1372 4.90315L15.9774 3.05756C15.9249 2.45146 15.348 2.03226 14.7553 2.16964L12.9507 2.588C12.5898 2.67165 12.2123 2.54898 11.9695 2.26921L10.7555 0.870014ZM14.7121 7.74287C15.0962 7.34404 15.0962 6.6974 14.7121 6.29857C14.3279 5.89973 13.7052 5.89973 13.321 6.29857L8.32097 11.4901L6.71893 9.61882C6.35803 9.19725 5.73632 9.15928 5.3303 9.534C4.92429 9.90873 4.88771 10.5542 5.24862 10.9758L7.5437 13.6567C7.72367 13.8669 7.97918 13.9907 8.24996 13.999C8.52074 14.0073 8.78281 13.8992 8.97437 13.7003L14.7121 7.74287Z"
        fill={fill}
      />
    </Svg>
  )
}

export default Icon

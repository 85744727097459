import React from 'react'
import { Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { useTranslation } from 'contexts/Localization'
import { useSaleUserData } from 'state/idos/hooks'
import styled from 'styled-components'
import getTimelines, { getCardBasedOnTimeline } from 'views/Sale/utils'
import { useSaleParticipable } from 'views/Sale/utils/hooks'
import ImageWithNetwork from 'componentsV2/ImageWithNetwork'
import IFTypography from 'componentsV2/IFTypography/IFTypography'
import { ModalCloseButton, OpenNewIcon, useMatchBreakpoints } from 'uikit'
import TimeLine from 'componentsV2/TimeLine/TimeLine'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'
import { ManageRow } from 'views/Manage/utils'
import useTheme from 'hooks/useTheme'
import ArrowDown from '@mui/icons-material/ArrowDropDownSharp'
import { DRAWER_WIDTH } from './DrawerContext'

const Container = styled(Box)`
  background-color: ${({ theme }) => theme.colorsV2?.main};
`

const TextBox = styled(Box)`
  margin-top: auto;
  margin-bottom: auto;
`

const SaleBox = styled(Box)`
  position: fixed;
  z-index: 200;
  width: 100%;
  border-radius: 20px 20px 0px 0px;
  ${({ theme }) => theme.mediaQueries?.md} {
    width: ${DRAWER_WIDTH}px;
  }
  background-color: ${({ theme }) => theme.colorsV2?.main};
`

const TimelineAccordion = styled((props) => <Accordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    marginBottom: '0 !important',
    marginTop: '0 !important',
    borderRadius: '5px',
    backgroundColor: `${theme.colorsV2?.feedbacks?.infoBg} !important`,
    '& .MuiAccordion-root.Mui-expanded': {
      marginBottom: 0,
      minHeight: '40px !important',
    },
    '&:before': {
      display: 'none',
    },
  }),
)

const TimelineAccordionSummary = styled((props) => {
  return <AccordionSummary expandIcon={<ArrowDown />} {...props} />
})(({ theme }) => ({
  padding: '0 8px',
  margin: '0',
  borderRadius: '5px',
  height: '40px',
  minHeight: '40px !important',
  '& p': {
    color: `${theme.colorsV2?.feedbacks?.infoText}`,
  },
  '& svg': {
    color: theme.colorsV2?.feedbacks?.infoText,
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: 10,
  },
  '& .MuiAccordionSummary-content': {
    margin: 10,
  },
}))

const TimelineAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  display: 'flex',
  padding: '15px 8px 8px',
  flexDirection: 'column',
  backgroundColor: theme.isDark ? theme.colorsV2.dark : theme.colorsV2.background,
  border: `${theme.isDark ? 'none' : '1px solid #DEEFFF'}`,
  borderRadius: '0 0 5px 5px',
  '> div': {
    padding: '0 8px',
  },
}))

const ManageDrawer = (props: any) => {
  const { drawerProps, onDismiss } = props
  const { value } = drawerProps
  const { saleId, ido, projectImage, projectId, chainId, projectTitle, projectSubtitle, isSingleSale } =
    value as ManageRow
  const userData = useSaleUserData(saleId)
  const { t } = useTranslation()
  const { theme } = useTheme()
  const sale = ido
  const participable = useSaleParticipable(sale)
  const timelines = getTimelines({
    sale,
    saleTokenImage: projectImage,
    userData,
    participable,
    translation: t,
    isQuickSidebar: true,
  })
  const SaleCardComponent = getCardBasedOnTimeline(timelines)
  const timezone = format(new Date(), 'OOO').replace('GMT', 'UTC ')
  const { isXl } = useMatchBreakpoints()
  const isMobile = !isXl

  return (
    <Container>
      <Box padding="0 1px">
        <SaleBox padding="20px 30px">
          <Box display="flex">
            <Box marginRight={4}>
              <ImageWithNetwork
                alt="icon"
                src={projectImage}
                chainId={chainId}
                width="44px"
                chainWidth="20px"
                chainHeight="20px"
                chainMarginBottom={0}
                chainMarginLeft={-3}
              />
            </Box>
            <TextBox>
              <TextBox>
                <IFTypography variant="body2" ifcolor="text" fontWeight="bold">
                  {projectTitle}
                </IFTypography>
              </TextBox>
              <TextBox>
                <IFTypography variant="body2" ifcolor="textSecondary">
                  {projectSubtitle}
                </IFTypography>
              </TextBox>
              <Box>
                <Link
                  onClick={() => {
                    if (window.fathom) {
                      window.fathom.trackGoal('PIUDEUVI', 0)
                    }
                    onDismiss()
                  }}
                  to={
                    isSingleSale ? `/launchpad/project/${projectId}` : `/launchpad/project/${projectId}/sale/${saleId}`
                  }
                >
                  <Box display="flex" alignItems="center">
                    <IFTypography variant="body2" ifcolor="textLink">
                      {t('Sale details')}
                    </IFTypography>
                    <OpenNewIcon ml="4px" width="15px" style={{ fill: theme.colorsV2?.textLink }} />
                  </Box>
                </Link>
              </Box>
            </TextBox>
            <Box marginLeft="auto">
              <ModalCloseButton onDismiss={onDismiss} />
            </Box>
          </Box>
        </SaleBox>
      </Box>
      <Box marginTop={22} padding="1px">
        {SaleCardComponent}
      </Box>
      <Box padding={isMobile ? 2 : '8px 8px 60px'} maxWidth={isMobile ? '350px' : 'undefined'} margin="0 auto">
        <TimelineAccordion>
          <TimelineAccordionSummary aria-controls="timeline-content" id="timeline-header">
            <IFTypography variant="body2" fontWeight="bold">
              Timeline
            </IFTypography>
          </TimelineAccordionSummary>
          <TimelineAccordionDetails>
            <TimeLine saleTitle={sale.title} timelines={timelines} timezone={timezone} />
          </TimelineAccordionDetails>
        </TimelineAccordion>
      </Box>
    </Container>
  )
}

export default ManageDrawer

import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 86 85" fill="none" {...props}>
      <path
        d="M49.5597 6.70689L78.8667 36.0139C82.464 39.6111 82.464 45.536 78.8667 49.1333L49.5597 78.4403C45.9625 82.0375 40.0375 82.0375 36.4403 78.4403L7.1333 49.1333C3.53605 45.536 3.53605 39.6111 7.1333 36.0139L36.4403 6.70689C40.1433 3.00384 45.9625 3.10964 49.5597 6.70689Z
        M39.2044 45.3011C39.2044 47.3823 40.8916 49.0695 42.9728 49.0695C45.0541 49.0695 46.7413 47.3824 46.7413 45.3011V27.2934C46.7413 25.2121 45.0541 23.5249 42.9728 23.5249C40.8916 23.5249 39.2044 25.2121 39.2044 27.2933V45.3011ZM42.9728 61.6221C45.9876 61.6221 48.1948 59.6195 48.1948 56.9332C48.1948 54.4911 46.0414 52.1955 42.9728 52.1955C40.0119 52.1955 37.8047 54.4422 37.8047 56.9332C37.8047 59.6195 40.1196 61.6221 42.9728 61.6221Z"
        fill="url(#paint0_linear_4716_71979)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4716_71979"
          x1="43"
          y1="80.6708"
          x2="43"
          y2="-13.2734"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ED696C" stopOpacity="0.53" />
          <stop offset="1" stopColor="#ED696C" />
        </linearGradient>
      </defs>
    </Svg>
  )
}
export default Icon

import { fetchQuestsData, fetchQuestData, fetchQuestResult } from 'state/quests/fetch'
import { Quiz, QuizResult } from 'state/v2_types'

export const getQuests = async (isUserSigned): Promise<Quiz[]> => {
  const data = await fetchQuestsData(isUserSigned)
  return data
}

export const getQuest = async (quizID): Promise<Quiz> => {
  const data = await fetchQuestData(quizID)
  return data
}

export const getQuestResult = async (quizID): Promise<QuizResult> => {
  const data = await fetchQuestResult(quizID)
  return data
}

export default getQuests

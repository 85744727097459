import React from 'react'
import CircleLoader from 'componentsV2/Loader/CircleLoader'

import { useTranslation } from 'contexts/Localization'
import IFTypography from 'componentsV2/IFTypography/IFTypography'
import { Box } from '@mui/material'
import SaleDetailCard from '../SaleDetailCard'

const LoadingCard: React.FC = () => {
  const { t } = useTranslation()

  return (
    <SaleDetailCard
      style={{ height: '100%', justifyContent: 'center' }}
      containerStyle={{ height: '100%' }}
      hidePostSaleInfo
      requireKYC={false}
    >
      <>
        <CircleLoader size="36px" />
        <Box marginTop={2}>
          <IFTypography variant="caption" ifcolor="textSecondary">
            {t('Loading')}...
          </IFTypography>
        </Box>
      </>
    </SaleDetailCard>
  )
}

export default LoadingCard

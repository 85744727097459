import React from 'react'

interface IconProps {
  size?: number
}

const FCFSIcon: React.FC<IconProps> = ({ size = 60 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="34" viewBox="0 0 30 34" fill="none">
      <path
        d="M12.3128 0H17.0024C23.8025 0 29.313 7.61131 29.313 16.9999C29.313 26.3887 23.8025 34 17.0024 34H12.3128V0Z"
        fill="#F9B053"
      />
      <path
        d="M24.6206 16.9999C24.6206 7.61104 19.1091 0 12.3103 0C5.51152 0 0 7.61104 0 16.9999C0 26.3887 5.51152 34 12.3103 34C19.1091 34 24.6206 26.3887 24.6206 16.9999Z"
        fill="#FFD57B"
      />
      <path
        d="M22.2736 16.999C22.2736 24.1976 18.5219 30.3821 13.8031 31.4842C9.05473 30.435 5.27376 24.2269 5.27376 16.999C5.27376 9.77116 9.05473 3.56329 13.8031 2.51379C18.5219 3.61588 22.2736 9.80041 22.2736 16.999Z"
        fill="#F2A40C"
      />
      <path
        d="M13.8123 31.4853C13.3218 31.5974 12.8205 31.6543 12.3174 31.6554C6.91857 31.6554 2.35189 24.9433 2.35189 17C2.35189 9.05706 6.91857 2.34497 12.3174 2.34497C12.8205 2.34578 13.3218 2.40293 13.8123 2.51483C9.06398 3.56433 5.28301 9.7722 5.28301 17C5.28301 24.2279 9.06398 30.436 13.8123 31.4853Z"
        fill="#FFBD40"
      />
      <g filter="url(#filter0_d_31143_1267)">
        <path
          d="M11.9856 11.266C12.2103 10.2102 13.717 10.2102 13.9418 11.266L14.5447 14.0985C14.608 14.3959 14.8033 14.6484 15.0752 14.7845L18.009 16.2527C18.7456 16.6214 18.7456 17.6726 18.009 18.0413L15.0752 19.5095C14.8033 19.6456 14.608 19.8981 14.5447 20.1956L13.9418 23.0281C13.717 24.0838 12.2103 24.0838 11.9856 23.0281L11.3827 20.1956C11.3193 19.8981 11.1241 19.6456 10.8521 19.5095L7.91838 18.0413C7.18177 17.6726 7.18177 16.6214 7.91838 16.2527L10.8521 14.7845C11.1241 14.6484 11.3193 14.3959 11.3827 14.0985L11.9856 11.266Z"
          fill="#FFD57B"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_31143_1267"
          x="3.36592"
          y="6.47412"
          width="19.1955"
          height="21.3457"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.316667 0 0 0 0 0.232275 0 0 0 0 0.154375 0 0 0 0.29 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_31143_1267" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_31143_1267" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

export default FCFSIcon

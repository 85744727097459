import { ConnectorNames } from 'uikit'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { connectorsByName } from './web3React'

const getProvider = () => {
  const { connectorId } = window.localStorage

  if (!connectorId) {
    return null
  }
  const connector = connectorsByName[connectorId]

  switch (connectorId) {
    case ConnectorNames.Injected:
      return (window as any).ethereum
    case ConnectorNames.WalletConnect:
      return (connector as WalletConnectConnector)?.walletConnectProvider
    case ConnectorNames.BSC:
      return (window as any).BinanceChain
    default:
      return null
  }
}

export default getProvider

import { DefaultTheme } from 'styled-components'
import { dark as darkAlert } from '../components/Alert/theme'
import { dark as darkCard } from '../components/Card/theme'
import { dark as darkRadio } from '../components/Radio/theme'
import { dark as darkToggle } from '../components/Toggle/theme'
import { beam as darkNav } from '../widgets/Menu/theme'
import { beam as darkModal } from '../widgets/Modal/theme'
import { beam as darkFooter } from '../widgets/Footer/theme'
import { beam as darkTable } from 'componentsV2/IFSortableTable/theme'
import { beam as darkAccordion } from 'componentsV2/IFAccordion/theme'
import { beam as beamSale } from 'views/Sale/theme'
import { beam as beamCommon } from './common'
import base from './base'
import { darkColors } from './colors'
import { beamColorsV2 } from './colorsV2'

const darkTheme: DefaultTheme = {
  ...base,
  isDark: true,
  useColorsOnly: false,
  alert: darkAlert,
  colors: darkColors,
  footer: darkFooter,
  accordion: darkAccordion,
  card: darkCard,
  sale: beamSale,
  toggle: darkToggle,
  common: beamCommon,
  table: darkTable,
  nav: darkNav,
  colorsV2: beamColorsV2,
  modal: darkModal,
  radio: darkRadio,
}

export default darkTheme

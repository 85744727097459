import { useTranslation } from 'contexts/Localization'
import React from 'react'
import ModalV2 from 'uikit/widgets/Modal/ModalV2'
import IFTypography from 'componentsV2/IFTypography/IFTypography'
import { Box } from '@mui/material'
import styled from 'styled-components'
import { useMatchBreakpoints } from 'uikit'
// import AnimatedCheckedBox from 'componentsV2/AnimatedCheckedBox'
import { AnimatedCheckedBox } from 'componentsV2'

import { IdleSale } from './utils'
import UnstakeRow from './components/UnstakeRow'

interface UnstakeIdleModalProps {
  onDismiss?: VoidFunction
  idleSales: IdleSale[]
  tokenName: string
}

const SalesBox = styled(Box)<{ isMobile }>`
  overflow-y: auto;
  max-height: calc(80vh - 124px);
`

const UnstakeIdleModal: React.FC<UnstakeIdleModalProps> = ({ onDismiss, idleSales, tokenName }) => {
  const { t } = useTranslation()
  const { isXl } = useMatchBreakpoints()
  const isMobile = !isXl
  const hasIdle = idleSales.length > 0
  return (
    <ModalV2
      title={hasIdle ? t('Unstake your %tokenName% assets', { tokenName }) : ''}
      onDismiss={onDismiss}
      disableScroll
      bodyPadding="0px"
    >
      {hasIdle ? (
        <>
          <Box marginBottom="10px">
            <IFTypography variant="body1" ifcolor="textSecondary">
              {t('Unstaked %tokenName% will be returned to your wallet.', { tokenName })}
            </IFTypography>
          </Box>
          <SalesBox isMobile={isMobile}>
            {idleSales.map((sale) => {
              return <UnstakeRow key={sale.saleId} sale={sale} />
            })}
          </SalesBox>
        </>
      ) : (
        <Box>
          <AnimatedCheckedBox />
          <Box display="flex" justifyContent="center">
            <IFTypography variant="h3" fontWeight="bold">
              {t('Unstake all your %tokenName% assets', { tokenName })}
            </IFTypography>
          </Box>

          <Box marginTop={4} textAlign="center" display="flex" justifyContent="center">
            <IFTypography variant="body2" ifcolor="textSecondary">
              {t(
                'Unstaked %tokenName% will be returned to your wallet. Your idle staked %tokenName% have been cleared.',
                { tokenName },
              )}
            </IFTypography>
          </Box>
        </Box>
      )}
    </ModalV2>
  )
}

export default UnstakeIdleModal

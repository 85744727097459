import { createSlice } from '@reduxjs/toolkit'
import { QuestState } from 'state/types'
import { RequestStatus } from 'state/v2_types'
import { getQuests, getQuest, getQuestResult } from 'utils/questHelpers'

const initialState: QuestState = {
  quizzes: [],
  detailQuizzes: null,
  quizResults: null,
  resultStatus: RequestStatus.IDLE,
  previewQuiz: null,
}

export const questsSlice = createSlice({
  name: 'quests',
  initialState,
  reducers: {
    initQuizzes: (state: QuestState, action) => {
      state.quizzes = action.payload
    },
    resetQuizDetail: (state: QuestState, action) => {
      state.detailQuizzes = {
        ...state.detailQuizzes,
        [action.payload]: undefined,
      }
    },
    setQuizDetail: (state: QuestState, action) => {
      state.detailQuizzes = {
        ...state.detailQuizzes,
        [action.payload.quiz.quizId]: action.payload,
      }
    },
    setQuizResultSuccess: (state: QuestState, action) => {
      state.quizResults = {
        ...state.quizResults,
        [action.payload.quizID]: action.payload,
      }
      state.resultStatus = RequestStatus.SUCCESS
    },
    setQuizResultFailed: (state: QuestState) => {
      state.resultStatus = RequestStatus.FAILED
    },
    fetchingResult: (state: QuestState) => {
      state.resultStatus = RequestStatus.LOADING
    },
    setPreviewQuiz: (state: QuestState, action) => {
      state.previewQuiz = action.payload
    },
  },
})

export const {
  initQuizzes,
  setQuizDetail,
  setQuizResultSuccess,
  setQuizResultFailed,
  fetchingResult,
  setPreviewQuiz,
  resetQuizDetail,
} = questsSlice.actions

export const fetchQuests = (isUserSigned) => async (dispatch) => {
  const data = await getQuests(isUserSigned)
  dispatch(initQuizzes(data.filter((item) => item.quizStatus > 0)))
}

export const fetchQuestResult = (quizID) => async (dispatch) => {
  dispatch(fetchingResult())
  const data = await getQuestResult(quizID).catch(() => {
    dispatch(setQuizResultFailed())
  })
  if (data) {
    data.quizID = quizID
    dispatch(setQuizResultSuccess(data))
  }
}

export const fetchQuest = (quizID) => async (dispatch) => {
  const data = await getQuest(quizID)
  if (data) {
    dispatch(setQuizDetail(data))
  }
}

export default questsSlice.reducer

import { useAccount } from 'wagmi'
import { useState, useEffect, useContext } from 'react'
import multicall from 'utils/multicall'
import IFTieredSaleABI from 'config/abi/IFTieredSale.json'
import CONTRACTS from 'config/constants/contracts'
import { utils } from 'ethers'
import { CompanyContext } from 'contexts/CompanyContext'

export const useReferralCodes = () => {
  const { address, isConnected } = useAccount()
  const [userCodeInfo, setUserCodeInfo] = useState({})
  const [userCodeInfoDetail, setUserCodeInfoDetail] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const { brand } = useContext(CompanyContext)
  const IFTieredSaleContracts = CONTRACTS.IFTieredSale[brand] || {}

  const getUserCodes = async (userAddress: string) => {
    const codesPerChain = {}

    const supportedNetworksForTieredSale = Object.keys(IFTieredSaleContracts)

    const codeDataPromises = supportedNetworksForTieredSale.map(async (network: any) => {
      const saleContractsByChain = Object.keys(IFTieredSaleContracts[network].contracts)

      const multicallParams = saleContractsByChain.map((saleContract) => ({
        address: saleContract,
        name: 'getOwnerPromoCodes',
        params: [userAddress],
      }))

      const codesForChain = await multicall(IFTieredSaleABI, multicallParams, network, {
        returnCallAddress: true,
      })

      codesForChain.forEach((userCodesInContract: string[]) => {
        const saleContractAddress = userCodesInContract[userCodesInContract.length - 1]
        const codesForContract = userCodesInContract[0]

        if (!codesPerChain[network]) {
          codesPerChain[network] = {}
        }

        codesPerChain[network][saleContractAddress] = [...codesForContract, userAddress.toLowerCase()]
      })
    })

    await Promise.all(codeDataPromises)

    return codesPerChain
  }

  const getUserCodesInfo = async (codesPerChain: any) => {
    const codeInfoPerContractAggregated = {}
    const codeInfoPerContractDetailed = {}
    const networks = Object.keys(codesPerChain)

    const codeInfoPromises = networks.map(async (network: any) => {
      const multicallParams = []
      Object.keys(codesPerChain[network]).forEach((saleContract) =>
        codesPerChain[network][saleContract].forEach((code) => {
          multicallParams.push({
            address: saleContract,
            name: 'promoCodes',
            params: [code],
          })
        }),
      )

      const codeInfoForChain = await multicall(IFTieredSaleABI, multicallParams, network, {
        returnCallAddress: true,
        returnCallParams: true,
      })

      //seperate by contract
      codeInfoForChain.forEach((codeInfo: any) => {
        if (codeInfo[1] !== '0x0000000000000000000000000000000000000000') {
          const [code] = codeInfo[codeInfo.length - 1]
          const saleContractAddress = codeInfo[codeInfo.length - 2]
          const saleContractsByChain = IFTieredSaleContracts[network]
          const contractInfo = IFTieredSaleContracts[network].contracts[saleContractAddress]

          const commissionRate = codeInfo[0] || contractInfo.defaultWalletCodeCommissionRate || 0

          const promoCodeOwnerEarnings = parseFloat(
            Number(utils.formatUnits(codeInfo[3], contractInfo.paymentTokenDecimals || 18)).toFixed(5),
          )

          const totalPurchased = parseFloat(
            Number(utils.formatUnits(codeInfo[5], contractInfo.paymentTokenDecimals || 18)).toFixed(5),
          )

          const paymentTokenSymbol = contractInfo.paymentTokenSymbol

          if (!codeInfoPerContractDetailed[saleContractAddress]) {
            codeInfoPerContractDetailed[saleContractAddress] = []
          }

          codeInfoPerContractDetailed[saleContractAddress].push({
            code,
            promoCodeOwnerEarnings,
            totalPurchased,
            commissionRate,
            paymentTokenSymbol,
          })

          if (!codeInfoPerContractAggregated[saleContractAddress]) {
            codeInfoPerContractAggregated[saleContractAddress] = {
              contractAddress: saleContractAddress,
              chainId: network,
              chainName: saleContractsByChain.chainName,
              commissionRate,
              paymentTokenSymbol,
              promoCodeOwnerEarnings,
              totalPurchased,
            }
          } else {
            codeInfoPerContractAggregated[saleContractAddress] = {
              ...codeInfoPerContractAggregated[saleContractAddress],
              promoCodeOwnerEarnings:
                codeInfoPerContractAggregated[saleContractAddress].promoCodeOwnerEarnings + promoCodeOwnerEarnings,
              totalPurchased: codeInfoPerContractAggregated[saleContractAddress].totalPurchased + totalPurchased,
            }
          }
        }
      })
    })

    await Promise.all(codeInfoPromises)
    return { codeInfoPerContractAggregated, codeInfoPerContractDetailed }
  }

  const handleGetUserCodesData = async (userAddress: string) => {
    try {
      const codesPerChain = await getUserCodes(userAddress)
      const codesDetail = await getUserCodesInfo(codesPerChain)

      return codesDetail
    } catch (e) {
      console.log(e)
      return { codeInfoPerContractAggregated: {}, codeInfoPerContractDetailed: {} }
    }
  }

  const fetchData = async () => {
    try {
      setIsLoading(true)
      setUserCodeInfo({})
      setUserCodeInfoDetail({})

      const { codeInfoPerContractAggregated, codeInfoPerContractDetailed } = await handleGetUserCodesData(address)
      setUserCodeInfo(codeInfoPerContractAggregated)
      setUserCodeInfoDetail(codeInfoPerContractDetailed)
      setIsLoading(false)
    } catch (e) {
      console.log(e)
      setIsLoading(false)
      setUserCodeInfo({})
      setUserCodeInfoDetail({})
    }
  }

  useEffect(() => {
    if (!address || !isConnected) {
      setUserCodeInfo({})
      setUserCodeInfoDetail({})
      return
    }

    fetchData()
  }, [address, isConnected])

  return {
    userCodeInfoDetail,
    userCodeInfo,
    isLoading,
    refetchCodeData: fetchData,
  }
}

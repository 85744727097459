import { Brands, CompanyContext } from 'contexts/CompanyContext'
import React, { useContext } from 'react'

interface Props {
  size?: number
}

const RoninMissed: React.FC<Props> = ({ size = 100 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M80.7848 51.9314C80.7848 32.9138 76.3898 12.1143 50 12.1143C23.6102 12.1143 19.2152 32.9138 19.2152 51.9314C19.2152 70.9494 10 74.9191 10 91.9315H21.5244H50H78.4756H90C90 74.9191 80.7848 70.9494 80.7848 51.9314Z"
        fill="url(#paint0_linear_204_21479)"
      />
      <path
        d="M80.7848 51.9314C80.7848 32.9138 76.3898 12.1143 50 12.1143C23.6102 12.1143 19.2152 32.9138 19.2152 51.9314C19.2152 70.9494 10 74.9191 10 91.9315H21.5244H50H78.4756H90C90 74.9191 80.7848 70.9494 80.7848 51.9314Z"
        fill="black"
        fillOpacity="0.1"
      />
      <g opacity="0.1">
        <path
          opacity="0.1"
          d="M52.4145 12.1747C51.6309 12.1346 50.8262 12.1143 50 12.1143C23.6102 12.1143 19.2152 32.9138 19.2152 51.9316C19.2152 70.9493 10 74.919 10 91.9313H14.8289C14.8289 74.9188 24.0441 70.9491 24.0441 51.9313C24.0441 33.5091 28.1683 13.4149 52.4145 12.1747Z"
          fill="black"
        />
      </g>
      <path
        d="M36.4043 46.7052C36.4043 46.7052 37.0755 51.9304 49.9973 51.9304C62.919 51.9304 63.5902 46.7052 63.5902 46.7052V44.437L50.8685 40.8926L36.4046 44.2951V46.7052H36.4043Z"
        fill="#FFA001"
      />
      <path
        d="M69.0303 42.0288H65.2734C54.0734 42.0288 57.9013 32.6719 49.9826 32.6719C42.0638 32.6719 45.8916 42.0288 34.6918 42.0288H30.9349C29.643 42.0288 28.5957 43.0759 28.5957 44.368C28.5957 45.66 29.6429 46.7072 30.9349 46.7072H36.3899C41.4113 46.7072 46.2543 43.7088 49.9829 47.0722C53.7113 43.7088 58.5543 46.7072 63.5759 46.7072H69.0309C70.3228 46.7072 71.3701 45.66 71.3701 44.368C71.3696 43.0761 70.3224 42.0288 69.0303 42.0288Z"
        fill="url(#paint1_linear_204_21479)"
      />
      <path
        d="M35.2367 38.3435C38.8818 38.3435 41.8367 35.3886 41.8367 31.7435C41.8367 28.0985 38.8818 25.1436 35.2367 25.1436C31.5916 25.1436 28.6367 28.0985 28.6367 31.7435C28.6367 35.3886 31.5916 38.3435 35.2367 38.3435Z"
        fill="white"
      />
      <path
        d="M36.7273 33.2242C37.7037 33.2242 38.4952 32.4327 38.4952 31.4563C38.4952 30.48 37.7037 29.6885 36.7273 29.6885C35.751 29.6885 34.9595 30.48 34.9595 31.4563C34.9595 32.4327 35.751 33.2242 36.7273 33.2242Z"
        fill="#212121"
      />
      <path
        d="M64.8109 38.3436C68.456 38.3436 71.4109 35.3886 71.4109 31.7436C71.4109 28.0985 68.456 25.1436 64.8109 25.1436C61.1659 25.1436 58.2109 28.0985 58.2109 31.7436C58.2109 35.3886 61.1659 38.3436 64.8109 38.3436Z"
        fill="white"
      />
      <path
        d="M64.4461 33.2242C65.4224 33.2242 66.2139 32.4327 66.2139 31.4563C66.2139 30.48 65.4224 29.6885 64.4461 29.6885C63.4697 29.6885 62.6782 30.48 62.6782 31.4563C62.6782 32.4327 63.4697 33.2242 64.4461 33.2242Z"
        fill="#212121"
      />
      <path
        d="M78.4776 91.9317C78.4776 91.9317 80.6042 74.0686 72.8068 65.4204C65.0095 56.7723 60.6553 63.5983 50.002 63.5983C39.3487 63.5983 34.9946 56.7725 27.1973 65.4206C19.3999 74.0687 21.5265 91.9317 21.5265 91.9317H78.4776Z"
        fill="url(#paint2_linear_204_21479)"
      />
      <path
        d="M28 28.0693C28 31.6852 25.0167 33 23 33C20.9833 33 18 31.6852 18 28.0693C18 24.207 21.4333 17.8752 21.8167 17.4807C22.4667 16.8398 23.5333 16.8398 24.1833 17.4807C24.5667 17.8752 28 24.2053 28 28.0693Z"
        fill="#85BCF9"
      />
      <path
        d="M71.6447 42C71.5748 42 71.5037 41.9886 71.434 41.9645C71.0975 41.8481 70.9192 41.4811 71.0356 41.1447L71.9732 38.4341C72.0896 38.0977 72.4567 37.9192 72.793 38.0356C73.1294 38.152 73.3077 38.519 73.1914 38.8553L72.2538 41.566C72.1615 41.8327 71.9118 42 71.6447 42Z"
        fill="#F37E7F"
      />
      <path
        d="M25.6447 42C25.5748 42 25.5037 41.9886 25.434 41.9645C25.0975 41.8481 24.9192 41.4811 25.0356 41.1447L25.9732 38.4341C26.0896 38.0977 26.4567 37.9192 26.793 38.0356C27.1294 38.152 27.3077 38.519 27.1914 38.8553L26.2538 41.566C26.1615 41.8327 25.9118 42 25.6447 42Z"
        fill="#F37E7F"
      />
      <path
        d="M74.5861 42C74.5162 42 74.4452 41.9886 74.3754 41.9645C74.039 41.8481 73.8606 41.4811 73.977 41.1447L74.9145 38.4341C75.0309 38.0977 75.3979 37.9192 75.7343 38.0356C76.0707 38.152 76.249 38.519 76.1327 38.8553L75.1952 41.566C75.1029 41.8327 74.8532 42 74.5861 42Z"
        fill="#F37E7F"
      />
      <path
        d="M28.5861 42C28.5162 42 28.4452 41.9886 28.3754 41.9645C28.039 41.8481 27.8606 41.4811 27.977 41.1447L28.9145 38.4341C29.0309 38.0977 29.3979 37.9192 29.7343 38.0356C30.0707 38.152 30.249 38.519 30.1327 38.8553L29.1952 41.566C29.1029 41.8327 28.8532 42 28.5861 42Z"
        fill="#F37E7F"
      />
      <defs>
        <linearGradient
          id="paint0_linear_204_21479"
          x1="50"
          y1="12.1143"
          x2="50"
          y2="129.378"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.367934" stopColor="#66B6FF" />
          <stop offset="1" stopColor="#9ACFFF" stopOpacity="0.08" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_204_21479"
          x1="49.9829"
          y1="32.6719"
          x2="49.9829"
          y2="47.0722"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC108" />
          <stop offset="1" stopColor="#FFD24A" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_204_21479"
          x1="50.0021"
          y1="54.2589"
          x2="50.0021"
          y2="125"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.203125" stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.12" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export const MissedLight: React.FC<Props> = ({ size = 100 }) => {
  const { brand } = useContext(CompanyContext)
  if (brand === Brands.RONIN) {
    return <RoninMissed size={size} />
  }
  return (
    <svg width={size} height={size} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M80.7848 51.9314C80.7848 32.9138 76.3898 12.1143 50 12.1143C23.6102 12.1143 19.2152 32.9138 19.2152 51.9314C19.2152 70.9494 10 74.9191 10 91.9315H21.5244H50H78.4756H90C90 74.9191 80.7848 70.9494 80.7848 51.9314Z"
        fill="url(#paint0_linear_7360_164616)"
      />
      <path
        d="M80.7848 51.9314C80.7848 32.9138 76.3898 12.1143 50 12.1143C23.6102 12.1143 19.2152 32.9138 19.2152 51.9314C19.2152 70.9494 10 74.9191 10 91.9315H21.5244H50H78.4756H90C90 74.9191 80.7848 70.9494 80.7848 51.9314Z"
        fill="black"
        fillOpacity="0.1"
      />
      <g opacity="0.1">
        <path
          opacity="0.1"
          d="M52.4145 12.1747C51.6309 12.1346 50.8262 12.1143 50 12.1143C23.6102 12.1143 19.2152 32.9138 19.2152 51.9316C19.2152 70.9493 10 74.919 10 91.9313H14.8289C14.8289 74.9188 24.0441 70.9491 24.0441 51.9313C24.0441 33.5091 28.1683 13.4149 52.4145 12.1747Z"
          fill="black"
        />
      </g>
      <path
        d="M36.4062 46.7052C36.4062 46.7052 37.0775 51.9304 49.9992 51.9304C62.9209 51.9304 63.5922 46.7052 63.5922 46.7052V44.437L50.8705 40.8926L36.4066 44.2951V46.7052H36.4062Z"
        fill="#FFA001"
      />
      <path
        d="M69.0283 42.0288H65.2714C54.0714 42.0288 57.8994 32.6719 49.9806 32.6719C42.0619 32.6719 45.8897 42.0288 34.6898 42.0288H30.933C29.6411 42.0288 28.5938 43.0759 28.5938 44.368C28.5938 45.66 29.6409 46.7072 30.933 46.7072H36.388C41.4094 46.7072 46.2524 43.7088 49.9809 47.0722C53.7094 43.7088 58.5524 46.7072 63.5739 46.7072H69.0289C70.3208 46.7072 71.3681 45.66 71.3681 44.368C71.3677 43.0761 70.3205 42.0288 69.0283 42.0288Z"
        fill="url(#paint1_linear_7360_164616)"
      />
      <path
        d="M35.2367 38.3435C38.8818 38.3435 41.8367 35.3886 41.8367 31.7435C41.8367 28.0985 38.8818 25.1436 35.2367 25.1436C31.5916 25.1436 28.6367 28.0985 28.6367 31.7435C28.6367 35.3886 31.5916 38.3435 35.2367 38.3435Z"
        fill="white"
      />
      <path
        d="M36.7288 33.2242C37.7052 33.2242 38.4966 32.4327 38.4966 31.4563C38.4966 30.48 37.7052 29.6885 36.7288 29.6885C35.7524 29.6885 34.9609 30.48 34.9609 31.4563C34.9609 32.4327 35.7524 33.2242 36.7288 33.2242Z"
        fill="#212121"
      />
      <path
        d="M64.8109 38.3436C68.456 38.3436 71.4109 35.3886 71.4109 31.7436C71.4109 28.0985 68.456 25.1436 64.8109 25.1436C61.1659 25.1436 58.2109 28.0985 58.2109 31.7436C58.2109 35.3886 61.1659 38.3436 64.8109 38.3436Z"
        fill="white"
      />
      <path
        d="M64.4475 33.2242C65.4239 33.2242 66.2154 32.4327 66.2154 31.4563C66.2154 30.48 65.4239 29.6885 64.4475 29.6885C63.4712 29.6885 62.6797 30.48 62.6797 31.4563C62.6797 32.4327 63.4712 33.2242 64.4475 33.2242Z"
        fill="#212121"
      />
      <path
        d="M78.4777 91.9317C78.4777 91.9317 80.6043 74.0686 72.807 65.4204C65.0096 56.7723 60.6554 63.5983 50.0021 63.5983C39.3488 63.5983 34.9948 56.7725 27.1974 65.4206C19.4001 74.0687 21.5266 91.9317 21.5266 91.9317H78.4777Z"
        fill="url(#paint2_linear_7360_164616)"
      />
      <path
        d="M28 28.0693C28 31.6852 25.0167 33 23 33C20.9833 33 18 31.6852 18 28.0693C18 24.207 21.4333 17.8752 21.8167 17.4807C22.4667 16.8398 23.5333 16.8398 24.1833 17.4807C24.5667 17.8752 28 24.2053 28 28.0693Z"
        fill="#85BCF9"
      />
      <path
        d="M71.6447 42C71.5748 42 71.5037 41.9886 71.434 41.9645C71.0975 41.8481 70.9192 41.4811 71.0356 41.1447L71.9732 38.4341C72.0896 38.0977 72.4567 37.9192 72.793 38.0356C73.1294 38.152 73.3077 38.519 73.1914 38.8553L72.2538 41.566C72.1615 41.8327 71.9118 42 71.6447 42Z"
        fill="#F37E7F"
      />
      <path
        d="M25.6447 42C25.5748 42 25.5037 41.9886 25.434 41.9645C25.0975 41.8481 24.9192 41.4811 25.0356 41.1447L25.9732 38.4341C26.0896 38.0977 26.4567 37.9192 26.793 38.0356C27.1294 38.152 27.3077 38.519 27.1914 38.8553L26.2538 41.566C26.1615 41.8327 25.9118 42 25.6447 42Z"
        fill="#F37E7F"
      />
      <path
        d="M74.5861 42C74.5162 42 74.4452 41.9886 74.3754 41.9645C74.039 41.8481 73.8606 41.4811 73.977 41.1447L74.9145 38.4341C75.0309 38.0977 75.3979 37.9192 75.7343 38.0356C76.0707 38.152 76.249 38.519 76.1327 38.8553L75.1952 41.566C75.1029 41.8327 74.8532 42 74.5861 42Z"
        fill="#F37E7F"
      />
      <path
        d="M28.5861 42C28.5162 42 28.4452 41.9886 28.3754 41.9645C28.039 41.8481 27.8606 41.4811 27.977 41.1447L28.9145 38.4341C29.0309 38.0977 29.3979 37.9192 29.7343 38.0356C30.0707 38.152 30.249 38.519 30.1327 38.8553L29.1952 41.566C29.1029 41.8327 28.8532 42 28.5861 42Z"
        fill="#F37E7F"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7360_164616"
          x1="50"
          y1="12.1143"
          x2="50"
          y2="129.378"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.367934" stopColor="#66B6FF" />
          <stop offset="1" stopColor="#9ACFFF" stopOpacity="0.08" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_7360_164616"
          x1="49.9809"
          y1="32.6719"
          x2="49.9809"
          y2="47.0722"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC108" />
          <stop offset="1" stopColor="#FFD24A" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_7360_164616"
          x1="50.0022"
          y1="54.2589"
          x2="50.0022"
          y2="125"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.203125" stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.12" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export const MissedDark: React.FC<Props> = ({ size = 100 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M80.7848 51.9314C80.7848 32.9138 76.3898 12.1143 50 12.1143C23.6102 12.1143 19.2152 32.9138 19.2152 51.9314C19.2152 70.9494 10 74.9191 10 91.9315H21.5244H50H78.4756H90C90 74.9191 80.7848 70.9494 80.7848 51.9314Z"
        fill="url(#paint0_linear_10330_40545)"
      />
      <path
        d="M80.7848 51.9314C80.7848 32.9138 76.3898 12.1143 50 12.1143C23.6102 12.1143 19.2152 32.9138 19.2152 51.9314C19.2152 70.9494 10 74.9191 10 91.9315H21.5244H50H78.4756H90C90 74.9191 80.7848 70.9494 80.7848 51.9314Z"
        fill="url(#paint1_linear_10330_40545)"
        fillOpacity="0.2"
      />
      <g opacity="0.1">
        <path
          opacity="0.1"
          d="M52.4145 12.1747C51.6309 12.1346 50.8262 12.1143 50 12.1143C23.6102 12.1143 19.2152 32.9138 19.2152 51.9316C19.2152 70.9493 10 74.919 10 91.9313H14.8289C14.8289 74.9188 24.0441 70.9491 24.0441 51.9313C24.0441 33.5091 28.1683 13.4149 52.4145 12.1747Z"
          fill="black"
        />
      </g>
      <path
        d="M36.4043 46.7054C36.4043 46.7054 37.0755 51.9305 49.9973 51.9305C62.919 51.9305 63.5902 46.7054 63.5902 46.7054V44.4371L50.8685 40.8927L36.4046 44.2952V46.7054H36.4043Z"
        fill="#FFA001"
      />
      <path
        d="M69.0303 42.0291H65.2734C54.0734 42.0291 57.9013 32.6722 49.9826 32.6722C42.0638 32.6722 45.8916 42.0291 34.6918 42.0291H30.9349C29.643 42.0291 28.5957 43.0763 28.5957 44.3683C28.5957 45.6604 29.6429 46.7076 30.9349 46.7076H36.3899C41.4113 46.7076 46.2543 43.7091 49.9829 47.0726C53.7113 43.7091 58.5543 46.7076 63.5759 46.7076H69.0309C70.3228 46.7076 71.3701 45.6604 71.3701 44.3683C71.3696 43.0765 70.3224 42.0291 69.0303 42.0291Z"
        fill="url(#paint2_linear_10330_40545)"
      />
      <path
        d="M35.2367 38.3435C38.8818 38.3435 41.8367 35.3886 41.8367 31.7435C41.8367 28.0985 38.8818 25.1436 35.2367 25.1436C31.5916 25.1436 28.6367 28.0985 28.6367 31.7435C28.6367 35.3886 31.5916 38.3435 35.2367 38.3435Z"
        fill="#E6E1D7"
      />
      <path
        d="M36.7268 33.2242C37.7032 33.2242 38.4947 32.4328 38.4947 31.4564C38.4947 30.48 37.7032 29.6885 36.7268 29.6885C35.7505 29.6885 34.959 30.48 34.959 31.4564C34.959 32.4328 35.7505 33.2242 36.7268 33.2242Z"
        fill="#212121"
      />
      <path
        d="M64.8109 38.3436C68.456 38.3436 71.4109 35.3886 71.4109 31.7436C71.4109 28.0985 68.456 25.1436 64.8109 25.1436C61.1659 25.1436 58.2109 28.0985 58.2109 31.7436C58.2109 35.3886 61.1659 38.3436 64.8109 38.3436Z"
        fill="#E6E1D7"
      />
      <path
        d="M64.4466 33.2243C65.4229 33.2243 66.2144 32.4328 66.2144 31.4564C66.2144 30.48 65.4229 29.6885 64.4466 29.6885C63.4702 29.6885 62.6787 30.48 62.6787 31.4564C62.6787 32.4328 63.4702 33.2243 64.4466 33.2243Z"
        fill="#212121"
      />
      <path
        d="M78.4777 91.9314C78.4777 91.9314 80.6043 74.0683 72.807 65.4202C65.0096 56.7721 60.6554 63.598 50.0021 63.598C39.3488 63.598 34.9948 56.7722 27.1974 65.4203C19.4001 74.0685 21.5266 91.9314 21.5266 91.9314H78.4777Z"
        fill="url(#paint3_linear_10330_40545)"
      />
      <path
        d="M28 28.0693C28 31.6852 25.0167 33 23 33C20.9833 33 18 31.6852 18 28.0693C18 24.207 21.4333 17.8752 21.8167 17.4807C22.4667 16.8398 23.5333 16.8398 24.1833 17.4807C24.5667 17.8752 28 24.2053 28 28.0693Z"
        fill="#85BCF9"
      />
      <path
        d="M71.6447 42C71.5748 42 71.5037 41.9886 71.434 41.9645C71.0975 41.8481 70.9192 41.4811 71.0356 41.1447L71.9732 38.4341C72.0896 38.0977 72.4567 37.9192 72.793 38.0356C73.1294 38.152 73.3077 38.519 73.1914 38.8553L72.2538 41.566C72.1615 41.8327 71.9118 42 71.6447 42Z"
        fill="#F37E7F"
      />
      <path
        d="M25.6447 42C25.5748 42 25.5037 41.9886 25.434 41.9645C25.0975 41.8481 24.9192 41.4811 25.0356 41.1447L25.9732 38.4341C26.0896 38.0977 26.4567 37.9192 26.793 38.0356C27.1294 38.152 27.3077 38.519 27.1914 38.8553L26.2538 41.566C26.1615 41.8327 25.9118 42 25.6447 42Z"
        fill="#F37E7F"
      />
      <path
        d="M74.5861 42C74.5162 42 74.4452 41.9886 74.3754 41.9645C74.039 41.8481 73.8606 41.4811 73.977 41.1447L74.9145 38.4341C75.0309 38.0977 75.3979 37.9192 75.7343 38.0356C76.0707 38.152 76.249 38.519 76.1327 38.8553L75.1952 41.566C75.1029 41.8327 74.8532 42 74.5861 42Z"
        fill="#F37E7F"
      />
      <path
        d="M28.5861 42C28.5162 42 28.4452 41.9886 28.3754 41.9645C28.039 41.8481 27.8606 41.4811 27.977 41.1447L28.9145 38.4341C29.0309 38.0977 29.3979 37.9192 29.7343 38.0356C30.0707 38.152 30.249 38.519 30.1327 38.8553L29.1952 41.566C29.1029 41.8327 28.8532 42 28.5861 42Z"
        fill="#F37E7F"
      />
      <defs>
        <linearGradient
          id="paint0_linear_10330_40545"
          x1="50"
          y1="12.1143"
          x2="50"
          y2="91.9315"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4E4A65" />
          <stop offset="1" stopColor="#424242" stopOpacity="0.08" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_10330_40545"
          x1="50"
          y1="12.1143"
          x2="50"
          y2="91.9315"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5ED4F3" />
          <stop offset="1" stopColor="#2AA5C6" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_10330_40545"
          x1="49.9829"
          y1="32.6722"
          x2="49.9829"
          y2="47.0726"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC108" />
          <stop offset="1" stopColor="#FFD24A" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_10330_40545"
          x1="50.0022"
          y1="54.2586"
          x2="50.0022"
          y2="105.682"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.135417" stopColor="#66B6FF" />
          <stop offset="1" stopColor="#B6DCFF" stopOpacity="0.12" />
        </linearGradient>
      </defs>
    </svg>
  )
}

// Step 3: Edit to add new chains

import JSBI from 'jsbi'

import { SolidityType } from '../constants'
import { validateSolidityTypeInstance } from '../utils'

/**
 * A currency is any fungible financial instrument on Ethereum, including Ether and all ERC20 tokens.
 *
 * The only instance of the base class `Currency` is Ether.
 */
export class Currency {
  public readonly decimals: number

  public readonly symbol?: string

  public readonly name?: string

  public readonly address?: string

  public readonly isNative: boolean

  /**
   * The only instance of the base class `Currency`.
   */
  public static readonly ETHER: { [chainId: number]: Currency } = {
    56: new Currency(18, 'BNB', 'BNB', true),
    97: new Currency(18, 'BNB', 'BNB', true),
    137: new Currency(18, 'MATIC', 'MATIC', true),
    165: new Currency(18, 'OMNI', 'OMNI', true),
    1284: new Currency(18, 'GLMR', 'GLMR', true),
    1285: new Currency(18, 'MOVR', 'MOVR', true),
    43114: new Currency(18, 'AVAX', 'AVAX', true),
    1: new Currency(18, 'ETH', 'ETH', true),
    1313161554: new Currency(18, 'ETH', 'ETH', true),
    288: new Currency(18, 'ETH', 'ETH', true),
    42: new Currency(18, 'ETH', 'ETH', true),
    4: new Currency(18, 'ETH', 'ETH', true),
    5: new Currency(18, 'ETH', 'ETH', true),
    10: new Currency(18, 'ETH', 'ETH', true),
    69: new Currency(18, 'ETH', 'ETH', true),
    421611: new Currency(18, 'ETH', 'ETH', true),
    42161: new Currency(18, 'ETH', 'ETH', true),
    595: new Currency(18, 'ACA', 'ACA', true),
    2222: new Currency(18, 'KAVA', 'KAVA', true),
    2221: new Currency(18, 'KAVA', 'KAVA', true),
    245022926: new Currency(18, 'NEON', 'NEON', true),
    66: new Currency(18, 'OKT', 'OKT', true),
    15110: new Currency(18, 'eHMND', 'eHMND', true),
    5234: new Currency(18, 'eHMND', 'eHMND', true),
    421613: new Currency(18, 'ETH', 'ETH', true),
    59140: new Currency(18, 'ETH', 'ETH', true),
    169: new Currency(18, 'ETH', 'ETH', true),
    195: new Currency(18, 'OKB', 'OKB', true),
  }

  public static readonly WETHER: { [chainID: number]: Currency } = {
    15110: new Currency(18, 'WeHMND', 'WeHMND', true),
    5234: new Currency(18, 'WeHMND', 'WeHMND', true),
  }

  /**
   * Constructs an instance of the base class `Currency`. The only instance of the base class `Currency` is `Currency.ETHER`.
   * @param decimals decimals of the currency
   * @param symbol symbol of the currency
   * @param name of the currency
   */
  protected constructor(decimals: number, symbol?: string, name?: string, isNative = false) {
    validateSolidityTypeInstance(JSBI.BigInt(decimals), SolidityType.uint8)

    this.decimals = decimals
    this.symbol = symbol
    this.name = name
    this.isNative = isNative
  }
}

const { ETHER, WETHER } = Currency
export { ETHER, WETHER }

import React from 'react'
import { Flex } from 'uikit'
import { Field } from 'state/swap/actions'
import { useTranslation } from 'contexts/Localization'
import { useUserSlippageTolerance } from 'state/user/hooks'
import { computeSlippageAdjustedAmounts, computeTradePriceBreakdown } from 'utils/prices'
import { AutoColumn } from 'componentsV2/layout/Column'
import { Trade } from 'swap-sdk/entities/trade'
import { TradeType } from 'swap-sdk/constants'
import { RowBetween, RowFixed } from 'componentsV2/layout/Row'
import IFTypography from 'componentsV2/IFTypography/IFTypography'
import styled from 'styled-components'
import DefaultIFTooltip from 'componentsV2/DefaultIFTooltip/DefaultIFTooltip'
import FormattedPriceImpact from './FormattedPriceImpact'
import SwapRoute from './SwapRoute'

function TradeSummary({ trade, allowedSlippage }: { trade: Trade; allowedSlippage: number }) {
  const { t } = useTranslation()
  const { priceImpactWithoutFee, realizedLPFee } = computeTradePriceBreakdown(trade)
  const isExactIn = trade.tradeType === TradeType.EXACT_INPUT
  const slippageAdjustedAmounts = computeSlippageAdjustedAmounts(trade, allowedSlippage)

  return (
    <AutoColumn gap="10px">
      <RowBetween>
        <RowFixed>
          <IFTypography variant="body2" ifcolor="textSecondary">
            {isExactIn ? t('Minimum received') : t('Maximum sold')}
          </IFTypography>
          <DefaultIFTooltip
            placement="top"
            title={t(
              'Your transaction will revert if there is a large, unfavorable price movement before it is confirmed.',
            )}
          />
        </RowFixed>
        <RowFixed>
          <IFTypography variant="body2" ifcolor="textSecondary">
            {isExactIn
              ? `${slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4)} ${trade.outputAmount.currency.symbol}` ??
                '-'
              : `${slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4)} ${trade.inputAmount.currency.symbol}` ?? '-'}
          </IFTypography>
        </RowFixed>
      </RowBetween>
      <RowBetween>
        <RowFixed>
          <IFTypography variant="body2" ifcolor="textSecondary">
            {t('Price Impact')}
          </IFTypography>
          <DefaultIFTooltip
            placement="top"
            title={t('The difference between the market price and estimated price due to trade size.')}
          />
        </RowFixed>
        <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
      </RowBetween>

      <RowBetween>
        <RowFixed>
          <IFTypography variant="body2" ifcolor="textSecondary">
            {t('Fees')}
          </IFTypography>
          <DefaultIFTooltip
            placement="top"
            title={
              <>
                {t(
                  'For each trade, the fees collected go to LP token holder and in revenue to buyback IF tokens from the open market.',
                )}
              </>
            }
          />
        </RowFixed>
        <IFTypography variant="body2" ifcolor="textSecondary">
          {realizedLPFee ? `${realizedLPFee.toSignificant(4)} ${trade.inputAmount.currency.symbol}` : '-'}
        </IFTypography>
      </RowBetween>
    </AutoColumn>
  )
}

export interface AdvancedSwapDetailsProps {
  trade?: Trade
}

const StyledRouteBox = styled(Flex)`
  border-radius: 20px;
  background: ${({ theme }) => theme.colorsV2.dark}99;
  padding: 10px 20px 0px;
  ${({ theme }) => theme.mediaQueries?.sm} {
    padding: 20px 20px 10px;
  }
`
export function AdvancedSwapDetails({ trade }: AdvancedSwapDetailsProps) {
  const { t } = useTranslation()
  const [allowedSlippage] = useUserSlippageTolerance()
  const showRoute = Boolean(trade && trade.route.path.length > 2)

  return (
    <AutoColumn>
      {trade && (
        <>
          <TradeSummary trade={trade} allowedSlippage={allowedSlippage} />
          {showRoute && (
            <StyledRouteBox mt="20px">
              <AutoColumn gap="10px">
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <IFTypography variant="body2">{t('Route')}</IFTypography>
                  <DefaultIFTooltip
                    placement="top"
                    title={t('Routing through these tokens resulted in the best price for your trade.')}
                  />
                </span>
                <SwapRoute trade={trade} />
              </AutoColumn>
            </StyledRouteBox>
          )}
        </>
      )}
    </AutoColumn>
  )
}

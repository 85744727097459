import { Box, BoxProps, SxProps } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { useTheme } from 'styled-components'
import useMatchBreakpoints from 'uikit/hooks/useMatchBreakpoints'

export interface CountdownProps {
  seconds: number
  style?: BoxProps
  description?: string
  descriptionStyle?: SxProps
  descriptionPosition?: 'TOP' | 'BOTTOM' | 'LEFT' | 'RIGHT'
  expirationHandler?: () => void
}

const timestampConverter = (timestamp: number) => {
  let delta = timestamp
  const days = Math.floor(delta / (24 * 3600))
  delta -= days * 24 * 3600
  const hours = Math.floor(delta / 3600)
  delta -= hours * 3600
  const minutes = Math.floor(delta / 60)
  const seconds = delta - minutes * 60

  return { days, hours, minutes, seconds }
}

const Countdown: React.FC<CountdownProps> = ({
  seconds: timestampInSeconds,
  style,
  description,
  descriptionStyle,
  descriptionPosition = 'TOP',
  expirationHandler,
}) => {
  const theme = useTheme()
  const { isXl } = useMatchBreakpoints()
  const isMobile = !isXl

  const styleVal = useMemo(() => {
    return {
      fontSize: isMobile ? 18 : 24,
      fontWeight: 700,
      lineHeight: isMobile ? '25px' : '30px',
      color: theme.sale.countdownTimerColor,
      ...style,
    }
  }, [style, theme, isMobile])

  const [margin, flexDirection] = useMemo(() => {
    let ret: string[]
    switch (descriptionPosition) {
      case 'TOP':
        ret = ['0 0 5px 0', 'column']
        break
      case 'BOTTOM':
        ret = ['5px 0 0 0', 'column-reverse']
        break
      case 'LEFT':
        ret = ['0 5px 0 0', 'row']
        break
      default:
        ret = ['0 0 0 5px ', 'row-reverse']
    }
    return ret
  }, [descriptionPosition])

  const descriptionStyleVal = useMemo(() => {
    return {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20px',
      color: theme.sale.countdownDescColor,
      margin,
      ...descriptionStyle,
    }
  }, [descriptionStyle, theme, margin])

  const [timeLeft, setTimeLeft] = useState(timestampInSeconds)

  const { days, hours, minutes, seconds } = timestampConverter(timeLeft)
  const daysStr = `${days < 10 ? '0' : ''}${days}`
  const hoursStr = `${hours < 10 ? '0' : ''}${hours}`
  const minutesStr = `${minutes < 10 ? '0' : ''}${minutes}`
  const secondsStr = `${seconds < 10 ? '0' : ''}${seconds}`

  useEffect(() => {
    setTimeLeft(timestampInSeconds)
  }, [setTimeLeft, timestampInSeconds])

  useEffect(() => {
    if (timeLeft <= 0) {
      if (expirationHandler) {
        expirationHandler()
      }
      return undefined
    }
    const timer = setTimeout(() => {
      setTimeLeft(timeLeft - 1)
    }, 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [timeLeft, setTimeLeft, expirationHandler])

  const descriptionElement = useMemo(() => {
    return description == null ? null : <Box sx={descriptionStyleVal}>{description}</Box>
  }, [description, descriptionStyleVal])
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection,
          alignItems: descriptionPosition === 'LEFT' || descriptionPosition === 'RIGHT' ? 'center' : 'inherit',
        }}
      >
        {descriptionElement}
        <Box
          style={{
            visibility: seconds ? 'visible' : 'hidden',
          }}
          sx={styleVal}
        >{`${daysStr}d : ${hoursStr}h : ${minutesStr}m : ${secondsStr}s`}</Box>
      </Box>
    </Box>
  )
}

export default Countdown

import React from 'react'
import { useAccount, useDisconnect } from 'wagmi'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { Box } from '@mui/material'

import Page from 'componentsV2/layout/Page'
import { connectorLocalStorageKey } from 'uikit/widgets/WalletModal/config'

import WarningSquareIcon from 'componentsV2/Icons/WarningSquare'
import { Flex, Button, useMatchBreakpoints } from 'uikit'
import IFTypography from 'componentsV2/IFTypography'
import { useTranslation } from 'contexts/Localization'
import useAuth from 'hooks/useAuth'
import { AddressState } from 'config/constants/types'

import useFetchUserState from 'hooks/useFetchUserState'
import { useUserState } from 'state/user/hooks'

type Props = {
  children: React.ReactNode
}

const BlockedContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  justify-content: center;
`

const CheckBlackList = ({ children }: Props) => {
  const { t } = useTranslation()
  const { address: account } = useAccount()
  const { logout } = useAuth()
  const history = useHistory()
  const { isXl } = useMatchBreakpoints()
  const isMobile = !isXl
  const userState = useUserState()
  const { disconnect } = useDisconnect({ onSuccess: logout })

  useFetchUserState()

  if (userState !== AddressState.BLACKLISTED) {
    return <>{children}</>
  }
  return (
    <Page>
      <BlockedContainer>
        <Flex flexDirection="column" alignItems="center" maxWidth="420px" margin="0 auto">
          <WarningSquareIcon />
          <Box marginTop="22.5px">
            <IFTypography variant="h3">Blocked Address</IFTypography>
          </Box>
          <Box marginY="15px">
            <IFTypography variant="body2" ifcolor="textThirdly">
              {account}
            </IFTypography>
          </Box>

          <Box marginBottom="30px" textAlign={isMobile ? 'center' : 'left'}>
            <IFTypography variant="body2" paddingX={2}>
              {t('This address is blocked as it is flagged for high risk activities.')}
            </IFTypography>
            <IFTypography variant="body2" paddingX={2}>
              {t('If you believe this is incorrect, please contact our support at xxx')}
            </IFTypography>
          </Box>
          <Flex justifyContent="center">
            <Button
              onClick={() => {
                disconnect()
                window.localStorage.removeItem(connectorLocalStorageKey)
                history.push('/explore')
              }}
            >
              {t('Disconnect Wallet')}
            </Button>
          </Flex>
        </Flex>
      </BlockedContainer>
    </Page>
  )
}

export default CheckBlackList

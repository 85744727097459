import React from 'react'
import useTheme from 'hooks/useTheme'
import IFTypography from 'componentsV2/IFTypography'
import { useTranslation } from 'contexts/Localization'
import { Flex } from 'uikit'

const SuccessPage: React.FC = () => {
  const { theme } = useTheme()
  const { t } = useTranslation()

  return (
    <Flex margin="0px auto 40px" flexDirection="column">
      <Flex justifyContent="center" alignItems="center" paddingBottom="50px">
        <img
          src={theme.isDark ? '/images/Icon-Checked-Dark.svg' : '/images/Icon-Checked-Light.svg'}
          alt="checked"
          width="85px"
          height="85px"
        />
      </Flex>
      <IFTypography fontSize="24px" lineHeight="30px" align="center" paddingBottom="30px" fontWeight="bold">
        {t('Your Citizenship Has Been Updated')}
      </IFTypography>
      <IFTypography fontSize="14px" lineHeight="20px" align="center" paddingBottom="20px" color="textDisabled">
        {t('You may be asked periodically to re-confirm your current status.')}
      </IFTypography>
    </Flex>
  )
}

export default SuccessPage

import { useNetwork } from 'wagmi'
import { BigNumber } from 'ethers'
import { useSingleCallResult } from '../state/multicall/hooks'
import { useMulticallContract } from './useContract'

// gets the current timestamp from the blockchain
export default function useCurrentBlockTimestamp(): BigNumber | undefined {
  const { chain } = useNetwork()
  const chainId = chain?.id
  const multicall = useMulticallContract(chainId)
  return useSingleCallResult(multicall, chainId, 'getCurrentBlockTimestamp')?.result?.[0]
}

import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00004 14.6666C11.6819 14.6666 14.6667 11.6819 14.6667 7.99998C14.6667 4.31808 11.6819 1.33331 8.00004 1.33331C4.31814 1.33331 1.33337 4.31808 1.33337 7.99998C1.33337 11.6819 4.31814 14.6666 8.00004 14.6666ZM8.71461 12.5022V11.7519C11.1666 11.7669 11.7051 7.93769 9.20386 7.4507L7.03186 7.07026C6.25619 6.91268 6.57403 5.70082 7.28613 5.7496H8.90602C9.16029 5.7496 9.39742 5.89292 9.52527 6.12478C9.72169 6.48346 10.1581 6.60652 10.5009 6.39867C10.8416 6.19082 10.9587 5.73234 10.7609 5.37366C10.3788 4.67956 9.6674 4.24809 8.90531 4.24809H8.71389V3.49772C8.71032 2.51697 7.2897 2.51622 7.28541 3.49772V4.24809C4.83343 4.23308 4.29489 8.063 6.79616 8.55L8.96816 8.93044C9.74454 9.08802 9.42599 10.2999 8.71389 10.2511H7.094C6.83973 10.2511 6.6026 10.1078 6.47475 9.87516C6.27833 9.51723 5.84122 9.39267 5.4991 9.60203C5.15841 9.80913 5.04127 10.2676 5.23911 10.627C5.62123 11.3211 6.33262 11.7519 7.09471 11.7519H7.28613V12.5022C7.2897 13.483 8.71104 13.4837 8.71461 12.5022Z"
      />
    </Svg>
  )
}

export default Icon

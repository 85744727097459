import styled from 'styled-components'
import loGet from 'lodash/get'
import { scales } from '../Checkbox/types'
import { ToggleProps, HandleProps, InputProps, ScaleKeys } from './types'

const scaleKeyValues = {
  sm: {
    handleHeight: '16px',
    handleWidth: '16px',
    handleLeft: '2px',
    handleTop: '2px',
    toggleHeight: '20px',
    toggleWidth: '36px',
  },
  md: {
    handleHeight: '24px',
    handleWidth: '24px',
    handleLeft: '4px',
    handleTop: '4px',
    toggleHeight: '32px',
    toggleWidth: '58px',
  },
}

const getScale =
  (property: ScaleKeys) =>
  ({ scale = scales.MD }: ToggleProps) => {
    return scaleKeyValues[scale][property]
  }

export const Handle = styled.div<HandleProps>`
  background: ${({ theme, checked }) =>
    theme.isDark ? (checked ? theme.colors.text : theme.colorsV2.textThirdly) : theme.colorsV2?.main};
  border-radius: 50%;
  cursor: pointer;
  height: ${getScale('handleHeight')};
  left: ${getScale('handleLeft')};
  position: absolute;
  top: ${getScale('handleTop')};
  transition: left 200ms ease-in;
  width: ${getScale('handleWidth')};
  z-index: 1;
`

export const Input = styled.input<InputProps>`
  cursor: pointer;
  opacity: 0;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 3;

  &:checked + ${Handle} {
    left: calc(100% - 28px);
  }
`

const StyledToggle = styled.div<ToggleProps>`
  align-items: center;
  background: ${({ theme, checked }) => loGet(theme.colorsV2, checked ? 'gradients.green2lightblue' : 'light')};
  border-radius: 24px;
  box-shadow: ${({ theme }) => theme.shadows?.inset};
  cursor: pointer;
  display: inline-flex;
  height: ${getScale('toggleHeight')};
  position: relative;
  transition: background-color 200ms;
  width: ${getScale('toggleWidth')};
`

export default StyledToggle

/* eslint-disable import/no-cycle */
import { Chain, Token } from 'config/constants/types'
import { TokenClass } from 'swap-sdk/entities/token'

const getTokenClass = (token: Token, chainId: Chain): TokenClass | null => {
  const address = token.address[chainId]
  if (!address) {
    return null
  }

  return new TokenClass(chainId, address, token.decimals, token.symbol, token.symbol)
}

export default getTokenClass

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Chain } from 'config/constants/types'
import { chainIds } from 'utils/web3React'
import { BlockStateMap } from '../types'

const initialState: BlockStateMap = {
  ...chainIds.reduce((acc, val) => {
    acc[val] = { currentBlock: 0, initialBlock: 0 }
    return acc
  }, {}),
  [Chain.BSC_MAINNET]: { currentBlock: 20056354, initialBlock: 20056354 },
  [Chain.MOONRIVER_MAINNET]: { currentBlock: 2302174, initialBlock: 2302174 },
  initialised: false,
  lastUpdated: 0,
}

export const blockSlice = createSlice({
  name: 'Block',
  initialState,
  reducers: {
    setBlock: (state, action: PayloadAction<{ [chainId: number]: number }>) => {
      Object.keys(action.payload).forEach((chainId) => {
        if (!state[chainId]) {
          state[chainId] = { currentBlock: 0, initialBlock: 0 }
        }

        if (state[chainId]?.initialBlock === 0) {
          state[chainId].initialBlock = action.payload[chainId]
        }

        state[chainId].currentBlock = action.payload[chainId]
      })
      state.initialised = true
      state.lastUpdated = new Date().getTime()
    },
  },
})

// Actions
export const { setBlock } = blockSlice.actions

export default blockSlice.reducer

import React from 'react'
import IFTooltip, { IFTooltipProps } from 'componentsV2/IFTooltip'
import useTheme from 'hooks/useTheme'
import { SpaceProps } from 'styled-system'
import Box from 'uikit/components/Box/Box'
import Flex from 'uikit/components/Box/Flex'
import { TipsIcon } from 'uikit/components/Svg'

interface DefaultIFTooltipProps extends IFTooltipProps, SpaceProps {
  color?: string
}

export default function DefaultIFTooltip({
  title,
  color,
  placement,
  leaveDelay,
  leaveTouchDelay,
  ...space
}: DefaultIFTooltipProps) {
  const { theme } = useTheme()
  return (
    <Box ml="4px" {...space}>
      <IFTooltip title={title} placement={placement} leaveDelay={leaveDelay} leaveTouchDelay={leaveTouchDelay}>
        <Flex alignItems="center">
          <TipsIcon color={color || theme.colorsV2?.textSecondary} width="20px" />
        </Flex>
      </IFTooltip>
    </Box>
  )
}

import React from 'react'
import Svg from '../../../components/Svg/Svg'

const LogoLightWithText: React.FC = ({ ...props }) => {
  return (
    <Svg width="134" height="35" viewBox="0 0 134 35" fill="none" {...props}>
      <path
        d="M4.22672 12.3795C6.54921 12.3795 8.43196 10.4707 8.43196 8.11616C8.43196 5.76159 6.54921 3.85284 4.22672 3.85284C1.90423 3.85284 0.0214844 5.76159 0.0214844 8.11616C0.0214844 10.4707 1.90423 12.3795 4.22672 12.3795Z"
        fill="#050A5A"
      />
      <path
        d="M23.4863 12.3795H17.662C16.9471 12.3795 16.3584 11.7826 16.3584 11.0579V5.15316C16.3584 4.42839 16.9471 3.83152 17.662 3.83152H23.4863C24.2012 3.83152 24.7899 4.42839 24.7899 5.15316V11.0579C24.7899 11.8039 24.2012 12.3795 23.4863 12.3795Z"
        fill="#00FFB9"
      />
      <path
        d="M8.4315 16.4083H8.17919H1.78723C0.798995 16.4083 0 17.2183 0 18.2202V24.9563V31.671C0 32.6729 0.798995 33.4829 1.78723 33.4829H6.64428C7.63251 33.4829 8.4315 32.6729 8.4315 31.671V26.7682C8.4315 25.7663 9.2305 24.9563 10.2187 24.9563H14.8235C15.8117 24.9563 16.6107 24.1462 16.6107 23.1444V18.2202C16.6107 17.2183 15.8117 16.4083 14.8235 16.4083H8.4315Z"
        fill="#00DAFF"
      />
      <path d="M40.6858 0.975098H43.1669V15.8541H40.6858V0.975098Z" fill="#050A5A" />
      <path
        d="M45.8162 6.1124H48.2973V7.07165C48.865 6.34689 49.685 5.92056 50.6312 5.92056C51.7245 5.92056 52.7128 6.4961 53.3856 7.45535C54.0164 6.51742 55.0046 5.92056 56.2452 5.92056C58.2847 5.92056 59.7986 7.56193 59.7986 9.69359V15.8541H57.3175V9.88544C57.3175 8.92619 56.6447 8.1588 55.7195 8.1588C54.7103 8.1588 54.0584 8.99014 54.0584 9.99202V15.8541H51.5773V9.88544C51.5773 8.92619 50.9045 8.1588 49.9373 8.1588C48.9491 8.1588 48.3183 8.99014 48.3183 9.99202V15.8541H45.8162V6.1124Z"
        fill="#050A5A"
      />
      <path
        d="M62.0906 6.1124H64.5717V7.15692C65.3286 6.38952 66.3589 5.92056 67.6205 5.92056C70.2067 5.92056 72.3514 8.1588 72.3514 10.9939C72.3514 13.829 70.2067 16.0673 67.6205 16.0673C66.3589 16.0673 65.3286 15.5983 64.5717 14.8309V20.3306H62.0906V6.1124V6.1124ZM69.8913 10.9939C69.8913 9.43779 68.6718 8.20143 67.1579 8.20143C65.644 8.20143 64.4035 9.43779 64.4035 10.9939C64.4035 12.55 65.644 13.7864 67.1579 13.7864C68.6718 13.7651 69.8913 12.5287 69.8913 10.9939Z"
        fill="#050A5A"
      />
      <path
        d="M73.5918 10.9939C73.5918 8.18011 75.7785 5.92056 78.6801 5.92056C81.5397 5.92056 83.7475 8.1588 83.7475 10.9939C83.7475 13.829 81.5397 16.0673 78.6801 16.0673C75.7785 16.0459 73.5918 13.8077 73.5918 10.9939ZM81.3505 10.9939C81.3505 9.45911 80.152 8.26538 78.6801 8.26538C77.1452 8.26538 75.9467 9.45911 75.9467 10.9939C75.9467 12.5287 77.1452 13.7438 78.6801 13.7438C80.152 13.7224 81.3505 12.5074 81.3505 10.9939Z"
        fill="#050A5A"
      />
      <path
        d="M84.7568 14.5964L86.1446 12.8698C86.8174 13.5732 87.7005 13.9996 88.4574 13.9996C89.2144 13.9996 89.677 13.5945 89.677 13.0616C89.677 12.3795 88.899 12.209 87.7426 11.8039C86.2497 11.271 84.9881 10.5889 84.9881 8.88356C84.9881 6.90111 86.6702 5.89923 88.5205 5.89923C89.9083 5.89923 91.0857 6.45346 91.8216 7.28481L90.3708 8.77697C89.8242 8.22274 89.0882 7.83904 88.4364 7.83904C87.8267 7.83904 87.4272 8.15879 87.4272 8.67039C87.4272 9.33121 88.121 9.52305 89.2354 9.88544C90.6652 10.3757 92.116 11.0365 92.116 12.9763C92.116 14.9588 90.6021 16.0246 88.5205 16.0246C86.8805 16.0459 85.5348 15.3638 84.7568 14.5964Z"
        fill="#050A5A"
      />
      <path
        d="M92.978 14.5964L94.3658 12.8698C95.0386 13.5732 95.9217 13.9996 96.6786 13.9996C97.4356 13.9996 97.8982 13.5945 97.8982 13.0616C97.8982 12.3795 97.1202 12.209 95.9637 11.8039C94.4709 11.271 93.2093 10.5889 93.2093 8.88356C93.2093 6.90111 94.8914 5.89923 96.7417 5.89923C98.1295 5.89923 99.3069 6.45346 100.043 7.28481L98.592 8.77697C98.0453 8.22274 97.3094 7.83904 96.6576 7.83904C96.0479 7.83904 95.6484 8.15879 95.6484 8.67039C95.6484 9.33121 96.3422 9.52305 97.4566 9.88544C98.8864 10.3757 100.337 11.0365 100.337 12.9763C100.337 14.9588 98.8233 16.0246 96.7417 16.0246C95.1017 16.0459 93.756 15.3638 92.978 14.5964Z"
        fill="#050A5A"
      />
      <path
        d="M101.977 2.93624C101.977 2.12621 102.65 1.48671 103.428 1.48671C104.227 1.48671 104.879 2.12621 104.879 2.93624C104.879 3.74627 104.227 4.38576 103.428 4.38576C102.629 4.38576 101.977 3.74627 101.977 2.93624ZM102.208 6.11241H104.647V15.8541H102.208V6.11241Z"
        fill="#050A5A"
      />
      <path
        d="M109.61 14.5964L109.547 15.8541H107.234V0.975098H109.715V7.22086C110.451 6.32557 111.607 5.87792 112.785 5.87792C115.476 5.87792 117.6 8.13748 117.6 10.9726C117.6 13.8077 115.476 16.0673 112.785 16.0673C111.46 16.0459 110.325 15.513 109.61 14.5964ZM115.118 10.9726C115.118 9.37384 113.92 8.13748 112.364 8.13748C110.829 8.13748 109.61 9.37384 109.61 10.9726C109.61 12.5713 110.829 13.829 112.364 13.829C113.92 13.829 115.118 12.5713 115.118 10.9726Z"
        fill="#050A5A"
      />
      <path d="M119.534 0.975098H121.994V15.8541H119.534V0.975098Z" fill="#050A5A" />
      <path
        d="M133.979 11.4202H126.41C126.599 12.9977 127.713 14.0209 129.122 14.0209C130.131 14.0209 131.078 13.5093 131.582 12.614L133.559 13.4453C132.781 15.0441 131.162 16.0459 129.143 16.0459C126.094 16.0459 123.992 13.8077 123.992 10.9726C123.992 8.13747 126.136 5.89923 129.059 5.89923C131.834 5.89923 133.958 7.88167 134 10.866L133.979 11.4202ZM126.662 9.52305H131.372C130.972 8.54249 130.215 7.92431 129.08 7.92431C127.903 7.90299 127.083 8.52117 126.662 9.52305Z"
        fill="#050A5A"
      />
      <path
        d="M40.6858 19.8829H49.8322V22.3983H43.1669V26.0648H49.0752V28.5801H43.1669V34.7619H40.6858V19.8829Z"
        fill="#050A5A"
      />
      <path
        d="M51.4092 21.8441C51.4092 21.034 52.082 20.3945 52.86 20.3945C53.659 20.3945 54.3108 21.034 54.3108 21.8441C54.3108 22.6541 53.659 23.2936 52.86 23.2936C52.061 23.2936 51.4092 22.6541 51.4092 21.8441ZM51.6405 25.0202H54.0795V34.7619H51.6405V25.0202Z"
        fill="#050A5A"
      />
      <path
        d="M56.6658 25.0203H59.1259V25.9156C59.8197 25.2121 60.7239 24.8284 61.6911 24.8284C63.6255 24.8284 65.1394 26.4272 65.1394 28.4735V34.7832H62.6583V28.7933C62.6583 27.7701 61.9013 27.0453 60.9131 27.0453C59.8828 27.0453 59.1259 27.7914 59.1048 28.7933V34.7832H56.6448V25.0203H56.6658Z"
        fill="#050A5A"
      />
      <path
        d="M66.8635 29.8804C66.8635 27.0666 69.0292 24.7858 71.6155 24.7858C72.9822 24.7858 74.0335 25.2974 74.7484 26.1287V25.0202H77.2295V34.7619H74.9166L74.8535 33.5042C74.1386 34.4209 73.0452 34.9538 71.6155 34.9538C69.0082 34.9538 66.8635 32.7155 66.8635 29.8804ZM74.8114 29.8804C74.8114 28.3243 73.6129 27.0879 72.078 27.0879C70.5431 27.0879 69.3236 28.3243 69.3236 29.8804C69.3236 31.4365 70.5221 32.6942 72.078 32.6942C73.6129 32.6729 74.8114 31.4152 74.8114 29.8804Z"
        fill="#050A5A"
      />
      <path
        d="M79.7945 25.0203H82.2545V25.9156C82.9484 25.2121 83.8525 24.8284 84.8197 24.8284C86.7541 24.8284 88.268 26.4272 88.268 28.4735V34.7832H85.7869V28.7933C85.7869 27.7701 85.03 27.0453 84.0418 27.0453C83.0115 27.0453 82.2545 27.7914 82.2335 28.7933V34.7832H79.7734V25.0203H79.7945Z"
        fill="#050A5A"
      />
      <path
        d="M89.9922 29.9018C89.9922 27.088 92.1789 24.8284 95.0805 24.8284C95.7954 24.8284 96.5103 24.9776 97.12 25.2548V28.0472C96.6364 27.4717 95.9216 27.1093 95.0805 27.1093C93.5246 27.1093 92.3681 28.3457 92.3681 29.9018C92.3681 31.4579 93.5036 32.6729 95.0805 32.6729C95.9216 32.6729 96.6364 32.3105 97.12 31.735V34.5488C96.5103 34.8259 95.7954 34.9751 95.0805 34.9751C92.1789 34.9538 89.9922 32.7155 89.9922 29.9018Z"
        fill="#050A5A"
      />
      <path
        d="M108.558 30.3281H100.989C101.178 31.9055 102.293 32.9287 103.701 32.9287C104.711 32.9287 105.657 32.4171 106.161 31.5218L108.138 32.3532C107.36 33.9519 105.741 34.9538 103.722 34.9538C100.674 34.9538 98.571 32.7155 98.571 29.8804C98.571 27.0453 100.716 24.8071 103.638 24.8071C106.414 24.8071 108.537 26.7895 108.58 29.7738L108.558 30.3281ZM101.22 28.4309H105.93C105.531 27.4503 104.774 26.8322 103.638 26.8322C102.482 26.8108 101.641 27.429 101.22 28.4309Z"
        fill="#050A5A"
      />
    </Svg>
  )
}

export default React.memo(LogoLightWithText)

import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

interface LogoProps extends SvgProps {
  isDark: boolean
}

const Logo: React.FC<LogoProps> = ({ ...props }) => {
  return (
    <Svg viewBox="150,375,800,250" x="0px" y="0px" {...props}>
      <g>
        <circle fill="#050A5A" cx="196.2" cy="450.5" r="20" />
        <path
          fill="#00FFB9"
          d="M287.8,470.5h-27.7c-3.4,0-6.2-2.8-6.2-6.2v-27.7c0-3.4,2.8-6.2,6.2-6.2h27.7c3.4,0,6.2,2.8,6.2,6.2v27.7
		C294,467.8,291.2,470.5,287.8,470.5z"
        />
        <path
          fill="#00DAFF"
          d="M216.2,489.4h-1.2h-30.4c-4.7,0-8.5,3.8-8.5,8.5v31.6V561c0,4.7,3.8,8.5,8.5,8.5h23.1c4.7,0,8.5-3.8,8.5-8.5
		V538c0-4.7,3.8-8.5,8.5-8.5h21.9c4.7,0,8.5-3.8,8.5-8.5v-23.1c0-4.7-3.8-8.5-8.5-8.5H216.2z"
        />
        <g>
          <path fill="#050A5A" d="M369.6,417h11.8v69.8h-11.8V417z" />
          <path
            fill="#050A5A"
            d="M394,441.1h11.8v4.5c2.7-3.4,6.6-5.4,11.1-5.4c5.2,0,9.9,2.7,13.1,7.2c3-4.4,7.7-7.2,13.6-7.2
			c9.7,0,16.9,7.7,16.9,17.7v28.9h-11.8v-28c0-4.5-3.2-8.1-7.6-8.1c-4.8,0-7.9,3.9-7.9,8.6v27.5h-11.8v-28c0-4.5-3.2-8.1-7.8-8.1
			c-4.7,0-7.7,3.9-7.7,8.6v27.5H394V441.1z"
          />
          <path
            fill="#050A5A"
            d="M471.4,441.1h11.8v4.9c3.6-3.6,8.5-5.8,14.5-5.8c12.3,0,22.5,10.5,22.5,23.8s-10.2,23.8-22.5,23.8
			c-6,0-10.9-2.2-14.5-5.8v25.8h-11.8V441.1z M508.5,464c0-7.3-5.8-13.1-13-13.1c-7.2,0-13.1,5.8-13.1,13.1
			c0,7.3,5.9,13.1,13.1,13.1C502.7,477,508.5,471.2,508.5,464z"
          />
          <path
            fill="#050A5A"
            d="M526.1,464c0-13.2,10.4-23.8,24.2-23.8c13.6,0,24.1,10.5,24.1,23.8s-10.5,23.8-24.1,23.8
			C536.5,487.7,526.1,477.2,526.1,464z M563,464c0-7.2-5.7-12.8-12.7-12.8c-7.3,0-13,5.6-13,12.8s5.7,12.9,13,12.9
			C557.3,476.8,563,471.1,563,464z"
          />
          <path
            fill="#050A5A"
            d="M579.2,480.9l6.6-8.1c3.2,3.3,7.4,5.3,11,5.3c3.6,0,5.8-1.9,5.8-4.4c0-3.2-3.7-4-9.2-5.9
			c-7.1-2.5-13.1-5.7-13.1-13.7c0-9.3,8-14,16.8-14c6.6,0,12.2,2.6,15.7,6.5l-6.9,7c-2.6-2.6-6.1-4.4-9.2-4.4
			c-2.9,0-4.8,1.5-4.8,3.9c0,3.1,3.3,4,8.6,5.7c6.8,2.3,13.7,5.4,13.7,14.5c0,9.3-7.2,14.3-17.1,14.3
			C589.3,487.7,582.9,484.5,579.2,480.9z"
          />
          <path
            fill="#050A5A"
            d="M618.3,480.9l6.6-8.1c3.2,3.3,7.4,5.3,11,5.3c3.6,0,5.8-1.9,5.8-4.4c0-3.2-3.7-4-9.2-5.9
			c-7.1-2.5-13.1-5.7-13.1-13.7c0-9.3,8-14,16.8-14c6.6,0,12.2,2.6,15.7,6.5l-6.9,7c-2.6-2.6-6.1-4.4-9.2-4.4
			c-2.9,0-4.8,1.5-4.8,3.9c0,3.1,3.3,4,8.6,5.7c6.8,2.3,13.7,5.4,13.7,14.5c0,9.3-7.2,14.3-17.1,14.3
			C628.4,487.7,622,484.5,618.3,480.9z"
          />
          <path
            fill="#050A5A"
            d="M661.1,426.2c0-3.8,3.2-6.8,6.9-6.8c3.8,0,6.9,3,6.9,6.8c0,3.8-3.1,6.8-6.9,6.8
			C664.2,433,661.1,430,661.1,426.2z M662.2,441.1h11.6v45.7h-11.6V441.1z"
          />
          <path
            fill="#050A5A"
            d="M697.4,480.9l-0.3,5.9h-11V417h11.8v29.3c3.5-4.2,9-6.3,14.6-6.3c12.8,0,22.9,10.6,22.9,23.9
			c0,13.3-10.1,23.9-22.9,23.9C706.2,487.7,700.8,485.2,697.4,480.9z M723.6,463.9c0-7.5-5.7-13.3-13.1-13.3
			c-7.3,0-13.1,5.8-13.1,13.3s5.8,13.4,13.1,13.4C717.9,477.3,723.6,471.4,723.6,463.9z"
          />
          <path fill="#050A5A" d="M744.6,417h11.7v69.8h-11.7V417z" />
          <path
            fill="#050A5A"
            d="M813.3,466h-36c0.9,7.4,6.2,12.2,12.9,12.2c4.8,0,9.3-2.4,11.7-6.6l9.4,3.9c-3.7,7.5-11.4,12.2-21,12.2
			c-14.5,0-24.5-10.5-24.5-23.8s10.2-23.8,24.1-23.8c13.2,0,23.3,9.3,23.5,23.3L813.3,466z M778.5,457.1h22.4
			c-1.9-4.6-5.5-7.5-10.9-7.5C784.4,449.5,780.5,452.4,778.5,457.1z"
          />
          <path fill="#050A5A" d="M369.6,505.7h43.5v11.8h-31.7v17.2h28.1v11.8h-28.1v29h-11.8V505.7z" />
          <path
            fill="#050A5A"
            d="M420.6,514.9c0-3.8,3.2-6.8,6.9-6.8c3.8,0,6.9,3,6.9,6.8c0,3.8-3.1,6.8-6.9,6.8
			C423.7,521.7,420.6,518.7,420.6,514.9z M421.7,529.8h11.6v45.7h-11.6V529.8z"
          />
          <path
            fill="#050A5A"
            d="M445.6,529.8h11.7v4.2c3.3-3.3,7.6-5.1,12.2-5.1c9.2,0,16.4,7.5,16.4,17.1v29.6h-11.8v-28.1
			c0-4.8-3.6-8.2-8.3-8.2c-4.9,0-8.5,3.5-8.6,8.2v28.1h-11.7V529.8z"
          />
          <path
            fill="#050A5A"
            d="M494.1,552.6c0-13.2,10.3-23.9,22.6-23.9c6.5,0,11.5,2.4,14.9,6.3v-5.2h11.8v45.7h-11l-0.3-5.9
			c-3.4,4.3-8.6,6.8-15.4,6.8C504.3,576.4,494.1,565.9,494.1,552.6z M531.9,552.6c0-7.3-5.7-13.1-13-13.1c-7.3,0-13.1,5.8-13.1,13.1
			c0,7.3,5.7,13.2,13.1,13.2C526.2,565.7,531.9,559.8,531.9,552.6z"
          />
          <path
            fill="#050A5A"
            d="M555.6,529.8h11.7v4.2c3.3-3.3,7.6-5.1,12.2-5.1c9.2,0,16.4,7.5,16.4,17.1v29.6h-11.8v-28.1
			c0-4.8-3.6-8.2-8.3-8.2c-4.9,0-8.5,3.5-8.6,8.2v28.1h-11.7V529.8z"
          />
          <path
            fill="#050A5A"
            d="M604.1,552.7c0-13.2,10.4-23.8,24.2-23.8c3.4,0,6.8,0.7,9.7,2V544c-2.3-2.7-5.7-4.4-9.7-4.4
			c-7.4,0-12.9,5.8-12.9,13.1c0,7.3,5.4,13,12.9,13c4,0,7.4-1.7,9.7-4.4v13.2c-2.9,1.3-6.3,2-9.7,2
			C614.5,576.4,604.1,565.9,604.1,552.7z"
          />
          <path
            fill="#050A5A"
            d="M692.4,554.7h-36c0.9,7.4,6.2,12.2,12.9,12.2c4.8,0,9.3-2.4,11.7-6.6l9.4,3.9c-3.7,7.5-11.4,12.2-21,12.2
			c-14.5,0-24.5-10.5-24.5-23.8s10.2-23.8,24.1-23.8c13.2,0,23.3,9.3,23.5,23.3L692.4,554.7z M657.5,545.8h22.4
			c-1.9-4.6-5.5-7.5-10.9-7.5C663.5,538.2,659.5,541.1,657.5,545.8z"
          />
        </g>
      </g>
    </Svg>
  )
}

export default React.memo(Logo, (prev, next) => prev.isDark === next.isDark)

import { BigNumber } from '@ethersproject/bignumber'
import { TokenClass } from 'swap-sdk/entities/token'
import { TokenAmount } from 'swap-sdk/entities/fractions/tokenAmount'
import { useSingleCallResult } from 'state/multicall/hooks'
import { useTokenContract } from './useContract'

// returns undefined if input token is undefined, or fails to get token contract,
// or contract total supply cannot be fetched
function useTotalSupply(token?: TokenClass): TokenAmount | undefined {
  const contract = useTokenContract(token?.address, false)

  const totalSupply: BigNumber = useSingleCallResult(contract, token?.chainId, 'totalSupply')?.result?.[0]

  return token && totalSupply ? new TokenAmount(token, totalSupply.toString()) : undefined
}

export default useTotalSupply

// Set of helper functions to facilitate wallet setup

import networkParams from 'config/constants/networkParams'
import { Chain } from 'config/constants/types'
import '@wagmi/core/window'
import { Toast } from 'uikit/widgets/Toast'

/**
 * Prompt the user to add BSC as a network on Metamask, or switch to BSC if the wallet is on a different network
 * @returns {boolean} true if the setup succeeded, false otherwise
 */
export const setupNetwork = async (chainId: Chain) => {
  const provider = window.ethereum

  if (provider) {
    try {
      // check if the chain to connect to is installed
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${chainId.toString(16)}` }], // chainId must be in hexadecimal numbers
      })
      return true
    } catch (error) {
      // This error code indicates that the chain has not been added to MetaMask
      // if it is not, then install it into the user MetaMask
      // if ((error as any).code === 4902) {
      try {
        await provider.request({
          method: 'wallet_addEthereumChain',
          params: networkParams[chainId],
        })
      } catch (addError) {
        console.error(addError)
      }
      // }
      console.error(error)
      return false
    }
  } else {
    console.error('Please make sure you are using supported wallet')
    return false
  }
}

/**
 * Prompt the user to add a custom token to metamask
 * @param tokenAddress
 * @param tokenSymbol
 * @param tokenDecimals
 * @param tokenImage
 * @returns {boolean} true if the token has been added, false otherwise
 */
export const registerToken = async (
  tokenAddress: string,
  tokenSymbol: string,
  tokenDecimals: number,
  toastErrorV2?: (
    title: string,
    description?: string,
  ) => {
    payload: Toast
    type: 'toasts/push'
  },
) => {
  try {
    const tokenAdded = await (window as WindowChain).ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: tokenAddress,
          symbol: tokenSymbol,
          decimals: tokenDecimals,
        },
      },
    })

    return tokenAdded
  } catch (error) {
    if ((error as any).code == -32602 && toastErrorV2) {
      toastErrorV2(
        `Token name too long. Consider adding it manually in Metamask.`,
        `Contract Address: ${tokenAddress}\nSymbol: ${tokenSymbol}\nDecimals: ${tokenDecimals}.`,
      )

      console.error('Metamask hello')
    } else {
      console.error('Metamask', error)
    }
    return false
  }
}

import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = ({ fillColor }) => {
  return (
    <Svg width="18" height="18" viewBox="0 0 18 18" fill="none">
      <circle cx="9" cy="9" r="3" fill={fillColor} />
      <path d="M9 3.75V2.25" stroke={fillColor} strokeWidth="2" strokeLinecap="round" />
      <path d="M9 15.75V14.25" stroke={fillColor} strokeWidth="2" strokeLinecap="round" />
      <path d="M12.7128 5.28747L13.7734 4.22681" stroke={fillColor} strokeWidth="2" strokeLinecap="round" />
      <path d="M4.22743 13.7733L5.28809 12.7126" stroke={fillColor} strokeWidth="2" strokeLinecap="round" />
      <path d="M14.25 9L15.75 9" stroke={fillColor} strokeWidth="2" strokeLinecap="round" />
      <path d="M2.25 9L3.75 9" stroke={fillColor} strokeWidth="2" strokeLinecap="round" />
      <path d="M12.7128 12.7125L13.7734 13.7732" stroke={fillColor} strokeWidth="2" strokeLinecap="round" />
      <path d="M4.22743 4.22669L5.28809 5.28735" stroke={fillColor} strokeWidth="2" strokeLinecap="round" />
    </Svg>
  )
}

export default Icon

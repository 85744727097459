import { useEffect, useState } from 'react'
import useRefresh from './useRefresh'

const API_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/api/backend-service/coins/price`

export const useCoinGeckoPrice = (ids: string[]) => {
  const { fiveMinutesRefresh } = useRefresh()
  const [priceMap, setPriceMap] = useState(undefined)
  const uniqueIds = [...new Set(ids)].sort()

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const result = await (
          await fetch(`${API_ENDPOINT}?ids=${uniqueIds.join(',')}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          })
        ).json()
        setPriceMap(
          result.data.reduce((acc, value) => {
            acc[value.coin] = { price: value.price, state: value.state }
            return acc
          }, {}),
        )
      } catch (e) {
        console.error(`Error fetching price of ${uniqueIds}`)
      }
    }
    if (uniqueIds.length > 0) {
      fetchPrices()
    }
  }, [uniqueIds, fiveMinutesRefresh])

  return priceMap
}

export default useCoinGeckoPrice

import BigNumber from 'bignumber.js'
import React, { useMemo } from 'react'
import { ClosedDark, ClosedLight } from 'componentsV2/Icons/Sale/Closed'
import styled, { useTheme } from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import IFTypography from 'componentsV2/IFTypography/IFTypography'
import { Box } from '@mui/material'
import SaleDetailCard from '../SaleDetailCard'

interface SaleMissedCardProps {
  participants: string
  sales: BigNumber
  tokenSymbol: string
}

const Body = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`

const SaleMissedCard: React.FC<SaleMissedCardProps> = ({ participants, sales, tokenSymbol }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const title = t('The sale has ended.')
  const imageInfo = useMemo(() => {
    return {
      image: theme.isDark ? <ClosedDark /> : <ClosedLight />,
    }
  }, [theme])

  const participantsStr = useMemo(() => {
    return parseInt(participants).toLocaleString()
  }, [participants])

  const salesStr = useMemo(() => {
    return sales.decimalPlaces(2).toNumber().toLocaleString()
  }, [sales])
  return (
    <SaleDetailCard title={title} imageInfo={imageInfo}>
      <Body>
        <Box display="flex" justifyContent="space-between" width="100%">
          <IFTypography variant="button" ifcolor="text">
            {t('Participants')}
          </IFTypography>
          <IFTypography variant="button" ifcolor="text" fontWeight="bold">
            {participantsStr}
          </IFTypography>
        </Box>
        <Box display="flex" justifyContent="space-between" width="100%">
          <IFTypography variant="button" ifcolor="text">
            {t('Total Sales')}
          </IFTypography>
          <IFTypography variant="button" ifcolor="text" fontWeight="bold">
            {salesStr} {tokenSymbol}
          </IFTypography>
        </Box>
      </Body>
    </SaleDetailCard>
  )
}

export default SaleMissedCard

import React, { createContext, RefObject, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useMatchBreakpoints } from 'uikit/hooks'

interface AccountSidebarContext {
  isOpen: boolean
  containerRef: null | RefObject<HTMLDivElement>
  kycSectionRef: null | RefObject<HTMLElement>
  toggleAccountSidebar: () => void
  openAccountSidebar: () => void
  closeAccountSidebar: () => void
  scrollToKYC: () => void
}

interface LifecycleHandler {
  afterOpen: () => void | null
  afterClose: () => void | null
}

type LifycycleHandlerSetter = (name: keyof LifecycleHandler, handler: () => void) => void

const disableBodyScrolling = () => {
  const bodyOverflow = document.body.style.overflow
  if (bodyOverflow !== 'hidden') {
    document.body.style.overflow = 'hidden'
  }
}

const enableBodyScrolling = () => {
  const bodyOverflow = document.body.style.overflow
  if (bodyOverflow !== 'auto') {
    document.body.style.overflow = 'auto'
  }
}

export const Context = createContext<AccountSidebarContext>({
  isOpen: false,
  kycSectionRef: null,
  containerRef: null,
  toggleAccountSidebar: () => null,
  openAccountSidebar: () => null,
  closeAccountSidebar: () => null,
  scrollToKYC: () => null,
})

interface ProviderProps {
  children: React.ReactNode
}

export const AccountSidebarProvider: React.FC<ProviderProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const kycSectionRef = useRef<HTMLElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const { isXl } = useMatchBreakpoints()
  const isMobile = !isXl
  const lifycycleHandlerRef = useRef<LifecycleHandler>({
    afterOpen: null,
    afterClose: null,
  })

  const lifecycleHandlerSetter = useCallback<LifycycleHandlerSetter>(
    (name, handler) => {
      lifycycleHandlerRef.current[name] = handler
    },
    [lifycycleHandlerRef],
  )

  const openHandler = useCallback(() => {
    setIsOpen(true)
  }, [setIsOpen])
  const closeHandler = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])
  const toggleHandler = useCallback(() => {
    setIsOpen((prv) => !prv)
  }, [setIsOpen])

  useEffect(() => {
    const lifycycleHandler = lifycycleHandlerRef.current
    if (isOpen && lifycycleHandler.afterOpen) {
      lifycycleHandler.afterOpen()
      return
    }
    if (isOpen && containerRef.current) {
      if (isMobile) {
        disableBodyScrolling()
      } else {
        // disable/enable body scroll when enter/out of account sidebar for desktop
        containerRef.current.addEventListener('mouseover', disableBodyScrolling)
        containerRef.current.addEventListener('mouseout', enableBodyScrolling)
      }
    }
    if (!isOpen) {
      if (lifycycleHandler.afterClose) {
        lifycycleHandler.afterClose()
      }
      if (containerRef.current) {
        containerRef.current.removeEventListener('mouseover', disableBodyScrolling)
        containerRef.current.removeEventListener('mouseout', enableBodyScrolling)
      }
      enableBodyScrolling()

      lifecycleHandlerSetter('afterOpen', null)
      lifecycleHandlerSetter('afterClose', null)
    }
  }, [isOpen, isMobile, lifycycleHandlerRef, lifecycleHandlerSetter])

  const scrollToKYC = useCallback(() => {
    // AccountSidebar is open now
    if (kycSectionRef.current) {
      kycSectionRef.current.scrollIntoView()
      return
    }
    // AccountSidebar is closed now
    lifecycleHandlerSetter('afterOpen', () => kycSectionRef.current.scrollIntoView())
    openHandler()
  }, [kycSectionRef, openHandler, lifecycleHandlerSetter])

  return (
    <Context.Provider
      value={{
        isOpen,
        kycSectionRef,
        containerRef,
        toggleAccountSidebar: toggleHandler,
        openAccountSidebar: openHandler,
        closeAccountSidebar: closeHandler,
        scrollToKYC,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useAccountSidebar = () => {
  const context = useContext(Context)
  return context
}

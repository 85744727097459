import { PancakeTheme } from '../../theme'

const variants = (theme: PancakeTheme) => ({
  warning: {
    background: theme.isDark ? '#FFD02C33' : '#FEF8EE',
    borderColor: 'warning',
  },
  danger: {
    background: '#ED4B9E19',
    borderColor: 'failure',
  },
})

export default variants

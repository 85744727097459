import useSubmitKYC from 'hooks/useSubmitKYC'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Button, useModalV2 } from 'uikit'
import ModalV2 from 'uikit/widgets/Modal/ModalV2'
import KeyboardArrowRightIcon from '@mui/icons-material/ArrowForward'

import { SubmitKYCBody } from 'state/v2_types'
import MainPage from './MainPage'

const OverlayButton = styled(Button)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  background: transparent;
  z-index: 10;
`

const StyledButton = styled(Button)`
  margin: auto;
  margin-top: 20px;
  min-width: 250px;
`

interface ComplianceCardProps {
  type: 'overlay' | 'button'
  recompliance?: boolean
  disabled?: boolean
  showArrow?: boolean
  overrideOnPresent?: any
  textOverride?: string
  scale?: string
  onSubmit?: () => void
  styleObject?: React.CSSProperties
}

export type CompliancePage = 'compliance' | 'bab' | 'success' | 'kycList'

const ComplianceCard: React.FC<ComplianceCardProps> = ({
  type,
  recompliance,
  disabled,
  overrideOnPresent,
  textOverride,
  onSubmit,
  showArrow = false,
  scale = 'md',
  styleObject = {},
}) => {
  const { submitKYC, submitKYCSource, isLoading } = useSubmitKYC()
  const handleSubmit = (body: SubmitKYCBody, noToast: boolean, onSucceed?: any) => {
    const callback = () => {
      if (onSucceed) {
        onSucceed()
      }
      if (onSubmit) {
        onSubmit()
      }
    }
    submitKYC(body, noToast, callback)
  }
  const [onPresent] = useModalV2({
    modal: (
      <ModalV2 modalBodyPadding={['0 20px 60px 20px', '0 40px 20px']}>
        <MainPage
          recompliance={recompliance}
          submitKYC={handleSubmit}
          submitKYCSource={submitKYCSource}
          isLoading={isLoading}
        />
      </ModalV2>
    ),
  })

  const component = useMemo(() => {
    if (type === 'button') {
      return (
        <StyledButton
          scale={scale}
          style={styleObject}
          disabled={disabled}
          onClick={() => {
            if (overrideOnPresent) {
              overrideOnPresent()
            } else {
              onPresent()
            }
          }}
        >
          {textOverride || (recompliance ? 'Confirm' : 'Verify KYC')}
          {showArrow && <KeyboardArrowRightIcon style={{ width: 16, height: 16, marginLeft: 2 }} />}
        </StyledButton>
      )
    }
    if (type === 'overlay') return <OverlayButton onClick={() => onPresent()} />
    return <></>
  }, [onPresent, type])

  return component
}

export default ComplianceCard

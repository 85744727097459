import { Brands, CompanyContext } from 'contexts/CompanyContext'
import React, { useContext } from 'react'
import styled from 'styled-components'

interface Props {
  size?: number
}

const SVGContainer = styled.svg`
  path:first-child {
    fill: ${({ theme }) => (theme.isDark ? '#6361C9' : '#C7D9F3')};
  }
`

const NeedVerify: React.FC<Props> = ({ size = 100 }) => {
  const { brand } = useContext(CompanyContext)
  if (brand === Brands.RONIN) {
    return (
      <SVGContainer width={size} height={size} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.9318 9.34462C50.5331 8.88513 49.8199 8.88513 49.4212 9.34462L40.7212 19.3711C40.4784 19.6509 40.1009 19.7736 39.7401 19.6899L26.8082 16.692C26.2155 16.5547 25.6385 16.9739 25.5861 17.58L24.441 30.8053C24.4091 31.1744 24.1758 31.4955 23.8347 31.6399L11.6104 36.8158C11.0502 37.053 10.8298 37.7313 11.1436 38.2524L17.991 49.625C18.182 49.9423 18.182 50.3393 17.991 50.6566L11.1436 62.0292C10.8298 62.5504 11.0502 63.2287 11.6104 63.4659L23.8347 68.6417C24.1758 68.7861 24.4091 69.1073 24.441 69.4763L25.5861 82.7017C25.6385 83.3078 26.2155 83.727 26.8082 83.5896L39.7401 80.5917C40.1009 80.5081 40.4784 80.6307 40.7212 80.9105L49.4212 90.937C49.8199 91.3965 50.5331 91.3965 50.9318 90.937L59.6318 80.9105C59.8746 80.6307 60.2521 80.5081 60.613 80.5917L73.5449 83.5896C74.1375 83.727 74.7145 83.3078 74.767 82.7017L75.912 69.4763C75.944 69.1073 76.1773 68.7861 76.5184 68.6417L88.7426 63.4659C89.3028 63.2287 89.5232 62.5504 89.2094 62.0292L82.3621 50.6566C82.171 50.3393 82.171 49.9423 82.3621 49.625L89.2094 38.2524C89.5232 37.7313 89.3028 37.053 88.7426 36.8158L76.5184 31.6399C76.1773 31.4955 75.944 31.1744 75.912 30.8053L74.767 17.58C74.7145 16.9739 74.1375 16.5547 73.5449 16.6921L60.613 19.6899C60.2521 19.7736 59.8746 19.6509 59.6318 19.3711L50.9318 9.34462ZM69.8095 40.738C71.41 39.0762 71.41 36.3819 69.8095 34.7201C68.209 33.0583 65.614 33.0583 64.0135 34.7201L43.1799 56.3516L36.5047 48.5544C35.001 46.7979 32.4105 46.6397 30.7188 48.201C29.0271 49.7624 28.8747 52.4521 30.3784 54.2086L39.9413 65.3788C40.6912 66.2547 41.7558 66.7708 42.884 66.8052C44.0123 66.8396 45.1043 66.3894 45.9024 65.5607L69.8095 40.738Z"
          fill="#C7D9F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.9318 9.34462C50.5331 8.88513 49.8199 8.88513 49.4212 9.34462L40.7212 19.3711C40.4784 19.6509 40.1009 19.7736 39.7401 19.6899L26.8082 16.692C26.2155 16.5547 25.6385 16.9739 25.5861 17.58L24.441 30.8053C24.4091 31.1744 24.1758 31.4955 23.8347 31.6399L11.6104 36.8158C11.0502 37.053 10.8298 37.7313 11.1436 38.2524L17.991 49.625C18.182 49.9423 18.182 50.3393 17.991 50.6566L11.1436 62.0292C10.8298 62.5504 11.0502 63.2287 11.6104 63.4659L23.8347 68.6417C24.1758 68.7861 24.4091 69.1073 24.441 69.4763L25.5861 82.7017C25.6385 83.3078 26.2155 83.727 26.8082 83.5896L39.7401 80.5917C40.1009 80.5081 40.4784 80.6307 40.7212 80.9105L49.4212 90.937C49.8199 91.3965 50.5331 91.3965 50.9318 90.937L59.6318 80.9105C59.8746 80.6307 60.2521 80.5081 60.613 80.5917L73.5449 83.5896C74.1375 83.727 74.7145 83.3078 74.767 82.7017L75.912 69.4763C75.944 69.1073 76.1773 68.7861 76.5184 68.6417L88.7426 63.4659C89.3028 63.2287 89.5232 62.5504 89.2094 62.0292L82.3621 50.6566C82.171 50.3393 82.171 49.9423 82.3621 49.625L89.2094 38.2524C89.5232 37.7313 89.3028 37.053 88.7426 36.8158L76.5184 31.6399C76.1773 31.4955 75.944 31.1744 75.912 30.8053L74.767 17.58C74.7145 16.9739 74.1375 16.5547 73.5449 16.6921L60.613 19.6899C60.2521 19.7736 59.8746 19.6509 59.6318 19.3711L50.9318 9.34462ZM69.8095 40.738C71.41 39.0762 71.41 36.3819 69.8095 34.7201C68.209 33.0583 65.614 33.0583 64.0135 34.7201L43.1799 56.3516L36.5047 48.5544C35.001 46.7979 32.4105 46.6397 30.7188 48.201C29.0271 49.7624 28.8747 52.4521 30.3784 54.2086L39.9413 65.3788C40.6912 66.2547 41.7558 66.7708 42.884 66.8052C44.0123 66.8396 45.1043 66.3894 45.9024 65.5607L69.8095 40.738Z"
          fill="url(#paint0_linear_250_18645)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_250_18645"
            x1="50.1765"
            y1="9"
            x2="50.1765"
            y2="129.884"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#B6E9FF" stopOpacity="0.12" />
            <stop offset="1" stopColor="#0185FF" />
          </linearGradient>
        </defs>
      </SVGContainer>
    )
  }

  return (
    <SVGContainer width={size} height={size} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.756 9.204C50.3573 8.7445 49.6441 8.7445 49.2454 9.204L40.5454 19.2305C40.3027 19.5103 39.9251 19.633 39.5643 19.5493L26.6324 16.5514C26.0397 16.414 25.4628 16.8332 25.4103 17.4393L24.2653 30.6647C24.2333 31.0337 24 31.3549 23.6589 31.4993L11.4346 36.6751C10.8744 36.9123 10.654 37.5906 10.9678 38.1118L17.8152 49.4844C18.0062 49.8017 18.0062 50.1987 17.8152 50.516L10.9678 61.8886C10.654 62.4098 10.8744 63.0881 11.4346 63.3253L23.6589 68.5011C24 68.6455 24.2333 68.9667 24.2653 69.3357L25.4103 82.5611C25.4628 83.1672 26.0397 83.5864 26.6324 83.449L39.5643 80.4511C39.9251 80.3675 40.3027 80.4901 40.5454 80.7699L49.2454 90.7964C49.6441 91.2559 50.3573 91.2559 50.756 90.7964L59.4561 80.7699C59.6988 80.4901 60.0763 80.3675 60.4372 80.4511L73.3691 83.449C73.9617 83.5864 74.5387 83.1672 74.5912 82.5611L75.7362 69.3357C75.7682 68.9667 76.0015 68.6455 76.3426 68.5011L88.5668 63.3253C89.127 63.0881 89.3474 62.4098 89.0336 61.8886L82.1863 50.516C81.9952 50.1987 81.9952 49.8017 82.1863 49.4844L89.0336 38.1118C89.3474 37.5906 89.127 36.9123 88.5668 36.6751L76.3426 31.4993C76.0015 31.3549 75.7682 31.0337 75.7362 30.6647L74.5912 17.4393C74.5387 16.8332 73.9617 16.414 73.3691 16.5514L60.4372 19.5493C60.0763 19.633 59.6988 19.5103 59.4561 19.2305L50.756 9.204ZM69.6337 40.5974C71.2342 38.9356 71.2342 36.2412 69.6337 34.5794C68.0332 32.9176 65.4382 32.9176 63.8377 34.5794L43.0041 56.211L36.329 48.4138C34.8252 46.6573 32.2347 46.4991 30.543 48.0604C28.8513 49.6218 28.6989 52.3114 30.2027 54.068L39.7655 65.2382C40.5154 66.1141 41.58 66.6302 42.7083 66.6646C43.8365 66.699 44.9285 66.2488 45.7266 65.4201L69.6337 40.5974Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.756 9.204C50.3573 8.7445 49.6441 8.7445 49.2454 9.204L40.5454 19.2305C40.3027 19.5103 39.9251 19.633 39.5643 19.5493L26.6324 16.5514C26.0397 16.414 25.4628 16.8332 25.4103 17.4393L24.2653 30.6647C24.2333 31.0337 24 31.3549 23.6589 31.4993L11.4346 36.6751C10.8744 36.9123 10.654 37.5906 10.9678 38.1118L17.8152 49.4844C18.0062 49.8017 18.0062 50.1987 17.8152 50.516L10.9678 61.8886C10.654 62.4098 10.8744 63.0881 11.4346 63.3253L23.6589 68.5011C24 68.6455 24.2333 68.9667 24.2653 69.3357L25.4103 82.5611C25.4628 83.1672 26.0397 83.5864 26.6324 83.449L39.5643 80.4511C39.9251 80.3675 40.3027 80.4901 40.5454 80.7699L49.2454 90.7964C49.6441 91.2559 50.3573 91.2559 50.756 90.7964L59.4561 80.7699C59.6988 80.4901 60.0763 80.3675 60.4372 80.4511L73.3691 83.449C73.9617 83.5864 74.5387 83.1672 74.5912 82.5611L75.7362 69.3357C75.7682 68.9667 76.0015 68.6455 76.3426 68.5011L88.5668 63.3253C89.127 63.0881 89.3474 62.4098 89.0336 61.8886L82.1863 50.516C81.9952 50.1987 81.9952 49.8017 82.1863 49.4844L89.0336 38.1118C89.3474 37.5906 89.127 36.9123 88.5668 36.6751L76.3426 31.4993C76.0015 31.3549 75.7682 31.0337 75.7362 30.6647L74.5912 17.4393C74.5387 16.8332 73.9617 16.414 73.3691 16.5514L60.4372 19.5493C60.0763 19.633 59.6988 19.5103 59.4561 19.2305L50.756 9.204ZM69.6337 40.5974C71.2342 38.9356 71.2342 36.2412 69.6337 34.5794C68.0332 32.9176 65.4382 32.9176 63.8377 34.5794L43.0041 56.211L36.329 48.4138C34.8252 46.6573 32.2347 46.4991 30.543 48.0604C28.8513 49.6218 28.6989 52.3114 30.2027 54.068L39.7655 65.2382C40.5154 66.1141 41.58 66.6302 42.7083 66.6646C43.8365 66.699 44.9285 66.2488 45.7266 65.4201L69.6337 40.5974Z"
        fill="url(#paint0_linear_8811_65266)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8811_65266"
          x1="50.0007"
          y1="8.85938"
          x2="50.0007"
          y2="129.744"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B6DCFF" stopOpacity="0.12" />
          <stop offset="1" stopColor="#66B6FF" />
        </linearGradient>
      </defs>
    </SVGContainer>
  )
}

export default NeedVerify

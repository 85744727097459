import { useMemo } from 'react'
import { useNetwork } from 'wagmi'
import { TokenClass } from 'swap-sdk/entities/token'
import { TokenAmount } from 'swap-sdk/entities/fractions/tokenAmount'

import { useSingleCallResult } from '../state/multicall/hooks'
import { useTokenContract } from './useContract'

function useTokenAllowance(token?: TokenClass, owner?: string, spender?: string): TokenAmount | undefined {
  const contract = useTokenContract(token?.address)
  const { chain } = useNetwork()
  const chainId = chain?.id
  const inputs = useMemo(() => [owner, spender], [owner, spender])
  const allowance = useSingleCallResult(contract, chainId, 'allowance', inputs).result

  return useMemo(
    () => (token && allowance ? new TokenAmount(token, allowance.toString()) : undefined),
    [token, allowance],
  )
}

export default useTokenAllowance

import { Box } from '@mui/material'
import { useNetwork } from 'wagmi'
import BigNumber from 'bignumber.js'
import { PurchaseStartDark } from 'componentsV2/Icons/Sale/PurchaseStart'
import IFTypography from 'componentsV2/IFTypography/IFTypography'
import tokens from 'config/constants/tokens'
import { useTranslation } from 'contexts/Localization'
import React, { useMemo } from 'react'
import { StepRequirementEnum } from 'state/types'
import { PrivateIDOConfig } from 'state/v2_types'
import styled, { useTheme } from 'styled-components'
import { OpenNewIcon } from 'uikit'
import getMoreTokenAddress from 'utils/getMoreTokenAddress'
import SaleDetailCard from '../SaleDetailCard'

const ChildWrapper = styled.div`
  margin-top: 20px;
  align-self: stretch;

  span {
    font-family: Roboto;
    text-transform: unset;
  }
  ${({ theme }) => theme.mediaQueries?.md} {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
`

const PurchasedAmountWrapper = styled.div`
  padding: 20px;
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.colorsV2?.light}`};
  display: flex;
  flex-direction: column;
  align-items: center;
`

interface WhitelistPurchaseStartCardProps {
  sale: PrivateIDOConfig
}

const WhitelistPurchaseStartCard: React.FC<WhitelistPurchaseStartCardProps> = ({ sale }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { chain } = useNetwork()
  const chainId = chain?.id

  const countdownInfo = useMemo(() => {
    const timestamp = new Date(sale.purchasePeriod.startTime).getTime()
    const currentTimestamp = new Date().getTime()
    const seconds = Math.trunc((timestamp - currentTimestamp) / 1000)
    return {
      seconds,
      description: t('Purchase starts in'),
    }
  }, [sale, t])

  const imageInfo = useMemo(() => {
    return {
      image: theme.isDark ? <PurchaseStartDark /> : <PurchaseStartDark />,
    }
  }, [theme])

  const title = t('Congratulations!')

  const subtitleInfo = useMemo(() => {
    return {
      subtitle: t(
        'You have won the %symbol% Token Whitelisting Campaign! Make sure you have completed KYC before you make the purchase. You can only enter via whitelisted URL.',
        { symbol: sale.token.symbol },
      ),
      style: {
        marginTop: '5px',
      },
    }
  }, [t, sale])

  const component = useMemo(() => {
    const saleTokenAllocationOverride = sale.saleTokenAllocationOverride ?? 0
    const { salePrice } = sale.purchasePeriod
    if (salePrice == null) return null
    const saleTokenAllocation = new BigNumber(saleTokenAllocationOverride)
    const allocationInPayment = saleTokenAllocation.times(salePrice)
    return (
      <ChildWrapper>
        <PurchasedAmountWrapper>
          <IFTypography ifcolor="textSecondary" variant="button" fontWeight="regular" lineHeight="20px">
            {t('Your Allocation')}
          </IFTypography>
          <Box display="flex" marginTop="5px">
            <Box marginRight="10px">
              <img src={sale.token.image} alt="token_icon" width="36px" />
            </Box>
            <IFTypography ifcolor="text" variant="h2" fontWeight="regular">
              {saleTokenAllocation.decimalPlaces(2).toNumber().toString()}
            </IFTypography>
          </Box>
        </PurchasedAmountWrapper>
        <Box marginTop="5px" textAlign="center">
          <IFTypography ifcolor="textSecondary" variant="overline">
            {t('You will need')} {allocationInPayment.decimalPlaces(2).toNumber().toString()} {sale.paymentToken.symbol}
          </IFTypography>
        </Box>
      </ChildWrapper>
    )
  }, [sale, t])

  const linkBtnInfo = useMemo(() => {
    return {
      text: t('Get more %token%', { token: sale.paymentToken.symbol }),
      icon: <OpenNewIcon />,
      href: getMoreTokenAddress(tokens[sale.paymentToken.symbol.toLowerCase()], { chainId }),
    }
  }, [chainId, t, sale])

  return (
    <SaleDetailCard
      requireKYC={!!sale.stepRequirement?.includes(StepRequirementEnum.KYC)}
      countdownInfo={countdownInfo}
      imageInfo={imageInfo}
      title={title}
      subtitleInfo={subtitleInfo}
      linkBtnInfo={linkBtnInfo}
    >
      {component}
    </SaleDetailCard>
  )
}

export default WhitelistPurchaseStartCard

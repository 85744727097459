import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const InvestLight: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.24641 4.48312C5.1353 5.40673 0.992248 8.52062 1.30891 13.5873C1.30891 14.5109 1.70473 16.5956 3.28807 17.5456C4.8714 18.4956 8.17001 18.7331 9.6214 18.7331C9.74078 18.7331 9.87354 18.7312 10.0172 18.7269V16.3581H9.22557V12.3998H10.0172V9.23312H16.152C15.3199 7.52204 13.8282 5.69393 11.2047 4.48312C10.4131 4.61506 8.51309 4.79979 7.24641 4.48312Z"
        fill="#5A5A5A"
        fillOpacity="0.7"
      />
      <path d="M11.6006 10.0248H18.7256V12.3998H11.6006V10.0248Z" fill="#5A5A5A" fillOpacity="0.7" />
      <path d="M10.8089 13.1915H17.9339V15.5665H10.8089V13.1915Z" fill="#5A5A5A" fillOpacity="0.7" />
      <path d="M11.6006 16.3581H18.7256V18.7331H11.6006V16.3581Z" fill="#5A5A5A" fillOpacity="0.7" />
      <path
        d="M6.05891 0.920432C6.05891 1.55377 6.85058 2.50377 7.24641 2.8996C8.82975 3.53297 10.545 3.16347 11.2047 2.89956C11.8645 2.10789 12.7881 1.31622 12.3922 0.524556C12.0756 -0.108777 10.8089 0.260646 10.4131 0.524524C9.46308 -0.425476 8.43391 0.128741 8.03808 0.524599C6.45475 -0.425401 6.05891 0.392654 6.05891 0.920432Z"
        fill="#5A5A5A"
        fillOpacity="0.7"
      />
    </Svg>
  )
}

export default InvestLight

const mainUrl = process.env.REACT_APP_BACKEND_URL

const SYMBOL_TO_ID_MAPPER = {
  PLY: 'aurigami',
}

const mapToIdIfPossible = (symbols: string[]) => {
  const newSymbols = []
  const ids = []
  symbols.forEach((symbol) => {
    if (SYMBOL_TO_ID_MAPPER[symbol]) {
      ids.push(SYMBOL_TO_ID_MAPPER[symbol])
    } else {
      newSymbols.push(symbol)
    }
  })

  return { symbols: newSymbols, ids }
}

async function fetchPriceCoins(rawSymbols: string[]) {
  const uniqueSymbols = [...new Set(rawSymbols)] // get unique symbols for api call optimization
  const { symbols, ids } = mapToIdIfPossible(uniqueSymbols)
  symbols.sort() // sort before API call to improve backend cache identification
  ids.sort() // sort before API call to improve backend cache identification
  const dataReturned = await fetchCoins(symbols, ids)

  const symbolCoinMapping = {}
  for (let i = 0; i < dataReturned?.data?.length; i++) {
    // symbol => price (mapping)
    symbolCoinMapping[dataReturned.data[i].symbol] = parseFloat(dataReturned.data[i].price)
  }
  return symbolCoinMapping
}

/* fetchCoins: helper function calls API with symbols */
async function fetchCoins(symbols: string[], ids: string[]): Promise<any> {
  const params: { symbols?: string; ids?: string } = {}
  if (symbols.length > 0) {
    params.symbols = symbols.join(',')
  }
  if (ids.length > 0) {
    params.ids = ids.join(',')
  }

  const url = `${mainUrl}/api/backend-service/coins/price?${new URLSearchParams(params).toString()}`
  try {
    const response = await fetch(url)
    const data = await response.json()
    return data
  } catch (error) {
    return null
  }
}

export default fetchPriceCoins

import BigNumber from 'bignumber.js'
import { Chain, Token } from 'config/constants/types'
import {
  isSubscribeableIDO,
  isClaimableIDO,
  isPurchaseableIDO,
  isDroppedIDO,
  isCliffVestableIDO,
} from 'state/idos/saleUtil'
import {
  ClaimableIDO,
  CliffVestableIDO,
  DroppedIDO,
  IDO,
  IDOSaleToken,
  Project,
  PurchasableIDO,
  SubscribeableIDO,
} from 'state/v2_types'
import { convertFromWei } from 'utils/formatBalance'

const findNextOrLastCliffPeriod = (cliffPeriod) => {
  if (!cliffPeriod) return null
  const now = new Date()
  let i = 0
  while (i < cliffPeriod.length) {
    if (now < cliffPeriod[i].claimTime) {
      return cliffPeriod[i]
    }
    i++
  }
  return cliffPeriod[i - 1]
}

export enum ManageState {
  purchase = 'Purchase',
  staking = 'Staking',
  claim = 'Airdrop / Claim',
  past = 'Past Projects',
}

export type ManageRow = {
  ido: IDO
  projectId: string
  id: string
  saleId: string
  chainId: Chain
  projectTitle: string
  projectImage: string
  projectSubtitle: string
  salePrice?: number
  staked: number
  allocation: number
  token: IDOSaleToken
  stakingToken?: Token
  startTime?: string
  isStartTimeTBD?: boolean
  endTime?: string
  isEndTimeTBD?: boolean
  isAirdrop?: boolean
  airdropNumber?: number
  isSingleSale?: boolean
  coinGeckoId?: string
  currentPrice?: string
  currentPriceState?: string
  hasClaimable?: boolean
}

export type IdleSale = {
  staked: number
  saleId: string
  masterAddress: string
  trackID: string
  chainId: Chain
  projectImage: string
  projectTitle: string
  projectSubtitle: string
}

export type CategorisedManageListAndStats = {
  categorisedManageList: CategorisedManageList
  totalStaked: BigNumber
  totalIdleStaked: BigNumber
  idleSales: IdleSale[]
}

export type CategorisedManageList = {
  readonly [state in ManageState]: ManageRow[]
}

const castToManageRow = (
  ido: IDO,
  staked = new BigNumber(0),
  allocation,
  startTime: string,
  isStartTimeTBD: boolean,
  endTime: string,
  isEndTimeTBD: boolean,
  isSingleSale: boolean,
  coinGeckoId?: string,
  currentPrice?: string,
  currentPriceState?: string,
  hasClaimable?: boolean,
): ManageRow => {
  let airdropNumber = null
  if (isDroppedIDO(ido as DroppedIDO)) {
    const { airdropInfo } = ido as DroppedIDO
    if (airdropInfo.finalAirdrop) {
      const now = new Date()
      if (now < new Date(airdropInfo.initialAirdrop)) {
        airdropNumber = 1
      } else {
        airdropNumber = 2
      }
    }
  }
  return {
    ido,
    projectId: ido.projectID,
    projectImage: ido.token.image,
    saleId: ido.id,
    id: ido.id,
    salePrice: (ido as PurchasableIDO).purchasePeriod?.salePrice || 0,
    chainId: ido.chainId,
    projectTitle: ido.title?.split(' ')[0],
    projectSubtitle: (ido as SubscribeableIDO).subscribePeriod?.isLimited ? 'IDO - Standard Pool' : 'IDO - Unlimited',
    staked: staked.toNumber(),
    allocation: allocation.toNumber(),
    token: ido.token,
    stakingToken: (ido as SubscribeableIDO).stakingToken,
    startTime,
    isStartTimeTBD,
    endTime,
    isEndTimeTBD,
    isAirdrop: isDroppedIDO(ido as DroppedIDO),
    airdropNumber,
    isSingleSale,
    coinGeckoId,
    currentPrice,
    currentPriceState,
    hasClaimable,
  }
}

export const getCategorisedManageList = (
  projects: Project[],
  useDataMap: { [id: string]: any },
  priceMap: { [id: string]: { price: string; state: string } },
  stakingTokenSymbol = 'IDIA',
): CategorisedManageListAndStats => {
  const currentDate = new Date()
  const categorisedList: CategorisedManageList = {
    [ManageState.purchase]: [],
    [ManageState.staking]: [],
    [ManageState.claim]: [],
    [ManageState.past]: [],
  }
  let totalStaked = new BigNumber(0)
  let totalIdleStaked = new BigNumber(0)

  const idleSales: IdleSale[] = []

  projects.forEach((project) => {
    const price = priceMap && priceMap[project.coinGeckoId]
    project.sales.forEach((ido) => {
      const userData = useDataMap[ido.id]
      if (!userData) return

      const isSingleSale = project.sales.length === 1

      let finalEndTime = new Date(0)

      const {
        userAllocation: userAllocationInWei,
        token: { decimals },
      } = ido

      const userAllocation = userAllocationInWei && convertFromWei(new BigNumber(userAllocationInWei), decimals)
      const userAllocationBigNum = userAllocation || userData?.userTokenAllocation || new BigNumber(0)

      // Get staking state's list
      if (isSubscribeableIDO(ido as SubscribeableIDO)) {
        const {
          subscribePeriod: { startTime, endTime },
          stakingToken,
        } = ido as SubscribeableIDO

        if (new Date(finalEndTime) < new Date(endTime)) {
          finalEndTime = new Date(endTime)
        }

        if (
          currentDate > new Date(startTime) &&
          currentDate < new Date(endTime) &&
          userAllocationBigNum.isGreaterThan(0)
        ) {
          categorisedList[ManageState.staking].push(
            castToManageRow(
              ido,
              userData?.userStakeAmount,
              userAllocationBigNum,
              startTime,
              false,
              endTime,
              false,
              isSingleSale,
            ),
          )
          if (stakingToken.symbol === stakingTokenSymbol) totalStaked = totalStaked.plus(userData?.userStakeAmount)
          return
        }
      }

      const userStakeAmount = new BigNumber(userData?.userStakeAmount)

      if ((ido as SubscribeableIDO).stakingToken?.symbol === stakingTokenSymbol && userStakeAmount.isGreaterThan(0)) {
        const { masterAddress, id, trackId, token, chainId, title, subscribePeriod } = ido as SubscribeableIDO
        totalIdleStaked = totalIdleStaked.plus(userStakeAmount)
        idleSales.push({
          staked: userData?.userStakeAmount,
          saleId: id,
          masterAddress,
          trackID: trackId,
          chainId,
          projectImage: token.image,
          projectTitle: title?.split(' ')[0],
          projectSubtitle: subscribePeriod?.isLimited ? 'IDO - Standard Pool' : 'IDO - Unlimited',
        })
      }

      // Get purchase state's list
      if (isPurchaseableIDO(ido as PurchasableIDO)) {
        const {
          purchasePeriod: { startTime, endTime },
        } = ido as PurchasableIDO
        let validStartTime = startTime
        if (isSubscribeableIDO(ido as SubscribeableIDO)) {
          validStartTime = (ido as SubscribeableIDO).subscribePeriod.endTime
        }

        if (new Date(finalEndTime) < new Date(endTime)) {
          finalEndTime = new Date(endTime)
        }

        if (
          currentDate > new Date(validStartTime) &&
          currentDate < new Date(endTime) &&
          userAllocationBigNum.isGreaterThan(0)
        ) {
          categorisedList[ManageState.purchase].push(
            castToManageRow(
              ido,
              userData?.userStakeAmount,
              userAllocationBigNum,
              startTime,
              false,
              endTime,
              false,
              isSingleSale,
            ),
          )
          return
        }
      }

      // Get claim state's list
      if (isClaimableIDO(ido as ClaimableIDO)) {
        const {
          claimPeriod: { startTime },
        } = ido as ClaimableIDO
        // let validStartTime = startTime
        let allocation = userAllocationBigNum
        if (isPurchaseableIDO(ido as PurchasableIDO)) {
          // validStartTime = (ido as PurchasableIDO).purchasePeriod.endTime
          allocation = userData?.paymentReceivedInWei
        }

        if (new Date(finalEndTime) < new Date(startTime)) {
          finalEndTime = new Date(startTime)
        }

        if (allocation?.isGreaterThan(0) && !userData?.hasWithdrawn) {
          categorisedList[ManageState.claim].push(
            castToManageRow(
              ido,
              userData?.userStakeAmount,
              userAllocationBigNum,
              new Date(startTime).toISOString(),
              false,
              null,
              false,
              isSingleSale,
            ),
          )
          return
        }
      }

      if (isDroppedIDO(ido as DroppedIDO)) {
        const {
          airdropInfo: { initialAirdrop, finalAirdrop, isInitialTBD, isFinalTBD },
        } = ido as DroppedIDO
        let allocation = userAllocationBigNum
        if (isPurchaseableIDO(ido as PurchasableIDO)) {
          allocation = userData?.paymentReceivedInWei
        }

        if (allocation?.isGreaterThan(0)) {
          if (initialAirdrop && currentDate < new Date(initialAirdrop)) {
            finalEndTime = new Date(initialAirdrop)
            categorisedList[ManageState.claim].push(
              castToManageRow(
                ido,
                userData?.userStakeAmount,
                userAllocationBigNum,
                new Date(finalEndTime).toISOString(),
                false,
                null,
                false,
                isSingleSale,
              ),
            )
            return
          }
          if (isInitialTBD || isFinalTBD) {
            categorisedList[ManageState.claim].push(
              castToManageRow(
                ido,
                userData?.userStakeAmount,
                userAllocationBigNum,
                null,
                true,
                null,
                true,
                isSingleSale,
              ),
            )
            return
          }
          if (finalAirdrop && currentDate < new Date(finalAirdrop)) {
            finalEndTime = new Date(finalAirdrop)
            categorisedList[ManageState.claim].push(
              castToManageRow(
                ido,
                userData?.userStakeAmount,
                userAllocationBigNum,
                new Date(finalEndTime).toISOString(),
                false,
                null,
                false,
                isSingleSale,
              ),
            )
            return
          }
        }
      }

      if (isCliffVestableIDO(ido as CliffVestableIDO)) {
        const { cliffVestInfo } = ido as CliffVestableIDO
        const nextCliff = findNextOrLastCliffPeriod(cliffVestInfo?.cliffPeriod)
        const startTime = nextCliff?.claimTime
        const endTime = cliffVestInfo?.cliffPeriod?.[cliffVestInfo?.cliffPeriod?.length - 1 || 0]?.claimTime

        let allocation = userAllocationBigNum
        const hasClaimableToken = new BigNumber(userData?.currentClaimableTokenInWei || 0).isGreaterThan(0)

        if (isPurchaseableIDO(ido as PurchasableIDO)) {
          allocation = userData?.paymentReceivedInWei
        }

        if (new Date(finalEndTime) < endTime) {
          finalEndTime = endTime
        }

        if ((new Date() < endTime && allocation?.isGreaterThan(0)) || hasClaimableToken) {
          categorisedList[ManageState.claim].push(
            castToManageRow(
              ido,
              userData?.userStakeAmount,
              userAllocationBigNum,
              startTime,
              false,
              null,
              false,
              isSingleSale,
              null,
              null,
              null,
              hasClaimableToken,
            ),
          )
          return
        }
      }

      // get past projects
      if (isPurchaseableIDO(ido as PurchasableIDO) && userData?.paymentReceivedInWei?.isGreaterThan(0)) {
        const purchasedToken = convertFromWei(
          userData?.paymentReceivedInWei,
          (ido as PurchasableIDO).paymentToken.decimals,
        ).div((ido as PurchasableIDO).purchasePeriod.salePrice)
        categorisedList[ManageState.past].push(
          castToManageRow(
            ido,
            userData?.userStakeAmount,
            purchasedToken,
            null,
            false,
            finalEndTime.toString(),
            false,
            isSingleSale,
            project.coinGeckoId,
            price?.price,
            price?.state,
          ),
        )
      } else if (userAllocationBigNum.isGreaterThan(0)) {
        categorisedList[ManageState.past].push(
          castToManageRow(
            ido,
            userData?.userStakeAmount,
            userAllocationBigNum,
            null,
            false,
            finalEndTime.toString(),
            false,
            isSingleSale,
            project.coinGeckoId,
            price?.price,
            price?.state,
          ),
        )
      }
    })
  })

  return { categorisedManageList: categorisedList, totalIdleStaked, totalStaked, idleSales }
}

export default getCategorisedManageList

import React from 'react'

interface StakeDropIconProps {
  size?: number
}

const StakeDropIcon: React.FC<StakeDropIconProps> = ({ size = 60 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39.8995 16.9751C37.2553 14.4117 33.7396 13 30 13C26.2604 13 22.7447 14.4117 20.1005 16.9751C17.4563 19.5384 16 22.9466 16 26.5718V26.9354L30 42L44 26.9354V26.5718C44 22.9466 42.5437 19.5384 39.8995 16.9751ZM33.9519 27.1613L30 37.7925L26.0479 27.161C28.6268 26.417 31.373 26.4174 33.9519 27.1613ZM18.9539 27.2846C20.658 27.1438 22.3703 27.19 24.0689 27.4227L27.3933 36.3658L18.9539 27.2846ZM32.6067 36.3658L35.9312 27.4226C37.6297 27.1899 39.342 27.1438 41.0461 27.2846L32.6067 36.3658Z"
        fill="url(#paint0_linear_15229_333781)"
      />
      <path
        d="M35 45C35 45.5523 34.5523 46 34 46H26C25.4477 46 25 45.5523 25 45V41C25 40.4477 25.4477 40 26 40H34C34.5523 40 35 40.4477 35 41V45Z"
        fill="#0094FF"
      />
      <defs>
        <linearGradient
          id="paint0_linear_15229_333781"
          x1="15.3822"
          y1="22.3878"
          x2="44"
          y2="22.3878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#12ECB8" />
          <stop offset="0.921875" stopColor="#00B4ED" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default StakeDropIcon

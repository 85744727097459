import { InputHTMLAttributes } from 'react'
import styled from 'styled-components'
import { PancakeTheme } from 'uikit'
import Text from '../Text/Text'

interface SliderLabelProps {
  progress: string
}

interface StyledInputProps extends InputHTMLAttributes<HTMLInputElement> {
  isMax: boolean
  theme: PancakeTheme
}

interface DisabledProp {
  disabled?: boolean
}

const getCursorStyle = ({ disabled = false }: DisabledProp) => {
  return disabled ? 'not-allowed' : 'cursor'
}

const getBaseThumbStyles = ({ disabled, theme }: StyledInputProps) => `
  -webkit-appearance: none;
  background-color: ${theme.colors?.primary};
  border: 0;
  cursor: ${getCursorStyle};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  filter: ${disabled ? 'grayscale(100%)' : 'none'};
  transform: translate(-2px, 2px);
  transition: 200ms transform;

`

export const SliderLabelContainer = styled.div`
  bottom: 0;
  position: absolute;
  left: 14px;
  width: calc(100% - 30px);
`

export const SliderLabel = styled(Text)<SliderLabelProps>`
  bottom: 0;
  font-size: 12px;
  left: ${({ progress }) => progress};
  position: absolute;
  text-align: center;
  min-width: 24px; // Slider thumb size
`

export const BunnySlider = styled.div`
  position: absolute;
  left: 14px;
  width: calc(100% - 14px);
`

export const StyledInput = styled.input<StyledInputProps>`
  cursor: ${getCursorStyle};
  height: 32px;
  position: relative;

  ::-webkit-slider-thumb {
    ${getBaseThumbStyles}
  }

  ::-moz-range-thumb {
    ${getBaseThumbStyles}
  }

  ::-ms-thumb {
    ${getBaseThumbStyles}
  }
`

export const BarBackground = styled.div<DisabledProp>`
  background-color: ${({ theme, disabled }) => theme.colors?.[disabled ? 'textDisabled' : 'inputSecondary']};
  height: 2px;
  position: absolute;
  top: 18px;
  width: 100%;
`

export const BarProgress = styled.div<DisabledProp>`
  background-color: ${({ theme }) => theme.colors?.primary};
  filter: ${({ disabled }) => (disabled ? 'grayscale(100%)' : 'none')};
  height: 3px;
  position: absolute;
  top: 18px;
`

import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 26 26" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 0.5C6.1 0.5 0.5 6.1 0.5 13C0.5 19.9 6.1 25.5 13 25.5C19.9 25.5 25.5 19.9 25.5 13C25.5 6.1 19.9 0.5 13 0.5ZM10.1464 18.8965C10.3417 19.0917 10.6583 19.0917 10.8536 18.8965L20.1477 9.60231C20.3425 9.40753 20.343 9.09192 20.1489 8.89645L19.091 7.83106C18.896 7.63462 18.5784 7.63406 18.3827 7.82981L10.8529 15.3596C10.6579 15.5546 10.3418 15.5549 10.1464 15.3603L7.61605 12.8397C7.42068 12.6451 7.10463 12.6454 6.90963 12.8404L5.85355 13.8965C5.65829 14.0917 5.65829 14.4083 5.85355 14.6036L10.1464 18.8965Z"
      />
    </Svg>
  )
}

export default Icon

import { MissedDark, MissedLight } from 'componentsV2/Icons/Staking/Missed'
import { useTranslation } from 'contexts/Localization'
import React, { useMemo } from 'react'
import { StepRequirementEnum } from 'state/types'
import styled, { useTheme } from 'styled-components'
import { OpenNewIcon } from 'uikit'
import { useAccountSidebar } from 'uikit/widgets/AccountSidebar/AccountSidebarContext'
import SaleDetailCard from '../SaleDetailCard'

interface StakingMissedCardProps {
  saleStarted?: boolean
  stepRequirement?: StepRequirementEnum[]
}

const Body = styled.ul`
  background: ${({ theme }) => theme.common.kycStatusDescBg};
  border-radius: 5px;
  padding: 10px 17.5px;
  margin-top: 20px;
  width: 100%;
  & > li {
    line-height: 16px;
    font-size: 11px;
    color: ${({ theme }) => theme.common.insctructionText};
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
`

const StakingMissedCard: React.FC<StakingMissedCardProps> = ({ saleStarted = false, stepRequirement }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { scrollToKYC } = useAccountSidebar()

  const imageInfo = useMemo(() => {
    return {
      image: theme.isDark ? <MissedDark /> : <MissedLight />,
    }
  }, [theme])
  const subtitleInfo = useMemo(() => {
    return {
      subtitle: t('The following reasons may be why you cannot view the staking page:'),
      style: {
        marginTop: '5px',
      },
    }
  }, [t])

  const title = saleStarted ? t('Oops! The sale has started.') : t('Oops! You missed the staking period.')

  const linkBtnInfo = useMemo(() => {
    const icon = <OpenNewIcon />
    return {
      text: t('Checking KYC status in your account'),
      icon,
      handler: scrollToKYC,
    }
  }, [t, scrollToKYC])

  const child = useMemo(() => {
    return (
      <Body>
        <li>{t('You did not pass KYC in time.')}</li>
        <li>{t('The staking period is already over.')}</li>
      </Body>
    )
  }, [t])

  return (
    <SaleDetailCard
      requireKYC={!!stepRequirement?.includes(StepRequirementEnum.KYC)}
      imageInfo={imageInfo}
      title={title}
      subtitleInfo={subtitleInfo}
      linkBtnInfo={linkBtnInfo}
    >
      {child}
    </SaleDetailCard>
  )
}

export default StakingMissedCard

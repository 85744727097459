import { useEffect, useState } from 'react'
import { useAccount } from 'wagmi'

const useFetchBabt = () => {
  const { address: account } = useAccount()
  const [babtDetected, setBabtDetected] = useState()

  const onFetchAddressState = async () => {
    if (account) {
      const URL = process.env.REACT_APP_BACKEND_URL
      const ENDPOINT = `${URL}/api/backend-service/kyc/babt`

      try {
        const response = await fetch(`${ENDPOINT}`, {
          credentials: 'include',
          headers: [['Cache-Control', 'no-cache']],
        })
        const responseData = await response.json()
        setBabtDetected(responseData.data?.valid)
      } catch (error) {
        console.error('Unable to fetch data:', error)
      }
    }
  }

  useEffect(() => {
    if (account) {
      onFetchAddressState()
    }
  }, [babtDetected])
  return babtDetected
}

export default useFetchBabt

import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = ({ fill = '#777777' }) => {
  return (
    <Svg width="15" height="18" viewBox="0 0 15 18">
      <path
        d="M13.1985 0H0.890174C0.399332 0 0 0.394277 0 0.878906V17.1211C0 17.6057 0.399332 18 0.890174 18H13.1985C13.6893 18 14.0887 17.6057 14.0887 17.1211V0.878906C14.0887 0.394277 13.6893 0 13.1985 0ZM3.02285 5.20288H11.0619C11.3569 5.20288 11.596 5.43899 11.596 5.73022C11.596 6.02146 11.3569 6.25757 11.0619 6.25757H3.02285C2.72789 6.25757 2.48875 6.02146 2.48875 5.73022C2.48875 5.43899 2.72785 5.20288 3.02285 5.20288ZM2.48875 3.72073C2.48875 3.42949 2.72789 3.19338 3.02285 3.19338H7.59019C7.88516 3.19338 8.1243 3.42949 8.1243 3.72073C8.1243 4.01196 7.88516 4.24807 7.59019 4.24807H3.02285C2.72785 4.24807 2.48875 4.012 2.48875 3.72073ZM3.02285 7.31239H11.0619C11.3569 7.31239 11.596 7.5485 11.596 7.83974C11.596 8.13097 11.3569 8.36708 11.0619 8.36708H3.02285C2.72789 8.36708 2.48875 8.13097 2.48875 7.83974C2.48875 7.5485 2.72785 7.31239 3.02285 7.31239ZM3.02285 9.42188H11.0619C11.3569 9.42188 11.596 9.65798 11.596 9.94922C11.596 10.2405 11.3569 10.4766 11.0619 10.4766H3.02285C2.72789 10.4766 2.48875 10.2405 2.48875 9.94922C2.48875 9.65798 2.72785 9.42188 3.02285 9.42188ZM8.52801 14.2496L8.41357 13.9129L8.14079 14.5869C8.0063 14.9192 7.69061 15.1295 7.33087 15.1295C7.32033 15.1295 7.30975 15.1294 7.29914 15.129C6.9263 15.1162 6.61164 14.8814 6.49749 14.5307L6.07572 13.2349L5.62647 14.7055C5.55843 14.9282 5.35069 15.0805 5.11512 15.0805H4.01073C3.71576 15.0805 3.47663 14.8444 3.47663 14.5532C3.47663 14.2619 3.71576 14.0258 4.01073 14.0258H4.71835L5.12605 12.6913C5.25181 12.2796 5.62896 12.0004 6.06447 11.9965C6.0675 11.9965 6.0706 11.9965 6.07362 11.9965C6.50536 11.9965 6.88308 12.268 7.01539 12.6746L7.34924 13.7004L7.50118 13.325C7.65685 12.9403 8.02745 12.6992 8.4479 12.711C8.86727 12.7227 9.22456 12.984 9.35808 13.3767L9.54074 13.9141C9.56346 13.981 9.6267 14.0259 9.69809 14.0259H9.896C10.191 14.0259 10.4301 14.262 10.4301 14.5532C10.4301 14.8445 10.191 15.0806 9.896 15.0806H9.69809C9.16712 15.0805 8.6969 14.7466 8.52801 14.2496Z"
        fill={fill}
      />
    </Svg>
  )
}

export default Icon

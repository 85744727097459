import { Quiz, QuizResult } from 'state/v2_types'

const QUESTS_STORAGE_KEY = 'QUESTS_DATA'

const fetchQuestsData = async (isUserSigned): Promise<Quiz[]> => {
  let quizzes: Quiz[] = []

  const backendURL = process.env.REACT_APP_BACKEND_URL
  const res = await fetch(`${backendURL}/api/backend-service/quizzes/list`, {
    credentials: isUserSigned ? 'include' : 'omit',
    headers: [['Cache-Control', 'no-cache']],
  })
  const data = await res.json()
  quizzes = data.data
  return quizzes
}

const fetchQuestData = async (quizID): Promise<Quiz> => {
  const backendURL = process.env.REACT_APP_BACKEND_URL
  const res = await fetch(`${backendURL}/api/backend-service/quizzes/${quizID}`, {
    credentials: 'include',
    headers: [['Cache-Control', 'no-cache']],
  })
  const data = await res.json()
  if (data.status_code !== 200) {
    return Promise.reject()
  }
  const quiz = data.data
  return quiz
}

const fetchQuestResult = async (quizID): Promise<QuizResult> => {
  const backendURL = process.env.REACT_APP_BACKEND_URL
  const res = await fetch(`${backendURL}/api/backend-service/quizzes/result/${quizID}`, {
    credentials: 'include',
    headers: [['Cache-Control', 'no-cache']],
  })
  const data = await res.json()
  if (data.status_code !== 200) {
    return Promise.reject()
  }
  const result = data.data
  return result
}

export { fetchQuestsData, fetchQuestData, fetchQuestResult, QUESTS_STORAGE_KEY }

import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 100 100" {...props}>
      <g id="Icon / need KYC" clipPath="url(#clip0_31341_253623)">
        <g id="Exclude">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M50.7551 9.20375C50.3564 8.74426 49.6432 8.74426 49.2445 9.20375L40.5444 19.2303C40.3017 19.51 39.9242 19.6327 39.5633 19.5491L26.6314 16.5512C26.0388 16.4138 25.4618 16.833 25.4093 17.4391L24.2643 30.6645C24.2323 31.0335 23.999 31.3546 23.6579 31.4991L11.4337 36.6749C10.8734 36.9121 10.6531 37.5904 10.9669 38.1116L17.8142 49.4841C18.0053 49.8015 18.0053 50.1984 17.8142 50.5158L10.9669 61.8883C10.6531 62.4095 10.8735 63.0878 11.4337 63.325L23.6579 68.5009C23.999 68.6453 24.2323 68.9664 24.2643 69.3355L25.4093 82.5608C25.4618 83.1669 26.0388 83.5861 26.6314 83.4487L39.5633 80.4509C39.9242 80.3672 40.3017 80.4899 40.5444 80.7696L49.2445 90.7962C49.6432 91.2557 50.3564 91.2557 50.7551 90.7962L59.4551 80.7696C59.6978 80.4899 60.0754 80.3672 60.4362 80.4509L73.3681 83.4487C73.9608 83.5861 74.5377 83.1669 74.5902 82.5608L75.7352 69.3355C75.7672 68.9664 76.0005 68.6453 76.3416 68.5009L88.5659 63.325C89.1261 63.0878 89.3465 62.4095 89.0327 61.8883L82.1853 50.5158C81.9943 50.1984 81.9943 49.8015 82.1853 49.4841L89.0327 38.1116C89.3465 37.5904 89.1261 36.9121 88.5658 36.6749L76.3416 31.4991C76.0005 31.3546 75.7672 31.0335 75.7352 30.6645L74.5902 17.4391C74.5377 16.833 73.9608 16.4138 73.3681 16.5512L60.4362 19.5491C60.0754 19.6327 59.6978 19.51 59.4551 19.2303L50.7551 9.20375ZM69.6327 40.5971C71.2332 38.9353 71.2332 36.241 69.6327 34.5792C68.0322 32.9174 65.4373 32.9174 63.8368 34.5792L43.0031 56.2107L36.328 48.4136C34.8242 46.657 32.2338 46.4988 30.542 48.0602C28.8503 49.6215 28.6979 52.3112 30.2017 54.0677L39.7645 65.2379C40.5144 66.1139 41.579 66.6299 42.7073 66.6643C43.8355 66.6988 44.9275 66.2485 45.7256 65.4198L69.6327 40.5971Z"
            fill="#6361C9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M50.7551 9.20375C50.3564 8.74426 49.6432 8.74426 49.2445 9.20375L40.5444 19.2303C40.3017 19.51 39.9242 19.6327 39.5633 19.5491L26.6314 16.5512C26.0388 16.4138 25.4618 16.833 25.4093 17.4391L24.2643 30.6645C24.2323 31.0335 23.999 31.3546 23.6579 31.4991L11.4337 36.6749C10.8734 36.9121 10.6531 37.5904 10.9669 38.1116L17.8142 49.4841C18.0053 49.8015 18.0053 50.1984 17.8142 50.5158L10.9669 61.8883C10.6531 62.4095 10.8735 63.0878 11.4337 63.325L23.6579 68.5009C23.999 68.6453 24.2323 68.9664 24.2643 69.3355L25.4093 82.5608C25.4618 83.1669 26.0388 83.5861 26.6314 83.4487L39.5633 80.4509C39.9242 80.3672 40.3017 80.4899 40.5444 80.7696L49.2445 90.7962C49.6432 91.2557 50.3564 91.2557 50.7551 90.7962L59.4551 80.7696C59.6978 80.4899 60.0754 80.3672 60.4362 80.4509L73.3681 83.4487C73.9608 83.5861 74.5377 83.1669 74.5902 82.5608L75.7352 69.3355C75.7672 68.9664 76.0005 68.6453 76.3416 68.5009L88.5659 63.325C89.1261 63.0878 89.3465 62.4095 89.0327 61.8883L82.1853 50.5158C81.9943 50.1984 81.9943 49.8015 82.1853 49.4841L89.0327 38.1116C89.3465 37.5904 89.1261 36.9121 88.5658 36.6749L76.3416 31.4991C76.0005 31.3546 75.7672 31.0335 75.7352 30.6645L74.5902 17.4391C74.5377 16.833 73.9608 16.4138 73.3681 16.5512L60.4362 19.5491C60.0754 19.6327 59.6978 19.51 59.4551 19.2303L50.7551 9.20375ZM69.6327 40.5971C71.2332 38.9353 71.2332 36.241 69.6327 34.5792C68.0322 32.9174 65.4373 32.9174 63.8368 34.5792L43.0031 56.2107L36.328 48.4136C34.8242 46.657 32.2338 46.4988 30.542 48.0602C28.8503 49.6215 28.6979 52.3112 30.2017 54.0677L39.7645 65.2379C40.5144 66.1139 41.579 66.6299 42.7073 66.6643C43.8355 66.6988 44.9275 66.2485 45.7256 65.4198L69.6327 40.5971Z"
            fill="url(#paint0_linear_31341_253623)"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_31341_253623"
          x1="49.9998"
          y1="8.85913"
          x2="49.9998"
          y2="129.744"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B6DCFF" stopOpacity="0.12" />
          <stop offset="1" stopColor="#66B6FF" />
        </linearGradient>
        <clipPath id="clip0_31341_253623">
          <rect width="100" height="100" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  )
}

export default Icon

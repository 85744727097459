import React from 'react'
import styled from 'styled-components'
import { Box } from '@mui/material'
import { Chain } from 'config/constants/types'

type ImageWithNetworkProps = {
  src: string
  chainId?: Chain
  alt: string
  width?: string
  height?: string
  chainWidth?: string
  chainHeight?: string
  showBorder?: boolean
  chainMarginLeft?: number
  chainMarginBottom?: number
}

const ChainIcon = styled.img<{ showBorder: boolean }>`
  border: solid ${({ showBorder }) => (showBorder ? '2px' : '0px')} ${({ theme }) => theme.colorsV2?.main};
  ${({ theme }) => theme.mediaQueries?.md} {
    border: solid ${({ showBorder }) => (showBorder ? '4px' : '0px')} ${({ theme }) => theme.colorsV2?.main};
  }
  border-radius: 50%;
  line-height: 0;
  align-item: flex-end;
`

const ImageWithNetwork: React.FC<ImageWithNetworkProps> = ({
  src,
  alt,
  width,
  height,
  chainId,
  chainWidth,
  chainHeight,
  chainMarginLeft = -5,
  chainMarginBottom = -2,
  showBorder = true,
}) => {
  return (
    <Box display="flex" flexDirection="row">
      <Box width={width} height={height}>
        <img src={src} alt={alt} width="100%" style={{ height, verticalAlign: 'bottom', borderRadius: '50%' }} />
      </Box>
      {chainId && (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          marginLeft={chainMarginLeft}
          marginBottom={chainMarginBottom}
          width={chainWidth ?? '45%'}
          height={height}
        >
          <ChainIcon
            src={`/images/icons/networks/${chainId}.svg`}
            width={chainWidth ?? '25px'}
            height={chainHeight ?? '23px'}
            alt={`${chainId}`}
            showBorder={showBorder}
            style={{
              width: chainWidth ?? '25px',
              height: chainHeight ?? '23px',
            }}
          />
        </Box>
      )}
    </Box>
  )
}

export default ImageWithNetwork

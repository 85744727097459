import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.29425 21.2548L11.1767 22.0214V21.0189L11.412 20.7831H13.059V21.9623V22.7878H11.2943L9.11778 21.8444L8.29425 21.2548Z"
        fill="#CDBDB2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7059 21.2548L14.8824 22.0214V21.0189L14.6471 20.7831H13V21.9623V22.7878H14.7647L16.9412 21.8444L17.7059 21.2548Z"
        fill="#CDBDB2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4117 19.0732L11.1765 21.0189L11.4705 20.7831H14.5294L14.8823 21.0189L14.647 19.0732L14.1764 18.7784L11.8235 18.8374L11.4117 19.0732Z"
        fill="#393939"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.82352 5.92443L11.2353 9.22635L11.8824 18.8374H14.1765L14.8824 9.22635L16.1765 5.92443H9.82352Z"
        fill="#F89C35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.64707 13.1769L2 17.9529L6.11767 17.717H8.76468V15.6534L8.64704 11.408L8.0588 11.8797L3.64707 13.1769Z"
        fill="#F89D35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.76477 13.7075L11.5883 13.8255L11.059 16.3019L8.76478 15.7123L6.76477 13.7075Z"
        fill="#D87C30"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.76477 13.7665L8.76478 15.6534V17.5402L6.76477 13.7665Z"
        fill="#EA8D3A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.76471 15.7123L11.1177 16.3019L11.8824 18.8374L11.353 19.1321L8.76471 17.5991V15.7123Z"
        fill="#F89D35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.76484 17.5991L8.29425 21.2548L11.412 19.0732L8.76484 17.5991Z"
        fill="#EB8F35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5884 13.8255L11.8825 18.8374L11.0001 16.2724L11.5884 13.8255Z"
        fill="#EA8E3A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.05862 17.6581L8.76452 17.5991L8.29393 21.2548L6.05862 17.6581Z"
        fill="#D87C30"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.29412 22.8468L8.2941 21.2548L6.05879 17.6581L2 17.9529L3.29412 22.8468Z"
        fill="#EB8F35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2354 9.22636L8.70601 11.349L6.76477 13.7075L11.5883 13.8845L11.2354 9.22636Z"
        fill="#E8821E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.29425 21.2548L11.412 19.0732L11.1767 20.96V22.0214L9.05901 21.6086L8.29425 21.2548Z"
        fill="#DFCEC3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7059 21.2548L14.6471 19.0732L14.8824 20.96V22.0214L17 21.6086L17.7059 21.2548Z"
        fill="#DFCEC3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2941 14.8868L10.9412 16.243L8.64704 15.6534L10.2941 14.8868Z"
        fill="#393939"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.23511 3.15323L11.2351 9.22635L9.88225 5.92443L3.23511 3.15323Z"
        fill="#E88F35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.23537 3.15318L2.17654 6.39613L2.76478 9.9339L2.35302 10.1698L2.94125 10.7004L2.47066 11.1132L3.11773 11.7028L2.70596 12.0566L3.64714 13.2358L8.05892 11.8797C10.2158 10.1501 11.2746 9.26566 11.2354 9.22635C11.1962 9.18704 8.52951 7.16265 3.23537 3.15318Z"
        fill="#8E5A30"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.3529 13.1769L23.9999 17.9529L19.8823 17.717H17.2353V15.6534L17.3529 11.408L17.9411 11.8797L22.3529 13.1769Z"
        fill="#F89D35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2352 13.7075L14.4116 13.8255L14.941 16.3019L17.2352 15.7123L19.2352 13.7075Z"
        fill="#D87C30"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2352 13.7665L17.2352 15.6534V17.5402L19.2352 13.7665Z"
        fill="#EA8D3A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2352 15.7123L14.8822 16.3019L14.1175 18.8374L14.647 19.1321L17.2352 17.5991V15.7123Z"
        fill="#F89D35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2353 17.5991L17.7059 21.2548L14.6471 19.1321L17.2353 17.5991Z"
        fill="#EB8F35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4116 13.8255L14.1174 18.8374L14.9998 16.2724L14.4116 13.8255Z"
        fill="#EA8E3A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9413 17.6581L17.2354 17.5991L17.706 21.2548L19.9413 17.6581Z"
        fill="#D87C30"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7058 22.8468L17.7058 21.2548L19.9411 17.6581L23.9999 17.9529L22.7058 22.8468Z"
        fill="#EB8F35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7646 9.22636L17.2939 11.349L19.2352 13.7075L14.4116 13.8845L14.7646 9.22636Z"
        fill="#E8821E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7058 14.8868L15.0587 16.243L17.3529 15.6534L15.7058 14.8868Z"
        fill="#393939"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7646 3.15323L14.7646 9.22635L16.1175 5.92443L22.7646 3.15323Z"
        fill="#E88F35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7646 3.15318L23.8234 6.39613L23.2352 9.9339L23.6469 10.1698L23.0587 10.7004L23.5293 11.1132L22.8822 11.7028L23.294 12.0566L22.3528 13.2358L17.941 11.8797C15.7841 10.1501 14.7253 9.26566 14.7645 9.22635C14.8037 9.18704 17.4704 7.16265 22.7646 3.15318Z"
        fill="#8E5A30"
      />
    </Svg>
  )
}

export default Icon

import React, { createContext, useState } from 'react'
import styled from 'styled-components'
import { PancakeTheme } from '../../theme'
import useMatchBreakpoints from '../../hooks/useMatchBreakpoints'
import Overlay from '../../components/Overlay/Overlay'
import { Handler } from './types'

export interface ModalPresentOptions {
  backdropBlurVal?: string | null
  backgroundColorVal?: string | null
  onDismissCustom?: () => void
}

interface ModalsContext {
  isOpen: boolean
  nodeId: string
  modalNode: React.ReactNode
  setModalNode: React.Dispatch<React.SetStateAction<React.ReactNode>>
  onPresent: (node: React.ReactNode, newNodeId: string, options?: ModalPresentOptions) => void
  onDismiss: Handler
  setCloseOnOverlayClick: React.Dispatch<React.SetStateAction<boolean>>
}

const cssValSetter = ({
  key,
  val,
  isMobile,
  theme,
  darkDefaultVal,
  lightDefaultVal,
}: {
  key: string
  val: string
  isMobile: boolean
  theme: PancakeTheme
  darkDefaultVal: string
  lightDefaultVal: string
}): string => {
  if (val != null && val !== '') return `${key}: ${val};`
  if (isMobile) {
    return `${key}: ${theme.isDark ? darkDefaultVal : lightDefaultVal};`
  }
  return ''
}

const ModalWrapper = styled.div<{ isMobile: boolean; backgroundColor: string | null; backdropBlur: string | null }>`
  ${({ isMobile, backgroundColor, backdropBlur, theme }) => `
    ${cssValSetter({
      key: 'background',
      val: backgroundColor,
      theme,
      isMobile,
      darkDefaultVal: '#15152e99',
      lightDefaultVal: '#6d8daa4d',
    })}
    ${cssValSetter({
      key: 'backdrop-filter',
      val: backdropBlur,
      theme,
      isMobile,
      darkDefaultVal: 'blur(10px)',
      lightDefaultVal: 'blur(20px)',
    })}
  `}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndices.modal - 1};
`

export const Context = createContext<ModalsContext>({
  isOpen: false,
  nodeId: '',
  modalNode: null,
  setModalNode: () => null,
  onPresent: () => null,
  onDismiss: () => null,
  setCloseOnOverlayClick: () => true,
})

interface Props {
  children: React.ReactNode
}

const ModalProvider: React.FC<Props> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [modalNode, setModalNode] = useState<React.ReactNode>()
  const [nodeId, setNodeId] = useState('')
  const [closeOnOverlayClick, setCloseOnOverlayClick] = useState(true)
  const [onCloseFunc, setOnCloseFunc] = useState<() => void>()

  const [backdropBlur, setBackdropBlur] = useState<string | null>(null)
  const [backgroundColor, setBackgroundColor] = useState<string | null>(null)
  const { isXl } = useMatchBreakpoints()
  const isMobile = isXl === false

  const handlePresent: ModalsContext['onPresent'] = (node, newNodeId, options) => {
    setModalNode(node)
    setIsOpen(true)
    setNodeId(newNodeId)
    setBackdropBlur(options?.backdropBlurVal ?? null)
    setBackgroundColor(options?.backgroundColorVal ?? null)
    setOnCloseFunc(() => options?.onDismissCustom)
  }

  const handleDismiss = () => {
    setModalNode(undefined)
    setIsOpen(false)
    setNodeId('')
    onCloseFunc?.()
  }

  const handleOverlayDismiss = () => {
    if (closeOnOverlayClick) {
      handleDismiss()
    }
  }

  const newProps = {
    onDismiss: handleDismiss,
  }

  return (
    <Context.Provider
      value={{
        isOpen,
        nodeId,
        modalNode,
        setModalNode,
        onPresent: handlePresent,
        onDismiss: handleDismiss,
        setCloseOnOverlayClick,
      }}
    >
      {isOpen && (
        <ModalWrapper isMobile={isMobile} backdropBlur={backdropBlur} backgroundColor={backgroundColor}>
          <Overlay show onClick={handleOverlayDismiss} />
          {React.isValidElement(modalNode) && React.cloneElement(modalNode, newProps)}
        </ModalWrapper>
      )}
      {children}
    </Context.Provider>
  )
}

export default ModalProvider

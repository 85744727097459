import React, { useContext, useMemo } from 'react'
import styled from 'styled-components'

import { Flex, OpenNewIcon } from 'uikit'
import useTheme from 'hooks/useTheme'
import { useTranslation } from 'contexts/Localization'
import { StartLight, StartDark } from 'componentsV2/Icons/Purchase/Start'
import IFTypography from 'componentsV2/IFTypography/IFTypography'
import BigNumber from 'bignumber.js'
import { Chain, Token } from 'config/constants/types'
import getMoreTokenAddress from 'utils/getMoreTokenAddress'
import { StepRequirementEnum } from 'state/types'
import SaleDetailCard from '../SaleDetailCard'
import { CompanyContext } from 'contexts/CompanyContext'

const AllocationAmount = styled.div`
  max-width: 300px;
  width: 100%;
  padding: 20px 50px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colorsV2?.light};
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 3px 0 10px 0;
  width: 100%;
`
const Logo = styled.img`
  width: 36px;
  height: 36px;
  margin-right: 8px;
`

type PurchaseStartCardProps = {
  estimatedAllocation: BigNumber
  projectPrice: BigNumber
  tokenImage: string
  endTime: string
  paymentToken: Token
  chainId: Chain
  stepRequirement?: StepRequirementEnum[]
}

const PurchaseStartCard: React.FC<PurchaseStartCardProps> = ({
  estimatedAllocation,
  projectPrice,
  tokenImage,
  endTime,
  paymentToken,
  chainId,
  stepRequirement,
}) => {
  const { t } = useTranslation()
  const { theme } = useTheme()
  const { brand, isJustSale } = useContext(CompanyContext)

  const countdownInfo = useMemo(() => {
    const endTimestamp = new Date(endTime).getTime()
    const currentTimestamp = new Date().getTime()
    const seconds = Math.trunc((endTimestamp - currentTimestamp) / 1000)
    return {
      seconds,
      description: t('Purchase starts in'),
    }
  }, [endTime, t])

  const linkBtnInfo = useMemo(() => {
    return {
      text: t('Get more %token%', { token: paymentToken.symbol }),
      icon: <OpenNewIcon />,
      href: getMoreTokenAddress(paymentToken, { chainId, showSwapInputCurrency: false }, brand),
    }
  }, [t, paymentToken, chainId])

  return (
    <SaleDetailCard
      requireKYC={!!stepRequirement?.includes(StepRequirementEnum.KYC)}
      targetNetworkID={chainId}
      countdownInfo={countdownInfo}
      imageInfo={{
        image: theme.isDark ? <StartDark /> : <StartLight />,
      }}
      linkBtnInfo={linkBtnInfo}
    >
      <>
        <AllocationAmount>
          <IFTypography variant="body2" ifcolor="textSecondary" fontWeight="regular">
            {isJustSale ? t('Max Allocation') : t('Your Allocation')}
          </IFTypography>

          <Flex justifyContent="center" alignItems="center">
            <Logo src={tokenImage} alt="logo" />
            <IFTypography variant="h2" fontWeight="regular">
              {estimatedAllocation.decimalPlaces(2).toNumber().toLocaleString()}
            </IFTypography>
          </Flex>
        </AllocationAmount>
        <IFTypography variant="caption" ifcolor="textSecondary" fontWeight="regular">
          You will need {estimatedAllocation.multipliedBy(projectPrice).decimalPlaces(2).toNumber().toLocaleString()}{' '}
          {paymentToken.symbol}
        </IFTypography>
      </>
    </SaleDetailCard>
  )
}

export default PurchaseStartCard

import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 14 13" fill="currentColor" color="currentColor" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.75 1.56559C0.75 1.15953 1.07917 0.830354 1.48523 0.830354H5.0643C5.47035 0.830354 5.79953 1.15953 5.79953 1.56559C5.79953 1.97164 5.47035 2.30082 5.0643 2.30082H2.22046V11.1449H10.9145V8.40723C10.9145 8.00117 11.2436 7.672 11.6497 7.672C12.0558 7.672 12.3849 8.00117 12.3849 8.40723V11.8801C12.3849 12.2862 12.0558 12.6154 11.6497 12.6154H1.48523C1.07917 12.6154 0.75 12.2862 0.75 11.8801V1.56559Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4799 6.0363C12.0738 6.03319 11.7472 5.7015 11.7503 5.29545L11.7659 3.26323L10.7173 2.23216L8.72119 2.24242C8.31514 2.24451 7.98427 1.91704 7.98219 1.51098C7.9801 1.10493 8.30758 0.774069 8.71363 0.771981L12.511 0.752451C12.7076 0.75144 12.8965 0.82923 13.0353 0.968443C13.1742 1.10766 13.2515 1.29669 13.25 1.49331L13.2207 5.30673C13.2176 5.71278 12.8859 6.03942 12.4799 6.0363Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7659 3.26323L8.07406 6.92998C7.78595 7.21613 7.32043 7.21454 7.03429 6.92644C6.74814 6.63834 6.74973 6.17282 7.03783 5.88667L10.7173 2.23216L11.7659 3.26323Z"
      />
    </Svg>
  )
}

export default Icon

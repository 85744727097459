import React from 'react'
import { Box } from '@mui/material'
import { AirdropInfo, DroppedIDO, IDOSaleToken, PurchasableIDO } from 'state/v2_types'
import { AirdropStartCard } from 'componentsV2/Card'
import BigNumber from 'bignumber.js'
import { isPurchaseableIDO } from 'state/idos/saleUtil'
import { useIsSaleUserDataFetched, useSaleUserData } from 'state/idos/hooks'
import LoadingCard from 'componentsV2/Card/LoadingCard/LoadingCard'
import { useAccount } from 'wagmi'
import { convertFromWei } from 'utils/formatBalance'

const Airdropping: React.FC<{
  airdropInfo: AirdropInfo
  token: IDOSaleToken
  estimatedAllocation: BigNumber
  sale: DroppedIDO
}> = ({ airdropInfo, token, estimatedAllocation, sale }) => {
  const { address: account } = useAccount()
  const isSaleUserDataFetched = useIsSaleUserDataFetched()
  const userData = useSaleUserData(sale.id)
  const paymentReceivedInWei = (userData && userData.paymentReceivedInWei) || new BigNumber(0)

  const { initialAirdrop, finalAirdrop, migratedUrl } = airdropInfo
  const now = new Date()
  const { symbol, image, address } = token
  const { chainId, saleAmount, saleId, projectID } = sale
  let finalTotalPaymentReceived = saleAmount
  let participants = 0
  let purchasableIdo = false

  if (account && !isSaleUserDataFetched) {
    return <LoadingCard />
  }

  let totalPurchased = new BigNumber(0)

  if (isPurchaseableIDO(sale as unknown as PurchasableIDO)) {
    const { purchasePeriod, paymentToken } = sale as unknown as PurchasableIDO
    const { purchaserCount = 0, salePrice } = purchasePeriod
    participants = Math.max(participants, purchaserCount)
    const { totalPaymentReceived } = purchasePeriod
    finalTotalPaymentReceived = totalPaymentReceived
    purchasableIdo = true

    totalPurchased = convertFromWei(new BigNumber(paymentReceivedInWei), paymentToken.decimals).dividedBy(salePrice)
  }

  return (
    <>
      <Box>
        <AirdropStartCard
          airdropDate={initialAirdrop && now < new Date(initialAirdrop) ? initialAirdrop : finalAirdrop || new Date(0)}
          tokenSymbol={symbol}
          tokenIcon={image}
          saleId={parseInt(saleId)}
          tokenAddress={address}
          chainId={chainId}
          projectID={projectID}
          participants={participants}
          totalSalesAmount={finalTotalPaymentReceived}
          shareAmount={estimatedAllocation}
          totalPurchased={totalPurchased}
          purchasableIdo={purchasableIdo}
          migratedUrl={migratedUrl}
          stakedAmount={new BigNumber(userData?.userStakeAmount ?? 0)}
        />
      </Box>
    </>
  )
}

export default Airdropping

import { useCallback } from 'react'
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import { NoBscProviderError } from '@binance-chain/bsc-connector'
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector'
import {
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
  WalletConnectConnector,
} from '@web3-react/walletconnect-connector'
import { ConnectorNames, connectorLocalStorageKey, useNetworkModal } from 'uikit'
import { connectorsByName } from 'utils/web3React'
import { useToast } from 'state/hooks'
import { setUnavailable, clearUnavailable } from 'state/app'

import networkModalOptions from 'config/constants/networkModalOptions'

import getProvider from 'utils/getProvider'
import { useDispatch } from 'react-redux'
import { setUserSignedState } from 'state/user/actions'

const useAuth = () => {
  const { activate, deactivate } = useWeb3React()
  const dispatch = useDispatch()
  const { toastErrorV2, toastInfoV2, clear } = useToast()
  const { onPresentUnsupportedNetworkModal } = useNetworkModal(networkModalOptions)

  const onChainChanged = useCallback(
    (chainID, initialConnect) => {
      const intChainId = parseInt(chainID)
      const networkData = networkModalOptions.find((item) => item.chainID === intChainId)
      if (networkData) {
        clear()
        dispatch(clearUnavailable())
        toastInfoV2(
          `You are connected to ${networkData.fullName ? networkData.fullName : networkData.name}`,
          initialConnect
            ? 'Please ensure that your usage and activities on this site complies with your local laws and regulations as applicable.'
            : '',
          networkData.iconSrc,
        )
      }
    },
    [dispatch, clear, toastInfoV2],
  )

  const login = useCallback(async (connectorID: ConnectorNames) => {
    const connector = connectorsByName[connectorID]
    dispatch(clearUnavailable())
    if (connector) {
      await activate(connector, async (error: Error) => {
        if (error instanceof UnsupportedChainIdError) {
          onPresentUnsupportedNetworkModal()
          dispatch(setUnavailable())
        } else {
          window.localStorage.removeItem(connectorLocalStorageKey)

          if (error instanceof NoEthereumProviderError || error instanceof NoBscProviderError) {
            toastErrorV2('Provider Error', 'No provider was found')
          } else if (
            error instanceof UserRejectedRequestErrorInjected ||
            error instanceof UserRejectedRequestErrorWalletConnect
          ) {
            if (connector instanceof WalletConnectConnector) {
              const walletConnector = connector as WalletConnectConnector
              walletConnector.walletConnectProvider = null
            }
            toastErrorV2('Authorization Error', 'Please authorize to access your account')
          } else if (error) {
            if ((error as any).code === -32002) {
              toastErrorV2('Failed Connecting Wallet', 'Please unlock/sign in to your wallet to proceed')
            } else {
              toastErrorV2(error.name, error.message)
            }
          }
        }
      })

      const provider = getProvider()
      if (provider) {
        const chainID = await provider.request({ method: 'eth_chainId' })
        onChainChanged(chainID, true)
        provider.on('chainChanged', (chainIDData) => onChainChanged(chainIDData, false))

        provider.removeAllListeners(['networkChanged'])
      }
    } else {
      toastErrorV2("Can't find connector", 'The connector config is wrong')
    }
  }, [])

  const logout = useCallback(() => {
    deactivate()
    const provider = getProvider()
    if (provider) {
      provider.removeListener('chainChanged', onChainChanged)
    }
    dispatch(setUserSignedState({ isSigned: false, expiry: 0, account: '' }))

    // This localStorage key is set by @web3-react/walletconnect-connector
    if (window.localStorage.getItem('walletconnect')) {
      connectorsByName.walletconnect.close()
      connectorsByName.walletconnect.walletConnectProvider = null
    } else if (window.localStorage.connectorId === 'blocto') {
      connectorsByName.blocto.deactivate()
      connectorsByName.blocto.blocto = null
    }
  }, [deactivate, onChainChanged])

  return { login, logout }
}

export default useAuth

import { nanoid } from '@reduxjs/toolkit'
import { TokenList } from '@uniswap/token-lists'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useNetwork, useWalletClient } from 'wagmi'

import getTokenList from 'utils/getTokenList'
import { Chain } from 'config/constants/types'
import resolveENSContentHash from 'utils/ENS/resolveENSContentHash'
import { AppDispatch } from 'state'
import { getProviderNoAccount } from 'utils/web3'

import { fetchTokenList } from 'state/lists/actions'
import { walletClientToSigner } from './useWeb3'

function useFetchListCallback(): (listUrl: string, sendDispatch?: boolean) => Promise<TokenList> {
  const dispatch = useDispatch<AppDispatch>()

  const { chain } = useNetwork()
  const chainId = chain?.id || 0
  const { data: walletClient } = useWalletClient({ chainId: chainId })

  // @ts-ignore
  const library = walletClient ? walletClientToSigner(walletClient)?.provider?.provider : getProviderNoAccount(chainId)

  const ensResolver = useCallback(
    (ensName: string) => {
      if (chainId !== Chain.BSC_MAINNET) {
        throw new Error('Could not construct mainnet ENS resolver')
      }
      return resolveENSContentHash(ensName, library)
    },
    [chainId, library],
  )

  // note: prevent dispatch if using for list search or unsupported list
  return useCallback(
    async (listUrl: string, sendDispatch = true) => {
      const requestId = nanoid()
      if (sendDispatch) {
        dispatch(fetchTokenList.pending({ requestId, url: listUrl }))
      }
      return getTokenList(listUrl, ensResolver)
        .then((tokenList) => {
          if (sendDispatch) {
            dispatch(fetchTokenList.fulfilled({ url: listUrl, tokenList, requestId }))
          }
          return tokenList
        })
        .catch((error) => {
          console.error(`Failed to get list at url ${listUrl}`, error)
          if (sendDispatch) {
            dispatch(fetchTokenList.rejected({ url: listUrl, requestId, errorMessage: error.message }))
          }
          throw error
        })
    },
    [dispatch, ensResolver],
  )
}

export default useFetchListCallback

import PageLoader from 'componentsV2/Loader/PageLoader'
import { Contract } from 'ethers'
import { usePairContract } from 'hooks/useContract'
import React, { useEffect, useState } from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import AddLiquidity from './index'

export function RedirectToAddLiquidity() {
  return <Redirect to="/liquidity/add/" />
}

const OLD_PATH_STRUCTURE = /^(0x[a-fA-F0-9]{40}|BNB)-(0x[a-fA-F0-9]{40}|BNB)$/
export function RedirectOldAddLiquidityPathStructure(props: RouteComponentProps<{ currencyIdA: string }>) {
  const {
    match: {
      params: { currencyIdA },
    },
  } = props
  const match = currencyIdA.match(OLD_PATH_STRUCTURE)
  if (match?.length) {
    return <Redirect to={`/liquidity/add/${match[1]}/${match[2]}`} />
  }

  return <AddLiquidity {...props} />
}

export function RedirectDuplicateTokenIds(props: RouteComponentProps<{ currencyIdA: string; currencyIdB: string }>) {
  const {
    match: {
      params: { currencyIdA, currencyIdB },
    },
  } = props
  if (currencyIdA.toLowerCase() === currencyIdB.toLowerCase()) {
    return <Redirect to={`/liquidity/add/${currencyIdA}`} />
  }
  return <AddLiquidity {...props} />
}

export function RedirectLpTokenId(props: RouteComponentProps<{ lpTokenId: string }>) {
  const {
    match: {
      params: { lpTokenId },
    },
  } = props

  const pairContract: Contract | null = usePairContract(lpTokenId)
  const [tokens, setTokens] = useState([])
  useEffect(() => {
    const fetchTokens = async () => {
      const token0 = await pairContract.token0.call()
      const token1 = await pairContract.token1.call()

      setTokens([token0, token1])
    }

    if (pairContract) {
      fetchTokens()
    }
  }, [pairContract])

  if (tokens[0] && tokens[1]) {
    return <Redirect to={`/liquidity/add/${tokens[0]}/${tokens[1]}`} />
  }

  return <PageLoader />
}

import { Chain } from 'config/constants/types'
import invariant from 'tiny-invariant'
import { validateAndParseAddress } from '../utils'
import { Currency } from './currency'

/**
 * Represents an ERC20 token with a unique address and some metadata.
 */
export class TokenClass extends Currency {
  public readonly chainId: Chain

  public readonly address: string

  public readonly projectLink?: string

  public constructor(
    chainId: Chain,
    address: string,
    decimals: number,
    symbol?: string,
    name?: string,
    projectLink?: string,
  ) {
    super(decimals, symbol, name)
    this.chainId = chainId
    this.address = validateAndParseAddress(address)
    this.projectLink = projectLink
  }

  /**
   * Returns true if the two tokens are equivalent, i.e. have the same chainId and address.
   * @param other other token to compare
   */
  public equals(other: TokenClass): boolean {
    // short circuit on reference equality
    if (this === other) {
      return true
    }
    return this.chainId === other.chainId && this.address === other.address
  }

  /**
   * Returns true if the address of this token sorts before the address of the other token
   * @param other other token to compare
   * @throws if the tokens have the same address
   * @throws if the tokens are on different chains
   */
  public sortsBefore(other: TokenClass): boolean {
    invariant(this.chainId === other.chainId, 'CHAIN_IDS')
    invariant(this.address !== other.address, 'ADDRESSES')
    return this.address.toLowerCase() < other.address.toLowerCase()
  }
}

/**
 * Compares two currencies for equality
 */
export function currencyEquals(currencyA: Currency, currencyB: Currency): boolean {
  if (currencyA instanceof TokenClass && currencyB instanceof TokenClass) {
    return currencyA.equals(currencyB)
  }
  if (currencyA instanceof TokenClass) {
    return false
  }
  if (currencyB instanceof TokenClass) {
    return false
  }
  return currencyA === currencyB
}

import React from 'react'

interface UnlimitedIconProps {
  size?: number
}

const UnlimitedIcon: React.FC<UnlimitedIconProps> = ({ size = 60 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39.8643 20.3701C37.5922 20.3701 35.4371 21.0596 33.4591 22.4194C32.2054 23.2812 31.0434 24.3945 29.9972 25.735C29.7434 25.4108 29.4822 25.0986 29.2155 24.8016C26.6125 21.9026 23.4725 20.3701 20.1349 20.3701C14.9817 20.3701 10.7891 24.6903 10.7891 30.0006C10.7891 35.3106 14.9817 39.6311 20.1349 39.6311C22.4072 39.6311 24.562 38.9416 26.5401 37.5818C27.7938 36.72 28.9558 35.6067 30.0022 34.2659C30.2558 34.5901 30.5173 34.9023 30.7837 35.1992C33.3867 38.0986 36.5267 39.6311 39.8643 39.6311C45.0175 39.6311 49.2101 35.3106 49.2101 30.0006C49.2101 24.6903 45.0175 20.3701 39.8643 20.3701ZM44.6458 30.0006C44.6458 32.7941 42.501 35.0667 39.8643 35.0667C38.5807 35.0667 35.3404 34.5658 32.5941 29.9956C35.3149 25.4342 38.5722 24.9344 39.8643 24.9344C42.501 24.9344 44.6458 27.2071 44.6458 30.0006ZM27.4022 30.0006C24.6558 34.5661 21.4176 35.0667 20.1349 35.0667C17.4982 35.0667 15.3534 32.7941 15.3534 30.0006C15.3534 27.2071 17.4982 24.9344 20.1349 24.9344C21.4176 24.9344 24.6558 25.4351 27.4022 30.0006Z"
        fill="#FF3D43"
      />
      <path
        d="M27.4025 30.001C26.9324 30.7825 26.4475 31.4449 25.9609 32.0063C26.4423 32.8845 27.5007 34.757 28.3798 36.0737C28.9468 35.5279 29.4885 34.9247 30.0023 34.2663C29.0104 32.8865 28.1779 31.4227 28.1225 31.2998C28.0087 31.0466 27.4025 30.001 27.4025 30.001Z"
        fill="#D72E2E"
      />
      <path
        d="M32.5971 29.9958C33.0949 29.1616 33.6105 28.4637 34.1284 27.8794C33.4771 26.903 32.3465 25.2313 31.5049 24.043C30.9808 24.5583 30.4781 25.1226 30 25.7352C30.9922 27.115 31.8825 28.6894 31.9382 28.8122C31.9496 28.8368 32.5971 29.9958 32.5971 29.9958Z"
        fill="#D72E2E"
      />
    </svg>
  )
}

export default UnlimitedIcon

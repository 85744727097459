import React, { ReactNode } from 'react'
import { Tooltip } from '@mui/material'
import styled from 'styled-components'

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} enterTouchDelay={0} />
))`
  & .MuiTooltip-tooltip {
    font-size: 12px;
    font-family: Roboto;
    background: ${({ theme }) => theme.colorsV2?.main};
    color: ${({ theme }) => theme.colorsV2?.text};
    padding: 15px;
    line-height: 18px;
    box-shadow: ${({ theme }) =>
      theme.isDark ? '0px 3px 20px rgba(1, 0, 56, 0.4)' : '0px 3px 20px rgba(97, 97, 97, 0.4) '};
  }

  & .MuiTooltip-arrow {
    color: ${({ theme }) => theme.colorsV2?.main};
  }
`

export interface IFTooltipProps {
  placement?:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start'
  title: ReactNode
  leaveDelay?: number
  leaveTouchDelay?: number
  children?: any
}

const IFTooltip: React.FC<IFTooltipProps> = ({
  title = 'title',
  placement = 'right-start',
  leaveDelay,
  leaveTouchDelay = 2000,
  children,
}) => {
  return (
    <StyledTooltip
      leaveTouchDelay={leaveTouchDelay}
      enterTouchDelay={100}
      title={title}
      placement={placement}
      leaveDelay={leaveDelay}
      arrow
    >
      {children}
    </StyledTooltip>
  )
}

export default IFTooltip

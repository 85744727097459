import React from 'react'
import BigNumber from 'bignumber.js'
import StakeDropIcon from 'componentsV2/Icons/SaleType/StakeDrop'
import StandardIcon from 'componentsV2/Icons/SaleType/Standard'
import UnlimitedIcon from 'componentsV2/Icons/SaleType/Unlimited'
import GiftBoxIcon from 'componentsV2/Icons/SaleType/GiftBox'
import WhitelistIcon from 'componentsV2/Icons/SaleType/Whitelist'
import FCFSIcon from 'componentsV2/Icons/SaleType/FCFS'

import { isDroppedIDO, isPurchaseableIDO, isSubscribeableIDO } from 'state/idos/saleUtil'
import { IDO, SubscribeableIDO, DroppedIDO, PurchasableIDO } from 'state/v2_types'

export const getSaleIcon = (sale: IDO, size?: number) => {
  if (isPurchaseableIDO(sale as PurchasableIDO)) {
    const {
      purchasePeriod: { maxTotalPurchasable },
    } = sale as PurchasableIDO
    if (maxTotalPurchasable > 0) {
      return <FCFSIcon size={size} />
    }
  }

  if (sale.isGiftBox) {
    return <GiftBoxIcon size={30} />
  }

  if (sale.isPrivate) {
    return <WhitelistIcon size={size} />
  }
  if (!isPurchaseableIDO(sale as PurchasableIDO)) {
    return <StakeDropIcon size={size} />
  }
  if (isSubscribeableIDO(sale as SubscribeableIDO)) {
    const {
      subscribePeriod: { isLimited, maxTotalStake },
    } = sale as SubscribeableIDO
    if (isLimited && maxTotalStake) {
      return <StandardIcon size={size} />
    }
    return <UnlimitedIcon size={size} />
  }
  return null
}

export const getAllocatedAmount = (sale: IDO): BigNumber | null => {
  const { saleAmount } = sale
  const now = new Date()
  if (!isSubscribeableIDO(sale as SubscribeableIDO)) return null
  if (isPurchaseableIDO(sale as PurchasableIDO)) {
    const {
      purchasePeriod: { salePrice, totalPaymentReceived },
    } = sale as PurchasableIDO
    return totalPaymentReceived && salePrice && salePrice > 0
      ? new BigNumber(totalPaymentReceived).div(salePrice)
      : new BigNumber(0)
  }
  if (isDroppedIDO(sale as DroppedIDO)) {
    const {
      airdropInfo: { finalAirdrop, initialAirdrop, firstDayRelease, isInitialTBD, isFinalTBD },
    } = sale as DroppedIDO
    if ((finalAirdrop && now >= new Date(finalAirdrop)) || isFinalTBD) {
      return new BigNumber(saleAmount)
    }
    if ((initialAirdrop && now >= new Date(initialAirdrop)) || isInitialTBD) {
      return new BigNumber(saleAmount).multipliedBy(parseFloat(firstDayRelease)).div(100)
    }
  }
  return new BigNumber(0)
}

export const formatAmount = (amount: BigNumber, token: string): string => {
  if (!amount.isFinite()) return ''
  return `${amount.decimalPlaces(2).toNumber().toLocaleString()} ${token}`
}

import React from 'react'
import { Player } from '@lottiefiles/react-lottie-player'
import styled, { useTheme } from 'styled-components'
import { Box } from 'uikit'
import dark from './dark.json'
import light from './light.json'

const CheckedBox = styled(Box)`
  div:first-child {
    height: 100%;
  }
`

const AnimatedCheckedBox: React.FC = () => {
  const theme = useTheme()

  return (
    <CheckedBox height="100px">
      <Player keepLastFrame style={{ height: '100%' }} src={JSON.stringify(theme.isDark ? dark : light)} autoplay />
    </CheckedBox>
  )
}

export default AnimatedCheckedBox

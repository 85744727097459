import React from 'react'

interface WhitelistIconProps {
  size?: number
}

const WhitelistIcon: React.FC<WhitelistIconProps> = ({ size = 60 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.4102 27.1443L25.2641 20.3945H34.7348L41.5887 27.1443L29.9994 40.5143L18.4102 27.1443Z"
        fill="#C193FF"
      />
      <path d="M30 20.3945V40.5143L41.5893 27.1443L34.7354 20.3945H30Z" fill="#8B43DA" />
      <path
        d="M46.9988 27.4259L42.8096 29.0832L39.0036 27.2325L33.8438 22.1524L34.1416 17.3854L38.4457 16.1055C38.5852 16.1757 38.7047 16.2636 38.8043 16.3867L46.8395 26.9337C46.939 27.0919 46.9988 27.2676 46.9988 27.4259Z"
        fill="#C193FF"
      />
      <path
        d="M47 27.4258C47 27.6191 46.9204 27.8124 46.7809 27.9707L30.777 45.6662C30.6773 45.7893 30.4184 46.0002 30 46.0002L28.0078 41.4275L30 37.6212L39.0048 27.2324L47 27.4258Z"
        fill="#A86DE0"
      />
      <path
        d="M26.1551 22.1524L20.9952 27.2325L17.5404 29.0899L13 27.461C13 27.2853 13.0399 27.0919 13.1594 26.9337L21.1946 16.3867C21.2942 16.2636 21.4137 16.1757 21.5532 16.1055L26.1408 17.7581L26.1551 22.1524Z"
        fill="#A86DE0"
      />
      <path
        d="M38.4484 16.1055L33.8465 22.1524H26.1566L21.5547 16.1055C21.7141 16.0351 21.8734 16 22.0328 16H37.9703C38.1298 16 38.289 16.0351 38.4484 16.1055Z"
        fill="#8B43DA"
      />
      <path
        d="M30 37.6212V46.0002C29.5816 46.0002 29.3226 45.7892 29.223 45.6662L13.2191 27.9707C13.0797 27.83 13.02 27.6542 13 27.4608L20.9952 27.2324L30 37.6212Z"
        fill="#8B43DA"
      />
      <path d="M30 16V22.1524H33.8449L38.4469 16.1055C38.2874 16.0351 38.1282 16 37.9688 16H30Z" fill="#A86DE0" />
    </svg>
  )
}

export default WhitelistIcon

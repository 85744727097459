import { Box } from '@mui/material'
import MenuClose from '@mui/icons-material/Close'
import { useTranslation } from 'contexts/Localization'
import React, { useCallback, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { defaultNetworkSelectorOptions, NetworkSelectorOption } from './type'

const MobileSelectorRoot = styled.div`
  overflow: auto;
  max-height: 90vh;
  border-radius: 20px 20px 0px 0px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.colorsV2?.main};
  color: ${({ theme }) => theme.colorsV2?.text};
  padding: 20px 20px 60px 20px;
  display: flex;
  flex-direction: column;
  z-index: ${({ theme }) => theme.zIndices.modal};
  filter: ${({ theme }) =>
    `drop-shadow(0px -4px 12px ${theme.isDark ? 'rgba(3, 0, 37, 0.38)' : 'rgba(3, 0, 37, 0.1)'})`};
`

const MobileSelectorContainer = styled.div`
  list-style-type: none;
  margin-top: 30px;
  overflow-y: scroll;
`

const MobileSelectorOptionWrapper = styled.li<{ active: boolean }>`
  border-radius: 10px;
  margin: 0 10px;
  padding: 10px;
  background: ${({ theme, active }) => (active ? theme.colorsV2?.dark2 : theme.colorsV2?.main)};
  font-weight: 400;
  color: ${({ active, theme }) => (active ? theme.colorsV2?.text : theme.colorsV2?.textSecondary)};
  display: flex;
  letter-spacing: 0.25px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  span {
    line-height: 24px;
  }

  img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
`

interface MobileNetworkSelectorProps {
  onDismiss?: () => void
  selectionHandler: (network: NetworkSelectorOption) => void
  currentNetwork: NetworkSelectorOption
  options?: NetworkSelectorOption[]
  className?: string
  title?: string
}

interface MobileSelectorOptionProps {
  clickHandler: (option: NetworkSelectorOption) => void
  option: NetworkSelectorOption
  activeID: string
}

const MobileSelectorOption: React.FC<MobileSelectorOptionProps> = ({ activeID, clickHandler, option }) => {
  const active = useMemo(() => {
    return option.id === activeID
  }, [activeID, option])
  const handler = useCallback(() => {
    clickHandler(option)
  }, [clickHandler, option])
  return (
    <MobileSelectorOptionWrapper active={active} onClick={handler} key={option.id}>
      {option.iconSrc && <img src={option.iconSrc} alt={option.name} />}
      <span>{option.fullName ?? option.name}</span>
    </MobileSelectorOptionWrapper>
  )
}

const MobileNetworkSelector: React.FC<MobileNetworkSelectorProps> = ({
  currentNetwork,
  selectionHandler,
  onDismiss,
  options = defaultNetworkSelectorOptions,
  className,
  title,
}) => {
  const { t } = useTranslation()
  const clickHandler = useCallback(
    (network: NetworkSelectorOption) => {
      onDismiss()
      selectionHandler(network)
    },
    [selectionHandler, onDismiss],
  )

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0]
    body.style.overflow = 'hidden'
    return () => {
      body.style.removeProperty('overflow')
    }
  }, [])
  return (
    <MobileSelectorRoot className={className}>
      <Box display="flex" justifyContent="space-between" width="100%" paddingRight="10px">
        <Box fontSize="18px" fontWeight="700" lineHeight="24px">
          {title ?? t('Network')}
        </Box>
        <Box onClick={onDismiss}>
          <MenuClose />
        </Box>
      </Box>
      <MobileSelectorContainer>
        {options.map((option) => (
          <MobileSelectorOption
            activeID={currentNetwork.id}
            clickHandler={clickHandler}
            option={option}
            key={option.id}
          />
        ))}
      </MobileSelectorContainer>
    </MobileSelectorRoot>
  )
}

export default MobileNetworkSelector

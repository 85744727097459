import React from 'react'
import { Text, ErrorIcon } from 'uikit'
import styled, { css } from 'styled-components'
import { AutoColumn } from 'componentsV2/layout/Column'

export const Wrapper = styled.div`
  position: relative;
  padding: 10px 19px 40px;
  ${({ theme }) => theme.mediaQueries?.sm} {
    padding: 10px 30px 40px 30px;
  }
`

export const ArrowWrapper = styled.div<{ clickable: boolean }>`
  padding: 2px;
  border: solid 1px ${({ theme }) => theme.colorsV2?.light};
  background: ${({ theme }) => (theme.isDark ? theme.colorsV2.dark : theme.colorsV2?.main)};
  border-radius: 50%;
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -13px;
  margin-bottom: -13px;
  z-index: 10;
  ${({ clickable }) =>
    clickable
      ? css`
          :hover {
            cursor: pointer;
            opacity: 0.8;
          }
        `
      : null}
`

export const ErrorText = styled(Text)<{ severity?: 0 | 1 | 2 | 3 | 4 }>`
  color: ${({ theme, severity }) =>
    severity === 3 || severity === 4
      ? theme.colorsV2?.feedbacks?.dangerText
      : severity === 2
      ? theme.colorsV2?.feedbacks?.dangerText
      : severity === 1
      ? theme.colorsV2?.text
      : theme.colorsV2?.feedbacks?.successText};
`

export const StyledBalanceMaxMini = styled.button`
  height: 22px;
  width: 22px;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  padding: 0.2rem;
  font-size: 0.875rem;
  font-weight: 400;
  margin-left: 0.4rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colorsV2?.textSecondary};
  border: solid 1px ${({ theme }) => theme.colorsV2?.light};
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;

  :hover {
    background-color: ${({ theme }) => theme.colorsV2?.dark2};
  }
`

export const TruncatedText = styled(Text).attrs({ ellipsis: true })`
  font-family: 'Acre';
`

const SwapCallbackErrorInner = styled.div`
  background-color: ${({ theme }) => `${theme.colors?.failure}33`};
  border-radius: 1rem;
  display: flex;
  align-items: center;
  font-size: 0.825rem;
  width: 100%;
  padding: 3rem 1.25rem 1rem 1rem;
  margin-top: -2rem;
  color: ${({ theme }) => theme.colors?.failure};
  z-index: -1;
  p {
    padding: 0;
    margin: 0;
    font-weight: 500;
  }
`

const SwapCallbackErrorInnerAlertTriangle = styled.div`
  background-color: ${({ theme }) => `${theme.colors?.failure}33`};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  border-radius: 12px;
  min-width: 48px;
  height: 48px;
`

export function SwapCallbackError({ error }: { error: string }) {
  return (
    <SwapCallbackErrorInner>
      <SwapCallbackErrorInnerAlertTriangle>
        <ErrorIcon width="24px" />
      </SwapCallbackErrorInnerAlertTriangle>
      <p>{error}</p>
    </SwapCallbackErrorInner>
  )
}

export const SwapShowAcceptChanges = styled(AutoColumn)`
  background-color: ${({ theme }) => `${theme.colors?.warning}33`};
  padding: 0.5rem;
  border-radius: 12px;
  margin-top: 8px;
`

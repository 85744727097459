import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const LogoLight: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="22" height="26" viewBox="0 0 22 26" fill="none" {...props}>
      <path
        d="M3.74952 7.49713C5.81014 7.49713 7.48061 5.82299 7.48061 3.75784C7.48061 1.69269 5.81014 0.0185547 3.74952 0.0185547C1.6889 0.0185547 0.0184326 1.69269 0.0184326 3.75784C0.0184326 5.82299 1.6889 7.49713 3.74952 7.49713Z"
        fill="#050A5A"
      />
      <path
        d="M20.8434 7.49726H15.6758C15.0415 7.49726 14.5192 6.97376 14.5192 6.33808V1.15918C14.5192 0.523502 15.0415 0 15.6758 0H20.8434C21.4777 0 22 0.523502 22 1.15918V6.33808C22 6.99246 21.4777 7.49726 20.8434 7.49726Z"
        fill="#00FFB9"
      />
      <path
        d="M7.48083 11.0244H7.25697H1.58571C0.708907 11.0244 0 11.7349 0 12.6136V18.5217V24.411C0 25.2898 0.708907 26.0002 1.58571 26.0002H5.89512C6.77192 26.0002 7.48083 25.2898 7.48083 24.411V20.1109C7.48083 19.2321 8.18974 18.5217 9.06654 18.5217H13.1521C14.0289 18.5217 14.7378 17.8112 14.7378 16.9325V12.6136C14.7378 11.7349 14.0289 11.0244 13.1521 11.0244H7.48083Z"
        fill="#00DAFF"
      />
    </Svg>
  )
}

export default LogoLight

import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 28 28" {...props}>
      <g clipPath="url(#clip0_4857_14901)">
        <path
          d="M24.148 6.1611C19.3535 1.28206 11.5713 1.28206 6.77684 6.1611C1.98241 11.0401 1.98241 18.9597 6.77684 23.8388C11.5713 28.7178 19.3535 28.7178 24.148 23.8388C28.9424 18.9597 28.9424 11.0401 24.148 6.1611ZM19.2871 19.9466C19.0935 20.1436 18.7797 20.1436 18.5862 19.9466L15.4624 16.7677L12.3386 19.9466C12.1451 20.1436 11.8313 20.1436 11.6377 19.9466L10.6015 18.8921C10.408 18.6951 10.408 18.3758 10.6015 18.1788L13.7253 14.9999L10.6015 11.821C10.408 11.6241 10.408 11.3047 10.6015 11.1078L11.6377 10.0533C11.8313 9.8563 12.1451 9.8563 12.3386 10.0533L15.4624 13.2322L18.5862 10.0533C18.7797 9.8563 19.0935 9.8563 19.2871 10.0533L20.3233 11.1078C20.5168 11.3047 20.5168 11.6241 20.3233 11.821L17.1995 14.9999L20.3233 18.1788C20.5168 18.3758 20.5168 18.6951 20.3233 18.8921L19.2871 19.9466Z"
          fill="#ED756E"
        />
      </g>
      <defs>
        <clipPath id="clip0_4857_14901">
          <rect width="29.4798" height="30" fill="white" transform="translate(0.722534)" />
        </clipPath>
      </defs>
    </Svg>
  )
}

export default Icon

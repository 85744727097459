import React, { ReactNode, useMemo, useEffect } from 'react'
import styled, { StyledComponent } from 'styled-components'
import { useMatchBreakpoints } from 'uikit'
import ArrowDown from '@mui/icons-material/ArrowDropDownSharp'
import ArrowUp from '@mui/icons-material/ArrowDropUpSharp'
import Loading from 'uikit/widgets/AccountSidebar/YourProjects/Loading'

// Emi import for [Improvement] Table pagination task
import { Pagination, Box } from '@mui/material'
import { useTranslation } from 'contexts/Localization'
import { Brands, CompanyContext } from 'contexts/CompanyContext'

export type TableTheme = {
  thColor: string
}

export const DefaultStyles = styled.div<{ isMobile?: boolean; brand?: string; isBeam?: boolean; isRonin?: boolean }>`
  width: 100%;
  overflow-x: auto;
  margin-left: ${({ isMobile }) => (isMobile ? '84px' : '119px')};
  width: ${({ isMobile }) => `calc(100% - ${isMobile ? '84px' : '119px'})`};

  &::-webkit-scrollbar {
    display: none;
  }
  table {
    width: 100%;
    padding: 16px 0;
    white-space: nowrap;
    border-collapse: separate;
    border-spacing: 0 10px;
    font-size: 16px;
    color: ${({ theme }) => theme.colorsV2?.text};

    th {
      font-size: ${({ isMobile }) => (isMobile ? ' 12px' : '14px')};
      font-weight: 400;
      color: ${({ theme }) => theme.table.thColor};
      text-align: left;
      padding: ${({ isMobile }) => (isMobile ? '0px 20px' : '0 20px;')};

      &:first-child {
        position: absolute;
        left: 0;
      }
    }

    .tableHeader {
      display: flex;
      align-items: center;
    }

    td {

      cursor: pointer;
      margin: 0;
      padding: ${({ isMobile }) => (isMobile ? '10px 20px' : '19px 20px;')};
      text-align: center;
      vertical-align: middle;
      height: ${({ isMobile }) => (isMobile ? '64px' : '90px')};

      border-style: ${({ isRonin, isBeam }) => (isRonin || isBeam ? 'solid' : 'none')};
      border-width: ${({ isRonin, isBeam }) => (isRonin || isBeam ? '1px 0px 1px 0px' : 'none')};
      border-color: ${({ theme }) => theme.common.borderCompletedProjects};

      :last-child {
        border-radius: 0px 20px 20px 0px;
        border-style: ${({ isRonin, isBeam }) => (isRonin || isBeam ? 'solid' : 'none')};
        border-width: ${({ isRonin, isBeam }) => (isRonin || isBeam ? '1px 1px 1px 0px' : 'none')};
        border-color: ${({ theme }) => theme.common.borderCompletedProjects};
      }
      :first-child {
        min-width: ${({ isMobile }) => (isMobile ? '84px' : '108px')};
        border-radius: 20px 0px 0px 20px;
        padding: ${({ isMobile }) => (isMobile ? '10px 0 10px 15px' : '23px 10px 23px 50px')};
        background-color: ${({ theme }) => (theme.isDark ? theme.colorsV2.mainNonTransparent : theme.colorsV2.main)};
        position: absolute;
        left: 0px;
        box-shadow: 60px 0 0 0
            ${({ theme }) => (theme.isDark ? theme.colorsV2.mainNonTransparent : theme.colorsV2.main)},
          120px 0 0 0 ${({ theme }) => (theme.isDark ? theme.colorsV2.mainNonTransparent : theme.colorsV2.main)},
          180px 0 0 0 ${({ theme }) => (theme.isDark ? theme.colorsV2.mainNonTransparent : theme.colorsV2.main)},
          240px 0 0 0 ${({ theme }) => (theme.isDark ? theme.colorsV2.mainNonTransparent : theme.colorsV2.main)};
        z-index: -1;
        border-style: ${({ isRonin, isBeam }) => (isRonin || isBeam ? 'solid' : 'none')};
        border-width: ${({ isRonin, isBeam }) => (isRonin || isBeam ? '1px 0px 1px 1px' : 'none')};
        border-color: ${({ theme }) => theme.common.borderCompletedProjects};
      }
    }
    .tableCell {
      border-radius: 20px;
      padding: 0 16px;
        background-color: ${({ theme }) => (theme.isDark ? theme.colorsV2.mainNonTransparent : theme.colorsV2?.main)};
        :hover {
          td {
            background-color: ${({ theme }) => theme.common.completedProjectsBgHover} ;
            :first-child:not(:only-child) {
              box-shadow: ${({ brand, theme }) =>
                brand !== Brands.RONIN
                  ? `60px 0 0 0 ${theme.isDark ? theme.colorsV2.light : theme.colorsV2.dark2},
            120px 0 0 0 ${theme.isDark ? theme.colorsV2.light : theme.colorsV2.dark2},
            180px 0 0 0 ${theme.isDark ? theme.colorsV2.light : theme.colorsV2.dark2},
            240px 0 0 0 ${theme.isDark ? theme.colorsV2.light : theme.colorsV2.dark2}`
                  : '0px 4px 25px 0px rgba(0, 36, 164, 0.15)'};

              z-index: -1;
            }
            border-color: ${({ isRonin, isBeam }) => (isBeam ? '#6976CD' : isRonin ? 'none' : '#1273EA')};
          }
        }
        :focus{
          td{
            border: 1px solid #4f8ae3;
            background-color: #deefff;
          }
        }
      }
    }
    .projectDetail {
      display: flex;
      justify-content: left;
      align-items: center;
    }
    .projectLogo {
      width: 44px;
      height: 44px;
    }
    .networkLogo {
      width: 26px;
      height: 26px;
      max-width: 26px;
    }
    .projectName {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      p {
        font-size: 14px;
        font-weight: 600;
        line-height: 1.5;
      }
      span {
        color: ${({ theme }) => theme.colorsV2?.textSecondary};
        font-size: 14px;
      }
    }
  }
`

const TableHeader = styled(Box)`
  cursor: pointer;
`

export const StyledPagination = styled(Pagination)`
  & .MuiButtonBase-root-MuiPaginationItem-root.Mui-selected:hover {
    font-weight: bold;
    background-color: ${({ theme }) => theme.colorsV2.light};
  }

  & .MuiButtonBase-root-MuiPaginationItem-root:hover {
    font-weight: bold;
    background-color: none;
  }

  & .MuiPaginationItem-root {
    color: ${({ theme }) => (theme.isDark ? `rgba(250, 250, 250, 0.7)` : `rgba(90, 90, 90, 0.7)`)};
  }

  & .MuiPaginationItem-root.Mui-selected {
    font-weight: bold;
    background-color: ${({ theme }) => theme.colorsV2.light};
  }

  & .MuiPaginationItem-root:hover {
    font-weight: bold;
    background-color: transparent;
  }

  & .MuiPaginationItem-root.Mui-selected:hover {
    font-weight: bold;
    background-color: ${({ theme }) => theme.colorsV2.light};
  }
`

export type IFSortableTableProps = {
  config: IFSortableTableConfig
  data: any[]
  columns: IFSortableTableColumn[]
  isLoading?: boolean
  CustomStyles?: StyledComponent<any, any>
  defaultSortField?: string
}

export type IFSortableTableColumn = {
  header: string
  accessor: string
  headerJustify?: string
  isSortable?: boolean
  cell?: (value) => ReactNode
}

export type IFSortableTableConfig = {
  rowsPerPage?: number
  onRowClicked?: (value) => void
}

export const IFSortableTable: React.FC<IFSortableTableProps> = ({
  data,
  config,
  columns,
  isLoading,
  CustomStyles = DefaultStyles,
  defaultSortField = '',
}) => {
  const [page, setPage] = React.useState(0)

  const [sortBy, setSortBy] = React.useState(defaultSortField)
  const { brand } = React.useContext(CompanyContext)

  const [isReversed, setIsReversed] = React.useState(false)
  const { rowsPerPage = 5, onRowClicked } = config

  const { isXl } = useMatchBreakpoints()
  const { t } = useTranslation()
  const isMobile = !isXl

  const sortedData = useMemo(() => {
    return data.sort((a, b) => {
      // This is only for date
      if (typeof a[sortBy] === 'object') {
        if (isReversed) {
          return a[sortBy] - b[sortBy]
        }
        return b[sortBy] - a[sortBy]
      }

      // For numbers
      if (typeof a[sortBy] === 'number') {
        if (isReversed) {
          return parseFloat(b[sortBy]) - parseFloat(a[sortBy])
        }
        return parseFloat(a[sortBy]) - parseFloat(b[sortBy])
      }

      // For strings
      if (isReversed) {
        if (a[sortBy] < b[sortBy]) {
          return 1
        }
        return -1
      }
      if (a[sortBy] < b[sortBy]) {
        return -1
      }
      return 1
    })
  }, [data, sortBy, isReversed])

  // Switch to the latest page with data if data length is shortened
  useEffect(() => {
    // A page should display at least one data
    // e.g. sortedData.length = 5, rowsPerPage = 5: page should be 0
    if (page * rowsPerPage > sortedData.length - 1) {
      // Switch to a page where there's at least one data
      setPage(Math.floor(Math.max(sortedData.length - 1, 0) / rowsPerPage))
    }
    // Not including `page` since it is not expected to switch to a page with no data
  }, [sortedData.length, rowsPerPage])

  const tableData = sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  const isBeam = brand === Brands.BEAM
  const isRonin = brand === Brands.RONIN
  return (
    <>
      <Box position="relative" width="100%">
        <CustomStyles isMobile={isMobile} brand={brand} isRonin={isRonin} isBeam={isBeam}>
          <table>
            <thead>
              <tr>
                {columns.map((column) => (
                  <th key={column.accessor}>
                    <TableHeader
                      className="tableHeader"
                      justifyContent={column.headerJustify ?? 'center'}
                      onClick={() => {
                        setSortBy(column.accessor)
                        setIsReversed(!isReversed)
                      }}
                    >
                      {t(column.header)}
                      {column.isSortable && (
                        <Box
                          style={{
                            visibility: sortBy === column.accessor ? 'visible' : 'hidden',
                          }}
                        >
                          {isReversed ? <ArrowDown /> : <ArrowUp />}
                        </Box>
                      )}
                    </TableHeader>
                  </th>
                ))}
              </tr>
            </thead>
            {!isLoading && (
              <tbody>
                {tableData.map((rowData) => {
                  return (
                    <tr
                      className="tableCell"
                      key={rowData.id}
                      onClick={() => {
                        onRowClicked(rowData)
                      }}
                    >
                      {columns.map((column) => (
                        <td tabIndex={0} key={`${rowData.id}-${column.accessor}`}>
                          {column.cell ? column.cell(rowData) : rowData[column.accessor]}
                        </td>
                      ))}
                    </tr>
                  )
                })}
              </tbody>
            )}
          </table>
        </CustomStyles>
        {isLoading && (
          <Box marginBottom="16px">
            <Loading height="90px" padding="20px 35px 20px" margin="0px" firstWidth="15%" secondWidth="88%" />
          </Box>
        )}
      </Box>
      {data.length > rowsPerPage && (
        <>
          <StyledPagination
            size="large"
            style={{ marginTop: '1rem', justifyContent: 'center', display: 'flex' }}
            siblingCount={1}
            onChange={(e, val) => setPage(val - 1)}
            count={Math.ceil(data.length / rowsPerPage)}
          />
        </>
      )}
    </>
  )
}

export default IFSortableTable

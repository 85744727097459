import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const PromoIcon: React.FC<SvgProps> = ({ fillColor }) => {
  return (
    <Svg width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4529 4.50469L12.7534 3.15071C12.5277 2.69939 12.1892 2.31576 11.8056 1.99984L12.3698 1.00692C12.5052 0.781259 12.4149 0.510464 12.2118 0.397633C11.9861 0.262235 11.7153 0.3525 11.6025 0.555596L11.0384 1.54851C10.587 1.36798 10.0906 1.27772 9.57155 1.30028L8.03704 1.36798C7.40519 1.39055 6.81847 1.61621 6.32201 1.99984V1.97727L5.75785 1.99984C4.62954 2.04497 3.59149 2.67682 3.02734 3.66974L0.725581 7.7091C-0.0868036 9.1082 0.409654 10.8909 1.78619 11.7033L4.51671 13.283C4.96804 13.5538 5.46449 13.6666 5.98352 13.6666C6.63794 13.6666 7.29236 13.4409 7.83395 13.0122C8.05961 13.0799 8.30784 13.125 8.55607 13.125C8.78173 13.125 9.00739 13.1024 9.23306 13.0347C9.91004 12.8542 10.4742 12.4254 10.8353 11.8162L13.3852 7.3706C13.9043 6.49052 13.9268 5.40734 13.4529 4.50469ZM4.9906 12.4931L2.26009 10.9135C1.28974 10.3493 0.97381 9.1082 1.5154 8.16042L3.83972 4.14363C4.20078 3.51177 4.83264 3.08302 5.55476 2.97018L3.02734 7.3029C2.66628 7.91219 2.57601 8.61174 2.75654 9.28873C2.93707 9.96572 3.36583 10.5299 3.97512 10.8909L6.8636 12.5608C6.29944 12.8542 5.57732 12.8316 4.9906 12.4931ZM10.7357 6.62033L7.86563 5.07847C7.57572 4.91327 7.22783 5.0234 7.08287 5.2712C6.90893 5.54653 7.02489 5.87693 7.28581 6.0146L10.1269 7.58399C10.2139 7.63905 10.3298 7.66659 10.4168 7.66659C10.6198 7.66659 10.8227 7.55645 10.9097 7.39125C11.0836 7.11592 10.9966 6.78552 10.7357 6.62033ZM6.56199 7.75068L8.03129 8.57599C8.33114 8.72337 8.4211 9.07708 8.24118 9.37183C8.15123 9.54868 7.94133 9.66658 7.73143 9.66658C7.64147 9.66658 7.52153 9.63711 7.43157 9.57816L5.96228 8.75285C5.69241 8.60547 5.57246 8.25176 5.75238 7.95701C5.90231 7.69173 6.26213 7.57382 6.56199 7.75068Z"
        fill={fillColor}
      />
    </Svg>
  )
}

export default PromoIcon

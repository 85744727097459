import { useCallback, useState } from 'react'
import { useToast } from 'state/hooks'
import { SubmitKYCBody, SubmitKYCSourceBody } from 'state/v2_types'
import { useKycStatus } from './useKycStatus'
import { useAccount } from 'wagmi'

const useSubmitKYC = () => {
  const { address: account } = useAccount()
  const { refreshTrigger } = useKycStatus()
  const { toastErrorV2, toastSuccessV2 } = useToast()
  const [success, setSuccess] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const URL = process.env.REACT_APP_BACKEND_URL
  const submitKYC = useCallback(
    (body: SubmitKYCBody, noToast?: boolean, onSucceed?, onError?) => {
      setIsLoading(true)
      setSuccess(false)
      fetch(`${URL}/api/backend-service/compliance/submit`, {
        credentials: 'include',
        // Try to remove explicit header
        method: 'POST',
        body: JSON.stringify(body),
      })
        .then((res) => res.json())
        .then((res) => {
          setIsLoading(false)
          if (res.status_code !== 200) {
            throw res.error
          } else {
            if (!noToast) {
              toastSuccessV2('Success', `Submitted KYC successfully`)
            }
            setSuccess(true)
            refreshTrigger()
          }
        })
        .then(() => {
          if (onSucceed) {
            onSucceed()
          }
        })
        .catch((error) => {
          setIsLoading(false)
          if (onError) {
            onError()
          }
          if (!noToast) {
            toastErrorV2('Error', `Something is wrong: ${error}`)
          }
        })
    },
    [account],
  )

  const submitKYCSource = useCallback(
    (body: SubmitKYCSourceBody, noToast?: boolean, onSucceed?, onError?) => {
      setIsLoading(true)
      setSuccess(false)
      fetch(`${URL}/api/backend-service/kyc/submit`, {
        credentials: 'include',
        // Try to remove explicit header
        method: 'POST',
        body: JSON.stringify(body),
      })
        .then((res) => res.json())
        .then((res) => {
          setIsLoading(false)
          if (res.status_code !== 200) {
            throw res.error
          } else {
            setSuccess(true)
            refreshTrigger()
          }
        })
        .then(() => {
          if (onSucceed) {
            onSucceed()
          }
        })
        .catch((error) => {
          setIsLoading(false)
          if (onError) {
            onError()
          }
        })
    },
    [account],
  )

  return { submitKYC, submitKYCSource, isLoading, success }
}

export default useSubmitKYC

import { useEffect, useState } from 'react'
import { FARM_STORAGE_KEY } from 'state/farms/fetchConfigs'
import { BANNER_STORAGE_KEY, IDOS_STORAGE_KEY, PROJECT_STORAGE_KEY } from 'state/idos/fetchConfigs'
import { STAGING_IDO_STORAGE_KEY } from 'utils/getCMSApiUrl'

const useStagingIDO = () => {
  const [isStagingIDO, setIsStagingIDO] = useState(() => {
    const cachedIsStagingIDO = localStorage.getItem(STAGING_IDO_STORAGE_KEY)
    return cachedIsStagingIDO ? JSON.parse(cachedIsStagingIDO) : false
  })

  const toggleStagingIDO = () => {
    setIsStagingIDO((prevState) => {
      localStorage.setItem(STAGING_IDO_STORAGE_KEY, JSON.stringify(!prevState))
      localStorage.removeItem(IDOS_STORAGE_KEY)
      while (Object.keys(localStorage).find((val) => val.includes(PROJECT_STORAGE_KEY))) {
        localStorage.removeItem(Object.keys(localStorage).find((val) => val.includes(PROJECT_STORAGE_KEY)))
      }
      localStorage.removeItem(FARM_STORAGE_KEY)
      localStorage.removeItem(BANNER_STORAGE_KEY)
      return !prevState
    })
  }

  useEffect(() => {
    return () => {
      // Hotfix for page keep reloading
      // window.location.reload()
    }
  }, [isStagingIDO])

  return { isStagingIDO, toggleStagingIDO }
}

export default useStagingIDO

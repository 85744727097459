import { Box } from '@mui/material'
import BigNumber from 'bignumber.js'
import { ClosedDark, ClosedLight } from 'componentsV2/Icons/Sale/Closed'
import IFTypography from 'componentsV2/IFTypography/IFTypography'
import { Chain } from 'config/constants/types'
import { useTranslation } from 'contexts/Localization'
import React, { useCallback, useContext, useMemo } from 'react'
import styled, { useTheme } from 'styled-components'
import BuybackSection from 'componentsV2/BuybackSection/BuybackSectionEclip'
import { Button, Flex } from 'uikit'
import { WalletWhiteIcon } from 'uikit/widgets/Menu/icons'
import { registerToken } from 'utils/wallet'
import SaleDetailCard from '../SaleDetailCard'
import { Link, useHistory } from 'react-router-dom'
import { CompanyContext } from 'contexts/CompanyContext'

const SVGWrapper = styled.div`
  path {
    fill: ${({ theme }) => theme.colorsV2?.textLink};
  }
`

const ShareAmountWrapper = styled.div`
  margin: 20px 30px 0;
  padding: 20px;
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.colorsV2?.light}`};
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  width: 100%;
`

interface AirdropStartCardPops {
  airdropDate: string | Date
  tokenSymbol: string
  tokenIcon: string
  tokenAddress: string
  tokenDecimals?: number
  chainId?: Chain
  totalSalesAmount?: number
  shareAmount: number | BigNumber
  participants?: number
  purchasableIdo?: boolean
  migratedUrl?: string
  stakedAmount?: BigNumber
  totalPurchased?: BigNumber
  saleId?: number
  projectID?: string
}

const AirdropStartCard: React.FC<AirdropStartCardPops> = ({
  airdropDate,
  tokenIcon,
  participants = 0,
  totalSalesAmount = 0,
  tokenSymbol,
  tokenAddress,
  tokenDecimals = 18,
  shareAmount,
  chainId,
  purchasableIdo,
  migratedUrl,
  stakedAmount,
  saleId,
  projectID,
  totalPurchased,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const history = useHistory()
  const { showAddToWallet } = useContext(CompanyContext)

  const countdownInfo = useMemo(() => {
    if (tokenSymbol === 'dAethirNode') return null
    const airdropTimestamp = new Date(airdropDate).getTime()
    const currentTimestamp = new Date().getTime()
    const seconds = Math.trunc((airdropTimestamp - currentTimestamp) / 1000)

    if (seconds <= 0) {
      return null
    }
    return {
      seconds,
      // HOTFIX FOR ARKEN
      description: tokenSymbol === 'ARKEN' ? "Claim in Arken's site starts in" : t('Airdrop starts in'),
    }
  }, [t, airdropDate])

  const imageInfo = useMemo(() => {
    return {
      image: theme.isDark ? <ClosedDark /> : <ClosedLight />,
    }
  }, [theme])

  const intShareAmount = parseInt(shareAmount.toString())

  const title =
    intShareAmount > 0
      ? migratedUrl && !stakedAmount.isGreaterThan(0)
        ? t('All clear!')
        : t(`Thank you for your participation.`)
      : t(`The ${purchasableIdo ? 'sale' : 'pool'} has ended.`)

  const addToWalletHandler = useCallback(() => {
    registerToken(tokenAddress, tokenSymbol, tokenDecimals)
  }, [tokenAddress, tokenSymbol, tokenDecimals])

  const child = useMemo(() => {
    if (intShareAmount) {
      if (migratedUrl) return null
      const amount =
        tokenSymbol === 'ARKEN' && chainId === 42161
          ? new BigNumber(shareAmount).times(new BigNumber(10).pow(12)).decimalPlaces(2).toNumber().toLocaleString()
          : new BigNumber(shareAmount).decimalPlaces(2).toNumber().toLocaleString()
      return (
        <ShareAmountWrapper>
          <IFTypography ifcolor="textSecondary" variant="button" fontWeight="regular">
            {t('Your Total Share')}
          </IFTypography>
          <Box display="flex" alignItems="center">
            <Box marginRight="10px">
              <img src={tokenIcon} alt="token_icon" width="36px" />
            </Box>
            <IFTypography ifcolor="text" variant="h2" fontWeight="regular">
              {amount}
            </IFTypography>
          </Box>
        </ShareAmountWrapper>
      )
    }
    return (
      <Box display="flex" justifyContent="center" width="100%">
        <Box marginTop="20px" display="flex" flexDirection="column" width="calc(100% - 30px)" maxWidth="300px">
          <Box display="flex" justifyContent="space-between">
            <IFTypography ifcolor="text" variant="button" fontFamily="Roboto" fontWeight="regular">
              {t('Participants')}
            </IFTypography>
            <IFTypography ifcolor="text" variant="button" fontFamily="Roboto" fontWeight="bold">
              {participants.toLocaleString()}
            </IFTypography>
          </Box>
          {/* <Box display="flex" justifyContent="space-between">
            <IFTypography ifcolor="text" variant="button" fontFamily="Roboto" fontWeight="regular">
              {t('Total Sales')}
            </IFTypography>
            <IFTypography ifcolor="text" variant="button" fontFamily="Roboto" fontWeight="bold">
              {totalSalesAmount}
            </IFTypography>
          </Box> */}
        </Box>
      </Box>
    )
  }, [tokenIcon, shareAmount, totalSalesAmount, participants, t])

  const linkBtnInfo = useMemo(() => {
    if (!showAddToWallet) {
      return null
    }
    return {
      text: t('Add %token% to your wallet', { token: tokenSymbol || '' }),
      icon: (
        <SVGWrapper>
          <WalletWhiteIcon />
        </SVGWrapper>
      ),
      handler: addToWalletHandler,
    }
  }, [tokenSymbol, t, addToWalletHandler])

  const isAPRSale = tokenSymbol === 'APRS'

  const footer = useMemo(() => {
    if (isAPRSale) {
      return (
        <Box padding={4} width="100%">
          <a href="https://marketplace.apeironnft.com/user/reward/vesting/">
            <Box display="flex" justifyContent="center">
              <Button width="100%">Claim in Apeiron</Button>
            </Box>
          </a>
        </Box>
      )
    }
    return (
      <>
        <BuybackSection saleId={saleId} totalPurchased={totalPurchased} />
        <Box padding={4} width="100%" display="flex" justifyContent="center">
          <Button width="100%" onClick={() => history.push(`/launchpad/project/${projectID}`)}>
            Claim
          </Button>
        </Box>
      </>
    )
  }, [totalPurchased, isAPRSale])

  const newPoolBtn = {
    button: (
      <Flex mt="30px">
        <a href={migratedUrl} target="_blank" rel="noreferrer">
          <Button minWidth="200px">{t('Stake in New Pool')}</Button>
        </a>
      </Flex>
    ),
  }

  return (
    <SaleDetailCard
      countdownInfo={countdownInfo}
      requireKYC={false}
      imageInfo={imageInfo}
      targetNetworkID={chainId}
      title={title}
      subtitleInfo={{
        subtitle:
          migratedUrl && intShareAmount > 0
            ? stakedAmount?.isGreaterThan(0)
              ? "We've moved this pool to Rewards with a new contract. Please unstake your LP tokens and stake to the new pool to keep earning."
              : 'You’ve unstaked all your LP tokens in this pool. Now you can stake in the new pool and keep earning rewards.'
            : intShareAmount > 0
            ? 'Your tokens will be airdropped to your wallet address at the specified airdrop time. Check Sale details for more info'
            : undefined,
      }}
      linkBtnInfo={
        migratedUrl && intShareAmount > 0 && !stakedAmount?.isGreaterThan(0)
          ? newPoolBtn
          : tokenAddress?.length > 0
          ? linkBtnInfo
          : undefined
      }
      checkWalletConnection={false}
      footer={(tokenSymbol === 'ECLIP' || tokenSymbol === 'KYCT' || isAPRSale) && footer}
    >
      {child}
    </SaleDetailCard>
  )
}

export default AirdropStartCard

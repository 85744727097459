import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M1.49593 6.085C1.37622 5.86297 1.32077 5.61198 1.33578 5.36018C1.35079 5.10839 1.43568 4.86576 1.58092 4.65953C1.81827 4.31129 2.13613 4.02544 2.50753 3.82625L6.67449 1.6575C7.08405 1.44453 7.53889 1.33334 8.00051 1.33334C8.46214 1.33334 8.91698 1.44453 9.32654 1.6575L13.4929 3.82792C13.8643 4.02711 14.1822 4.31295 14.4196 4.6612C14.5648 4.86743 14.6497 5.11006 14.6647 5.36185C14.6797 5.61364 14.6242 5.86464 14.5045 6.08666C14.3757 6.33044 14.1824 6.53419 13.9457 6.67573C13.7091 6.81727 13.4382 6.89117 13.1624 6.88939H2.83862C2.56261 6.89112 2.29143 6.81701 2.05466 6.67516C1.81789 6.53332 1.62462 6.32917 1.49593 6.085V6.085ZM14.1112 13.5556C14.1112 13.261 13.9942 12.9784 13.7858 12.77C13.5775 12.5617 13.2949 12.4446 13.0002 12.4446V8.00043H11.8892V12.4446H10.2226V8.00043H9.11156V12.4446H6.88947V8.00043H5.77843V12.4446H4.11187V8.00043H3.00083V12.4446C2.70616 12.4446 2.42357 12.5617 2.21521 12.77C2.00685 12.9784 1.88979 13.261 1.88979 13.5556C1.74246 13.5556 1.60116 13.6142 1.49698 13.7183C1.3928 13.8225 1.33427 13.9638 1.33427 14.1112C1.33427 14.2585 1.3928 14.3998 1.49698 14.504C1.60116 14.6081 1.74246 14.6667 1.88979 14.6667H14.1112C14.2586 14.6667 14.3999 14.6081 14.5041 14.504C14.6082 14.3998 14.6668 14.2585 14.6668 14.1112C14.6668 13.9638 14.6082 13.8225 14.5041 13.7183C14.3999 13.6142 14.2586 13.5556 14.1112 13.5556Z" />
    </Svg>
  )
}

export default Icon

import styled, { DefaultTheme } from 'styled-components'
import { space, layout, variant } from 'styled-system'
import { scaleVariants, styleVariants } from './theme'
import { BaseButtonProps } from './types'

interface ThemedButtonProps extends BaseButtonProps {
  theme: DefaultTheme
}

const getDisabledStyles = ({ isLoading, theme }: ThemedButtonProps) => {
  if (isLoading === true) {
    return `
      &:disabled,
      &.pancake-button--loading {
        cursor: not-allowed;
      }
    `
  }

  return `
    &:disabled,
    &.pancake-button--disabled {
      background: ${theme.colorsV2?.button?.disabledBg};
      border-color: ${theme.colors?.backgroundDisabled};
      box-shadow: none;
      color: ${theme.colorsV2?.button?.disabledText};
      cursor: not-allowed;
    }
  `
}

/**
 * This is to get around an issue where if you use a Link component
 * React will throw a invalid DOM attribute error
 * @see https://github.com/styled-components/styled-components/issues/135
 */
interface TransientButtonProps extends ThemedButtonProps {
  isLoading?: boolean
}

const getOpacity = ({ isLoading = false }: TransientButtonProps) => {
  return isLoading ? '.5' : '1'
}

const StyledButton = styled.button<BaseButtonProps>`
  align-items: center;
  border: 0;
  width: 100%;
  max-width: 320px;
  cursor: pointer;
  display: inline-flex;
  font-family: ${({ theme }) => (theme.brand === 'if' ? 'Acre' : 'Roboto')};
  font-size: 16px;
  font-weight: ${({ fontWeight }) => fontWeight || '700'};
  justify-content: center;
  letter-spacing: 0;
  line-height: 1;
  opacity: ${getOpacity};
  outline: 0;
  transition: background-color 0.2s;
  position: relative;
  &:hover:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled):not(:active) {
    opacity: 0.65;
  }

  &:active:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled) {
    opacity: 0.85;
  }
  ${({ fullWidth }) => fullWidth && 'width: 100%;'}
  text-decoration: none;
  ${getDisabledStyles}
  ${variant({
    prop: 'scale',
    variants: scaleVariants,
  })}
  ${({ theme }) =>
    variant({
      variants: styleVariants(theme),
    })}
  ${layout}
  ${space}
`

export default StyledButton

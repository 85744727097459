import { useAccount, useNetwork } from 'wagmi'
import ConnectWalletButton from 'componentsV2/ConnectWalletButton/ConnectWalletButton'
import { LockDark, LockLight } from 'componentsV2/Icons/Sale/Lock'
import WarningSquareIcon from 'componentsV2/Icons/WarningSquare'
import networkModalOptions from 'config/constants/networkModalOptions'
import { useTranslation } from 'contexts/Localization'
import { useKycStatus } from 'hooks/useKycStatus'
import React, { useContext, useMemo } from 'react'
import { useAppStateUnavailableNetwork } from 'state/hooks'
import { ComplianceStatus, KYCStatus } from 'state/types'
import styled, { useTheme } from 'styled-components'
import { Box, OpenNewIcon, useIsDrawerOpen, useMatchBreakpoints } from 'uikit'
import { setupNetwork } from 'utils/wallet'
import { QUICK_ACTION_DRAWER_TYPE } from 'views/Manage/Manage'
import ComplianceCard from '../ComplianceCard'
import RestrictedCountriesCard from './RestrictedCountriesCard'
import SaleKYCStatusCard from './SaleKYCStatusCard'
import StyledCard from './StyledCard'
import { SaleDetailCardProps } from './type'
import { CompanyContext, Brands } from 'contexts/CompanyContext'
import TemporarilySuspended from './TemporarilySuspended'
import { alpha } from '@mui/material'

const StyledCardModified = styled(StyledCard)<{ isNodeSale: boolean; isMobile: boolean }>`
  width: ${({ isNodeSale, isMobile }) => (isNodeSale && !isMobile ? '475px' : '360px')};
  max-width: ${({ isNodeSale }) => isNodeSale && '475px'};
  min-width: ${({ isNodeSale }) => isNodeSale && 'auto'};
  border: ${({ isNodeSale, theme }) => isNodeSale && `1px solid ${alpha(theme.colorsV2.main5, 0.5)}`};
`
const SaleDetailCard: React.FC<SaleDetailCardProps> = ({
  targetNetworkID,
  checkWalletConnection = true,
  requireKYC = true,
  restrictedCountries = [],
  countdownInfo = null,
  purchasePeriod,
  purchasedAllocation,
  ...rest
}) => {
  const { address: account } = useAccount()
  const isPurchaseHalted = purchasePeriod?.isPurchaseHalted
  const endTime = purchasePeriod?.endTime
  const endSaleDate = new Date(endTime).getTime()
  const now = new Date().getTime()
  const isSaleNotEnd = now < endSaleDate
  const { faqLink, isJustSale, brand, isNodeSale } = useContext(CompanyContext)
  const { chain } = useNetwork()
  const chainId = chain?.id
  const { t } = useTranslation()
  const theme = useTheme()
  const { status: kycStatus, complianceCountry: country, complianceStatus } = useKycStatus()
  const isNetworUnavailable = useAppStateUnavailableNetwork()
  const [isOpen, drawerType] = useIsDrawerOpen()
  const { isXl } = useMatchBreakpoints()
  const isMobile = !isXl
  const isSidebar = isOpen && drawerType === QUICK_ACTION_DRAWER_TYPE

  const connectWalletComp = useMemo(() => {
    if (!checkWalletConnection || account) return null
    return (
      <StyledCardModified
        isMobile={isMobile}
        isNodeSale={isNodeSale}
        countdownInfo={
          countdownInfo &&
          !isNodeSale && {
            ...countdownInfo,
            style: {
              fontSize: !isMobile && !isSidebar ? 24 : 18,
            },
          }
        }
        imageInfo={{
          image: theme.isDark ? <LockDark /> : <LockLight />,
        }}
        title={t('Connect your wallet to participate.')}
        ctaInfo={{
          button: (
            <ConnectWalletButton
              width="100%"
              sx={{ width: '320px', justifyContent: 'center', fontSize: '18px', marginRight: 0 }}
              style={{ marginTop: '12px' }}
            />
          ),
        }}
        linkBtnInfo={{
          text: t('How to participate?'),
          icon: <OpenNewIcon />,
          href: faqLink,
        }}
      />
    )
  }, [checkWalletConnection, account, t, theme, countdownInfo, isSidebar])

  const wrongNetworkComp = useMemo(() => {
    if (isNetworUnavailable || (targetNetworkID != null && chainId != null && targetNetworkID !== chainId)) {
      const target = networkModalOptions.filter((network) => network.chainID === targetNetworkID)
      if (target.length === 0) {
        return null
      }
      const targetNetwork = target[0]
      const ctaHandler = () => setupNetwork(targetNetwork.chainID)
      return (
        <StyledCardModified
          isNodeSale={isNodeSale}
          isMobile={isMobile}
          countdownInfo={
            countdownInfo &&
            !isNodeSale && {
              ...countdownInfo,
              style: {
                fontSize: !isMobile && !isSidebar ? 24 : 18,
              },
            }
          }
          imageInfo={{ image: <WarningSquareIcon /> }}
          title={t('Network Unavailable')}
          subtitleInfo={{
            style: { paddingBottom: '20px' },
            subtitle: t(
              process.env.REACT_APP_WHITELABEL_BRAND === 'ronin'
                ? 'Please connect to %chainName% throught your wallet'
                : 'Please connect to one of our supported networks.',
              { chainName: targetNetwork.name },
            ),
          }}
          ctaInfo={
            process.env.REACT_APP_WHITELABEL_BRAND === 'ronin'
              ? {
                  btnProps: { display: 'none' },
                }
              : {
                  text: t('Switch to %chainName%', { chainName: targetNetwork.name }),
                  handler: ctaHandler,
                  btnProps: { width: '100%' },
                }
          }
          hidePostSaleInfo
        />
      )
    }
    return null
  }, [targetNetworkID, chainId, t, countdownInfo, isNetworUnavailable, isSidebar])

  if (wrongNetworkComp) {
    return wrongNetworkComp
  }

  if (connectWalletComp) {
    return connectWalletComp
  }

  if (requireKYC && kycStatus !== KYCStatus.VERIFIED) {
    return <SaleKYCStatusCard countdownInfo={countdownInfo} kycStatus={kycStatus} />
  }

  if (restrictedCountries.includes(country)) {
    return <RestrictedCountriesCard />
  }
  if (isPurchaseHalted && isSaleNotEnd) {
    return <TemporarilySuspended purchasedAllocation={purchasedAllocation} />
  }

  return (
    <Box position="relative">
      {/* KYC is verified at this point */}
      {/* TODO: remove REACT_APP_ENABLE_RECOMPLIANCE_BABT check after testing */}
      {requireKYC && complianceStatus !== ComplianceStatus.VALID && process.env.REACT_APP_ENABLE_RECOMPLIANCE_BABT && (
        <ComplianceCard type="overlay" recompliance />
      )}
      <StyledCardModified
        isNodeSale={isNodeSale}
        isMobile={isMobile}
        style={{
          padding: isSidebar ? '35px 15px 60px' : undefined,
          maxWidth: isSidebar && isMobile ? '350px' : undefined,
          margin: isSidebar && isMobile ? '0 auto' : undefined,
        }}
        countdownInfo={
          countdownInfo &&
          !isNodeSale && {
            ...countdownInfo,
            style: {
              fontSize: !isMobile && !isSidebar ? 24 : 18,
            },
          }
        }
        {...rest}
      />
    </Box>
  )
}

export default SaleDetailCard

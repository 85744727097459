import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="20" height="20" viewBox="0 0 20 20" {...props}>
      <path
        d="M4.18703 15.812C3.87215 16.1273 3.33325 15.9043 3.33325 15.4587V11.9204C3.33325 11.3353 3.83328 10.8752 4.4163 10.9238L7.38781 11.1715C7.78866 11.2049 7.98799 11.6738 7.73386 11.9856L6.48575 13.5169C7.41072 14.4627 8.67697 14.9972 9.99992 15.0002C12.1161 14.9971 14.0012 13.6621 14.7066 11.6669H14.7216C14.7378 11.6209 14.7533 11.5747 14.7681 11.5283C14.8941 11.1346 15.2398 10.8336 15.653 10.8336C16.1632 10.8336 16.5617 11.2836 16.4263 11.7754C15.6412 14.6268 13.0347 16.6668 9.99992 16.6669H9.99158C8.22389 16.6722 6.52755 15.97 5.28075 14.7169L4.18703 15.812ZM5.23158 8.47124C5.10446 8.86489 4.75848 9.1669 4.34482 9.1669C3.83529 9.1669 3.43722 8.71742 3.57241 8.22615C4.35686 5.37532 6.96181 3.33517 9.99575 3.33353H9.99992C11.7679 3.32787 13.4647 4.0301 14.7116 5.28356L15.858 4.38441C16.1861 4.12705 16.6666 4.36082 16.6666 4.77784V7.98644C16.6666 8.60438 16.1117 9.07442 15.5022 8.97283L12.6255 8.49339C12.2242 8.42651 12.0665 7.93399 12.3543 7.64648L13.5183 6.48356C12.5923 5.5366 11.3243 5.00201 9.99992 5.00023C7.88371 5.00335 5.99865 6.33838 5.29325 8.33356H5.27825C5.26203 8.37924 5.24647 8.42513 5.23158 8.47124Z"
        fillOpacity="0.7"
      />
    </Svg>
  )
}

export default Icon

import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import { simpleRpcProviders } from 'utils/providers'
import { chainIds } from 'utils/web3React'
import { Chain } from 'config/constants/types'
import { setBlock } from 'state/actions'
import { State } from '../types'

export const usePollBlockNumber = (refreshTime = 6000) => {
  const timer = useRef(null)
  const dispatch = useAppDispatch()

  useEffect(() => {
    timer.current = setInterval(async () => {
      const blockNumbers = await Promise.allSettled(
        chainIds.map(async (chainId) => {
          return simpleRpcProviders[chainId].getBlockNumber()
        }),
      )

      dispatch(
        setBlock(
          chainIds.reduce((acc, val, idx) => {
            if (blockNumbers[idx].status === 'fulfilled') {
              acc[val] = Number((blockNumbers[idx] as PromiseFulfilledResult<number>).value)
            } else {
              acc[val] = null
            }

            return acc
          }, {}),
        ),
      )
    }, refreshTime)

    return () => clearInterval(timer.current)
  }, [dispatch, timer, refreshTime])
}

export const useBlock = (chainId: Chain) => {
  return useSelector((state: State) => state.blocks[chainId] || { currentBlock: 0, initialBlock: 0 })
}

export const useCurrentBlock = (chainId: Chain) => {
  return useSelector((state: State) => state.blocks[chainId]?.currentBlock)
}

export const useInitialBlock = (chainId: Chain) => {
  return useSelector((state: State) => state.blocks[chainId].initialBlock)
}

export const useBlockInitialised = () => {
  const isInitialised = useSelector((state: State) => state.blocks.initialised)
  const lastUpdated = useSelector((state: State) => state.blocks.lastUpdated)

  return isInitialised && lastUpdated + 4 * 60 * 60 * 1000 > new Date().getTime() // 4 hours
}

export const useValidBlockNumber = (chainId: Chain, allocSnapshotBlock: number) => {
  const blockNumber = useCurrentBlock(chainId)
  return allocSnapshotBlock
    ? Math.min(allocSnapshotBlock, Math.max(0, blockNumber - 10))
    : Math.max(0, blockNumber - 10)
}

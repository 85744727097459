import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M12.3095 6.41659H19.25C19.7563 6.41659 20.1667 6.00618 20.1667 5.49992C20.1667 4.99366 19.7563 4.58325 19.25 4.58325H12.3095H9.69049H2.75001C2.24375 4.58325 1.83334 4.99366 1.83334 5.49992C1.83334 6.00618 2.24375 6.41659 2.75001 6.41659H9.69049H12.3095Z" />
      <path d="M15.5833 5.89286L15.5833 7.33333C15.5833 7.83959 15.9937 8.25 16.5 8.25C17.0063 8.25 17.4167 7.83959 17.4167 7.33333L17.4167 5.89286L17.4167 5.10714L17.4167 3.66667C17.4167 3.16041 17.0063 2.75 16.5 2.75C15.9937 2.75 15.5833 3.16041 15.5833 3.66667L15.5833 5.10714L15.5833 5.89286Z" />
      <path d="M15.5833 16.8929L15.5833 18.3333C15.5833 18.8396 15.9937 19.25 16.5 19.25C17.0063 19.25 17.4167 18.8396 17.4167 18.3333L17.4167 16.8929L17.4167 16.1071L17.4167 14.6667C17.4167 14.1604 17.0063 13.75 16.5 13.75C15.9937 13.75 15.5833 14.1604 15.5833 14.6667L15.5833 16.1071L15.5833 16.8929Z" />
      <path d="M10.0833 11.3929L10.0833 12.8333C10.0833 13.3396 10.4937 13.75 11 13.75C11.5063 13.75 11.9167 13.3396 11.9167 12.8333L11.9167 11.3929L11.9167 10.6071L11.9167 9.16667C11.9167 8.66041 11.5063 8.25 11 8.25C10.4937 8.25 10.0833 8.66041 10.0833 9.16667L10.0833 10.6071L10.0833 11.3929Z" />
      <path d="M12.3095 11.9166H19.25C19.7563 11.9166 20.1667 11.5062 20.1667 10.9999C20.1667 10.4937 19.7563 10.0833 19.25 10.0833H12.3095H9.69049H2.75001C2.24375 10.0833 1.83334 10.4937 1.83334 10.9999C1.83334 11.5062 2.24375 11.9166 2.75001 11.9166H9.69049H12.3095Z" />
      <path d="M12.3095 17.4166H19.25C19.7563 17.4166 20.1667 17.0062 20.1667 16.4999C20.1667 15.9937 19.7563 15.5833 19.25 15.5833H12.3095H9.69049H2.75001C2.24375 15.5833 1.83334 15.9937 1.83334 16.4999C1.83334 17.0062 2.24375 17.4166 2.75001 17.4166H9.69049H12.3095Z" />
    </Svg>
  )
}

export default Icon

import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75ZM9.75 13.5V12.06H8.25V13.5H9.75ZM9.57783 8.69161C8.95659 9.14874 8.25 9.66868 8.25 10.98H9.75C9.75 10.1913 10.2833 9.74395 10.8306 9.28479C11.4075 8.80079 12 8.3037 12 7.38C12 5.7888 10.6575 4.5 9 4.5C7.3425 4.5 6 5.7888 6 7.38H7.5C7.5 6.588 8.175 5.94 9 5.94C9.825 5.94 10.5 6.588 10.5 7.38C10.5 8.01304 10.0652 8.333 9.57783 8.69161Z"
      />
    </Svg>
  )
}

export default Icon

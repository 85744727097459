import React from 'react'

interface Props {
  size?: number
}

export const LockLight: React.FC<Props> = ({ size = 100 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M49.4617 7.31738C37.0571 7.31738 26.9531 17.4214 26.9531 29.826V52.668H41.9588V29.826C41.9588 25.6742 45.31 22.3231 49.4617 22.3231C53.6134 22.3231 56.9646 25.6742 56.9646 29.826V52.668H71.9703V29.826C71.9703 17.4214 61.8663 7.31738 49.4617 7.31738Z"
        fill="url(#paint0_linear_7153_131141)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9531 65.1727V47.666H76.9741V65.1727C76.9741 80.3286 64.6195 92.6832 49.4636 92.6832C34.3077 92.6832 21.9531 80.3286 21.9531 65.1727ZM51.9646 72.2252C54.8658 71.175 56.9665 68.4241 56.9665 65.1727C56.9665 61.0209 53.6154 57.6698 49.4636 57.6698C45.3119 57.6698 41.9607 61.0209 41.9607 65.1727C41.9607 68.4241 44.0614 71.175 46.9627 72.2252V82.6793H51.9646V72.2252Z"
        fill="#C7D9F3"
      />
      <path
        d="M56.9667 65.1728C56.9667 68.4242 54.866 71.1751 51.9647 72.2253V82.6794H46.9628V72.2253C44.0616 71.1751 41.9609 68.4242 41.9609 65.1728C41.9609 61.021 45.312 57.6699 49.4638 57.6699C53.6155 57.6699 56.9667 61.021 56.9667 65.1728Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7153_131141"
          x1="49.4617"
          y1="7.31738"
          x2="49.4617"
          y2="73.9444"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C2D2F1" stopOpacity="0.12" />
          <stop offset="1" stopColor="#6589CF" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export const LockDark: React.FC<Props> = ({ size = 100 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M49.4617 7.31738C37.0571 7.31738 26.9531 17.4214 26.9531 29.826V52.668H41.9588V29.826C41.9588 25.6742 45.31 22.3231 49.4617 22.3231C53.6134 22.3231 56.9646 25.6742 56.9646 29.826V52.668H71.9703V29.826C71.9703 17.4214 61.8663 7.31738 49.4617 7.31738Z"
        fill="url(#paint0_linear_7153_130387)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9531 65.1727V47.666H76.9741V65.1727C76.9741 80.3286 64.6195 92.6832 49.4636 92.6832C34.3077 92.6832 21.9531 80.3286 21.9531 65.1727ZM51.9646 72.2252C54.8658 71.175 56.9665 68.4241 56.9665 65.1727C56.9665 61.0209 53.6154 57.6698 49.4636 57.6698C45.3119 57.6698 41.9607 61.0209 41.9607 65.1727C41.9607 68.4241 44.0614 71.175 46.9627 72.2252V82.6793H51.9646V72.2252Z"
        fill="#6361C9"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7153_130387"
          x1="49.4617"
          y1="7.31738"
          x2="49.4617"
          y2="73.9444"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B6DCFF" stopOpacity="0.12" />
          <stop offset="1" stopColor="#66B6FF" />
        </linearGradient>
      </defs>
    </svg>
  )
}

import BigNumber from 'bignumber.js'

export const getBalanceNumber = (balance: BigNumber, decimals = 18) => {
  const displayBalance = new BigNumber(balance).dividedBy(new BigNumber(10).pow(decimals))
  return displayBalance.toNumber()
}

export const getFullDisplayBalance = (balance: BigNumber, decimals = 18, fractions?: number): string => {
  return balance.dividedBy(new BigNumber(10).pow(decimals)).toFixed(fractions)
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getBalanceInWei = (balance: BigNumber, decimals = 18, fractions?: number): string => {
  return balance.multipliedBy(new BigNumber(10).pow(decimals)).toFixed(fractions)
}

export const convertFromWei = (balance: BigNumber, decimals = 18) => {
  const displayBalance = new BigNumber(balance).dividedBy(new BigNumber(10).pow(decimals))
  return displayBalance
}

export const convertToWei = (balance: BigNumber, decimals = 18) => {
  const displayBalance = new BigNumber(balance).multipliedBy(new BigNumber(10).pow(decimals))
  return displayBalance
}

export const roundDecimals = (balance?: number, sigFigs = 6) => {
  if (balance) {
    return parseFloat(Number(balance).toPrecision(Math.min(sigFigs, balance.toString().replace('.', '').length)))
  }
  return ''
}

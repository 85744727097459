import React from 'react'
import { SvgProps } from '../types'
import Svg from '../Svg'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="14" height="12" viewBox="0 0 14 12" {...props}>
      <path d="M4.95433 11.6458C4.75907 11.841 4.44249 11.841 4.24723 11.6458L0.154335 7.55287C-0.0409274 7.3576 -0.0409275 7.04102 0.154335 6.84576L1.13991 5.86018C1.33491 5.66519 1.65096 5.66488 1.84633 5.8595L4.24723 8.25113C4.4426 8.44574 4.75866 8.44544 4.95365 8.25044L12.154 1.05012C12.3497 0.854368 12.6673 0.854928 12.8623 1.05137L13.8497 2.04576C14.0438 2.24123 14.0432 2.55684 13.8485 2.75162L4.95433 11.6458Z" />
    </Svg>
  )
}

export default Icon

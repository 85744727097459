import React, { useMemo } from 'react'
import styled from 'styled-components'
import useHttpLocations from 'hooks/useHttpLocations'
import { WrappedTokenInfo } from 'config/swap/types'
import { Currency } from 'swap-sdk/entities/currency'
import { TokenClass } from 'swap-sdk/entities/token'
import { useNetwork } from 'wagmi'
import { Chain } from 'config/constants/types'
import getTokenLogoURL from '../../utils/getTokenLogoURL'
import Logo from './Logo'

const StyledLogo = styled(Logo)<{ size: string }>`
  border-radius: 50%;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  style,
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}) {
  const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)
  const { chain } = useNetwork()
  const chainId = chain?.id || Chain.BSC_MAINNET
  const srcs: string[] = useMemo(() => {
    if (currency?.isNative) return [`/images/tokens/${currency.symbol}.png`]
    if (currency instanceof TokenClass) {
      if (currency instanceof WrappedTokenInfo) {
        return [currency.logoURI, ...uriLocations, getTokenLogoURL((currency as TokenClass).address, chainId)].filter(
          (val) => val,
        )
      }
      return [`/images/tokens/${currency.symbol}.png`, getTokenLogoURL(currency.address, chainId)]
    }
    return []
  }, [currency, uriLocations, chainId])

  return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
}

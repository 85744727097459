import addresses from 'config/constants/contracts'
import { Address, Chain } from 'config/constants/types'

export const getAddress = (address: Address, chainId: number): string => {
  return address?.[chainId]
}

export const getMasterChefAddress = (chainId: Chain) => {
  return getAddress(addresses.masterChef, chainId)
}
export const getMulticallAddress = (chainId: Chain) => {
  return getAddress(addresses.mulltiCall, chainId)
}
export const getWGasAddress = (chainId: Chain) => {
  return getAddress(addresses.wgas, chainId)
}
export const getENSRegistrarAddress = (chainId: Chain) => {
  return getAddress(addresses.ensRegistrar, chainId)
}
export const getRouterAddress = (chainId: Chain) => {
  return getAddress(addresses.router, chainId)
}

import { useContext } from 'react'
import { RefreshContext } from 'contexts/RefreshContext'

const useRefresh = () => {
  const { threeSeconds, fast, slow, fiveMinutes, medium } = useContext(RefreshContext)
  return {
    threeSecondsRefresh: threeSeconds,
    fastRefresh: fast,
    slowRefresh: slow,
    fiveMinutesRefresh: fiveMinutes,
    mediumRefresh: medium,
  }
}

export default useRefresh

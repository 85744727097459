import BigNumber from 'bignumber.js'
import { useTranslation } from 'contexts/Localization'
import React, { useCallback, useState } from 'react'
import ModalV2 from 'uikit/widgets/Modal/ModalV2'
import InputArea from 'componentsV2/InputArea/InputArea'
import { Token } from 'config/constants/types'
import { useUnstake } from 'hooks/useIFAMaster'
import { Button } from 'uikit'
import styled, { useTheme } from 'styled-components'
import IFTypography from 'componentsV2/IFTypography/IFTypography'
import WhiteCircleLoader from 'componentsV2/Loader/WhiteCircleLoader'
import { getBalanceInWei } from 'utils/formatBalance'
import { useToast } from 'state/hooks'
import { getBscScanLink } from 'utils'

interface UnstakeModalProps {
  userStakeAmount: BigNumber
  stakingToken: Token
  masterAddress: string
  trackID: number
  chainId: number
  onDismiss?: VoidFunction
  isDuringSubscriptionPeriod: boolean
}
const Middle = styled.div`
  margin-top: 20px;
  span {
    text-transform: unset;
  }
`
const Bottom = styled.div`
  margin-bottom: -24px;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;

  button {
    height: 44px;
    flex-basis: 150px;
    font-size: 18px;
    border-radius: 22px;
    ${({ theme }) => theme.mediaQueries?.md} {
      flex-basis: calc((100% - 15px) / 2);
    }
  }

  ${({ theme }) =>
    !theme.isDark &&
    `button:disabled {
      color: ${theme.colorsV2?.feedbacks.disableText};
    }`}
`

const UnstakeModal: React.FC<UnstakeModalProps> = ({
  userStakeAmount,
  stakingToken,
  masterAddress,
  trackID,
  onDismiss,
  chainId,
  isDuringSubscriptionPeriod,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [inputVal, setInputVal] = useState('')
  const { onUnstake, isLoading } = useUnstake(masterAddress, trackID)
  const unstakable = new BigNumber(inputVal).isLessThanOrEqualTo(userStakeAmount)
  const isBeam = process.env.REACT_APP_WHITELABEL_BRAND === 'beam'
  const { toastSuccessV2 } = useToast()

  const onUnstakeSuccess = useCallback(
    (txHash) => {
      toastSuccessV2(`Success`, `You've unstaked ${inputVal} ${stakingToken.symbol}`, {
        text: 'View on explorer',
        url: getBscScanLink(txHash, 'transaction', chainId),
      })
      onDismiss()
    },
    [inputVal, stakingToken, toastSuccessV2, onDismiss, chainId],
  )

  const unstakeCallback = useCallback(async () => {
    if (isLoading) return
    onUnstake(getBalanceInWei(new BigNumber(inputVal), stakingToken.decimals), onUnstakeSuccess)
  }, [onUnstake, isLoading, inputVal, onDismiss])
  return (
    <ModalV2 title={t('Unstake Tokens')} onDismiss={onDismiss} disableScroll bodyPadding="24px 14px">
      <InputArea
        balance={userStakeAmount}
        inputVal={inputVal}
        iconSrc={`/images/tokens/${stakingToken.symbol}.png`}
        symbol={stakingToken.symbol}
        onInputChange={setInputVal}
        maxAmountOnSelect={userStakeAmount}
      />
      {isDuringSubscriptionPeriod && (
        <Middle>
          <IFTypography
            ifcolor="textSecondary"
            lineHeight="20px"
            variant="button"
            fontFamily="Roboto"
            fontWeight="regular"
          >
            {t('Unstaking your tokens during the staking period will likely decrease the allocation you receive.')}
          </IFTypography>
        </Middle>
      )}
      <Bottom>
        <Button
          variant="secondary"
          onClick={onDismiss}
          style={
            theme.brand !== 'if'
              ? {
                  border: isBeam ? '' : '1px solid',
                  color: isBeam ? '#FFF' : '#1273EA',
                  background: isBeam ? '#444E60' : '',
                }
              : {}
          }
        >
          {t('Cancel')}
        </Button>
        <Button
          onClick={unstakeCallback}
          disabled={!unstakable}
          isLoading={isLoading}
          startIcon={isLoading ? <WhiteCircleLoader size="24px" /> : null}
          style={{ paddingLeft: isLoading ? 40 : undefined }}
        >
          {isLoading ? `  ${t('Unstaking')}` : t('Unstake')}
        </Button>
      </Bottom>
    </ModalV2>
  )
}

export default UnstakeModal

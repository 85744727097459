import React from 'react'
import styled from 'styled-components'
import { variant as systemVariant, space } from 'styled-system'
import WarningIcon from '../Svg/Icons/Warning'
import ErrorIcon from '../Svg/Icons/Error'

import { Box } from '../Box'
import { MessageProps } from './types'
import variants from './theme'

const Icons = {
  warning: WarningIcon,
  danger: ErrorIcon,
}

const MessageContainer = styled.div<MessageProps>`
  display: flex;
  background-color: gray;
  padding: 20px;
  border-radius: 5px;

  ${space}
  ${({ theme }) =>
    systemVariant({
      variants: variants(theme),
    })}
`

const Message: React.FC<MessageProps> = ({ children, variant, icon, ...props }) => {
  const Icon = Icons[variant]
  return (
    <MessageContainer variant={variant} {...props}>
      <Box mr="12px">{icon ?? <Icon color={variant === 'danger' ? 'failure' : 'warning'} width="24px" />}</Box>
      {children}
    </MessageContainer>
  )
}

export default Message

import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { useModalV2 } from 'uikit'
import { useMatchBreakpoints } from 'uikit/hooks'
import { Network } from 'uikit/widgets/WalletModal/types'
import { setupNetwork } from 'utils/wallet'
import DesktopNetworkSelector, { DesktopNetworkSelectorStyles } from './DesktopNetworkSelector'
import MobileNetworkSelector from './MobileNetworkSelector'

interface Options {
  desktopSelectorStyle?: DesktopNetworkSelectorStyles
}

interface NetworkSelectorProps {
  children: React.ReactElement
  currentNetwork: Network
  options?: Options
}

const DesktopSelectorWrapper = styled.div`
  display: none;
  height: 5px;
  width: 100%;
  position: absolute;
`

const Container = styled.div<{ isMobile: boolean }>`
  position: relative;
  height: 100%;
  ${({ isMobile }) =>
    !isMobile &&
    `
  &:focus-within ${DesktopSelectorWrapper} {
    display: block;
  }
`}
`

const NetworkSelector: React.FC<NetworkSelectorProps> = ({ currentNetwork, children, options }) => {
  const { isXl } = useMatchBreakpoints()
  const isMobile = !isXl
  const currentNetworkWithID = useMemo(() => {
    return { ...currentNetwork, id: currentNetwork?.chainID?.toString() ?? '' }
  }, [currentNetwork])
  const networkSelectionHandler = useCallback((network) => {
    setupNetwork(network?.chainID)
  }, [])

  const [onPresentMobileSelector] = useModalV2({
    modal: <MobileNetworkSelector currentNetwork={currentNetworkWithID} selectionHandler={networkSelectionHandler} />,
  })

  const networkChangeHandler = useCallback(() => {
    if (isMobile) {
      onPresentMobileSelector()
    }
  }, [isMobile, onPresentMobileSelector])

  return (
    <Container onClick={networkChangeHandler} isMobile={isMobile}>
      {children}
      <DesktopSelectorWrapper>
        <DesktopNetworkSelector
          currentNetwork={currentNetworkWithID}
          selectionHandler={networkSelectionHandler}
          style={options?.desktopSelectorStyle}
        />
      </DesktopSelectorWrapper>
    </Container>
  )
}

export default NetworkSelector

import { FormControl, CircularProgress, Autocomplete, TextField, Popper, Box } from '@mui/material'

import styled, { useTheme } from 'styled-components'
import IFTypography from 'componentsV2/IFTypography'
import { useTranslation } from 'contexts/Localization'
import { countries } from 'countries-list'
import React, { useCallback, useEffect, useState } from 'react'

import { LinkExternal, Button, Checkbox, Flex, VerifiedDarkIcon, VerifiedLightIcon, RectangleWarning } from 'uikit'
import { useBlockedCountries } from 'hooks/useBlockedCountries'

import useSignOAuth from 'hooks/useSignOAuth'
import { useKycStatus } from 'hooks/useKycStatus'
import { SubmitKYCBody } from 'state/v2_types'
import isNationalityBlocked from 'utils/isNationalityBlocked'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import { useIsUserSignedIn } from 'state/user/hooks'
import { CompliancePage } from './ComplianceCard'

const StyledCheckbox = styled(Checkbox)`
  min-width: 24px;
  min-height: 24px;
`

const StyledButton = styled(Button)`
  width: 280px;
  height: 44px;
  align-self: center;
`

const StyledPopper = styled(Popper)`
  > div {
    background: ${({ theme }) => (theme.isDark ? theme.colorsV2?.light : theme.colorsV2?.main)};
    .MuiAutocomplete-option.Mui-focused {
      font-weight: 600;
      background: ${({ theme }) => (theme.isDark ? theme.colorsV2?.main2 : theme.colorsV2.dark2)};
    }
    color: ${({ theme }) => theme.colorsV2?.text};
  }
`

const StyledInputBox = styled(Box)<{ $val: string }>`
  input {
    width: 100%;
    color: ${({ theme, $val }) => ($val?.length > 0 ? theme.colorsV2.text : theme.colorsV2?.textDisabled)} !important;
    font-size: 16px;
    background: ${({ theme }) => theme.colorsV2?.dark2};
    height: 40px;
    border: none;
    border-radius: 10px;
    padding: 0 10px;
    height: 48px;
    ::-ms-input-placeholder {
      color: ${({ theme }) => theme.colorsV2?.textDisabled} !important;
    }
    ::placeholder {
      color: ${({ theme }) => theme.colorsV2?.textDisabled} !important;
    }
  }
`

interface ComplianceModalProps {
  submitKYC: (body: SubmitKYCBody, noToast?: boolean, onSucceed?: any, onError?: any) => void
  isLoading: boolean
  recompliance?: boolean
  setPage: (page: CompliancePage) => void
}

const ComplianceModal: React.FC<ComplianceModalProps> = ({ submitKYC, isLoading, recompliance, setPage }) => {
  // country code
  const [nationality, setNationality] = useState('')
  const [nationalityNotMatch, setNationalityNotMatch] = useState(false)
  const blockedCountries = useBlockedCountries()
  const [isBanned, setIsBanned] = useState(false)
  const [confirmLaws, setConfirmLaws] = useState(false)
  const [confirmTerms, setConfirmTerms] = useState(false)
  const { complianceCountry: prevCountry } = useKycStatus()
  const { signOAuth } = useSignOAuth('if')
  const isUserSigned = useIsUserSignedIn()
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)

  const theme = useTheme()

  const isValid = confirmLaws && confirmTerms && nationality !== '' && !(!recompliance && isBanned)

  useEffect(() => {
    // check if the country is banned
    setIsBanned(isNationalityBlocked(blockedCountries, nationality))
    // check if the selected country matches the KYC record
    if (!recompliance) {
      return
    }
    if (nationality !== '' && prevCountry && prevCountry !== nationality) {
      setNationalityNotMatch(true)
    } else {
      setNationalityNotMatch(false)
    }
  }, [nationality])

  const handleSubmit = useCallback(
    (noToast?: boolean, onSucceed?: () => void, source?: string) => {
      submitKYC({ countryCode: nationality, source }, noToast, onSucceed)
    },
    [submitKYC, nationality],
  )

  // sort countries by name
  const sortedCountries = Object.fromEntries(
    Object.entries(countries).sort(([, a], [, b]) => a.name.localeCompare(b.name)),
  )
  const countriesObj = Object.keys(sortedCountries || {})
  const countriesValArr = Object.entries(sortedCountries)

  const { t } = useTranslation()

  const menuProps = {
    sx: {
      'div.MuiPaper-root': {
        borderRadius: `20px`,
        overflow: `auto`,
        scrollbarWidth: `thin` /* For Firefox */,
        scrollbarColor: `transparent transparent`,
        '-webkit-background-clip': 'padding-box',
        '&::-webkit-scrollbar': { width: 0 } /* For Chrome, Safari, and Opera */,
        height: `300px`,
      },
      'ul.MuiList-root': {
        backgroundColor: `${theme.colorsV2.main}`,
        color: `${theme.colorsV2.textSecondary}`,
        borderRadius: `15px`,
      },
    },
  }

  return (
    <>
      <Flex margin="0px auto 40px">
        {recompliance ? (
          <RectangleWarning height="85px" width="85px" />
        ) : theme.isDark ? (
          <VerifiedDarkIcon height="100px" width="100px" />
        ) : (
          <VerifiedLightIcon height="100px" width="100px" />
        )}
      </Flex>
      {recompliance ? (
        <IFTypography fontSize="14px" fontWeight="bold" lineHeight="20px" align="center" paddingBottom="40px">
          {t('Please confirm the details below. You may be asked periodically to re-confirm your current status.')}
        </IFTypography>
      ) : (
        <IFTypography fontSize="14px" fontWeight="bold" lineHeight="20px" align="center" paddingBottom="40px">
          {t(
            'To begin the KYC verification process, please confirm the details below. You may be asked periodically to re-confirm your current status.',
          )}
        </IFTypography>
      )}
      <Flex>
        {/* dropdown menu */}
        <FormControl sx={{ height: '45px', width: '100%', borderRadius: '10px', marginBottom: '20px' }}>
          <Autocomplete
            options={countriesObj}
            filterOptions={(options, state) => {
              const { inputValue } = state
              const newArr = countriesValArr.filter(([_, count]) =>
                count.name.toLowerCase().startsWith(inputValue.toLowerCase()),
              )

              return newArr.map(([key]) => key)
            }}
            sx={menuProps.sx}
            PopperComponent={StyledPopper}
            getOptionLabel={(code) => countries[code]?.name}
            onChange={(event, newValue) => {
              setNationality(newValue)
            }}
            onInputChange={(event, newInputValue) => {
              setNationality(newInputValue)
            }}
            renderInput={(params) => (
              <StyledInputBox ref={params.InputProps.ref} position="relative" $val={nationality}>
                <input type="text" {...params.inputProps} placeholder="Select Citizenship" />
                <KeyboardArrowDownIcon
                  style={{ position: 'absolute', right: '10px', top: '10px', pointerEvents: 'none' }}
                />
              </StyledInputBox>
            )}
          />
        </FormControl>
      </Flex>
      {/* No referral code for now */}
      {/* {!recompliance && (
        <IFTypography fontSize="14px" lineHeight="20px" align="left" padding="5px 0px">
          {t('Referral Code (optional)')}
        </IFTypography>
      )}
      <Input placeholder="Referral code (optional)" /> */}

      <>
        <Flex flexDirection="column">
          {nationalityNotMatch && (
            <IFTypography fontSize="14px" lineHeight="20px" align="left" padding="8px 0px 5px 0px" ifcolor="textDanger">
              {t(
                `- Please ensure that you have reviewed the selected information as your previous citizenship is ${
                  countries[prevCountry]?.name || prevCountry
                }.`,
              )}
            </IFTypography>
          )}
          {isBanned && !recompliance && (
            <IFTypography fontSize="14px" lineHeight="20px" align="left" padding="0px 0px 5px 0px" ifcolor="textDanger">
              {t('Jurisdiction ineligible to proceed with KYC.')}
            </IFTypography>
          )}
          {isBanned && recompliance && (
            <IFTypography fontSize="14px" lineHeight="20px" align="left" padding="0px 0px 5px 0px" ifcolor="textDanger">
              {t(
                '- Citizens and residents of jurisdictions, countries, and regions, including the US, Mainland China, Hong Kong, Macau, Taiwan, and UN-sanctioned jurisdictions are excluded from participation.',
              )}
            </IFTypography>
          )}
        </Flex>

        <>
          <Flex flexDirection="row" alignItems="flex-start" padding="30px 0px">
            <StyledCheckbox
              name="confirmedLaws"
              type="checkbox"
              checked={confirmLaws}
              onChange={() => {
                setConfirmLaws(!confirmLaws)
              }}
              scale="sm"
            />
            <IFTypography
              fontSize="14px"
              lineHeight="20px"
              align="left"
              paddingLeft="16px"
              margin="auto 0px"
              ifcolor="textSecondary"
            >
              {t('I can participate in this activity under my local laws.')}
            </IFTypography>
          </Flex>
          <Flex flexDirection="row" alignItems="flex-start" paddingBottom="15px">
            <StyledCheckbox
              name="confirmedTerms"
              type="checkbox"
              checked={confirmTerms}
              onClick={() => setConfirmTerms(!confirmTerms)}
              scale="sm"
            />
            <IFTypography
              fontSize="14px"
              lineHeight="20px"
              align="left"
              paddingLeft="16px"
              margin="auto 0px"
              ifcolor="textSecondary"
            >
              <Flex>
                {t('I have read these')}
                <LinkExternal external small href="https://impossible.finance/terms" ml="5px" target="_blank">
                  {t('Terms & Conditions')}
                </LinkExternal>
              </Flex>
            </IFTypography>
          </Flex>
        </>
      </>

      <StyledButton
        disabled={!isValid || isLoading}
        margin="15px 30px"
        onClick={() => {
          setIsSubmitLoading(true)
          if (!isUserSigned) {
            signOAuth(() =>
              handleSubmit(true, () => {
                if (recompliance) {
                  setPage('success')
                } else {
                  setPage('kycList')
                }
                setIsSubmitLoading(false)
              }),
            )
          } else {
            handleSubmit(true, () => {
              if (recompliance) {
                setPage('success')
              } else {
                setPage('kycList')
              }
              setIsSubmitLoading(false)
            })
          }
        }}
      >
        {isSubmitLoading && (
          <Flex paddingRight="10px">
            <CircularProgress color="inherit" size="16px" />{' '}
          </Flex>
        )}
        {t('Submit')}
      </StyledButton>
    </>
  )
}

export default ComplianceModal

import React from 'react'
import Svg from '../../../components/Svg/Svg'

const LogoDarkWithText: React.FC = ({ ...props }) => {
  return (
    <Svg viewBox="0 0 134 35" width="134" height="35" fill="none" {...props}>
      <path
        d="M4.10022 10.9133C6.35338 10.9133 8.17993 9.08679 8.17993 6.83362C8.17993 4.58046 6.35338 2.75391 4.10022 2.75391C1.84706 2.75391 0.0205078 4.58046 0.0205078 6.83362C0.0205078 9.08679 1.84706 10.9133 4.10022 10.9133Z"
        fill="white"
      />
      <path
        d="M22.7852 10.9132H17.1348C16.4413 10.9132 15.8701 10.3421 15.8701 9.64851V3.99811C15.8701 3.30456 16.4413 2.7334 17.1348 2.7334H22.7852C23.4788 2.7334 24.05 3.30456 24.05 3.99811V9.64851C24.05 10.3625 23.4788 10.9132 22.7852 10.9132Z"
        fill="#00FFB9"
      />
      <path
        d="M8.17982 14.7686H7.93503H1.73388C0.775145 14.7686 0 15.5437 0 16.5024V22.9484V29.3739C0 30.3326 0.775145 31.1078 1.73388 31.1078H6.44594C7.40467 31.1078 8.17982 30.3326 8.17982 29.3739V24.6823C8.17982 23.7235 8.95496 22.9484 9.91369 22.9484H14.381C15.3397 22.9484 16.1149 22.1732 16.1149 21.2145V16.5024C16.1149 15.5437 15.3397 14.7686 14.381 14.7686H8.17982Z"
        fill="#00DAFF"
      />
      <path d="M39.4712 0H41.8782V14.2382H39.4712V0Z" fill="white" />
      <path
        d="M44.4482 4.91601H46.8553V5.83394C47.406 5.14039 48.2016 4.73242 49.1195 4.73242C50.1802 4.73242 51.139 5.28318 51.7917 6.20111C52.4037 5.30358 53.3624 4.73242 54.5659 4.73242C56.5446 4.73242 58.0133 6.30311 58.0133 8.34296V14.2381H55.6063V8.52655C55.6063 7.60861 54.9535 6.87427 54.056 6.87427C53.0768 6.87427 52.4445 7.66981 52.4445 8.62854V14.2381H50.0374V8.52655C50.0374 7.60861 49.3847 6.87427 48.4464 6.87427C47.4876 6.87427 46.8757 7.66981 46.8757 8.62854V14.2381H44.4482V4.91601Z"
        fill="white"
      />
      <path
        d="M60.2368 4.91601H62.6439V5.91554C63.3782 5.18119 64.3777 4.73242 65.6017 4.73242C68.1107 4.73242 70.1913 6.87427 70.1913 9.58728C70.1913 12.3003 68.1107 14.4421 65.6017 14.4421C64.3777 14.4421 63.3782 13.9934 62.6439 13.259V18.5218H60.2368V4.91601V4.91601ZM67.8047 9.58728C67.8047 8.09818 66.6216 6.91506 65.1529 6.91506C63.6842 6.91506 62.4807 8.09818 62.4807 9.58728C62.4807 11.0764 63.6842 12.2595 65.1529 12.2595C66.6216 12.2391 67.8047 11.056 67.8047 9.58728Z"
        fill="white"
      />
      <path
        d="M71.395 9.58728C71.395 6.89467 73.5165 4.73242 76.3315 4.73242C79.1057 4.73242 81.2475 6.87427 81.2475 9.58728C81.2475 12.3003 79.1057 14.4421 76.3315 14.4421C73.5165 14.4217 71.395 12.2799 71.395 9.58728ZM78.9221 9.58728C78.9221 8.11858 77.7594 6.97626 76.3315 6.97626C74.8424 6.97626 73.6797 8.11858 73.6797 9.58728C73.6797 11.056 74.8424 12.2187 76.3315 12.2187C77.7594 12.1983 78.9221 11.0356 78.9221 9.58728Z"
        fill="white"
      />
      <path
        d="M82.2266 13.0345L83.5729 11.3822C84.2256 12.0554 85.0824 12.4634 85.8167 12.4634C86.5511 12.4634 86.9998 12.0758 86.9998 11.5658C86.9998 10.9131 86.2451 10.7499 85.1232 10.3623C83.6749 9.85235 82.4509 9.1996 82.4509 7.56772C82.4509 5.67065 84.0828 4.71191 85.8779 4.71191C87.2242 4.71191 88.3665 5.24228 89.0805 6.03782L87.673 7.46572C87.1426 6.93536 86.4287 6.56819 85.7963 6.56819C85.2048 6.56819 84.8172 6.87416 84.8172 7.36373C84.8172 7.99609 85.4903 8.17967 86.5715 8.52645C87.9586 8.99561 89.3661 9.62797 89.3661 11.4842C89.3661 13.3813 87.8974 14.4012 85.8779 14.4012C84.2868 14.4216 82.9813 13.7689 82.2266 13.0345Z"
        fill="white"
      />
      <path
        d="M90.2026 13.0345L91.5489 11.3822C92.2017 12.0554 93.0584 12.4634 93.7928 12.4634C94.5271 12.4634 94.9759 12.0758 94.9759 11.5658C94.9759 10.9131 94.2212 10.7499 93.0992 10.3623C91.6509 9.85235 90.427 9.1996 90.427 7.56772C90.427 5.67065 92.0589 4.71191 93.854 4.71191C95.2003 4.71191 96.3426 5.24228 97.0566 6.03782L95.649 7.46572C95.1187 6.93536 94.4047 6.56819 93.7724 6.56819C93.1808 6.56819 92.7933 6.87416 92.7933 7.36373C92.7933 7.99609 93.4664 8.17967 94.5475 8.52645C95.9346 8.99561 97.3421 9.62797 97.3421 11.4842C97.3421 13.3813 95.8734 14.4012 93.854 14.4012C92.2629 14.4216 90.9574 13.7689 90.2026 13.0345Z"
        fill="white"
      />
      <path
        d="M98.9331 1.87636C98.9331 1.10122 99.5859 0.489258 100.341 0.489258C101.116 0.489258 101.748 1.10122 101.748 1.87636C101.748 2.65151 101.116 3.26346 100.341 3.26346C99.5655 3.26346 98.9331 2.65151 98.9331 1.87636ZM99.1575 4.91574H101.524V14.2379H99.1575V4.91574Z"
        fill="white"
      />
      <path
        d="M106.338 13.0347L106.277 14.2382H104.033V0H106.44V5.97678C107.154 5.12004 108.276 4.69167 109.418 4.69167C112.029 4.69167 114.089 6.85392 114.089 9.56692C114.089 12.2799 112.029 14.4422 109.418 14.4422C108.133 14.4218 107.031 13.9118 106.338 13.0347ZM111.682 9.56692C111.682 8.03703 110.519 6.85392 109.01 6.85392C107.521 6.85392 106.338 8.03703 106.338 9.56692C106.338 11.0968 107.521 12.3003 109.01 12.3003C110.519 12.3003 111.682 11.0968 111.682 9.56692Z"
        fill="white"
      />
      <path d="M115.966 0H118.352V14.2382H115.966V0Z" fill="white" />
      <path
        d="M129.979 9.99514H122.636C122.819 11.5046 123.901 12.4838 125.267 12.4838C126.246 12.4838 127.164 11.9942 127.654 11.1375L129.571 11.933C128.817 13.4629 127.246 14.4216 125.288 14.4216C122.33 14.4216 120.29 12.2798 120.29 9.56677C120.29 6.85376 122.371 4.71191 125.206 4.71191C127.899 4.71191 129.959 6.60898 130 9.46478L129.979 9.99514ZM122.881 8.17967H127.45C127.062 7.24134 126.328 6.64978 125.226 6.64978C124.084 6.62938 123.289 7.22094 122.881 8.17967Z"
        fill="white"
      />
      <path
        d="M39.4712 18.0938H48.3446V20.5008H41.8782V24.0093H47.6102V26.4164H41.8782V32.3319H39.4712V18.0938Z"
        fill="white"
      />
      <path
        d="M49.8745 19.9701C49.8745 19.195 50.5273 18.583 51.282 18.583C52.0572 18.583 52.6895 19.195 52.6895 19.9701C52.6895 20.7453 52.0572 21.3572 51.282 21.3572C50.5069 21.3572 49.8745 20.7453 49.8745 19.9701ZM50.0989 23.0095H52.4651V32.3316H50.0989V23.0095Z"
        fill="white"
      />
      <path
        d="M54.974 23.0098H57.3606V23.8665C58.0338 23.1933 58.9109 22.8262 59.8493 22.8262C61.7259 22.8262 63.1946 24.3561 63.1946 26.3143V32.3523H60.7876V26.6203C60.7876 25.6412 60.0533 24.9476 59.0945 24.9476C58.095 24.9476 57.3606 25.6616 57.3402 26.6203V32.3523H54.9536V23.0098H54.974Z"
        fill="white"
      />
      <path
        d="M64.8677 27.6604C64.8677 24.9678 66.9687 22.7852 69.4778 22.7852C70.8037 22.7852 71.8236 23.2747 72.5171 24.0703V23.0095H74.9242V32.3317H72.6803L72.6191 31.1282C71.9256 32.0053 70.8649 32.5153 69.4778 32.5153C66.9483 32.5153 64.8677 30.3734 64.8677 27.6604ZM72.5783 27.6604C72.5783 26.1713 71.4156 24.9882 69.9265 24.9882C68.4374 24.9882 67.2543 26.1713 67.2543 27.6604C67.2543 29.1495 68.417 30.353 69.9265 30.353C71.4156 30.3326 72.5783 29.1291 72.5783 27.6604Z"
        fill="white"
      />
      <path
        d="M77.4125 23.0098H79.7991V23.8665C80.4723 23.1933 81.3494 22.8262 82.2877 22.8262C84.1644 22.8262 85.6331 24.3561 85.6331 26.3143V32.3523H83.2261V26.6203C83.2261 25.6412 82.4917 24.9476 81.533 24.9476C80.5335 24.9476 79.7991 25.6616 79.7787 26.6203V32.3523H77.3921V23.0098H77.4125Z"
        fill="white"
      />
      <path
        d="M87.3057 27.681C87.3057 24.9884 89.4271 22.8262 92.2421 22.8262C92.9356 22.8262 93.6292 22.969 94.2208 23.2341V25.9063C93.7516 25.3556 93.058 25.0088 92.2421 25.0088C90.7326 25.0088 89.6107 26.1919 89.6107 27.681C89.6107 29.1701 90.7122 30.3328 92.2421 30.3328C93.058 30.3328 93.7516 29.986 94.2208 29.4353V32.1279C93.6292 32.3931 92.9356 32.5359 92.2421 32.5359C89.4271 32.5155 87.3057 30.3736 87.3057 27.681Z"
        fill="white"
      />
      <path
        d="M105.318 28.0889H97.9743C98.1578 29.5984 99.239 30.5775 100.606 30.5775C101.585 30.5775 102.503 30.0879 102.992 29.2312L104.91 30.0267C104.155 31.5566 102.584 32.5154 100.626 32.5154C97.6683 32.5154 95.6284 30.3735 95.6284 27.6605C95.6284 24.9475 97.7091 22.8057 100.544 22.8057C103.237 22.8057 105.297 24.7027 105.338 27.5585L105.318 28.0889ZM98.1986 26.2734H102.768C102.38 25.3351 101.646 24.7435 100.544 24.7435C99.4225 24.7231 98.6066 25.3147 98.1986 26.2734Z"
        fill="white"
      />
    </Svg>
  )
}

export default React.memo(LogoDarkWithText)

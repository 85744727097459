import React from 'react'

export const LogoWithText: React.FC = ({ ...props }) => {
  return (
    <svg width="130" height="22" viewBox="0 0 130 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Light_Short 1" clipPath="url(#clip0_3350_1349)">
        <path
          id="Vector"
          d="M68.8247 8.87183H129.307V12.8139H69.188L68.8247 8.87183Z"
          fill="url(#paint0_linear_3350_1349)"
        />
        <path
          id="Vector_2"
          d="M63.4678 4.92969H129.307V8.87172H68.8047L63.4678 4.92969Z"
          fill="url(#paint1_linear_3350_1349)"
        />
        <path id="Vector_3" d="M69.188 16.7559H129.307V20.6979H69.188V16.7559Z" fill="url(#paint2_linear_3350_1349)" />
        <path id="Vector_4" d="M69.188 12.8137H129.307V16.7558H69.188V12.8137Z" fill="url(#paint3_linear_3350_1349)" />
        <path
          id="Vector_5"
          d="M0.416016 20.701H3.54726V18.8603C4.39494 19.8761 5.60015 21.1952 8.34503 21.1952C13.5551 21.1952 15.4264 16.0585 15.4264 13.0079C15.4264 8.96884 12.6036 4.92978 8.26718 4.92978C6.67562 4.92978 5.00908 5.5605 3.82693 6.82498V0.369141H0.416016V20.701ZM3.62511 12.8715C3.62511 10.6457 5.24262 8.28353 7.86065 8.28353C10.0173 8.28353 12.0183 10.0696 12.0183 13.0352C12.0183 16.0009 10.1442 17.8446 7.91254 17.8446C5.91155 17.8446 3.62799 16.2495 3.62799 12.8715H3.62511Z"
          fill="white"
        />
        <path
          id="Vector_6"
          d="M30.5723 14.712C30.6761 14.2177 30.777 13.587 30.777 12.8714C30.777 9.02638 28.0811 4.92969 23.413 4.92969C18.7451 4.92969 15.9194 8.80502 15.9194 13.117C15.9194 17.429 19.1027 21.1951 23.4391 21.1951C26.8009 21.1951 29.494 18.6388 30.3157 15.8915H26.7231C26.0802 17.1561 24.9528 17.8414 23.4391 17.8414C20.9998 17.8414 19.6677 15.6976 19.512 14.709H30.5723V14.712ZM19.4083 11.7737C20.0483 8.9445 22.0522 8.28346 23.4361 8.28346C25.2584 8.28346 26.9019 9.32658 27.2853 11.7737H19.4083Z"
          fill="white"
        />
        <path
          id="Vector_7"
          d="M46.2428 5.45125H43.1116V7.37375H43.0597C42.9559 7.15543 41.2374 4.92969 38.3368 4.92969C34.3839 4.92969 31.2036 8.30772 31.2036 12.9805C31.2036 18.0627 34.4617 21.1951 38.1322 21.1951C39.9284 21.1951 42.0332 20.1793 43.1116 18.8057V20.7009H46.2428V5.45125ZM43.0107 13.0351C43.0107 15.8643 41.2143 17.8443 38.8529 17.8443C36.4916 17.8443 34.6174 15.7278 34.6174 13.1473C34.6174 10.867 36.0533 8.28346 38.8529 8.28346C40.8799 8.28346 43.0107 9.87846 43.0107 13.0351Z"
          fill="white"
        />
        <path
          id="Vector_8"
          d="M47.8115 20.701H51.2253V13.0899C51.2253 11.4676 51.2253 8.28057 53.9442 8.28057C56.049 8.28057 56.7929 10.1485 56.7929 12.2378V20.701H60.2067V13.0899C60.2067 12.1286 60.1808 10.6185 60.72 9.71182C61.2072 8.83245 62.1328 8.28359 63.0814 8.28359C65.7772 8.28359 65.7772 11.3614 65.7772 12.2408V20.704H69.191V11.8011C69.191 9.54809 68.6777 8.09256 68.1127 7.29505C67.5994 6.52484 66.2126 4.93286 63.4159 4.93286C62.4153 4.93286 60.3105 5.04202 58.8227 7.32235C57.4878 5.1239 55.4867 4.93286 54.717 4.93286C52.2547 4.93286 51.2771 6.25193 50.9946 6.7462H50.9427V5.45442H47.8115V20.704V20.701Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_3350_1349"
          x1="99.0675"
          y1="10.9399"
          x2="99.0675"
          y2="15.3368"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF6B6B" />
          <stop offset="0.33" stopColor="#FE1414" />
          <stop offset="0.6" stopColor="#8E0900" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3350_1349"
          x1="96.3891"
          y1="6.99775"
          x2="96.3891"
          y2="11.3946"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBDBFF" />
          <stop offset="0.33" stopColor="#139EDD" />
          <stop offset="0.6" stopColor="#B9F7EA" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3350_1349"
          x1="99.2492"
          y1="18.8239"
          x2="99.2492"
          y2="23.2207"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBFFCA" />
          <stop offset="0.33" stopColor="#48DD13" />
          <stop offset="0.6" stopColor="#008805" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_3350_1349"
          x1="99.2492"
          y1="14.8818"
          x2="99.2492"
          y2="19.2786"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F1E869" />
          <stop offset="0.33" stopColor="#FEA514" />
          <stop offset="0.6" stopColor="#FF4539" />
        </linearGradient>
        <clipPath id="clip0_3350_1349">
          <rect width="128.891" height="20.8754" fill="white" transform="translate(0.416016 0.369141)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const Logo: React.FC = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="37" viewBox="0 0 36 37" fill="none">
      <g clipPath="url(#clip0_3350_13568)">
        <path d="M-11.7002 9.02783H62.9998V18.4778H-11.2514L-11.7002 9.02783Z" fill="url(#paint0_linear_3350_13568)" />
        <path d="M-18.4502 0.027832H62.9998V9.02783H-11.848L-18.4502 0.027832Z" fill="url(#paint1_linear_3350_13568)" />
        <path d="M-11.25 27.4778H63V36.4778H-11.25V27.4778Z" fill="url(#paint2_linear_3350_13568)" />
        <path d="M-11.25 18.4778H63V27.4778H-11.25V18.4778Z" fill="url(#paint3_linear_3350_13568)" />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_3350_13568"
          x1="25.6516"
          y1="13.9855"
          x2="25.6516"
          y2="24.5259"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF6B6B" />
          <stop offset="0.33" stopColor="#FE1414" />
          <stop offset="0.6" stopColor="#8E0900" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3350_13568"
          x1="22.2766"
          y1="4.74939"
          x2="22.2766"
          y2="14.7879"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBDBFF" />
          <stop offset="0.33" stopColor="#139EDD" />
          <stop offset="0.6" stopColor="#B9F7EA" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3350_13568"
          x1="25.8769"
          y1="32.1992"
          x2="25.8769"
          y2="42.2375"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBFFCA" />
          <stop offset="0.33" stopColor="#48DD13" />
          <stop offset="0.6" stopColor="#008805" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_3350_13568"
          x1="25.8769"
          y1="23.1992"
          x2="25.8769"
          y2="33.2375"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F1E869" />
          <stop offset="0.33" stopColor="#FEA514" />
          <stop offset="0.6" stopColor="#FF4539" />
        </linearGradient>
        <clipPath id="clip0_3350_13568">
          <rect y="0.027832" width="36" height="36" rx="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default React.memo(LogoWithText)

import { useAccount } from 'wagmi'
import { useCallback, useState, useEffect } from 'react'
import { useToast } from 'state/hooks'
import { JoinBuybackBody } from 'state/v2_types'

export const useJoinBuyback = () => {
  const { address: account } = useAccount()
  const { toastErrorV2 } = useToast()

  const [isLoading, setIsLoading] = useState(false)

  const URL = process.env.REACT_APP_BACKEND_URL
  const joinBuyback = useCallback(
    (body: JoinBuybackBody, onSucceed?) => {
      setIsLoading(true)

      fetch(`${URL}/api/backend-service/buyback/join`, {
        credentials: 'include',
        headers: [['Cache-Control', 'no-cache']],
        method: 'POST',
        body: JSON.stringify(body),
      })
        .then((res) => res.json())
        .then((res) => {
          setIsLoading(false)
          if (res.status_code !== 200) {
            throw res.error
          }
        })
        .then(onSucceed)
        .catch((error) => {
          setIsLoading(false)
          toastErrorV2('Error', `Something is wrong: ${error}`)
        })
    },
    [account],
  )

  return { joinBuyback, isLoading }
}

export const useFetchJoin = (saleId: number, stateChanged: boolean, account: string) => {
  const [joinBuyback, setJoinBuyback] = useState()

  const onFetchAddressState = async () => {
    if (account) {
      const URL = process.env.REACT_APP_BACKEND_URL
      const ENDPOINT = `${URL}/api/backend-service/buyback/${account}/${saleId}`

      try {
        const response = await fetch(`${ENDPOINT}`, {
          credentials: 'include',
          headers: [['Cache-Control', 'no-cache']],
        })
        const responseData = await response.json()
        setJoinBuyback(responseData.data?.hasJoined)
      } catch (error) {
        console.error('Unable to fetch data:', error)
      }
    }
  }

  useEffect(() => {
    if (account) {
      onFetchAddressState()
    }
  }, [joinBuyback, stateChanged, account])
  return joinBuyback
}

export default useJoinBuyback

import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const InvestLight: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.74629 4.48312C4.63518 5.40673 0.492126 8.52062 0.808792 13.5873C0.808788 14.5109 1.20461 16.5956 2.78795 17.5456C4.37128 18.4956 7.66989 18.7331 9.12128 18.7331C9.24065 18.7331 9.37342 18.7312 9.51711 18.7269V16.3581H8.72545V12.3998H9.51711V9.23312H15.6518C14.8198 7.52204 13.328 5.69393 10.7046 4.48312C9.91295 4.61506 8.01297 4.79979 6.74629 4.48312Z"
        fill="url(#paint0_linear_20522_201169)"
      />
      <path d="M11.1004 10.0248H18.2254V12.3998H11.1004V10.0248Z" fill="url(#paint1_linear_20522_201169)" />
      <path d="M10.3088 13.1915H17.4338V15.5665H10.3088V13.1915Z" fill="url(#paint2_linear_20522_201169)" />
      <path d="M11.1004 16.3581H18.2254V18.7331H11.1004V16.3581Z" fill="url(#paint3_linear_20522_201169)" />
      <path
        d="M5.55879 0.920432C5.55879 1.55377 6.35046 2.50377 6.74629 2.8996C8.32962 3.53297 10.0449 3.16347 10.7046 2.89956C11.3643 2.10789 12.288 1.31622 11.8921 0.524557C11.5755 -0.108777 10.3088 0.260646 9.91296 0.524524C8.96296 -0.425476 7.93379 0.128741 7.53796 0.524599C5.95462 -0.425401 5.55879 0.392654 5.55879 0.920432Z"
        fill="url(#paint4_linear_20522_201169)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_20522_201169"
          x1="0.406974"
          y1="6.06421"
          x2="18.2254"
          y2="6.06421"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00CB82" />
          <stop offset="1" stopColor="#00B4ED" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_20522_201169"
          x1="0.406974"
          y1="6.06421"
          x2="18.2254"
          y2="6.06421"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00CB82" />
          <stop offset="1" stopColor="#00B4ED" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_20522_201169"
          x1="0.406974"
          y1="6.06421"
          x2="18.2254"
          y2="6.06421"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00CB82" />
          <stop offset="1" stopColor="#00B4ED" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_20522_201169"
          x1="0.406974"
          y1="6.06421"
          x2="18.2254"
          y2="6.06421"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00CB82" />
          <stop offset="1" stopColor="#00B4ED" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_20522_201169"
          x1="0.406974"
          y1="6.06421"
          x2="18.2254"
          y2="6.06421"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00CB82" />
          <stop offset="1" stopColor="#00B4ED" />
        </linearGradient>
      </defs>
    </Svg>
  )
}

export default InvestLight

import { Token, Chain } from 'config/constants/types'
import { Banner, Project } from 'state/v2_types'
import { getCMSApiUrl, getCMSV1ApiUrl } from 'utils/getCMSApiUrl'
import { getWithExpiry, setWithExpiry } from 'utils/localStorageHelper'

const IDOS_STORAGE_KEY = 'IDOS_DATA'
const PROJECT_STORAGE_KEY = 'PROJECTS_DATA'
const PROJECT_ACCOUNT_STORAGE_KEY = 'PROJECTS_ACCOUNT_DATA'
const BANNER_STORAGE_KEY = 'BANNERS_DATA'

const CACHE_VERSION = '3'

const parseTokenToV1 = (token: Token, chainId: Chain): Token => {
  return { ...token, symbol: token.tokenSymbol, address: { [chainId]: token.tokenAddress } }
}

const encodeQueryData = (data) => {
  const ret = []

  Object.keys(data).forEach((dataKey) => {
    if (data[dataKey]) {
      ret.push(`${encodeURIComponent(dataKey)}=${encodeURIComponent(data[dataKey])}`)
    }
  })

  return ret.join('&')
}

const fetchProjectsConfig = async (account, withAllocations): Promise<Project[]> => {
  let projects: Project[] = []
  // check local storage

  const cacheKey = `${PROJECT_ACCOUNT_STORAGE_KEY}_${account?.length > 0 ? account : 'NOACCOUNT'}_${
    withAllocations ? 'WITHALLO' : 'NOALLO'
  }`

  // const cachedData = getWithExpiry(cacheKey, CACHE_VERSION)
  // if (cachedData) {
  //   return cachedData
  // }

  const projectUrl = withAllocations ? 'projects' : 'projects-v2'

  const queryString = {
    account: account && projectUrl === 'projects' ? account : undefined,
    company_id: process.env.REACT_APP_COMPANY_ID ?? undefined,
  }

  const url = `${getCMSApiUrl()}/api/backend-service/${projectUrl}${
    encodeQueryData(queryString)?.length > 0 ? `?${encodeQueryData(queryString)}` : ''
  }`
  const opt: RequestInit = { headers: [['Cache-Control', 'no-cache']] }

  // fetch data from server
  const res = await fetch(url, opt)
  projects = await res.json()
  projects = (projects as any).data || []
  projects = projects.map((project) => {
    project.id = project.projectId
    project.banner = project.bannerImage

    // For OMNI Test sale
    if (project.title?.toLowerCase().indexOf('omni') > -1) {
      project.isPrivate = true
    }

    if (project.title?.toLowerCase().indexOf('brosky') > -1 || project.title?.toLowerCase().indexOf('strat') > -1) {
      project.isInnovationZone = true
    }

    project.image = project.projectImage
    project.sales = project.sales.map((sale: any) => {
      sale.id = sale.saleId
      sale.projectID = sale.projectId
      sale.title = sale.saleTitle
      if (sale.paymentToken) {
        sale.paymentToken = parseTokenToV1(sale.paymentToken, sale.saleChainId)
      }
      sale.stepRequirement = sale.stepsRequirement
      if (sale.stakingToken) {
        sale.stakingToken = parseTokenToV1(sale.stakingToken, sale.chainId)

        if (sale.stakingToken.symbol === 'USDT' && sale.chainId === 5000) {
          sale.isGiftBox = true
        }
      }

      if (sale.chainId === 169) {
        sale.isSaleTBD = true
      }

      if (sale.saleToken) {
        if (sale.saleToken.tokenSymbol === 'ECLIP') {
          sale.isSeparateClaim = true
        }

        if (sale.saleToken.tokenSymbol === 'MockAPR') {
          sale.saleToken.tokenSymbol = 'APRS'
          sale.saleToken.tokenName = 'Apeiron'
          sale.limitPerPurchase = '5500'
        }

        if (sale.saleToken.tokenSymbol === 'IFST') {
          sale.saleToken.tokenSymbol = 'APRS'
          sale.saleToken.tokenName = 'Apeiron'
        }

        if (sale.saleToken.tokenSymbol === 'MATICS' || sale.saleToken.tokenSymbol === 'KYCT') {
          sale.isPromoCodeEnabled = true
          sale.isNFTSale = true
        }

        if (sale.saleToken.tokenSymbol === 'ManyTokens') {
          sale.isPromoCodeEnabled = true
        }

        const { saleToken } = sale
        sale.token = {
          name: saleToken.tokenName,
          symbol: saleToken.tokenSymbol,
          image: saleToken.tokenImage,
          address: saleToken.tokenAddress,
          decimals: saleToken.decimals,
        }
      }
      return sale
    })
    return project
  })
  // save in local storage
  // if (projects?.length > 0) {
  //   setWithExpiry(cacheKey, projects, 30 * 60 * 1000, CACHE_VERSION)
  // }
  return projects
}

const fetchBannersConfig = async (): Promise<Banner[]> => {
  let banners: Banner[] = []

  // check local storage
  const cachedData = getWithExpiry(BANNER_STORAGE_KEY, CACHE_VERSION)
  if (cachedData) {
    return cachedData
  }

  // fetch data from server
  const res = await fetch(`${getCMSV1ApiUrl()}/api/banners`)
  banners = await res.json()

  const filteredBanners = banners.filter(
    (banner) => banner.isShown && (banner.brand === 'all' || banner.brand === process.env.REACT_APP_WHITELABEL_BRAND),
  )
  // save in local storage
  setWithExpiry(BANNER_STORAGE_KEY, filteredBanners, 15 * 60 * 1000, CACHE_VERSION)
  return filteredBanners
}

export { fetchProjectsConfig, fetchBannersConfig, IDOS_STORAGE_KEY, PROJECT_STORAGE_KEY, BANNER_STORAGE_KEY }

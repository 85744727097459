import React from 'react'
import Svg from '../../../components/Svg/Svg'

const WalletFullWhite: React.FC = ({ ...props }) => {
  return (
    <Svg width="23" height="16" viewBox="0 0 23 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0682 11.6667H16.7272C14.6219 11.6667 12.9091 9.74166 12.9091 7.63636C12.9091 5.53105 14.6219 3.5 16.7272 3.5H20.0682C20.332 3.5 20.5454 3.28653 20.5454 3.02271V1.9091C20.5454 0.908262 19.7691 0.0943203 18.7883 0.0153915L4.0594 0H2.4091C1.35622 0 0.5 0.85618 0.5 1.9091V13.3636C0.5 14.4165 1.35618 15.2727 2.4091 15.2727H18.6363C19.6892 15.2727 20.5454 14.4165 20.5454 13.3636V12.144C20.5454 11.8801 20.332 11.6667 20.0682 11.6667ZM17.3636 4.77269H20.7045C21.4941 4.77269 22.1364 5.41495 22.1364 6.20451V9.06814C22.1364 9.8577 21.4941 10.5 20.7045 10.5H17.3636C15.7845 10.5 14.5 9.21544 14.5 7.63632C14.5 6.05721 15.7845 4.77269 17.3636 4.77269ZM16.4091 7.63632C16.4091 8.16252 16.8374 8.59085 17.3636 8.59085C17.8899 8.59085 18.3182 8.16252 18.3182 7.63632C18.3182 7.11013 17.8898 6.6818 17.3636 6.6818C16.8374 6.6818 16.4091 7.11013 16.4091 7.63632Z"
        fill="white"
      />
    </Svg>
  )
}

export default React.memo(WalletFullWhite)

import { Box } from '@mui/material'
import ConnectWalletButton from 'componentsV2/ConnectWalletButton/ConnectWalletButton'
import { LockDark, LockLight } from 'componentsV2/Icons/Sale/Lock'
import { useTranslation } from 'contexts/Localization'
import React, { useMemo } from 'react'
import styled, { useTheme } from 'styled-components'
import SaleDetailCard from '../SaleDetailCard'

const SaleDetailCardWrapper = styled(SaleDetailCard)`
  background: transparent;
  box-shadow: unset;
  border-radius: 0;
  backdrop-filter: unset;
  & > div {
    padding: 0;
  }
`

interface WhitelistSaleLimitedAccessCardProps {
  showConnectWalletButton?: boolean
}

const WhitelistSaleLimitedAccessCard: React.FC<WhitelistSaleLimitedAccessCardProps> = ({
  showConnectWalletButton = true,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const imageInfo = useMemo(() => {
    return {
      image: theme.isDark ? <LockDark /> : <LockLight />,
    }
  }, [theme])
  const title = t('Oops! Limited access.')
  const subtitleInfo = useMemo(() => {
    return {
      subtitle: t('You can only access this pool if your address is whitelisted.'),
      style: {
        marginTop: 0,
      },
    }
  }, [t])
  const ctaInfo = useMemo(() => {
    return showConnectWalletButton
      ? {
          button: <ConnectWalletButton width="100%" marginTop="30px" />,
        }
      : null
  }, [showConnectWalletButton])
  return (
    <Box>
      <SaleDetailCardWrapper
        showBorder={false}
        imageInfo={imageInfo}
        title={title}
        subtitleInfo={subtitleInfo}
        ctaInfo={ctaInfo}
      />
    </Box>
  )
}

export default WhitelistSaleLimitedAccessCard
